import React from 'react';
import moment from 'moment';
import logo from '../../../../img/logo-konica.png';

const DraftServiceLabor = props => {
  let total = 0; let gst = 0;
  props.dsubmit.quotation_items.map(qi => total=+total + +qi.amount)
  gst = 0.07 * total;
  gst = gst.toFixed(2);
  total = +gst + +total;
  total = total.toFixed(2);
  return ( 
    <div className="modal-body">
      <div className="report" style={{width: "800px", margin:"0 auto"}}>
        <div className="row">
          <div className="col-md-2">
            <img src={logo} alt="" style={{width: "100%"}} />
          </div>
          <div className="col-md-10">
            <br/><br/>
            <strong>Konica Minolta Business Solutions Asia Pte Ltd, No 10 Teban Gardens Crescent, Singapore 608823</strong><br/>
            Co Reg No 197200816N TI : (65) 65635533 Fax (65) 65619879 www.konicaminolta.asia
          </div>
        </div>
        <h2 className="text-center nomargin">QUOTATION</h2>
        <br/><br/>
        <div className="row">
          <div className="col-md-6">
            <div className="media">
              <div className="media-left">To</div>
              <div className="media-body">
                {props.customer.CustomerName}<br/>
                
                Singapore &nbsp;&nbsp;&nbsp;&nbsp;{props.customer.ship_to_postcode}
              </div>
            </div>
            <br/>
            Attn: {props.contact.gender + ' ' + props.contact.contact_name}<br/>
            {props.contact.type_channel_of_contact} : {props.contact.channel_of_contact}<br/>
            {/* Fax : evelynechia@smu.edu.sg */}
          </div>
          <div className="col-md-6">
            <table style={{width: "100%"}}>
              <tbody>
                  <tr>
                <td width="50%">Date:</td>
                <td>{moment().format('LL')}</td>
              </tr>
              <tr>
                <td>Our Ref:</td>
                <td><strong>{props.dsubmit.ticket_tracking_id}</strong></td>
              </tr>
              <tr>
                <td>Prepared By:</td>
                <td>{localStorage.username}</td>
              </tr>
              <tr>
                <td>Service Hotline No</td>
                <td>6895 8555</td>
              </tr>
              <tr>
                <td colSpan="2">&nbsp;</td>
              </tr>
              <tr>
                <td>Model No</td>
                <td>{props.dsubmit.model}</td>
              </tr>
              <tr>
                <td>Serial No</td>
                <td>{props.dsubmit.serial_number}</td>
              </tr>
              <tr>
                <td>Equipment No</td>
                <td>{props.dsubmit.equipment_number}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr style={{border: "none", borderBottom: "4px solid #000" }} />
        Customer No : {props.customer.sap_customer_code}<br/>
        As requested, we are please to quote the following for your kind consideration.
        <br/><br/>
        <table style={{border: "1px solid #000", width: "100%", borderCollapse: "collapse"}} border="1">
          <thead>
            <tr>
              <th></th>
              <th>Item Description</th>
              <th className="text-center">Item Code</th>
              <th className="text-center">Unit Price</th>
              <th className="text-center">Qty</th>
              <th className="text-center">Discount ($)</th>
              <th className="text-center">Amout ($)</th>
            </tr>
          </thead>
          <tbody>
            {props.dsubmit.quotation_items.length > 0 ?
             props.dsubmit.quotation_items.map((qi, i) => (
               <tr key={i+1}>
                 <td className="text-center">{i+1}</td>
                 <td>{qi.item_description}</td>
                 <td className="text-center">{qi.item_code}</td>
                 <td className="text-center">{qi.unit_price}</td>
                 <td className="text-center">{qi.qty}</td>
                 <td className="text-center">{qi.disc}</td>
                 <td className="text-center">{qi.amount}</td>
               </tr>
             ))
             :
             <tr>
               <td colSpan="7" style={{textAlign: "center"}}>No Data Available in This Table </td>
             </tr>
            }
           
            <tr>
              <td colSpan="5">&nbsp;</td>
              <td className="text-right"><strong>7% GST</strong></td>
              <td className="text-right"><strong>${gst}</strong></td>
            </tr>
            <tr>
              <td colSpan="5">&nbsp;</td>
              <td className="text-right"><strong>TOTAL</strong></td>
              <td className="text-right"><strong>$ {total}</strong></td>
            </tr>
          </tbody>
        </table>
        <br/>
        Please be informed that we are the sole supplier of the above-mentioned item<br/>
        Price validity: 14 Days, subject to change without further notice<br/>
        Delivery Period : 1 to 7 days subject to availability of Stocks<br/>
        Prevailing Goods & Service Tax (GST) will be applied.<br/>
        <strong>Payment terms : {props.dsubmit.payment_method}</strong>
        <br/>
        <strong><u>Term and Condition for "Due to Delivery"</u></strong><br/>
        As Part of the company policies and compliance, we only accept cheques as the mode of payment.<br/>
        Cheques should be crossed and made payment to <strong>Konica Minolta Business Solutions Asia Pte Ltd</strong>
        <br/><br/>
        <div className="row">
          <div className="col-md-4 col-md-offset-2">
            <strong>
              Kindly confirm your acceptance of this quote Fax to : 6895 8662
            </strong>
          </div>
          <div className="col-md-6">
            <div style={{border: "1px solid #000"}}>
              <div style={{padding:"5px", height: "150px"}}>
                CONFIRMED ACCEPTANCE BY :
              </div>
              <div style={{padding: "10px 5px 5px", borderTop:"1px solid #000"}}>
                Name/ Signature & Company Stamp
              </div>
            </div>
          </div>
        </div>
        <br/><br/>
        Regards, <br/>
        Computer Generated Sales Quote, No Signature is Required
      </div>
    </div>
  )
}

export default DraftServiceLabor;
