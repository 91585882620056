import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import logo_konica from "../../img/logo-konica2.png";
import { logoutUser, loginUser } from "../../actions/authActions";
import jwt_decode from "jwt-decode";
import { Link, Route } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <div className="text-center copyright">
        <p>Version 2.0 </p>
        &copy;Copyright 2019. Incenplus Marketing Group Sdn. Bhd
      </div>
    );
  }
}

export default Footer;
