import React from 'react';

import Modal from '../../../../ui/Modal/Modal';
import './Modal.css'

const SearchNameModel = (props) => {
  return (
    <Modal title="Search Name" onclose={props.onclose} modalid={props.modalid} modalclass={props.modalclass}>
      <div className="modal-body">
        <div className="alert alert-warning">
          Your search has multiple matches
        </div>
        <p>
          Please highlight the applicable customer and click select
        </p>
        <div className="table-fixed-header" style={{height: "200px"}}>
          <table className="table table-bordered table-hover table-xs table-fixed">
            <thead>
              <tr>
                {/* <th width="10"></th> */}
                <th style={{width:"300px"}}>Customer Name</th>
                <th style={{width:"500px"}}>Ship to Address</th>
              </tr>
            </thead>
            <tbody>
              {props.listuser.map(lu => (
                <tr key={lu.CustomerCode} className={props.trclass === lu.CustomerCode ? 'selected' : ''}>
                  {/* <td><input name="selected_name" type="radio" onChange={props.select} custid={lu.CustomerCode} value={lu.CustomerCode}/></td> */}
                  <td style={{width:"300px"}} onClick={e => props.onclick(e, lu.CustomerCode, lu.CustomerName)}>{lu.CustomerName}</td>
                  <td style={{width:"500px"}} onClick={e => props.onclick(e, lu.CustomerCode, lu.CustomerName)}>{lu.CustomerAddress}</td>
                </tr>
              ))}
              
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-footer align-right">
        <button type="button" className="btn btn-default pull-right" onClick={props.onclose} style={{marginLeft: "5px", marginTop: "-1px"}}>Close</button>
        <button type="button" className="btn btn-primary pull-right" onClick={props.onselect} disabled={!props.trclass}>Select</button>
      </div>
    </Modal>
  )
}

export default SearchNameModel;
