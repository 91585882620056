import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "../../../axios-env";
import moment from "moment";
import PrintTemplate from "react-print";
import Navbar from "../../layout/Navbar";
import Sidebar from "../../layout/Sidebar";
import isEmpty from "../../../validation/is-empty";
import OriginatorField from "./OriginatorField"
import CustomerInformation from "./CustomerInformation"
import {
  proccessConsumable,
  createSalesOrder
} from "../../../actions/consumableAction";

import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

import { listToner } from "../../../actions/listTonerActions";
import { ticketTracking } from "../../../actions/ticketTrackingAction";
import ModalHeader from "../../common/ModalHeader";
import Pagination from "../../common/Pagination";
import salesItem from "../../common/salesItem";
import Modal from "../../../ui/Modal/Modal";

import FormCustomer from "../customer/FormCustomer";
import ConsumableRequested from "./ConsumableRequested";
import ContactDetail from "./ContactDetail";
import Footer from "../../layout/Footer";

let kiri = "";
let kanan = "";
let modalMachine;
let flash;
let modalToner = false;
let selectTonerModal;
let displayModal = "";

class Consumable extends Component {
    state = {
      remarks: "",
      delivery_to: "",
      ticket_tracking: [],
      list_toner: [],
      currentToners: [],
      currentPage: null,
      totalPages: null,
      c_code: "",
      y_code: "",
      m_code: "",
      k_code: "",
      stappler_code: "",
      wtb_code: "",
      toner_for_c: {},
      toner_for_y: {},
      toner_for_m: {},
      toner_for_k: {},
      toner_for_stappler: {},
      toner_for_wtb: {},
      fulfill_c: false,
      date: "",
      stock_in_hand: "",
      displayInternalNote: false,
      displayMeterReading: false,
      displayHistoryToner: false,
      displayErrorMessage: false,
      listMeterReading: [],
      listHistoryTonerSupply: []
    }
   

  componentDidMount() {
    const { id } = this.props.match.params;
    const jwtToken = localStorage.getItem("jwtToken");
    const data = {
      id: id,
      jwtToken: localStorage.getItem("jwtToken")
    };

    this.props.proccessConsumable(data);
    this.props.listToner(jwtToken);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.consumable) {
        const consumable_request = nextProps.consumable.consumable_request;
        const machine_customer = nextProps.consumable.machine_customer
        
        // If consumable field doesnt exist, make empty string
        consumable_request.remark = !isEmpty(consumable_request.remark) ? consumable_request.remark : '';
        consumable_request.delivery_to = !isEmpty(consumable_request.delivery_to) ? consumable_request.delivery_to : '';
        machine_customer.location = !isEmpty(machine_customer.location) ? machine_customer.location : '';

        // Set component fields state
        this.setState({
            remarks: consumable_request.remark,
            delivery_to: consumable_request.delivery_to
        })
    }
  }

  onChange =(e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  printContent = () => {
    window.print(); 
  };

  meterReading = () => {
    axios.get(`/api/v1/ticket-management/consumable/meter-reading-history?machine_serial=${this.props.consumable.machine.machine_serial}`, {headers: {Authorization: `Bearer ${localStorage.jwtToken}`}})
    .then(res => {        
        this.setState({displayMeterReading: true, listMeterReading: res.data.data}) 
    }).catch(err => console.log("error", err));
  }

  historyTonerSupply = () => {
      axios
        .get(`api/v1/ticket-management/consumable/toner-supply-history?customer_code=${this.props.consumable.customer.sap_customer_code}`, {headers: {Authorization: `Bearer ${localStorage.jwtToken}`}})
        .then(res => {
            this.setState({listHistoryTonerSupply: res.data.data, displayHistoryToner: true})
        })
        .catch(err => {
            console.log("error", err);
        })
  }
  showModalMachine2 = () => {
    this.setState({ showMachine2: true });
    console.log("tracking id", this.props.consumable.ticket_origin.id);

    const dataTrack = {
      id: this.props.consumable.ticket_origin.id,
      jwtToken: localStorage.getItem("jwtToken")
    };
    this.props.ticketTracking(dataTrack);
  };
  hideModal2 = () => {
    this.setState({ show2: false, showMachine2: false });
  };

  selectToner = data => {
    if (this.state.c_code !== "") {
     NotificationManager.success( 'successfully chosed!', 'Success', 3000, () => {
        alert('callback');
      });
        this.setState({toner_for_c: data});  
    } else if (this.state.y_code !== "") {
     NotificationManager.success( 'successfully chosed!', 'Success', 3000, () => {
        alert('callback');
      });
        this.setState({toner_for_y: data})
    } else if (this.state.m_code !== "") {
      NotificationManager.success( 'successfully chosed!', 'Success', 3000, () => {
        alert('callback');
      });
        this.setState({toner_for_m: data})
    } else if (this.state.k_code !== "") {
      NotificationManager.success( 'successfully chosed!', 'Success', 3000, () => {
        alert('callback');
      });
        this.setState({toner_for_k: data})
    } else if(this.state.stappler_code !== "") {
      NotificationManager.success( 'successfully chosed!', 'Success', 3000, () => {
        alert('callback');
      });
        this.setState({toner_for_stappler: data})
    } else {
      NotificationManager.success( 'successfully chosed!', 'Success', 3000, () => {
        alert('callback');
      });
        this.setState({toner_for_wtb: data})
    }
  }
 
  // Function to handle pagination
  onPageChanged = data => {
    const { list_toners } = this.props.list_toner;    
    const { currentPage, totalPages, pageLimit } = data;    
    const offset = (currentPage - 1) * pageLimit;
    const currentToners = list_toners.slice(offset, offset + pageLimit);
    this.setState({ currentPage, currentToners, totalPages }); 
  };

  selectFullfilment = event => {
    if(event === "c_code") {
        this.setState({c_code: event, y_code: "", m_code: "", k_code: "", stappler_code: "", wtb_code: "", list_toner: this.props.list_toner.list_toners}, () => {
            console.log("event-nya adalah ", event, "propsnya ", this.state.list_toner);
            
        })     
    } else if(event === "y_code") {
        this.setState({y_code: event, c_code: "", m_code: "", k_code: "", stappler_code: "", wtb_code: ""})
    } else if(event === "m_code") {
        this.setState({m_code: event, c_code: "", y_code: "", k_code: "", stappler_code: "", wtb_code: ""})
    } else if(event === "k_code") {
        this.setState({k_code: event, c_code: "", y_code: "", m_code: "", stappler_code: "", wtb_code: ""})
    } else if(event === "stappler_code") {
        this.setState({stappler_code: event, c_code: "", y_code: "", m_code: "", k_code: "", wtb_code: ""})
    } else {
        this.setState({wtb_code: event, c_code: "", y_code: "", m_code: "", k_code:"", stappler_code: ""})
    }
  }


  onSubmitOrder = () => {
    let list_item = [];
    const months = ["JAN", "FEB", "MAR","APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    let current_datetime = new Date()
    let formatted_date = months[current_datetime.getMonth()] + " " + current_datetime.getDate() + " " + current_datetime.getFullYear() + " "   + current_datetime.getHours() + ":" + current_datetime.getMinutes() + ":" + current_datetime.getSeconds() 
    if(!isEmpty(this.state.toner_for_c)) {
        list_item = [...list_item, salesItem(
            1, this.state.toner_for_c.part_number, "0214", formatted_date, 
            this.state.toner_for_c.description, "descritpion2", this.props.consumable.consumable_request.c, 
            this.props.consumable.consumable_request.c,  this.props.consumable.consumable_request.c, 60, 
            this.props.consumable.consumable_request.c, this.props.consumable.consumable_request.c, 
            this.props.consumable.consumable_request.c, "bin code", "unitmeasurecode", formatted_date, formatted_date,
            this.props.consumable.machine_customer.ship_to_code, "machinecode", "service report number"
             )]
    }
    if (!isEmpty(this.state.toner_for_y)) {
        list_item = [...list_item, salesItem(
            2, this.state.toner_for_y.part_number, "0214", formatted_date, 
            this.state.toner_for_y.description, "descritpion2", this.props.consumable.consumable_request.y, 
            this.props.consumable.consumable_request.y,  this.props.consumable.consumable_request.y, 60, 
            this.props.consumable.consumable_request.y, this.props.consumable.consumable_request.y, 
            this.props.consumable.consumable_request.y, "bin code", "unitmeasurecode", formatted_date, formatted_date,
            this.props.consumable.machine_customer.ship_to_code, "machinecode", "service report number"
             )]
    }
    if (!isEmpty(this.state.toner_for_m)) {
        list_item = [...list_item, salesItem(
            3, this.state.toner_for_m.part_number, "0214", formatted_date, 
            this.state.toner_for_m.description, "descritpion2", this.props.consumable.consumable_request.m, 
            this.props.consumable.consumable_request.m,  this.props.consumable.consumable_request.m, 60, 
            this.props.consumable.consumable_request.m, this.props.consumable.consumable_request.m, 
            this.props.consumable.consumable_request.m, "bin code", "unitmeasurecode", formatted_date, formatted_date,
            this.props.consumable.machine_customer.ship_to_code, "machinecode", "service report number"
             )]
    }
    if (!isEmpty(this.state.toner_for_k)) {
        list_item = [...list_item, salesItem(
            4, this.state.toner_for_k.part_number, "0214", formatted_date, 
            this.state.toner_for_k.description, "descritpion2", this.props.consumable.consumable_request.k, 
            this.props.consumable.consumable_request.k,  this.props.consumable.consumable_request.k, 60, 
            this.props.consumable.consumable_request.k, this.props.consumable.consumable_request.k, 
            this.props.consumable.consumable_request.k, "bin code", "unitmeasurecode", formatted_date, formatted_date,
            this.props.consumable.machine_customer.ship_to_code, "machinecode", "service report number"
             )]
    }
    if (!isEmpty(this.state.toner_for_stappler)) {
        list_item = [...list_item, salesItem(
            5, this.state.toner_for_stappler.part_number, "0214", formatted_date, 
            this.state.toner_for_stappler.description, "descritpion2", this.props.consumable.consumable_request.stapler, 
            this.props.consumable.consumable_request.stapler,  this.props.consumable.consumable_request.stapler, 60, 
            this.props.consumable.consumable_request.stapler, this.props.consumable.consumable_request.stapler, 
            this.props.consumable.consumable_request.stapler, "bin code", "unitmeasurecode", formatted_date, formatted_date,
            this.props.consumable.machine_customer.ship_to_code, "machinecode", "service report number"
             )]
    }
    if (!isEmpty(this.state.toner_for_wtb)) {
        list_item = [...list_item, salesItem(
            1, this.state.toner_for_wtb.part_number, "0214", formatted_date, 
            this.state.toner_for_wtb.description, "descritpion2", this.props.consumable.consumable_request.wtb, 
            this.props.consumable.consumable_request.wtb,  this.props.consumable.consumable_request.wtb, 60, 
            this.props.consumable.consumable_request.wtb, this.props.consumable.consumable_request.wtb, 
            this.props.consumable.consumable_request.wtb, "bin code", "unitmeasurecode", formatted_date, formatted_date,
            this.props.consumable.machine_customer.ship_to_code, "machinecode", "service report number"
             )]
    }
    
    const dataOrder = {
            is_next_step: 1,
            sell_to_customer_code:"",
            sell_to_customer_name: this.props.consumable.machine_customer_detail_customer.CustomerName,
            sales_order_number: this.props.consumable.data.ticket_number,
            bill_to_customer_code: this.props.consumable.data.ticket_number,
            bill_to_customer_name: this.props.consumable.machine_customer_detail_customer.CustomerName,
            bill_to_customer_name2: this.props.consumable.machine_customer_detail_customer.CustomerName,
            bill_to_address: this.props.consumable.machine_customer.ship_to_address,
            bill_to_city: this.props.consumable.machine_customer.ship_to_city,
            your_reference:"",
            ship_to_code: this.props.consumable.machine_customer.ship_to_code,
            ship_to_customer_name: this.props.consumable.machine_customer_detail_customer.CustomerName,
            ship_to_customer_name2: this.props.consumable.machine_customer_detail_customer.CustomerName,
            ship_to_address: this.props.consumable.machine_customer.ship_to_address,
            ship_to_city: this.props.consumable.machine_customer.ship_to_city,
            order_date: this.state.date,
            posting_date: this.state.date,
            shipment_date: this.state.date,
            payment_terms_code:"",
            due_date: this.state.date,
            location_code:"0214",
            branch_code:"BSA",
            sales_person_code: this.props.consumable.customer_sales.Sales_Person_Code,
            sell_to_address: this.props.consumable.machine_customer.ship_to_address,
            sell_to_city: this.props.consumable.machine_customer.ship_to_city,
            bill_to_post_code: this.props.consumable.machine_customer.ship_to_postcode,
            sell_to_post_code: this.props.consumable.machine_customer.ship_to_postcode,
            ship_to_post_code: this.props.consumable.machine_customer.ship_to_postcode,
            document_date: this.state.date,
            status:"",
            sell_to_contact_number:"51912601",
            bill_to_contact_number:"51912601",
            ship_to_phone:"51912601",
            bundle_price:"0",
            sales_sub_type:"C07",
            sales_type:"3",
            po_number:".",
            contract_number:"500054026",
            sales_items: list_item,
            ticket_tracking_id:this.props.consumable.data.id,
    }

    // this.props.createSalesOrder(dataOrder, this.props.history)    
    console.log("create salse order", dataOrder);
    console.log("data of consumable", this.props.consumable.data);
    console.log("localStorage.jwtToken", localStorage.jwtToken);
    
    axios({
        method: "POST",
        url: "/api/v1/ticket-management/consumable-request/store",
        headers: {
            Authorization: "Bearer " + localStorage.jwtToken
        },
        data: dataOrder
    }).then(res => {
        console.log("res status", res.status);
        
        if (res.status == 200 && this.state.c_code !== "" || this.state.k_code !== "" || this.state.m_code !== "" || this.state.y_code !== "") {
          NotificationManager.success( 'successfully submited!', 'Success', 3000, () => {
            alert('callback');
          });
            this.props.history.push("/ticket-management");
        } else {
            // this.setState({ displayErrorMessage: true});
            NotificationManager.error( 'Failed to submit!', 'Warning', 3000, () => {
             alert('callback');
          });
        }
    }).catch(err => {
        console.log("Error", err);
    })   
    
  }

  handleChange = event => {
    this.setState({
      stock_in_hand: event.target.value
    });
        
  }

  render() {
    // console.log("fullprops", this.props.consumable.ticket_origin.contact_detail);

    if (this.props.consumable.data !== null) {
      if (this.props.consumable.data.length !== 0) {
        const {
          data,
          action_by,
          received_by,
          originator,
          ticket_origin,
          originator_department,
          customer,
          customer_sales,
          consumable_request,
          machine_customer,
          machine_customer_detail_customer,
          machine,
          machine_model,
          machine_block_by,
          machine_contract,
          ticket_type
        } = this.props.consumable;
        const {contact_detail} = this.props.consumable.ticket_origin;
        const { ticket_tracking } = this.props.ticket_tracking;
        const {
          currentToners,
          toner_for_c,
          toner_for_y,
          toner_for_m,
          toner_for_k,
          toner_for_stappler,
          toner_for_wtb,
          listMeterReading
        } = this.state;
        const { list_toners } = this.props.list_toner;

        const totalToners = list_toners.length;
        if (totalToners === 0) return null;

        console.log("this.props.consumable", this.props.consumable);

        if (this.state.displayErrorMessage == true) {
          flash = (
            <Modal
              onclose={() => this.setState({ displayErrorMessage: false })}
              title="Submit Next Step"
              modalclass="modal display-block"
            >
              <div className="modal-body text-center">
                <div className="alert alert-danger">Failed to submit!</div>
              </div>
            </Modal>
          );
        }

        let radioButtonC;
        if (consumable_request.c !== 0) {
            if (toner_for_c.stock < consumable_request.c) {
                radioButtonC = (
                  <React.Fragment>
                    <td className="text-center">
                      <input type="radio" disabled defaultChecked={false} />
                    </td>
                    <td className="text-center">
                      <input type="radio" defaultChecked={true} />
                    </td>
                  </React.Fragment>
                );
              } else if (toner_for_c.stock > consumable_request.c ) {
                radioButtonC = (
                  <React.Fragment>
                    <td className="text-center">
                      <input type="radio" defaultChecked={true} />
                    </td>
                    <td className="text-center">
                      <input type="radio" disabled defaultChecked={false} />
                    </td>
                  </React.Fragment>
                );
              } else {
                radioButtonC = (
                  <React.Fragment>
                    <td className="text-center">
                      <input type="radio" disabled />
                    </td>
                    <td className="text-center">
                      <input type="radio" disabled />
                    </td>
                  </React.Fragment>
                );
              }
        } else {
            radioButtonC = (
                <React.Fragment>
                  <td className="text-center">
                    <input type="radio" disabled />
                  </td>
                  <td className="text-center">
                    <input type="radio" disabled />
                  </td>
                </React.Fragment>
              );
        }
        

        let radioButtonY;
        if (consumable_request.y !== 0) {
            
        if (toner_for_y.stock < consumable_request.y) {
            radioButtonY = (
              <React.Fragment>
                <td className="text-center">
                  <input type="radio" disabled defaultChecked={false} />
                </td>
                <td className="text-center">
                  <input type="radio" defaultChecked={true} />
                </td>
              </React.Fragment>
            );
          } else if (toner_for_y.stock > consumable_request.y) {
            radioButtonY = (
              <React.Fragment>
                <td className="text-center">
                  <input type="radio" defaultChecked={true} />
                </td>
                <td className="text-center">
                  <input type="radio" disabled defaultChecked={false} />
                </td>
              </React.Fragment>
            );
          } else {
            radioButtonY = (
              <React.Fragment>
                <td className="text-center">
                  <input type="radio" disabled />
                </td>
                <td className="text-center">
                  <input type="radio" disabled />
                </td>
              </React.Fragment>
            );
          }
        } else {
            radioButtonY = (
                <React.Fragment>
                  <td className="text-center">
                    <input type="radio" disabled />
                  </td>
                  <td className="text-center">
                    <input type="radio" disabled />
                  </td>
                </React.Fragment>
              );
        }


        let radioButtonM;
        if (consumable_request.m !== 0) {
            if (toner_for_m.stock < consumable_request.m) {
                radioButtonM = (
                  <React.Fragment>
                    <td className="text-center">
                      <input type="radio" disabled defaultChecked={false} />
                    </td>
                    <td className="text-center">
                      <input type="radio" defaultChecked={true} />
                    </td>
                  </React.Fragment>
                );
              } else if (toner_for_m.stock > consumable_request.m) {
                radioButtonM = (
                  <React.Fragment>
                    <td className="text-center">
                      <input type="radio" defaultChecked={true} />
                    </td>
                    <td className="text-center">
                      <input type="radio" disabled defaultChecked={false} />
                    </td>
                  </React.Fragment>
                );
              } else {
                  radioButtonM = (
                      <React.Fragment>
                        <td className="text-center">
                          <input type="radio" disabled />
                        </td>
                        <td className="text-center">
                          <input type="radio" disabled />
                        </td>
                      </React.Fragment>
                    );
              }
        } else {
            radioButtonM = (
                <React.Fragment>
                  <td className="text-center">
                    <input type="radio" disabled />
                  </td>
                  <td className="text-center">
                    <input type="radio" disabled />
                  </td>
                </React.Fragment>
              );
        }
        

        console.log("toner for k stock", toner_for_k.stock);
        console.log("consumable request k", consumable_request.k);

        let radioButtonK;
        if (consumable_request.k !== 0) {
            if (toner_for_k.stock < consumable_request.k) {
                radioButtonK = (
                  <React.Fragment>
                    <td className="text-center">
                      <input type="radio" disabled defaultChecked={false} />
                    </td>
                    <td className="text-center">
                      <input type="radio" defaultChecked={true} />
                    </td>
                  </React.Fragment>
                );
              } else if (toner_for_k.stock > consumable_request.k) {
                radioButtonK = (
                  <React.Fragment>
                    <td className="text-center">
                      <input type="radio" defaultChecked={true} />
                    </td>
                    <td className="text-center">
                      <input type="radio" disabled defaultChecked={false} />
                    </td>
                  </React.Fragment>
                );
              } else {
                radioButtonK = (
                  <React.Fragment>
                    <td className="text-center">
                      <input type="radio" disabled />
                    </td>
                    <td className="text-center">
                      <input type="radio" disabled />
                    </td>
                  </React.Fragment>
                );
              }
        } else {
            radioButtonK = (
                <React.Fragment>
                  <td className="text-center">
                    <input type="radio" disabled />
                  </td>
                  <td className="text-center">
                    <input type="radio" disabled />
                  </td>
                </React.Fragment>
              );
        }
        
        let radioButtonStappler;
        if (consumable_request.stapler !== 0) {
            if (toner_for_stappler.stock < consumable_request.stapler) {
                radioButtonStappler = (
                  <React.Fragment>
                    <td className="text-center">
                      <input type="radio" disabled defaultChecked={false} />
                    </td>
                    <td className="text-center">
                      <input type="radio" defaultChecked={true} />
                    </td>
                  </React.Fragment>
                );
              } else if (toner_for_stappler.stock > consumable_request.stapler) {
                radioButtonStappler = (
                  <React.Fragment>
                    <td className="text-center">
                      <input type="radio" defaultChecked={true} />
                    </td>
                    <td className="text-center">
                      <input type="radio" disabled defaultChecked={false} />
                    </td>
                  </React.Fragment>
                );
              } else {
                radioButtonStappler = (
                  <React.Fragment>
                    <td className="text-center">
                      <input type="radio" disabled />
                    </td>
                    <td className="text-center">
                      <input type="radio" disabled />
                    </td>
                  </React.Fragment>
                );
              }
        } else {
            radioButtonStappler = (
                <React.Fragment>
                  <td className="text-center">
                    <input type="radio" disabled />
                  </td>
                  <td className="text-center">
                    <input type="radio" disabled />
                  </td>
                </React.Fragment>
              );
        }

        let radioButtonWtb;
        if (consumable_request.wtb !== 0) {
            if (toner_for_wtb.stock < consumable_request.wtb) {
                radioButtonWtb = (
                  <React.Fragment>
                    <td className="text-center">
                      <input type="radio" disabled defaultChecked={false} />
                    </td>
                    <td className="text-center">
                      <input type="radio" defaultChecked={true} />
                    </td>
                  </React.Fragment>
                );
              } else if (toner_for_wtb.stock > consumable_request.wtb) {
                radioButtonWtb = (
                  <React.Fragment>
                    <td className="text-center">
                      <input type="radio" defaultChecked={true} />
                    </td>
                    <td className="text-center">
                      <input type="radio" disabled defaultChecked={false} />
                    </td>
                  </React.Fragment>
                );
              } else {
                radioButtonWtb = (
                  <React.Fragment>
                    <td className="text-center">
                      <input type="radio" disabled />
                    </td>
                    <td className="text-center">
                      <input type="radio" disabled />
                    </td>
                  </React.Fragment>
                );
              }
        } else {
            radioButtonWtb = (
                <React.Fragment>
                  <td className="text-center">
                    <input type="radio" disabled />
                  </td>
                  <td className="text-center">
                    <input type="radio" disabled />
                  </td>
                </React.Fragment>
              );
        }
        

        if (this.state.displayInternalNote) {
          displayModal = (
            <Modal
              onclose={() => this.setState({ displayInternalNote: false })}
              title="Interna Note"
              modalclass="modal display-block"
            >
              <div className="modal-body">
                <div className="form-group">
                  <textarea
                    defaultValue={ticket_origin.internal_note}
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    className="form-control"
                  />
                </div>
              </div>
            </Modal>
          );
        } else {displayModal = "";}

        if (
          this.state.displayMeterReading &&
          this.state.listMeterReading.length > 0
        ) {
          displayModal = (
            <Modal
              onclose={() => this.setState({ displayMeterReading: false })}
              title="Meter Reading"
              modalclass="modal display-block"
            >
              <div className="modal-body">
                <table className="table table-bordered table-xs">
                  <thead>
                    <tr>
                      <th>Reading Date</th>
                      <th> Description </th>
                      <th>Reading</th>
                      <th> Units </th>
                      <th> Reason Code </th>
                    </tr>
                  </thead>
                  <tbody>
                    {listMeterReading.map(meter => {
                      return (
                        <tr key={Math.random() * 10}>
                          <td>
                            {moment(meter.reading_date).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td> {meter.description} </td>
                          <td> {meter.reading} </td>
                          <td> {meter.units} </td>
                          <td> {meter.reason_code} </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Modal>
          );
        } 

        if (
          this.state.displayMeterReading &&
          this.state.listMeterReading.length < 1
        ) {
          displayModal = (
            <Modal
              onclose={() => this.setState({ displayMeterReading: false })}
              title="Meter Reading"
              modalclass="modal display-block"
            >
              <div className="modal-body text-center">
                <h4> Meter reading not available </h4>
              </div>
            </Modal>
          );
        } 

        if (
          this.state.displayHistoryToner &&
          this.state.listHistoryTonerSupply.length > 0
        ) {
          displayModal = (
            <Modal
              onclose={() => this.setState({ displayHistoryToner: false })}
              title="Toner Supply History"
              modalclass="modal display-block"
            >
              <div className="modal-body">
                <table className="table table-bordered table-xs">
                  <thead>
                    <tr>
                      <th> Date </th>
                      <th> Delivery Note Number</th>
                      <th> Description </th>
                      <th> Part Number </th>
                      <th> Quantity </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key={Math.random() * 10}>
                      <td> 12-07-2019 </td>
                      <td> Delivery Number </td>
                      <td> Description </td>
                      <td> Part Number </td>
                      <td> Quantity </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Modal>
          );
        } 

        if (
          this.state.displayHistoryToner &&
          this.state.listHistoryTonerSupply.length < 1
        ) {
          displayModal = (
            <Modal
              onclose={() => this.setState({ displayHistoryToner: false })}
              title="Toner Supply History"
              modalclass="modal display-block"
            >
              <div className="modal-body text-center">
                <h4> Meter History Toner Not available </h4>
              </div>
            </Modal>
          );
        } 


        modalMachine = (
            <div
          className={
            this.state.showMachine2
              ? "modal display-block"
              : "modal display-none"
          }
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <ModalHeader
                onClick={this.hideModal2}
                onClick={this.hideModal2}
              />
              <hr />

              <div className="modal-body">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    Tracking Process of Ticket
                  </div>
                  <div className="table-responsive">
                    <table className="table table-xs table-bordered">
                      <thead>
                        <tr>
                          <th>Date Time</th>
                          <th>Description</th>
                          <th>Action By</th>
                          <th>Department</th>
                          <th>Assign to Department</th>
                          <th>Received By</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ticket_tracking.map(tic => (
                          <tr key = {tic.id}>
                            <td>{tic.created_at}</td>
                            <td>{tic.description}</td>
                            <td>
                              {tic.action_by.id}
                              {tic.action_by.fullname}
                            </td>
                            <td>{tic.action_department.name}</td>
                            <td>{tic.received_department.name}</td>
                            <td>
                              {tic.received_by.fullname}(
                              {tic.received_by.username})
                            </td>
                            <td>{tic.ticket_status.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-9" />
              </div>
            </div>
          </div>
        </div>
        );

        selectTonerModal = (
            <div className="modal fade" id="modal-add">
            <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                >
                    &times;
                </button>
                <h4 className="modal-title"> Please Choose Part Code </h4>
                </div>
                <div className="modal-body">
                <table className="table table-bordered table-xs">
                        <thead>
                            <tr>
                                <th>Part No</th>
                                <th> Name </th>
                                <th>Price</th>
                                <th width="10"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentToners.map(data => {
                                                
                                return(
                                    <tr key={Math.random() * 10}>
                
                                        <td>{data.part_number}</td>
                                        <td>{data.description}</td>
                                        <td>{data.price}</td>
                                        <td> <button type="button" data-dismiss="modal" className="btn btn-primary" onClick = {this.selectToner.bind(this, data)}> Select </button> </td>
                                    </tr>
                                );
                            })}
                        
                        </tbody>
                    </table>
                    <div className="panel-footer form-xs">
                        <Pagination
                        totalRecords={totalToners}
                        pageLimit={4}
                        pageNeighbours={1}
                        onPageChanged={this.onPageChanged}
                        />
                    </div>
                </div>
                <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-danger pull-right"
                    data-dismiss="modal"
                >
                    Close
                </button>
                
                </div>
            </div>
            </div>
            </div>
        );

        kiri = (
           <div className="col-md-6" id="react-no-print">  
            <OriginatorField department={originator_department.name} created={data.created_at} fullname = {originator.fullname} /> 
                                     
             <ContactDetail contact = {contact_detail} />
             <CustomerInformation 
                machine = {machine}
                machine_customer_detail_customer = {machine_customer_detail_customer}
                machine_model = {machine_model}
                machine_customer = {machine_customer}
                machine_block_by = {machine_block_by}
                customer_sales = {customer_sales}
                machine_contract = {machine_contract}
             />
              <div className="text-right">
                  <button className="btn btn-warning btn-sm" type="submit">TICKET IN PROGRESS</button>
                  <button className="btn btn-default btn-sm" type="submit">CUSTOMER HISTORY</button>
              </div>
            </div>  
        );

         kanan = (
            <div className="col-md-6" >


          <div className="panel panel-default">
              <div className="panel-heading">
                Ticket Number : {data.ticket_number ? data.ticket_number : ""}
              </div>
              <div className="panel-body">
                <div className="form form-xs">
                  <div
                    className="form-inline form-group"
                    style={{ display: "flex" }}
                  >
                    <label style={{ width: "40px" }}>From </label>
                    <input
                      type="text"
                      className="form-control"
                      value={action_by.fullname ? action_by.fullname : ""}
                      style={{ width: "100px" }}
                      disabled
                    />
                    <label style={{ width: "15px", textAlign: "left" }}>
                      To
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={received_by.fullname ? received_by.fullname : ""}
                      style={{ width: "100px" }}
                      disabled
                    />
                    <label
                    className="col-sm-1"
                      style={{
                        textAlign: "left",
                        width: "90px",
                        fontSize: "10px"
                      }}
                    >
                      Requirement Process
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      // value={ticket_type.name ? ticket_type.name : ""}
                      value="Assign CE"
                      style={{ width: "80px" }}
                      disabled
                    />
                    <label
                      style={{
                        textAlign: "left",
                        width: "5px"
                      }}
                    >
                    </label>
                    <button
                      className="btn btn-default"
                      style={{ width: "100px" }}
                      onClick={this.showModalMachine2}
                    >
                      Track Ticket
                    </button>
                  </div>
                  <div className="col-md-7">
                    <input
                      type="checkbox"
                      name="radio-2"
                      onChange={(event) => this.setState({date: moment()}) }
                    />
                    Approved by Credit Control
                  </div>
                  <div className="form-group form-inline col-sm-6">
                    <label>Date Time</label>{" "}
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        value={
                          this.state.date
                            ? moment(this.state.date).format(
                                "YYYY-MM-DD hh:mm"
                              )
                            : ""
                        }
                        disabled
                      />

                      <span className="input-group-addon">
                        <i className="fa fa-calendar" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
          </div>


            <div className="panel panel-default">
                <div className="panel-heading">Consumable Requested</div>
                <div className="panel-body">
                    <div className="form form-xs">
                    <ConsumableRequested 
                        request_c = {consumable_request.c}
                        request_y = {consumable_request.y}
                        request_m = {consumable_request.m}
                        request_k = {consumable_request.k}
                        request_m = {consumable_request.m}
                        request_stappler = {consumable_request.stapler}
                        request_wtb = {consumable_request.wtb}
                    />
                        <div className="form-group">
                            <a 
                                className="btn btn-default"
                                style={{marginLeft: "5px"}}
                                onClick={() => this.setState({displayInternalNote: true})}
                            >
                                View Internal Note
                            </a>
                            <a 
                                className="btn btn-default"
                                style={{marginLeft: "5px"}}
                                onClick={this.meterReading}
                            >
                                History Meter Reading
                            </a>
                            <a 
                                className="btn btn-default"
                                style={{marginLeft: "5px"}}
                                onClick={this.historyTonerSupply}
                            >
                                History Toner Supply
                            </a>
                        </div>
                        <div className="row row-xs">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Remarks</label>
                                    <textarea name="remarks" id="" cols="30" rows="3" className="form-control" value = {this.state.remarks} onChange = {this.onChange}> </textarea>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Deliver </label>
                                    <textarea name="delivery_to" id="" cols="30" rows="3" className="form-control" value = {this.state.delivery_to} onChange = {this.onChange}> </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="panel panel-default">
                <div className="panel-heading">Fulfillment Process</div>
                <table className="table table-bordered table-xs">
                    <tbody>
                    <tr>
                        <th width="50">Part No</th>
                        <th>Code</th>
                        <th>Quantity</th>
                        <th>Description</th>
                        <th>Price</th>
                        <th>Stock in Hands</th>
                        <th>Fulfill</th>
                        <th>Out of Stock</th>
                    </tr>
                    <tr>
                        <td><a data-toggle="modal" data-target="#modal-add" className="btn btn-primary btn-xs btn-block" onClick={() => this.selectFullfilment('c_code')}>Select</a></td>
                        <td>C</td>
                        <td>{consumable_request.c}</td>
                        <td> {toner_for_c.description} </td>
                        <td> {toner_for_c.price} </td>
                        <td> {toner_for_c.stock} </td>
                        {radioButtonC}
                    </tr>
                    <tr>
                        <td><a data-toggle="modal" data-target="#modal-add" className="btn btn-primary btn-xs btn-block" onClick={() => this.selectFullfilment('y_code')}>Select</a></td>
                        <td>Y</td>
                        <td>{consumable_request.y}</td>
                        <td> {toner_for_y.description} </td>
                        <td> {toner_for_y.price} </td>
                        <td> {toner_for_y.stock} </td>
                        {radioButtonY}
                    </tr>
                    <tr>
                        <td><a data-toggle="modal" data-target="#modal-add" className="btn btn-primary btn-xs btn-block" onClick={() => this.selectFullfilment('m_code')}>Select</a></td>
                        <td>M</td>
                        <td>{consumable_request.m}</td>
                        <td> {toner_for_m.description} </td>
                        <td> {toner_for_m.price} </td>
                        <td> {toner_for_m.stock} </td>
                        {radioButtonM}
                    </tr>
                    <tr>
                        <td><a data-toggle="modal" data-target="#modal-add" className="btn btn-primary btn-xs btn-block" onClick={() => this.selectFullfilment('k_code')}>Select</a></td>
                        <td>K</td>
                        <td>{consumable_request.k}</td>
                        <td> {toner_for_k.description} </td>
                        <td> {toner_for_k.price} </td>
                        <td> {toner_for_k.stock} </td>
                        {radioButtonK}
                    </tr>
                    <tr>
                        <td><a data-toggle="modal" data-target="#modal-add" className="btn btn-primary btn-xs btn-block" onClick={() => this.selectFullfilment('stappler_code')}>Select</a></td>
                        <td>STAPLER</td>
                        <td> {consumable_request.stapler} </td>
                        <td> {toner_for_stappler.description} </td>
                        <td> {toner_for_stappler.price} </td>
                        <td> {toner_for_stappler.stock} </td>
                        {radioButtonStappler}
                    </tr>
                    <tr>
                        <td><a data-toggle="modal" data-target="#modal-add" className="btn btn-primary btn-xs btn-block" onClick={() => this.selectFullfilment('wtb_code')}>Select</a></td>
                        <td>WTB</td>
                        <td> {consumable_request.wtb} </td>
                        <td> {toner_for_wtb.description} </td>
                        <td> {toner_for_wtb.price} </td>
                        <td> {toner_for_wtb.stock} </td>
                        {radioButtonWtb}
                    </tr>
                    </tbody>
                </table>
                
            </div>
            <div className="panel panel-default">
                <div className="panel-heading">Create Sales Order</div>
                <div className="panel-body">
                    <div className="form form-xs">
                        <div className="form-group">
                            <label className="control-label">Ship to Address</label>
                            <input type="text" className="form-control" defaultValue={ machine_customer.ship_to_address} disabled />
                        </div>
                        <div className="form-group">
                            <label className="control-label">Ship to Address 2</label>
                            <input type="text" className="form-control" disabled />
                        </div>
                        <div className="row row-xs">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Ship to Contact</label>
                                    <input type="text" className="form-control" defaultValue = {machine_customer.ship_to_contact_name} disabled  />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Ship to City</label>
                                    <input type="text" className="form-control" defaultValue = {machine_customer.ship_to_city} disabled />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Ship to Postcode</label>
                                    <input type="text" className="form-control" defaultValue={machine_customer.ship_to_postcode} disabled />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Sales Order No</label>
                                    <input type="text" className="form-control" disabled />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="control-label">Phone</label>
                                    <input type="text" className="form-control" defaultValue={machine_customer.ship_to_contact_phone} disabled />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Ship to Country</label>
                                    <input type="text" className="form-control" disabled />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Stock Room</label>
                                    <input type="text" className="form-control" defaultValue="0214" disabled />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Status</label>
                                    <select name="" id="" className="form-control">
                                        <option defaultValue="">OPEN</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="pull-right" id="react-no-print">
                <div className="pull-right">
                  <input className="btn btn-default btn-sm" type="button" onClick={this.printContent} value = "PRINT" />
                  <Link to="/ticket-management"> <button className="btn btn-danger btn-sm" type="submit">SAVE</button></Link>
                </div>
            
                <br />
                <br />
                <button className="btn btn-warning btn-sm" type="onsubmit" onClick={this.onSubmitOrder}>PROCESS NEXT STEP AND SUBMIT</button>
              
            </div>
        </div>
       
        );
      }
    }

    return (
    <div>
      <div id="app">
        <div id="react-no-print">
          <Navbar
            information="TICKET MANAGEMENT"
            detail="Process Ticket - Consumables"
          />

          <Sidebar information="TicketManagement" />
        </div>

        {modalMachine}

        <br />
        <div id="main" className="main-xs">
        {flash}
        <br />
          <div className="row row-xs">
            <div>
              {kiri ? (
                kiri
              ) : (
                <i
                  className="fa fa-spinner fa-spin fa-3x"
                  style={{
                    opacity: "0.3",
                    top: "50%",
                    left: "50%",
                    position: "absolute"
                  }}
                />
              )}
            </div>
            <div>{kanan ? kanan : ""}</div>
          </div>
        </div>
        <Footer />
      </div>

      {selectTonerModal}
      {displayModal}
      <NotificationContainer/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    consumable: state.consumable,
    list_toner: state.list_toner,
    ticket_tracking: state.trackingReducer
  };
}

export default connect(
  mapStateToProps,
  { proccessConsumable, createSalesOrder, ticketTracking, listToner }
)(Consumable);
