import React, { Component } from "react";
import { connect } from "react-redux";
import Pagination from "../../common/Pagination";
import PaginationReactTable from "../../common/PaginationReactTable";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { Link, Route } from "react-router-dom";
import axios from "../../../axios-env";

import "../ticket/reactDatePicker.css";
import "../ticket/pagination.css";
import moment from "moment";
import DatePicker, { subDays } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import isEmpty from "../../../validation/is-empty";
import { setSearhCustomerBySerial } from "../../../actions/searchSerialAction";

// import "../../../App.css";
import Modal from "../../../ui/Modal/Modal";

let page = 1;
const loading = (
  <div>
    <i className="fa fa-spinner fa-spin fa-2x" />
  </div>
);

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      list_toner: [],
      currentHistory: [],
      pages: [],
      currentPage: null,
      totalPages: null,
      start_date: "",
      end_date: "",
      loading: false,
      selectShowHistory: false,
      dataDisplayHistory: {}
    };
  }

  handleStartDate = start_date => {
    this.setState({ start_date });
    setTimeout(
      function() {
        // this.props.ticketInbox(jwtToken);
        this.refReactTable.fireFetchData();
      }.bind(this),
      1000
    );
  };
  handleEndDate = end_date => {
    this.setState({ end_date }, () =>
      console.log("end date", this.state.end_date)
    );
    setTimeout(
      function() {
        // this.props.ticketInbox(jwtToken);
        this.refReactTable.fireFetchData();
      }.bind(this),
      1000
    );
  };

  historySelect = item => {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`
      }
    };
    console.log("history Select", item.id);
    axios
      .get(
        `/api/v1/customers/document-ticket?ticket_origin_id=${item.id}`,
        // data,
        headers
      )
      .then(res => {
        console.log("respons from click history", res.data.data);
      });
    this.setState({ selectShowHistory: true, dataDisplayHistory: item });
  };

  render() {
    // let filtered = this.props.serial.slice();
    const { start_date, end_date, dataDisplayHistory } = this.state;
    if (!isEmpty(this.props.serial)) {
      var totalHistory = this.props.serial.length;
    }

    let columns = [
      {
        Header: "DATE",
        columns: [
          {
            accessor: "created_at",
            show: false
          },

          {
            sortable: false,
            filterable: false,
            Cell: ({ row }) => moment(row.created_at).format("L")
          }
        ]
      },
      {
        Header: "TIME",
        columns: [
          {
            accessor: "created_at",
            show: false
          },
          {
            sortable: false,
            filterable: false,
            Cell: ({ row }) => moment(row.created_at).format("H:m")
          }
        ]
      },
      {
        Header: "Original Location",
        columns: [
          {
            accessor: "-",
            show: false
          },
          {
            sortable: false,
            filterable: false
          }
        ]
      },
      {
        Header: "Service Order Temp No",
        columns: [
          {
            accessor: "-",
            show: false
          },
          {
            sortable: false,
            filterable: false
          }
        ]
      },
      {
        Header: "Sales Order Temp No",
        columns: [
          {
            accessor: "-",
            show: false
          },
          {
            sortable: false,
            filterable: false
          }
        ]
      },
      {
        Header: "Action Taken",
        columns: [
          {
            accessor: "action_taken",
            show: true
          }
        ]
      },
      {
        Header: "Ticket Status",
        columns: [
          {
            accessor: "ticket_status.name",
            show: true
          }
        ]
      },
      {
        Header: "Action",
        columns: [
          {
            accessor: "id",
            show: false
          },
          {
            sortable: false,
            filterable: false,
            Cell: row => {
              return (
                <div style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    className="btn btn-primary btn-xs"
                    onClick={this.historySelect.bind(this, row.original)}
                  >
                    SELECT
                  </button>
                </div>
              );
            }
          }
        ]
      }
    ];

    if (totalHistory === 0) return null;
    let display;
    if (this.state.selectShowHistory) {
      display = (
        <Modal
          onclose={() => this.setState({ selectShowHistory: false })}
          title="Detail History [No design what should be list here]"
          modalclass="modal display-block"
        >
          <div className="modal-body">
            <table className="table table-bordered table-xs">
              <thead>
                <tr>
                  <th>Part No</th>
                  <th> Action Taken </th>
                  <th> Status Ticket </th>
                </tr>
              </thead>
              <tbody>
                <tr key={Math.random() * 10}>
                  <td />
                  <td> {dataDisplayHistory.action_taken} </td>
                  <td> {dataDisplayHistory.ticket_status.name} </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal>
      );
    }

    return (
      <React.Fragment>
        {display}
        <div className="panel panel-default">
          <div className="panel-heading">History </div>
          <div className="p-10">
            <div className="pull-left form-inline">
              <DatePicker
                className="form-control "
                selected={this.state.start_date}
                style={{ width: "130px" }}
                onChange={this.handleStartDate}
                selectsStart
                startDate={this.state.start_date}
                endDate={this.state.end_date}
                placeholderText="Start date"
                isClearable={this.state.start_date}
                // name="start_date"
              />
              <label>-</label>
              <DatePicker
                className="form-control "
                selected={this.state.end_date}
                onChange={this.handleEndDate}
                style={{ width: "130px" }}
                selectsEnd
                startDate={this.state.start_date}
                endDate={this.state.end_date}
                minDate={this.state.start_date}
                placeholderText="End date"
                isClearable={this.state.end_date}
                // name="end_date"
              />
              <button className="btn btn-default btn-sm">Filter</button>
            </div>
            <div className="clearfix" />
          </div>

          <ReactTable
            columns={columns}
            data={this.state.data}
            previousText="<<"
            nextText=">>"
            PaginationComponent={PaginationReactTable}
            resizable={true}
            key="id"
            minRows={1}
            noDataText="No Matches Found"
            onFetchData={(state, instance) => {
              this.forceUpdate();
              // show the loading overlay
              this.setState({ loading: true });
              // fetch your data
              const headers = {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwtToken")}`
                }
              };

              const machine = this.props.serial
                ? this.props.serial[0].machine_customer.id
                : "";

              const st_dt = this.state.start_date
                ? moment(this.state.start_date).format("YYYY-MM-DD 00:00:00")
                : "";
              const en_dt = this.state.end_date
                ? moment(this.state.end_date)
                    .add(1, "days")
                    .format("YYYY-MM-DD 00:00:00")
                : "";
              axios
                .get(
                  `/api/v1/customers/history-ticket?machine_customer_id=${machine}&from=${st_dt}&to=${en_dt}`,
                  // data,
                  headers
                )

                .then(res => {
                  const mod = res.data.data.total % 5;
                  const addmod = mod > 0 ? 1 : 0;

                  console.log("respons from paginate ", res);

                  this.setState({
                    data: res.data.data,
                    pages:
                      res.data.data.length === 0
                        ? 1
                        : (res.data.data.length - mod) / 5 + addmod,
                    loading: false
                  });
                });
            }}
            ref={refReactTable => {
              this.refReactTable = refReactTable;
            }}
            defaultPageSize={5}
          />
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    serial: state.searchSerial.serial
  };
}
export default connect(
  mapStateToProps,
  { setSearhCustomerBySerial }
)(History);
