export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const GET_USERS = "GET_USERS";
// export const LIST_FAULT_AREA = "LIST_FAULT_AREA";
// export const LIST_PROBLEM = "LIST_PROBLEM";

export const XXX_DATA = "XXX_DATA";
export const TICKET_HISTORY_DATA = "TICKET_HISTORY_DATA";
export const TICKET_INBOX_DATA = "TICKET_INBOX_DATA";
// export const TICKET_INBOX_HELPDESK_DATA = "TICKET_INBOX_HELPDESK_DATA";

export const LIST_USER = "LIST_USER";
// export const LIST_USER_CE = "LIST_USER_CE";

export const DETAIL_HISTORY = "DETAIL_HISTORY";
export const DETAIL_INBOX = "DETAIL_INBOX";
export const PROSES_TICKET_HELPDESK = "PROSES_TICKET_HELPDESK";
export const SAVE_HELP_DESK = "SAVE_HELP_DESK";

export const PROSES_TICKET_ASSIGN_CE = "PROSES_TICKET_ASSIGN_CE";
export const SAVE_OTP = "SAVE_OTP";
export const SAVE_ASSIGN = "SAVE_ASSIGN";

export const DATA_CREATE_USER = "DATA_CREATE_USER";
export const SAVE_USER = "SAVE_USER";
export const RESET = "RESET";

export const GET_ORIGINATION_CUSTOMER = "GET_ORIGINATION_CUSTOMER";
export const DELETE_ORIGINATION_CUSTOMER = "DELETE_ORIGINATION_CUSTOMER";

export const ADD_INSTALLATION_TICKET = "ADD_INSTALLATION_TICKET";

export const TICKET_TRACKING = "TICKET_TRACKING";

export const DETAIL_CONSUMABLE = "DETAIL_CONSUMABLE";
export const LIST_TONER_CONSUMABLE = "LIST_TONER_CONSUMABLE";
export const CREATE_SALES_ORDER = "CREATE_SALES_ORDER";
export const CALENDAR_SEARCH = "CALENDAR_SEARCH";
export const MAPING = "MAPING";
export const EDIT_USER = "EDIT_USER";
export const UPDATE = "UPDATE";

export const ADD_FLASH = "ADD_FLASH";
export const DELETE_FLASH = "DELETE_FLASH";
export const LIST_PM_CUSTOMER = "LIST_PM_CUSTOMER";
export const EDIT_PM_CUST = "EDIT_PM_CUST";
export const UPDATE_PM_CUST = "UPDATE_PM_CUST";
export const LIST_CUSTOMER_ADD = "LIST_CUSTOMER_ADD";
export const STORE_PM_CUST = "STORE_PM_CUST";
export const DEL_PM_CUST = "DEL_PM_CUST";
export const UPDATE_DEFAULT_DAYS = "UPDATE_DEFAULT_DAYS";

export const LIST_PM_MACHINE = "LIST_PM_MACHINE";
export const LIST_MACHINE_ADD = "LIST_MACHINE_ADD";
export const STORE_PM_MACH = "STORE_PM_MACH";
export const EDIT_PM_MACH = "EDIT_PM_MACH";
export const UPDATE_PM_MACH = "UPDATE_PM_MACH";
export const DEL_PM_MACH = "DEL_PM_MACH";
export const SET_DELETE_LOGIN = "SET_DELETE_LOGIN";
export const LIST_UNASSIGN = "LIST_UNASSIGN";
export const DEL_MESSAGE = "DEL_MESSAGE";
export const UNASSIGN_CE = "UNASSIGN_CE";

export const GET_SPR = "GET_SPR";
export const CHECK_STOCK = "CHECK_STOCK";
export const GET_SPR_RECON = "GET_SPR_RECON";

export const HOLIDAY = "HOLIDAY";
export const ADD_HOLIDAY = "ADD_HOLIDAY";
export const EDIT_HOLIDAY = "EDIT_HOLIDAY";
export const DELETE_HOLIDAY = "DELETE_HOLIDAY";
export const DEL_EDIT_HOLIDAY = "DEL_EDIT_HOLIDAY";

export const WORKING = "WORKING";
export const DEL_INBOX = "DEL_INBOX";
export const DEL_OTP = "DEL_OTP";
export const DEL_ASSIGN = "DEL_ASSIGN";
export const DEL_ASSIGN_HELP = "DEL_ASSIGN_HELP";
export const ORDER_DB = "ORDER_DB";
export const ORDER_WSP = "ORDER_WSP";
export const PRINT_S = "PRINT_S";
export const UPDATE_W = "UPDATE_W";

export const QUOTATION_PDF_SAVE = "QUOTATION_PDF_SAVE";
export const QUOTATION_PDF_PURGE = "QUOTATION_PDF_PURGE";

export const POST_SUB = "POST_SUB";
export const PRINT_SPR = "PRINT_SPR";
export const LOCATION_CE = "LOCATION_CE";
