import {
  LIST_PM_MACHINE,
  LIST_MACHINE_ADD,
  STORE_PM_MACH,
  EDIT_PM_MACH,
  UPDATE_PM_MACH,
  DEL_PM_MACH,
  DEL_MESSAGE
} from "../actions/types";

const initialState = {
  list_pm_machine: [],
  pm_default: [],
  machines: [],
  machine: [],
  customer: [],
  resUpdatePmMachine: [],
  resDelPmMachine: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_PM_MACHINE:
      return {
        ...state,
        list_pm_machine: action.payload
      };

    //   case UPDATE_PM_CUST:
    //     return {
    //       ...state,
    //       resUpdateDays: action.payload
    //     };
    case LIST_MACHINE_ADD:
      return {
        ...state,
        pm_default: action.pm_default,
        machines: action.machines
      };
    case EDIT_PM_MACH:
      return {
        ...state,
        data_mc: action.payload,
        machine: action.machine,
        customer: action.customer
      };

    case STORE_PM_MACH:
      return {
        ...state,
        resStorePmMachine: action.payload
      };

    case DEL_PM_MACH:
      return {
        ...state,
        resDelPmMachine: action.payload,
        list_pm_machine: state.list_pm_machine.filter(
          det => det.id !== action.data_send
        )
      };
    case UPDATE_PM_MACH:
      return {
        ...state,
        resUpdatePmMachine: action.payload,
        // list_pm_machine: { ...state.pm_default, days: action.aaa.days },
        list_pm_machine: state.list_pm_machine.map(
          contact =>
            contact.id === action.data_send.pm_machine_id
              ? (contact = {
                  ...contact,
                  days: action.data_send.days,
                  dates: action.data_send.dates
                })
              : contact
          // console.log("DDD", contact.id, action.aaa)
        )
      };
    case DEL_MESSAGE:
      return {
        ...state,
        resUpdatePmMachine: action.payload,
        resDelPmMachine: action.payload,
        resStorePmMachine: action.payload
      };

    default:
      return state;
  }
}
