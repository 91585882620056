import React, { Component } from 'react';
import Container from '../../../layout/Container';
//import TextAreaInput from "../../../common/TextAreaInput";

import Flash from '../../../common/FlashMessage';
import UserOriginator from './UserOriginator';
import ContactDetail from './ContactDetail';
//import CustomerInformation from './CustomerInformation';
import LeftOriginationTicket from "./Left";
//import AdditionalNotes from './AdditionalNotes';
import SpanError from "../../../common/SpanError";

import CallInformation from './CallInformation';
import ServiceOrder from './ServiceOrder';
import LastDeliveryModal from './lastDeliveryModal';
import AdditionalNotes from './additionalNotes';

import axios from '../../../../axios-env'
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import './Origination.css';
const initialConsumable = {
  c: "",
  m: "",
  y: "",
  k: "",
  stapler: "",
  wtb: "",
  is_remark: 0,
  remark: "",
  is_delivery_to: 0,
  delivery_to: "",
  is_urgent_delivery: 1,
}

const initialNote = {
    inbound_type: 'urgent',
    From: localStorage.iduser,
    name: localStorage.username,
    subject: '',
    cover_note: '',
    contact_note: '',
    customer_information: '',
    ticket_tracking_id: ''
  }

const initialState = {
  users: {
    id: null,
    username: null,
    level: null
  },
  showNote: 0,
  sendNote: 0,
  note: initialNote,
  date_time_created: moment().format('LLL'),
  is_new_contact: 1,
  date_of_contact: new Date(),
  gender: 'Mr',
  contact_name: "",
  channel_of_contact: '',
  type_channel_of_contact: 'Telephone',

  // Right
  contact_note: "",
  action_taken: "",
  internal_note: "",
  machine_types: [],
  service_code: [],
  fault_comment: "",
  machine_status_id: 1,

  //next process
  ticket_no : "123",

  service_type: "",
  is_service: "",
  is_consumable: 0,
  disablesc: true,
  service_code_id: "",
  machine_type_id: "",
  machine_customer_id: "",
  customer_id: "",
  ticket_status_id: 1,
  consumable_request: initialConsumable,
  ticket_types: [],//[{id: 5}, {id: 6}],
  //machine_type: [],
  file: "",
  filename: "",
  waive: 0,
  displayDeliveryModal: 0,
  lastDeliveryData: "",
  customerHistory: "",
  validationTTypes: false
}

class Origination extends Component {
  constructor(props){
    super(props);
    this.state = initialState
    this.handleChange = this.handleChange.bind(this)
    this.validator = new SimpleReactValidator({autoForceUpdate: this})
  }

  componentDidMount(){
    axios.get('/api/v1/ticket-management/origination',{
      headers: {
        Authorization: 'Bearer ' + localStorage.jwtToken
      }
    }).then(res => {
      this.setState({
        machine_types: res.data.data.machine_types,
        service_code: res.data.data.service_code
      });
    }).catch(error => {
      this.flashMessage('error', 'Failed to Load Master Data');
    });
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });
  onChangeInt = e => this.setState({ [e.target.name]: +e.target.value });
  onChangeCheckbox = e => this.setState({[e.target.name]: +e.target.checked});  
  handleChange = date => this.setState({ date_of_contact: date});

  isServiceHandler = e => {
    //e.preventDefault();
    if(e.target.value === '1'){
      this.setState({is_service: e.target.value, service_code_id: 5, disablesc: false});
    }else{
      this.setState({is_service: e.target.value});
    }
  }

  toggleNoteHandler = (e) => {
    e.preventDefault();
    this.setState({showNote: !this.state.showNote});
  }

  changeNoteHandler = (e) => {
    e.preventDefault();
    const updatedNote = {...this.state.note};
    const updatedName = e.target.name;
    updatedNote[updatedName] = e.target.value;
    this.setState({note: updatedNote});
  }
  
  saveNoteHandler = (e) => {
    e.preventDefault();

    if (this.validator.fieldValid('subject') || this.validator.fieldValid('cover_note') || this.validator.fieldValid('contact_note') || this.validator.fieldValid('customer_information')) {
      //this.flashMessage('success', 'Notes Will Be Saved After Origination Ticket Created');
      this.setState({showNote: 0, sendNote: 1});
    }else {
      this.validator.showMessageFor('subject');
      this.forceUpdate();
    }
  }

  changeConsumableInputHandler = (e) => {
    const updatedCon = {...this.state.consumable_request};
    // if(e.target.name === 'c' || e.target.name === 'm' || e.target.name === 'y' || e.target.name === 'k'  || e.target.name === 'stapler' || e.target.name === 'wtb' ){
    //   if(true){
    //     if(e.target.value <= 0){
    //       updatedCon[e.target.name] = 0;
    //     }else{
    //       updatedCon[e.target.name] = +e.target.value;
    //     }
    //   }else{}
    // }else{
    updatedCon[e.target.name] = e.target.value;
    //}
    this.setState({consumable_request: updatedCon});
  }

  // isConsumableHandler = (e) => {
  //   if(+e.target.checked){
  //     const prevState = this.state.ticket_types;
  //     const updatedState = { id: +e.target.value }
  //     this.setState({[e.target.name]: +e.target.checked, ticket_types: [...prevState, updatedState]});
  //   }else{
  //     this.setState({ [e.target.name]: +e.target.checked });
  //   }
  // }

  changeConsumableCheckboxHandler = (e) => {
    const updatedCon = {...this.state.consumable_request};
    updatedCon[e.target.name] = +e.target.checked;
    this.setState({consumable_request: updatedCon});
  }

  changeUrgentRadio = e => {
    const updatedCon = {...this.state.consumable_request};
    updatedCon['is_urgent_delivery'] = !+updatedCon['is_urgent_delivery']
    this.setState({consumable_request: updatedCon});
  }

  lastDeliveryHandler = e => {
    e.preventDefault();
    //this.setState({lastDeliveryModal: !this.state.lastDeliveryModal});
    if(this.state.machine_customer_id != ''){
      axios.get('/api/v1/ticket-management/origination/last-delivery?machine_customer_id=' + this.state.machine_customer_id,{
        headers: {
          Authorization: 'Bearer ' + localStorage.jwtToken
        }
      }).then(res => {
        this.setState({ lastDeliveryData: res.data.data, lastDeliveryModal: !this.state.lastDeliveryModal});
      }).catch(error => {
        this.flashMessage('error', 'Failed to Get Last Delivery Info');
      });
    }else {
      this.flashMessage('error', 'Machine Customer ID Not Found, Failed to Get Last Delivery Info');
    }
  }
  closeDeliveryHandler = e => {
    this.setState({lastDeliveryModal: !this.state.lastDeliveryModal});
  }
  
  ticketTypeHandler = (e) => {
    const prevState = this.state.ticket_types;
    const ticketLength = this.state.ticket_types.length;
    if(e.target.value == 3){this.setState({is_consumable: !this.state.is_consumable, consumable_request: initialConsumable})}
    if(prevState.some(obj => obj.id == e.target.value)){
      if(prevState == 1){this.setState({validationTTypes: true})}
      const updatedState = prevState.filter(pr => pr.id != e.target.value);
      this.setState({ticket_types: updatedState});
    }else{
      this.setState({ticket_types: [...prevState, {id: +e.target.value}], validationTTypes: false});
    }
  }
  
  changeMachineCustomerHandler = (mcid, cid) => this.setState({machine_customer_id: mcid, customer_id: cid});
  
  changeContactDetail = (name, value) => {
    this.setState({[name]: value})
  }

  flashMessage = (type, message) => {
    this.setState({...this.state, alert_type: type, alert_message: message})
    setTimeout(() => {
      this.setState({...this.state, alert_type: '', alert_message: ''});
    }, 3000)
  };

  removeFlashMessage = e => { e.preventDefault(); this.setState({...this.state, alert_type: '', alert_message: ''}); }

  addFiles = e => {
    e.preventDefault();
    //this.setState({...this.state, file: e.target.files[0]});
    const headers = { headers:{ Authorization: `Bearer ${localStorage.getItem("jwtToken")}`, 'Content-Type': 'multipart/form-data' }};
    let file = e.target.files[0];
    if(file){
      let formData = new FormData();
      formData.append('file', file);

      axios.post('/api/v1/ticket-management/origination/upload-quotation', formData, headers).then(res => {
        const meta = res.data.meta; const data = res.data.data;
        if(meta.code === 200){
          this.setState({filename: res.data.data.filename});
        }else{
          this.flashMessage('error', 'Failed to send quotation');
        }
      }).catch(error => {
        this.flashMessage('error', 'Someting Error');
      });
    }else{
      this.setState({filename: ""});
    }
  }

  uploadQuotationHandler = () => {
    //e.preventDefault();
    if (this.state.file !== '') {
      const headers = { headers:{ Authorization: `Bearer ${localStorage.getItem("jwtToken")}`, 'Content-Type': 'multipart/form-data' }};
      let file = this.state.file;
      let formData = new FormData();
      formData.append('file', file);

      axios.post('/api/v1/ticket-management/origination/upload-quotation', formData, headers).then(res => {
        const meta = res.data.meta; //const data = res.data.data;
        if(meta.code === 200){
          this.setState({filename: res.data.data.filename});
        }else{
          this.flashMessage('error', 'Failed to send quotation');
        }
      }).catch(error => {
        this.flashMessage('error', 'Someting Error');
      });
    }else{
      this.flashMessage('error', 'Failed to send quotation');
    }
  }

  originSubmitHandler = e => {
    if(this.state.ticket_types.length > 0){
      if (this.validator.allValid()) {
        const ticketType = this.state.ticket_types;
        //if(ticketType.some(obj => obj.id == 6) && this.state.waive && this.state.filename){
        const dataPost = this.state;
        axios.post('/api/v1/ticket-management/origination', dataPost,{
          headers: { Authorization: 'Bearer ' + localStorage.jwtToken }
        }).then(res => {
          if(res.data.meta.code == '200'){
            const updNote = {...this.state.note, ticket_tracking: res.data.data.ticket_tracking}
            this.setState({ note: updNote });
            //send Adittional Note
            const updatedNote = {...this.state.note}
            const headers = { headers: { Authorization: `Bearer ${localStorage.getItem("jwtToken")}` }}
            
            if(this.state.sendNote && updatedNote.ticket_tracking.length > 0){
              axios.post('/api/v1/ticket-management/additional-note', updatedNote, headers)
                .then(resnote => {
                  if(resnote.data.meta.code == '200'){
                  }else{
                  }
                }).catch(error => {
                });
            }

            if(this.state.ticket_types.some(obj => obj.id == 1) && res.data.data.ticket_ce_id != 0){
              this.props.history.push('/ticket-management/assignce/'+ res.data.data.ticket_ce_id);
            }else{
              this.setState({...initialState, alert_type: 'success', alert_message: 'New Origination Ticket Saved'});
              setTimeout(() => {
                this.setState({...this.state, alert_type: '', alert_message: ''});
              }, 3000)
            }
          }else{
            this.flashMessage('error', 'Failed to Save Data Origination Ticket');
          }
          //this.props.history.push("/ticket-management");
          //this.flashMessage('success', 'New Origination Ticket Saved');
        }).catch(error => {
          this.flashMessage('error', 'Failed to Save Data Origination Ticket');
        });
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    }else{
      this.setState({validationTTypes: true});
    }
  }
  
  render(){
    let isEmptyQuantity =
        this.state.consumable_request.c == ''  ||
        this.state.consumable_request.m == '' ||
        this.state.consumable_request.y == '' ||
        this.state.consumable_request.k == '' ||
        this.state.consumable_request.stapler == '' ||
        this.state.consumable_request.w == ''
    ;

    let warningQuantity = ''
    if(this.state.is_consumable == true && (+this.state.consumable_request.c + +this.state.consumable_request.m + +this.state.consumable_request.y + +this.state.consumable_request.k + +this.state.consumable_request.stapler + +this.state.consumable_request.wtb < 1)){
        warningQuantity = <SpanError>You need to order min 1 item</SpanError>;
    }
    return (
      <Container pagename="Ticket Management" pagedetailname="Create Origination Ticket" sidebarinfo="TicketManagement">
        <div id="main" className="main-xs">
          {this.state.alert_type ? <Flash type={this.state.alert_type} message={this.state.alert_message} remove={this.removeFlashMessage}/> :''}
          {this.state.lastDeliveryModal ? <LastDeliveryModal dd={this.state.lastDeliveryData} modalclass="modal display-block" onclose={this.closeDeliveryHandler}/> : ''}
          {this.state.showNote ? <AdditionalNotes dnote={this.state.note} modalclass="modal display-block" validator={this.validator}
                                                  onChange={this.changeNoteHandler} onSave={this.saveNoteHandler} onClose={this.toggleNoteHandler}/> : ''}
          <div className="row row-xs">
            <div className="col-md-12 text-right">
              {/* <button className="btn btn-warning btn-sm" type="submit" onClick={this.originSubmitHandler}>Test Submit</button> */}
              <button className="btn btn-warning" data-toggle="modal" data-target="#modal-create-note" onClick={this.toggleNoteHandler}><i className="fa fa-plus"></i> Create Note</button>
            </div>
          </div>
          <br/>
          <div className="row row-xs">
            <div className="col-md-6">
              <form action="create-new-origination-ticket.html">
                 <UserOriginator date={this.state.date_time_created}/>
                <ContactDetail isnew={this.state.isNewContact} doc={this.state.date_of_contact} gender={this.state.gender} cn={this.state.contact_name}
                               activesc={this.is_service_code_active} coc={this.state.channel_of_contact} tcoc={this.state.type_channel_of_contact}
                               validator={this.validator} onChange={this.onChange} hChange={this.handleChange}/>
                {/* <CustomerInformation/> */}
                <LeftOriginationTicket custMachineId={this.changeMachineCustomerHandler} changeContactDetail={this.changeContactDetail} setError={this.flashMessage}/>
                <div className="text-right">
                  <button className="btn btn-warning btn-sm" type="submit">
                    TICKET IN PROGRESS
                  </button>
                  <button className="btn btn-default btn-sm" type="submit">
                    CUSTOMER HISTORY
                  </button>
                </div>
              </form>
            </div>
            <div className="col-md-6">
              <CallInformation cn={this.state.contact_note} at={this.state.action_taken} inote={this.state.internal_note} onChange={this.onChange} validator={this.validator}/>
              <ServiceOrder machinetypes={this.state.machine_types} servicecode={this.state.service_code} fc={this.state.fault_comment} msi={this.state.machine_status_id}
                            disablesc={this.state.disablesc} sci={this.state.service_code_id} mti={this.state.machine_type_id}
                            validator={this.validator} onChange={this.onChange} onChangeInt={this.onChangeInt} onChangeRadio={this.isServiceHandler}/>
              <div className="panel panel-default">
                <div className={this.state.validationTTypes ? 'panel-heading has-error' : 'panel-heading'}>
                  Select Originator do you want to process
                </div>
                <div className="panel-body">
                  <div className="form form-xs">
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="is_consumable" value="3" onChange={this.ticketTypeHandler}/> Consumable Request
                      </label>
                    </div>
                    <fieldset className="consumable-item" disabled={this.state.is_consumable ? '' : 'disabled'}>
                      <table className="table table-bordered table-xs">
                        <tbody>
                          <tr>
                            <th />
                            <th colSpan="4" className="text-center">
                              TONER
                            </th>
                            <th className="text-center">STAPLER</th>
                            <th className="text-center">WTB</th>
                          </tr>
                          <tr>
                            <td />
                            <td className="text-center">C</td>
                            <td className="text-center">M</td>
                            <td className="text-center">Y</td>
                            <td className="text-center">K</td>
                            <td /><td />
                          </tr>
                          <tr>
                            <td>Quantity</td>
                            <td className={this.validator.message('', this.state.consumable_request.c, 'integer') ? 'has-error' : ''}>
                              <input type="text" className="form-control" name="c" value={this.state.consumable_request.c} onChange={this.changeConsumableInputHandler}/>
                            </td>
                            <td className={this.validator.message('', this.state.consumable_request.m, 'integer') ? 'has-error' : ''}>
                              <input type="text" className="form-control" name="m" value={this.state.consumable_request.m} onChange={this.changeConsumableInputHandler}/>
                            </td>
                            <td className={this.validator.message('', this.state.consumable_request.y, 'integer') ? 'has-error' : ''}>
                              <input type="text" className="form-control" name="y" value={this.state.consumable_request.y} onChange={this.changeConsumableInputHandler}/>
                            </td>
                            <td className={this.validator.message('', this.state.consumable_request.k, 'integer') ? 'has-error' : ''}>
                              <input type="text" className="form-control" name="k" value={this.state.consumable_request.k} onChange={this.changeConsumableInputHandler}/>
                            </td>
                            <td className={this.validator.message('', this.state.consumable_request.stapler, 'integer') ? 'has-error' : ''}>
                              <input type="text" className="form-control" name="stapler" value={this.state.consumable_request.stapler} onChange={this.changeConsumableInputHandler}/>
                            </td>
                            <td className={this.validator.message('', this.state.consumable_request.wtb, 'integer') ? 'has-error' : ''}>
                              <input type="text" className="form-control" name="wtb" value={this.state.consumable_request.wtb} onChange={this.changeConsumableInputHandler}/>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {
                        this.validator.message('', this.state.consumable_request.c, 'integer') ||
                          this.validator.message('', this.state.consumable_request.m, 'integer') ||
                          this.validator.message('', this.state.consumable_request.y, 'integer') ||
                          this.validator.message('', this.state.consumable_request.k, 'integer') ||
                          this.validator.message('', this.state.consumable_request.stapler, 'integer') ||
                          this.validator.message('', this.state.consumable_request.wtb, 'integer')
                          ? <SpanError>Consumable quantity must be an integer</SpanError> : ''}
                      {warningQuantity}
                      <button className="btn btn-default" onClick={this.lastDeliveryHandler} disabled={this.state.machine_customer_id == '' ? '' : ''}>Last Delivery</button>
                      <hr/>
                      <div className="form-group">
                        <label className="checkbox-inline" style={{width: "200px"}}>
                          <input type="checkbox" name="is_remark" value={this.state.consumable_request.is_remark} onChange={this.changeConsumableCheckboxHandler}/>
                          Remark
                        </label>
                        <input type="text" className="form-control" name="remark" value={this.state.consumable_request.remark} onChange={this.changeConsumableInputHandler}/>
                      </div>
                      <div className="form-group">
                        <label className="checkbox-inline"  style={{width: "200px"}}>
                          <input type="checkbox" name="is_delivery_to" value={this.state.consumable_request.is_delivery_to} onChange={this.changeConsumableCheckboxHandler}/>
                          Alt. Delivery Info
                        </label>
                        <input type="text" className="form-control" name="delivery_to" value={this.state.consumable_request.delivery_to} onChange={this.changeConsumableInputHandler}/>
                      </div>
                      <div>
                        <label className="radio-inline">Is Urgent Delivery</label>
                        <label className="radio-inline">
                          <input type="radio" name="urgent" value="yes" onChange={this.changeUrgentRadio}
                                 checked={this.state.consumable_request.is_urgent_delivery == 1 ? 'checked' : ''}/> Yes
                        </label>
                        <label className="radio-inline">
                          <input type="radio" name="urgent" valu="no" onChange={this.changeUrgentRadio}
                                 checked={this.state.consumable_request.is_urgent_delivery == 0 ? 'checked' : ''}/> No
                        </label>
                      </div>
                    </fieldset>
                    
                    {/* <div className="form-group nopadding"> */}
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="next_ce" value="1" onChange={this.ticketTypeHandler}/> Assign CE
                      </label>
                    </div>
                    {/* </div> */}
                    {/* <div className="form-group"> */}
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="next_helpdesk" value="9" onChange={this.ticketTypeHandler}/> Help Desk
                      </label>
                    </div>
                    {/* </div> */}
                    <div className="checkbox">
                      <label>
                        <input type="radio" name="type_helpdesk" disabled/> Technical
                        Helpdesk
                      </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <input type="radio" name="type_helpdesk" disabled/> System Support
                        Helpdesk
                      </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <input type="radio" name="type_helpdesk" disabled/> Solution
                        Helpdesk
                      </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="next_quotation" value="6" onChange={this.ticketTypeHandler}/> Quotation
                      </label>
                    </div>
                    <div className="form-inline">
                      <div className="form-group">
                        <input type="file" onChange={this.addFiles}/> 
                      </div>
                      <div className="form-group">
                        <input type="checkbox" name="waive" value={this.state.waive} onChange={this.onChangeCheckbox}/> Waive
                      </div>
                    </div>
                  </div>
                  <div className="process-types">
                    {/* {this.validator.message('ticket_process', this.ticket_types, 'required|min:1,array') ? */}
                    {/*  <SpanError>{this.validator.message('ticket_origination_process', this.ticket_types, 'required|min:1,array')}</SpanError> : ''} */}
                    { this.state.validationTTypes ? <SpanError>You Need Minimal 1 Origination Ticket Process Picked </SpanError> : ''}
                  </div>
                </div>
              </div>
              <p>
                <em>
                  Please take note : Assign CE or Quotation will process on next step
                </em>
              </p>
              <div className="text-left">
                <button className="btn btn-warning btn-sm" type="submit" onClick={this.originSubmitHandler}>
                  PROCESS TO NEXT STEP AND SUBMIT
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

export default Origination;
