import React, { Component } from "react";
import TextInputDisabled from "../../common/TextInputDisabled";

class ContactDetail extends Component {
  render() {
    return (
      <div className="panel panel-default">
        <div className="panel-heading">Contact Details</div>
        <div className="panel-body">
          <div className="form form-xs">
            <div className="row row-xs">
              <div className="col-md-6">
                <TextInputDisabled
                  label="Date/Time Contact"
                  value={this.props.date}
                />
              </div>
              <div className="col-md-6">
                <TextInputDisabled
                  label="Contact Name"
                  value={this.props.contact_name}
                />
                <TextInputDisabled
                  label="Channel of Contact"
                  value={this.props.chanel_contact}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactDetail;
