import React from 'react';
import TextAreaInput from "../../../common/TextAreaInput";
import SpanError from "../../../common/SpanError";

const CallInformation = props => {
  return (
    <div className="panel panel-default">
      <div className="panel-heading">Call Information/Request</div>
      <div className="panel-body">
        <div className="form form-xs">
          <div className="row row-xs">
            <TextAreaInput dcname={props.validator.message('contact_note', props.cn, 'required|alpha_num_space|max:150') ? 'form-group has-error' : 'form-group'} label="Contact Note" value={props.cn} onChange={props.onChange} name="contact_note" rules="required|max:150"/>
            {props.validator.message('contact_note', props.cn, 'required|alpha_num_space|max:150')
             ? <SpanError>{props.validator.message('contact_note', props.cn, 'required|alpha_num_space|max:150')}</SpanError> : ''}
            
            <TextAreaInput dcname={props.validator.message('action_taken', props.at, 'required|alpha_num_space|max:150') ? 'form-group has-error' : 'form-group'} label="Action Taken" value={props.at} onChange={props.onChange} name="action_taken"/>
            {props.validator.message('action_taken', props.at, 'required|alpha_num_space|max:150')
             ? <SpanError>{props.validator.message('action_taken', props.at, 'required|alpha_num_space|max:150')}</SpanError> : ''}
            
            <TextAreaInput dcname={props.validator.message('internal_note', props.inote, 'required|alpha_num_space|max:150') ? 'form-group has-error' : 'form-group'} label="Internal Note" value={props.inote} onChange={props.onChange} name="internal_note"/>
            {props.validator.message('internal_note', props.inote, 'required|alpha_num_space|max:150')
             ? <SpanError>{props.validator.message('internal_note', props.inote, 'required|alpha_num_space|max:150')}</SpanError> : ''}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CallInformation;
