import setAuthToken from "../utils/setAuthToken";
// import jwt_decode from "jwt-decode";
import { GET_ERRORS, SET_CURRENT_USER, SET_DELETE_LOGIN } from "./types";
import axios from "../axios-env";
// import axios from "axios";
// const BASE_URL = process.env.REACT_APP_API_KEY;

// var headers = {
//   "Access-Control-Allow-Origin": "*",
// "Content-Type": "application/json",
//   "Content-Type": "text/plain",
//   Accept: "*/*",
//   cors: true,
//   "Access-Control-Request-Headers": "*",
//   "Access-Control-Allow-Credentials": true
// };

// var credentials = "include";
// var body = "JSON.stringify(exerciseForTheReader)";

// Login - Get User Token
export const loginUser = userData => dispatch => {
  // -----------------staging dev kelana----------------------
  // axios
  //   .post("http://api-bsgccrm.kelana.id/api/v1/login", userData)
  //   .then(res => {
  //----------------staging ICP----------------------
  // const head = {
  //   headers: {
  //     "Content-Type": "application/json",
  //     "Access-Control-Allow-Headers":
  //       "Origin, X-Requested-With, Content-Type, Accept",
  //     "Access-Control-Request-Headers": "authorization",
  //     "Access-Control-Allow-Origin": "*"
  //     // "Access-Control-Allow-Credentials": true
  //   }
  // };
  // console.log("parr", BASE_URL);
  axios
    .post("/api/v1/login", userData)
    .then(res => {
      // ----------------Apiari------------------------------
      // axios
      //   .post("/login", userData)
      //   .then(res => {
      //------------------server local-------------------------------------------------------------
      // axios
      // .post("http://192.168.100.13:1025/api/v1/login", userData)
      // .then(res => {

      // console.log(res.data.meta.message);
      const { message, code, error } = res.data.meta;
      // console.log("sini");
      const { meta } = res.data;

      // console.log(message);
      // console.log(code);
      // console.log(error);

      if (error === true) {
        const mess = message ? message : "";
        dispatch({
          type: GET_ERRORS,
          payload: meta
        });
        // console.log(mess);
      }
      if (error === false) {
        //   return;
        //   const { message, code, error } = res.data.meta;
        // }
        // Save to local storage
        const { token } = res.data.data;
        const { id, fullname } = res.data.data.users;
        const { name } = res.data.data.users.level;

        // Set token ls
        localStorage.setItem("jwtToken", token);
        // localStorage.setToken("jwtToken", token);
        localStorage.setItem("iduser", id);
        localStorage.setItem("username", fullname);
        localStorage.setItem("level", name);
        localStorage.setItem("id_login", id);

        // Set token to Auth header
        setAuthToken(token);
        // Decode token to get user data
        // const decoded = jwt_decode(token);
        const decoded = res.data.data;

        // console.log(decoded);
        // Set current user
        dispatch(setCurrentUser(decoded));
        // console.log(decoded);
      }
    })
    .catch(
      mess => {
        // console.log(mess);
        dispatch({
          type: GET_ERRORS,
          payload: mess
        });
      }
      // console.log(payload);
    );
};

// set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};
export const delete_login = () => {
  return {
    type: SET_DELETE_LOGIN,
    payload: ""
  };
};

// Log user out
export const logoutUser = jwtToken => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  };
  // console.log("head log out");
  // console.log(head);
  axios.post("/api/v1/logout", head).then(res => {
    // console.log("res log out");
    // console.log(res.data.meta);
    // dispatch({
    //   type: DATA_CREATE_USER,
    //   payload: res.data.meta
    // });
  });
  // this.state = undefined;
  // .catch(err =>
  //   dispatch({
  //     type: DATA_CREATE_USER,
  //     payload: null
  //   })
  // );
  // Remove token from localstorage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("username");
  localStorage.removeItem("level");
  // Remove auth header for future requests
  setAuthToken(false);
  // set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
