import React, { Component } from "react";
import Navbar from "../../layout/Navbar";
import Sidebar from "../../layout/Sidebar";
import Footer from "../../layout/Footer";
import History from "./History";
import Document from "./document/Document";
import { connect } from "react-redux";
import { setCurrentUser } from "../../../actions/authActions";
import LeftCustomer from "./LeftCustomer";

class CustomerInformation extends Component {
  reset() {
    window.location.reload();
  }
  render() {
    return (
      <div id="app">
        <Navbar information="CUSTOMER INFORMATION" />

        <Sidebar information="CustomerInformation" />
        <div id="main" className="main-xs">
          <div className="row row-xs">
            <div className="col-md-6">
              {/* <form action="create-new-origination-ticket.html"> */}
              <LeftCustomer />
              {/* </form> */}
            </div>
            {this.props.serial.serial !== undefined &&
            this.props.serial.serial.length > 0 ? (
              <div className="col-md-6">
                <History />
                <Document />
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>

        <br />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    serial: state.searchSerial,
    auth: state.auth.user,
    searchName: state.searchName
  };
}
// const mapStateToProps = state => ({
//   auth: state.auth.user
// });

export default connect(
  mapStateToProps,
  { setCurrentUser }
)(CustomerInformation);
