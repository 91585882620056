import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import logo_konica from "../../img/logo-konica2.png";
import { logoutUser, loginUser } from "../../actions/authActions";
import jwt_decode from "jwt-decode";
import { Link, Route } from "react-router-dom";
import { setCurrentUser } from "../../actions/authActions";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      log: []
    };

    this.onChanged = this.onChanged.bind(this);
  }

  onLogoutClick(e) {
    e.preventDefault();
    const jwtToken = localStorage.getItem("jwtToken");
    this.props.logoutUser(jwtToken);
    if (this.props.auth.isAuthenticated === "false") {
      this.props.history.push("/");
    }
  }
  onChanged(event) {
    this.setState({ log: event.target.value });
  }

  render() {
    const { icon, information, detail } = this.props;
    // const { level } = this.props.auth.users;
    // const { users } = this.props.auth;
    const { token } = this.props.auth;
    const namex = localStorage.getItem("username");
    const levels = localStorage.getItem("level");
    const jwtToken = localStorage.getItem("jwtToken");

    const { log } = this.state;
    let confirm;
    if (
      levels !== "Call Center" &&
      levels !== "Help Desk" &&
      levels !== "Control Center" &&
      levels !== "Spare Parts"
    ) {
      confirm = (
        <div className="modal display-block">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div
                className="modal-body text-center text-warning bg-warning"
                style={{ padding: "30px" }}
              >
                <i
                  className="fa fa-exclamation-triangle fa-5x"
                  onClick={this.onLogoutClick.bind(this)}
                />
                <br />
                <br />
                <p>Sorry, You cannot access this page!</p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div id="navbar">
        <nav className="navbar navbar-default" role="navigation">
          <div className="container-fluid">
            <div className="navbar-column">
              {confirm}
              <div className="nav left">
                <div className="media media-profile">
                  <div className="media-left media-middle">
                    <i className="fa fa-user-circle fa-2x" />
                  </div>
                  <div className="media-body">
                    <div>
                      <strong>You are login as : </strong>
                      {/* {users.fullname} ({level.name}) {token} */}
                      {namex} ({levels})
                    </div>
                    {/* <div className="panel-body form-xs"> */}
                    <select
                      // name="log"
                      // id="log"
                      name=""
                      id=""
                      className="form-control input-sm"
                      // style={{
                      //   height: "20px",
                      //   width: "150px",
                      //   fontSize: "10px",
                      //   padding: "0px"
                      // }}
                      // onChange={this.onChanged}
                    >
                      <option value="ss">Not Available</option>
                      <option value="dd">Available</option>
                    </select>
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="center">
                <a href="#" className="title">
                  <h2>
                    {/* <i className={icon} /> */}
                    {information}
                  </h2>
                  <h5>{detail}</h5>
                </a>
              </div>
              <div className="nav right">
                <a
                  // href="#"
                  // onClick={this.onLogoutClick.bind(this)}
                  className="logo-konica"
                >
                  <img src={logo_konica} alt="" />
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth.user
});
export default connect(
  mapStateToProps,
  { logoutUser, setCurrentUser }
)(Navbar);
