import React from 'react'
import TextAreaInput from "../../../common/TextAreaInput";
import SpanError from "../../../common/SpanError";
import './ServiceOrder.css';

const ServiceOrder = props => {
  let machineTypes = '';
  if(props.machinetypes){
    machineTypes = props.machinetypes.map(mtypes => <option key={mtypes.id} value={mtypes.id}>{mtypes.name}</option>);
  }
  let serviceCode = '';
  if(props.servicecode){
    serviceCode = props.servicecode.map(scode => <option key={scode.id} value={scode.id}>{scode.job_code + ' (' + scode.description + ')'}</option>);
  }
  return (
    <div className="panel panel-default service-order-panel">
      <div className="panel-body">
        <div className="form form-xs">
          <div className="row row-xs">
            <div className={props.validator.message('required', props.sci, 'required') ? 'has-error' : ''}>
               <label className="radio-inline">
                <input type="radio" name="is_service" value="1" onChange={props.onChangeRadio}/>
                Service/Machine Problem
              </label>
              <label className="radio-inline">
                <input type="radio" name="is_service" value="2" onChange={props.onChangeRadio}/>
                Internal Follow Up?
              </label>
              <label className="radio-inline">
                <input type="radio" name="is_service" value="3" onChange={props.onChangeRadio}/>
                Other?
              </label>
            </div>
            <div className="well service-field" disabled={props.disablesc ?  'disabled' : ''}>
              <br/>
              <TextAreaInput label="Fault Comment" value={props.fc} onChange={props.onChange} name="fault_comment"
                             dcname={props.validator.message('fault_comment', props.fc, 'required|alpha_num_space|max:150') ? 'form-group has-error' : 'form-group'}/>
              {props.validator.message('fault_comment', props.fc, 'required|alpha_num_space|max:150')
               ? <SpanError>{props.validator.message('fault_comment', props.fc, 'required|alpha_num_space|max:150')}</SpanError> : ''}
              <div className="form-group">
                <label className="control-label">Machine Status</label>
                <select name="machine_status_id" value={props.msi} onChange={props.onChangeInt} className="form-control">
                  <option value="1">Machine Running</option>
                  <option value="2">Machine Down</option>
                </select>
              </div>
              <div className={props.validator.message('machine_type', props.mti, 'required') ? 'form-group has-error' : 'form-group'}>
                <label className="control-label">Machine Type</label>
                <select name="machine_type_id" id="" className="form-control" value={props.mti} onChange={props.onChangeInt}>
                  <option value="">Select</option>
                  {machineTypes}
                  {/* <option value="">PP</option> */}
                </select>
              </div>
              {props.validator.message('machine_type', props.mti, 'required') ? <SpanError>{props.validator.message('machine_type', props.mti, 'required')}</SpanError> : ''}
              
              <div className={props.validator.message('service_code_type', props.sci, 'required') ? 'form-group has-error' : 'form-group'}>
                <label className="control-label">Service Order Type</label>
                <select name="service_code_id" id="" className="form-control" value={props.sci} onChange={props.onChangeInt}>
                  <option value="">Select {props.activesc}</option>
                  {serviceCode}
                  {/* <option value="">30 (CM for Local)</option> */}
                </select>
              </div>
              {props.validator.message('service_code_type', props.sci, 'required') ? <SpanError>{props.validator.message('service_code_type', props.sci, 'required')}</SpanError> : ''}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceOrder;
