import { TICKET_TRACKING } from "../actions/types";

const initialState = {
  ticket_tracking: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TICKET_TRACKING:
      return {
        ...state,
        ticket_tracking: action.payload
      };

    default:
      return state;
  }
}
