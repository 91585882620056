// import axios from "axios";
import { EDIT_USER, UPDATE, DEL_MESSAGE } from "./types";
import axios from "../axios-env";

export const editUser = data => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${data.jwtToken}`
    }
  };
  // console.log("head user");
  // console.log(head);

  axios
    .get(`/api/v1/edit-user/${data.id}`, head)
    .then(res => {
      // console.log("edit user");
      // console.log(res.data.data.user);
      dispatch({
        type: EDIT_USER,
        payload: res.data.data.user,
        department: res.data.data.user.department,
        level: res.data.data.user.level
      });
    })
    .catch(err =>
      dispatch({
        type: EDIT_USER,
        payload: null,
        department: null,
        level: null
      })
    );
};

export const update = datax => dispatch => {
  // console.log("data2");

  const head2 = {
    headers: {
      Authorization: `Bearer ${datax ? datax.jwtToken : ""}`,
      // Accept: "multipart/form-data",
      "Content-Type": "application/json"
    }
  };
  // let data = new FormData();
  // data.append("d", "datax.dt");
  const data = datax ? datax.dt : "";
  const idx = datax ? datax.id : "";
  // console.log("sebelum-Update");
  // console.log(head2);
  console.log(datax);
  // console.log(idx);

  axios
    .put(`/api/v1/update-user/${idx}`, data, head2)
    .then(res => {
      // console.log("res-Update");
      // console.log(res);
      dispatch({
        type: UPDATE,
        payload: res.data.meta
        // aaa: datax
      });
    })
    .catch(err =>
      dispatch({
        type: UPDATE,
        payload: null
        // aaa: null
      })
    );
};
export const del_message = () => {
  // console.log("del dalam");
  return {
    type: DEL_MESSAGE,
    payload: []
  };
};
