import React, { Component } from "react";
import Navbar from "../../layout/Navbar";
import Sidebar from "../../layout/Sidebar";
import { connect } from "react-redux";
import { processTicketHelpdesk, saveHelpDesk, del_assign_help } from "../../../actions/helpDesk";
// import { listFaultArea, listProblem } from "../../../actions/getUserActions";
import { ticketTracking } from "../../../actions/ticketTrackingAction";
import NumberFormat from "react-number-format";
import moment from "moment";
import ModalMachine from "../../common/ModalMachine";
import ModalHeader from "../../common/ModalHeader";
import ModalFooter from "../../common/ModalFooter";
import SimpleReactValidator from "simple-react-validator";
import Footer from "../../layout/Footer";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import "react-notifications/lib/notifications.css";
let left = "";
let right = "";

class ProcessTicketHelpdesk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickets_det: [],
      originator_des: [],
      originator_des_dep: [],
      originator: [],
      customer: [],
      ticket_details: [],
      persons: [],
      ticket_tracking_id: [],
      // contact_name: "",
      // comment: [],
      fault_area_codes: [],
      problem_codes: [],
      helpdesk_comments: [],
      // escalation: [],
      // id: false,

      call_information: [],
      service_code: [],
      machine: [],
      runing: [],
      yes: [],
      no: [],
      fault_area_codep: [],
      problem_codep: [],
      ticket_tracking: []
    };
    this.validator = new SimpleReactValidator();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleHelpdesk = this.handleHelpdesk.bind(this);
    this.handleEnabled = this.handleEnabled.bind(this);
  }
  handleHelpdesk = e => this.setState({ [e.target.name]: e.target.value });
  handleCekbox = e => {
    const oldState = { ...this.state.fault_area_codep };
    //const newState = { id: e.target.value };
    // this.setState({ fault_area_codep: [e.target.name, +e.target.name] });
    // cekb.push(e.target.value);
    // const updatedState = {
    //   id: +e.target.value
    // }
    // this.setState({
    //   cekb: [updatedState]
    // });

    let day_list = this.state.days;
    let check = e.target.checked;
    let checked_day = e.target.name;

    // let cekb = [];

    // if (check) {
    //   cekb.push([...e.target.name]);
    // }
    this.setState(prevState => ({
      ...prevState,
      fault_area_codep: [...prevState.fault_area_codep, +checked_day]
    }));

    // const qq = this.state.cekb;
    // qq.push(e.target.value);

    // let cekb = [];
    // for (var key in this.state) {
    //   if (this.state[key] === true) {
    //     cekb.push(key);
    //   }
    // }
    //console.log(cekb);
  };

  handleCekbox2 = e => {
    let checked_day = e.target.name;
    this.setState(prevState => ({
      ...prevState,
      problem_codep: [...prevState.problem_codep, +checked_day]
    }));
  };
  handleEnabled(event) {
    this.setState({
      button: "disabled",
      formx: "active",
      // datex: new Date().toLocaleString(),
      datex: moment()

    });
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    // console.log(id);
    const data = {
      id: id,
      jwtToken: localStorage.getItem("jwtToken")
    };
    this.props.processTicketHelpdesk(data);
    // this.props.listFaultArea();
    // this.props.listProblem();
    // this.props.saveHelpDesk();
  }
  handleSubmit = event => {
    event.preventDefault();

    if (this.validator.allValid()) {
      let arr = [];
      // const key = [this.state.fault_area_codesi];
      // for (var key in this.state.fault_area_codesi) {
      arr.push(parseInt(this.state.fault_area_codesi));

      // console.log("kunci");
      // console.log(arr);

      const saveUserData = {
        ticket_tracking_id: this.props.helpDesk.ticket_helpdesk.id,
        contact_name: this.state.contact_name,
        comment: this.state.comment,
        fault_area_codes: this.state.fault_area_codep,
        problem_codes: this.state.problem_codep,
        escalation: this.state.escalation,
        start_datetime:
          moment(this.state.datex).format("YYYY-MM-DD") +
          " " +
          moment(this.state.datex).format("LTS")
      };
      // const jwtToken = localStorage.getItem("jwtToken");
      const datax = {
        dt: saveUserData,
        jwtToken: localStorage.getItem("jwtToken")
      };
      // console.log("console masuk");
      // console.log(datax);
      this.props.saveHelpDesk(datax);

      // this.props.history.push("/ticket-management");
      
      // setTimeout(
      //   function() {
      //     this.props.del_assign_help();
      //     this.props.history.push("/ticket-management");
      //   }.bind(this),
      //   3000
      // );
      setTimeout(
        function() {
          
          if (this.props.helpDesk.resSaveHelpDesk) {
            if (this.props.helpDesk.resSaveHelpDesk.code === 200)
            this.props.del_assign_help();
          this.props.history.push("/ticket-management");
          }
          // console.log("code", this.props.assign_ce.resSaveAssign);
        }.bind(this),
        3000
      );
      // this.validator.showMessages();
      // this.forceUpdate();
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };

  showModalMachine2 = () => {
    this.setState({ showMachine2: true });
    // console.log("tracking");
    // console.log(this.props.helpDesk.ticket_origin.id);

    const dataTrack = {
      id: this.props.helpDesk.ticket_origin.id,
      jwtToken: localStorage.getItem("jwtToken")
    };
    this.props.ticketTracking(dataTrack);
  };
  hideModal2 = () => {
    this.setState({ show2: false, showMachine2: false });
  };

  render() {
    if (this.props.helpDesk.department !== null){
if (this.props.helpDesk.department.length !== 0){


    const { originator } = this.props.yyy;

    const {
      ticket_helpdesk,
      department,
      action_by,
      ticket_origin,
      machine_customer,
      customers,
      machine,
      machine_models,
      technicians,
      shipping,
      blocked_by,
      contracts,
      customer,
      sales,
      ticket_type,
      machine_status,
      service_code,
      fault_area_codes,
      problem_codes,
      ticket_helpdesk2,
      helpdesk_comments,
      contact_detail, resSaveHelpDesk,
      originator_hlp,
        originator_dept
    } = this.props.helpDesk;
    if ((resSaveHelpDesk)) {
      if (resSaveHelpDesk.length !== 0) {
        NotificationManager.success(resSaveHelpDesk.message, "Save Help Desk");
      }
      // if (resSaveAssign.length !== 0) {
      //   NotificationManager.success(
      //     resSaveAssign.message,
      //     "Assign CE Input"
      //   );
      }
    const {
      ticket_tracking_id,
      contact_name,
      comment,
      fault_area_codesi,
      problem_codesi,
      escalation,
      cekb,
      problem_codep,
      button,
      formx,
      datex
    } = this.state;
    // console.log("cekb");
    const { ticket_tracking } = this.props.ticket_tracking;
    // console.log(problem_codep);
    const { tickets_det } = this.props.yyy;
    const { originator_des } = this.props.yyy;
    const { originator_des_dep } = this.props.yyy;
    // const { customer } = this.props.yyy;
    const { ticket_details } = this.props.yyy;
    const { call_information } = this.props.yyy;
    const { service_code_det } = this.props.yyy;
    // const { machine } = this.props.yyy;
    // const { fault_area_code } = this.props.yyy;
    const { problem_code } = this.props.yyy;
    const { history_comments } = this.props.yyy;

    // const { runing } = this.props.machine.machine_running;
    // const { yes } = { runing } === "1" ? "checked" : "";
    // const { no } = { runing } === "0" ? "checked" : "";

    // const {
    //   tickets_det,
    //   originator_des,
    //   originator_des_dep,
    //   originator,
    //   customer,
    //   ticket_details
    // } = this.state;
    // console.log(tickets_det);
    left = (
      <div className="col-md-6">
            <div
          className={
            this.state.showMachine2
              ? "modal display-block"
              : "modal display-none"
          }
        >
          {" "}
          <div className="modal-dialog">
            <div className="modal-content">
              <ModalHeader
                onClick={this.hideModal2}
                onClick={this.hideModal2}
              />
              <hr />

              <div className="modal-body">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    Tracking Process of Ticket
                  </div>
                  <div className="table-responsive">
                    <table className="table table-xs table-bordered">
                      <thead>
                        <tr>
                          <th>Date Time</th>
                          <th>Description</th>
                          <th>Action By</th>
                          <th>Department</th>
                          <th>Assign to Department</th>
                          <th>Received By</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ticket_tracking.map(tic => (
                          <tr>
                            <td>{tic.created_at}</td>
                            <td>{tic.description}</td>
                            <td>
                              {tic.action_by.id}
                              {tic.action_by.fullname}
                            </td>
                            <td>{tic.action_department.name}</td>
                            <td>{tic.received_department.name}</td>
                            <td>
                              {tic.received_by.fullname}(
                              {tic.received_by.username})
                            </td>
                            <td>{tic.ticket_status.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-9" />
              </div>
            </div>
          </div>
        </div>
              <form action="create-new-origination-ticket.html">
                <div className="panel panel-default">
                  <div className="panel-heading">ORIGINATOR</div>
                  <NotificationContainer />
                  <div className="panel-body">
                    <div className="form form-xs">
                      <div className="row">
                        <div className="form-horizontal col-md-6">
                          <div className="form-group">
                            <label
                              className="control-label col-sm-4"
                              style={{ textAlign: "left" }}
                            >
                              Department
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                value={originator_dept.name ? originator_dept.name : ""}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              className="control-label  col-sm-4"
                              style={{ textAlign: "left" }}
                            >
                              Date Time Created
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                value={ticket_helpdesk.created_at ? moment(ticket_helpdesk.created_at).format("L hh:mm") : ""}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-horizontal col-md-6">
                          <div className="form-group">
                            <label
                              className="control-label col-sm-4"
                              style={{ textAlign: "left" }}
                            >
                              Name
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                value={originator_hlp.fullname ? originator_hlp.fullname :""}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              className="control-label col-sm-4"
                              style={{ textAlign: "left" }}
                            >
                              User ID
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                value={originator_hlp.id ? originator_hlp.id : ""}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">CONTACT DETAIL</div>
                  <div className="panel-body">
                    <div className="form form-xs">
                      <div className="row">
                        {/* <div className="form-horizontal col-md-6">
                          
                        </div> */}
                        <div className="form-horizontal col-md-8">
                        <div className="form-group">
                            <label
                              className="control-label  col-sm-6"
                              style={{ textAlign: "left", width: "70%" }}
                            >
                              Date Time Contact
                              {/* {contact_detail.date_of_contact ? moment(contact_detail.date_of_contact).format("L") : ""} */}
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                value={contact_detail.date_of_contact ? contact_detail.date_of_contact : ""}
                                disabled
                              />
                            </div>
                          </div>
                        <div className="form-group form-inline">
                          
                            <label
                              className="control-label  col-sm-6"
                              style={{ textAlign: "left", width: "70%" }}
                            >
                              Contact Name
                            </label>
                            <div className="col-sm-6">
                            <input
                            type="text"
                            className="form-control"
                            value={contact_detail.gender ? contact_detail.gender : ""}
                            style={{width: "20%"}}
                            disabled
                          />
                              <input
                                type="text"
                                className="form-control"
                                value={contact_detail.contact_name ?contact_detail.contact_name : ""}
                            style={{width: "80%"}}

                                disabled
                              />
                            </div>
                          
                          </div>
                          <div className="form-group form-inline">
                            <label
                              className="control-label  col-sm-4"
                              style={{ textAlign: "left", width: "70%" }}
                            >
                              Ref Contact Details
                            </label>
                            <div className="col-sm-8">
                            <input
                            type="text"
                            className="form-control"
                            value={contact_detail.type_channel_of_contact ? contact_detail.type_channel_of_contact : ""}
                            style={{width: "45%"}}
                            disabled
                          />
                              <input
                                type="text"
                                className="form-control"
                                value={contact_detail.channel_of_contact ? contact_detail.channel_of_contact : ""}
                            style={{width: "55%"}}

                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="panel panel-default">
                  <div className="panel-heading">Customer Information</div>
                  <div className="panel-body">
                    <div className="form form-xs">
                      <div className="row row-xs">
                        <div className="col-md-6">
                          <fieldset>
                            <legend>Search By</legend>
                            {/* <div className="form-horizontal"> */}
                              <div className="form-group">
                                <label
                                  className="control-label  col-sm-4"
                                  style={{ textAlign: "left" }}
                                >
                                  Serial No
                                </label>
                                <div className="input-group  col-sm-7">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={machine.machine_serial ? machine.machine_serial : ""}
                                    disabled
                                  />
                                  <span className="input-group-btn">
                                    <button
                                      className="btn btn-default"
                                      disabled
                                    >
                                      <i className="fa fa-search" />
                                    </button>
                                  </span>
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  className="control-label   col-sm-4"
                                  style={{ textAlign: "left" }}
                                >
                                  Equipment No
                                </label>
                                <div className="input-group col-sm-7">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={machine_customer.item_no ? machine_customer.item_no : ""}
                                    disabled
                                  />
                                  <span className="input-group-btn">
                                    <button
                                      className="btn btn-default"
                                      disabled
                                    >
                                      <i className="fa fa-search" />
                                    </button>
                                  </span>
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  className="control-label   col-sm-4"
                                  style={{ textAlign: "left" }}
                                >
                                  Notification No
                                </label>
                                <div className="input-group col-sm-7">
                                  <input
                                    type="text"
                                    className="form-control"
                                    disabled
                                  />
                                  <span className="input-group-btn">
                                    <button className="btn btn-default">
                                      <i className="fa fa-search" />
                                    </button>
                                  </span>
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  className="control-label   col-sm-4"
                                  style={{ textAlign: "left" }}
                                >
                                  Customer Name
                                </label>
                                <div className="input-group col-sm-7">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={customers.CustomerName ? customers.CustomerName : ""}
                                    disabled
                                  />
                                  <span className="input-group-btn">
                                    <button
                                      className="btn btn-default"
                                      data-toggle="modal"
                                      data-target="#modal-search-name"
                                      type="button"
                                      disabled
                                    >
                                      <i className="fa fa-search" />
                                    </button>
                                    <button
                                      className="btn btn-default"
                                      data-toggle="modal"
                                      data-target="#modal-customer-multi"
                                      type="button"
                                      disabled
                                    >
                                      Multi
                                    </button>
                                  </span>
                                </div>
                              </div>
                            {/* </div> */}
                          </fieldset>
                          <div className="form-horizontal">
                            <div className="form-group">
                              <label
                                className="control-label  col-sm-4"
                                style={{ textAlign: "left" }}
                              >
                                Model
                              </label>
                              <div className="col-sm-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={machine_models.model_name ? machine_models.model_name : ""}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="control-label  col-sm-4"
                                style={{
                                  textAlign: "left"
                                }}
                              >
                                Customer Category
                              </label>
                              <div className="col-sm-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={customers.customer_category ? customers.customer_category : ""}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label
                                className="control-label  col-sm-4"
                                style={{
                                  textAlign: "left"
                                }}
                              >
                                Prefered CE
                              </label>
                              <div className="col-sm-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={technicians.code ? technicians.code : ""}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <fieldset>
                            <legend>SHIP TO</legend>
                            <div className="form-horizontal">
                              <div className="form-group">
                                <label
                                  className="control-label  col-sm-5"
                                  style={{
                                    textAlign: "left"
                                  }}
                                >
                                  Ship to Name
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={machine_customer.ship_to_name ? machine_customer.ship_to_name : ""}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  className="control-label  col-sm-5"
                                  style={{
                                    textAlign: "left"
                                  }}
                                >
                                  Ship to Code
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={machine_customer.ship_to_code ? machine_customer.ship_to_code : ""}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  className="control-label  col-sm-5"
                                  style={{
                                    textAlign: "left"
                                  }}
                                >
                                  Ship to Contact
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={machine_customer.ship_to_contact_name ? machine_customer.ship_to_contact_name : ""}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          {/* <fieldset>
                            <legend>SOLD TO</legend>
                            <div className="form-group">
                              <label className="control-label">
                                Sold to Name
                              </label>
                              <input type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                              <label className="control-label">
                                Sold to Code
                              </label>
                              <input type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                              <label className="control-label">
                                Sold to Contact
                              </label>
                              <input type="text" className="form-control" />
                            </div>
                          </fieldset> */}
                          <fieldset>
                            <legend>CSRC</legend>
                            <div className="form-horizontal">
                              <div className="form-group">
                                <label
                                  className="control-label  col-sm-4"
                                  style={{
                                    textAlign: "left"
                                  }}
                                >
                                  CSRC Status
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={customers.csrc_status ? customers.csrc_status : ""}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  className="control-label  col-sm-4"
                                  style={{
                                    textAlign: "left"
                                  }}
                                >
                                  Last Date of Connection
                                </label>
                                <div className="col-sm-8">
                                  <input type="text" className="form-control" disabled />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>

                        <div className="col-md-6">
                          <fieldset>
                            <legend>BLOCK BY</legend>
                            <div className="form-horizontal">
                              <div className="form-group">
                                <label
                                  className="control-label  col-sm-4"
                                  style={{
                                    textAlign: "left"
                                  }}
                                >
                                  Status
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={customers.blocked_status ? customers.blocked_status : ""}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  className="control-label  col-sm-4"
                                  style={{
                                    textAlign: "left"
                                  }}
                                >
                                  Department
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={blocked_by.blocked_by_department ? blocked_by.blocked_by_department : ""}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  className="control-label  col-sm-4"
                                  style={{
                                    textAlign: "left"
                                  }}
                                >
                                  Name
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={blocked_by.blocked_by_user_name ? blocked_by.blocked_by_user_name : ""}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  className="control-label  col-sm-4"
                                  style={{
                                    textAlign: "left"
                                  }}
                                >
                                  User ID
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={blocked_by.blocked_by_user_id ? blocked_by.blocked_by_user_id : ""}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <fieldset>
                            <legend>Contract Detail</legend>
                            <div className="form-horizontal">
                              <div className="form-group">
                                <label
                                  className="control-label col-sm-4"
                                  style={{
                                    textAlign: "left"
                                  }}
                                >
                                  Contract No
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={contracts.contract_number ? contracts.contract_number : ""}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  className="control-label col-sm-4"
                                  style={{
                                    textAlign: "left"
                                  }}
                                >
                                  Start Date
                                </label>
                                <div className="col-sm-8">
                                  <input type="text" className="form-control" disabled/>
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  className="control-label col-sm-4"
                                  style={{
                                    textAlign: "left"
                                  }}
                                >
                                  End Date
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={ contracts.contract_end_date ? moment(
                                      contracts.contract_end_date
                                    ).format("L") : ""}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  className="control-label col-sm-4"
                                  style={{
                                    textAlign: "left"
                                  }}
                                >
                                  User ID
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={contracts.id ? contracts.id : ""}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          {/* <fieldset>
                            <legend>Machine Extend</legend>
                            <div className="form-group">
                              <label className="control-label">
                                Start Date
                              </label>
                              <input type="date" className="form-control" />
                            </div>
                            <div className="form-group">
                              <label className="control-label">End Date</label>
                              <input type="date" className="form-control" />
                            </div>
                          </fieldset> */}
                          <fieldset>
                            <div className="form-horizontal">
                              <legend>Sales Details</legend>

                              <div className="form-group">
                                <label
                                  className="control-label  col-sm-4"
                                  style={{
                                    textAlign: "left"
                                  }}
                                >
                                  Sales Person Code
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={sales.Sales_Person_Code ? sales.Sales_Person_Code : ""}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  className="control-label col-sm-4"
                                  style={{
                                    textAlign: "left"
                                  }}
                                >
                                  Sales Person Name
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={sales.Sales_Person_name ? sales.Sales_Person_name : ""}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  className="control-label col-sm-4"
                                  style={{
                                    textAlign: "left"
                                  }}
                                >
                                  DSM Code
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={sales.dsm_code ? sales.dsm_code : ""}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  className="control-label col-sm-4"
                                  style={{
                                    textAlign: "left"
                                  }}
                                >
                                  DSM Name
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={sales.dsm_name ? sales.dsm_name : ""}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>

                      <fieldset>
                        <legend>Customer Details</legend>
                        <div className="form-horizontal">
                          <div className="form-group">
                            <label
                              className="control-label col-sm-2"
                              style={{
                                textAlign: "left"
                              }}
                            >
                              Ship to Address
                            </label>
                            <div className="col-sm-10">
                              <textarea
                                name=""
                                id=""
                                cols="30"
                                rows="3"
                                className="form-control"
                                value={customers.ship_to_address ? customers.ship_to_address : ""}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              className="control-label col-sm-2"
                              style={{
                                textAlign: "left"
                              }}
                            >
                              Location of Equipment No
                            </label>
                            <div className="col-sm-10">
                              <textarea
                                name=""
                                id=""
                                cols="30"
                                rows="3"
                                className="form-control"
                                value={machine_customer.location ? machine_customer.location : ""}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="row row-xs">
                            <div className="form-horizontal col-sm-6">
                              <div className="form-group" style={{ display: "flex" }}>
                                <label
                                  className="control-label col-sm-2"
                                  style={{
                                    textAlign: "left"
                                  }}
                                >
                                  Telephone No
                                </label>
                                <div className="col-sm-offset-1 col-sm-1">
                                  <input type="text" className="form-control" 
                                  value={
                                    customer.customer_phone1
                                      ? customer.customer_phone1
                                      : ""}
                                      disabled
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-horizontal col-md-6">
                              <div className="form-group">
                                <label
                                  className="control-label col-sm-4"
                                  style={{
                                    textAlign: "left"
                                  }}
                                >
                                  Postal Code
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={customers.ship_to_postcode ? customers.ship_to_postcode : ""}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  className="control-label col-sm-4"
                                  style={{
                                    textAlign: "left"
                                  }}
                                >
                                  Delivery Date
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={machine_customer.delivery_date ? machine_customer.delivery_date : ""}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset>
                        <legend>SLA DETAILS</legend>
                        <div className="form-horizontal">
                          <div className="form-group">
                            <label
                              className="control-label col-sm-2"
                              style={{
                                textAlign: "left"
                              }}
                            >
                              SLA Response Time
                            </label>
                            <div className="col-sm-10">
                              <input type="text" className="form-control" 
                              value={
                                customers.sla_details
                                  ? customers.sla_details
                                  : ""
                              }
                              disabled
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              className="control-label col-sm-2"
                              style={{
                                textAlign: "left"
                              }}
                            >
                              Additional SLA Details
                            </label>
                            <div className="col-sm-10">
                              <input type="text" className="form-control" disabled/>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
                <div className="text-right">
                  <button className="btn btn-warning btn-sm" type="submit">
                    TICKET IN PROGRESS
                  </button>
                  <button className="btn btn-default btn-sm" type="submit">
                    CUSTOMER HISTORY
                  </button>
                </div>
              </form>
            </div>
    );
    right = (
<div className="col-md-6">
              <div className="panel panel-default">
                <div className="panel-heading">
                  Ticket Number : {ticket_origin.ticket_no}
                </div>
                <div className="panel-body">
                  <div className="form form-xs">
                    <div className="form-inline form-group">
                      <label>From </label>{" "}
                      <input
                        type="text"
                        className="form-control"
                        value={department.name ? department.name : ""}
                        style={{ width: "100px" }}
                        disabled
                      />{" "}
                      <label>To</label>{" "}
                      <input
                        type="text"
                        className="form-control"
                        value={ticket_type.name ? ticket_type.name : ""}
                        style={{ width: "100px" }}
                        disabled
                      />{" "}
                      <label
                        style={{
                          textAlign: "left"
                        }}
                      >
                        Requirement Process
                      </label>{" "}
                      <input
                        type="text"
                        className="form-control"
                        value={ticket_type.name ? ticket_type.name : ""}
                        style={{ width: "100px" }}
                        disabled
                      />
                      <button
                        className="btn btn-default"
                        style={{ width: "100px" }}
                        onClick={this.showModalMachine2}
                      >
                        Track Ticket
                      </button>
                    </div>

                    <div className="pull-right">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <br />
                          <br />
                          <button
                            className={
                              button ? "btn btn-success" : "btn btn-danger"
                            }
                            onClick={this.handleEnabled}
                            disabled={button}
                          >
                            START{" "}
                            {/* {datex
                              ? moment(datex).format("YYYY-MM-DD") +
                                " " +
                                moment(datex).format("LTS")
                              : ""} */}
                          </button>
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="form-group form-inline">
                          <label>Start Date</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              datex ? moment(datex).format("YYYY-MM-DD") : ""
                            }
                            disabled
                          />
                        </div>
                        <div className="form-group form-inline">
                          <label>Start Time</label>
                          <input
                            type="text"
                            className="form-control"
                            value={datex ? moment(datex).format("LTS") : ""}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel panel-default">
                <div className="panel-heading">Call Information/Request</div>
                <div className="panel-body">
                  <div className="form form-xs">
                    <div className="form-group">
                      <label className="control-label">Contact Note</label>
                      <textarea
                        cols="30"
                        rows="3"
                        className="form-control"
                        value={ticket_origin.contact_note ? ticket_origin.contact_note : ""}
                        disabled
                      />
                    </div>
                    <div className="form-group">
                      <label className="control-label">Action Taken</label>
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="3"
                        className="form-control"
                        value={ticket_origin.action_taken ? ticket_origin.action_taken : ""}
                        disabled
                      />
                    </div>
                    <div className="form-group">
                      <label className="control-label">Internal Note</label>
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="3"
                        className="form-control"
                        value={ticket_origin.internal_note ? ticket_origin.internal_note : ""}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel panel-default">
                <div className="panel-body">
                  <div className="form form-xs">
                    <div className="form-group">
                      <div className="radio-inline">
                        <label>
                          <input
                            type="radio"
                            name="radio-1"
                            checked={ticket_origin.is_service ? 
                              ticket_origin.is_service === 1 ? "checked" : "" : ""
                            }
                            disabled
                          />
                          Service/Machine Problem
                        </label>
                      </div>
                      <div className="radio-inline">
                        <label>
                          <input
                            type="radio"
                            name="radio-1"
                            checked={
                              ticket_origin.is_service === 0 ? "checked" : ""
                            }
                            disabled
                          />
                          Internal Follow Up?
                        </label>
                      </div>
                      <div className="radio-inline">
                        <label>
                          <input
                            type="radio"
                            name="radio-1"
                            checked={
                              ticket_origin.is_service === 2 ? "checked" : ""
                            }
                            disabled
                          />
                          Other?
                        </label>
                      </div>
                    </div>
                    <div className="well">
                      <div className="form-group form-inline">
                        <label>Is Machine Running</label>
                        <div>
                          <div className="radio-inline">
                            <label>
                              <input
                                type="radio"
                                name="machine"
                                checked={
                                  machine_status.id === 1 ? "checked" : ""
                                }
                                disabled
                              />
                              Yes
                            </label>
                          </div>
                          <div className="radio-inline">
                            <label>
                              <input
                                type="radio"
                                name="machine"
                                checked={
                                  machine_status.id === 0 ? "checked" : ""
                                }
                                disabled
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Service Code</label>
                        <input
                          name=""
                          id=""
                          className="form-control"
                          value={ service_code.job_code ?
                            service_code.job_code +
                            " (" +
                            service_code.job_detail +
                            ")"
                            : ""
                          }
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Fault Comment</label>
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="3"
                          className="form-control"
                          value={ticket_origin.fault_comment ? ticket_origin.fault_comment : ""}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <form className="form-horizontal" onSubmit={this.handleSubmit}> */}
              <div className="panel panel-default">
                <div className="panel-heading">Helpdesk Processing</div>
                <div className="panel-body">
                  <div className="form form-xs">
                    <div className="row">
                      <div className="form-group form-inline">
                        <div className="col-md-4">
                          <label className="control-label">
                            Service Notification No
                          </label>
                        </div>
                        <div className="col-md-7">
                          <input
                            className="form-control"
                            type="text"
                            disabled={formx ? "" : "disabled"}
                            value={ticket_helpdesk2.service_notification_no ? ticket_helpdesk2.service_notification_no : ""}
                            // onChange={this.handleHelpdesk}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="tabs-xs">
                      <ul className="nav nav-tabs">
                        <li className="active">
                          <a data-toggle="tab" href="#tab-comment">
                            Comment
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#tab-fault">
                            Fault Area Code
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#tab-problem">
                            Problem Code
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#tab-history">
                            History
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="tab-comment">
                          <br />
                          <div
                        className={
                          this.validator.message(
                            "contact_name",
                            this.state.contact_name,
                            "required|alpha_num_space"
                          )
                            ? "form-group has-error"
                            : "form-group"
                        }
                      >
                            <label className="control-label col-sm-4"
                          style={{
                            textAlign: "left"
                          }}>Contact Name </label>
                            <div className="col-sm-2">
                            <input
                              type="text"
                              className="form-control"
                              name="contact_name"
                              onChange={this.handleHelpdesk}
                              disabled={formx ? "" : "disabled"}
                              value={this.state.contact_name ? this.state.contact_name : ""}
                            />

                            <p style={{ color: "#A94442" }}>
                              {this.validator.message(
                                "contact_name",
                                this.state.contact_name,
                                "required|alpha_num_space"
                              )}
                            </p>
                            </div>
                          </div>
                          <div
                        className={
                          this.validator.message(
                            "comment",
                                this.state.comment,
                                "required|alpha_num_space"
                          )
                            ? "form-group has-error"
                            : "form-group"
                        }
                      >
                            <label className="control-label col-sm-4"
                          style={{
                            textAlign: "left"
                          }}>Please Input Detail of Calls</label>
                          <div className="col-sm-10">
                            <textarea
                              name="comment"
                              id=""
                              onChange={this.handleHelpdesk}
                              className="form-control"
                              disabled={formx ? "" : "disabled"}
                            />
                            <p style={{ color: "#A94442" }}>
                              {this.validator.message(
                                "comment",
                                this.state.comment,
                                "required|alpha_num_space"
                              )}
                            </p>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane" id="tab-fault">
                          <br />
                          <div className="form-group">
                            {/* <label>Fault Area Code</label> */}
                            <div className="col-sm-10">
                            <div className="overflow-auto">
                              {fault_area_codes.map(person => (
                                <div key={person.id}>
                                  {/* <label> */}
                                    <input
                                      type="checkbox"
                                      name={person.id}
                                      // checked={person.id}
                                      key={person.id}
                                      // value={person.id}
                                      value="1"
                                      onChange={this.handleCekbox}
                                      className="form-check-input"
                                      disabled={formx ? "" : "disabled"}
                                    />
                                    {" " + person.code + " (" + person.name + ")"}
                                  {/* </label> */}
                                </div>
                              ))}
                            </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="tab-problem">
                          <br />
                          <div className="form-group">
                            {/* <label>Problem Code</label> */}
                            <div className="col-sm-10">
                            <div className="overflow-auto">
                              {problem_codes.map(person => (
                                <div key={person.id}>
                                  {/* <label> */}
                                    <input
                                      type="checkbox"
                                      name={person.id}
                                      onChange={this.handleCekbox2}
                                      key={person.id}
                                      // checked={person.id}
                                      value={person.id}
                                      disabled={formx ? "" : "disabled"}
                                    />
                                    {" " + person.code + " (" + person.name + ")"}
                                  {/* </label> */}
                                </div>
                              ))}
                            </div>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane" id="tab-history">
                          <br />
                          <div className="form-group">
                            {/* <label>History</label> */}
                            <div className="overflow-auto">
                              <div className="table-responsive form-xs">
                                <table className="table table-xs table-bordered">
                                  <thead>
                                    <tr>
                                      <th>ID</th>
                                      <th>Contact name</th>
                                      <th>Detail</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {helpdesk_comments.map(person => (
                                        <tr>
                                          <td>{person.id}</td>
                                          <td>{person.contact_name}</td>
                                          <td>{person.comment}</td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel panel-default">
                <div className="panel-heading">Ticket Escallation</div>
                <div className="panel-body">
                  <div className="form form-xs">
                  <div
                        className={
                          this.validator.message(
                            "escalation",
                            this.state.escalation,
                            "required"
                          )
                            ? "form-group has-error"
                            : "form-group"
                        }
                      >
                      <label className="control-label col-sm-4"
                          style={{
                            textAlign: "left"
                          }}>Select Next Step</label>
                      <div className="col-sm-8">
                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              name="escalation"
                              value="RESOLVED THROUGH PHONE"
                              onChange={this.handleHelpdesk}
                              disabled={formx ? "" : "disabled"}
                            />
                            Resolved Through Phone
                          </label>
                        </div>
                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              name="escalation"
                              value="ASSIGN CE"
                              onChange={this.handleHelpdesk}
                              disabled={formx ? "" : "disabled"}
                            />{" "}
                            Assign CE
                          </label>
                        </div>
                        <p style={{ color: "#A94442" }}>
                          {this.validator.message(
                            "escalation",
                            this.state.escalation,
                            "required"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-left">
                <button
                  className="btn btn-warning btn-sm"
                  type="submit"
                  onClick={this.handleSubmit}
                  disabled={formx ? "" : "disabled"}
                >
                  SUBMIT
                </button>
              </div>
              {/* </form> */}
            </div>
    );
  }
}
    return (
      <div id="app">
        <Navbar
          icon="fa fa-ticket icon"
          information="TICKET MANAGEMENT"
          detail="Helpdesk Detail"
        />
        <Sidebar information="TicketManagement" />
        
        <div id="main" className="main-xs">
          <br />
{left ? (
  ""
) : (
  <i
    className="fa fa-spinner fa-spin fa-2x"
    style={{
      opacity: "0.3",
      top: "50%",
      left: "50%",
      position: "absolute"
    }}
  />
)}
          <div className="row row-xs">
            <div>{left ? left : ""}</div>
            <div>{right ? right : ""}</div>
            
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // originator: state.originator,
  // tickets_det: state.tickets_det,
  // originator_des: state.originator_des,
  // originator_des_dep: state.originator_des_dep,
  // customer: state.customer,
  // ticket_details: state.ticket_details

  yyy: state.user,
  helpDesk: state.helpDesk,
  ticket_tracking: state.trackingReducer
});
export default connect(
  mapStateToProps,
  {
    processTicketHelpdesk,
    del_assign_help,
    // listProblem,
    saveHelpDesk,
    ticketTracking
  }
)(ProcessTicketHelpdesk);
