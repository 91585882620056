import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import classnames from "classnames";
import { loginUser, delete_login } from "../../actions/authActions";
import logo from "../../img/logo-konica-small.png";
import minilogo from "../../img/logo-cats-small.png";
import "../../App.css";
import { BarLoader } from "react-spinners";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      errors: {},
      modalview: "modal display-none",
      override: {
        display: "block",
        margin: "0 auto",
        "border-color": "red"
      },
      loading: false
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      // window.location.href = "/dashboard";
      this.props.delete_login();
      this.props.history.push("/dashboard");
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }
  componentWillUnmount() {
    window.location.reload();
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  onSubmit = e => {
    e.preventDefault();

    const userData = {
      username: this.state.username,
      password: this.state.password
    };
    this.props.loginUser(userData);
    this.setState({
      modalview: "modal display-block",
      loading: true,
      errors: ""
    });
    // setInterval(() => {
    //   this.setState({
    //     loading: false
    //   });
    // }, 4000);
  };
  hideModal2 = () => {
    // window.location.reload();

    this.props.delete_login();
    this.setState({ modalview: "modal display-none" });
    this.props.history.push("/");
  };

  render() {
    const { errors } = this.state;
    // console.log("err");
    // console.log(errors);
    if (errors.error) {
      // console.log("maka");
      // this.setState({ modalview: "modal display-block" });
    }

    return (
      <div className="login-container">
        <div className="login-form">
          <form className="form form-login" onSubmit={this.onSubmit}>
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <div className="form-group">
              {/* <label style={{ "text-align": "left" }} className="pull-left" /> */}
              {/* {process.env.REACT_APP_MACHINE} */}
              <input
                type="text"
                className="form-control"
                name="username"
                value={this.state.username}
                onChange={this.onChange}
                placeholder="Username"
              />
            </div>
            <div className="form-group">
              <label htmlFor="" />
              <input
                type="password"
                className="form-control"
                name="password"
                value={this.state.password}
                onChange={this.onChange}
                placeholder="Password"
              />
            </div>
            <div className="form-group">
              <div className="checkbox">
                <label>
                  <input type="checkbox" /> Remember Me
                </label>
              </div>

              {errors.message ? (
                <div className="alert alert-danger alert-dismissible">
                  {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button> */}
                  {/* <div className={this.state.modalview}>
                  <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                      <div
                        className="modal-body text-center text-danger bg-danger"
                        style={{ padding: "30px" }}
                      > */}
                  {/* <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-hidden="true"
                          onClick={this.hideModal2}
                        >
                          &times;
                        </button> */}
                  {/* <i
                          className="fa fa-times fa-5x"
                          onClick={this.hideModal2}
                        /> */}
                  <h5>
                    <i className="icon fa fa-info" /> {errors.message}
                  </h5>

                  {/* <br />
                  <br />
                  <p>{errors.message}</p> */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* </div> */}
                </div>
              ) : (
                // <div className="alert alert-danger alert-dismissible">

                //   <h5>
                //     <i className="icon fa fa-info" /> {errors.message}
                //   </h5>
                // </div>
                ""
              )}
            </div>

            <br />
            <div className="form-group">
              {/* {!errors.message ? (
                <div>
                  <BarLoader
                    css={this.state.override}
                    sizeUnit={"px"}
                    // size={15}
                    // margin={"9px"}
                    height={3}
                    width={400}
                    color={"#66ccff"}
                    loading={this.state.loading}
                  />
                  <br />
                </div>
              ) : (
                ""
              )} */}
              <button
                className="btn btn-primary btn-raised"
                type="submit"
                disabled={this.state.loading && !errors.message}
              >
                {" "}
                {!errors.message ? (
                  this.state.loading ? (
                    <i
                      className="fa fa-spinner fa-spin fa-1x"
                      style={{
                        // opacity: "0.8",
                        color: "#ffffff"
                      }}
                    />
                  ) : (
                    "LOGIN"
                  )
                ) : (
                  "LOGIN"
                )}
              </button>
            </div>
          </form>

          <br />
          <div className="minilogo">
            <img src={minilogo} alt="" />
            <h5>Version 2.0</h5>
            <h5>&copy;Copyright 2019. Incenplus Marketing Group Sdn. Bhd.</h5>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser, delete_login }
)(Login);
