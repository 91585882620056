import React from 'react';
import './ContactDetail.css';
import TextInputDisabled from "../../../common/TextInputDisabled";
import moment from 'moment';

const ContactDetail = props => {

  let cocVal = 'required';
  if(props.tcoc == 'Telephone'){
    cocVal = 'required|phone';
  }else{
    cocVal = 'required|email';
  }
  
  return (
    <div className="panel panel-default contact-detail" style={{overflow: "visible"}}>
      <div className="panel-heading">Contact Details</div>
      <div className="panel-body">
        <div className="form form-xs">
          <div className="row row-xs">
            <TextInputDisabled label="Date/Time Created" value={moment(props.created).format('L HH:mm')} iclass="form-control datetimecontact"/>
            <div className="form-group">
              <label className="control-label" style={{width:"150px"}}>Contact Name</label>
              <select name="gender" className="form-control"  value={props.contact.gender} style={{width: "auto"}} onChange={props.onChange} disabled>
                <option value="Mr">Mr</option>
                <option value="Ms">Ms</option>
              </select>
              <input type="text" className="form-control" style={{minWidth: "140px", maxWidth:"200px"}} name="contact_name"  value={props.contact.contact_name}  disabled/>
            </div>
            <div className="form-group">
              <label className="control-label" style={{width:"150px"}}>Channel of Contact</label>
              <select name="type_channel_of_contact" value={props.contact.type_channel_of_contact} className="form-control" style={{width: "auto"}}  disabled>
                <option value="Telephone">Telephone</option>
                <option value="Email">Email</option>
              </select>
              <input type="text" className="form-control channel" name="channel_of_contact" style={{minWidth: "150px", maxWidth: "161px"}}  value={props.contact.channel_of_contact} disabled/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactDetail;
