import React, { Component } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from './Footer';

class LayoutContainer extends Component{
  
  render (){
    return (
      <div>
        <Navbar
          icon="fa fa-ticket icon"
          information={this.props.pagename}
          detail={this.props.pagedetailname}
        />
        <br /> <br /> <br />
        <Sidebar information={this.props.sidebarinfo}/>
        {this.props.children}
        <Footer />
      </div>
    )
  }
}

export default LayoutContainer;
