import React from 'react';

import Modal from '../../../../ui/Modal/Modal';
import TextFormControl from "../../../common/TextFormControl";
import TextInputDisabled from "../../../common/TextInputDisabled";
import SpanError from "../../../common/SpanError";
import SimpleReactValidator from "simple-react-validator";

class addItemsModal extends React.Component{
  constructor(props){
    super(props)
    this.validator = new SimpleReactValidator({autoForceUpdate: this})
  }
  render(){
    return (
      <Modal title="Add Quotation Items" onclose={this.props.onClose} modalid={this.props.modalid} modalclass={this.props.modalclass}>
        <div className="modal-body">
          <TextFormControl
            dcname={this.validator.message('item_description', this.props.temp.item_description, 'required') ? 'form-group has-error' : 'form-group'}
            label="Description"
            name="item_description"
            value={this.props.temp.item_description}
            onChange={this.props.onChange}
            onBLur={() => this.validator.showMessageFor('item_description')}
          />{this.validator.message('item_description', this.props.temp.item_description, 'required') ? <SpanError>{this.validator.message('item_description', this.props.temp.item_description, 'required')}</SpanError> : ''}
          <TextFormControl
            dcname={this.validator.message('item_code', this.props.temp.item_code, 'required') ? 'form-group has-error' : 'form-group'}
            label="Code"
            name="item_code"
            value={this.props.temp.item_code}
            onChange={this.props.onChange}
          />{this.validator.message('item_code', this.props.temp.item_code, 'required') ? <SpanError>{this.validator.message('item_code', this.props.temp.item_code, 'required')}</SpanError> : ''}
          <TextFormControl
            dcname={this.validator.message('unit_price', this.props.temp.unit_price, 'numeric|required') ? 'form-group has-error' : 'form-group'}
            label="Unit Price"
            name="unit_price"
            value={this.props.temp.unit_price}
            onChange={this.props.onChange}
      // onBlur={() => this.validator.showMessageFor('unit_price')}
          />{this.validator.message('unit_price', this.props.temp.unit_price, 'numeric|required') ? <SpanError>{this.validator.message('unit_price', this.props.temp.unit_price, 'numeric|required')}</SpanError> : ''}
          <TextFormControl
            dcname={this.validator.message('qty', this.props.temp.qty, 'required|numeric') ? 'form-group has-error' : 'form-group'}
            label="Qty"
            name="qty"
            value={this.props.temp.qty}
            onChange={this.props.onChange}
            onBlur={() => this.validator.showMessageFor('qty')}
          />{this.validator.message('qty', this.props.temp.qty, 'required|numeric') ? <SpanError>{this.validator.message('qty', this.props.temp.qty, 'required|numeric')}</SpanError> : ''}
          <TextFormControl
            dcname={this.validator.message('disc', this.props.temp.disc, 'required|numeric') ? 'form-group has-error' : 'form-group'}
            label="Disc"
            name="disc"
            value={this.props.temp.disc}
            onChange={this.props.onChange}
            onBlur={() => this.validator.showMessageFor('disc')}
          />{this.validator.message('disc', this.props.temp.disc, 'required|numeric') ? <SpanError>{this.validator.message('disc', this.props.temp.disc, 'required|numeric')}</SpanError> : ''}
          {/* <TextInputDisabled label="Amount" value={this.props.temp.amount} disabled="disabled"/> */}
        </div>
        <div className="modal-footer align-right">
          <button type="button" className="btn btn-primary pull-right" onClick={this.props.onSave} style={{marginLeft: "5px"}}>Save</button>
          <button type="button" className="btn btn-default pull-right" onClick={this.props.onClose} style={{border: "0"}}>Close</button>
        </div>
      </Modal>
    )
  }
}

export default addItemsModal;
