import { WORKING, UPDATE_W } from "../actions/types";

const initialState = {
  list_working: [],
  working: [],
  res_edit_w: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case WORKING:
      return {
        ...state,
        list_working: action.payload,
        working: action.working
        // working: action.working
      };
    case UPDATE_W:
      return {
        ...state,
        res_edit_w: action.payload
        // working: action.working
      };
    //   case EDIT_HOLIDAY:
    //     // console.log("delete sdasdx");

    //     return {
    //       ...state,
    //       res_edit_holiday: action.payload
    //       // working: action.working
    //     };

    //   case DEL_EDIT_HOLIDAY:
    //     console.log("delete xxx");
    //     return {
    //       ...state,
    //       res_edit_holiday2: action.payload
    //       // working: action.working
    //     };
    //   case DELETE_HOLIDAY:
    //     return {
    //       ...state,
    //       res_delete_holiday: action.payload
    //       // working: action.working
    //     };
    // case DELETE_ORIGINATION_CUSTOMER:
    //   console.log("delete origin");
    //   console.log(state);
    //   return {
    //     ...state,
    //     customer: {}
    //   };
    default:
      return state;
  }
}
