import { SET_SEARCH_NAME } from "../actions/searchNameActions";

export default function searchName(state = [], action) {
  switch (action.type) {
    case SET_SEARCH_NAME:
      return action.payload;
    default:
      return state;
  }
}
