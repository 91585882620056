// import axios from "axios";
import {
  PROSES_TICKET_ASSIGN_CE,
  SAVE_OTP,
  SAVE_ASSIGN,
  DEL_OTP,
  DEL_ASSIGN,
  LOCATION_CE
} from "./types";
import axios from "../axios-env";

export const processTicketAssignCE = data => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${data.jwtToken}`
    }
  };
  // console.log("head asce");
  // console.log(head);

  axios
    .get(`/api/v1/ticket-process/detail-assign-ce/${data.id}`, head)
    .then(res => {
      // console.log("ber");
      // console.log(res);
      dispatch({
        type: PROSES_TICKET_ASSIGN_CE,
        payload: res.data.data.ticket_inbox,
        ce: res.data.data.ce,
        acting: res.data.data.ticket_inbox.action_by,
        ticket_type: res.data.data.ticket_inbox.ticket_type,

        department: res.data.data.ticket_inbox.action_by.department,
        ticket_origin: res.data.data.ticket_inbox.ticket_origin,
        machine_customer:
          res.data.data.ticket_inbox.ticket_origin.machine_customer,
        machine_status: res.data.data.ticket_inbox.ticket_origin.machine_status,
        service_code: res.data.data.ticket_inbox.ticket_origin.service_code,
        machine_type: res.data.data.ticket_inbox.ticket_origin.machine_type,
        machine:
          res.data.data.ticket_inbox.ticket_origin.machine_customer.machine,
        customers:
          res.data.data.ticket_inbox.ticket_origin.machine_customer.customers,
        machine_models:
          res.data.data.ticket_inbox.ticket_origin.machine_customer.machine
            .machine_models,
        technicians:
          res.data.data.ticket_inbox.ticket_origin.machine_customer.customers
            .technicians,
        contracts:
          res.data.data.ticket_inbox.ticket_origin.machine_customer.contracts,
        sales:
          res.data.data.ticket_inbox.ticket_origin.machine_customer.customers
            .sales,
        customer: res.data.data.ticket_inbox.ticket_origin.customer,
        blocked_by:
          res.data.data.ticket_inbox.ticket_origin.machine_customer.customers
            .blocked_by,
        contact: res.data.data.ticket_inbox.contact,
        originator: res.data.data.ticket_inbox.ticket_origin.originator,
        originator_dept:
          res.data.data.ticket_inbox.ticket_origin.originator.department
      });
    })
    .catch(err =>
      dispatch({
        type: PROSES_TICKET_ASSIGN_CE,
        payload: null,
        ce: null,
        acting: null,
        ticket_type: null,
        department: null,
        ticket_origin: null,
        machine_customer: null,
        machine_status: null,
        machine_type: null,
        service_code: null,
        machine: null,
        customers: null,
        machine_models: null,
        technicians: null,
        contracts: null,
        sales: null,
        customer: null,
        blocked_by: null,
        contact: null,
        originator: null,
        originator_dept: null
      })
    );
};
export const ce_location = jwtToken => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  };
  // console.log("head asce");
  // console.log(head);

  axios
    .get("/api/v1/ticket-process/multiple-location-ce", head)
    .then(res => {
      // console.log("ber");
      // console.log(res.data);
      dispatch({
        type: LOCATION_CE,
        payload: res.data.data
      });
    })
    .catch(err =>
      dispatch({
        type: LOCATION_CE,
        payload: null
      })
    );
};

export const saveOtp = datax => dispatch => {
  // console.log("data2");

  const head2 = {
    headers: {
      Authorization: `Bearer ${datax ? datax.jwtToken : ""}`
    }
  };
  const data = datax ? datax.dt : "";
  const id = datax ? datax.id_otp : "";
  // console.log("otp-dalam");
  // console.log(head2);
  // console.log(data);
  // console.log(id);

  axios
    .put(`/api/v1/ticket-management/add-otp-process/${id}`, data, head2)
    .then(res => {
      // console.log("res-otp");
      // console.log(res);
      dispatch({
        type: SAVE_OTP,
        payload: res.data.meta
      });
    })
    .catch(err =>
      dispatch({
        type: SAVE_OTP,
        payload: null
      })
    );
};
export const del_otp = () => {
  // console.log("del dalam");
  return {
    type: DEL_OTP,
    payload: []
  };
};
export const saveAssignCE = datax => dispatch => {
  // console.log("data2");

  const head2 = {
    headers: {
      Authorization: `Bearer ${datax ? datax.jwtToken : ""}`
    }
  };
  const data = datax ? datax.dt : "";
  const id = datax ? datax.id_otp : "";
  // console.log("Asce-dalam");
  // console.log(head2);
  // console.log(data);
  // console.log(id);

  axios
    .put(`/api/v1/ticket-process/process-assign-ce/${id}`, data, head2)
    .then(res => {
      // console.log("res-asce");
      // console.log(res);
      dispatch({
        type: SAVE_ASSIGN,
        payload: res.data.meta
      });
    })
    .catch(err =>
      dispatch({
        type: SAVE_ASSIGN,
        payload: null
      })
    );
};
export const del_assign = () => {
  // console.log("del dalam");
  return {
    type: DEL_ASSIGN,
    payload: []
  };
};
