import React, { Component } from "react";
import "../../../App.css";

class SearchBar extends Component {
  state = { term: "" };

  onFormSubmit = event => {
    event.preventDefault();
    this.props.onSubmit(this.state.term);
  };

  render() {
    return (
      <div className="form-group">
        <form>
          <label className="control-label">Customer Name</label>
          <div className="input-group">
            <input
              type="text"
              className="form-control col-sm-2"
              value={this.state.term}
              onChange={e => this.setState({ term: e.target.value })}
            />
            <span className="input-group-btn">
              <button className="btn btn-default" onClick={this.onFormSubmit}>
                <i className="fa fa-search" />
              </button>
              <button
                className="btn btn-default"
                style={{ background: this.props.color }}
                onClick={this.props.onClick}
                type="button"
              >
                Multi
              </button>
            </span>
          </div>
        </form>
      </div>
    );
  }
}

export default SearchBar;
