import React, { Component } from "react";
import Originator from "./Originator";
import ContactDetail from "./ContactDetail";
import SearchBar from "./SearchBar";
import apiCustomer from "../../../api/apiCustomer";
import ModalHeader from "../../common/ModalHeader";
import ModalFooter from "../../common/ModalFooter";
import ModalCustomer from "../../common/ModalCustomer";
import ModalMachine from "../../common/ModalMachine";
import axios from "../../../axios-env";

class FormCustomer extends Component {
  state = {
    show: false,
    showMachine: false,
    color: "",
    customer_info: [],
    customer_detail: {},
    machines: [],
    machine_detail: {},
    customer_full_info: {},
    category: {},
    prefered_ce: {},
    shipping: {},
    sold: {},
    csrc: {},
    sla: {},
    sales: {},
    contracts: {},
    machine_extends: {},
    block_by: {},
    department: {}
  };

  componentDidMount() {
    this.onSearchSubmit();
  }

  onSearchSubmit = term => {
    // axios
    //   .get(
    //     `/tiket-management/origination2/get-customer-info?sn=342342&en=13123&nofif=&cname=${term}`
    //     // `/api/v1/customer-by-name/${term}`,
    //     // {
    //     //   headers: {
    //     //     Authorization: "Bearer " + localStorage.jwtToken
    //     //   }
    //     // }
    //   )
    //   .then(response => {
    //     console.log(response);
    //     console.log(`/api/v1/customer-by-name/${term}`);

    //     this.setState({ customer_info: response.data.data.customers });
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });
    // if (this.state.customer_info.length > 1) {
    //   this.setState({ show: true });
    // } else {
    //   this.setState({ show: false });
    // }
    axios
      .get(`/api/v1/customer-by-name/${term}`, {
        headers: {
          Authorization: "Bearer " + localStorage.jwtToken
        }
      })
      .then(res => {
        console.log(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  onSubmit = () => {
    this.setState({ show: false });

    if (this.state.machines.length > 1) {
      this.setState({ color: "red" });
    }
  };

  selectMachine = machine => {
    this.setState({ machine_detail: machine });
    apiCustomer
      .get(
        "/tiket-management/origination/get-customer-info?sn=342342&en=13123&nofif=&cname=sampoerna"
      )
      .then(res => {
        this.setState({
          customer_full_info: res.data.data.customer,
          category: res.data.data.customer.category,
          prefered_ce: res.data.data.customer.prefered_ce,
          shipping: res.data.data.customer.shipping,
          sold: res.data.data.customer.sold,
          csrc: res.data.data.customer.csrc,
          sla: res.data.data.customer.sla,
          sales: res.data.data.customer.sales,
          contracts: res.data.data.customer.contracts,
          machine_extends: res.data.data.customer.machine_extends,
          block_by: res.data.data.customer.blocked_by,
          department: res.data.data.customer.blocked_by.department
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    return (
      <React.Fragment>
        {/* Modal Customer */}
        <div
          className={
            this.state.show ? "modal display-block" : "modal display-none"
          }
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <ModalHeader
                onClick={() => this.setState({ show: false })}
                info="Check customer name"
              />
              <hr />
              <div className="modal-body">
                <p className="alert alert-warning" style={{ marginTop: "1px" }}>
                  Your search has multiple match
                </p>
                <p>
                  Please highlight the applicable customer and and click SELECT
                </p>
                <div className="box-body table-responsive ">
                  <table
                    className="table table-hover table-bordered table-xs"
                    id="table"
                  >
                    <thead>
                      <tr>
                        <th>Customer Name</th>
                        <th>Ship To address</th>
                      </tr>
                    </thead>
                    {this.state.customer_info.map(customer => {
                      return (
                        <tbody>
                          <ModalCustomer
                            key={customer.id}
                            customer={customer}
                            onClick={() =>
                              this.setState({
                                machines: customer.machines,
                                customer_detail: customer
                              })
                            }
                          />
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
              <ModalFooter
                onClick={() => this.setState({ show: false, color: "" })}
                onClick={this.onSubmit}
              />
            </div>
          </div>
        </div>
        {/* Modal Machine */}
        <div
          className={
            this.state.showMachine
              ? "modal display-block"
              : "modal display-none"
          }
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <ModalHeader
                onClick={() => this.setState({ showMachine: false })}
                info="Multy Machine"
              />

              <hr />
              <div className="modal-body">
                <div className="form form-xs">
                  <div className="form-group">
                    <label for="">Company Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.customer_detail.name}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Ship to Address</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.customer_detail.ship_to_address}
                    />
                  </div>
                </div>
                <div className="box-body table-responsive ">
                  <table
                    className="table table-hover table-bordered"
                    id="table2"
                  >
                    <thead>
                      <tr>
                        <th>Serial NO</th>
                        <th>Equipment NO</th>
                        <th>Model</th>
                        <th>Ship to Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.machines.map(machine => {
                        return (
                          <ModalMachine
                            key={machine.id}
                            machine={machine}
                            onClick={this.selectMachine.bind(this, machine)}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <ModalFooter
                onClick={() => this.setState({ showMachine: false })}
              />
            </div>
          </div>
        </div>
        <form action="create-new-origination-ticket.html">
          <Originator />
          <ContactDetail />

          <div className="panel panel-default">
            <div className="panel-heading">Customer Information</div>
            <div className="panel-body">
              <div className="form form-xs">
                <div className="row row-xs">
                  <div className="col-md-6">
                    <fieldset>
                      <legend>Search By</legend>
                      <div className="form-group">
                        <label className="control-label">Serial No</label>
                        <div className="input-group">
                          <input
                            type="search"
                            className="form-control"
                            name="searchSerial"
                            onChange={this.onChangeSerial}
                            value={this.state.machine_detail.serial_no}
                          />
                          <span
                            className="input-group-btn"
                            onClick={this.handleClick}
                          >
                            <button className="btn btn-default">
                              <i className="fa fa-search" />
                            </button>
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="control-label">Equipment No</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.machine_detail.equipment_no}
                          />
                          <span className="input-group-btn">
                            <button className="btn btn-default">
                              <i className="fa fa-search" />
                            </button>
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="control-label">Notification No</label>
                        <div className="input-group">
                          <input type="text" className="form-control" />
                          <span className="input-group-btn">
                            <button className="btn btn-default">
                              <i className="fa fa-search" />
                            </button>
                          </span>
                        </div>
                      </div>
                      <SearchBar
                        onSubmit={this.onSearchSubmit}
                        color={this.state.color}
                        onClick={() => this.setState({ showMachine: true })}
                      />
                    </fieldset>
                    <div className="form-group">
                      <label className="control-label">Model</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.machine_detail.model}
                      />
                    </div>
                    <div className="form-group">
                      <label className="control-label">Customer Category</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.category.name}
                      />
                    </div>
                    <div className="form-group">
                      <label className="control-label">Preferred CE</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.prefered_ce.fullname}
                      />
                    </div>
                    <fieldset>
                      <legend>SHIP TO</legend>
                      <div className="form-group">
                        <label className="control-label">Ship to Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.shipping.ship_to_name}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Ship to Code</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.shipping.ship_to_code}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Ship to Contact</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.shipping.ship_to_contact}
                        />
                      </div>
                    </fieldset>
                    <fieldset>
                      <legend>SOLD TO</legend>
                      <div className="form-group">
                        <label className="control-label">Sold to Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.sold.sold_to_name}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Sold to Code</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.sold.sold_to_code}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Sold to Contact</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.sold.sold_to_contact}
                        />
                      </div>
                    </fieldset>
                    <fieldset>
                      <legend>CSRC</legend>
                      <div className="form-group">
                        <label className="control-label">CSRC Status</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.csrc.status}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">
                          Last Date of Connection
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.csrc.last_contact}
                        />
                      </div>
                    </fieldset>
                  </div>

                  <div className="col-md-6">
                    <fieldset>
                      <legend>BLOCK BY</legend>
                      <div className="form-group">
                        <label className="control-label">Status</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.department.id}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Department</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.department.name}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.block_by.fullname}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">User ID</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.block_by.id}
                        />
                      </div>
                    </fieldset>
                    <fieldset>
                      <legend>Contract Detail</legend>
                      <div className="form-group">
                        <label className="control-label">Contract No</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Start Date</label>
                        <input
                          // type="date"
                          className="form-control"
                          value={this.state.contracts.start_date}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">End Date</label>
                        <input
                          // type="Date"
                          className="form-control"
                          value={this.state.contracts.end_date}
                        />
                      </div>
                    </fieldset>
                    <fieldset>
                      <legend>Machine Extend</legend>
                      <div className="form-group">
                        <label className="control-label">Start Date</label>
                        <input
                          // type="date"
                          className="form-control"
                          value={this.state.machine_extends.start_date}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">End Date</label>
                        <input
                          // type="date"
                          className="form-control"
                          value={this.state.machine_extends.end_date}
                        />
                      </div>
                    </fieldset>
                    <fieldset>
                      <legend>Sales Details</legend>
                      <div className="form-group">
                        <label className="control-label">
                          Sales Person Code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.sales.id}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">
                          Sales Person Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.sales.fullname}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">DSM Code</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.sales.dsm_code}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">DSM Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.sales.dsm_name}
                        />
                      </div>
                    </fieldset>
                  </div>
                </div>

                <fieldset>
                  <legend>Customer Details</legend>
                  <div className="form-group">
                    <label className="control-label">Ship to Address</label>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="3"
                      className="form-control"
                      value={this.state.machine_detail.ship_to_address}
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label">
                      Location of Equipment No
                    </label>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="3"
                      className="form-control"
                    />
                  </div>
                  <div className="row row-xs">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="" className="control-label">
                          Telephone No
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.customer_full_info.phone_number}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="" className="control-label">
                          Postal Code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.customer_full_info.postal_code}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="" className="control-label">
                          Delivery Date
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.customer_full_info.delivery_date}
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <legend>SLA DETAILS</legend>
                  <div className="form-group">
                    <label htmlFor="" className="control-label">
                      SLA Response Time
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.sla.response_time}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="" className="control-label">
                      Additional SLA Details
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.sla.additional_details}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <button className="btn btn-default btn-sm" type="submit">
            HISTORY
          </button>
        </form>
      </React.Fragment>
    );
  }
}

export default FormCustomer;
