// import axios from "axios";
import { GET_SPR_RECON, POST_SUB, PRINT_SPR } from "./types";

import axios from "../axios-env";

export const get_spr_recon_list = data => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${data.jwtToken}`
    }
  };
  // console.log("head asce");
  // console.log(head);

  axios
    .get(
      `/api/v1/ticket-management/detail-part-reconciliation/${data.id}`,
      head
    )
    .then(res => {
      // console.log("res spr");
      // console.log(res.data.data);

      const { message, code, error } = res.data.meta;
      const { meta } = res.data;

      dispatch({
        type: GET_SPR_RECON,
        payload: res.data.data,
        action_by: res.data.data.action_by,
        // department: res.data.data.action_by.department,
        ticket_origin: res.data.data.ticket_origin,
        originator: res.data.data.ticket_origin.originator,
        department: res.data.data.ticket_origin.originator.department,

        machine_customer: res.data.data.ticket_origin.machine_customer,
        customers: res.data.data.ticket_origin.machine_customer.customers,
        machine: res.data.data.ticket_origin.machine_customer.machine,
        machine_models:
          res.data.data.ticket_origin.machine_customer.machine.machine_models,
        customer: res.data.data.ticket_origin.customer,
        technician: res.data.data.technician,
        blocked_by:
          res.data.data.ticket_origin.machine_customer.customers.blocked_by,
        contracts: res.data.data.ticket_origin.machine_customer.contracts,
        sales: res.data.data.ticket_origin.machine_customer.customers.sales,
        service_code: res.data.data.ticket_origin.service_code,
        part_replace: res.data.data.part_replace,
        contact_detail: res.data.data.ticket_origin.contact_detail
      });
    })
    .catch(err =>
      dispatch({
        type: GET_SPR_RECON,
        payload: null,
        action_by: null,

        ticket_origin: null,
        originator: null,
        department: null,
        machine_customer: null,
        customers: null,
        machine: null,
        machine_models: null,
        customer: null,
        technician: null,
        blocked_by: null,
        contracts: null,
        sales: null,
        service_code: null,
        part_replace: null,
        contact_detail: null
      })
    );
};

export const print_spr_recon = data => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${data.jwtToken}`
    }
  };
  // console.log("head pm");
  // console.log(data);

  axios
    .get(
      `/api/v1/ticket-management/reconsiliation?ticket_tracking_id=${data.id}`,
      head
    )
    .then(res => {
      // console.log("res pm");
      // console.log(res.data);
      dispatch({
        type: PRINT_SPR,
        payload: res.data.data
      });
    })
    .catch(err => {
      // console.log("res pm err");
      dispatch({
        type: PRINT_SPR,
        payload: null
      });
    });
};

// export const get_spr_recon_list = data => dispatch => {
//   const head = {
//     headers: {
//       Authorization: `Bearer ${data.jwtToken}`
//     }
//   };
//   // console.log("head asce");
//   // console.log(head);

//   axios
//     .get(`/api/v1/ticket-process/detail-part-reconciliation/${data.id}`, head)
//     .then(res => {
//       // console.log("res spr");
//       // console.log(res.data.data);

//       const { message, code, error } = res.data.meta;
//       const { meta } = res.data;

//       dispatch({
//         type: GET_SPR_RECON,
//         payload: res.data.data,
//         action_by: res.data.data.action_by,
//         // department: res.data.data.action_by.department,
//         ticket_origin: res.data.data.ticket_origin,
//         originator: res.data.data.ticket_origin.originator,
//         department: res.data.data.ticket_origin.originator.department,

//         machine_customer: res.data.data.ticket_origin.machine_customer,
//         customers: res.data.data.ticket_origin.machine_customer.customers,
//         machine: res.data.data.ticket_origin.machine_customer.machine,
//         machine_models:
//           res.data.data.ticket_origin.machine_customer.machine.machine_models,
//         customer: res.data.data.ticket_origin.customer,
//         technician: res.data.data.technician,
//         blocked_by:
//           res.data.data.ticket_origin.machine_customer.customers.blocked_by,
//         contracts: res.data.data.ticket_origin.machine_customer.contracts,
//         sales: res.data.data.ticket_origin.machine_customer.customers.sales,
//         service_code: res.data.data.ticket_origin.service_code,
//         spr_requests: res.data.data.spr_requests
//       });
//     })
//     .catch(err =>
//       dispatch({
//         type: GET_SPR_RECON,
//         payload: null,
//         action_by: null,

//         ticket_origin: null,
//         originator: null,
//         department: null,
//         machine_customer: null,
//         customers: null,
//         machine: null,
//         machine_models: null,
//         customer: null,
//         technician: null,
//         blocked_by: null,
//         contracts: null,
//         sales: null,
//         service_code: null,
//         spr_requests: null
//       })
//     );
// };

//   export const edit_pm_cust = data => dispatch => {
//     const head = {
//       headers: {
//         Authorization: `Bearer ${data.jwtToken}`
//       }
//     };
//     console.log("head edit pm");
//     console.log(head);

//     axios
//       .get(`/api/v1/setting/pm-customer/edit/${data.id}`, head)
//       .then(res => {
//         console.log("res edit pm");
//         console.log(res.data.data);
//         dispatch({
//           type: EDIT_PM_CUST,
//           payload: res.data.data,
//           customer: res.data.data.customer
//           // level: res.data.data.user.level
//         });
//       })
//       .catch(err =>
//         dispatch({
//           type: EDIT_PM_CUST,
//           payload: null,
//           customer: null
//           // level: null
//         })
//       );
//   };

export const post_submit = off => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${off.jwtToken}`
    }
  };
  const datas = off ? off.id : "";
  // console.log("pos sub");
  // console.log(datas);

  axios
    .put("/api/v1/ticket-management/reconsiliation", datas, head)
    .then(res => {
      // console.log("res pos sub");
      // console.log(res.data.meta);
      dispatch({
        type: POST_SUB,
        payload: res.data.meta
        // customer: res.data.data.customer
        // level: res.data.data.user.level
      });
    })
    .catch(err =>
      dispatch({
        type: POST_SUB,
        payload: null
        // customer: null
        // level: null
      })
    );
};
//   export const update_default_days = off => dispatch => {
//     const head = {
//       headers: {
//         Authorization: `Bearer ${off.jwtToken}`
//       }
//     };
//     const datas = off ? off.dat : "";
//     console.log("head default days");
//     console.log(datas);

//     axios
//       .put("/api/v1/setting/pm-default/update", datas, head)
//       .then(res => {
//         console.log("res default days");
//         console.log(res.data.meta);
//         dispatch({
//           type: UPDATE_DEFAULT_DAYS,
//           payload: res.data.meta
//           // customer: res.data.data.customer
//           // level: res.data.data.user.level
//         });
//       })
//       .catch(err =>
//         dispatch({
//           type: UPDATE_DEFAULT_DAYS,
//           payload: null
//           // customer: null
//           // level: null
//         })
//       );
//   };

//   export const store_pm_cust = off => dispatch => {
//     const head = {
//       headers: {
//         Authorization: `Bearer ${off.jwtToken}`
//       }
//     };
//     const datas = off ? off.dt : "";
//     console.log("head STORE pm");
//     console.log(datas);

//     axios
//       .post("/api/v1/setting/pm-customer/create", datas, head)
//       .then(res => {
//         console.log("res store pm");
//         console.log(res.data.meta);
//         dispatch({
//           type: STORE_PM_CUST,
//           payload: res.data.meta
//           // customer: res.data.data.customer
//           // level: res.data.data.user.level
//         });
//       })
//       .catch(err =>
//         dispatch({
//           type: STORE_PM_CUST,
//           payload: null
//           // customer: null
//           // level: null
//         })
//       );
//   };
//   export const del_pm_cust = off => dispatch => {
//     const head = {
//       headers: {
//         Authorization: `Bearer ${off.jwtToken}`
//       }
//     };
//     const datas = off ? off.dt : "";
//     console.log("head del pm");
//     console.log(datas);

//     axios
//       .delete(`/api/v1/setting/pm-customer/delete/${off.id}`, head)
//       .then(res => {
//         console.log("res del pm");
//         console.log(res.data.meta);
//         dispatch({
//           type: DEL_PM_CUST,
//           payload: res.data.meta
//           // customer: res.data.data.customer
//           // level: res.data.data.user.level
//         });
//       })
//       .catch(err =>
//         dispatch({
//           type: DEL_PM_CUST,
//           payload: null
//           // customer: null
//           // level: null
//         })
//       );
//   };
