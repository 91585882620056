// import axios from "axios";
import { MAPING } from "./types";
import axios from "../axios-env";

export const mapAction = dataCalendar => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${dataCalendar.jwtToken}`
    }
  };

  axios
    .get(
      `/api/v1/ticket-management/check-location-ce?id_ce=${dataCalendar.id}`,
      head
    )
    .then(res => {
      // console.log("map dlm");
      // console.log(res);
      dispatch({
        type: MAPING,
        payload: res.data.data
      });
    })
    .catch(err =>
      dispatch({
        type: MAPING,
        payload: null
      })
    );
};
