import React, { Component } from "react";
// import DatePicker from "react-datepicker";
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import { connect } from "react-redux";
import { getUsers } from "../../actions/getUserActions";
// import GoogleMapReact from "google-map-react";
// import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
// import PropTypes from "prop-types";
// import BigCalendar from "react-big-calendar";
// import moment from "moment";
import { loginUser } from "../../actions/authActions";
// import iconGreen from "../../img/icon-green.png";
// import iconRed from "../../img/icon-red.png";
// import ModalHeader from "../common/ModalHeader";
// import ModalFooter from "../common/ModalFooter";
import LoadingModal from "../common/LoadingModal";

// const MarkerGreen = ({ text }) => (
//   <div>
//     <img src={iconGreen} alt="" />
//     {text}
//   </div>
// );
// const MarkerRed = ({ text }) => (
//   <div>
//     <img src={iconRed} alt="" />
//     {text}
//   </div>
// );
// const Butt = ({ action, text }) => (
//   <button type="button" className="btn btn-default btn-sm" onClick={action}>
//     {text}
//   </button>
// );
// let gomap = "";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ball: false,
      center: {
        lat: 1.35,
        lng: 103.9
      },
      zoom: 11,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      muncul: false
    };
  }
  // static propTypes = {
  //   onCenterChange: PropTypes.func, // @controllable generated fn
  //   onZoomChange: PropTypes.func, // @controllable generated fn
  //   onBoundsChange: PropTypes.func,
  //   onMarkerHover: PropTypes.func,
  //   onChildClick: PropTypes.func,
  //   center: PropTypes.any,
  //   zoom: PropTypes.number,
  //   markers: PropTypes.any,
  //   visibleRowFirst: PropTypes.number,
  //   visibleRowLast: PropTypes.number,
  //   maxVisibleRows: PropTypes.number,
  //   hoveredRowIndex: PropTypes.number,
  //   openBallonIndex: PropTypes.number
  // };
  componentDidMount() {
    this.props.getUsers();
    // this.props.xxx();
    // this.props.ticketHistory();
  }
  // reset() {
  //   window.location.reload();
  // }
  // submitWin = () => {
  //   this.setState({
  //     muncul: false
  //   });
  //   // console.log("ini sub......", this.state.selectedPlace.name);
  // };
  // muncul = () => {
  //   this.setState({
  //     muncul: true
  //   });
  //   // console.log("ini sub......");
  // };
  // closeMod = () => {
  //   this.setState({
  //     muncul: false
  //   });
  //   // console.log("ini sub......");
  // };
  // onMarkerClick = (props, marker, e) => {
  //   this.setState({
  //     selectedPlace: props,
  //     activeMarker: marker,
  //     showingInfoWindow: true,
  //     activeAssign: true
  //   });
  // };
  // windowHasClosed = () => {
  //   this.setState({
  //     showingInfoWindow: false,
  //     activeAssign: false
  //   });
  // };

  // onMapClicked = props => {
  //   if (this.state.showingInfoWindow) {
  //     this.setState({
  //       showingInfoWindow: false,
  //       activeMarker: null,
  //       activeAssign: false
  //     });
  //   }
  // };

  render() {
    const { user_data } = this.props.useri;
    // const BASE_URL = process.env.REACT_APP_MACHINE;
    // console.log(BASE_URL);
    // const localizer = BigCalendar.momentLocalizer(moment);
    // const { ticket } = this.props.ticket;
    // const { userx } = this.props.userx;
    // console.log("fgsd");
    // console.log(user);
    // console.log("ini if error", this.props.err.error);
    if (this.props.err.error === true) {
      // console.log("ini if error se");
      this.props.history.push("/dashboard");
    }
    let notification;
    if (this.props.err.error === true) {
      notification = (
        <div className="modal display-block">
          <div className="modal-dialog modal-sm ">
            <div className="alert alert-info alert-dismissible">
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-hidden="true"
                onClick={this.reset}
              >
                &times;
              </button>
              <h4>
                <i class="icon fa fa-info" /> Alert! cox
              </h4>
              {this.props.err.message}
            </div>
          </div>
        </div>
      );
    }
    // let gomap;
    // if (this.state.muncul) {
    //   gomap = (
    //     <div className="modal display-block">
    //       <div className="modal-dialog modal-lg ">
    //         <div className="modal-content">
    //           {/* <ModalHeader
    //             onClick={this.hideModal3}

    //             // info={"Tracking Calendar (" + this.state.fullnameCE + ")"}
    //           /> */}
    //           <div className="modal-body">
    //             <div style={{ height: "65vh", width: "100%" }}>
    //               <Map
    //                 google={this.props.google}
    //                 onClick={this.onMapClicked}
    //                 initialCenter={this.state.center}
    //                 zoom={this.state.zoom}
    //               >
    //                 <Marker
    //                   onClick={this.onMarkerClick}
    //                   title={"Pak A."}
    //                   name={"Pak A"}
    //                   position={{ lat: 1.359435, lng: 103.903079 }}
    //                   icon={iconRed}
    //                 />
    //                 <Marker
    //                   onClick={this.onMarkerClick}
    //                   title={"Pak B."}
    //                   name={"Pak B"}
    //                   position={{ lat: 1.33279, lng: 103.977313 }}
    //                   icon={iconGreen}
    //                 />

    //                 <InfoWindow
    //                   marker={this.state.activeMarker}
    //                   visible={this.state.showingInfoWindow}
    //                   onClose={this.windowHasClosed}
    //                 >
    //                   <div>
    //                     <h2>{this.state.selectedPlace.name}</h2>
    //                   </div>
    //                 </InfoWindow>
    //               </Map>
    //             </div>
    //           </div>

    //           <div className="modal-footer" style={{ textAlign: "right" }}>
    //             <button
    //               className="btn btn-primary btn-sm"
    //               onClick={this.submitWin}
    //               disabled={!this.state.activeAssign}
    //             >
    //               Assign
    //             </button>{" "}
    //             <button
    //               className="btn btn-default btn-sm"
    //               onClick={this.closeMod}
    //             >
    //               Cancel
    //             </button>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }
    return (
      <div id="app">
        <Navbar icon="fa fa-dashboard" information="DASHBOARD" />
        <Sidebar information="DASHBOARD" />
        <div id="main" className="main-xs">
          {/* {notification} */}
          <div className="row row-xs">
            <div className="col-md-6">
              <div className="panel panel-default">
                <div className="panel-heading">
                  Dashboard Header
                  {/* {BASE_URL} */}
                </div>
                <div className="panel-body">
                  <div className="table-responsive">
                    <table className="table table-bordered table-xs">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        {user_data ? (
                          user_data.map(person => (
                            <tr key={person.id}>
                              <td>{person.id}</td>
                              <td>{person.name}</td>
                              <td>{person.email}</td>
                              <td>
                                {person.address.street}, {person.address.suite},{" "}
                                {person.address.city}, {person.address.zipcode}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <LoadingModal />
                        )}
                      </tbody>
                    </table>
                    {/* {this.state.showingInfoWindow ? "muncul" : "gak"}
                    {this.state.muncul ? "muncul2" : "gak2"} */}

                    {/* <Butt action={this.muncul} text="Map" />
                    {gomap} */}
                  </div>
                  {/* <div> */}
                  {/* <BigCalendar
                    localizer={localizer}
                    events={myEventsList}
                    startAccessor="start"
                    endAccessor="end"
                  /> */}
                  {/* </div> */}
                  {/* <div style={{ height: "100vh", width: "100%" }}> */}
                  {/* <GoogleMapReact
                      bootstrapURLKeys={{
                        // key: "AIzaSyBFG8E71CLa-5Q0SdPES4khXEDz5lk_lvo"
                        key: "AIzaSyD8kVr5dj4fb_s-s80rqu8mbehkHKRXgFY"
                      }}
                      defaultCenter={this.state.center}
                      // defaultZoom={this.props.zoom}
                      defaultZoom={11}
                      onChildClick={this._onChildClick}
                    >
                      <MarkerGreen
                        lat={1.33279}
                        lng={103.977313}
                        text="My Marker"
                        // onChildClick={this._onChildClick}
                        showBallon={this.state.ball}
                        marker={<div>waaa</div>}
                      />
                      <MarkerRed
                        lat={1.359435}
                        lng={103.903079}
                        text="Ce Engineering"
                      />
                    </GoogleMapReact> */}
                  {/* </div> */}
                  {/* <div className="table-responsive">
                    <table className="table table-bordered table-xs">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ticket.map(person => (
                          <tr>
                            <td>{person.id}</td>
                            <td>{person.id}</td>
                            <td>{person.id}</td>
                            <td />{" "}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  useri: state.user,
  err: state.errors
  // ticket: state.ticket
  // userx: state.userx
});
export default connect(
  mapStateToProps,
  { getUsers, loginUser }
)(Dashboard);
// export default connect(
//   mapStateToProps,
//   { getUsers, loginUser }
// )(
//   GoogleApiWrapper({
//     apiKey: "AIzaSyD8kVr5dj4fb_s-s80rqu8mbehkHKRXgFY"
//   })(Dashboard)
// );
