// import axios from "axios";
import {
  LIST_PM_MACHINE,
  LIST_MACHINE_ADD,
  STORE_PM_MACH,
  EDIT_PM_MACH,
  UPDATE_PM_MACH,
  DEL_PM_MACH,
  DEL_MESSAGE
} from "./types";
import axios from "../axios-env";

export const listPmMachine = jwtToken => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  };
  // console.log("head list pm machine");
  // console.log(head);

  axios
    .get("/api/v1/setting/pm-machine/list", head)
    .then(res => {
      // console.log("res list pm machine");
      // console.log(res.data.data);
      dispatch({
        type: LIST_PM_MACHINE,
        payload: res.data.data
        // level: res.data.data.user.level
      });
    })
    .catch(err =>
      dispatch({
        type: LIST_PM_MACHINE,
        payload: null
      })
    );
};
export const listMachineAdd = jwtToken => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  };
  // console.log("head pm");
  // console.log(head);

  axios
    .get("/api/v1/setting/pm-machine/create", head)
    .then(res => {
      // console.log("res pm");
      // console.log(res.data.data);
      dispatch({
        type: LIST_MACHINE_ADD,

        pm_default: res.data.data.pm_default,
        machines: res.data.data.machines
        // department: res.data.data.user.department,
        // level: res.data.data.user.level
      });
    })
    .catch(err =>
      dispatch({
        type: LIST_MACHINE_ADD,

        pm_default: null,
        machines: null
      })
    );
};
export const store_pm_machine = off => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${off.jwtToken}`
    }
  };
  const datas = off ? off.dt : "";
  // console.log("head STORE pm Machine", datas);

  axios
    .post("/api/v1/setting/pm-machine/create", datas, head)
    .then(res => {
      // console.log("res store pm Machine", res.data.meta);
      dispatch({
        type: STORE_PM_MACH,
        payload: res.data.meta
        // customer: res.data.data.customer
        // level: res.data.data.user.level
      });
    })
    .catch(err =>
      dispatch({
        type: STORE_PM_MACH,
        payload: null
        // customer: null
        // level: null
      })
    );
};

export const edit_pm_machine = data => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${data.jwtToken}`
    }
  };
  // console.log("head edit pm machine");
  // console.log(head);

  axios
    .get(`/api/v1/setting/pm-machine/edit/${data.id}`, head)
    .then(res => {
      // console.log("res edit pm machine");
      // console.log(res.data.data);
      dispatch({
        type: EDIT_PM_MACH,
        payload: res.data.data,
        customer: res.data.data.customer,
        machine: res.data.data.machine
        // level: res.data.data.user.level
      });
    })
    .catch(err =>
      dispatch({
        type: EDIT_PM_MACH,
        payload: null,
        customer: null,
        machine: null
        // level: null
      })
    );
};

export const update_pm_mach = off => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${off.jwtToken}`
    }
  };
  const datas = off ? off.dt : "";
  // console.log("head update pm machine");
  // console.log(datas);

  axios
    .put("/api/v1/setting/pm-machine/update", datas, head)
    .then(res => {
      // console.log("res update pm machine");
      // console.log(res.data.meta);
      dispatch({
        type: UPDATE_PM_MACH,
        payload: res.data.meta,
        data_send: datas
        // customer: res.data.data.customer
        // level: res.data.data.user.level
      });
    })
    .catch(err =>
      dispatch({
        type: UPDATE_PM_MACH,
        payload: null,
        data_send: null
        // customer: null
        // level: null
      })
    );
};

export const del_pm_mach = off => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${off.jwtToken}`
    }
  };
  const datas = off ? off.id : "";
  // console.log("head del pm machine");
  // console.log(datas);

  axios
    .delete(`/api/v1/setting/pm-machine/delete/${off.id}`, head)
    .then(res => {
      // console.log("res del pm machine");
      // console.log(res.data.meta);
      dispatch({
        type: DEL_PM_MACH,
        payload: res.data.meta,
        data_send: datas
        // customer: res.data.data.customer
        // level: res.data.data.user.level
      });
    })
    .catch(err =>
      dispatch({
        type: DEL_PM_MACH,
        payload: null,
        data_send: null
        // customer: null
        // level: null
      })
    );
};
export const del_message = () => {
  // console.log("del dalam");
  return {
    type: DEL_MESSAGE,
    payload: []
  };
};
