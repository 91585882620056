import React, { Component } from "react";
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";
import axios from "axios";

class Reports extends React.Component {

  render() {

    return (
      <div id="app">
        <Navbar icon="fa fa-print icon" information="Reports" />
        <Sidebar information="Reports"/>
        <div id="main" className="main-xs">
        <div className="row row-xs">
            <div className="col-md-6">
            <div className="panel panel-default">
            <div className="panel-heading">Reports Header</div>
                  <div className="panel-body">

	setting

        </div>
        </div>
        </div>
        </div>
        </div>
      </div>
    );
  }
}

export default Reports;
