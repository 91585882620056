import React, { Component } from "react";

class ModalFooter extends Component {
  render() {
    const { onClick, onClose } = this.props;
    return (
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-default pull-right"
          onClick={onClose}
        >
          Close
        </button>
        <input
          className="btn btn-primary pull-right"
          type="submit"
          value="Select"
          onClick={onClick}
        />
      </div>
    );
  }
}

export default ModalFooter;
