import React, { Component } from "react";
import axios from "../../../../axios-env";

import SettingContainer from "../Container";
//import TextInputGroup from "../../../common/TextInputGroup";
import Pagination from "../../../../components/common/Pagination";
import SimpleReactValidator from "simple-react-validator";
import Flash from "../../../../components/common/FlashMessage";

import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Csrc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csrc: [],
      file: "",
      currentData: [],
      currentPage: null,
      totalPages: null,
      alert_type: "",
      alert_message: "",
      disable: true
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    const headers = {
      headers: { Authorization: `Bearer ${localStorage.getItem("jwtToken")}` }
    };
    axios
      .get("/api/v1/setting/customer/list", headers)
      .then(res => {
        const meta = res.data.meta;
        const data = res.data.data;
        if (meta.code === 200) {
          if (data.length > 0) {
            this.setState({ csrc: data });
          }
        } else {
          this.setState({
            ...this.state,
            alert_type: "error",
            alert_message: "Failed to Load Data"
          });
        }
      })
      .catch(error => {
        this.setState({
          ...this.state,
          alert_type: "error",
          alert_message: "Failed to Load Data"
        });
      });
  }

  onPageChanged = data => {
    const { csrc } = this.state;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentData = csrc.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentData, totalPages });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  addFiles = e => {
    e.preventDefault();

    console.log(e.target.files[0]);
    this.setState({ ...this.state, file: e.target.files[0] });
  };

  uploadHandler = e => {
    e.preventDefault();
    if (this.state.file !== "") {
      const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          "Content-Type": "multipart/form-data"
        }
      };
      let file = this.state.file;
      let formData = new FormData();
      formData.append("file", file);

      axios
        .post("/api/v1/setting/customer/import", formData, headers)
        .then(res => {
          const meta = res.data.meta; //const data = res.data.data;
          if (meta.code === 200) {
            this.setState({
              ...this.state,
              alert_type: "success",
              alert_message: "Data has been imported"
            });
            setTimeout(() => {
              this.setState({
                ...this.state,
                alert_type: "success",
                alert_message: "Data has been imported"
              });
              window.location.reload();
            }, 2000);
          } else {
            this.setState({
              ...this.state,
              alert_type: "error",
              alert_message: "Failed to Import Data"
            });
          }
        })
        .catch(error => {
          console.log("error", error);
          this.setState({
            ...this.state,
            alert_type: "error",
            alert_message: "Something error"
          });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    // console.log(this.state.sla);
    let recordLength = this.state.csrc.length;
    let alert = "";
    if (this.state.alert_type) {
      alert = (
        <Flash
          type={this.state.alert_type}
          message={this.state.alert_message}
        />
      );
    }

    console.log("currentData", this.state.currentData);

    return (
      <div>
        <SettingContainer
          pagename="Setting"
          pagedetailname="CSRC Status"
          location="csrc"
        >
          {alert}
          <div className="panel panel-default">
            <div className="panel-heading">Upload CSRC Status</div>
            <div className="panel-body form form-xs">
              <div className="row">
                <div className="col-md-4">
                  <div className="well form form-xs">
                    <div className="alert alert-info text-center">
                      Please upload the data using right excel format
                    </div>
                    <div className="form-group">
                      <label>Download</label>
                      <div>
                        {this.state.csrc !== "" ? (
                          <ExcelFile
                            element={
                              <button
                                className="btn btn-success"
                                onClick={() =>
                                  this.setState({ disable: false })
                                }
                              >
                                Download
                              </button>
                            }
                            filename="Customer"
                          >
                            <ExcelSheet data={this.state.csrc} name="Sheet1">
                              <ExcelColumn
                                label="CustomerCode"
                                value="CustomerCode"
                              />
                              <ExcelColumn
                                label="sap_customer_code"
                                value="sap_customer_code"
                              />
                              <ExcelColumn
                                label="contact_number"
                                value="contact_number"
                              />
                              <ExcelColumn
                                label="CustomerName"
                                value="CustomerName"
                              />
                              <ExcelColumn
                                label="CustomerAddress"
                                value="CustomerAddress"
                              />
                              <ExcelColumn
                                label="CustomerCity"
                                value="CustomerCity"
                              />
                              <ExcelColumn
                                label="customer_post_code"
                                value="customer_post_code"
                              />
                              <ExcelColumn
                                label="contact_person_name"
                                value="contact_person_name"
                              />
                              <ExcelColumn
                                label="contact_person_email"
                                value="contact_person_email"
                              />
                              <ExcelColumn
                                label="customer_phone1"
                                value="customer_phone1"
                              />
                              <ExcelColumn
                                label="customer_phone2"
                                value="customer_phone2"
                              />
                              <ExcelColumn
                                label="customer_phone_mobile"
                                value="customer_phone_mobile"
                              />
                              <ExcelColumn
                                label="lunch_hours"
                                value="lunch_hours"
                              />
                              <ExcelColumn
                                label="sla_detail"
                                value="sla_detail"
                              />
                              <ExcelColumn
                                label="csrc_status"
                                value="csrc_status"
                              />
                              <ExcelColumn
                                label="blocked_by_department"
                                value="blocked_by_department"
                              />
                              <ExcelColumn
                                label="blocked_by_user_name"
                                value="blocked_by_user_name"
                              />
                              <ExcelColumn
                                label="blocked_by_user_id"
                                value="blocked_by_user_id"
                              />
                              <ExcelColumn
                                label="branch_code"
                                value="branch_code"
                              />
                              <ExcelColumn
                                label="sap_sales_office_code"
                                value="sap_sales_office_code"
                              />
                              <ExcelColumn
                                label="Sales_Person_Code"
                                value="Sales_Person_Code"
                              />
                              <ExcelColumn
                                label="sap_sales_person_code"
                                value="sap_sales_person_code"
                              />
                              <ExcelColumn label="dsm_code" value="dsm_code" />
                              <ExcelColumn
                                label="sap_dsm_code"
                                value="sap_dsm_code"
                              />
                              <ExcelColumn
                                label="technician_code"
                                value="technician_code"
                              />
                              <ExcelColumn
                                label="sap_technician_code"
                                value="sap_technician_code"
                              />
                              <ExcelColumn
                                label="customer_category"
                                value="customer_category"
                              />
                            </ExcelSheet>
                          </ExcelFile>
                        ) : (
                          <button className="btn btn-danger">Download</button>
                        )}
                        {/* <button className="btn btn-danger">Download</button> */}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Upload</label>
                      <input
                        type="file"
                        name="customer_file"
                        onChange={this.addFiles}
                      />
                    </div>
                    <span
                      className="help-block form-error"
                      style={{ color: "#8B0000" }}
                    >
                      {this.validator.message(
                        "upload",
                        this.state.file,
                        "required"
                      )}
                    </span>
                    <div className="form-group">
                      <button
                        className="btn btn-primary"
                        onClick={this.uploadHandler}
                        disabled={this.state.disable}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <table className="table table-bordered table-xs">
                    <thead>
                      <tr>
                        <th>Serial No</th>
                        <th>Machine Model</th>
                        <th>Equipment Number</th>
                        <th>CSRC Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.csrc.length <= 0 ? (
                        <tr>
                          <td colSpan="4" className="text-center">
                            No Data Available in This Table
                          </td>
                        </tr>
                      ) : (
                        this.state.currentData.map(csrc => (
                          <tr key={csrc.id}>
                            <td> No data* </td>
                            <td> No data* </td>
                            <td> No data* </td>
                            <td>{csrc.csrc_status}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                  <div className="panel-footer form-xs">
                    {recordLength !== 0 ? (
                      <Pagination
                        totalRecords={recordLength}
                        pageLimit={10}
                        pageNeighbours={1}
                        onPageChanged={this.onPageChanged}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SettingContainer>
      </div>
    );
  }
}

export default Csrc;
