import {
  PROSES_TICKET_HELPDESK,
  SAVE_HELP_DESK,
  DEL_ASSIGN_HELP
} from "../actions/types";

const initialState = {
  help_desk: [],
  ticket_helpdesk: [],
  action_by: [],
  department: [],
  level: [],
  ticket_origin: [],
  machine_customer: [],
  customers: [],
  machine: [],
  machine_models: [],
  technicians: [],
  shipping: [],
  blocked_by: [],
  contracts: [],
  customer: [],
  sales: [],
  ticket_type: [],
  machine_status: [],
  service_code: [],
  fault_area_codes: [],
  problem_codes: [],
  ticket_helpdesk2: [],
  helpdesk_comments: [],
  contact_detail: [],
  originator_hlp: [],
  originator_dept: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PROSES_TICKET_HELPDESK:
      return {
        ...state,
        help_desk: action.payload,
        ticket_helpdesk: action.ticket_helpdesk,
        action_by: action.action_by,
        department: action.department,
        level: action.level,
        ticket_origin: action.ticket_origin,
        machine_customer: action.machine_customer,
        machine: action.machine,
        customers: action.customers,
        machine_models: action.machine_models,
        technicians: action.technicians,
        shipping: action.shipping,
        blocked_by: action.blocked_by,
        contracts: action.contracts,
        customer: action.customer,
        sales: action.sales,
        ticket_type: action.ticket_type,
        machine_status: action.machine_status,
        service_code: action.service_code,
        fault_area_codes: action.fault_area_codes,
        problem_codes: action.problem_codes,
        ticket_helpdesk2: action.ticket_helpdesk2,
        helpdesk_comments: action.helpdesk_comments,
        contact_detail: action.contact_detail,
        originator_hlp: action.originator_hlp,
        originator_dept: action.originator_dept

        // originator: action.originator,
        // tickets_det: action.tickets_det,
        // originator_des: action.originator_des,
        // originator_des_dep: action.originator_des_dep,
        // // customer: action.customer,
        // ticket_details: action.ticket_details,
        // // call_information: action.call_information,
        // service_code_det: action.service_code_det,
        // // machine: action.machine,
        // history_comments: action.history_comments
      };
    case SAVE_HELP_DESK:
      return {
        ...state,

        resSaveHelpDesk: action.payload
      };
    case DEL_ASSIGN_HELP:
      return {
        ...state,

        resSaveHelpDesk: action.payload
      };

    default:
      return state;
  }
}
