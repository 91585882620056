// import axios from "axios";
import {
  HOLIDAY,
  ADD_HOLIDAY,
  EDIT_HOLIDAY,
  DELETE_HOLIDAY,
  DEL_EDIT_HOLIDAY
} from "./types";
import axios from "../axios-env";

export const holiday_list = jwtToken => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  };
  // console.log("req holiday dlm", head);
  //   console.log("req holiday dlm id", dataCalendar.id);

  axios
    .get("/api/v1/setting/holiday/list", head)
    .then(res => {
      // console.log("holiday dlm");
      // console.log("holiday dlm", res.data);
      dispatch({
        type: HOLIDAY,
        payload: res.data.data
      });
    })
    .catch(err =>
      dispatch({
        type: HOLIDAY,
        payload: null
      })
    );
};
export const add_holiday = dac => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${dac.jwtToken}`
    }
  };
  const datas = dac ? dac.dat : "";
  // console.log("req holiday dlm", head);
  //   console.log("req holiday dlm id", dataCalendar.id);

  axios
    .post("/api/v1/setting/holiday/store", datas, head)
    .then(res => {
      // console.log("holiday dlm");
      // console.log("holiday dlm", res.data);
      dispatch({
        type: ADD_HOLIDAY,
        payload: res.data.meta
      });
    })
    .catch(err =>
      dispatch({
        type: ADD_HOLIDAY,
        payload: null
      })
    );
};
export const edit_holiday = dac => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${dac.jwtToken}`
    }
  };
  const datas = dac ? dac.dat : "";
  // console.log("req holiday dlm", head);
  //   console.log("req holiday dlm id", dataCalendar.id);

  axios
    .put("/api/v1/setting/holiday/update", datas, head)
    .then(res => {
      // console.log("holiday dlm");
      // console.log("holiday dlm", res.data);
      dispatch({
        type: EDIT_HOLIDAY,
        payload: res.data.meta
      });
    })
    .catch(err =>
      dispatch({
        type: EDIT_HOLIDAY,
        payload: null
      })
    );
};
export const del_edit = () => {
  // console.log("del dalam");
  return {
    type: DEL_EDIT_HOLIDAY,
    payload: []
  };
};
// export const del_edit = () => dispatch => {
//   console.log("fasd");
//   dispatch({
//     type: DEL_EDIT_HOLIDAY
//   });
// };
export const delete_holiday = dac => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${dac.jwtToken}`
    }
  };
  const datas = dac ? dac.dat : "";
  // console.log("req holiday dlm", dac);
  // console.log("req holiday dlm id", dataCalendar.id);

  axios
    .delete(`api/v1/setting/holiday/delete?holiday_id=${datas}`, head)
    .then(res => {
      // console.log("holiday dlm");
      // console.log("holiday dlm", res.data);
      dispatch({
        type: DELETE_HOLIDAY,
        payload: res.data.meta
      });
    })
    .catch(err =>
      dispatch({
        type: DELETE_HOLIDAY,
        payload: null
      })
    );
};
