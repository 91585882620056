import React from 'react';

import Modal from '../../../../ui/Modal/Modal';
import './Modal.css'
import SpanError from "../../../common/SpanError";

const AdditionalNotes = props => {
  return (
    <Modal title="Create Additional Note" onclose={props.onClose} modalid={props.modalid} modalclass={props.modalclass}>
      <div className="modal-body">
        <div className="form-group"> 
          <label>Inbound Type</label> 
          <select id="" className="form-control"  name="inbound_type" value={props.dnote.inbound_type} onChange={props.onChange}> 
            <option value="urgent">Urgent</option> 
            <option value="not">Not Urgent</option> 
          </select> 
        </div> 
        <div className="row"> 
          <div className="col-md-6"> 
            <div className="form-group"> 
              <label  style={{width:"230px"}}>From</label> 
              <input type="text" className="form-control" name="From" value={props.dnote.From} onChange={props.onChange} disabled/> 
            </div> 
          </div> 
          <div className="col-md-6"> 
            <div className="form-group"> 
              <label>Name</label> 
              <input type="text" className="form-control" name="name" value={props.dnote.name} onChange={props.onChange} disabled/> 
            </div> 
          </div> 
        </div> 
        <div className="form-group"> 
          <label>Subject</label> 
          <input type="text" className="form-control" name="subject" value={props.dnote.subject} onChange={props.onChange}/> 
        </div>
        <br/> 
        <div className="tabs-xs"> 
          <ul className="nav nav-tabs"> 
            <li className="active"><a href="#cover-note" data-toggle="tab">Cover Note</a></li> 
            <li><a href="#contact-note" data-toggle="tab">Contact Note</a></li> 
            <li><a href="#customer-information" data-toggle="tab">Customer Information</a></li> 
          </ul> 
          <div className="tab-content" style={{border: "1px solid #eee"}}> 
            <div className="tab-pane active panel-body" id="cover-note"> 
              <label >Note</label>    
              <textarea id="" cols="30" rows="5" className="form-control" name="cover_note" value={props.dnote.cover_note} onChange={props.onChange}></textarea> 
            </div> 
            <div className="tab-pane panel-body" id="contact-note"> 
              <label >Note</label>    
              <textarea id="" cols="30" rows="5" className="form-control" name="contact_note" value={props.dnote.contact_note} onChange={props.onChange}></textarea> 
            </div> 
            <div className="tab-pane panel-body" id="customer-information"> 
              <label >Note</label>    
              <textarea id="" cols="30" rows="5" className="form-control" name="customer_information" value={props.dnote.customer_information} onChange={props.onChange}></textarea> 
            </div> 
          </div> 
        </div> 
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-default pull-right" data-dismiss="modal" style={{marginLeft: "5px"}} onClick={props.onClose}>Close</button> 
        <button type="button" className="btn btn-primary pull-right" onClick={props.onSave}>Save changes</button> 
      </div>
    </Modal>
  )
}

export default AdditionalNotes;
