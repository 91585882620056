import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "../../../axios-env";
import moment from "moment";

import "../../../App.css";
import "../../../style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Alert } from "react-bootstrap";

import TextInputGroup from "../../common/TextInputGroup";
import TextInputDisabled from "../../common/TextInputDisabled";
import TextAreaInput from "../../common/TextAreaInput";

import SearchNameModal from "../ticket/origination/searchNameModal";
import SearchMachineModal from "../ticket/origination/searchMachineModal";
import Modal from "../../../ui/Modal/Modal";
import Flash from "../../common/FlashMessage";

import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";

import { setCurrentUser } from "../../../actions/authActions";
import {
  getOriginationCustomer,
  deleteOriginationCustomer
} from "../../../actions/originationActions";
import SimpleReactValidator from "simple-react-validator";
import { setSearchName } from "../../../actions/searchNameActions";
import { setSearhCustomerBySerial } from "../../../actions/searchSerialAction";

const initialState = {
  users: {
    id: null,
    username: null,
    level: null
  },

  alert_type: "",
  alert_message: "",
  notFoundName: false,

  equipment_no: "",
  notification_no: "",
  customer_company_name: "",

  customer_id: "",
  machine_customer_id: "",
  customer_name: "",

  displaySearchModal: false,
  displayMachineModal: false,
  isMultipleMachine: false,
  list_name: [],
  selected_name: "",
  list_machine: [],
  selected_machine: "",

  // State for Customer Information

  model_name: "",
  customer_info: "",
  customer_category_name: "",
  model: "",

  ship_to_name: "",
  ship_to_code: "",
  ship_to_contact: "",

  sold_to_name: "",
  sold_to_code: "",
  sold_to_contact: "",

  csrc_status: "",
  csrc_last_connection: "",

  ship_to_address: "",
  location_of_equipment: "",
  phone_number: "",
  postal_code: "",
  delivery_date: "",

  sla_response_time: "",
  sla_additional_details: "",

  blocked_status: "",
  blocked_by_department: "",
  blocked_by_user_name: "",
  blocked_by_user_id: "",

  contract_no: "",
  contract_start_date: "",
  contract_end_date: "",
  contract_user_id: "",

  machine_extend_start: "",
  machind_extend_end: "",

  Sales_Person_Code: "",
  Sales_Person_name: "",
  dsm_code: "",
  dsm_name: "",

  //ALert
  isShowingInfoAlert: false,
  isShowingSuccessAlert: false,
  isShowingWarningAlert: false,
  isShowingDangerAlert: false,

  // Spinner
  serialNotFound: false
};

class LeftCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.handleChange = this.handleChange.bind(this);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  handleEquipment = e => {
    if (e === undefined) {
      e.preventDefault();
    }
    this.setState({ displayMachineModal: false });

    if (this.validator.fieldValid("equipment_no")) {
      axios
        .get(
          "/api/v1/customer-by-equipment-number/" + this.state.equipment_no,
          {
            headers: {
              Authorization: "Bearer " + localStorage.jwtToken
            }
          }
        )
        .then(res => {
          let payload = {
            headers: localStorage.jwtToken,
            id: res.data.data.id
          };

          this.props.setSearhCustomerBySerial(payload);
          if (res.data.meta.code == 200) {
            const re = res.data.data;

            console.log("re.customers.shipping", re.customers.shipping);

            this.setState({
              customer_id: re.customers.id ? re.customers.id : "",
              customer_name: re.customers.CustomerName
                ? re.customers.CustomerName
                : "",
              machine_customer_id: re.id ? re.id : "",

              model_name: re.machine.machine_models.model_name
                ? re.machine.machine_models.model_name
                : "",
              customer_info: re.customers.CustomerName
                ? re.customers.CustomerName
                : "",
              customer_category: re.customers.customer_category
                ? re.customers.customer_category
                : "-",
              model: re.technician.code ? re.technician.code : "-",

              ship_to_name: re.ship_to_name ? re.ship_to_name : "-",
              ship_to_code: re.ship_to_postcode ? re.ship_to_postcode : "-",
              // ship_to_contact: re.customers.shipping.ship_to_contact
              //   ? re.customers.shipping.ship_to_contact
              //   : "",

              sold_to_name: "-",
              sold_to_code: "-",
              sold_to_contact: "-",

              csrc_status: "-",
              csrc_last_connection: "-",

              ship_to_address: re.ship_to_address ? re.ship_to_address : "-",
              location_of_equipment: "-",
              phone_number: "-",
              postal_code: re.customers.ship_to_postcode
                ? re.customers.ship_to_postcode
                : "-",
              delivery_date: re.delivery_date ? re.delivery_date : "-",

              blocked_status: re.customers.blocked_status
                ? re.customers.blocked_status
                : "-",
              blocked_by_department: re.customers.blocked_by
                .blocked_by_department
                ? re.customers.blocked_by.blocked_by_department
                : "-",
              blocked_by_user_name: re.customers.blocked_by.blocked_by_user_name
                ? re.customers.blocked_by.blocked_by_user_name
                : "-",
              blocked_by_user_id: re.customers.blocked_by.blocked_by_user_id
                ? re.customers.blocked_by.blocked_by_user_id
                : "-",

              contract_no: re.contracts.contract_number
                ? re.contracts.contract_number
                : "-",
              contract_start_date: "-",
              contract_end_date: re.contracts.contract_end_date
                ? re.contracts.contract_end_date
                : "-",
              contract_user_id: "-",

              machine_extend_start: "-",
              machind_extend_end: "-",

              Sales_Person_Code: re.customers.sales.Sales_Person_Code
                ? re.customers.sales.Sales_Person_Code
                : "-",
              Sales_Person_name: re.customers.sales.Sales_Person_name
                ? re.customers.sales.Sales_Person_name
                : "-",
              dsm_code: re.customers.sales.dsm_code
                ? re.customers.sales.dsm_code
                : "-",
              dsm_name: re.customers.sales.dsm_name
                ? re.customers.sales.dsm_name
                : "-"
            });
          } else {
            console.log("response NOT 200");
          }
        })
        .catch(error => {
          let pay = {
            headers: localStorage.jwtToken,
            id: 0
          };
          console.log(error);
          this.setState({ serialNotFound: true });
          this.props.setSearhCustomerBySerial(pay);
        });
    } else {
      this.validator.showMessages();
    }
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  onChangeContactDetail = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.props.changeContactDetail(e.target.name, e.target.value);
  };

  searchByNameHandler = e => {
    e.preventDefault();
    this.setState({ isMultipleMachine: false });
    if (this.validator.fieldValid("customer_name")) {
      axios
        .get("/api/v1/customer-by-name/" + this.state.customer_name, {
          headers: {
            Authorization: "Bearer " + localStorage.jwtToken
          }
        })
        .then(res => {
          if (res.data.meta.code === 204) {
            this.setState({
              alert_type: "error",
              alert_message: "Data Not Found",
              notFoundName: true
            });
          }

          if (res.data.meta.code === 200) {
            const resName = res.data.data;

            const newRes = resName
              .map(e => e["CustomerCode"])
              .map((e, i, final) => final.indexOf(e) === i && i)
              .filter(e => resName[e])
              .map(e => resName[e]);

            this.setState({
              displaySearchModal: true,
              list_name: newRes
            });
            this.props.setSearchName(newRes);
          }
        })
        .catch(error => {});
    } else {
      this.validator.showMessages();
    }
  };

  closeSearchNameModal = () => {
    this.setState({
      displaySearchModal: false,
      selected_name: ""
    });
  };

  changeSelectName = (e, id) => {
    e.preventDefault();

    const updState = {
      ...this.state,
      selected_name: id
    };
    this.setState(updState);
  };

  onSelectName = e => {
    e.preventDefault();
    const listName = this.state.list_name;
    let company = "";
    let count = 0;

    listName.forEach(ln => {
      if (ln.CustomerCode === this.state.selected_name && count === 0) {
        company = ln;
        count++;
      }
    });

    if (this.state.selected_name) {
      axios
        .get("/api/v1/customer-by-code/" + this.state.selected_name, {
          headers: {
            Authorization: "Bearer " + localStorage.jwtToken
          }
        })
        .then(res => {
          console.log(
            "search by customer code when select Machine",
            res.data.data
          );

          if (res.data.meta.code === 200) {
            if (res.data.data.length > 1) {
              this.setState({
                displaySearchModal: false,
                isMultipleMachine: true,
                list_machine: res.data.data,
                selected_company: company
              });
            } else if (res.data.data.length === 1) {
              //console.log(res.data.data);
              let mach = "";
              res.data.data.map(rd => {
                mach = rd.MachineCode;
              });
              this.setState({ equipment_no: mach, displaySearchModal: false });
              this.handleEquipment(this);
              //console.log(a);
            } else {
              console.log("DIDN'T HAVE MACHINE");
              // NotificationManager.info('Info message');
            }

            //this.setState({displaySearchModal: false, isMultipleMachine: true, list_machine: res.data.data, selected_company: company});
          } else {
            console.log(res.data.meta.message);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  searchMultiHandler = e => {
    e.preventDefault();
    this.setState({ displayMachineModal: true });
  };

  closeMultiMachineModal = () => {
    this.setState({
      displayMachineModal: false,
      selected_name: ""
    });
  };

  changeSelectMachine = (e, id) => {
    e.preventDefault();
    console.log("select machine", id);
    const updState = {
      ...this.state,
      equipment_no: id
    };
    this.setState(updState);
  };

  onAlertToggle(type) {
    this.setState({
      [type]: !this.state[type]
    });
  }

  dismissAlert() {
    console.log("dismiss alert");
  }

  handleChange(date) {
    this.setState({
      date_of_contact: date
    });
  }

  render() {
    const trClass = this.state.selected_name ? this.state.selected_name : "";
    const trMachineClass = this.state.equipment_no
      ? this.state.equipment_no
      : "";
    console.log("not found condition", this.state.notFoundName);

    let searchName = "";
    if (this.state.displaySearchModal) {
      searchName = (
        <SearchNameModal
          listuser={this.state.list_name}
          onclick={this.changeSelectName}
          trclass={trClass}
          modalclass="modal display-block" /* modalid="search-name-modal"  */
          select={this.changeSelectName}
          onselect={this.onSelectName}
          onclose={this.closeSearchNameModal}
        />
      );
    }

    if (this.state.serialNotFound) {
      // searchName = (
      //   <Modal
      //     onclose={() => this.setState({ serialNotFound: false })}
      //     title="Data not found"
      //     modalclass="modal display-block"
      //   />
      // );
    }

    let searchMachine = "";
    if (this.state.displayMachineModal) {
      searchName = (
        <SearchMachineModal
          listmachine={this.state.list_machine}
          select={this.changeSelectMachine}
          trclass={trMachineClass}
          modalclass="modal display-block" /* modalid="search-multi-name-modal" */
          onselect={this.handleEquipment}
          onclose={this.closeMultiMachineModal}
          company={this.state.selected_company}
        />
      );
    }

    let multiButton = (multiButton = (
      <button className="btn btn-default" disabled>
        Multi
      </button>
    ));
    if (this.state.isMultipleMachine) {
      multiButton = (
        <button
          className="btn btn-default"
          onClick={this.searchMultiHandler}
          style={{
            color: "red"
          }} /* data-toggle="modal" data-target="#search-multi-name-modal" */
        >
          Multi
        </button>
      );
    }

    let sByNameButton = (
      <button className="btn btn-default" onClick={this.searchByNameHandler}>
        <i className="fa fa-search" />
      </button>
    );

    let alert = "";
    if (this.state.notFoundName) {
      alert = (
        <div className="alert alert-danger alert-dismissible" role="alert">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          Data Not Found
        </div>
      );
      // NotificationManager.warning( 'Data Not Found', 'Warning', 2000, () => {
      //   alert('callback');
      // });
    }

    return (
      <div>
        {alert}
        {searchName}

        <div className="panel panel-default">
          <div className="panel-heading">Customer Information</div>
          <div className="panel-body">
            <div className="form form-xs">
              <div className="row row-xs">
                <div className="col-md-6">
                  <fieldset>
                    <legend>Search By</legend>

                    {/* <TextInputGroup
                      label="Equipment No"
                      name="equipment_no"
                      value={this.state.equipment_no}
                      onChange={this.onChange}
                      onClick={this.handleEquipment}
                      onBlur={() =>
                        this.validator.showMessageFor("equipment_no")
                      }
                      onKeyDown={e =>
                        e.key === "Enter" ? this.handleEquipment(e) : null
                      }
                    /> */}
                    <div className="form-group">
                      <label className="control-label">Equipment No</label>
                      <div className="input-group">
                        <input
                          type="text"
                          name="equipment_no"
                          value={this.state.equipment_no}
                          className="form-control"
                          onChange={this.onChange}
                          onBlur={() =>
                            this.validator.showMessageFor("equipment_no")
                          }
                          onKeyDown={e =>
                            e.key === "Enter" ? this.handleEquipment(e) : null
                          }
                        />
                        <span style={{ color: "#8B0000" }}>
                          {this.validator.message(
                            "equipment_no",
                            this.state.equipment_no,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label">Customer Name</label>
                      <div className="input-group">
                        <input
                          type="text"
                          name="customer_name"
                          value={this.state.customer_name}
                          className="form-control"
                          onChange={this.onChange}
                          onBlur={() =>
                            this.validator.showMessageFor("customer_name")
                          }
                          onKeyDown={e =>
                            e.key === "Enter"
                              ? this.searchByNameHandler(e)
                              : null
                          }
                        />

                        <span className="input-group-btn">
                          {sByNameButton}
                          {multiButton}
                        </span>
                      </div>
                    </div>
                    <span style={{ color: "#8B0000" }}>
                      {this.validator.message(
                        "customer_name",
                        this.state.customer_name,
                        "required"
                      )}
                    </span>
                  </fieldset>
                  <TextInputDisabled label="Model" value={this.state.model} />
                  <TextInputDisabled
                    label="Customer Category"
                    value={this.state.customer_category_name}
                  />
                  <TextInputDisabled
                    label="Prefered CE"
                    value={this.state.model}
                  />
                  <fieldset>
                    <legend>Ship To</legend>
                    <TextInputDisabled
                      label="Ship to Name"
                      value={this.state.ship_to_name}
                    />
                    <TextInputDisabled
                      label="Ship to Code"
                      value={this.state.ship_to_code}
                    />
                    <TextInputDisabled
                      label="Ship to Contact"
                      value={this.state.ship_to_contact}
                    />
                  </fieldset>
                  <fieldset>
                    <legend>CSRC</legend>
                    <TextInputDisabled
                      label="CSRC Status"
                      value={this.state.csrc_status}
                    />
                    <TextInputDisabled
                      label="Last Day of Connection"
                      value={this.state.csrc_last_connection}
                    />
                  </fieldset>
                </div>
                <div className="col-md-6">
                  <fieldset>
                    <legend>Block By</legend>
                    <TextInputDisabled
                      label="Status"
                      value={this.state.blocked_status}
                    />
                    <TextInputDisabled
                      label="Department"
                      value={this.state.blocked_by_department}
                    />
                    <TextInputDisabled
                      label="Name"
                      value={this.state.blocked_by_user_name}
                    />
                    <TextInputDisabled
                      label="User ID"
                      value={this.state.blocked_by_user_name}
                    />
                  </fieldset>
                  <fieldset>
                    <legend>Contract Detail</legend>
                    <TextInputDisabled
                      label="Contract No"
                      value={this.state.contract_no}
                    />
                    <TextInputDisabled
                      label="End Date"
                      value={
                        this.state.contract_end_date
                          ? moment(this.state.contract_end_date).format("L")
                          : ""
                      }
                    />
                  </fieldset>
                  <fieldset>
                    <legend>Sales Detail</legend>
                    <TextInputDisabled
                      label="Sales Person Code"
                      value={this.state.Sales_Person_Code}
                    />
                    <TextInputDisabled
                      label="Sales Person Name"
                      value={this.state.Sales_Person_name}
                    />
                    <TextInputDisabled
                      label="DSM Code"
                      value={this.state.dsm_code}
                    />
                    <TextInputDisabled
                      label="DSM Name"
                      value={this.state.dsm_name}
                    />
                  </fieldset>
                </div>
              </div>
              <fieldset>
                <legend>Customer Details</legend>
                <TextAreaInput
                  label="Ship to Address"
                  value={this.state.ship_to_address}
                  disabled="disabled"
                />
                <TextAreaInput
                  label="Location of Equipment No"
                  value={this.state.location_of_equipment}
                  disabled="disabled"
                />
                <div className="row">
                  <div className="col-md-6">
                    <TextInputDisabled
                      label="Telephone No"
                      value={this.state.phone_number}
                      disabled="disabled"
                    />
                  </div>
                  <div className="col-md-6">
                    <TextInputDisabled
                      label="Postal Code"
                      value={this.state.postal_code}
                    />
                    <TextInputDisabled
                      label="Delivery Date"
                      value={
                        this.state.delivery_date
                          ? moment(this.state.delivery_date).format("L")
                          : ""
                      }
                      disabled="disabled"
                    />
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>SLA Details</legend>
                <TextInputDisabled
                  label="SLA Response Time"
                  value={this.state.sla_response_time}
                />
                <TextInputDisabled
                  label="Additional SLA Details"
                  value={this.state.sla_additional_details}
                />
              </fieldset>
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth.user,
    searchName: state.searchName,
    serial: state.searchSerial.serial,
    customerInfo: state.customerOrigin.customer.data
      ? state.customerOrigin.customer.data
      : ""
  };
}

export default connect(
  mapStateToProps,
  {
    setCurrentUser,
    getOriginationCustomer,
    deleteOriginationCustomer,
    setSearchName,
    setSearhCustomerBySerial
  }
)(LeftCustomer);
