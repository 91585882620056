import React, { Component } from "react";
import ModalHeader from "../../common/ModalHeader";
// import ModalFooter from "../../common/ModalFooter";
import { connect } from "react-redux";
import { ticketTracking } from "../../../actions/ticketTrackingAction";
import "./assignce.css";
class TrackingTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticket_tracking: []
    };
  }
  componentDidMount() {
    // setTimeout(
    //   function() {

    //   }.bind(this),
    //   3000
    // );

    const dataTrack = {
      id: this.props.datas,
      jwtToken: localStorage.getItem("jwtToken")
    };
    // console.log("dat", dataTrack);
    this.props.ticketTracking(dataTrack);
  }
  // componentWillReceiveProps() {
  //   const dataTrack = {
  //     id: this.props.datas,
  //     jwtToken: localStorage.getItem("jwtToken")
  //   };
  //   console.log("dat", dataTrack);
  //   this.props.ticketTracking(dataTrack);
  // }
  render() {
    const { ticket_tracking } = this.props.ticket_tracking;
    // console.log("dat", this.props);

    return (
      <div
        className={
          this.props.showModal ? "modal display-block" : "modal display-none"
        }
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ maxHeight: "600px" }}>
            <div className="modal-header">
              Tracking Process of Ticket
              <ModalHeader onClick={this.props.hideModal} />
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table table-xs table-bordered">
                  <thead>
                    <tr>
                      <th>Date Time</th>
                      <th>Description</th>
                      <th>Action By</th>
                      <th>Department</th>
                      <th>Assign to Department</th>
                      <th>Received By</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ticket_tracking.map(tic => (
                      <tr key={tic.id}>
                        <td>{tic.created_at}</td>
                        <td>{tic.description}</td>
                        <td>
                          {tic.action_by.id}
                          {tic.action_by.fullname}
                        </td>
                        <td>{tic.action_department.name}</td>
                        <td>{tic.received_department.name}</td>
                        <td>
                          {tic.received_by.fullname}({tic.received_by.username})
                        </td>
                        <td>{tic.ticket_status.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ticket_tracking: state.trackingReducer
});
export default connect(
  mapStateToProps,
  {
    ticketTracking
  }
)(TrackingTicket);
