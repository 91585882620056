import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import axios from '../../../../axios-env';
import moment from 'moment';
import Container from '../../../layout/Container';
import UserOriginator from './UserOriginator';
import ContactDetail from './ContactDetail';
import CustomerInformation from './CustomerInformation';
import ContactNote from './ContactNote';
import QProcess from './QProcess';
import AddItemsModal from './addItemsModal';
import DraftModal from './draftModal';
import Flash from '../../../common/FlashMessage';

import { saveQuotation, removeQuotation } from "../../../../actions/quotationActions";

import SimpleReactValidator from "simple-react-validator";
import uuid from 'uuid';
const initialItem = {
  id: "",
  item_description: "",
  item_code: "",
  unit_price: "",
  qty: "",
  disc: "",
  amount: ""
}

const initialState = {
  id: "",
  machine_types: [],
  service_code: [],
  ticket_origin: {
    originator: {
      id: 10,
      username: "",
      fullname: "",
      department: {
        "name": ""
      },
      "level": {
        "name": ""
      }
    },
    contact_note: "",
    action_taken: "",
    internal_note: "",
    fault_comment: "",
    is_service: "",
    service_code: {
      id: ""
    },
    machine_customer: {
      customers: {
        CustomerName: "",
        blocked_by: {
          blocked_by_department: "",
          blocked_by_user_name: "",
          blocked_by_user_id: ""
        }
      },
      machine: {
        machine_serial: "",
        equipment_no: "",
        machine_model: {
          id: "",
          model_name: ""
        }
      },
      ship_to_code: "",
      ship_to_name: "",
      ship_to_contact_name: "",
      ship_to_address: "",
      ship_to_postcode: "",
      ship_to_contact_phone: "",
      location: "",
      csrc_status: "",
      contracts: {
        contract_number: "",
        contract_end_date: "",
        sla_details: ""
      },
      delivery_date: "",
    },
    machine_type: {
      id: ""
    },
    machine_status: {
      id: ""
    },
    contact_detail: {
      id: "",
      contact_name: "",
      gender: "",
      date_of_contact: "",
      channel_of_contact: "",
      type_channel_of_contact: ""
    }
  },
  created_at: "",

  submit: {
    ticket_tracking_id: window.location.href.split("/").pop(),
    quotation_date: "",
    equipment_number: "",
    serial_number: "",
    model: "",
    payment_method: "30 Days",
    quotation_type: "",
    is_waive: 0,
    is_remark: 0,
    remark: "",
    quotation_items: [],
    // quotation_type: "SERVICE LABOR CHARGE",
    // is_waive: 1,
    // is_remark: 1,
    // remark: "abcd",
    // quotation_items: [
    //   {
    //   id: "12",
    //   item_description: "aaaa",
    //   item_code: "A123",
    //   unit_price: "10000",
    //   qty: "1",
    //   disc: "0",
    //   amount: "10000"
    //   },
    //   {
    //     id: "13",
    //     item_description: "aaccccaa",
    //     item_code: "A123",
    //     unit_price: "5000",
    //     qty: "1",
    //     disc: "0",
    //     amount: "5000"
    //   }
    // ],
  },
  displayNewItemsModal: false,
  tempQuotationItem: initialItem,
  displayDraftModal: false,
  validationQItems: false,
}
class Quotation extends Component{
  constructor(props){
    super(props)
    this.state = initialState
    this.validator = new SimpleReactValidator({autoForceUpdate: this})
  }
  componentDidMount(){
    axios.get('/api/v1/ticket-management/origination',{
      headers: { Authorization: 'Bearer ' + localStorage.jwtToken}
    }).then(res => {
      this.setState({  machine_types: res.data.data.machine_types, service_code: res.data.data.service_code });
    }).catch(error => { this.flashMessage('error', 'Failed to Load Master Data'); });
    
    axios.get('/api/v1/ticket-management/quotation/detail?ticket_tracking_id=' + window.location.href.split("/").pop(),{
      headers: { Authorization: 'Bearer ' + localStorage.jwtToken }
    }).then(res => {
      const data = res.data.data
      const updatedSubmit = {...this.state.submit}
      updatedSubmit['quotation_date'] = moment(data.created_at).format('L')
      updatedSubmit['serial_number'] = data.ticket_origin.machine_customer.machine.machine_serial
      updatedSubmit['equipment_number'] = data.ticket_origin.machine_customer.machine.equipment_number
      updatedSubmit['model'] = data.ticket_origin.machine_customer.machine.machine_models.model_name
      this.setState({...data, submit: updatedSubmit});
    }).catch(error => {
      this.flashMessage('error', 'Failed to Load Master Data');
    });
  }

  componentWillUnmount(){
    //this.props.removeQuotation();   
  }
  
  flashMessage = (type, message) => {
    this.setState({...this.state, alert_type: type, alert_message: message})
    setTimeout(() => {
      this.setState({...this.state, alert_type: '', alert_message: ''});
    }, 5000)
  };
  onChange = e => this.setState({ [e.target.name]: e.target.value });
  onChangeSubmit = e => {
    const updatedState = {...this.state.submit}
    updatedState[e.target.name] = e.target.value
    if(e.target.type == 'checkbox' ){ updatedState[e.target.name] = +e.target.checked }
    this.setState({submit: updatedState});
  }
  onChangeQuotationItem = e => {
    const updatedItem = {...this.state.tempQuotationItem}
    const intValue = ['unit_price', 'qty', 'disc'];
    if(intValue.includes(e.target.name)){
      updatedItem[e.target.name]= +e.target.value
    }else{
      updatedItem[e.target.name]= e.target.value
    }
    this.setState({ tempQuotationItem: updatedItem });
    if(this.validator.fieldValid(e.target.name)){
      this.setState({ tempQuotationItem: updatedItem });
    }else{
      this.validator.showMessageFor(e.target.name);
    }
  }
  
  saveQuotationItem = e => {
    this.validator.hideMessageFor('quotation_items');
    const amount = this.state.tempQuotationItem.unit_price * this.state.tempQuotationItem.qty - this.state.tempQuotationItem.disc
    const temp = {...this.state.tempQuotationItem}
    temp['amount'] = amount
    temp['id'] = uuid();
    const updatedItems = this.state.submit.quotation_items
    updatedItems.push(temp)
    const updatedSubmit = {...this.state.submit}
    updatedSubmit['quotation_items'] = updatedItems
    this.setState({submit: updatedSubmit, displayNewItemsModal: !this.state.displayNewItemsModal, tempQuotationItem: initialItem, validationQItems: false});
  }

  removeModalValidation = e => {
    //console.log('remove modal val');
    this.validator.hideMessageFor('item_description');
    this.validator.hideMessageFor('item_code');
    this.validator.hideMessageFor('unit_price');
    this.validator.hideMessageFor('qty');
    this.validator.hideMessageFor('disc');
  }

  toggleNewItemsModal = e => {
    this.setState({displayNewItemsModal: !this.state.displayNewItemsModal, tempQuotationItem: initialItem})
  }

  toggleDraftModal = e => this.setState({displayDraftModal: !this.state.displayDraftModal})

  submitQuotationHandler = e => {
    if (this.validator.allValid() && this.state.submit.quotation_items.length > 0) {
      const quotation = {...this.state.submit}
      const headers = { headers: { Authorization: `Bearer ${localStorage.getItem("jwtToken")}` }}
      axios.post('/api/v1/ticket-management/quotation/store', quotation, headers).then(res => {
        const meta = res.data.meta; const data = res.data.data;
        if(meta.code == '200'){
          this.flashMessage('success', 'Quotation Ticket Saved');
        }else{
          this.flashMessage('error', 'Failed to send quotation');
        }
      }).catch(error => {
        this.flashMessage('error', 'Someting Error');
      });
    } else {
      if(this.state.submit.quotation_items.length == 0){
        this.setState({validationQItems: true});
      }
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  viewDraftHandler = e => {
    if (this.validator.allValid() && this.state.submit.quotation_items.length > 0) {
      this.removeModalValidation();
      this.toggleDraftModal();
    } else {
      if(this.state.submit.quotation_items.length == 0){
        this.setState({validationQItems: true});
      }
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  savePdfHandler = e => {
    const dataQuotation = {
      submit: this.state.submit,
      contact: this.state.ticket_origin.contact_detail,
      customer: this.state.ticket_origin.machine_customer.customers
    }
    this.props.saveQuotation(dataQuotation);
  }
  
  render(){
    //console.log('Render Quotation....');
    //console.log(this.state.validationQItems);
    //console.log(this.validator.errorMessages);
    return (
      <Container pagename="Ticket Management" pagedetailname="Quotation Ticket" sidebarinfo="TicketManagement">
        <div id="main" className="main-xs">
          {this.state.alert_type ? <Flash type={this.state.alert_type} message={this.state.alert_message} remove={this.removeFlashMessage}/> :''}
          <div className="row row-xs">
            <div className="col-md-6">
                <UserOriginator originator={this.state.ticket_origin.originator} created={this.state.created_at}/>
                <ContactDetail origin={this.state.ticket_origin} isnew={this.state.isNewContact} doc={this.state.date_of_contact} gender={this.state.gender} cn={this.state.contact_name}
                               activesc={this.is_service_code_active} coc={this.state.channel_of_contact} tcoc={this.state.type_channel_of_contact}
                               hChange={this.handleChange}/>
                <CustomerInformation {...this.state.ticket_origin.machine_customer}/>
                {/* <LeftOriginationTicket custMachineId={this.changeMachineCustomerHandler} changeContactDetail={this.changeContactDetail} setError={this.flashMessage}/> */}
            </div>
            <div className="col-md-6">
              <ContactNote origin={this.state.ticket_origin} machinetypes={this.state.machine_types} servicecode={this.state.service_code}/>
              <QProcess {...this.state} add={this.addQuotationItem} toggleModal={this.toggleNewItemsModal}
                        dataSubmit={this.state.submit} validator={this.validator} qival={this.state.validationQItems}
                        onChangeSubmit={this.onChangeSubmit}/>
              <div className="pull-left">
                <button className="btn btn-default btn-sm" onClick={this.submitQuotationHandler}>Submit</button>
                <button className="btn btn-default btn-sm" onClick={this.viewDraftHandler} style={{marginLeft: "2px"}}>View Draft</button>
                {/* <button className="btn btn-default btn-sm" style={{marginLeft: "2px"}}>Save Pdf</button> */}
                <Link className="btn btn-default btn-sm" onClick={this.savePdfHandler}
                      to={{pathname: "/ticket-management/quotation/" + this.state.submit.ticket_tracking_id +"/savepdf"}}
                      /* to={{pathname:"5729/savepdf", state:this.state}} */
                >Save PDF</Link>
              </div>
              <div className="pull-right">
                <button className="btn btn-default btn-sm">Close</button>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
        {this.state.displayNewItemsModal
         ? <AddItemsModal
             temp={this.state.tempQuotationItem} 
             modalclass="modal display-block" validator={this.validator}
             onChange={this.onChangeQuotationItem} onClose={this.toggleNewItemsModal} onSave={this.saveQuotationItem}/> : ''}
        {this.state.displayDraftModal
         ? <DraftModal
             dsubmit={this.state.submit} customer={this.state.ticket_origin.machine_customer.customers} contact={this.state.ticket_origin.contact_detail}
             modalclass="modal display-block modal-large" onClose={this.toggleDraftModal}/> : ''}
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  
});


export default connect(mapStateToProps, { saveQuotation, removeQuotation })(Quotation);
