import React, { Component } from "react";
// import Navbar from "../../layout/Navbar";
// import Sidebar from "../../layout/Sidebar";
import Pagination from "../../../common/Pagination";
import "../../ticket/pagination.css";

import axios from "axios";
import { Link, Route } from "react-router-dom";
import { connect } from "react-redux";
import {
  working_list,
  update_working
} from "../../../../actions/calendarWorkingAction";
import SettingContainer from "../Container";
import TextFormControl from "../../../common/TextFormControl";
import LoadingModal from "../../../common/LoadingModal";
// import { isSunday } from "date-fns";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import SimpleReactValidator from "simple-react-validator";

class calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      persons: [],
      resp2: [],
      search: "",
      search_by: "",
      currentTickets: [],
      currentPage: null,
      totalPages: null,
      working: []
    };
    // this.reset = this.reset.bind(this);
    // this.onChanged = this.onChanged.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    const jwtToken = localStorage.getItem("jwtToken");

    this.props.working_list(jwtToken);

    setTimeout(
      function() {
        if (this.props.workingReducer.list_working) {
          if (this.props.workingReducer.list_working.working.length !== 0) {
            const { list_working } = this.props.workingReducer;

            const selasa_start = list_working.working
              .filter(person => person.day === "Tuesday")
              .map(person => person.from_time);
            const selasa_end = list_working.working
              .filter(person => person.day === "Tuesday")
              .map(person => person.to_time);

            const rabu_start = list_working.working
              .filter(person => person.day === "Wednesday")
              .map(person => person.from_time);
            const rabu_end = list_working.working
              .filter(person => person.day === "Wednesday")
              .map(person => person.to_time);

            const kamis_start = list_working.working
              .filter(person => person.day === "Thursday")
              .map(person => person.from_time);
            const kamis_end = list_working.working
              .filter(person => person.day === "Thursday")
              .map(person => person.to_time);

            const jumat_start = list_working.working
              .filter(person => person.day === "Friday")
              .map(person => person.from_time);
            const jumat_end = list_working.working
              .filter(person => person.day === "Friday")
              .map(person => person.to_time);

            const sabtu_start = list_working.working
              .filter(person => person.day === "Saturday")
              .map(person => person.from_time);
            const sabtu_end = list_working.working
              .filter(person => person.day === "Saturday")
              .map(person => person.to_time);

            const minggu_start = list_working.working
              .filter(person => person.day === "Sunday")
              .map(person => person.from_time);
            const minggu_end = list_working.working
              .filter(person => person.day === "Sunday")
              .map(person => person.to_time);

            const senin_start = list_working.working
              .filter(person => person.day === "Monday")
              .map(person => person.from_time);
            const senin_end = list_working.working
              .filter(person => person.day === "Monday")
              .map(person => person.to_time);

            // const senin_start = list_working.working
            //   .filter(person => person.day === "Monday")
            //   .map(person => person.from_time);
            // const senin_end = list_working.working
            //   .filter(person => person.day === "Monday")
            //   .map(person => person.to_time);

            // console.log(
            //   "senin_start",
            //   list_working.working
            //     .filter(person => person.day === "Monday")
            //     .map(person => person.from_time)
            // );
            this.setState({
              id: list_working.id,
              state: list_working.state,
              city: list_working.state,

              capital: list_working.capital,
              iso_name: list_working.iso_name,
              geo_location: list_working.geo_location,
              calender_type: list_working.calender_type,
              working: list_working.working,
              mon_start: senin_start.toString(),
              mon_end: senin_end.toString(),
              mon_start: senin_start.toString(),
              tue_start: selasa_start.toString(),
              tue_end: selasa_end.toString(),
              wed_start: rabu_start.toString(),
              wed_end: rabu_end.toString(),
              thr_start: kamis_start.toString(),
              thr_end: kamis_end.toString(),
              fri_start: jumat_start.toString(),
              fri_end: jumat_end.toString(),
              sat_start: sabtu_start.toString(),
              sat_end: sabtu_end.toString(),
              sun_start: minggu_start.toString(),
              sun_end: minggu_end.toString()
            });
          }
        }
      }.bind(this),
      4000
    );

    // const { resp } = this.props.list_user;
    // this.setState({ resp2: resp });
  }
  componentWillReceiveProps() {
    // if (this.props.edit_userid.departmentx !== null) {
    //   if (this.props.edit_userid.departmentx.length !== 0) {
    // const { edit_user, departmentx, level } = this.props.edit_userid;
    // const jwtToken = localStorage.getItem("jwtToken");
    // this.props.working_list(jwtToken);
    // const { list_working } = this.props.workingReducer;
    // this.setState({
    //   id: list_working.id,
    //   state: list_working.state,
    //   capital: list_working.capital,
    //   iso_name: list_working.iso_name,
    //   geo_location: list_working.geo_location,
    //   calender_type: list_working.calender_type,
    //   working: list_working.working
    // });
    // // console.log("edit detail");
    // console.log("ini.....", this.props);
    //   }
    // }
  }
  showModalMachine = (idx, day) => {
    // const { dataw } = this.props.list_pm;
    this.setState({
      showMachine: true
      // id_cust: idx,
      // pm_days: day
    });
  };
  // onPageChanged = data => {
  //   // const { tickets } = this.state;
  //   const { list_user } = this.props.list_user;
  //   const { currentPage, totalPages, pageLimit } = data;

  //   const offset = (currentPage - 1) * pageLimit;
  //   const currentTickets = list_user.slice(offset, offset + pageLimit);

  //   this.setState({ currentPage, currentTickets, totalPages });
  // };
  // reset() {
  //   // this.props.reset();
  //   localStorage.removeItem("message");
  //   localStorage.removeItem("error");
  // }
  // onChanged(e) {
  //   this.setState({ search: e.target.value.substr(0, 20) });
  // }
  handleEdit = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  submitAdd = () => {
    if (this.validator.allValid()) {
      const saveUserData = {
        state: this.state.city,
        capital: this.state.capital,
        iso_name: this.state.iso_name,
        geo_location: this.state.geo_location,
        calender_type: this.state.calender_type,
        working_hours: [
          {
            day: "Sunday",
            from_time: this.state.sun_start,
            to_time: this.state.sun_end
          },
          {
            day: "Monday",
            from_time: this.state.mon_start,
            to_time: this.state.mon_end
          },
          {
            day: "Tuesday",
            from_time: this.state.tue_start,
            to_time: this.state.tue_end
          },
          {
            day: "Wednesday",
            from_time: this.state.wed_start,
            to_time: this.state.wed_end
          },
          {
            day: "Thursday",
            from_time: this.state.thr_start,
            to_time: this.state.thr_end
          },
          {
            day: "Friday",
            from_time: this.state.fri_start,
            to_time: this.state.fri_end
          },
          {
            day: "Saturday",
            from_time: this.state.sat_start,
            to_time: this.state.sat_end
          }
        ]
      };
      const datax = {
        dat: saveUserData,
        jwtToken: localStorage.getItem("jwtToken")
      };
      // console.log("ini default kirim", datax);
      this.props.update_working(datax);
      // this.props.history.push("/setting/pm");

      setTimeout(
        function() {
          window.location.reload();
        }.bind(this),
        3000
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    // const { list_working, working } = this.props.workingReducer;
    // console.log("state", this.state);
    const { working } = this.state;
    const { list_working, res_edit_w } = this.props.workingReducer;
    if (res_edit_w.length !== 0) {
      if (res_edit_w.code === 200) {
        NotificationManager.success(
          res_edit_w.message,
          "Edit Working Hours",
          2000
        );
      } else {
        NotificationManager.error(
          res_edit_w.message,
          "Edit Working Hours",
          2000
        );
      }
    }

    let modaladd;
    modaladd = (
      <div className="modal fade" id="modal-add">
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
              <h4 className="modal-title">Add Holiday</h4>
            </div>

            <div className="modal-body">
              <div className="form-horizontal">
                <div className="form-group">
                  <label className="col-sm-4 control-label">Description</label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Description"
                      name="description"
                      value={this.state.description}
                      onChange={this.handleAdd}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-4 control-label">Date</label>
                  <div className="col-sm-8">
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Customer Name"
                      name="date"
                      value={this.state.date}
                      onChange={this.handleAdd}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-info btn-xs btn-block"
                // onClick={this.submitAdd}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    let content = (
      <div className="row">
        <div className="col-md-6">
          <TextFormControl
            label="state"
            value={this.state.city}
            dcname={
              this.validator.message(
                "city",
                this.state.city,
                "required|alpha_space"
              )
                ? "form-group has-error"
                : "form-group"
            }
            name="city"
            onChange={this.handleEdit}
          />
          <div className="col-md-offset-2" style={{ paddingLeft: "20px" }}>
            <span style={{ color: "#A94442" }}>
              {this.validator.message(
                "city",
                this.state.city,
                "required|alpha_space"
              )}
            </span>
          </div>

          <TextFormControl
            label="Capital"
            value={this.state.capital}
            dcname={
              this.validator.message(
                "city",
                this.state.capital,
                "required|alpha_space"
              )
                ? "form-group has-error"
                : "form-group"
            }
            name="capital"
            onChange={this.handleEdit}
          />
          <div className="col-md-offset-2" style={{ paddingLeft: "20px" }}>
            <span style={{ color: "#A94442" }}>
              {this.validator.message(
                "Capital",
                this.state.capital,
                "required|alpha_space"
              )}
            </span>
          </div>

          <TextFormControl
            label="ISO Name"
            value={this.state.iso_name}
            dcname={
              this.validator.message("city", this.state.iso_name, "required")
                ? "form-group has-error"
                : "form-group"
            }
            name="iso_name"
            onChange={this.handleEdit}
          />
          <div className="col-md-offset-2" style={{ paddingLeft: "20px" }}>
            <span style={{ color: "#A94442" }}>
              {this.validator.message(
                "ISO NAME",
                this.state.iso_name,
                "required"
              )}
            </span>
          </div>

          <TextFormControl
            label="Geo Location"
            value={this.state.geo_location}
            dcname={
              this.validator.message(
                "Geo Location",
                this.state.geo_location,
                "required"
              )
                ? "form-group has-error"
                : "form-group"
            }
            name="geo_location"
            onChange={this.handleEdit}
          />
          <div className="col-md-offset-2" style={{ paddingLeft: "20px" }}>
            <span style={{ color: "#A94442" }}>
              {this.validator.message(
                "Geo Location",
                this.state.geo_location,
                "required"
              )}
            </span>
          </div>

          <div className="form-group">
            <input type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label>Calendar Type</label>
            <div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="international"
                    onChange={this.handleEdit}
                    name="calender_type"
                    checked={this.state.calender_type === "international"}
                  />{" "}
                  International <a href="#">[e]</a>
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="islamic"
                    onChange={this.handleEdit}
                    name="calender_type"
                    checked={this.state.calender_type === "islamic"}
                  />{" "}
                  Islamic <a href="#">[e]</a>
                </label>
              </div>
            </div>
            <button className="btn btn-primary">New</button>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Working Hours</label>
          </div>
          <div className="form-group">
            <label>Sunday</label>
            <div className="form-inline">
              <div
                className={
                  this.validator.message(
                    "Geo Location",
                    this.state.sun_start,
                    "required"
                  )
                    ? "input-group has-error"
                    : "input-group"
                }
              >
                <input
                  type="time"
                  className="form-control"
                  name="sun_start"
                  value={this.state.sun_start}
                  onChange={this.handleEdit}
                />
                <span className="input-group-addon">
                  <i className="fa fa-clock-o" />
                </span>
              </div>
              <div
                className={
                  this.validator.message(
                    "Geo Location",
                    this.state.sun_end,
                    "required"
                  )
                    ? "input-group has-error"
                    : "input-group"
                }
              >
                <input
                  type="time"
                  className="form-control"
                  value={this.state.sun_end}
                  name="sun_end"
                  onChange={this.handleEdit}
                />
                <span className="input-group-addon">
                  <i className="fa fa-clock-o" />
                </span>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Monday</label>
            <div className="form-inline">
              <div
                className={
                  this.validator.message(
                    "Geo Location",
                    this.state.mon_start,
                    "required"
                  )
                    ? "input-group has-error"
                    : "input-group"
                }
              >
                <input
                  type="time"
                  className="form-control"
                  name="mon_start"
                  value={this.state.mon_start}
                  onChange={this.handleEdit}
                />
                <span className="input-group-addon">
                  <i className="fa fa-clock-o" />
                </span>
              </div>
              <div
                className={
                  this.validator.message(
                    "Geo Location",
                    this.state.mon_end,
                    "required"
                  )
                    ? "input-group has-error"
                    : "input-group"
                }
              >
                <input
                  type="time"
                  className="form-control"
                  value={this.state.mon_end}
                  name="mon_end"
                  onChange={this.handleEdit}
                />
                <span className="input-group-addon">
                  <i className="fa fa-clock-o" />
                </span>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Tuesday</label>
            <div className="form-inline">
              <div
                className={
                  this.validator.message(
                    "Geo Location",
                    this.state.tue_start,
                    "required"
                  )
                    ? "input-group has-error"
                    : "input-group"
                }
              >
                <input
                  type="time"
                  className="form-control"
                  name="tue_start"
                  value={this.state.tue_start}
                  onChange={this.handleEdit}
                />
                <span className="input-group-addon">
                  <i className="fa fa-clock-o" />
                </span>
              </div>
              <div
                className={
                  this.validator.message(
                    "Geo Location",
                    this.state.tue_end,
                    "required"
                  )
                    ? "input-group has-error"
                    : "input-group"
                }
              >
                <input
                  type="time"
                  className="form-control"
                  value={this.state.tue_end}
                  name="tue_end"
                  onChange={this.handleEdit}
                />
                <span className="input-group-addon">
                  <i className="fa fa-clock-o" />
                </span>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Wednesday</label>
            <div className="form-inline">
              <div
                className={
                  this.validator.message(
                    "Geo Location",
                    this.state.wed_start,
                    "required"
                  )
                    ? "input-group has-error"
                    : "input-group"
                }
              >
                <input
                  type="time"
                  className="form-control"
                  name="wed_start"
                  value={this.state.wed_start}
                  onChange={this.handleEdit}
                />
                <span className="input-group-addon">
                  <i className="fa fa-clock-o" />
                </span>
              </div>
              <div
                className={
                  this.validator.message(
                    "Geo Location",
                    this.state.wed_end,
                    "required"
                  )
                    ? "input-group has-error"
                    : "input-group"
                }
              >
                <input
                  type="time"
                  className="form-control"
                  value={this.state.wed_end}
                  name="wed_end"
                  onChange={this.handleEdit}
                />
                <span className="input-group-addon">
                  <i className="fa fa-clock-o" />
                </span>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Thursday</label>
            <div className="form-inline">
              <div
                className={
                  this.validator.message(
                    "Geo Location",
                    this.state.thr_start,
                    "required"
                  )
                    ? "input-group has-error"
                    : "input-group"
                }
              >
                <input
                  type="time"
                  className="form-control"
                  name="thr_start"
                  value={this.state.thr_start}
                  onChange={this.handleEdit}
                />
                <span className="input-group-addon">
                  <i className="fa fa-clock-o" />
                </span>
              </div>
              <div
                className={
                  this.validator.message(
                    "Geo Location",
                    this.state.thr_end,
                    "required"
                  )
                    ? "input-group has-error"
                    : "input-group"
                }
              >
                <input
                  type="time"
                  className="form-control"
                  value={this.state.thr_end}
                  name="thr_end"
                  onChange={this.handleEdit}
                />
                <span className="input-group-addon">
                  <i className="fa fa-clock-o" />
                </span>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Friday</label>
            <div className="form-inline">
              <div
                className={
                  this.validator.message(
                    "Geo Location",
                    this.state.fri_start,
                    "required"
                  )
                    ? "input-group has-error"
                    : "input-group"
                }
              >
                <input
                  type="time"
                  className="form-control"
                  name="fri_start"
                  value={this.state.fri_start}
                  onChange={this.handleEdit}
                />
                <span className="input-group-addon">
                  <i className="fa fa-clock-o" />
                </span>
              </div>
              <div
                className={
                  this.validator.message(
                    "Geo Location",
                    this.state.fri_end,
                    "required"
                  )
                    ? "input-group has-error"
                    : "input-group"
                }
              >
                <input
                  type="time"
                  className="form-control"
                  value={this.state.fri_end}
                  name="fri_end"
                  onChange={this.handleEdit}
                />
                <span className="input-group-addon">
                  <i className="fa fa-clock-o" />
                </span>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Saturday</label>
            <div className="form-inline">
              <div
                className={
                  this.validator.message(
                    "Geo Location",
                    this.state.sat_start,
                    "required"
                  )
                    ? "input-group has-error"
                    : "input-group"
                }
              >
                <input
                  type="time"
                  className="form-control"
                  name="sat_start"
                  value={this.state.sat_start}
                  onChange={this.handleEdit}
                />
                <span className="input-group-addon">
                  <i className="fa fa-clock-o" />
                </span>
              </div>
              <div
                className={
                  this.validator.message(
                    "Geo Location",
                    this.state.sat_end,
                    "required"
                  )
                    ? "input-group has-error"
                    : "input-group"
                }
              >
                <input
                  type="time"
                  className="form-control"
                  value={this.state.sat_end}
                  name="sat_end"
                  onChange={this.handleEdit}
                />
                <span className="input-group-addon">
                  <i className="fa fa-clock-o" />
                </span>
              </div>
            </div>
          </div>
          <div className="form-group">
            <button className="btn btn-primary" onClick={this.submitAdd}>
              Save
            </button>
          </div>
        </div>
      </div>
    );
    return (
      <div>
        <SettingContainer
          pagename="Setting"
          pagedetailname="Calendar"
          location="working"
        >
          {/* {!this.state.state ? <LoadingModal /> : ""} */}
          <div className="panel panel-default">
            {/* {this.props.workingReducer.list_working} */}
            <div className="tabs-xs">
              <div className="tab-content">
                <div className="panel-heading">State Calendar</div>
                <NotificationContainer />
                <div className="panel panel-default">
                  <div className="panel-body form form-xs">
                    {!this.state.state ? (
                      <i
                        className="fa fa-spinner fa-spin fa-3x"
                        style={{
                          opacity: "0.3",
                          top: "50%",
                          left: "50%",
                          position: "absolute"
                        }}
                      />
                    ) : (
                      content
                    )}
                    {/* {content} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SettingContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  workingReducer: state.workingReducer
});

export default connect(
  mapStateToProps,

  { working_list, update_working }
)(calendar);
