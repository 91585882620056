import React, { Component } from 'react';
import TextInputDisabled from "../../../common/TextInputDisabled";
import moment from 'moment';

class CustomerInformation extends Component{
  render () {
    return (
      <React.Fragment>
        <div className="panel panel-default">
          <div className="panel-heading">Originator</div>
          <div className="panel-body">
            <div className="form form-xs">
              <div className="row row-xs">
                <div className="col-md-6">
                  <TextInputDisabled
                    label="Department"
                    value={localStorage.level}
                  />
                  <TextInputDisabled label="Date/Time Created" value={moment(this.props.date).format('L HH:mm ')}/>
                </div>
                <div className="col-md-6">
                  <TextInputDisabled label="Name" value={localStorage.username} />
                  <TextInputDisabled label="User ID" value={localStorage.iduser} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default CustomerInformation;
