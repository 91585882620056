import React, { Component } from "react";
import axios from "../../../../axios-env";

import SettingContainer from "../Container";
//import TextInputGroup from "../../../common/TextInputGroup";
import Pagination from "../../../../components/common/Pagination";

import PaginationReactTable from "../../../common/PaginationReactTable";
import ReactTable from "react-table";
import "react-table/react-table.css";

import SimpleReactValidator from "simple-react-validator";
import Flash from "../../../../components/common/FlashMessage";

import ReactExport from "react-data-export";
import moment from "moment";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class MachineModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      machine: [],
      file: "",
      alert_type: "",
      alert_message: "",
      disable: true
    };
    this.validator = new SimpleReactValidator();
  }

  addFiles = e => {
    e.preventDefault();

    console.log(e.target.files[0]);
    this.setState({ ...this.state, file: e.target.files[0] });
  };

  uploadHandler = e => {
    e.preventDefault();
    if (this.state.file !== "") {
      const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          "Content-Type": "multipart/form-data"
        }
      };
      let file = this.state.file;
      let formData = new FormData();
      formData.append("file", file);

      axios
        .post("/api/v1/setting/machine/import", formData, headers)
        .then(res => {
          const meta = res.data.meta; //const data = res.data.data;
          if (meta.code === 200) {
            this.setState({
              ...this.state,
              alert_type: "success",
              alert_message: "Data has been imported"
            });
            setTimeout(() => {
              this.setState({
                ...this.state,
                alert_type: "success",
                alert_message: "Data has been imported"
              });
              window.location.reload();
            }, 2000);
          } else {
            this.setState({
              ...this.state,
              alert_type: "error",
              alert_message: "Failed to Import Data"
            });
          }
        })
        .catch(error => {
          console.log("error", error);
          this.setState({
            ...this.state,
            alert_type: "error",
            alert_message: "Something error"
          });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    // console.log("Log this state machine", this.state.machine);
    let recordLength = this.state.machine.length;
    let alert = "";
    if (this.state.alert_type) {
      alert = (
        <Flash
          type={this.state.alert_type}
          message={this.state.alert_message}
        />
      );
    }

    let columns = [

      {
        Header: "MODEL",
        columns: [
          {
            accessor: "model_name",
            show: true
          }
        ]
      },
      {
        Header: "TONER",
        columns: [
          {
            accessor: "description",
            show: true
          }
        ]
      },
      {
        Header: "PART NUMBER",
        columns: [
          {
            accessor: "part_number",
            show: true
          }
        ]
      },
      {
        Header: "PRICE",
        columns: [
          {
            accessor: "price",
            show: true
          }
        ]
      }
    ];

    return (
      <div>
        <SettingContainer
          pagename="UTILITY"
          location="consumable-machine-modal"
        >
          {alert}
          <div className="panel panel-default">
            <div className="panel-heading">Machine Model Management</div>
            <div className="panel-body form form-xs">
              <div className="row">
                <div className="col-md-4">
                  <div className="well form form-xs">
                    <div className="alert alert-info text-center">
                      Please upload the data using right excel format
                    </div>
                    <div className="form-group">
                      <label>Download</label>
                      <div>
                        {this.state.machine !== "" ? (
                          <ExcelFile
                            element={
                              <button
                                className="btn btn-success"
                                onClick={() =>
                                  this.setState({ disable: false })
                                }
                              >
                                Download
                              </button>
                            }
                            filename="MachineModelManagement"
                          >
                            <ExcelSheet data={this.state.machine} name="Sheet1">
                              <ExcelColumn label="id" value="id" />
                              <ExcelColumn label="model_name" value="model_name" />
                              <ExcelColumn
                                  label="part_number"
                                  value="part_number"
                              />
                              <ExcelColumn label="description" value="description" />

                              <ExcelColumn label="price" value="price" />
                              <ExcelColumn
                                label="life_span"
                                value="life_span"
                              />
                              <ExcelColumn label="stock" value="stock" />
                              <ExcelColumn
                                label="sap_material_code"
                                value="sap_material_code"
                              />
                            </ExcelSheet>
                          </ExcelFile>
                        ) : (
                          <button className="btn btn-danger">Download</button>
                        )}
                        {/* <button className="btn btn-danger">Download</button> */}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Upload</label>
                      <input
                        type="file"
                        name="customer_file"
                        onChange={this.addFiles}
                      />
                    </div>
                    <span
                      className="help-block form-error"
                      style={{ color: "#8B0000" }}
                    >
                      {this.validator.message(
                        "upload",
                        this.state.file,
                        "required"
                      )}
                    </span>
                    <div className="form-group">
                      <button
                        className="btn btn-primary"
                        onClick={this.uploadHandler}
                        disabled={this.state.disable}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <ReactTable
                      columns={columns}
                      data={this.state.data}
                      previousText="<<"
                      nextText=">>"
                      PaginationComponent={PaginationReactTable}
                      resizable={true}
                      key="id"
                      minRows={1}
                      noDataText="No Matches Found"
                      onFetchData={(state, instance) => {
                        this.forceUpdate();
                        // show the loading overlay
                        this.setState({ loading: true });
                        // fetch your data
                        const headers = {
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`
                          }
                        };

                        axios
                            .get(
                                `/api/v1/setting/machine/list`,
                                // data,
                                headers
                            )

                            .then(res => {
                              const mod = res.data.data.total % 5;
                              const addmod = mod > 0 ? 1 : 0;

                              this.setState({
                                data: res.data.data,
                                pages:
                                    res.data.data.length === 0
                                        ? 1
                                        : (res.data.data.length - mod) / 5 + addmod,
                                loading: false
                              });
                            });
                      }}
                      ref={refReactTable => {
                        this.refReactTable = refReactTable;
                      }}
                      defaultPageSize={10}
                  />
                </div>
              </div>
            </div>
          </div>
        </SettingContainer>
      </div>
    );
  }
}

export default MachineModel;
