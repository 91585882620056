import React, { Component } from "react";
import { Link } from "react-router-dom";
import LayoutContainer from "../../../layout/Container";
import "./Container.css";

const TicketManagementContainer = props => {
  return (
    <LayoutContainer
      icon="fa fa-ticket icon"
      pagename={props.pagename}
      pagedetailname={props.pagedetailname}
      sidebarinfo="TicketManagement"
    >
      <div id="main" className="main-xs">
        <nav className="tabs-xs tabs-bg-blue">
          <ul className="nav nav-tabs">
            <li
              className={props.location === "ticket-management" ? "active" : ""}
            >
              <Link to="/ticket-management">Ticket Management</Link>
            </li>
            <li
              className={props.location === "quotation-inbox" ? "active" : ""}
            >
              <Link to="/ticket-management/quotation-inbox">
                Quotation Inbox
              </Link>
            </li>
          </ul>
        </nav>
        {props.children}
      </div>
    </LayoutContainer>
  );
};

export default TicketManagementContainer;
