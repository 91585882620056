import {
  GET_SPR,
  CHECK_STOCK,
  ORDER_DB,
  ORDER_WSP,
  PRINT_S
  // EDIT_PM_CUST,
  // UPDATE_PM_CUST,
  // LIST_CUSTOMER_ADD,
  // STORE_PM_CUST,
  // DEL_PM_CUST,
  // UPDATE_DEFAULT_DAYS
} from "../actions/types";

const initialState = {
  spr: [],
  action_by: [],
  ticket_origin: [],
  originator: [],
  department: [],
  machine_customer: [],
  customers: [],
  machine: [],
  machine_models: [],
  customer: [],
  technician: [],
  blocked_by: [],
  contracts: [],
  sales: [],
  service_code: [],
  tracking_spr: [],
  checkStock: [],
  contact_detail: [],
  order_db: [],
  order_wsp: [],
  res_cek: [],
  res_wsp: [],
  resStn: [],
  transfer_order: [],
  spr: [],
  res_stn: []
  // customer: [],
  // dataw: [],
  // resUpdateDays: [],
  // pm_default: [],
  // customers: [],
  // resDelPmCust: [],
  // resUpdateDefaultDays: []
  //   level: [],
  //   save_user: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SPR:
      return {
        ...state,
        spr: action.payload,
        action_by: action.action_by,
        ticket_origin: action.ticket_origin,
        originator: action.originator,
        department: action.department,
        machine_customer: action.machine_customer,
        customers: action.customers,
        machine: action.machine,
        machine_models: action.machine_models,
        customer: action.customer,
        technician: action.technician,
        blocked_by: action.blocked_by,
        contracts: action.contracts,
        sales: action.sales,
        service_code: action.service_code,
        tracking_spr: action.tracking_spr,
        contact_detail: action.contact_detail
        // departmentx: action.department,
        // level: action.level
      };
    case CHECK_STOCK:
      return {
        ...state,
        checkStock: action.payload,
        res_cek: action.res_cek
      };
    case ORDER_DB:
      return {
        ...state,
        order_db: action.payload
      };
    case ORDER_WSP:
      return {
        ...state,
        order_wsp: action.payload,
        res_wsp: action.res_wsp
      };

    case PRINT_S:
      return {
        ...state,
        resStn: action.payload,
        transfer_order: action.transfer_order,
        spr: action.spr,
        res_stn: action.res_stn
      };
    //     case UPDATE_DEFAULT_DAYS:
    //     return {
    //       ...state,
    //       resUpdateDefaultDays: action.payload
    //     };

    default:
      return state;
  }
}
