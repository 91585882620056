import React, { Component } from "react";
import axios from "axios";
import moment from "moment";

import Pagination from "../../common/Pagination";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ticketInbox, del_inbox } from "../../../actions/getUserActions";
import {
  // processTicketAssignCE,
  // saveOtp,
  saveAssignCE
} from "../../../actions/assignCe";
import { saveHelpDesk } from "../../../actions/helpDesk";
import { ClipLoader } from "react-spinners";
import DatePicker, { subDays } from "react-datepicker";
// import DatePicker from "react-date-picker";

import "react-datepicker/dist/react-datepicker.css";
import "./reactDatePicker.css";
// import GoogleMapReact from "google-map-react";
// import iconGreen from "../../../img/icon-green.png";
// import iconRed from "../../../img/icon-red.png";
// const MarkerGreen = ({ text }) => (
//   <div>
//     <img src={iconGreen} alt="" />
//     {text}
//   </div>
// );
// const MarkerRed = ({ text }) => (
//   <div>
//     <img src={iconRed} alt="" />
//     {text}
//   </div>
// );
let notification = "";
let head = "";
let table = "";
let page = "";

class TicketInbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticket_type: [],
      ticket_status: [],
      search: "",
      ticket_pic: [],
      ticket_stat_pic: [],
      currentTickets: [],
      currentPage: null,
      totalPages: null,
      start_date: "",
      end_date: "",
      xdate: ""

      // center: {
      //   lat: 1.338,
      //   lng: 103.977
      // },
      // zoom: 11
    };
    this.onChanged = this.onChanged.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndtDate = this.handleEndtDate.bind(this);
    this.reset = this.reset.bind(this);
    // const jwtToken = localStorage.getItem("jwtToken");
    // this.props.ticketInbox(jwtToken);
  }

  // componentWillReceiveProps(newProps) {
  //   const jwtToken = localStorage.getItem("jwtToken");
  //   this.props.ticketInbox(jwtToken);
  //   console.log("Component WILL RECIEVE PROPS!");
  // }
  componentDidMount() {
    const jwtToken = localStorage.getItem("jwtToken");
    this.props.ticketInbox(jwtToken);
    // console.log("did mount in");
    // {
    // setTimeout(
    //   function() {
    //     // this.props.ticketInbox(jwtToken);
    //   }.bind(this),
    //   3000
    // );
    // this.lookupInterval = setInterval(() => {
    //   const jwtToken = localStorage.getItem("jwtToken");
    //   this.props.ticketInbox(jwtToken);
    // }, 5000);
  }

  // componentWillUnmount() {
  //   // this.props.del_inbox();
  //   // window.location.reload();
  //   clearInterval(this.lookupInterval);
  //   // console.log("ini meninggalkan......");
  // }
  // componentDidUnmount() {
  //   // this.props.del_inbox();
  //   console.log("ini meninggalkan did......");
  // }

  // Function to handle pagination
  onPageChanged = data => {
    // const { tickets } = this.state;

    const { ticketIn } = this.props.tic_in;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentTickets = ticketIn.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentTickets, totalPages });
  };

  reset() {
    window.location.reload();
  }

  // handleStartDate(event) {
  //   this.setState({ start_date: event.target.value });
  // }
  // handleEndtDate(event) {
  //   this.setState({ end_date: event.target.value });
  // }

  handleChange(event) {
    this.setState({ ticket_pic: event.target.value });
  }

  handleChange2(event) {
    this.setState({ ticket_stat_pic: event.target.value });
  }

  onChanged(e) {
    this.setState({ search: e.target.value.substr(0, 20) });
  }
  onChangex = xdate => this.setState({ xdate });
  handleStartDate = start_date => this.setState({ start_date });
  handleEndtDate = end_date => this.setState({ end_date });

  render() {
    if (this.props.tic_in.ticket_typeIn !== null) {
      if (this.props.tic_in.ticket_typeIn.length !== 0) {
        const { resSaveAssign } = this.props.assignCe;
        const { ticketIn } = this.props.tic_in;
        const { resSaveHelpDesk } = this.props.helpDesk;
        // console.log("ticketIn");
        // console.log(ticketIn);
        // console.log("resSaveAssign");
        // console.log(resSaveAssign);
        // console.log("resSaveHelpDesk");
        // console.log(resSaveHelpDesk);
        let notification;
        if (resSaveAssign) {
          if (resSaveAssign.length !== 0) {
            if (!resSaveAssign.error) {
              notification = (
                <div className="modal display-block">
                  <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                      <div
                        className="modal-body text-center text-success bg-success"
                        style={{ padding: "30px" }}
                      >
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-hidden="true"
                          onClick={this.reset}
                        >
                          &times;
                        </button>
                        <i className="fa fa-check-circle fa-5x" />
                        <br />
                        <br />
                        <p>{resSaveAssign.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            if (resSaveAssign.error) {
              notification = (
                <div className="modal display-block">
                  <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                      <div
                        className="modal-body text-center text-danger bg-danger"
                        style={{ padding: "30px" }}
                      >
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-hidden="true"
                          onClick={this.reset}
                        >
                          &times;
                        </button>
                        <i className="fa fa-check-circle fa-5x" />
                        <br />
                        <br />
                        <p>{resSaveAssign.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          }
        }
        if (resSaveHelpDesk) {
          if (resSaveHelpDesk.length !== 0) {
            notification = (
              <div className="modal display-block">
                <div className="modal-dialog modal-sm">
                  <div className="modal-content">
                    <div
                      className="modal-body text-center text-success bg-success"
                      style={{ padding: "30px" }}
                    >
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-hidden="true"
                        onClick={this.reset}
                      >
                        &times;
                      </button>
                      <i className="fa fa-check-circle fa-5x" />
                      <br />
                      <br />
                      <p>{resSaveHelpDesk.message}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        }

        const { ticket_typeIn } = this.props.tic_in;
        const { ticket_statusIn } = this.props.tic_in;
        ticket_typeIn.length = 9;
        let filtered = ticketIn.slice();
        if (this.state.search) {
          filtered = filtered.filter(ticket =>
            ticket.ticket_origin.machine_customer.machine.equipment_number !==
            null
              ? ticket.ticket_origin.machine_customer.machine.equipment_number
                  .toLowerCase()
                  .toString()
                  .indexOf(this.state.search.toLowerCase().toString()) !== -1
              : "" ||
                ticket.ticket_origin.machine_customer.machine.machine_serial !==
                  null
              ? ticket.ticket_origin.machine_customer.machine.machine_serial
                  .toLowerCase()
                  .toString()
                  .indexOf(this.state.search.toLowerCase().toString()) !== -1
              : "" ||
                ticket.ticket_origin.machine_customer.ship_to_name !== null
              ? ticket.ticket_origin.machine_customer.ship_to_name
                  .toLowerCase()
                  .toString()
                  .indexOf(this.state.search.toLowerCase().toString()) !== -1
              : ""
          );
        }

        if (this.state.ticket_pic) {
          filtered = filtered.filter(
            ticket =>
              ticket.ticket_type.name.indexOf(this.state.ticket_pic) !== -1
          );
        }

        if (this.state.ticket_stat_pic) {
          filtered = filtered.filter(
            ticket =>
              ticket.ticket_origin.ticket_status.name.indexOf(
                this.state.ticket_stat_pic
              ) !== -1
          );
        }

        if (
          this.state.start_date.length !== 0 ||
          this.state.end_date.length !== 0
        ) {
          filtered = filtered.filter(
            ticket =>
              moment(this.state.start_date).format("YYYY-MM-DD") <=
                ticket.created_at &&
              ticket.created_at <=
                moment(this.state.end_date)
                  .add(1, "days")
                  .format("YYYY-MM-DD")
            // moment(this.state.start_date) - ticket.created_at <= 0 ||
            // moment(this.state.end_date) - ticket.created_at >= 0
          );
        }

        const {
          currentTickets,
          ticket_pic,
          ticket_stat_pic,
          search,
          ticket_type,
          ticket_status,
          start_date,
          end_date
        } = this.state;

        const totalTickets = ticketIn.length;

        if (totalTickets === 0) return null;
        // console.log({ filtered });
        // console.log("ko.....", this.props.tic_in.ticket_typeIn.length);
        const active = this.props.tic_in.ticket_typeIn.length;
        head = (
          <div className="panel-body form-xs">
            <div className="pull-left form-inline">
              {/* <label htmlFor="">
                From
                
              </label> */}
              <DatePicker
                className="form-control "
                selected={this.state.start_date}
                style={{ width: "130px" }}
                onChange={this.handleStartDate}
                selectsStart
                startDate={this.state.start_date}
                endDate={this.state.end_date}
                placeholderText="Start date"
                isClearable={start_date}
                // name="start_date"
                // onChange={this.handleAssignCE}
                // excludeDates={listcal}
                // highlightDates={listcal}
                // minDate={new Date("2019", "05", "07")}
                // filterDate={date => {
                //   return moment() > date;
                // }}
                // showTimeSelect
                // timeFormat="HH:mm"
                // timeIntervals={15}
                // dateFormat="MMMM d, yyyy h:mm aa"
                // timeCaption="time"
                // startDate={this.state.valueOfInput}
                // endDate={this.state.endDate}
                // selectsStart
              />
              {/* <DatePicker
                onChange={this.onChangex}
                value={this.state.xdate}
                
              /> */}
              {/* <input
                type="date"
                className="form-control"
                style={{ width: "130px" }}
                onChange={this.handleStartDate}
                name="start_date"
                value={start_date}
              /> */}
              <label htmlFor="">{/* To */}-</label>
              <DatePicker
                className="form-control "
                selected={this.state.end_date}
                onChange={this.handleEndtDate}
                style={{ width: "130px" }}
                selectsEnd
                startDate={this.state.start_date}
                endDate={this.state.end_date}
                minDate={this.state.start_date}
                placeholderText="End date"
                isClearable={end_date}
              />
              {/* <input
                type="date"
                className="form-control"
                style={{ width: "130px" }}
                onChange={this.handleEndtDate}
                name="end_date"
                value={end_date}
              /> */}
              <label htmlFor="">Ticket Type </label>
              <select
                style={{ width: "100px" }}
                className="form-control-xs"
                onChange={this.handleChange}
                // value={ticket_pic}
                name="ticket_pic"
                // multiple={true}
              >
                <option value="">-</option>

                {ticket_typeIn.map(tic => (
                  <option value={tic.name} key={tic.id}>
                    {tic.name}
                  </option>
                ))}
              </select>
              <label htmlFor="">Status Ticket </label>
              <select
                name=""
                id=""
                className="form-control-xs"
                onChange={this.handleChange2}
                // value={ticket_stat_pic}
                name="ticket_stat_pic"
                style={{ width: "100px" }}
                // multiple={true}
              >
                <option value="">-</option>

                {ticket_statusIn.map(tic => (
                  <option value={tic.name} key={tic.id}>
                    {tic.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="pull-right form-inline">
              <label htmlFor="">Serial, Equipment or Name</label>
              <input
                type="text"
                className="form-control"
                style={{ width: "100px" }}
                value={search}
                onChange={this.onChanged.bind(this)}
                placeholder="search"
                name="search"
              />
            </div>
            <div className="clearfix" />
          </div>
        );
        table = (
          <div className="table-responsive form-xs">
            <table className="table table-xs table-bordered">
              <thead>
                <tr>
                  <th>Date Time</th>
                  <th>From</th>
                  <th>Service Order</th>
                  <th>Equipment No</th>
                  <th>Serial Number</th>
                  <th>Model No</th>
                  <th>Shipping Name</th>
                  <th>Ticket No</th>
                  <th>Ticket Type</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {search === "" &&
                ticket_pic.length === 0 &&
                ticket_stat_pic.length === 0 &&
                start_date.length === 0 &&
                end_date.length === 0
                  ? currentTickets.map(ticket => {
                      let ticketType;
                      if (ticket.ticket_type.name === "Help Desk") {
                        ticketType = (
                          <Link
                            to={`/ticket-management/processtickethelpdesk/${ticket.id}`}
                          >
                            <button type="button" className="btn btn-primary">
                              SELECT
                            </button>
                          </Link>
                        );
                      }
                      if (ticket.ticket_type.name === "Assign CE") {
                        ticketType = (
                          <Link to={`/ticket-management/assignce/${ticket.id}`}>
                            <button type="button" className="btn btn-primary">
                              SELECT
                            </button>
                          </Link>
                        );
                      }
                      if (
                        ticket.ticket_type.name === "Consumable Fulfillment"
                      ) {
                        ticketType = (
                          <Link
                            to={`/ticket-management/consumable/${ticket.id}`}
                          >
                            <button type="button" className="btn btn-primary">
                              SELECT
                            </button>
                          </Link>
                        );
                      }
                      if (
                        ticket.ticket_type.name === "Spare-Parts Fulfillment"
                      ) {
                        ticketType = (
                          <Link to={`/ticket-management/spr/${ticket.id}`}>
                            <button type="button" className="btn btn-primary">
                              SELECT
                            </button>
                          </Link>
                        );
                      }
                      if (
                        ticket.ticket_type.name === "Spare-Parts Reconciliation"
                      ) {
                        ticketType = (
                          <Link
                            to={`/ticket-management/spr_reconciliation/${ticket.id}`}
                          >
                            <button type="button" className="btn btn-primary">
                              SELECT
                            </button>
                          </Link>
                        );
                      }
                     if (
                        ticket.ticket_type.name === "Invoice"
                      ) {
                        ticketType = (
                          <Link
                            to={`/ticket-management/invoice/${ticket.id}`}
                          >
                            <button type="button" className="btn btn-primary">
                              SELECT
                            </button>
                          </Link>
                        );
                      }
                      return (
                        <tr key={ticket.id}>
                          <td>
                            {ticket.created_at === null
                              ? "-"
                              : moment(ticket.created_at).format("L")}
                          </td>
                          <td>
                            {" "}
                            {ticket.action_by.username === null
                              ? "-"
                              : ticket.action_by.username}{" "}
                          </td>
                          <td>
                            {" "}
                            {ticket.ticket_origin.service_code.job_code === null
                              ? "-"
                              : ticket.ticket_origin.service_code.job_code}{" "}
                          </td>
                          <td>
                            {" "}
                            {ticket.ticket_origin.machine_customer.machine
                              .equipment_number === null
                              ? "-"
                              : ticket.ticket_origin.machine_customer.machine
                                  .equipment_number}{" "}
                          </td>
                          <td>
                            {" "}
                            {ticket.ticket_origin.machine_customer.machine
                              .machine_serial === null
                              ? "-"
                              : ticket.ticket_origin.machine_customer.machine
                                  .machine_serial}{" "}
                          </td>
                          <td>
                            {" "}
                            {ticket.ticket_origin.machine_customer.machine
                              .machine_models.model_name === null
                              ? "-"
                              : ticket.ticket_origin.machine_customer.machine
                                  .machine_models.model_name}{" "}
                          </td>
                          <td>
                            {" "}
                            {ticket.ticket_origin.machine_customer
                              .ship_to_name === null
                              ? "-"
                              : ticket.ticket_origin.machine_customer
                                  .ship_to_name}{" "}
                          </td>
                          <td>
                            {" "}
                            {ticket.ticket_number === null
                              ? "-"
                              : ticket.ticket_number}{" "}
                          </td>
                          <td>
                            {" "}
                            {ticket.ticket_type.name === null
                              ? "-"
                              : ticket.ticket_type.name}{" "}
                          </td>
                          <td>
                            {" "}
                            {ticket.ticket_origin.ticket_status.name === null
                              ? "-"
                              : ticket.ticket_origin.ticket_status.name}{" "}
                          </td>
                          <td>
                            <div className="row">
                              <div className="col-md-2">{ticketType}</div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : filtered.map(ticket => {
                      let ticketType;
                      if (ticket.ticket_type.name === "Help Desk") {
                        ticketType = (
                          <Link
                            to={`/ticket-management/processtickethelpdesk/${ticket.id}`}
                          >
                            <button type="button" className="btn btn-primary">
                              SELECT
                            </button>
                          </Link>
                        );
                      }
                      if (ticket.ticket_type.name === "Assign CE") {
                        ticketType = (
                          <Link to={`/ticket-management/assignce/${ticket.id}`}>
                            <button type="button" className="btn btn-primary">
                              SELECT
                            </button>
                          </Link>
                        );
                      }
                      if (
                        ticket.ticket_type.name === "Consumable Fulfillment"
                      ) {
                        ticketType = (
                          <Link
                            to={`/ticket-management/consumable/${ticket.id}`}
                          >
                            <button type="button" className="btn btn-primary">
                              SELECT
                            </button>
                          </Link>
                        );
                      }
                      if (
                        ticket.ticket_type.name === "Spare-Parts Fulfillment"
                      ) {
                        ticketType = (
                          <Link to={`/ticket-management/spr/${ticket.id}`}>
                            <button type="button" className="btn btn-primary">
                              SELECT
                            </button>
                          </Link>
                        );
                      }
                      if (
                        ticket.ticket_type.name === "Spare-Parts Reconciliation"
                      ) {
                        ticketType = (
                          <Link
                            to={`/ticket-management/spr_reconciliation/${ticket.id}`}
                          >
                            <button type="button" className="btn btn-primary">
                              SELECT
                            </button>
                          </Link>
                        );
                      }
                     if (
                        ticket.ticket_type.name === "Invoice"
                      ) {
                        ticketType = (
                          <Link
                            to={`/ticket-management/invoice/${ticket.id}`}
                          >
                            <button type="button" className="btn btn-primary">
                              SELECT
                            </button>
                          </Link>
                        );
                      }
                      return (
                        <tr>
                          <td>
                            {ticket.created_at === null
                              ? "-"
                              : moment(ticket.created_at).format("L")}
                          </td>
                          <td>
                            {" "}
                            {ticket.action_by.username === null
                              ? "-"
                              : ticket.action_by.username}{" "}
                          </td>
                          <td>
                            {" "}
                            {ticket.ticket_origin.service_code.job_code === null
                              ? "-"
                              : ticket.ticket_origin.service_code.job_code}{" "}
                          </td>
                          <td>
                            {" "}
                            {ticket.ticket_origin.machine_customer.machine
                              .equipment_number === null
                              ? "-"
                              : ticket.ticket_origin.machine_customer.machine
                                  .equipment_number}{" "}
                          </td>
                          <td>
                            {" "}
                            {ticket.ticket_origin.machine_customer.machine
                              .machine_serial === null
                              ? "-"
                              : ticket.ticket_origin.machine_customer.machine
                                  .machine_serial}{" "}
                          </td>
                          <td>
                            {" "}
                            {ticket.ticket_origin.machine_customer.machine
                              .machine_models.model_name === null
                              ? "-"
                              : ticket.ticket_origin.machine_customer.machine
                                  .machine_models.model_name}{" "}
                          </td>
                          <td>
                            {" "}
                            {ticket.ticket_origin.machine_customer
                              .ship_to_name === null
                              ? "-"
                              : ticket.ticket_origin.machine_customer
                                  .ship_to_name}{" "}
                          </td>
                          <td>
                            {" "}
                            {ticket.ticket_number === null
                              ? "-"
                              : ticket.ticket_number}{" "}
                          </td>
                          <td>
                            {" "}
                            {ticket.ticket_type.name === null
                              ? "-"
                              : ticket.ticket_type.name}{" "}
                          </td>
                          <td>
                            {" "}
                            {ticket.ticket_origin.ticket_status.name === null
                              ? "-"
                              : ticket.ticket_origin.ticket_status.name}{" "}
                          </td>
                          <td>
                            <div className="row">
                              <div className="col-md-2">{ticketType}</div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        );
        page = (
          <div>
            {search === "" &&
            ticket_pic.length === 0 &&
            ticket_stat_pic.length === 0 &&
            start_date.length === 0 &&
            end_date.length === 0 ? (
              <div className="panel-footer form-xs">
                <Pagination
                  totalRecords={totalTickets}
                  pageLimit={4}
                  pageNeighbours={1}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            ) : (
              <div />
            )}
          </div>
        );
      }
    }

    // console.log("ko.....", this.props.tic_in.ticket_typeIn.length);
    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          Ticket Inbox
          {/* {this.props.tic_in.ticket_typeIn.length} */}
        </div>
        {/* {this.props.tic_in.ticket_typeIn.length === 0 ? <div>hjg</div> : */}

        <div>
          {/* {this.props.tic_in.ticket_typeIn.length !== 0 ? notification : ""} */}
          {notification ? notification : ""}
        </div>
        <div>
          {head
            ? head
            : // <div style={{ textAlign: "center" }}>
              //   <br />
              //   {/* <ClipLoader sizeUnit={"px"} size={50} color={"#123abc"} /> */}
              //   <i
              //     className="fa fa-spinner fa-spin fa-2x"
              //     style={{ opacity: "0.3" }}
              //   />
              //   <br />
              //   <br />
              // </div>
              ""}
        </div>
        <div>
          {table ? (
            table
          ) : (
            <div className="table-responsive form-xs">
              <table className="table table-xs table-bordered">
                <thead>
                  <tr>
                    <th>Date Time</th>
                    <th>From</th>
                    <th>Service Order</th>
                    <th>Equipment No</th>
                    <th>Serial Number</th>
                    <th>Model No</th>
                    <th>Shipping Name</th>
                    <th>Ticket No</th>
                    <th>Ticket Type</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="11">
                      <div style={{ textAlign: "center" }}>
                        No Data Available in This Table
                        <br />
                        {/* <ClipLoader sizeUnit={"px"} size={50} color={"#123abc"} /> */}
                        <i
                          className="fa fa-spinner fa-spin fa-2x"
                          style={{ opacity: "0.3" }}
                        />
                        <br />
                        <br />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div>{page ? page : ""}</div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  // ticketIn: state.ticketIn,
  // ticket_type: state.ticket_typeIn,
  // ticket_status: state.ticket_statusIn

  assignCe: state.assignCe,
  tic_in: state.user,
  helpDesk: state.helpDesk
});
export default connect(
  mapStateToProps,
  { ticketInbox, saveAssignCE, saveHelpDesk, del_inbox }
)(TicketInbox);
