import {
  HOLIDAY,
  ADD_HOLIDAY,
  EDIT_HOLIDAY,
  DELETE_HOLIDAY,
  DEL_EDIT_HOLIDAY
} from "../actions/types";

const initialState = {
  list_holiday: [],
  store_holiday: [],
  res_edit_holiday: []
  // res_edit_holiday2: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case HOLIDAY:
      return {
        ...state,
        list_holiday: action.payload
        // working: action.working
      };
    case ADD_HOLIDAY:
      return {
        ...state,
        store_holiday: action.payload
        // working: action.working
      };
    case EDIT_HOLIDAY:
      // console.log("delete sdasdx");

      return {
        ...state,
        res_edit_holiday: action.payload
        // working: action.working
      };

    case DEL_EDIT_HOLIDAY:
      // console.log("delete xxx");
      return {
        ...state,
        res_edit_holiday: action.payload
        // working: action.working
      };
    case DELETE_HOLIDAY:
      return {
        ...state,
        res_delete_holiday: action.payload
        // working: action.working
      };
    // case DELETE_ORIGINATION_CUSTOMER:
    //   console.log("delete origin");
    //   console.log(state);
    //   return {
    //     ...state,
    //     customer: {}
    //   };
    default:
      return state;
  }
}
