import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../layout/Navbar";
// import DatePicker, { subDays } from "react-datepicker";
import Sidebar from "../../layout/Sidebar";
// import "react-datepicker/dist/react-datepicker.css";
// import axios from "axios";
// import ModalCustomer from "../../common/ModalCustomer";
// import ModalMachine from "../../common/ModalMachine";
import TextInputGroup from "../../common/TextInputGroup";
import TextInputDisabled from "../../common/TextInputDisabled";

import ModalHeader from "../../common/ModalHeader";
import ModalFooter from "../../common/ModalFooter";
import { connect } from "react-redux";
import moment from "moment";
import {
  processTicketAssignCE,
  saveOtp,
  saveAssignCE,
  del_otp,
  del_assign,
  ce_location
} from "../../../actions/assignCe";
import { calendar } from "../../../actions/calendar";
import { mapAction } from "../../../actions/mapAction";

import { listUser } from "../../../actions/getUserActions";
import { ticketTracking } from "../../../actions/ticketTrackingAction";
import NumberFormat from "react-number-format";
import SimpleReactValidator from "simple-react-validator";
import BigCalendar from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Footer from "../../layout/Footer";
// import "react-big-calendar/lib/sass/styles";
import GoogleMapReact from "google-map-react";
import iconGreen from "../../../img/icon-green.png";
import iconRed from "../../../img/icon-red.png";
import iconBlue from "../../../img/icon-blue.png";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { working_list } from "../../../actions/calendarWorkingAction";
import { holiday_list } from "../../../actions/calendar_settingAction";

import "./assignce.css";
let kiri = "";
let kanan = "";
const MarkerGreen = ({ text, color, icon }) => (
  <div>
    <img src={icon} alt="" />
    {/* <label className={}>{text}</label> */}
    <span className={color}>{text}</span>
  </div>
);
const MarkerRed = ({ text }) => (
  <div>
    <img src={iconBlue} alt="" />

    <span className="label label-warning">{text}</span>
  </div>
);
const Butt = ({ action, text }) => (
  <button type="button" className="btn btn-default btn-sm" onClick={action}>
    {text}
  </button>
);
let myEventsList = [];
// let myEventsList2 =[];
let mypick = [];
let aaaww = "";
let working_ho = [];
// let addce = false;
const Info = ({ text }) => <div>{text}</div>;
class AssignCE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickets_det: [],
      originator_des: [],
      originator_des_dep: [],
      originator: [],
      customer: [],
      ticket_details: [],

      call_information: [],
      service_code: [],
      machine: [],
      runing: [],
      yes: [],
      no: [],
      button: [],
      yaho: [],
      action_by: [],
      value: [],
      value2: [],
      value3: [],
      input_otp: "",
      id_otp: "",
      ott: "",
      long: "",
      lat: "",
      respx: [],
      ticket_tracking: [],
      // calendar_find: [],
      ce_cal: [],
      working: [],
      map_location: [],
      center: {
        lat: 1.329447,
        lng: 103.865441
      },
      zoom: 15,
      showingInfoWindow: false,
      activeMarker: {},
      // showingInfoWindow: false,
      // activeMarker: {},
      selectedPlace: {},
      muncul: false,
      ini3: "",
      ini4: "",
      modd: {
        width: 1200
      },
      modd2: {
        width: 1200
      }
      // input_otp: this.props.assign_ce.input_otp
    };
    this.validator = new SimpleReactValidator();
    this.handleDisabled = this.handleDisabled.bind(this);
    this.handleEnabled = this.handleEnabled.bind(this);
    this.handleAssignCE = this.handleAssignCE.bind(this);
    this.handleSaveAssignCE = this.handleSaveAssignCE.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);

    this.handleChange2 = this.handleChange2.bind(this);
    // this.input_otp = this.input_otp.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const jwtToken = localStorage.getItem("jwtToken");

    const data = {
      id: id,
      jwtToken: localStorage.getItem("jwtToken")
    };
    this.props.processTicketAssignCE(data);

    this.props.listUser(jwtToken);
    this.props.working_list(jwtToken);
    this.props.holiday_list(jwtToken);
    this.props.ce_location(jwtToken);

    setTimeout(
      function() {
        const { assign_ce } = this.props.assign_ce;
        this.setState({
          input_otp: assign_ce.input_otp ? assign_ce.input_otp : "",
          button: assign_ce.input_otp ? "disabled" : ""
        });
      }.bind(this),
      3000
    );
  }

  // componentWillReceiveProps() {
  //   if (this.props.assign_ce.assign_ce !== null) {
  //     if (this.props.assign_ce.assign_ce.length !== 0) {
  //       const { assign_ce } = this.props.assign_ce;
  //       this.setState({
  //         input_otp: assign_ce.input_otp,
  //         button: assign_ce.input_otp ? "disabled" : ""
  //       });

  //       console.log("akuuuuu", assign_ce.input_otp);
  //     }
  //   }
  // }
  handleApprove = event => {
    this.setState({
      datex: moment()
    });
  };
  handleAssignCE = e => this.setState({ [e.target.name]: e.target.value });
  input_otp = e => this.setState({ [e.target.name]: e.target.value });
  handleDisabled(event) {
    // event.preventDefault();
    // const { value_otp } = this.state.input_otp.replace(/ /g, "");
    // console.log("otp", this.state.input_otp.toString().length);

    const saveUserData = {
      input_otp:
        this.state.input_otp.toString().length === 4
          ? this.state.input_otp
          : this.state.input_otp.split(" ").join("")
    };

    const datax = {
      dt: saveUserData,
      id_otp: this.props.assign_ce.assign_ce.id,
      jwtToken: localStorage.getItem("jwtToken")
    };
    // console.log("otp");
    // console.log(datax);
    this.props.saveOtp(datax);
    // this.props.history.push("/setting");

    this.setState({
      button: "disabled",
      ott: this.state.input_otp,
      input_otp:
        this.state.input_otp.toString().length === 4
          ? this.state.input_otp
          : this.state.input_otp.split(" ").join("")
    });
    // window.location.reload();

    setTimeout(
      function() {
        this.props.del_otp();
        // console.log("ini hapus");
      }.bind(this),
      1000
    );
  }
  handleSaveAssignCE(event) {
    event.preventDefault();
    // const { value_otp } = this.state.input_otp.replace(/ /g, "");

    if (this.validator.allValid()) {
      const dataMap =
        this.state.pickMap +
        "#" +
        this.state.nameMap +
        "#" +
        this.state.enaMap +
        "#" +
        this.state.lngMap +
        "#" +
        this.state.latMap;
      const ee = dataMap;
      const ff = ee.split("#");
      const id_CE = ff[0];

      const dateall = this.state.ini;
      const datesp = dateall.split("#");
      const datestart = datesp[0];
      const dateend = datesp[1];

      const saveUserData = {
        priority: parseInt(this.state.priority),
        customer_contact_name: this.state.customer_contact_name,
        ce: this.state.valuex,
        // received_by_id: parseInt(id_CE),
        // date_of_assign: this.state.date_of_assign
        date_of_assign: datestart,
        date_to_assign: dateend
      };

      const datax = {
        dt: saveUserData,
        id_otp: this.props.assign_ce.assign_ce.id,
        jwtToken: localStorage.getItem("jwtToken")
      };
      // console.log("asgn ce");
      // console.log(datax);
      //--------------------------------------------------------------------------------------
      this.props.saveAssignCE(datax);
      //----------------------------------------------------------------------------------

      setTimeout(
        function() {
          if (this.props.assign_ce.resSaveAssign) {
            if (this.props.assign_ce.resSaveAssign.code === 200)
              this.props.del_assign();
            this.props.history.push("/ticket-management");
          }
          // console.log("code", this.props.assign_ce.resSaveAssign);
        }.bind(this),
        3000
      );
      // this.setState({ button: "disabled", ott: this.state.input_otp });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  handleEnabled(event) {
    this.setState({ button: "ss" });
  }
  handleSubmit(event) {
    this.setState({ yaho: "ppppp" });
    // console.log(this.state.yaho);

    this.props.history.push("/ticket-management");
  }
  handleChange(date) {
    this.setState({
      valueOfInput: date,
      valueOfInput2: moment(date).format("YYYY-MM-DD")
    });
    // console.log("valueOfInput");
    // console.log(moment(this.state.valueOfInput).format("L"));
  }
  handleChangeEnd(date) {
    this.setState({
      endDate: date
    });
  }

  handleChange2 = e => this.setState({ [e.target.name]: e.target.value });

  save(e) {
    e.preventDefault();
    // const { dt } = this.state;
    // const userData = {
    const dataMap =
      this.state.pickMap +
      "#" +
      this.state.nameMap +
      "#" +
      this.state.enaMap +
      "#" +
      this.state.lngMap +
      "#" +
      this.state.latMap;
    const we = dataMap;
    const sa = we.split("#");
    const id_ce = sa[0];
    const name_ce = sa[1];

    const enable_ce = sa[2];

    // };
    this.state.value.push({
      id_ce: id_ce,
      name_ce: name_ce,
      enable_ce: enable_ce
    });
    this.setState({ value: this.state.value });

    if (this.state.value.length === 1) {
      this.state.value3.push({
        id_ce: id_ce,
        name_ce: name_ce,
        enable_ce: enable_ce,
        type_ce: "Main"
      });
      this.state.value2.push({ id_ce: parseInt(id_ce), type_ce: "Main" });
    }
    if (this.state.value.length > 1) {
      this.state.value3.push({
        id_ce: id_ce,
        name_ce: name_ce,
        enable_ce: enable_ce,
        type_ce: "Alternative"
      });
      this.state.value2.push({
        id_ce: parseInt(id_ce),
        type_ce: "Alternative"
      });
    }

    this.setState({ valuex: this.state.value3 });
    this.setState({ valuex: this.state.value2 });

    // this.state;
    // console.log("vale");
    // console.log(this.state.value);
  }

  handleDeleteCE = () => {
    this.setState({ value: [], valuex: [], value2: [], value3: [] });
  };
  showModalMachine = () => {
    this.setState({ showMachine: true });

    if (this.state.id_ce) {
      const we = this.state.id_ce;
      const sa = we.split("#");
      const id_ce = sa[0];
      // console.log("tracking");
      // console.log(this.props.assign_ce.ticket_origin.id);

      const dataLocation = {
        id: id_ce,
        jwtToken: localStorage.getItem("jwtToken")
      };
      // console.log("dataLocation");
      // console.log(dataLocation);
      this.props.mapAction(dataLocation);
    }
    if (!this.state.id_ce) {
      const id_ce = this.props.assign_ce.technicians.id;
      // console.log("tracking");
      // console.log(this.props.assign_ce.ticket_origin.id);

      const dataLocation = {
        id: id_ce,
        jwtToken: localStorage.getItem("jwtToken")
      };
      // console.log("dataLocation");
      // console.log(dataLocation);
      this.props.mapAction(dataLocation);
    }
  };
  showModalMachineNew = () => {
    this.setState({ showMachineNew: true });
  };
  hideModalNew = () => {
    this.setState({ show: false, showMachineNew: false });
  };
  hideModal = () => {
    this.setState({ show: false, showMachine: false });
  };
  showModalMachine2 = () => {
    document.body.style.overflow = "hidden";
    this.setState({ showMachine2: true });
    // console.log("tracking");
    // console.log(this.props.assign_ce.ticket_origin.id);

    const dataTrack = {
      id: this.props.assign_ce.ticket_origin.id,
      jwtToken: localStorage.getItem("jwtToken")
    };
    this.props.ticketTracking(dataTrack);
  };
  hideModal2 = () => {
    document.body.style.overflow = "unset";
    this.setState({ show2: false, showMachine2: false });
  };

  showModalMachine3 = () => {
    document.body.style.overflow = "hidden";
    this.setState({ showMachine3: true });
    const dataMap =
      this.state.pickMap +
      "#" +
      this.state.nameMap +
      "#" +
      this.state.enaMap +
      "#" +
      this.state.lngMap +
      "#" +
      this.state.latMap;
    // console.log("ini repppppppp", this.state.id_ce);
    if (this.state.pickMap) {
      const we = dataMap;
      const sa = we.split("#");
      const id_ce = sa[0];
      // const id_ce = this.state.pickMap;

      const dataCalendar = {
        id: id_ce,
        jwtToken: localStorage.getItem("jwtToken")
      };
      // console.log("dataCalendar");
      // console.log(dataCalendar);
      this.props.calendar(dataCalendar);
    }
    // if (!this.state.id_ce) {
    //   const id_ce = this.props.assign_ce.technicians.id;
    //   const dataCalendar = {
    //     id: id_ce,
    //     jwtToken: localStorage.getItem("jwtToken")
    //   };
    //   console.log("dataCalendar");
    //   console.log(dataCalendar);
    //   this.props.calendar(dataCalendar);
    // }

    // console.log("tracking");
    // console.log(this.props.assign_ce.ticket_origin.id);
  };
  hideModal3 = () => {
    document.body.style.overflow = "unset";
    this.setState({ show3: false, showMachine3: false });
  };
  hideModalclear = () => {
    document.body.style.overflow = "unset";
    this.setState({
      show3: false,
      showMachine3: false,
      ini: "",
      ini1: "",
      ini2: "",
      ini3: "",
      ini4: ""
    });
  };
  handleEventClick = slotInfo => {
    // console.log("hore.............. di clicked!");
    var startDate = moment(slotInfo.start).format("YYYY-MM-DD h:mm:ss");
    var endDate = moment(slotInfo.end).format("YYYY-MM-DD h:mm:ss");
    // var startDate = moment(slotInfo.start).format("YYYY-MM-DD h:mm:ss");
    this.setState({
      ini: startDate + "#" + endDate,
      ini1: startDate,
      ini2: endDate,
      ini3: slotInfo.start,
      ini4: slotInfo.end
    });
    // console.log(startDate); //shows the start time chosen
    // console.log(endDate); //shows the end time chosen
    // const mypick = {
    //   title: "pick",
    //   start: new Date(moment(slotInfo.start).format("YYYY-MM-DD h:mm:ss")),
    //   end: new Date(moment(slotInfo.end).format("YYYY-MM-DD h:mm:ss")),
    //   desc: "picker"
    // };
    const aaaww = new Date(moment(slotInfo.start).format("YYYY-MM-DD h:mm:ss"));
    // let myEventsList = [...myEventsList, mypick];
    // const myEventsList2 = this.myEventsList.concat(mypick);
    // const myEventsList2 = mypick;

    // console.log("mypick", mypick);
    // console.log(mypick);
    // console.log(myEventsList);
    // const date_holiday =this.props.holiday_Reducer.list_holiday;

    // if(date_holiday.length !== 0){
    // NotificationManager.error(
    //   are_holiday,
    //   "Error"
    // );
    // }
  };
  deleteEventClick = () => {
    this.setState({ ini: "", ini2: "", ini1: "" });
    this.setState({ show3: false, showMachine3: false });
  };

  onNavigate = (date, view) => {
    let start, end;

    if (view === "month") {
      start = moment(date)
        .startOf("month")
        .startOf("week");
      end = moment(date)
        .endOf("month")
        .endOf("week");
    }

    return { start, end };
  };
  // -------------------------------------map---------------------------------------------------

  _onChildClick = (key, childProps) => {
    // this.props.onCenterChange([childProps.lat, childProps.lng]);
    // console.log("okemap", childProps.lat);
    // console.log("ike map id", key, childProps.text, childProps.enableCe);
    // const markerId = key;
    // const index = this.props.markers.findIndex(m => key === markerId);
    // if (this._onChildClick) {
    // this.props.onChildClick(index);
    //   <Info text={key} />;
    // }
    // const buka = "<Info text={key} />";

    this.setState({
      // show: false,
      // showMachineNew: false,
      pickMap: key,
      latMap: childProps.lat,
      lngMap: childProps.lng,
      nameMap: childProps.text,
      enaMap: childProps.enableCe,
      // activeMarker: marker,
      mapModal: true,
      levelCE: childProps.levelCE,
      moblileCE: childProps.moblileCE,
      emailCE: childProps.emailCE,
      fullnameCE: childProps.fullnameCE
    });
  };
  AssignMapModal = () => {
    this.setState({
      show: false,
      showMachineNew: false,
      mapModal: false
    });
  };
  closeMapModal = () => {
    // this.setState({ ini: "", ini2: "", ini1: "" });
    this.setState({
      mapModal: false,
      pickMap: "",
      latMap: "",
      lngMap: "",
      nameMap: "",
      enaMap: "",
      levelCE: "",
      moblileCE: "",
      emailCE: "",
      fullnameCE: ""
    });
  };
  _onChildMouseEnter = (key /*, childProps */) => {
    // this.state.onHoverKeyChange(key);
    // console.log("enter.............");
  };
  _onChildMouseLeave = (/* key, childProps */) => {
    // this.props.onHoverKeyChange(null);
    // console.log("leave.............");
  };
  //-----------------------------------------------------------------map 2---------------
  submitWin = () => {
    document.body.style.overflow = "unset";
    this.setState({
      muncul: !this.state.muncul
    });
    // console.log("ini sub......", this.state.selectedPlace.name);
  };
  muncul = () => {
    document.body.style.overflow = "hidden";
    this.setState({
      muncul: true
    });
    // console.log("ini sub......");
  };
  closeMod = () => {
    document.body.style.overflow = "unset";
    this.setState({
      muncul: false
    });
    // console.log("ini sub......");
  };
  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      activeAssign: true,

      pickMap: props.id,
      latMap: props.lat,
      lngMap: props.lng,
      nameMap: props.name,
      enaMap: props.enableCe
    });
    // console.log("ini mark on..............", props.enableCe);
  };
  windowHasClosed = () => {
    this.setState({
      showingInfoWindow: false,
      activeAssign: false
    });
  };

  // onMapClicked = (t, map, coord) => {
  //   if (this.state.showingInfoWindow) {
  //     this.setState({
  //       showingInfoWindow: false,
  //       activeMarker: null,
  //       activeAssign: false
  //       // latcoy: props.latLng,
  //     });
  //   }
  //   const { latLng } = coord;
  //   // console.log("latt", latLng.lat(), latLng.lng());
  //   this.setState({
  //     latPicker: latLng.lat(),
  //     lngPicker: latLng.lng()
  //   });
  // };
  render() {
    if (this.props.assign_ce.assign_ce !== null) {
      if (this.props.assign_ce.assign_ce.length !== 0) {
        var map;
        var markers = [];
        const {
          assign_ce,
          ce,
          acting,
          ticket_type,
          department,
          ticket_origin,
          machine_status,
          machine_type,
          service_code,
          machine,
          machine_customer,
          customers,
          machine_models,
          technicians,
          contracts,
          sales,
          customer,
          blocked_by,
          contact,
          resSaveOtp,
          resSaveAssign,
          originator,
          originator_dept,
          locationCE
        } = this.props.assign_ce;
        // console.log("center",  customers.latitude)
        // const otpNumb = this.state.input_otp ? (this.state.input_otp.toString().length === 4
        // ? this.state.input_otp : parseInt(this.state.input_otp.split(" ").join(""))) : "";

        const otpNumb = this.state.input_otp
          ? this.state.input_otp
              .split(" ")
              .join("")
              .split("_")
              .join("")
          : "";

        // console.log("ot", otpNumb.toString().length);
        // console.log("oko", (this.state.input_otp.split(" ").join("")).split("_").join("").length);
        // console.log(
        //   "from",
        //   this.props.workingReducer.list_working.working
        //     .filter(item => item.day === moment(this.state.ini3).format("dddd"))
        //     .map(item => item.from_time)
        //     .toString()
        // );
        // console.log(
        //   "to",
        //   this.props.workingReducer.list_working.working
        //     .filter(item => item.day === moment(this.state.ini3).format("dddd"))
        //     .map(item => item.to_time)
        //     .toString()
        // );
        //-----------------------------------------------------cek ce list------------------------
        const celistCek = this.state.value
          .filter(item => item.id_ce === this.state.pickMap.toString())
          .map(item => item.name_ce);

        // console.log("show", celistCek);
        const start = this.props.workingReducer.list_working.working
          ? this.props.workingReducer.list_working.working
              .filter(
                item => item.day === moment(this.state.ini3).format("dddd")
              )
              .map(item => item.from_time)
              .toString()
          : "";
        const end = this.props.workingReducer.list_working.working
          ? this.props.workingReducer.list_working.working
              .filter(
                item => item.day === moment(this.state.ini3).format("dddd")
              )
              .map(item => item.to_time)
              .toString()
          : "";

        const start2 = this.props.workingReducer.list_working.working
          ? this.props.workingReducer.list_working.working
              .filter(
                item => item.day === moment(this.state.ini4).format("dddd")
              )
              .map(item => item.from_time)
              .toString()
          : "";
        const end2 = this.props.workingReducer.list_working.working
          ? this.props.workingReducer.list_working.working
              .filter(
                item => item.day === moment(this.state.ini4).format("dddd")
              )
              .map(item => item.to_time)
              .toString()
          : "";

        // console.log(
        //   "sen",
        //   start + end + moment(this.state.ini3).format("HH:mm")
        // );
        // console.log(
        //   "sen",
        //   start2 + end2 + moment(this.state.ini4).format("HH:mm")
        // );

        if (
          moment(this.state.ini3).format("HH:mm") >= start &&
          moment(this.state.ini3).format("HH:mm") <= end
        ) {
          // console.log("oke");
        }
        if (
          moment(this.state.ini4).format("HH:mm") >= start2 &&
          moment(this.state.ini4).format("HH:mm") <= end2
        ) {
          // console.log("oke2");
        }
        let addce;
        // let note;
        const note =
          "Working Hour " +
          moment(this.state.ini3).format("dddd") +
          " " +
          start +
          "-" +
          end;

        if (
          moment(this.state.ini3).format("HH:mm") >= start &&
          moment(this.state.ini3).format("HH:mm") <= end &&
          moment(this.state.ini4).format("HH:mm") >= start2 &&
          moment(this.state.ini4).format("HH:mm") <= end2
        ) {
          // console.log("oke3");
          addce = "ada";
        }

        //------------------------------------ scedule validation----------------------------
        // console.log("SD", this.props.calendar_find.ce_cal
        //   // .filter(
        //   //   tic =>
        //   //     moment(tic.date_to_assign).format("L") ===
        //   //     moment(this.state.ini3).format("L")
        //   // )
        //   .map(tic =>
        //   new Date(
        //           moment(tic.date_to_assign).format("MM/DD/YYYY h:mm:ss"),
        //  )));
        // console.log("SB", this.props.calendar_find.ce_cal
        //   // .filter(
        //   //   tic =>
        //   //     moment(tic.date_to_assign).format("L") ===
        //   //     moment(this.state.ini3).format("L")
        //   // )
        //   .map(tic =>

        //        new Date(
        //           moment(tic.date_of_assign).format("MM/DD/YYYY h:mm:ss")
        //         )

        // ));
        const orderstart = this.props.calendar_find.ce_cal
          .filter(
            tic =>
              moment(tic.date_to_assign).format("L") ===
              moment(this.state.ini3).format("L")
          )
          .map(tic => tic.date_of_assign)
          .toString();
        const orderend = this.props.calendar_find.ce_cal
          .filter(
            tic =>
              moment(tic.date_to_assign).format("L") ===
              moment(this.state.ini3).format("L")
          )
          .map(tic => tic.date_to_assign)
          .toString();
        let bentrok;
        if (
          (moment(this.state.ini3).format("MM/DD/YYYY hh:mm:ss") >=
            moment(orderstart).format("MM/DD/YYYY hh:mm:ss") &&
            moment(this.state.ini3).format("MM/DD/YYYY hh:mm:ss") <
              moment(orderend).format("MM/DD/YYYY hh:mm:ss")) ||
          (moment(this.state.ini4).format("MM/DD/YYYY hh:mm:ss") >
            moment(orderstart).format("MM/DD/YYYY hh:mm:ss") &&
            moment(this.state.ini4).format("MM/DD/YYYY hh:mm:ss") <=
              moment(orderend).format("MM/DD/YYYY hh:mm:ss"))
        ) {
          // console.log("oke3");
          bentrok = "ada";
        }
        // console.log("ww", orderstart, orderend);
        // console.log("bentrok", bentrok);
        // console.log(moment(this.state.ini3).format("MM/DD/YYYY h:mm:ss"),"prb1", moment(orderstart).format("MM/DD/YYYY h:mm:ss"));
        // console.log(moment(this.state.ini3).format("MM/DD/YYYY h:mm:ss"),"prb2", moment(orderend).format("MM/DD/YYYY h:mm:ss"));
        // console.log(moment(this.state.ini4).format("MM/DD/YYYY h:mm:ss"),"prb3", moment(orderstart).format("MM/DD/YYYY h:mm:ss"));
        // console.log(moment(this.state.ini4).format("MM/DD/YYYY h:mm:ss"),"prb4", moment(orderend).format("MM/DD/YYYY h:mm:ss"));

        //-------------------------------------------------scedule validation end------------------

        // console.log("holiday_Reducer", this.props.holiday_Reducer.list_holiday);
        // console.log("holi filter",this.props.holiday_Reducer.list_holiday
        //   .filter(days => moment(days.date).format("L") === moment(this.state.ini3).format("L"))
        //   .map(days => days.date));

        const date_holiday = this.props.holiday_Reducer.list_holiday
          .filter(
            days =>
              moment(days.date).format("L") ===
              moment(this.state.ini3).format("L")
          )
          .map(days => days.date);

        const desc_holiday = this.props.holiday_Reducer.list_holiday
          .filter(
            days =>
              moment(days.date).format("L") ===
              moment(this.state.ini3).format("L")
          )
          .map(days => days.description);
        let are_holiday;
        if (date_holiday.length !== 0) {
          are_holiday = date_holiday + " is Holiday of " + desc_holiday;
          NotificationManager.error(are_holiday, "Error");
        }
        if (
          addce !== "ada" &&
          this.state.pickMap &&
          this.state.ini3 &&
          !are_holiday
        ) {
          NotificationManager.error(note, "Error");
          const working_ho = {
            title: "Working",
            start: new Date(moment(this.state.ini3).format("MM/DD/YYYY")),
            end: new Date(moment(this.state.ini3).format("MM/DD/YYYY")),
            desc: "Power lunch"
          };

          // myEventsList2.push(working_ho);
          myEventsList = [...myEventsList, working_ho];
          // const wok
          // console.log("on", myEventsList);
          // console.log("next", nextProps.resSaveOtp);
        }
        // if(moment(this.state.ini3).format("HH:mm") <= start &&
        //     moment(this.state.ini3).format("HH:mm") >= end &&
        //     moment(this.state.ini4).format("HH:mm") <= start2 &&
        //     moment(this.state.ini4).format("HH:mm") >= end2){
        //   console.log("oke4");
        // }
        if (resSaveOtp) {
          if (resSaveOtp.length !== 0) {
            NotificationManager.success(resSaveOtp.message, "OTP Input", 2000);
          }

          // if (store_holiday.length !== 0) {
          //   NotificationManager.success(store_holiday.message, "Add Holiday");
          // }
        }
        if (resSaveAssign) {
          if (resSaveAssign.length !== 0) {
            if (resSaveAssign.code === 200) {
              NotificationManager.success(
                resSaveAssign.message,
                "Assign CE Input"
              );
            }
            // else {
            //   NotificationManager.error(resSaveAssign.message, "Error");
            // }
          }

          // if (store_holiday.length !== 0) {
          //   NotificationManager.success(store_holiday.message, "Add Holiday");
          // }
        }

        const { ticket_tracking } = this.props.ticket_tracking;
        const { location_find } = this.props.map_location;

        const { ce_cal, working } = this.props.calendar_find;
        const { value, value3 } = this.state;
        const { action_by } = this.props.action_by;
        const levels = localStorage.getItem("level");
        // console.log("action_by");
        // console.log(action_by);
        const { tickets_det } = this.props.yyy;
        const { originator_des } = this.props.yyy;
        const { originator_des_dep } = this.props.yyy;
        // const { customer } = this.props.yyy;
        const { ticket_details } = this.props.yyy;
        const { call_information } = this.props.yyy;
        const { service_code_det } = this.props.yyy;
        // const { machine } = this.props.yyy;
        const { list_user_ce } = this.props.yyy;
        const { ce_assign } = this.props.yyy;
        const now = moment();
        const { button, id_ce, yaho, name_ce, enable_ce, ott } = this.state;
        const { list_user } = this.props.yyy;

        //----------------------------cara split-------------------------------
        const str = "How are you doing today?";
        const spl = str.split(" ").join("");
        // console.log("jajal replace");
        // console.log(spl);

        const we2 = this.state.id_ce ? this.state.id_ce : "";
        const sa2 = we2.split("#");
        const use = sa2[1] === undefined ? "" : sa2[1];

        const av = sa2[2] === "1" ? "Available" : "Not Available";
        const long = sa2[3] === undefined ? "" : sa2[3];
        const lat = sa2[4] === undefined ? "" : sa2[4];
        const localizer = BigCalendar.momentLocalizer(moment);

        // console.log("localizer");
        // console.log(localizer);

        // .rbc-calendar {
        //   min-height: 500px ;
        // }
        // const mypick = [];

        myEventsList =
          // {
          //   allDay: false,
          //   endDate: new Date("2019-06-19"),
          //   startDate: new Date("2019-06-18"),
          //   title: "hi"
          // },
          // {
          //   allDay: true,
          //   endDate: new Date("2019-06-11"),
          //   startDate: new Date("2019-06-10"),
          //   title: "All Day Event"
          // }

          ce_cal.map(tic =>
            tic.date_of_assign
              ? {
                  end: new Date(
                    moment(tic.date_to_assign).format("MM/DD/YYYY h:mm:ss")
                  ),
                  start: new Date(
                    moment(tic.date_of_assign).format("MM/DD/YYYY h:mm:ss")
                  ),

                  // endDate: new Date("2019-06-19"),
                  // startDate: new Date("2019-06-18"),

                  title:
                    tic.ticket_origin.machine_customer.customers.CustomerName,
                  desc: tic.status_job.id,
                  id: tic.id
                }
              : ""
          );
        const myHoliday = this.props.holiday_Reducer.list_holiday.map(days => ({
          start: new Date(moment(days.date)),
          end: new Date(
            moment(days.date)
              .add(23, "hours")
              .add(59, "minutes")
          ),
          title: days.description,
          desc: "holiday"
        }));
        // console.log("my holi", myHoliday);
        // console.log("myEventsList", myEventsList);
        const listcal = ce_cal.map(tic =>
          tic.date_of_assign
            ? new Date(moment(tic.date_of_assign).format("MM/DD/YYYY"))
            : ""
        );
        //-----------------------------------------------------------------------------------------------------------------
        // let arra = [1, 3];
        // let arrbaru = [4, 5];
        // let gabung = [...arra, ...arrbaru]
        // arra = [...arra, arrbaru] == [1, 3, 4, 5]

        const mylist = [
          {
            title: "Conference",
            start: new Date("sunday"),
            end: new Date("monday"),
            desc: "Big conference"
          },
          {
            title: "Meeting",
            start: new Date(2017, 3, 12, 10, 30, 0, 0),
            end: new Date(2017, 3, 12, 12, 30, 0, 0),
            desc: "Pre-meeting meeting, to prepare for the meeting"
          },
          {
            title: "Lunch",
            start: new Date("09/05/2019, 13:30:00"),
            end: new Date("09/05/2019, 14:30:00"),
            desc: "Power lunch"
          },
          {
            title: "Working",
            start: new Date(moment(this.state.ini3).format("dddd"), start),
            end: new Date(moment(this.state.ini3).format("dddd"), end),
            desc: "Power lunch"
          }
        ];

        const mypick = {
          title: "SELECTED",
          start: this.state.ini3,
          end: this.state.ini4,
          desc: "picker"
        };
        const valpick =
          !are_holiday && addce === "ada" && bentrok !== "ada" ? mypick : {};
        // {addce !== "ada" &&
        //                 this.state.pickMap &&
        //                 this.state.ini3
        //                   ? note
        //                   : ""}

        const worked1 = !are_holiday
          ? {
              title: "Not Working Hour",
              start: new Date(
                moment(this.state.ini3).format("MM/DD/YYYY 00:00:00")
              ),
              end: new Date(
                moment(this.state.ini3).format("MM/DD/YYYY " + start)
              ),
              desc: "Power lunch"
            }
          : {};
        const worked2 = !are_holiday
          ? {
              title: "Not Working Hour",
              start: new Date(
                moment(this.state.ini3).format("MM/DD/YYYY " + end)
              ),
              end: new Date(
                moment(this.state.ini3).format("MM/DD/YYYY 23:59:00")
              ),
              desc: "Power lunch"
            }
          : {};
        const worked3 = {
          title: "Working Hour",
          start: new Date(
            moment(this.state.ini3).format("MM/DD/YYYY " + start)
          ),
          end: new Date(moment(this.state.ini3).format("MM/DD/YYYY " + end)),
          desc: "Power lunch"
        };
        // console.log( "luar ", new Date(moment(this.state.ini3).format("MM/DD/YYYY")));
        // console.log( "luar2 ", start, end);
        // console.log( "worked ", worked1);

        //---------------------------work----------------------------------------
        // console.log(
        //   "iii",
        //   this.props.workingReducer.list_working.working
        //     .filter(item => item.day === "Sunday")
        //     .map(item => item.to_time)
        //     .toString()
        // );
        const sunstart = this.props.workingReducer.list_working.working
          ? this.props.workingReducer.list_working.working
              .filter(item => item.day === "Sunday")
              .map(item => item.from_time)
              .toString()
          : "";
        const sunend = this.props.workingReducer.list_working.working
          ? this.props.workingReducer.list_working.working
              .filter(item => item.day === "Sunday")
              .map(item => item.to_time)
              .toString()
          : "";

        const monstart = this.props.workingReducer.list_working.working
          ? this.props.workingReducer.list_working.working
              .filter(item => item.day === "Monday")
              .map(item => item.from_time)
              .toString()
          : "";
        const monend = this.props.workingReducer.list_working.working
          ? this.props.workingReducer.list_working.working
              .filter(item => item.day === "Monday")
              .map(item => item.to_time)
              .toString()
          : "";

        const tuesstart = this.props.workingReducer.list_working.working
          ? this.props.workingReducer.list_working.working
              .filter(item => item.day === "Tuesday")
              .map(item => item.from_time)
              .toString()
          : "";
        const tuesend = this.props.workingReducer.list_working.working
          ? this.props.workingReducer.list_working.working
              .filter(item => item.day === "Tuesday")
              .map(item => item.to_time)
              .toString()
          : "";

        const wedstart = this.props.workingReducer.list_working.working
          ? this.props.workingReducer.list_working.working
              .filter(item => item.day === "Wednesday")
              .map(item => item.from_time)
              .toString()
          : "";
        const wedend = this.props.workingReducer.list_working.working
          ? this.props.workingReducer.list_working.working
              .filter(item => item.day === "Wednesday")
              .map(item => item.to_time)
              .toString()
          : "";

        const thustart = this.props.workingReducer.list_working.working
          ? this.props.workingReducer.list_working.working
              .filter(item => item.day === "Thursday")
              .map(item => item.from_time)
              .toString()
          : "";
        const thuend = this.props.workingReducer.list_working.working
          ? this.props.workingReducer.list_working.working
              .filter(item => item.day === "Thursday")
              .map(item => item.to_time)
              .toString()
          : "";

        const fristart = this.props.workingReducer.list_working.working
          ? this.props.workingReducer.list_working.working
              .filter(item => item.day === "Friday")
              .map(item => item.from_time)
              .toString()
          : "";
        const friend = this.props.workingReducer.list_working.working
          ? this.props.workingReducer.list_working.working
              .filter(item => item.day === "Friday")
              .map(item => item.to_time)
              .toString()
          : "";

        const satstart = this.props.workingReducer.list_working.working
          ? this.props.workingReducer.list_working.working
              .filter(item => item.day === "Saturday")
              .map(item => item.from_time)
              .toString()
          : "";
        const satend = this.props.workingReducer.list_working.working
          ? this.props.workingReducer.list_working.working
              .filter(item => item.day === "Saturday")
              .map(item => item.to_time)
              .toString()
          : "";
        // console.log(monstart, monend);
        var curr = new Date(); // get current date
        var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        var second = first + 1;
        var third = first + 2;
        var fourth = first + 3;
        var fifth = first + 4;
        var sixth = first + 5;
        var seventh = first + 6;
        var firstday = new Date(curr.setDate(first)).toUTCString();
        var secondday = new Date(curr.setDate(second)).toUTCString();
        var thirdday = new Date(curr.setDate(third)).toUTCString();
        var fourthday = new Date(curr.setDate(fourth)).toUTCString();
        var fifthday = new Date(curr.setDate(fifth)).toUTCString();
        var sixthday = new Date(curr.setDate(sixth)).toUTCString();
        var seventhday = new Date(curr.setDate(seventh)).toUTCString();
        // console.log(firstday);
        // console.log(new Date(
        //   moment(secondday).format("MM/DD/YYYY " + monstart)));
        // console.log(thirdday, curr.getDay());
        // console.log(fourthday);
        // console.log(fifthday);
        // console.log(sixthday);
        // console.log(seventhday);

        const workedall = [
          {
            title: "Working Hour",
            start: new Date(moment(firstday).format("MM/DD/YYYY " + sunstart)),
            end: new Date(moment(firstday).format("MM/DD/YYYY " + sunend)),
            desc: "Power lunch"
          },
          {
            title: "Working Hour",
            start: new Date(moment(secondday).format("MM/DD/YYYY " + monstart)),
            end: new Date(moment(secondday).format("MM/DD/YYYY " + monend)),
            desc: "Power lunch"
          },
          {
            title: "Working Hour",
            start: new Date(moment(thirdday).format("MM/DD/YYYY " + tuesstart)),
            end: new Date(moment(thirdday).format("MM/DD/YYYY " + tuesend)),
            desc: "Power lunch"
          },
          {
            title: "Working Hour",
            start: new Date(moment(fourthday).format("MM/DD/YYYY " + wedstart)),
            end: new Date(moment(fourthday).format("MM/DD/YYYY " + wedend)),
            desc: "Power lunch"
          },
          {
            title: "Working Hour",
            start: new Date(moment(fifthday).format("MM/DD/YYYY " + thustart)),
            end: new Date(moment(fifthday).format("MM/DD/YYYY " + thuend)),
            desc: "Power lunch"
          },
          {
            title: "Working Hour",
            start: new Date(moment(sixthday).format("MM/DD/YYYY " + fristart)),
            end: new Date(moment(sixthday).format("MM/DD/YYYY " + friend)),
            desc: "Power lunch"
          },
          {
            title: "Working Hour",
            start: new Date(
              moment(seventhday).format("MM/DD/YYYY " + satstart)
            ),
            end: new Date(moment(seventhday).format("MM/DD/YYYY " + satend)),
            desc: "Power lunch"
          }
        ];

        myEventsList = [
          ...myEventsList,
          valpick,
          ...myHoliday,
          worked1,
          worked2
          // ...workedall
        ];

        const myEventsList2 = myEventsList;
        // const myEventsList2 = myEventsList.concat(working_ho);
        // console.log("myEventsList");
        // console.log("dfs", myEventsList2);
        // console.log("listcal", this.state.ini2);
        // console.log(mySis);

        let filtered = ce.slice();
        if (this.state.id_ce) {
          filtered = filtered.filter(
            ticket => ticket.id.toString().indexOf(sa2[0].toLowerCase()) !== -1
          );
        }
        const apem = {
          x: Math.round(this.props.assign_ce.customers.latitude * 1000) / 1000,
          y: Math.round(this.props.assign_ce.customers.longitude * 1000) / 1000
        };

        // Math.round(customers.latitude * 1000)) / 1000
        // console.log("asuuuuu.....", {
        //   apem
        // });
        // console.log("asuuuuu cen.....", this.state.center);
        // const { runing } = this.props.machine.machine_running;
        // const { yes } = { runing } === "1" ? "checked" : "";
        // const { no } = { runing } === "0" ? "checked" : "";

        // const {
        //   tickets_det,
        //   originator_des,
        //   originator_des_dep,
        //   originator,
        //   customer,
        //   ticket_details
        // } = this.state;
        // console.log(tickets_det);
        let gomap;
        if (this.state.muncul) {
          gomap = (
            <div className="modal display-block">
              <div className="modal-dialog modal-lg" style={this.state.modd2}>
                <div className="modal-content">
                  {/* <ModalHeader
                // onClick={this.hideModal3}

                // info={"'"+customers.latitude+"'"}
              /> */}
                  <div
                    className="modal-body"
                    // style={{ height: "80vh", width: "1010px", padding: "5px" }}
                  >
                    {/* {technicians.code} */}
                    <div className="row">
                      <div className="col-md-12 modal_body_map">
                        <div
                          className="row"
                          style={{ height: "75vh", width: "1000px" }}
                        >
                          <Map
                            google={this.props.google}
                            // onClick={this.onMapClicked}
                            // onClick={(e) => this.onMapClicked(e)}
                            // onClick={(t, map, c) => this.onMapClicked(c.latLng, map)}
                            // initialCenter={this.state.center}
                            initialCenter={{
                              lat:
                                customers.latitude === undefined ||
                                customers.latitude === null ||
                                customers.latitude === ""
                                  ? this.state.center.lat
                                  : Math.round(customers.latitude * 10000) /
                                    10000,
                              lng:
                                customers.longitude === undefined ||
                                customers.longitude === null ||
                                customers.longitude === ""
                                  ? this.state.center.lng
                                  : Math.round(customers.longitude * 10000) /
                                    10000
                            }}
                            zoom={this.state.zoom}
                          >
                            {/* <Marker
                          onClick={this.onMarkerClick}
                          title={"Pak A."}
                          name={"Pak A"}
                          position={{ lat: 1.359435, lng: 103.903079 }}
                          icon={iconRed}
                        /> */}
                            {/* {ce.map(tic => (
                          <Marker
                            position={{
                              lat: tic.latitude ? tic.latitude : "",
                              lng: tic.longitude ? tic.longitude : ""
                            }}
                            lat={tic.latitude ? tic.latitude : ""}
                            lng={tic.longitude ? tic.longitude : ""}
                            onClick={this.onMarkerClick}
                            title={tic.username ? tic.username : ""}
                            name={tic.username ? tic.username : ""}
                            key={tic.id ? tic.id : ""}
                            id={tic.id ? tic.id : ""}
                            enableCe={tic.enabled ? tic.enabled : ""}
                            levelCE={tic.level.name ? tic.level.name : ""}
                            moblileCE={tic.mobile_phone ? tic.mobile_phone : ""}
                            emailCE={tic.email ? tic.email : ""}
                            fullnameCE={tic.fullname ? tic.fullname : ""}
                            icon={
                              tic.username === technicians.code
                                ? iconRed
                                : iconGreen
                            }
                            type="ce"
                          />
                        ))} */}
                            {locationCE.map(tic => (
                              <Marker
                                position={{
                                  lat: tic.postal_code.latitude
                                    ? tic.postal_code.latitude
                                    : "",
                                  lng: tic.postal_code.longitude
                                    ? tic.postal_code.longitude
                                    : ""
                                }}
                                lat={
                                  tic.postal_code.latitude
                                    ? tic.postal_code.latitude
                                    : ""
                                }
                                lng={
                                  tic.postal_code.longitude
                                    ? tic.postal_code.longitude
                                    : ""
                                }
                                onClick={this.onMarkerClick}
                                title={
                                  tic.ce.user.username
                                    ? tic.ce.user.username
                                    : ""
                                }
                                name={
                                  tic.ce.user.username
                                    ? tic.ce.user.username
                                    : ""
                                }
                                key={tic.ce.user.id ? tic.ce.user.id : ""}
                                //---------------------------------------------------
                                id={tic.ce.user.id ? tic.ce.user.id : ""}
                                enableCe={
                                  tic.ce.user.enabled ? tic.ce.user.enabled : ""
                                }
                                // levelCE={tic.level.name ? tic.level.name : ""}
                                moblileCE={
                                  tic.ce.user.mobile_phone
                                    ? tic.ce.user.mobile_phone
                                    : ""
                                }
                                emailCE={tic.ce.email ? tic.ce.email : ""}
                                fullnameCE={
                                  tic.ce.user.fullname
                                    ? tic.ce.user.fullname
                                    : ""
                                }
                                icon={
                                  tic.ce.user.username === technicians.code
                                    ? iconRed
                                    : iconGreen
                                }
                                // icon={iconGreen}

                                type="ce"
                              />
                            ))}
                            <Marker
                              // lat={customers.latitude}
                              // lng={customers.longitude}
                              // text={customers.CustomerName}
                              // onClick={this.onMarkerClick}
                              title={customer.CustomerName}
                              name={customer.CustomerName}
                              position={{
                                lat: customers.latitude,
                                lng: customers.longitude
                              }}
                              icon={iconBlue}
                              type="company"
                            />
                            {/* <Marker
                              title="omah"
                              name="nama omah"
                              position={{
                                lat: this.state.latPicker,
                                lng: this.state.lngPicker
                              }}
                              icon={iconBlue}
                              type="company"
                            /> */}
                            {/* <Marker
                          onClick={this.onMarkerClick}
                          title={"Pak B."}
                          name={"Pak B"}
                          position={{ lat: 1.33279, lng: 103.977313 }}
                          icon={iconGreen}
                        /> */}

                            <InfoWindow
                              marker={this.state.activeMarker}
                              visible={this.state.showingInfoWindow}
                              onClose={this.windowHasClosed}
                            >
                              <div>
                                <div style={{ textAlign: "center" }}>
                                  <label className="control-label">
                                    {this.state.selectedPlace.name}
                                  </label>
                                </div>
                                <div>
                                  ------------------------------------------------------------------------------------------
                                </div>
                                {this.state.selectedPlace.type === "ce" ? (
                                  <div>
                                    <TextInputDisabled
                                      label="id"
                                      value={this.state.selectedPlace.id}
                                    />
                                    <TextInputDisabled
                                      label="Name"
                                      value={
                                        this.state.selectedPlace.fullnameCE
                                      }
                                    />
                                    <TextInputDisabled
                                      label="Level"
                                      value={this.state.selectedPlace.levelCE}
                                    />
                                    <TextInputDisabled
                                      label="Enable"
                                      value={
                                        this.state.selectedPlace.enableCe === 1
                                          ? "Available"
                                          : "Not Available"
                                      }
                                    />
                                    <TextInputDisabled
                                      label="Mobile No."
                                      value={this.state.selectedPlace.moblileCE}
                                    />
                                    <TextInputDisabled
                                      label="Email"
                                      value={this.state.selectedPlace.emailCE}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </InfoWindow>
                          </Map>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer" style={{ textAlign: "right" }}>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={this.submitWin}
                      disabled={!this.state.activeAssign}
                    >
                      Assign
                    </button>{" "}
                    <button
                      className="btn btn-default btn-sm"
                      onClick={this.closeMod}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        }
        kiri = (
          <div className="col-md-6">
            {/* <div
              className={
                this.state.showMachineNew
                  ? "modal display-block"
                  : "modal display-none"
              }
            >
              <div className="modal-dialog modal-lg">
                <div
                  className="modal-content"
                  
                >
                  <ModalHeader
                    onClick={this.hideModalNew}
                   
                  />
                 

                  <div className="modal-body" />
                  <div style={{ height: "65vh", width: "100%" }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        // key: "AIzaSyBFG8E71CLa-5Q0SdPES4khXEDz5lk_lvo"
                        key: "AIzaSyD8kVr5dj4fb_s-s80rqu8mbehkHKRXgFY"
                      }}
                      
                      center={{
                        lat:
                          customers.latitude !== undefined
                            ? Math.round(customers.latitude * 10000) / 10000
                            : this.state.center.lat,
                        lng:
                          customers.longitude !== undefined
                            ? Math.round(customers.longitude * 10000) / 10000
                            : this.state.center.lng
                      }}
                      
                      defaultZoom={this.state.zoom}
                      
                      onChildClick={this._onChildClick}
                      
                      onChildMouseEnter={this._onChildMouseEnter}
                      onChildMouseLeave={this._onChildMouseLeave}
                    >
                      {ce.map(tic => (
                        <MarkerGreen
                          lat={tic.latitude ? tic.latitude : ""}
                          lng={tic.longitude ? tic.longitude : ""}
                          text={tic.username ? tic.username : ""}
                          key={tic.id ? tic.id : ""}
                          enableCe={tic.enabled ? tic.enabled : ""}
                          levelCE={tic.level.name ? tic.level.name : ""}
                          moblileCE={tic.mobile_phone ? tic.mobile_phone : ""}
                          emailCE={tic.email ? tic.email : ""}
                          fullnameCE={tic.fullname ? tic.fullname : ""}
                          hover={this.state.hoverKey === tic.id}
                          color={
                            tic.username === technicians.code
                              ? "label label-danger"
                              : "label label-primary"
                          }
                          icon={
                            tic.username === technicians.code
                              ? iconRed
                              : iconGreen
                          }
                   
                        />

                        
                      ))}
                      
                      <MarkerRed
                        lat={customers.latitude}
                        lng={customers.longitude}
                        text={customers.CustomerName}
                      />
                    </GoogleMapReact>
                  </div>
                </div>
                <div className="modal-footer" />
              </div>
            </div> */}

            <div
              className={
                this.state.mapModal
                  ? "modal display-block"
                  : "modal display-none"
              }
            >
              {" "}
              <div className="modal-dialog modal-xs">
                <div className="modal-content">
                  <ModalHeader
                    onClick={this.closeMapModal}
                    onClick={this.closeMapModal}
                    info="Detail"
                  />
                  <div className="modal-body">
                    <div className="form form-xs">
                      <div className="form-group">
                        <label
                          className="control-label"
                          style={{ textAlign: "left" }}
                        >
                          ID
                        </label>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.pickMap ? this.state.pickMap : ""}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="control-label"
                          style={{ textAlign: "left" }}
                        >
                          Username
                        </label>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.nameMap ? this.state.nameMap : ""}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="control-label"
                          style={{ textAlign: "left" }}
                        >
                          Fullname
                        </label>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            className="form-control"
                            value={
                              this.state.fullnameCE ? this.state.fullnameCE : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="control-label"
                          style={{ textAlign: "left" }}
                        >
                          Enable
                        </label>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            className="form-control"
                            value={
                              this.state.enaMap === 1
                                ? "Available"
                                : "Not Available"
                            }
                            disabled
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          className="control-label"
                          style={{ textAlign: "left" }}
                        >
                          Level
                        </label>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.levelCE ? this.state.levelCE : ""}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          className="control-label"
                          style={{ textAlign: "left" }}
                        >
                          Mobile
                        </label>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            className="form-control"
                            value={
                              this.state.moblileCE ? this.state.moblileCE : ""
                            }
                            disabled
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          className="control-label"
                          style={{ textAlign: "left" }}
                        >
                          Email
                        </label>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.emailCE ? this.state.emailCE : ""}
                            disabled
                          />
                        </div>
                      </div>

                      <label
                        className="control-label"
                        style={{ textAlign: "left" }}
                      >
                        {" "}
                      </label>
                      <div className="col-sm-8">
                        <button
                          className="btn btn-primary btn-sm pull-right"
                          onClick={this.AssignMapModal}
                        >
                          Assign
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer" />
                </div>
              </div>
            </div>

            <div
              className={
                this.state.showMachine2
                  ? "modal display-block"
                  : "modal display-none"
              }
            >
              {" "}
              <div className="modal-dialog modal-lg">
                <div className="modal-content" style={{ maxHeight: "600px" }}>
                  <div className="modal-header">
                    Tracking Process of Ticket
                    <ModalHeader
                      onClick={this.hideModal2}
                      // onClick={this.hideModal2}
                    />
                  </div>
                  <div className="modal-body">
                    <div className="table-responsive">
                      <table className="table table-xs table-bordered">
                        <thead>
                          <tr>
                            <th>Date Time</th>
                            <th>Description</th>
                            <th>Action By</th>
                            <th>Department</th>
                            <th>Assign to Department</th>
                            <th>Received By</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ticket_tracking.map(tic => (
                            <tr key={tic.id}>
                              <td>{tic.created_at}</td>
                              <td>{tic.description}</td>
                              <td>
                                {tic.action_by.id}
                                {tic.action_by.fullname}
                              </td>
                              <td>{tic.action_department.name}</td>
                              <td>{tic.received_department.name}</td>
                              <td>
                                {tic.received_by.fullname}(
                                {tic.received_by.username})
                              </td>
                              <td>{tic.ticket_status.name}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                this.state.showMachine3
                  ? "modal display-block"
                  : "modal display-none"
              }
            >
              {" "}
              <div className="modal-dialog modal-lg" style={this.state.modd}>
                <div className="modal-content">
                  <div className="modal-header">
                    <ModalHeader
                      onClick={this.hideModalclear}
                      onClick={this.hideModalclear}
                      info={"Tracking Calendar (" + this.state.nameMap + ")"}
                    />

                    {/* {calendar_find.map(tic => (
                    <p>
                      {tic.date_approve
                        ? moment(tic.date_approve).format("L")
                        : ""}
                    </p>
                  ))} */}
                    <BigCalendar
                      selectable
                      localizer={localizer}
                      events={myEventsList2 ? myEventsList2 : ""}
                      startAccessor="start"
                      endAccessor="end"
                      style={{ width: "1170px", height: "370px" }}
                      // views={["week"]}
                      // onNavigate={this.onNavigate}

                      // selectable="ignoreEvents"
                      // localizer={localizer}
                      // // events={events}
                      // // views={allViews}
                      // step={60}
                      // showMultiDayTimes
                      // defaultDate={new Date()}
                      // defaultView={BigCalendar.Views.WEEK}
                      // style={{ height: "90vh" }}
                      // // resources={resourceMap}
                      // resourceIdAccessor="resourceId"
                      // resourceTitleAccessor="resourceTitle"
                      // onSelectSlot={this.handleSelect}
                      // eventPropGetter={this.eventStyleGetter}

                      // name="date_of_assign"
                      // onSelectEvent={this.handleAssignCE}
                      // onSelectEvent={({ startDate, endDate }) =>
                      //   console.log(startDate, endDate)
                      // }
                      //-------------------------------------------------------------
                      // defaultDate={new Date()}
                      defaultView="week"
                      scrollToTime={new Date(1970, 1, 1, 6)}
                      defaultDate={new Date()}
                      // onSelectEvent={event =>
                      //   alert(event.title + "/" + event.desc + "/" + event.id)
                      // }
                      onSelectSlot={
                        slotInfo => this.handleEventClick(slotInfo)
                        // |
                        // alert(
                        //   `selected slot: \n\nstart ${slotInfo.start.toLocaleString()} ` +
                        //     `\nend: ${slotInfo.end.toLocaleString()}` +
                        //     `\naction: ${slotInfo.action}`
                        // )
                      }
                      eventPropGetter={(event, start, end, isSelected) => {
                        let newStyle = {
                          backgroundColor: "lightgrey",
                          color: "black",
                          borderRadius: "0px",
                          border: "none"
                        };

                        // if (event.title === "ARES DIGITAL PTE LTD ") {
                        //   newStyle.backgroundColor = "lightgreen";
                        // }
                        if (event.title === "Conference") {
                          newStyle.backgroundColor = "lightblue";
                        }
                        if (event.title === "Not Working Hour") {
                          newStyle.backgroundColor =
                            "rgba(221, 216, 216, 0.671)";
                        }

                        if (event.desc === 4) {
                          newStyle.backgroundColor = "#ba9b3d";
                        }
                        if (event.desc === 3) {
                          newStyle.backgroundColor = "#3949b3";
                        }
                        if (event.desc === 2) {
                          newStyle.backgroundColor = "#00FF00";
                        }
                        if (event.desc === 1) {
                          newStyle.backgroundColor = "#00FF00";
                        }
                        if (event.desc === "picker") {
                          newStyle.backgroundColor = "grey";
                        }
                        if (event.desc === "holiday") {
                          newStyle.backgroundColor = "red";
                        }

                        return {
                          className: "",
                          style: newStyle
                        };
                      }}
                    />
                  </div>
                  <div className="modal-footer">
                    <table>
                      <tbody>
                        <tr>
                          <th bgcolor="#d29f40" width="50" />
                          <th width="70">&nbsp; Waiting</th>
                          <th bgcolor="#3949b3" width="50" />
                          <th width="90">&nbsp; Accepted</th>
                          <th bgcolor="#96b2dd" width="50" />
                          <th width="100">&nbsp; In Progress</th>
                          <th bgcolor="#00FF00" width="50" />
                          <th width="100">&nbsp; Completed</th>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      className="panel-body form form-xs"
                      style={{ textAlign: "right" }}
                    >
                      <h6 style={{ color: "#A94442" }}>
                        {addce !== "ada" &&
                        this.state.pickMap &&
                        this.state.ini3
                          ? note
                          : ""}
                      </h6>
                      <h6 style={{ color: "#A94442" }}>{are_holiday}</h6>
                      <button
                        type="button"
                        className="btn btn-primary pull-right btn-xs"
                        data-dismiss="modal"
                        onClick={this.hideModal3}
                        // disabled={this.state.ini1 ? "" : "disabled"}
                        disabled={
                          this.state.pickMap && addce === "ada" && !are_holiday
                            ? ""
                            : "disabled"
                        }
                      >
                        SELECT
                      </button>
                      {/* <div className="col-sm-6 pull-right form-inline">
                        <span className="label label-default">
                          {this.state.ini2 ? this.state.ini2 : ""}
                        </span>
                        -{" - "}
                        <span className="label label-default">
                          {this.state.ini1 ? this.state.ini1 : ""}
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <form action="create-new-origination-ticket.html"> */}
            <div className="panel panel-default">
              <div className="panel-heading">ORIGINATOR</div>
              <div className="panel-body">
                <NotificationContainer />
                <div className="form form-xs">
                  <div className="row">
                    <div className="form-horizontal col-md-6">
                      <div className="form-group">
                        <label
                          className="control-label col-sm-4"
                          style={{ textAlign: "left" }}
                        >
                          Department
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            value={
                              originator_dept.name ? originator_dept.name : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                      {gomap}
                      <div className="form-group">
                        <label
                          className="control-label col-sm-6"
                          style={{ textAlign: "left" }}
                        >
                          Date Time Created
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            value={moment(assign_ce.created_at).format(
                              "L hh:mm"
                            )}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-horizontal col-md-6">
                      <div className="form-group">
                        <label
                          className="control-label col-sm-4"
                          style={{ textAlign: "left" }}
                        >
                          Name
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            value={
                              originator.fullname ? originator.fullname : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="control-label col-sm-4"
                          style={{ textAlign: "left" }}
                        >
                          User ID
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            value={originator.id ? originator.id : ""}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">CONTACT DETAIL</div>
              <div className="panel-body">
                <div className="form form-xs">
                  <div className="row">
                    {/* <div className="form-horizontal col-md-6">
                      <div className="form-group">
                        <label
                          className="control-label col-sm-6"
                          style={{ textAlign: "left" }}
                        >
                          Date Time Contact
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            value={contact.date_of_contact}
                            disabled
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="form-horizontal col-md-8">
                      <div className="form-group">
                        <label
                          className="control-label col-sm-6"
                          style={{ textAlign: "left", width: "70%" }}
                        >
                          Date Time Contact
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            value={contact.date_of_contact}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group form-inline">
                        <label
                          className="control-label col-sm-6"
                          style={{ textAlign: "left", width: "70%" }}
                        >
                          Contact Name
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            value={contact.gender}
                            style={{ width: "20%" }}
                            disabled
                          />
                          {/* </div>
                        <div className="col-sm-6"> */}
                          <input
                            type="text"
                            className="form-control"
                            value={contact.contact_name}
                            disabled
                            style={{ width: "80%" }}
                          />
                        </div>
                      </div>
                      <div className="form-group form-inline">
                        <label
                          className="control-label col-sm-6"
                          style={{ textAlign: "left", width: "70%" }}
                        >
                          Channel of contact
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            value={contact.type_channel_of_contact}
                            style={{ width: "45%" }}
                            disabled
                          />
                          <input
                            type="text"
                            className="form-control"
                            value={contact.channel_of_contact}
                            disabled
                            style={{ width: "55%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="panel panel-default">
              <div className="panel-heading">Customer Information</div>
              <div className="panel-body">
                <div className="form form-xs">
                  <div className="row row-xs">
                    <div className="col-md-6">
                      <fieldset>
                        <legend>Search By</legend>
                        {/* <div className="form-horizontal"> */}
                        {/* <div className="form-group">
                          <label
                            className="control-label"
                            style={{ textAlign: "left" }}
                          >
                            Serial No
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              value={
                                machine.machine_serial
                                  ? machine.machine_serial
                                  : ""
                              }
                              disabled
                            />
                            <span className="input-group-btn">
                              <button className="btn btn-default" disabled>
                                <i className="fa fa-search" />
                              </button>
                            </span>
                          </div>
                        </div> */}
                        <div className="form-group">
                          <label
                            className="control-label"
                            style={{ textAlign: "left" }}
                          >
                            Equipment No
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              value={
                                machine_customer.item_no
                                  ? machine_customer.item_no
                                  : "-"
                              }
                              disabled
                            />
                            <span className="input-group-btn">
                              <button className="btn btn-default" disabled>
                                <i className="fa fa-search" />
                              </button>
                            </span>
                          </div>
                        </div>
                        {/* <div className="form-group">
                            <label className="control-label">
                              Notification No
                            </label>
                            <div className="input-group">
                              <input type="text" className="form-control" />
                              <span className="input-group-btn">
                                <button className="btn btn-default">
                                  <i className="fa fa-search" />
                                </button>
                              </span>
                            </div>
                          </div> */}
                        <div className="form-group">
                          <label
                            className="control-label"
                            style={{ textAlign: "left" }}
                          >
                            Customer Name
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              value={
                                customers.CustomerName
                                  ? customers.CustomerName
                                  : "-"
                              }
                              disabled
                            />
                            <span className="input-group-btn">
                              <button
                                className="btn btn-default"
                                data-toggle="modal"
                                data-target="#modal-search-name"
                                type="button"
                                disabled
                                // style={{width: "20px", textAlign: "left"}}
                              >
                                <i className="fa fa-search" />
                              </button>
                              <button
                                className="btn btn-default"
                                data-toggle="modal"
                                data-target="#modal-customer-multi"
                                type="button"
                                disabled
                              >
                                Multi
                              </button>
                            </span>
                          </div>
                        </div>
                        {/* </div> */}
                      </fieldset>
                      <div className="form-horizontal">
                        <div className="form-group">
                          <label
                            className="control-label col-sm-4"
                            style={{ textAlign: "left" }}
                          >
                            Model
                          </label>
                          <div className="col-sm-8">
                            <input
                              type="text"
                              className="form-control"
                              value={
                                machine_models.model_name
                                  ? machine_models.model_name
                                  : "-"
                              }
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            className="control-label col-sm-4"
                            style={{
                              textAlign: "left"
                            }}
                          >
                            Customer Category
                          </label>
                          <div className="col-sm-8">
                            <input
                              type="text"
                              className="form-control"
                              value={
                                customers.customer_category
                                  ? customers.customer_category
                                  : "-"
                              }
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            className="control-label col-sm-4"
                            style={{
                              textAlign: "left"
                            }}
                          >
                            Prefered CE
                          </label>
                          <div className="col-sm-8">
                            <input
                              type="text"
                              className="form-control"
                              value={technicians.code ? technicians.code : "-"}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <fieldset>
                        <legend>SHIP TO</legend>
                        <div className="form-horizontal">
                          <div className="form-group">
                            <label
                              className="control-label col-sm-5"
                              style={{
                                textAlign: "left"
                              }}
                            >
                              Ship to Name
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  machine_customer.ship_to_name
                                    ? machine_customer.ship_to_name
                                    : "-"
                                }
                                disabled
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              className="control-label col-sm-5"
                              style={{
                                textAlign: "left"
                              }}
                            >
                              Ship to Code
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  machine_customer.ship_to_code
                                    ? machine_customer.ship_to_code
                                    : "-"
                                }
                                disabled
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              className="control-label col-sm-5"
                              style={{
                                textAlign: "left"
                              }}
                            >
                              Ship to Contact
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  machine_customer.ship_to_contact_name
                                    ? machine_customer.ship_to_contact_name
                                    : "-"
                                }
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      {/* <fieldset>
                          <legend>SOLD TO</legend>
                          <div className="form-group">
                            <label className="control-label">
                              Sold to Name
                            </label>
                            <input type="text" className="form-control" />
                          </div>
                          <div className="form-group">
                            <label className="control-label">
                              Sold to Code
                            </label>
                            <input type="text" className="form-control" />
                          </div>
                          <div className="form-group">
                            <label className="control-label">
                              Sold to Contact
                            </label>
                            <input type="text" className="form-control" />
                          </div>
                        </fieldset> */}
                      <fieldset>
                        <legend>CSRC</legend>
                        <div className="form-horizontal">
                          <div className="form-group">
                            <label
                              className="control-label col-sm-4"
                              style={{
                                textAlign: "left"
                              }}
                            >
                              CSRC Status
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  customer.csrc_status
                                    ? customer.csrc_status
                                    : "-"
                                }
                                disabled
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              className="control-label col-sm-4"
                              style={{
                                textAlign: "left"
                              }}
                            >
                              Last Date of Connection
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>

                    <div className="col-md-6">
                      <fieldset>
                        <legend>BLOCK BY</legend>
                        <div className="form-horizontal">
                          <div className="form-group">
                            <label
                              className="control-label col-sm-4"
                              style={{
                                textAlign: "left"
                              }}
                            >
                              Status
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  customers.blocked_status === null
                                    ? "-"
                                    : "blocked"
                                }
                                disabled
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              className="control-label col-sm-4"
                              style={{ textAlign: "left" }}
                            >
                              Department
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  blocked_by.blocked_by_department
                                    ? blocked_by.blocked_by_department
                                    : "-"
                                }
                                disabled
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              className="control-label col-sm-4"
                              style={{ textAlign: "left" }}
                            >
                              Name
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  blocked_by.blocked_by_user_name
                                    ? blocked_by.blocked_by_user_name
                                    : "-"
                                }
                                disabled
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              className="control-label col-sm-4"
                              style={{ textAlign: "left" }}
                            >
                              User ID
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  blocked_by.blocked_by_user_id
                                    ? blocked_by.blocked_by_user_id
                                    : "-"
                                }
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset>
                        <legend>Contract Detail</legend>
                        <div className="form-horizontal">
                          <div className="form-group">
                            <label
                              className="control-label col-sm-4"
                              style={{
                                textAlign: "left"
                              }}
                            >
                              Contract No
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  contracts.contract_number
                                    ? contracts.contract_number
                                    : "-"
                                }
                                disabled
                              />
                            </div>
                          </div>
                          {/* <div className="form-group">
                            <label className="control-label">Start Date</label>
                            <input type="date" className="form-control" />
                          </div> */}
                          <div className="form-group">
                            <label
                              className="control-label col-sm-4"
                              style={{
                                textAlign: "left"
                              }}
                            >
                              End Date
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                value={moment(
                                  contracts.contract_end_date
                                ).format("L")}
                                disabled
                              />
                            </div>
                          </div>
                          {/* <div className="form-group">
                            <label className="control-label">User ID</label>
                            <input
                              type="text"
                              className="form-control"
                              value={contracts.id}
                              disabled
                            />
                          </div> */}
                        </div>
                      </fieldset>
                      {/* <fieldset>
                          <legend>Machine Extend</legend>
                          <div className="form-group">
                            <label className="control-label">Start Date</label>
                            <input type="date" className="form-control" />
                          </div>
                          <div className="form-group">
                            <label className="control-label">End Date</label>
                            <input type="date" className="form-control" />
                          </div>
                        </fieldset> */}
                      <fieldset>
                        <legend>Sales Details</legend>
                        <div className="form-horizontal">
                          <div className="form-group">
                            <label
                              className="control-label col-sm-4"
                              style={{
                                textAlign: "left"
                              }}
                            >
                              Sales Person Code
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  sales.Sales_Person_Code
                                    ? sales.Sales_Person_Code
                                    : "-"
                                }
                                disabled
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              className="control-label col-sm-4"
                              style={{
                                textAlign: "left"
                              }}
                            >
                              Sales Person Name
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  sales.Sales_Person_name
                                    ? sales.Sales_Person_name
                                    : "-"
                                }
                                disabled
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              className="control-label col-sm-4"
                              style={{
                                textAlign: "left"
                              }}
                            >
                              DSM Code
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                value={sales.dsm_code ? sales.dsm_code : "-"}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              className="control-label col-sm-4"
                              style={{
                                textAlign: "left"
                              }}
                            >
                              DSM Name
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                value={sales.dsm_name ? sales.dsm_name : "-"}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>

                  <fieldset>
                    <legend>Customer Details</legend>
                    <div className="form-horizontal">
                      <div className="form-group">
                        <label
                          className="control-label col-sm-2"
                          style={{
                            textAlign: "left"
                          }}
                        >
                          Ship to Address
                        </label>
                        <div className="col-sm-10">
                          <textarea
                            name=""
                            id=""
                            cols="30"
                            rows="3"
                            className="form-control"
                            value={machine_customer.ship_to_address}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="control-label col-sm-2"
                          style={{
                            textAlign: "left"
                          }}
                        >
                          Location of Equipment No
                        </label>
                        <div className="col-sm-10">
                          <textarea
                            name=""
                            id=""
                            cols="30"
                            rows="3"
                            className="form-control"
                            value={
                              machine_customer.location
                                ? machine_customer.location
                                : "-"
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row row-xs">
                      <div className="form-horizontal col-sm-6">
                        <div className="form-group" style={{ display: "flex" }}>
                          <label
                            className="control-label col-sm-2"
                            style={{
                              textAlign: "left"
                            }}
                          >
                            Telephone No
                          </label>
                          <div className="col-sm-offset-1 col-sm-1">
                            <input
                              type="text"
                              className="form-control"
                              value={
                                customer.customer_phone1
                                  ? customer.customer_phone1
                                  : "-"
                              }
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-horizontal col-md-5">
                        <div className="form-group">
                          <label
                            className="control-label col-sm-4"
                            style={{
                              textAlign: "left"
                            }}
                          >
                            Postal Code
                          </label>
                          <div className="col-sm-8">
                            <input
                              type="text"
                              className="form-control"
                              value={
                                customer.ship_to_postcode
                                  ? customer.ship_to_postcode
                                  : "-"
                              }
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            className="control-label col-sm-4"
                            style={{
                              textAlign: "left"
                            }}
                          >
                            Delivery Date
                          </label>
                          <div className="col-sm-8">
                            <input
                              type="text"
                              className="form-control"
                              value={
                                machine_customer.delivery_date
                                  ? machine_customer.delivery_date
                                  : "-"
                              }
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset>
                    <legend>SLA DETAILS</legend>
                    <div className="form-horizontal">
                      <div className="form-group">
                        <label
                          className="control-label col-sm-2"
                          style={{
                            textAlign: "left"
                          }}
                        >
                          SLA Response Time
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            value={
                              customers.sla_details ? customers.sla_details : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="control-label col-sm-2"
                          style={{
                            textAlign: "left"
                          }}
                        >
                          Additional SLA Details
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
            <div className="text-right">
              <button className="btn btn-default btn-sm">
                CUSTOMER HISTORY
              </button>
            </div>
            {/* </form> */}
          </div>
        );
        kanan = (
          <div className="col-md-6">
            <div className="panel panel-default">
              <div className="panel-heading">
                Ticket Number :{" "}
                {assign_ce.ticket_number ? assign_ce.ticket_number : "-"}
              </div>
              <div className="panel-body">
                <div className="form form-xs">
                  <div
                    className="form-inline form-group"
                    style={{ display: "flex" }}
                  >
                    <label style={{ width: "40px" }}>From </label>
                    <input
                      type="text"
                      className="form-control"
                      value={department.name ? department.name : "-"}
                      style={{ width: "100px" }}
                      disabled
                    />
                    <label style={{ width: "15px", textAlign: "left" }}>
                      To
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={levels ? levels : "-"}
                      style={{ width: "100px" }}
                      disabled
                    />{" "}
                    <label
                      className="col-sm-1"
                      style={{
                        textAlign: "left",
                        width: "90px",
                        fontSize: "10px"
                      }}
                    >
                      {" "}
                      Requirement Process
                    </label>{" "}
                    <input
                      type="text"
                      className="form-control"
                      value={ticket_type.name ? ticket_type.name : "-"}
                      style={{ width: "80px" }}
                      disabled
                    />
                    <label
                      style={{
                        textAlign: "left",
                        width: "5px"
                      }}
                    >
                      {" "}
                    </label>
                    <button
                      className="btn btn-default"
                      style={{ width: "100px" }}
                      onClick={this.showModalMachine2}
                    >
                      Track Ticket
                    </button>
                  </div>
                  <div className="col-md-7">
                    <input
                      type="checkbox"
                      name="radio-2"
                      onChange={this.handleApprove}
                    />{" "}
                    Approved by Credit Control
                  </div>
                  <div className="form-group form-inline col-sm-6">
                    <label>Date Time</label>{" "}
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        value={
                          this.state.datex
                            ? moment(this.state.datex).format(
                                "YYYY-MM-DD hh:mm"
                              )
                            : "-"
                        }
                        disabled
                      />

                      <span className="input-group-addon">
                        <i className="fa fa-calendar" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">Call Information/Request</div>
              <div className="panel-body">
                <div className="form form-xs">
                  <div className="form-group" style={{ display: "block" }}>
                    <label>Contact Note</label>
                    <textarea
                      type="text"
                      className="form-control"
                      value={
                        ticket_origin.contact_note
                          ? ticket_origin.contact_note
                          : "-"
                      }
                      disabled
                    />
                  </div>
                  {/* <div className="form-group" style={{ display: "block" }}>
                    <label>
                      <a
                        className="btn btn-default"
                        data-toggle="collapse"
                        href="#multiCollapseExample1"
                        role="button"
                        aria-expanded="false"
                        aria-controls="multiCollapseExample1"
                        style={{ fontSize: "11px" }}
                      >
                        View Internal Note
                      </a>
                    </label>

                    <div
                      className="collapse multi-collapse"
                      id="multiCollapseExample1"
                    >
                      <textarea
                        type="text"
                        className="form-control"
                        value={
                          ticket_origin.internal_note
                            ? ticket_origin.internal_note
                            : ""
                        }
                        disabled
                      />
                    </div>
                  </div> */}
                  <div className="form-group" style={{ display: "block" }}>
                    <label>Internal Note</label>
                    <textarea
                      type="text"
                      className="form-control"
                      value={
                        ticket_origin.internal_note
                          ? ticket_origin.internal_note
                          : "-"
                      }
                      disabled
                    />
                  </div>
                  <div className="form-group" style={{ display: "block" }}>
                    <label>Action Taken</label>
                    <textarea
                      type="text"
                      className="form-control"
                      value={
                        ticket_origin.action_taken
                          ? ticket_origin.action_taken
                          : "-"
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-body">
                <div className="form form-xs">
                  <div className="form-group">
                    <div className="form-inline">
                      <input
                        type="radio"
                        name="radio-1"
                        checked={
                          ticket_origin.is_service === 1 ? "checked" : ""
                        }
                        disabled
                      />
                      <label style={{ width: "170px" }}>
                        Service/Machine Problem
                      </label>
                    </div>
                    <div className="form-inline">
                      <input
                        type="radio"
                        name="radio-1"
                        checked={
                          ticket_origin.is_service === 0 ? "checked" : ""
                        }
                        disabled
                      />
                      <label>Internal Follow Up?</label>
                    </div>
                    <div className="form-inline">
                      <input
                        type="radio"
                        name="radio-1"
                        checked={
                          ticket_origin.is_service === 2 ? "checked" : ""
                        }
                        disabled
                      />
                      <label>Other?</label>
                    </div>
                  </div>
                  <div className="well">
                    <div className="form-horizontal">
                      <div className="form-group">
                        <label
                          className="control-label col-sm-3"
                          style={{
                            textAlign: "left",
                            fontSize: "11px"
                          }}
                        >
                          Fault Comment
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            value={
                              ticket_origin.fault_comment
                                ? ticket_origin.fault_comment
                                : "-"
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="control-label col-sm-3"
                          style={{
                            textAlign: "left",
                            fontSize: "11px"
                          }}
                        >
                          Machine Status
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            value={
                              machine_status.name ? machine_status.name : "-"
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="control-label col-sm-3"
                          style={{
                            textAlign: "left",
                            fontSize: "11px"
                          }}
                        >
                          Machine Type
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            value={machine_type.name ? machine_type.name : "-"}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="control-label col-sm-3"
                          style={{
                            textAlign: "left",
                            fontSize: "11px"
                          }}
                        >
                          Service Code
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            value={
                              service_code.job_detail
                                ? service_code.job_detail
                                : "-"
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-body">
                <div className="panel-body form form-xs">
                  <div className="form-horizontal col-md-6">
                    <div className="form-group">
                      <label
                        className="control-label col-sm-4"
                        style={{
                          textAlign: "left",
                          fontSize: "11px"
                        }}
                      >
                        Service Notification Number
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          value={"S" + assign_ce.id}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        className="control-label col-sm-4"
                        style={{
                          textAlign: "left",
                          fontSize: "11px"
                        }}
                      >
                        Service Code
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          value={
                            service_code.job_code ? service_code.job_code : "-"
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-horizontal col-md-6">
                    <div className="form-group">
                      <label
                        className="control-label col-sm-4"
                        style={{
                          textAlign: "left",
                          fontSize: "11px"
                        }}
                      >
                        Response Time (Hours)
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          value={
                            customers.sla_details ? customers.sla_details : "-"
                          }
                          disabled
                        />
                      </div>
                    </div>
                    <div
                      className={
                        this.validator.message(
                          "Priority",
                          this.state.priority,
                          "required"
                        )
                          ? "form-group has-error"
                          : "form-group"
                      }
                    >
                      <label
                        className="control-label col-sm-4"
                        style={{
                          textAlign: "left",
                          fontSize: "11px"
                        }}
                      >
                        Priority
                      </label>
                      <div className="col-sm-8">
                        <select
                          name="priority"
                          className="form-control"
                          value={this.state.priority}
                          onChange={this.handleAssignCE}
                          required
                        >
                          <option value="">---SELECT---</option>
                          <option value="1">Low</option>
                          <option value="2">Medium</option>
                          <option value="3">High</option>
                        </select>
                        <h6 style={{ color: "#A94442" }}>
                          {this.validator.message(
                            "priority",
                            this.state.priority,
                            "required|integer"
                          )}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <fieldset>
                  <legend>One Time Pin Processing</legend>
                  <div className="panel-body form form-xs">
                    <div className="form-horizontal">
                      <div
                        className={
                          this.validator.message(
                            "Assigned Time Segment",
                            this.state.customer_contact_name,
                            "required"
                          )
                            ? "form-group has-error"
                            : "form-group"
                        }
                      >
                        <label
                          className="control-label"
                          style={{
                            textAlign: "left",
                            fontSize: "11px"
                          }}
                        >
                          Customer Contact Name
                        </label>
                        {/* <div className="col-sm-10"> */}
                        <div className="col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            name="customer_contact_name"
                            onChange={this.handleAssignCE}
                          />
                          <h6 style={{ color: "#A94442" }}>
                            {this.validator.message(
                              "Customer Contact Name",
                              this.state.customer_contact_name,
                              "required"
                            )}
                          </h6>
                        </div>
                        {/* <div className="col-sm-10">
                            
                          </div> */}
                        {/* </div> */}
                      </div>
                      <div
                        className={
                          this.validator.message(
                            "Assigned Time Segment",
                            this.state.input_otp,
                            "required"
                          )
                            ? "form-group has-error"
                            : "form-group"
                        }
                        //  style={{ display: "flex" }}
                      >
                        <label
                          className="control-label"
                          style={{
                            textAlign: "left",
                            fontSize: "11px"
                          }}
                        >
                          Input OTP
                          {/* {this.state.input_otp} */}
                          {/* {"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"}
                            {"sdg"} */}
                        </label>
                        {/* <form onSubmit={this.handleDisabled}> */}
                        {/* <table className="table table-bordered table-xs">
                          <tbody>
                            <tr>
                              <td>
                                <div> */}

                        {/* <div className="input-group col-sm-7">
                            <input
                              type="text"
                              className="form-control"
                              value={
                                customers.CustomerName
                                  ? customers.CustomerName
                                  : ""
                              }
                              disabled
                            />
                            <span className="input-group-btn"> */}

                        <div className="col-sm-8">
                          <div className="input-group col-sm-6">
                            {/* <div className="form-group form-inline"> */}
                            <NumberFormat
                              className="form-control"
                              format="# # # #"
                              mask="_"
                              name="input_otp"
                              value={this.state.input_otp}
                              onChange={this.input_otp}
                              disabled={button === "disabled" ? "disabled" : ""}
                              style={{ textAlign: "center" }}
                            />
                            {/* </div>
                        <div className="col-sm-5"> */}
                            <span className="input-group-btn">
                              {button === "disabled" ? (
                                <button
                                  onClick={this.handleEnabled}
                                  className="btn btn-primary btn-xs"
                                >
                                  CHANGE
                                </button>
                              ) : (
                                <button
                                  onClick={this.handleDisabled}
                                  className="btn btn-danger"
                                  disabled={
                                    !this.state.input_otp ||
                                    otpNumb.toString().length !== 4
                                      ? "disabled"
                                      : ""
                                  }
                                >
                                  SAVE
                                  {/* {otpNumb.toString().length} */}
                                </button>
                              )}
                            </span>
                          </div>
                          <h6 style={{ color: "#A94442" }}>
                            {this.validator.message(
                              "otp",
                              this.state.input_otp,
                              "required"
                            )}
                          </h6>
                        </div>
                        {/* </div>
                              </td>
                            </tr> */}

                        {/* </tbody> */}
                        {/* </table> */}

                        {/* <table className="table table-bordered table-xs">
                              <tr>
                                <NumberFormat
                                  className="form-control"
                                  format="# # # #"
                                  mask="_"
                                  name="input_otp"
                                  value={this.state.input_otp}
                                  onChange={this.input_otp}
                                  disabled={
                                    button === "disabled" ? "disabled" : ""
                                  }
                                />

                                <td>
                                  {button === "disabled" ? (
                                    <button
                                      onClick={this.handleEnabled}
                                      className="btn btn-primary btn-xs"
                                    >
                                      CHANGE
                                    </button>
                                  ) : (
                                    <button
                                      onClick={this.handleDisabled}
                                      className="btn btn-danger"
                                    >
                                      SAVE
                                    </button>
                                  )}{" "} */}
                        {/* <span className="label label-success">
                                    Old OTP : {assign_ce.input_otp}
                                  </span>{" "}
                                  <span className="label label-warning">
                                    New OTP : {ott}
                                  </span> */}
                        {/* </td>
                              </tr>
                            </table> */}
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <legend>Assign CE Processing</legend>
                  <div className="panel-body form form-xs">
                    <div className="row row-xs">
                      <div className="form-horizontal col-md-6">
                        <div
                          className="form-group"
                          // {
                          //   this.validator.message(
                          //     "Assigned Time Segment",
                          //     this.state.id_ce,
                          //     "required"
                          //   )
                          //     ? "form-group has-error"
                          //     : "form-group"
                          // }
                        >
                          <label
                            className="control-label col-sm-3"
                            style={{
                              textAlign: "left"
                            }}
                          >
                            Preferred Resource CE
                            {/* {technicians.code} */}
                            {/* {this.state.pickMap} */}
                          </label>
                          <div className="col-sm-9">
                            <select
                              name="id_ce"
                              // value={id_ce}
                              className="form-control"
                              onChange={this.handleChange2}
                              disabled
                            >
                              <option value="">-</option>
                              {this.state.pickMap ? (
                                <option
                                  defaultValue={
                                    this.state.pickMap +
                                    "#" +
                                    this.state.nameMap +
                                    "#" +
                                    this.state.enaMap +
                                    "#" +
                                    this.state.lngMap +
                                    "#" +
                                    this.state.latMap
                                    // pickMap: key,
                                    // latMap: childProps.lat,
                                    // lngMap: childProps.lng,
                                    // nameMap: childProps.text,
                                    // enaMap: childProps.enableCe
                                  }
                                  selected

                                  // key={tic.id}
                                >
                                  {
                                    // this.state.pickMap +
                                    this.state.nameMap
                                    // this.state.enaMap +
                                    // this.state.lngMap +
                                    // this.state.latMap
                                  }
                                </option>
                              ) : (
                                <option value="">-</option>
                              )}
                              {/* {ce.map(tic => (
                                  <option
                                    value={
                                      tic.id +
                                      "#" +
                                      tic.username +
                                      "#" +
                                      tic.enabled +
                                      "#" +
                                      tic.longitude +
                                      "#" +
                                      tic.latitude
                                    }
                                    disabled={
                                      tic.enabled === 1 ? "" : "disabled"
                                    }
                                    selected={
                                      tic.id === this.state.pickMap
                                        ? "selected"
                                        : ""
                                    }
                                    key={tic.id}
                                  >
                                    {tic.username}/{tic.enabled}/{tic.id}
                                    {technicians.id === tic.id
                                      ? "-->Primary"
                                      : ""}
                                  </option>
                                ))} */}
                            </select>
                            {/* <h6 style={{ color: "red" }}>
                                {this.validator.message(
                                  "Preferred Resource CE",
                                  this.state.valuex,
                                  "required"
                                )}
                              </h6> */}
                          </div>
                        </div>
                        <div
                          className={
                            (addce !== "ada" &&
                              this.state.pickMap &&
                              this.state.ini3) ||
                            are_holiday
                              ? "form-group has-error"
                              : "form-group"
                          }
                        >
                          <label
                            className="control-label col-sm-3"
                            style={{
                              textAlign: "left",
                              fontSize: "11px"
                            }}
                          >
                            Status of CE
                            {/* {this.state.pickMap} */}
                            {/* {name_ce} */}
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              value={
                                addce === "ada" && !are_holiday
                                  ? "Available"
                                  : "Not Available"
                              }
                              // value={
                              //   this.state.enaMap === 1
                              //     ? "Available"
                              //     : "Not Available"
                              //   // id_ce
                              //   //   ? av + " (" + long + ", " + lat + ")"
                              //   //   : ""
                              // }
                              disabled
                            />
                          </div>

                          {/* <input
                            type="text"
                            name="name_ce"
                            className="form-control"
                            onChange={this.handleChange2}
                            value={filtered.map(person => person.username)}
                          />
                          <input
                            type="text"
                            name="enable_ce"
                            className="form-control"
                            onChange={this.handleChange2}
                            value={filtered.map(person => person.enabled)}
                          /> */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-6">
                          <div className="form-group">
                            <button
                              className="btn btn-default btn-block"
                              disabled={this.state.pickMap ? "" : "disabled"}
                              onClick={this.showModalMachine3}
                            >
                              CALENDAR
                            </button>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            {/* <button
                                className="btn btn-default btn-block"
                                onClick={this.showModalMachine}
                                disabled={id_ce ? "" : "disabled"}
                              >
                                MAPS
                              </button> */}
                            {/* <button
                              className="btn btn-default btn-block"
                              onClick={this.showModalMachineNew}
                              // disabled={id_ce ? "" : "disabled"}
                            >
                              MAPS
                            </button> */}
                            <Butt action={this.muncul} text="MAPS" />
                          </div>
                        </div>
                        <br />
                        <div className="form-horizontal">
                          <div className="col-sm-12">
                            <div
                              className={
                                this.validator.message(
                                  "Assigned Time Segment",
                                  this.state.ini,
                                  "required"
                                )
                                  ? "form-group has-error"
                                  : "form-group"
                              }
                            >
                              <label
                                className="control-label col-sm-2"
                                style={{
                                  textAlign: "left",
                                  fontSize: "10px"
                                }}
                              >
                                Assigned Time Segment
                              </label>
                              {/* <input
                            type="date"
                            className="form-control"
                            name="date_of_assign"
                            onChange={this.handleAssignCE}
                          /> */}

                              <div className="col-md-10">
                                {/* {moment(new Date()).format("L")} */}
                                {/* {this.state.valueOfInput} */}
                                <input
                                  className="text"
                                  className="form-control"
                                  value={this.state.ini ? this.state.ini : ""}
                                  id="inputError"
                                  disabled
                                />

                                {/* <DatePicker
                                className="form-control "
                                selected={this.state.valueOfInput}
                                // name="date_of_assign"
                                // onChange={this.handleAssignCE}
                                onChange={this.handleChange}
                                excludeDates={listcal}
                                highlightDates={listcal}
                                // minDate={new Date("2019", "05", "07")}
                                // filterDate={date => {
                                //   return moment() > date;
                                // }}

                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                timeCaption="time"
                                startDate={this.state.valueOfInput}
                                endDate={this.state.endDate}
                                selectsStart
                              /> */}
                                <h6 style={{ color: "#A94442" }}>
                                  {this.validator.message(
                                    "Assigned Time Segment",
                                    this.state.ini,
                                    "required"
                                  )}
                                </h6>
                              </div>
                              {/* <div className="col-md-6"> */}
                              {/* <DatePicker
                                className="form-control"
                                selected={this.state.endDate}
                                selectsEnd
                                startDate={this.state.valueOfInput}
                                endDate={this.state.endDate}
                                onChange={this.handleChangeEnd}
                                minDate={this.state.valueOfInput}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                timeCaption="time"
                              /> */}
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* {moment.locale()}
                      {moment(this.state.ini1).format("dddd hh:mm")} */}
                      <div className="col-sm-6" style={{ textAlign: "right" }}>
                        {/* {this.state.pickMap +
                          "#" +
                          this.state.nameMap +
                          "#" +
                          this.state.enaMap +
                          "#" +
                          this.state.lngMap +
                          "#" +
                          this.state.latMap} */}
                        {/* <div className="form-group pull-right"> */}
                        <h6 style={{ color: "#A94442" }}>
                          {addce !== "ada" &&
                          this.state.pickMap &&
                          this.state.ini3
                            ? note
                            : ""}
                        </h6>
                        <h6 style={{ color: "#A94442" }}>{are_holiday}</h6>
                        <button
                          className={
                            this.validator.message(
                              "Assigned Time Segment",
                              this.state.valuex,
                              "required"
                            )
                              ? "btn btn-danger btn-xs"
                              : "btn btn-default btn-xs"
                          }
                          onClick={this.save.bind(this)}
                          disabled={
                            this.state.pickMap &&
                            celistCek.length === 0 &&
                            addce === "ada" &&
                            !are_holiday
                              ? ""
                              : "disabled"
                          }
                        >
                          ADD CE
                          {/* {addce ? "" : "disabled"} */}
                        </button>

                        {/* </div> */}
                        <h6 style={{ color: "#A94442" }}>
                          {this.validator.message(
                            "ce Assigned",
                            this.state.valuex,
                            "required"
                          )}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="form-group text-right">
                    {value.length !== 0 ? (
                      <div className="pull-left">
                        <button
                          className="btn btn-danger btn-xs"
                          onClick={this.handleDeleteCE}
                        >
                          RESET <i class="fa fa-fw fa-trash" />
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <table className="table table-bordered table-xs">
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {value3.map(tic => (
                        <tr>
                          <td>{tic.id_ce}</td>
                          <td>{tic.name_ce}</td>
                          <td>
                            {tic.enable_ce === "1"
                              ? "Available"
                              : "Not Available"}
                          </td>
                          <td>{tic.type_ce}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </fieldset>
                <br />
                <br />
                {/* <input
                    type="text"
                    name="name_ce"
                    className="form-control"
                    onChange={this.percob}
                    // value={filtered.map(person => person.username)}
                  /> */}
                <input type="checkbox" name="radio-2" /> Email to Credit Control
              </div>
            </div>
            <div className="text-left">
              <button
                className="btn btn-warning btn-sm"
                onClick={this.handleSaveAssignCE}
                // disabled={assign_ce.input_otp || ott ? "" : "disabled"}
              >
                SUBMIT
              </button>{" "}
              {/* {assign_ce.input_otp || ott ? (
                ""
              ) : (
                <span className="label label-danger">Save OTP First</span>
              )} */}
            </div>
          </div>
        );
      }
    }

    return (
      <div id="app">
        <Navbar
          icon="fa fa-ticket icon"
          information="TICKET MANAGEMENT"
          detail="Assign CE"
        />
        <Sidebar information="TicketManagement" />
        <br />
        <div id="main" className="main-xs">
          <div className="row row-xs">
            <div style={{ textAlign: "center" }}>
              {kiri ? (
                ""
              ) : (
                <i
                  className="fa fa-spinner fa-spin fa-3x"
                  style={{
                    opacity: "0.3",
                    top: "50%",
                    left: "50%",
                    position: "absolute"
                  }}
                />
              )}
            </div>
            <div>{kiri ? kiri : ""}</div>
            <div>{kanan ? kanan : ""}</div>
          </div>
          <br />
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // originator: state.originator,
  // tickets_det: state.tickets_det,
  // originator_des: state.originator_des,
  // originator_des_dep: state.originator_des_dep,
  // customer: state.customer,
  // ticket_details: state.ticket_details

  yyy: state.user,
  assign_ce: state.assignCe,
  action_by: state.assignCe.assign_ce,
  ticket_tracking: state.trackingReducer,
  calendar_find: state.calendar,
  map_location: state.map_location,
  workingReducer: state.workingReducer,
  holiday_Reducer: state.holiday_Reducer
});
export default connect(
  mapStateToProps,
  {
    processTicketAssignCE,
    saveOtp,

    listUser,
    saveAssignCE,
    ticketTracking,
    calendar,
    mapAction,
    del_otp,
    del_assign,
    working_list,
    holiday_list,
    ce_location
  }
)(
  GoogleApiWrapper({
    apiKey: "AIzaSyD8kVr5dj4fb_s-s80rqu8mbehkHKRXgFY"
  })(AssignCE)
);
