import { QUOTATION_PDF_SAVE, QUOTATION_PDF_PURGE } from "../actions/types";

const initialState = {
};

export default function(state = initialState, action) {
  switch (action.type) {
  case QUOTATION_PDF_SAVE:
    return {
      ...state,
      ...action.payload
    }
  case QUOTATION_PDF_PURGE:
    return null;
  default:
    return state;
  }
}









