import React, { Component } from "react";

class ConsumableRequested extends Component {
  render() {
    return (
      <table className="table table-bordered table-xs">
        <thead>
          <tr>
            <th />
            <th colSpan="4" className="text-center">
              TONER
            </th>
            <th className="text-center">STAPLER</th>
            <th className="text-center">WTB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td />
            <td className="text-center">C</td>
            <td className="text-center">Y</td>
            <td className="text-center">M</td>
            <td className="text-center">K</td>
            <td />
            <td>
              <select name="" id="" className="form-control">
                <option defaultValue="">A3</option>
                <option defaultValue="">A4</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>Quantity</td>
            <td>
              <input
                type="text"
                className="form-control"
                defaultValue={this.props.request_c}
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                defaultValue={this.props.request_y}
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                defaultValue={this.props.request_m}
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                defaultValue={this.props.request_k}
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                defaultValue={this.props.request_stapler}
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                defaultValue={this.props.request_wtb}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default ConsumableRequested;
