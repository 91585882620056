import React from 'react';

import Modal from '../../../../ui/Modal/Modal';
//import './Modal.css'
import moment from 'moment'

const trackTicketModal = (props) => {
  return (
    <Modal title="Ticket Tracking" onclose={props.onclose} modalid={props.modalid} modalclass={props.modalclass} mdialog={props.mdialog}>
      <div className="modal-body">
        <div className="table-fixed-header" style={{height: "200px"}}>
          <table className="table table-bordered table-hover table-xs table-fixed">
            <thead>
              <tr>
                <th width="13%">Date Time</th>
                <th width="22%">Description</th>
                <th width="15%">Action By</th>
                <th width="15%">Department</th>
                <th width="18%">Assign to Department</th>
                <th width="15%">Recived By</th>
                <th width="7%">Status</th>
              </tr>
            </thead>
            <tbody>
              {props.tracking.map((tr, i) => (
                <tr key={i+1}>
                  <td width="13%">{moment(tr.created_at).format('L hh:mm')}</td>
                  <td width="22%">{tr.ticket_type.name}</td>
                  <td width="15%">{tr.action_by.fullname}</td>
                  <td width="15%">{tr.action_department.name}</td>
                  <td width="18%">{tr.received_department.name}</td>
                  <td width="15%">{tr.received_by.fullname}</td>
                  <td width="7%">{tr.ticket_status.name}</td>
                </tr>
              ))}
              
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-footer align-right">
        <button type="button" className="btn btn-default pull-right" onClick={props.onclose}>Close</button>
      </div>
    </Modal>
  )
}

export default trackTicketModal;
