import React from "react";
import TextFormControl from '../../../common/TextFormControl';

const Machine = (props) => {
  return (
    <div className="row row-xs">
      <div className="col-md-4">
        <div className="form-group">
          <input type={props.type} className={props.cname} name="machine_model" value={props.machine_model} onChange={props.onChange}/>
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <input type={props.type} className={props.cname} name="machine_serial" value={props.machine_serial} onChange={props.onChange}/>
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <input type={props.type} className={props.cname} name="equipment_number" value={props.equipment_number} onChange={props.onChange}/>
        </div>
      </div>
    </div>
  );
};

TextFormControl.defaultProps = {
  type: "text",
  cname: "form-control" 
};

export default Machine;
