import React, { Component } from 'react';
import TextInputGroup from "../../../common/TextInputGroup";
import TextInputDisabled from "../../../common/TextInputDisabled";
import TextAreaInput from "../../../common/TextAreaInput";
import moment from 'moment';

const CustomerInformation = props => {
  return (
    <React.Fragment>
      <div className="panel panel-default">
        <div className="panel-heading">Customer Information</div>
        <div className="panel-body">
          <div className="form form-xs">
            <div className="row row-xs">
              <div className="col-md-6">
                <fieldset>
                  <legend>Search By</legend>
                  {/* <TextInputGroup label="Serial No" name="serial_no" value={props.machine.machine_serial ? props.machine.machine_serial : ''} disabled="disabled"/> */}
                  <TextInputGroup label="Equipment No" name="equipment_no" value="" disabled="disabled"/>
                  <TextInputGroup label="Customer Name" name="serial_no" value={props.cust.CustomerName ? props.cust.CustomerName : ''} disabled="disabled"/>
                </fieldset>
                <TextInputDisabled label="Model" value=""/>
                <TextInputDisabled label="Customer Category" value=""/>
                <TextInputDisabled label="Prefered CE" value=""/>
                <fieldset>
                  <legend>Ship To</legend>
                  <TextInputDisabled label="Ship to Name" value={props.st.ship_to_contact_name ? props.st.ship_to_contact_name : ''}/>
                  <TextInputDisabled label="Ship to Code" value={props.st.ship_to_code ? props.st.ship_to_code : ''}/>
                  <TextInputDisabled label="Ship to Contact" value={props.st.ship_to_contact ? props.st.ship_to_contact : ''}/>
                </fieldset>
                <fieldset>
                  <legend>CSRC</legend>
                  <TextInputDisabled label="CSRC Status" value=""/>
                  <TextInputDisabled label="Last Day of Connection" value=""/>
                </fieldset>
              </div>
              <div className="col-md-6">
                <fieldset>
                  <legend>Block By</legend>
                  <TextInputDisabled label="Status" value="" />
                  <TextInputDisabled label="Department" value=""/>
                  <TextInputDisabled label="Name" value=""/>
                  <TextInputDisabled label="User ID" value=""/>
                </fieldset>
                <fieldset>
                  <legend>Contract Detail</legend>
                  <TextInputDisabled label="Contract No" value=""/>
                  <TextInputDisabled label="End Date" value=""/>
                </fieldset>
                <fieldset>
                  <legend>Sales Detail</legend>
                  <TextInputDisabled label="Sales Person Code" value={props.cust.sap_sales_person_code ? props.cust.sap_sales_person_code : ''}/>
                  <TextInputDisabled label="Sales Person Name" value={props.cust.sap_sales_person_name ? props.cust.sap_sales_person_name : ''}/>
                  <TextInputDisabled label="DSM Code" value={props.cust.sap_dsm_code ? props.cust.sap_dsm_code : ''}/>
                  <TextInputDisabled label="DSM Name" value={props.cust.sap_dsm_name ? props.cust.sap_dsm_name : ''}/>
                </fieldset>
              </div>
            </div>
            <fieldset>
              <legend>Customer Details</legend>
              <TextAreaInput label="Ship to Address" value={props.st.ship_to_address ? props.st.ship_to_address : ''} disabled="disabled"/>
              <TextAreaInput label="Location of Equipment No" value={props.st.location ? props.st.location : ''} disabled="disabled"/>
              <div className="row">
                <div className="col-md-6">
                  <TextInputDisabled label="Telephone No" value="" disabled="disabled"/>
                </div>
                <div className="col-md-6">
                  <TextInputDisabled label="Postal Code" value={props.st.ship_to_postcode ? props.st.ship_to_postcode : ''} disabled="disabled"/>
                  <TextInputDisabled label="Delivery Date" value={props.st.delivery_date ? moment(props.st.delivery_date).format('L') : ''} disabled="disabled"/>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>SLA Details</legend>
              <TextInputDisabled label="SLA Response Time" value=""/>
              <TextInputDisabled label="Additional SLA Details" value=""/>
            </fieldset>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CustomerInformation;
