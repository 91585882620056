import React, { Component } from "react";
import axios from '../../../../axios-env';
import uuid from "uuid";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";

import TextFormControl from "../../../common/TextFormControl";
import TextInputDisabled from "../../../common/TextInputDisabled";
import TextAreaInput from "../../../common/TextAreaInput";
import SpanError from "../../../common/SpanError";
import Flash from '../../../../components/common/FlashMessage';

import Navbar from "../../../layout/Navbar";
import Sidebar from "../../../layout/Sidebar";
import Machine from "./Machine";

//import { setCurrentUser } from "../../../../actions/authActions";
//import { storeInstallationTicket } from "../../../../actions/installationActions";

const initialState = {
  date_time_created: moment().format('YYYY-MM-DD HH:mm'),
  customer_name: "",
  ship_to_name: "",
  ship_to_code: "",
  ship_to_contact: "",
  ship_to_address: "",
  sold_to_name: "",
  sold_to_code: "",
  sold_to_contact: "",
  telp_number: "",
  postal_code: "",
  delivery_date_time: "",
  machines: [],
  
  //Alert
  alert_type: '',
  alert_message: ''
};

class Create extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.validator = new SimpleReactValidator({autoForceUpdate: this})
  }

  

  addRowMachine = e => {
    e.preventDefault();
    //const { machines } = this.state;
    const newRow = {
      machine_id: uuid(),
      machine_model: "",
      machine_serial: "",
      equipment_number: ""
    };
    this.setState(prevState => ({
      machines: [...prevState.machines, newRow]
    }));
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  onChangeMachine = (e,key) => {
    var mo = this.state.machines.map(m => (
      m.machine_id === key ? Object.assign({}, m, { [e.target.name]: e.target.value }) : m
    ))
    this.setState({machines: mo});
  }

  onSubmit = e => {
    e.preventDefault();

    if (this.validator.allValid()) { 
      const updatedState = {...this.state}
      const headers = { headers: { Authorization: `Bearer ${localStorage.getItem("jwtToken")}`}}
      const sendData = {
        ...this.state,
        machine: updatedState.machines
      }

    if(this.state.machine === undefined || this.state.machine.length === 0){
        this.setState({...this.state, alert_type: 'error', alert_message: 'Need Minimum 1 Machine to Create Installation Ticket!'});
        console.log('masuk');
      }else{
    
    console.log('bablas');
    console.log(this.state.machine);
      axios.post('/api/v1/ticket-management/create-installation/store', sendData, headers)
        .then(res => {
          if(res.data.meta.code === 200){
            this.setState({...initialState, alert_type: 'success', alert_message: 'New Installation Ticket Has Been Created!'});
            this.validator.hideMessages();
            this.props.history.push("/ticket-management");
          }else{
            console.log(res.data.meta.message);
            this.setState({...this.state, alert_type: 'error', alert_message: 'Failed To Created New Installation Ticket!'});
          }
        }).catch(error => {
          this.setState({...this.state, alert_type: 'error', alert_message: 'Something error'});
          console.log(error);
          })
      }
    
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    //console.log('render lagi');
    //console.log(this.state);
    let alert = '';
    if(this.state.alert_type){
      alert = <Flash type={this.state.alert_type} message={this.state.alert_message}/>
    }
    return (
      <div>
        <Navbar
          icon="fa fa-ticket icon"
          information="Ticket Management"
          detail="CREATE NEW INSTALLATION TICKET"
        />
        <br /> <br /> <br />
        <Sidebar information="TicketManagement" />
        <div id="main" className="main-xs">
          {alert}
          <div className="row row-xs">
            <form onSubmit={this.onSubmit}>
              <div className="col-md-8">
                <div className="panel panel-default">
                  <div className="panel-heading">Originator</div>
                  <div className="panel-body">
                    <div className="form form-xs">
                      <div className="row row-xs">
                        <div className="col-md-6">
                          <TextInputDisabled
                            label="Department"
                            value={localStorage.level}
                          />
                          <TextInputDisabled label="Date/Time Created" value={this.state.date_time_created}/>
                        </div>
                        <div className="col-md-6">
                          <TextInputDisabled label="Name" value={localStorage.username} />
                          <TextInputDisabled label="User ID" value={localStorage.iduser} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">Customer Information</div>
                  <div className="panel-body">
                    <div className="form form-xs">
                      <div className="row row-xs">
                        <div className="col-md-6">
                          <TextFormControl
                            dcname={this.validator.message('customer_name', this.state.customer_name, 'required') ? 'form-group has-error' : 'form-group'}
                            label="Customer Name"
                            name="customer_name"
                            value={this.state.customer_name}
                            onChange={this.onChange}
                          />{this.validator.message('customer_name', this.state.customer_name, 'required') ? <SpanError>{this.validator.message('customer_name', this.state.customer_name, 'required')}</SpanError> : ''}
                          <TextFormControl
                            dcname={this.validator.message('ship_to_name', this.state.ship_to_name, 'required') ? 'form-group has-error' : 'form-group'}
                            label="Ship to Name"
                            name="ship_to_name"
                            value={this.state.ship_to_name}
                            onChange={this.onChange}
                          />{this.validator.message('ship_to_name', this.state.ship_to_name, 'required') ? <SpanError>{this.validator.message('ship_to_name', this.state.ship_to_name, 'required')}</SpanError> : ''}
                          <TextFormControl
                            dcname={this.validator.message('ship_to_code', this.state.ship_to_code, 'required') ? 'form-group has-error' : 'form-group'}
                            label="Ship to Code"
                            name="ship_to_code"
                            value={this.state.ship_to_code}
                            onChange={this.onChange}
                          />{this.validator.message('ship_to_code', this.state.ship_to_code, 'required') ? <SpanError>{this.validator.message('ship_to_code', this.state.ship_to_code, 'required')}</SpanError> : ''}
                          <TextFormControl
                            dcname={this.validator.message('ship_to_contact', this.state.ship_to_contact, 'required') ? 'form-group has-error' : 'form-group'}
                            label="Ship to Contact"
                            name="ship_to_contact"
                            value={this.state.ship_to_contact}
                            onChange={this.onChange}
                          />{this.validator.message('ship_to_contact', this.state.ship_to_contact, 'required') ? <SpanError>{this.validator.message('ship_to_contact', this.state.ship_to_contact, 'required')}</SpanError> : ''}
                          <TextAreaInput
                            dcname={this.validator.message('ship_to_address', this.state.ship_to_address, 'required') ? 'form-group has-error' : 'form-group'}
                            label="Ship to address"
                            name="ship_to_address"
                            value={this.state.ship_to_address}
                            onChange={this.onChange}
                          />{this.validator.message('ship_to_address', this.state.ship_to_address, 'required') ? <SpanError>{this.validator.message('ship_to_address', this.state.ship_to_address, 'required')}</SpanError> : ''}
                        </div>
                        <div className="col-md-6">
                          <TextFormControl
                            dcname={this.validator.message('sold_to_name', this.state.sold_to_name, 'required') ? 'form-group has-error' : 'form-group'}
                            label="Sold to Name"
                            name="sold_to_name"
                            value={this.state.sold_to_name}
                            onChange={this.onChange}
                          />{this.validator.message('sold_to_name', this.state.sold_to_name, 'required') ? <SpanError>{this.validator.message('sold_to_name', this.state.sold_to_name, 'required')}</SpanError> : ''}
                          <TextFormControl
                            dcname={this.validator.message('sold_to_code', this.state.sold_to_code, 'required') ? 'form-group has-error' : 'form-group'}
                            label="Sold to Code"
                            name="sold_to_code"
                            value={this.state.sold_to_code}
                            onChange={this.onChange}
                          />{this.validator.message('sold_to_code', this.state.sold_to_code, 'required') ? <SpanError>{this.validator.message('sold_to_code', this.state.sold_to_code, 'required')}</SpanError> : ''}
                          <TextFormControl
                            dcname={this.validator.message('sold_to_contact', this.state.sold_to_contact, 'required') ? 'form-group has-error' : 'form-group'}
                            label="Sold to contact"
                            name="sold_to_contact"
                            value={this.state.sold_to_contact}
                            onChange={this.onChange}
                          />{this.validator.message('sold_to_contact', this.state.sold_to_contact, 'required') ? <SpanError>{this.validator.message('sold_to_contact', this.state.sold_to_contact, 'required')}</SpanError> : ''}
                          <TextFormControl
                            dcname={this.validator.message('phone_number', this.state.telp_number, 'required') ? 'form-group has-error' : 'form-group'}
                            label="Phone No"
                            name="telp_number"
                            value={this.state.telp_number}
                            onChange={this.onChange}
                          />{this.validator.message('phone_number', this.state.telp_number, 'required') ? <SpanError>{this.validator.message('phone_number', this.state.telp_number, 'required')}</SpanError> : ''}
                          <TextFormControl
                            dcname={this.validator.message('postal_code', this.state.postal_code, 'required') ? 'form-group has-error' : 'form-group'}
                            label="Postal Code"
                            name="postal_code"
                            value={this.state.postal_code}
                            onChange={this.onChange}
                          />{this.validator.message('postal_code', this.state.postal_code, 'required') ? <SpanError>{this.validator.message('postal_code', this.state.postal_code, 'required')}</SpanError> : ''}
                          <TextFormControl
                            dcname={this.validator.message('delivery_date/time', this.state.delivery_date_time, 'required') ? 'form-group has-error' : 'form-group'}
                            type="date"
                            label="Delivery Date/Time"
                            name="delivery_date_time"
                            value={this.state.delivery_date_time}
                            onChange={this.onChange}
                          />{this.validator.message('delivery_date/time', this.state.delivery_date_time, 'required') ? <SpanError>{this.validator.message('delivery_date/time', this.state.delivery_date_time, 'required')}</SpanError> : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">Machine</div>
                  <div className="panel-body">
                    <div className="form form-xs">
                      <div className="row row-xs">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">Machine Model</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">Machine Serial Number</label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">Equipment Number</label>
                          </div>
                        </div>
                      </div>
                      {this.state.machines.length > 0 ? this.state.machines.map(machine => (
                        <Machine
                          key={machine.machine_id}
                          cname="form-control"
                          machine_model={machine.machine_model}
                          machine_serial={machine.machine_serial}
                          equipment_number={machine.equipment_number}
                          onChange={e => this.onChangeMachine(e, machine.machine_id)}
                        />
                      )) : ''}
                      <div className="form-group">
                        <button
                          className="btn btn-default btn-sm btn-add-machine"
                          type="button"
                          onClick={this.addRowMachine}>
                          <i className="fa fa-plus" /> Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-right">
                  <button className="btn btn-warning btn-sm" type="submit">
                    SUBMIT
                  </button>
                </div>
              </div>
              {/* End of */}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Create;
