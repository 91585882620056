import { CALENDAR_SEARCH } from "../actions/types";

const initialState = {
  ce_cal: [],
  working: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CALENDAR_SEARCH:
      return {
        ...state,
        ce_cal: action.ce,
        working: action.working
      };

    default:
      return state;
  }
}
