import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../layout/Navbar";
import Sidebar from "../../layout/Sidebar";
import Footer from "../../layout/Footer";
import TicketInbox from "./TicketInbox";
import TicketInboxPagination from "./TicketInboxPagination";
import TicketHistoryPagination from "./TicketHistoryPagination";
import TicketInboxHelpDesk from "./TicketInboxHelpDesk";
import HistoryTicket from "./HistoryTicket";
import TicketManagementContainer from "./TicketManagement/Container";
import { connect } from "react-redux";

import { saveAssignCE } from "../../../actions/assignCe";

import { setCurrentUser } from "../../../actions/authActions";

class TicketManagement extends Component {
  // componentDidMount() {
  //   this.props.saveAssignCE();
  // }
  reset() {
    // this.props.reset();
    localStorage.removeItem("message");
    localStorage.removeItem("error");
  }
  render() {
    const level = localStorage.getItem("level");
    // const { assign_ce } = this.props.assign;
    // console.log("resSaveAssign");
    // console.log(assign_ce);
    // const { dd } = customer ? customer.error : "";
    // const { rr } = dd === true ? "errrrrrr" : "";
    // console.log("level", level);
    return (
      <TicketManagementContainer
        pagename="Ticket Management"
        pagedetailname="Ticket Management"
        location="ticket-management"
      >
        <div className="panel panel-default">
          {/* <div className="panel-heading">Ticket Management</div> */}
          {/* <div id="app"> */}
          {/* <Navbar icon="fa fa-ticket icon" information="TICKET MANAGEMENT" />
        <Sidebar information="TicketManagement" /> */}
          {/* <div id="main" className="main-xs"> */}
          {/* <br /> */}
          {level === "Help Desk" ? (
            <p />
          ) : (
            <div className="panel-body">
              <Link to="/ticket-management/create-origin-ticket">
                <span className="btn btn-warning btn-sm">
                  CREATE NEW ORIGINATION TICKET
                </span>
              </Link>{" "}
              <Link to="/ticket-management/create-installation-ticket">
                <span className="btn btn-default btn-sm">
                  CREATE NEW INSTALLATION TICKET
                </span>
              </Link>
            </div>
          )}
          {/* <TicketInbox /> */}
          <TicketInboxPagination />
          {/* <HistoryTicket /> */}
          <TicketHistoryPagination />
          {/* <Footer /> */}
          {/* </div> */}
          {/* </div> */}
        </div>
      </TicketManagementContainer>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth.user,
  assign: state.assignCE
});

export default connect(
  mapStateToProps,
  { setCurrentUser, saveAssignCE }
)(TicketManagement);
