import React from "react";
import PropTypes from "prop-types";

const TextInputDisabled = ({ type, label, value, dcname, clabel, iclass }) => {
  return (
    <div className={dcname}>
      <label className={clabel}> {label} </label>
      <input type={type} className={iclass} value={value} disabled />
    </div>
  );
};

TextInputDisabled.propTypes = {
  type: PropTypes.string.isRequired
};

TextInputDisabled.defaultProps = {
  type: "text",
  dcname: "form-group",
  clabel: "control-label",
  iclass: "form-control"
};

export default TextInputDisabled;
