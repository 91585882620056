import React from 'react';

const FlashMessage = props => {
  let head = 'alert alert-warning alert-dismissible';
  switch(props.type){
  case 'success':
    head = 'alert alert-success alert-dismissible';
    break;
  case 'error':
    head = 'alert alert-danger alert-dismissible';
    break;
  case 'warning':
    head= 'alert alert-warning alert-dismissible';
    break;
  default:
    head = 'alert alert-info alert-dismissible';
  }
  window.scrollTo(0, 0)
  return(
    <div className={head} role="alert">
      <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      {props.message}
    </div>
  
  )
}

export default FlashMessage;
