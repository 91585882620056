import React, { Component } from 'react';
import axios from '../../../../axios-env';

import SettingContainer from '../Container';
//import TextInputGroup from "../../../common/TextInputGroup";
import Pagination from '../../../../components/common/Pagination';
import SimpleReactValidator from "simple-react-validator";
import Flash from '../../../../components/common/FlashMessage';

import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class UploadSla extends Component {
  constructor(props){
    super(props);
    this.state = {
      sla: [],
      file: "",
      currentData: [],
      currentPage: null,
      totalPages: null,
      alert_type: '',
      alert_message: ''
      
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount(){
    const headers = { headers:{ Authorization: `Bearer ${localStorage.getItem("jwtToken")}`}};
    axios.get('/api/v1/setting/additional-sla/list', headers).then(res => {
      const meta = res.data.meta; const data = res.data.data;
      if(meta.code === 200){
        if(data.length > 0){
          this.setState({sla: data})
        }
      }else{
        this.setState({...this.state, alert_type: 'error', alert_message: 'Failed to Load Data'});
      }
      
    }).catch(error => {
      this.setState({...this.state, alert_type: 'error', alert_message: 'Failed to Load Data'});
    });
  }

  onPageChanged = data => {
    const { sla } = this.state;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentData= sla.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentData, totalPages });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });
  
  addFiles = e => {
    e.preventDefault();
    
    console.log(e.target.files[0]);
    this.setState({...this.state, file: e.target.files[0]});
  }

  uploadHandler = e => {
    e.preventDefault();
    if (this.state.file !== '') {
      const headers = { headers:{ Authorization: `Bearer ${localStorage.getItem("jwtToken")}`, 'Content-Type': 'multipart/form-data' }};
      let file = this.state.file;
      let formData = new FormData();
      formData.append('file', file);

      axios.post('/api/v1/setting/additional-sla/import', formData, headers).then(res => {
        const meta = res.data.meta; //const data = res.data.data;
        if(meta.code === 200){
          this.setState({...this.state, alert_type: 'success', alert_message: 'Data has been imported'});
          setTimeout(() => {
            this.setState({...this.state, alert_type: 'success', alert_message: 'Data has been imported'});
            window.location.reload();
          }, 2000)
        }else{
          this.setState({...this.state, alert_type: 'error', alert_message: 'Failed to Import Data'});
        }
      }).catch(error => {
        this.setState({...this.state, alert_type: 'error', alert_message: 'Something error'});
      });
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  
  render(){
    // console.log(this.state.sla);
    let recordLength = this.state.sla.length;
    let alert = '';
    if(this.state.alert_type){
      alert = <Flash type={this.state.alert_type} message={this.state.alert_message}/>
    }
    return (
      <div>
        <SettingContainer pagename="Setting" pagedetailname="Upload Additional SLA" location="uploadsla">
          {alert}
          <div className="panel panel-default">
            <div className="panel-heading">Upload Additional SLA</div>
            <div className="panel-body form form-xs">
              <div className="row">
                <div className="col-md-4">
                  <div className="well form form-xs">
                    <div className="alert alert-info text-center">
                      Please upload the data using right excel format
                    </div>
                    <div className="form-group">
                      <label>Download</label>
                      <div>
                        { this.state.sla !== '' ? 
                          <ExcelFile element={<button className="btn btn-danger">Download</button>} filename="Additional SLA">
                            <ExcelSheet data={this.state.sla} name="Part">
                              <ExcelColumn label="serial_number" value="serial_number"/>
                              {/* <ExcelColumn label="code" value="code"/> */}
                              <ExcelColumn label="sla_description" value="sla_description"/>
                            </ExcelSheet>
                          </ExcelFile>
                          : <button className="btn btn-danger">Download</button>
                        }
                        {/* <button className="btn btn-danger">Download</button> */}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Upload</label>
                      <input type="file" name="sla_file" onChange={this.addFiles}/>
                    </div>
                    <span className="help-block form-error" style={{color: "#8B0000"}}>{this.validator.message('upload', this.state.file, 'required')}</span>
                    {/* <div className="form-group"> */}
                    {/*   <label>Test</label> */}
                    {/*   <input type="text" className="form-control" name="test" onChange={this.onChange}/> */}
                    {/*   <p style={{ color: "red" }}>{this.validator.message('test', this.state.test, 'required|email')}</p> */}
                    {/* </div> */}
                    <div className="form-group">
                      <button className="btn btn-primary" onClick={this.uploadHandler}>Upload</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <table className="table table-bordered table-xs">
                    <thead>
                      <tr>
                        <th>Serial Number</th>
                        <th>Equipment Number</th>
                        <th>Additional SLA</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.sla.length <= 0
                       ?<tr><td colSpan="3" className="text-center">No Data Available in This Table</td></tr>
                       :
                       this.state.currentData.map(sla => (
                         <tr key={sla.serial_number}>
                           <td>{sla.serial_number}</td>
                           <td>no data*</td>
                           <td>{sla.sla_description}</td>
                         </tr>
                       ))}
                    </tbody>
                  </table>
                  <div className="panel-footer form-xs">
                    {recordLength !== 0 ?
                     <Pagination
                       totalRecords={recordLength}
                       pageLimit={10}
                       pageNeighbours={1}
                       onPageChanged={this.onPageChanged}
                     />
                     : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SettingContainer>
      </div>
    )
  }
}

export default UploadSla;
