import React, { Component } from "react";
import { connect } from "react-redux";
import axios from '../../../../axios-env'
import '../../../../App.css';
import './Left.css';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import TextInputGroup from "../../../common/TextInputGroup";
//import TextFormControl from "../../../common/TextFormControl";
import TextInputDisabled from "../../../common/TextInputDisabled";
import TextAreaInput from "../../../common/TextAreaInput";
import SearchNameModal from "./searchNameModal";
import SearchMachineModal from "./searchMachineModal";
import SpanError from "../../../common/SpanError";

import { setCurrentUser } from "../../../../actions/authActions";
import { getOriginationCustomer } from "../../../../actions/originationActions";
import { deleteOriginationCustomer } from "../../../../actions/originationActions";
import SimpleReactValidator from "simple-react-validator";

const initialState = {
  users: {
    id: null,
    username: null,
    level: null
  },
  date_time_created: moment().format('YYYY-MM-DD HH:mm'),  
  serial_no: "",
  equipment_no: "",
  notification_no: "",
  customer_company_name: "",
  
  customer_id: "",
  machine_customer_id: "",
  customer_name: "",

  displaySearchModal: false,
  displayMachineModal: false,
  isMultipleMachine: false,
  list_name: [],
  selected_name: "",
  selected_customer_name: "",
  list_machine: [],
  selected_machine: "",

  is_new_contact: 1,
  date_of_contact: new Date(),
  gender: 'Mr',
  contact_name: "",
  channel_of_contact: '',
  type_channel_of_contact: 'Telephone',
  contact_history: [],
  // State for Customer Information
  
  model_name: "",
  customer_info: "",
  customer_category: "",
  model: "",

  ship_to_name: "",
  ship_to_code:  "",
  ship_to_contact: "",

  sold_to_name: "",
  sold_to_code: "",
  sold_to_contact: "",

  csrc_status: "",
  csrc_last_connection: "",

  ship_to_address: "",
  location_of_equipment: "",
  phone_number: "",
  postal_code: "",
  delivery_date:  "",

  sla_response_time: "",
  sla_additional_details: "",

  blocked_status: "",
  blocked_by_department: "",
  blocked_by_user_name: "",
  blocked_by_user_id: "",

  contract_no: "",
  contract_start_date: "",
  contract_end_date: "",
  contract_user_id: "",

  machine_extend_start: "",
  machind_extend_end: "",

  Sales_Person_Code: "",
  Sales_Person_name: "",
  dsm_code: "",
  dsm_name:  "",

  //ALert
  isShowingInfoAlert: false,
  isShowingSuccessAlert: false,
  isShowingWarningAlert: false,
  isShowingDangerAlert: false
};

class LeftOriginationTicket extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleChange = this.handleChange.bind(this);
    this.validator = new SimpleReactValidator({autoForceUpdate: this});
  }

  clickSearchSerialHandler = (e) => {
    e.preventDefault()
    this.searchSerialHandler();
  }
  
  searchSerialHandler = e => {
    this.props.custMachineId("", "");
    if (this.validator.fieldValid('serial_no')) {
      //this.setState({displayMachineModal: false, equipment_no: ""});
      this.setState({...initialState, serial_no: this.state.serial_no});
      axios.get('/api/v1/customer-by-serial/'+ this.state.serial_no,{
        headers: { Authorization: 'Bearer ' + localStorage.jwtToken }
      }).then(res => {
        if(res.data.meta.code == '200'){
          const re = res.data.data;
          this.props.custMachineId(re.id, re.customers.id);
          this.setState({
            customer_id: re.customers.id ? +re.customers.id : "",
            machine_customer_id: re.id ? +re.id : "",
            contact_history: re.contact ? re.contact : "",
            
            model_name: re.machine.machine_models.model_name ? re.machine.machine_models.model_name : "",
            customer_info: re.customers.CustomerName ? re.customers.CustomerName : "",
            customer_category: re.customers.customer_category ? re.customers.customer_category : "",
            model:  re.technician.code ? re.technician.code : "",

            ship_to_name: re.ship_to_name ? re.ship_to_name : "-",
            ship_to_code: re.ship_to_code ? re.ship_to_code : "-",
            ship_to_contact: re.ship_to_contact_name ? re.ship_to_contact_name : "-",

            csrc_status: re.customers.csrc_status ? re.customers.csrc_status : "",
            csrc_last_connection: "",

            ship_to_address: re.customers.ship_to_address ? re.customers.ship_to_address : "",
            location_of_equipment: "",
            phone_number: "",
            postal_code: re.customers.ship_to_postcode ? re.customers.ship_to_postcode : "",
            delivery_date: re.delivery_date ? re.delivery_date : "",

            blocked_status: re.customers.blocked_status ? re.customers.blocked_status : "-",
            blocked_by_department: re.customers.blocked_by.blocked_by_department ? re.customers.blocked_by.blocked_by_department : "-",
            blocked_by_user_name: re.customers.blocked_by.blocked_by_user_name ? re.customers.blocked_by.blocked_by_user_name : "-",
            blocked_by_user_id: re.customers.blocked_by.blocked_by_user_id ? re.customers.blocked_by.blocked_by_user_id : "-",

            contract_no: re.contracts.contract_number ? re.contracts.contract_number : "-",
            contract_start_date: "",
            contract_end_date: re.contracts.contract_end_date ? re.contracts.contract_end_date : "-",
            contract_user_id: "",

            machine_extend_start: "-",
            machind_extend_end: "-",

            Sales_Person_Code: re.customers.sales.Sales_Person_Code ? re.customers.sales.Sales_Person_Code : "-",
            Sales_Person_name: re.customers.sales.Sales_Person_name ? re.customers.sales.Sales_Person_name : "-",
            dsm_code: re.customers.sales.dsm_code ? re.customers.sales.dsm_code : "-",
            dsm_name: re.customers.sales.dsm_name ? re.customers.sales.dsm_name : "-",
          });
        }else{
          this.props.setError('error', 'Data Not Found');
        }
      }).catch(error => {
        this.props.setError('error', 'Data Not Found');
      });
    }else{
      this.validator.showMessageFor('serial_no');
      this.validator.hideMessageFor('equipment_no');
      this.validator.hideMessageFor('customer_name');
      this.forceUpdate();
    }
  }

  searchEquipmentHandler = e => {
    this.props.custMachineId("", ""); //reset machine_customer_id & customer_id
    if (this.validator.fieldValid('equipment_no')) {
      //this.setState({displayMachineModal: false, serial_no: ""});
      this.setState({...initialState, equipment_no: this.state.equipment_no});
      axios.get('/api/v1/customer-by-equipment-number/'+ this.state.equipment_no,{
        headers: { Authorization: 'Bearer ' + localStorage.jwtToken }
      }).then(res => {
        if(res.data.meta.code == '200'){
          const re = res.data.data;
          this.props.custMachineId(re.id, re.customers.id);
          this.setState({
            customer_id: re.customers.id ? +re.customers.id : "",
            customer_name: re.customers.CustomerName ? re.customers.CustomerName : "",
            serial_no: re.machine.machine_serial ? re.machine.machine_serial : "",
            machine_customer_id: re.id ? +re.id : "",
            contact_history: re.contact ? re.contact : "",
            
            model_name: re.machine.machine_models.model_name ? re.machine.machine_models.model_name : "-",
            customer_info: re.customers.CustomerName ? re.customers.CustomerName : "-",
            customer_category: re.customers.customer_category ? re.customers.customer_category : "-",
            model:  re.technician.code ? re.technician.code : "-",

            ship_to_name: re.ship_to_name ? re.ship_to_name : "-",
            ship_to_code: re.ship_to_code ? re.ship_to_code : "-",
            ship_to_contact: re.ship_to_contact_name ? re.ship_to_contact_name : "-",

            csrc_status: re.customers.csrc_status ? re.customers.csrc_status : "-",
            csrc_last_connection: "",

            ship_to_address: re.ship_to_address ? re.ship_to_address : "-",
            location_of_equipment: re.location ? re.location : "-",
            phone_number: re.ship_to_contact_phone ? re.ship_to_contact_phone : "-",
            postal_code: re.customers.ship_to_postcode ? re.customers.ship_to_postcode : "-",
            delivery_date: re.delivery_date ? re.delivery_date : "-",

            blocked_status: re.customers.blocked_status ? re.customers.blocked_status : "-",
            blocked_by_department: re.customers.blocked_by.blocked_by_department ? re.customers.blocked_by.blocked_by_department : "-",
            blocked_by_user_name: re.customers.blocked_by.blocked_by_user_name ? re.customers.blocked_by.blocked_by_user_name : "-",
            blocked_by_user_id: re.customers.blocked_by.blocked_by_user_id ? re.customers.blocked_by.blocked_by_user_id : "-",

            contract_no: re.contracts.contract_number ? re.contracts.contract_number : "-",
            contract_start_date: "",
            contract_end_date: re.contracts.contract_end_date ? re.contracts.contract_end_date : "-",
            contract_user_id: "",

            Sales_Person_Code: re.customers.sales.Sales_Person_Code ? re.customers.sales.Sales_Person_Code : "-",
            Sales_Person_name: re.customers.sales.Sales_Person_name ? re.customers.sales.Sales_Person_name : "-",
            dsm_code: re.customers.sales.dsm_code ? re.customers.sales.dsm_code : "-",
            dsm_name: re.customers.sales.dsm_name ? re.customers.sales.dsm_name : "-",
          });
        }else{
          this.props.setError('error', 'Data Not Found');
        }
      }).catch(error => {
        this.props.setError('error', 'Data Not Found');
      });
    }else{
      this.validator.showMessageFor('equipment_no');
      this.validator.hideMessageFor('customer_name');
      this.validator.hideMessageFor('serial_no');
      this.forceUpdate();
    }
  }

  clickSearchEquipmentHandler = (e) => {
    e.preventDefault()
    this.searchEquipmentHandler();
  }

  handleClickSerialNo = e => {
    e.preventDefault();
    const { serial_no, equipment_no, notification_no } = this.state;

    const searchKey = {
      serial_no,
      equipment_no,
      notification_no
    };

    this.props.getOriginationCustomer(searchKey);
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });
  
  onChangeCN = e => {
    this.setState({ [e.target.name]: e.target.value, isMultipleMachine: false });
  }

  // onChangeContactDetail = e => {
  //   this.setState({ [e.target.name]: e.target.value })
  //   this.props.changeContactDetail(e.target.name, e.target.value)
  // };

  searchByNameHandler = e => {
    e.preventDefault();
    this.props.custMachineId("", "");
    //this.setState({isMultipleMachine: false});
    this.setState({...initialState, customer_name: this.state.customer_name});
    if (this.validator.fieldValid('customer_name')) {
      axios.get('/api/v1/customer-by-name/'+ this.state.customer_name,{
        headers: {
          Authorization: 'Bearer ' + localStorage.jwtToken
        }
      }).then(res => {
        if(res.data.meta.code === 200){
          const resName = res.data.data;
          const newRes = resName
                .map(e => e['CustomerCode'])
                .map((e, i, final) => final.indexOf(e) === i && i)
                .filter(e => resName[e]).map(e => resName[e]);
          this.setState({displaySearchModal: true, list_name: newRes});
        }else{
          this.props.setError('error', 'Data Not Found');
        }
      }).catch(error => {
        this.props.setError('error', 'Data Not Found');
      })
    }else{
      this.validator.showMessageFor('customer_name');
      this.validator.hideMessageFor('equipment_no');
      this.validator.hideMessageFor('serial_no');
      this.forceUpdate();
    }
   
  }

  closeSearchNameModal = () => {
    this.setState({displaySearchModal: false, selected_name: "", serial_no: ""});
  }

  // changeSelectName = e => {
  //   e.preventDefault();
  //   console.log('Change Select Name');
  //   console.log(e.target);
  //   const updState = {
  //     ...this.state,
  //     selected_name: e.target.value
  //   }
  //   this.setState(updState);
  // }

  changeSelectName = (e, id, name) => {
    e.preventDefault();
    const updState = {
      ...this.state,
      selected_name: id,
      selected_customer_name: name,
    }
    this.setState(updState);
  }

  onSelectName = e => {
    e.preventDefault();
    this.setState({customer_name : this.state.selected_customer_name});

    const listName = this.state.list_name;
    let company = "";let count=0;

    listName.forEach(ln => {
      if(ln.CustomerCode === this.state.selected_name && count===0){
        company = ln;
        count++;
      }
    });
    
    if(this.state.selected_name){
      axios.get('/api/v1/customer-by-code/'+ this.state.selected_name,{
        headers: {
          Authorization: 'Bearer ' + localStorage.jwtToken
        }
      }).then(res => {
        if(res.data.meta.code === 200){
          if(res.data.data.length > 1){
            this.setState({displaySearchModal: false, isMultipleMachine: true, list_machine: res.data.data, selected_company: company});
          }else if(res.data.data.length === 1){
            let mach = '';
            res.data.data.map(rd => {mach = rd.MachineCode});
            this.setState({equipment_no: mach, displaySearchModal: false,});
            this.searchEquipmentHandler();
          }else{
            this.props.setError('error', 'Customer Machine Data Not Found');
          }
           //this.setState({displaySearchModal: false, isMultipleMachine: true, list_machine: res.data.data, selected_company: company});
        }else{
          this.props.setError('error', 'Data Not Found');
        }
      }).catch(error => {
        this.props.setError('error', 'Data Not Found');
      })
    }
  }
  
  searchMultiHandler = e => {
    e.preventDefault();
    this.setState({displayMachineModal: true});
  }

  closeMultiMachineModal = () => {
    this.setState({displayMachineModal: false, selected_name: "", serial_no: ""});
  }

  changeSelectMachine = (e, id) => {
    e.preventDefault();
    const updState = {
      ...this.state,
      equipment_no: id
    }
    this.setState(updState);
  }


  onAlertToggle(type) {
    this.setState({
      [type]: !this.state[type]
    });
  }

  dismissAlert(){
  }

  handleChange(date) {
    this.setState({
      date_of_contact: date
    });
  }
  

  render() {
    this.validator.purgeFields();
    const trClass = this.state.selected_name ? this.state.selected_name : '';
    const trMachineClass = this.state.equipment_no;

    let searchName = '';
    if(this.state.displaySearchModal){
     searchName = <SearchNameModal
                    listuser={this.state.list_name} onclick={this.changeSelectName} trclass={trClass} modalclass="modal display-block" /* modalid="search-name-modal"  */
                     select={this.changeSelectName} onselect={this.onSelectName} onclose={this.closeSearchNameModal}/>
    }

    let searchMachine = '';
    if(this.state.displayMachineModal){
      searchName = <SearchMachineModal
                     listmachine={this.state.list_machine} select={this.changeSelectMachine} trclass={this.state.equipment_no} modalclass="modal display-block" /* modalid="search-multi-name-modal" */
                     onselect={this.searchEquipmentHandler} onclose={this.closeMultiMachineModal}
                     company={this.state.selected_company}/>
    }

    let multiButton = <button className="btn btn-default" disabled>Multi</button>;
    if(this.state.isMultipleMachine){
      multiButton = <button className="btn btn-default" onClick={this.searchMultiHandler} style={{color:"red"}} /* data-toggle="modal" data-target="#search-multi-name-modal" */>Multi</button>
    }

    let sByNameButton = <button className="btn btn-default" onClick={this.searchByNameHandler}><i className="fa fa-search" /></button>;
    // if(this.state.customer_name != ""){
    //   sByNameButton = <button className="btn btn-default" onClick={this.searchByNameHandler}  data-toggle="modal" data-target="#search-name-modal"><i className="fa fa-search" /></button>;
    // }
    return (
      <div>
        {searchName}
        <div className="panel panel-default">
          <div className="panel-heading">Customer Information</div>
          <div className="panel-body">
            <div className="form form-xs">
              <div className="row row-xs">
                <div className="col-md-6">
                  <fieldset className="search-fieldset">
                    <legend>Search By</legend>
                    {/* <TextInputGroup */}
                    {/*   dcname={this.validator.message('serial_no', this.state.serial_no, 'required') ? 'form-group has-error' : 'form-group'} */}
                    {/*   label="Serial No" */}
                    {/*   name="serial_no" */}
                    {/*   value={this.state.serial_no} */}
                    {/*   onChange={this.onChange} */}
                    {/*   onClick={this.clickSearchSerialHandler} */}
                    {/* /> */}
                    {/* this.validator.message('serial_no', this.state.serial_no, 'required') ? */
                     /* <SpanError>{this.validator.message('serial_no', this.state.serial_no, 'required')}</SpanError> : '' */}
                    <TextInputGroup
                      dcname={this.validator.message('equipment_no', this.state.equipment_no, 'required') ? 'form-group has-error' : 'form-group'}
                      label="Equipment No"
                      name="equipment_no"
                      value={this.state.equipment_no}
                      onChange={this.onChange}
                      onClick={this.clickSearchEquipmentHandler}
                    />
                    {this.validator.message('equipment_no', this.state.equipment_no, 'required') ?
                     <SpanError>{this.validator.message('equipment_no', this.state.equipment_no, 'required')}</SpanError> : ''}
                    <div className={this.validator.message('customer_name', this.state.customer_name, 'required') ? 'form-group has-error' : 'form-group'}>
                      <label className="control-label">Customer Name</label>
                      <div className="input-group">
                        <input type="text" name="customer_name" className="form-control" value={this.state.customer_name} onChange={this.onChangeCN} 
                               onKeyDown={ e => (e.key === 'Enter' ? this.searchByNameHandler(e) : null)}/>
                        
                        <span className="input-group-btn">
                          {sByNameButton}
                          {multiButton}
                        </span>
                      </div>
                    </div>
                    {this.validator.message('customer_name', this.state.customer_name, 'required') ?
                     <SpanError>{this.validator.message('customer_name', this.state.customer_name, 'required')}</SpanError> : ''}
                    {/* <span style={{color: "#A94442", marginTop: "-30px" }}>{this.validator.message('customer_name', this.state.customer_name, 'required')}</span> */}
                  </fieldset>
                  <TextInputDisabled label="Model" value={this.state.model} />
                  <TextInputDisabled
                    label="Customer Category"
                    value={this.state.customer_category}
                  />
                  <TextInputDisabled
                    label="Prefered CE"
                    value={this.state.model}
                  />
                  <fieldset>
                    <legend>Ship To</legend>
                    <TextInputDisabled
                      label="Ship to Name"
                      value={this.state.ship_to_name}
                    />
                    <TextInputDisabled
                      label="Ship to Code"
                      value={this.state.ship_to_code}
                    />
                    <TextInputDisabled
                      label="Ship to Contact"
                      value={this.state.ship_to_contact}
                    />
                  </fieldset>
                  <fieldset>
                    <legend>CSRC</legend>
                    <TextInputDisabled label="CSRC Status" value={this.state.csrc_status}/>
                    <TextInputDisabled label="Last Day of Connection" value={this.state.csrc_last_connection}/>
                  </fieldset>
                </div>
                <div className="col-md-6">
                  <fieldset>
                    <legend>Block By</legend>
                    <TextInputDisabled label="Status" value={this.state.blocked_status} />
                    <TextInputDisabled label="Department" value={this.state.blocked_by_department}/>
                    <TextInputDisabled label="Name" value={this.state.blocked_by_user_name}/>
                    <TextInputDisabled label="User ID" value={this.state.blocked_by_user_name}/>
                  </fieldset>
                  <fieldset>
                    <legend>Contract Detail</legend>
                    <TextInputDisabled label="Contract No" value={this.state.contract_no}/>
                    <TextInputDisabled label="End Date" value={this.state.contract_end_date ? moment(this.state.contract_end_date).format('L') : ''}/>
                  </fieldset>
                  <fieldset>
                    <legend>Sales Detail</legend>
                    <TextInputDisabled label="Sales Person Code" value={this.state.Sales_Person_Code}/>
                    <TextInputDisabled label="Sales Person Name" value={this.state.Sales_Person_name}/>
                    <TextInputDisabled label="DSM Code" value={this.state.dsm_code}/>
                    <TextInputDisabled label="DSM Name" value={this.state.dsm_name}/>
                  </fieldset>
                </div>
              </div>
              <fieldset>
                <legend>Customer Details</legend>
                <TextAreaInput
                  label="Ship to Address"
                  value={this.state.ship_to_address}
                  disabled="disabled"
                />
                <TextAreaInput
                  label="Location of Equipment No"
                  value={this.state.location_of_equipment}
                  disabled="disabled"
                />
                <div className="row">
                  <div className="col-md-6">
                    <TextInputDisabled label="Telephone No" value={this.state.phone_number} disabled="disabled"/>
                  </div>
                  <div className="col-md-6">
                    <TextInputDisabled label="Postal Code" value={this.state.postal_code} />
                    <TextInputDisabled label="Delivery Date" value={this.state.delivery_date ? moment(this.state.delivery_date).format('L') : ''} disabled="disabled"/>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>SLA Details</legend>
                <TextInputDisabled
                  label="SLA Response Time"
                  value={this.state.sla_response_time}
                />
                <TextInputDisabled
                  label="Additional SLA Details"
                  value={this.state.sla_additional_details}
                />
              </fieldset>
            </div>
          </div>
        </div>
      
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth.user,
  customerInfo: state.customerOrigin.customer.data ? state.customerOrigin.customer.data : ''
});

export default connect(
  mapStateToProps,
  { setCurrentUser, getOriginationCustomer, deleteOriginationCustomer }
)(LeftOriginationTicket);
