import React from 'react';

const Quota = props =>  {
  return (
    <React.Fragment>
      <div className="panel panel-default">
        <div className="panel-heading">Ticket Number : </div>
        <div className="panel-body">
          <div className="form form-xs">
            <div className="row row-xs">
              <div className="form-group">
                <label>File Name</label>
              </div>
              <div className="form-group">
                <label>Image</label>
              </div>
              <div className="form-group">
                <button className="btn btn-primary btn-sm">Download Quotation</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Quota;
