import React, { Component } from "react";
// import logo from "../../img/logo-cats.png";
import logo from "../../img/br-small.png";
import PropTypes from "prop-types";

import { Link, Route } from "react-router-dom";
import { logoutUser, setCurrentUser } from "../../actions/authActions";
import { connect } from "react-redux";

const routes = [
  {
    path: "/",
    exact: true,
    sidebar: () => <div>home!</div>,
    main: () => <h2>Home</h2>
  },
  {
    path: "/bubblegum",
    sidebar: () => <div>bubblegum!</div>,
    main: () => <h2>Bubblegum</h2>
  },
  {
    path: "/shoelaces",
    sidebar: () => <div>shoelaces!</div>,
    main: () => <h2>Shoelaces</h2>
  }
];

class Sidebar extends Component {
  onLogoutClick(e) {
    e.preventDefault();
    const jwtToken = localStorage.getItem("jwtToken");
    this.props.logoutUser(jwtToken);
    // localStorage.removeItem("jwtToken");
    // localStorage.removeItem("username");
    // localStorage.removeItem("level");
    // store.dispatch(RESET_ACTION);
    if (this.props.auth.isAuthenticated === "false") {
      this.props.history.push("/");
    }
  }
  render() {
    // const { location } = this.props;
    // const dashboardClass = location.pathname === "/dashboard" ? "active" : "";
    // const customerInformationClass = location.pathname.match(/^\/customer-information/) ? "active" : "";
    // const ticketManagementClass = location.pathname.match(/^\/ticket-management/) ? "active" : "";

    // function OldSchoolMenuLink({ label, to, activeOnlyWhenExact }) {
    //   return (
    //     <Route
    //       path={to}
    //       exact={activeOnlyWhenExact}
    //       children={({ match }) => (
    //         <div className={match ? "active" : ""}>

    // function OldSchoolMenuLink({ label, to, activeOnlyWhenExact }) {
    //   return (
    //     <Route
    //       path={to}
    //       exact={activeOnlyWhenExact}
    //       children={({ match }) => (
    //         <div className={match ? "active" : ""}>

    //           <Link to={to}>{label}</Link>
    //         </div>
    //       )}
    //     />
    //   );
    // }
    // const level = localStorage.getItem("level");
    // let confirm;
    // if (
    //   level === "CE"
    // level !== "Help Center" &&
    // level !== "Control Center" &&
    // level !== "Spare Parts"
    // ) {
    //   confirm = (
    //     <div className="modal display-block">
    //       <div className="modal-dialog modal-sm">
    //         <div className="modal-content">
    //           <div
    //             className="modal-body text-center text-warning bg-warning"
    //             style={{ padding: "30px" }}
    //           >
    //             <i
    //               className="fa fa-exclamation-triangle fa-5x"
    //               onClick={this.onLogoutClick.bind(this)}
    //             />
    //             <br />
    //             <br />
    //             <p>Sorry, You cannot access this page!</p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }
    const { icon, information } = this.props;
    const das = information === "DASHBOARD" ? "active" : "";
    const cus = information === "CustomerInformation" ? "active" : "";
    const tic = information === "TicketManagement" ? "active" : "";
    const loc = information === "Location" ? "active" : "";
    const set = information === "Setting" ? "active" : "";
    const mes = information === "Messaging" ? "active" : "";
    const rep = information === "Reports" ? "active" : "";
    const unas = information === "Unassign" ? "active" : "";

    return (
      <div id="sidebar">
        <div className="widget-logo">
          <img src={logo} alt="" />
        </div>
        <div className="widget widget-nav">
          {/* {confirm} */}
          <ul className="nav">
            <li className={das}>
              {" "}
              {/* className="active" */}
              <Link to="/dashboard">
                <i className="fa fa-pie-chart icon" />
                <span>Dashboard</span>
              </Link>
            </li>
            <li className={cus}>
              <Link to="/customer-information">
                <i className="fa fa-group icon" />
                <span>Customer Information</span>
              </Link>
            </li>
            <li className={tic}>
              <Link to="/ticket-management">
                <i className="fa fa-ticket icon" />
                <span>Ticket Management</span>
              </Link>
              {/* { window.location.reload()} */}
            </li>

            <li className={unas}>
              <Link to="/unassign">
                <i className="fa fa-random icon" />
                <span>Unassign</span>
              </Link>
            </li>

            <li className={mes}>
              <Link to="/messaging">
                <i className="fa fa-comments icon" />
                <span>Messaging</span>
              </Link>
            </li>
            <li className={loc}>
              <Link to="/location">
                <i className="fa fa-map-marker icon" />
                <span>Location</span>
              </Link>
            </li>
            <li className={rep}>
              <Link to="/reports">
                <i className="fa fa-print icon" />
                <span>Reports</span>
              </Link>
            </li>
            <li className={set}>
              <Link to="/setting/home">
                <i className="fa fa-cogs icon" />
                <span>Setting</span>
              </Link>
            </li>
            <li>
              {/* <Link to="/setting/home"> */}
              <a
                href="/"
                onClick={this.onLogoutClick.bind(this)}
                className="logo-konica"
              >
                <i className="fa fa-power-off icon" />
                <span>Exit</span>
              </a>
              {/* </Link> */}
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

// export default Sidebar;

Sidebar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth.user
});
export default connect(
  mapStateToProps,
  { logoutUser, setCurrentUser }
)(Sidebar);
