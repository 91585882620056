import React, { Component } from "react";
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";
import axios from "axios";

class Messaging extends React.Component {
state = {
    items: [],
  }



componentDidMount() {
    fetch("http://localhost/api/what.php")
        .then(res => res.json())
        .then(parsedJSON => parsedJSON.results.map(data => (
          {
            id: `${data.id}`,
            kecamatan: `${data.kecamatan}`
          }
        )))
        .then(items => this.setState({
          items,
          isLoaded: false
        }))
        .catch(error => console.log('parsing failed', error))
  }
  render() {
const {items } = this.state;
    return (
      <div id="app">
        <Navbar icon="fa fa-comments icon" information="Messaging" />
        <Sidebar information="Messaging"/>
        <div id="main" className="main-xs">
        <div className="row row-xs">
            <div className="col-md-6">
            <div className="panel panel-default">
            <div className="panel-heading">Messaging Header</div>
                  <div className="panel-body">

                    <div className="table-responsive">
                            <table className="table table-bordered table-xs">
                              <thead>
                                <tr>
                                  <th>ID</th>
                                  <th>Kecamatan</th>
                                  </tr>
                              </thead>
                              <tbody>
                                
                                    {
              items.length > 0 ? items.map(item => {
              const {id, kecamatan} = item;
               return (
                                    <tr><td>{id}</td><td>{kecamatan}</td> </tr>
                                    );
            }) : null
          }
                               
                              </tbody>
                            </table>
                      </div>

        </div>
        </div>
        </div>
        </div>
        </div>
      </div>
    );
  }
}

export default Messaging;
