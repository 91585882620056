import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "../../../../axios-env";
import OnSearchSLA from "./OnSearch/OnSearchSLA";
import "../../../../App.css";

import SettingContainer from "../Container";
import { creataAdditionalSla } from "../../../../actions/slaAdditionalActions";

class Sla extends Component {
  state = {
    sla_by_customer: [],
    sla_by_serial: [],
    sla_by_contract: [],
    machine: {},
    machines: [],
    customer_name: "",
    columnCustomer: "",
    serialCheck: "",
    value: "",
    isCheckedSerial: true,
    checkedItems: new Map()
  };

  handleChange = e => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked)
    }));
    console.log("isi dari e", e);
    if (isChecked == true) {
      this.setState(prevState => ({
        ...prevState,
        machines: [...prevState.machines, { serial_number: item }]
      }));
    } else {
      const machines = this.state.machines.filter(
        machine => machine.serial_number !== e.target.name
      );
      console.log("isis machines ", machines);

      this.setState({ machines });
    }
  };

  onSearchCustomer = term => {
    axios
      .get(
        `/api/v1/setting/additional-sla/customer-name/search?customer_name=${term}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.jwtToken
          }
        }
      )
      .then(res => {
        this.setState({
          sla_by_customer: res.data.data,
          columnCustomer: "customer"
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  searchSerialHandler = term => {
    axios
      .get(
        `/api/v1/setting/additional-sla/machine-serial/search?machine_serial=${term}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.jwtToken
          }
        }
      )
      .then(res => {
        this.setState({
          sla_by_serial: res.data.data,
          columnCustomer: "serial"
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  searchContractHandler = term => {
    axios
      .get(
        `/api/v1/setting/additional-sla/contract-no/search?contract_no=${term}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.jwtToken
          }
        }
      )
      .then(res => {
        this.setState({
          sla_by_contract: res.data.data,
          columnCustomer: "contract"
        });
        console.log("data dari contract searching ", res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleChangeTextarea = event => {
    this.setState({ value: event.target.value });
  };

  onSave = () => {
    const createManualSla = {
      machines: this.state.machines,
      sla_description: this.state.value,
      jwtToken: localStorage.getItem("jwtToken")
    };

    this.props.creataAdditionalSla(createManualSla);
  };

  render() {
    const { sla_by_customer, sla_by_serial, sla_by_contract } = this.state;
    let columnCustomerSla;

    console.log("length of sla ", sla_by_customer.length);

    if (
      this.state.columnCustomer == "customer" &&
      sla_by_customer.length >= 1
    ) {
      columnCustomerSla = sla_by_customer.map(data => {
        return (
          <tr key={data.id}>
            <td width="10">
              <input
                name={data.machine.machine_serial}
                type="checkbox"
                checked={this.state.checkedItems.get(
                  data.machine.machine_serial
                )}
                // onChange={this.onCheckByCustomer.bind(this, data)}
                onClick={this.handleChange}
              />
            </td>
            <td> {data.customers.CustomerName} </td>
            <td> {data.machine.machine_models.model_name} </td>
            <td> {data.machine.machine_serial} </td>
            <td />
          </tr>
        );
      });
    }

    if (this.state.columnCustomer == "customer" && sla_by_customer.length < 1) {
      columnCustomerSla = (
        <tr width="10px">
          <td />
          <td>Data not found</td>
          <td />
          <td />
          <td />
        </tr>
      );
    }

    if (this.state.columnCustomer == "serial") {
      columnCustomerSla = sla_by_serial.map(data => {
        return (
          <tr key={data.id}>
            <td width="10">
              <input
                name={data.machine.machine_serial}
                type="checkbox"
                checked={this.state.checkedItems.get(
                  data.machine.machine_serial
                )}
                onClick={this.handleChange}
              />
            </td>
            <td> {data.customers.CustomerName} </td>
            <td> {data.machine.machine_models.model_name} </td>
            <td>{data.machine.machine_serial}</td>
            <td />
          </tr>
        );
      });
    }

    if (this.state.columnCustomer == "contract") {
      columnCustomerSla = sla_by_contract.map(data => {
        return (
          <tr key={data.id}>
            <td width="10">
              <input
                name={data.machine.machine_serial}
                type="checkbox"
                checked={this.state.checkedItems.get(
                  data.machine.machine_serial
                )}
                onClick={this.handleChange}
              />
            </td>
            <td> {data.customers.CustomerName} </td>
            <td> {data.machine.machine_models.model_name} </td>
            <td>{data.machine.machine_serial}</td>
            <td />
          </tr>
        );
      });
    }

    return (
      <div>
        <SettingContainer
          pagename="Setting"
          pagedetailname="Additional SLA"
          location="addsla"
        >
          <div className="panel panel-default">
            <div className="panel-heading">Manual Additional SLA</div>
            <div className="panel-body form form-xs">
              <div className="row row-xs">
                <div className="col-md-4">
                  <div className="well">
                    <OnSearchSLA
                      name="customer"
                      onSubmit={this.onSearchCustomer}
                      label_name="Customer Name"
                    />

                    <OnSearchSLA
                      onSubmit={this.searchSerialHandler}
                      label_name="Machine Serial Number"
                    />

                    <OnSearchSLA
                      onSubmit={this.searchContractHandler}
                      label_name="Contract Number"
                    />

                    <div className="form-group">
                      <button className="btn btn-warning">SUBMIT</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="p-10 form form-xs">
                    <div className="pull-right">
                      <a
                        className="btn btn-default"
                        data-toggle="modal"
                        data-target="#modal-add"
                      >
                        <i className="fa fa-plus" /> Add New
                      </a>
                      <a href="#" className="btn btn-default">
                        <i className="fa fa-edit" /> Edit
                      </a>
                      <a href="#" className="btn btn-danger">
                        <i className="fa fa-trash-o" /> Delete
                      </a>
                    </div>
                    <div className="clearfix" />
                  </div>
                  <table className="table table-bordered table-xs">
                    <tbody>
                      <tr>
                        <th width="20px" />
                        <th>Customer Name </th>
                        <th>Model No</th>
                        <th>Serial No</th>
                        <th>View</th>
                      </tr>
                      {columnCustomerSla}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </SettingContainer>

        <div className="modal fade" id="modal-add">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
                <h4 className="modal-title">Add Additional SLA</h4>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <textarea
                    value={this.state.value}
                    onChange={this.handleChangeTextarea}
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={this.onSave}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { creataAdditionalSla }
)(Sla);
