import { ADD_INSTALLATION_TICKET } from "../actions/types";
const initialState = {
  installation: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_INSTALLATION_TICKET:
      return {
        ...state,
        installation: action.payload
      };
    default:
      return state;
  }
}
