import React, { Component } from 'react';
import axios from '../../../../axios-env';
import SimpleReactValidator from 'simple-react-validator';

import SettingContainer from '../Container';
import Pagination from '../../../../components/common/Pagination';
import Flash from '../../../../components/common/FlashMessage';

import { NotificationContainer, NotificationManager } from "react-notifications";
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class Sales extends Component{
  constructor(props){
    super(props);
    this.state = {
      salesPerson: [],
      file: "",
      currentData: [],
      currentPage: null,
      totalPages: null,
      alert_type: '',
      alert_message: ''
    }
    this.validator = new SimpleReactValidator();
  }
  
  componentDidMount(){
    const headers = { headers:{ Authorization: `Bearer ${localStorage.getItem("jwtToken")}`}};
    axios.get('/api/v1/setting/sales/list', headers).then(res => {
      const meta = res.data.meta; const data = res.data.data;
      if(meta.code === 200){
        console.log('sini');
       
        if(data.length > 0){
          this.setState({salesPerson: data})
        }
      }else{
         NotificationManager.error("Error", "Failed to Load Data", 50000, () => {
            alert('callback');
        });
      }
    }).catch(error => {
       NotificationManager.error("Error", "Failed to Load Data", 50000, () => {
            alert('callback');
        });
    });
  }

  onPageChanged = data => {
    const { salesPerson } = this.state;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentData= salesPerson.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentData, totalPages });
  };

  downloadHandler = e => {
    const headers = { headers:{ Authorization: `Bearer ${localStorage.getItem("jwtToken")}`}};
    axios.get('/api/v1/setting/sales/export', headers).then(res => {
      const meta = res.data.meta; //const data = res.data.data;
      if(meta.code === 200){
        console.log('success');
      }else{
        console.log('res note 200');
      }
      
    }).catch(error => {
      this.setState({...this.state, alert_type: 'error', alert_message: 'Something error'});
    });
  }

  addFiles = e => {
    e.preventDefault();
    
    console.log(e.target.files[0]);
    this.setState({...this.state, file: e.target.files[0]});
  }

  uploadHandler = e => {
    e.preventDefault();
    if (this.state.file !== '') {
      const headers = { headers:{ Authorization: `Bearer ${localStorage.getItem("jwtToken")}`, 'Content-Type': 'multipart/form-data' }};
      let file = this.state.file;
      let formData = new FormData();
      formData.append('file', file);

      axios.post('/api/v1/setting/sales/import', formData, headers).then(res => {
        const meta = res.data.meta; //const data = res.data.data;
        if(meta.code === 200){
          NotificationManager.success("Success", "Data has been uploaded");
          setTimeout(() => {
            window.location.reload();
          }, 2000)
        }else{
          NotificationManager.error("Error", "Failed to Upload Data", 5000, () => { alert('callback'); });
        }
      }).catch(error => {
        NotificationManager.error("Error", "Failed to Upload Data", 5000, () => { alert('callback'); });
      });
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  
  render (){
    let recordLength = this.state.salesPerson.length;
    let alert = '';
    if(this.state.alert_type){
      alert = <Flash type={this.state.alert_type} message={this.state.alert_message}/>
    }
    return (
      <div>
        <SettingContainer pagename="Setting" pagedetailname="Sales" location="sales">
          {alert}
          <div className="panel panel-default">
            <div className="panel-heading">SALES PERSON MANAGEMENT</div>
            <div className="panel-body form form-xs">
              <div className="row">
                <div className="col-md-3">
                  <div className="well">
                    {/* <button className="btn btn-danger btn-block" onClick={this.downloadHandler}>Download Format</button> */}
                    { this.state.salesPerson !== '' ? 
                      <ExcelFile element={<button className="btn btn-danger btn-block">Download Format</button>} filename="Sales Person">
                        <ExcelSheet data={this.state.salesPerson} name="Part">
                          <ExcelColumn label="id" value="id"/>
                          <ExcelColumn label="name" value="name"/>
                          <ExcelColumn label="code" value="code"/>
                          <ExcelColumn label="email" value="email"/>
                        </ExcelSheet>
                      </ExcelFile>
                      : <button className="btn btn-danger btn-block">Download Format</button>
                    }
                    <br/><br/><hr/>
                    <input type="file" name="sales_file" onChange={this.addFiles}/>
                    <span className="help-block form-error" style={{color: "#8B0000"}}>{this.validator.message('upload', this.state.file, 'required')}</span>
                    <br/>
                    <button className="btn btn-primary btn-block" onClick={this.uploadHandler}>Upload</button>
                  </div>
                </div>
                <div className="col-md-9">
                  <table className="table table-bordered table-xs">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.salesPerson.length > 0 ?
                       this.state.currentData.map(sp => (
                         <tr key={sp.id}>
                           <td>{sp.name}</td>
                           <td>{sp.code}</td>
                           <td>{sp.email}</td>
                         </tr>
                       ))
                       : <tr>
                           <td colSpan="3" style={{textAlign: "center"}}>
                             No Data Available in This Table
                           </td>
                           
                         </tr>}
                    </tbody>
                  </table>    
                  <div className="text-right">
                    {recordLength !== 0 ?
                     <Pagination
                       totalRecords={recordLength}
                       pageLimit={10}
                       pageNeighbours={1}
                       onPageChanged={this.onPageChanged}
                     />
                     : ''}
                  </div>  
                </div>
              </div>
            </div>
          </div>
        </SettingContainer>
        <NotificationContainer/>
      </div>
    )
  }
}

export default Sales;
