import {
  PROSES_TICKET_ASSIGN_CE,
  SAVE_OTP,
  SAVE_ASSIGN,
  DEL_OTP,
  DEL_ASSIGN,
  LOCATION_CE
} from "../actions/types";

const initialState = {
  assign_ce: [],
  ce: [],
  acting: [],
  ticket_type: [],
  department: [],
  ticket_origin: [],
  machine_status: [],
  machine_type: [],
  service_code: [],
  machine: [],
  machine_customer: [],
  customers: [],
  machine_models: [],
  technicians: [],
  contracts: [],
  sales: [],
  customer: [],
  resSaveOtp: [],
  resSaveAssign: [],
  blocked_by: [],
  contact: [],
  originator: [],
  originator_dept: [],
  locationCE: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PROSES_TICKET_ASSIGN_CE:
      return {
        ...state,
        assign_ce: action.payload,
        ce: action.ce,
        acting: action.acting,
        ticket_type: action.ticket_type,
        department: action.department,
        ticket_origin: action.ticket_origin,
        machine_status: action.machine_status,
        machine_customer: action.machine_customer,
        machine_type: action.machine_type,
        service_code: action.service_code,
        machine: action.machine,
        customers: action.customers,
        machine_models: action.machine_models,
        technicians: action.technicians,
        contracts: action.contracts,
        sales: action.sales,
        customer: action.customer,
        blocked_by: action.blocked_by,
        contact: action.contact,
        originator: action.originator,
        originator_dept: action.originator_dept
      };
    case SAVE_OTP:
      return {
        ...state,

        resSaveOtp: action.payload
      };
    case LOCATION_CE:
      return {
        ...state,

        locationCE: action.payload
      };
    case DEL_OTP:
      return {
        ...state,

        resSaveOtp: action.payload
      };

    case SAVE_ASSIGN:
      return {
        ...state,

        resSaveAssign: action.payload
      };
    case DEL_ASSIGN:
      return {
        ...state,

        resSaveAssign: action.payload
      };

    default:
      return state;
  }
}
