import React, { Component } from "react";

class ModalHeader extends Component {
  render() {
    const { onClick, info } = this.props;
    return (
      <div className="modal-header">
        <button type="button" className="close" onClick={onClick}>
          &times;
        </button>
        <h4 className="modal-title">{info}</h4>
      </div>
    );
  }
}

export default ModalHeader;
