import React, { Component } from "react";
import TextInputDisabled from "../../common/TextInputDisabled";
class Originator extends Component {
  render() {
    // const { fullName, email, level, department } = this.props.auth.users;
    return (
      <div className="panel panel-default">
        <div className="panel-heading">Originator</div>
        <div className="panel-body">
          <div className="form form-xs">
            <div className="row row-xs">
              <div className="col-md-6">
                <TextInputDisabled
                  label="Department"
                  value={this.props.department}
                />
                <TextInputDisabled
                  label="Date/Time Created"
                  value={this.props.date}
                />
              </div>
              <div className="col-md-6">
                <TextInputDisabled label="Name" value={this.props.fullname} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Originator;
