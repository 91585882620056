import React, { Component } from 'react';
import TextInputDisabled from "../../../common/TextInputDisabled";
import moment from 'moment';

const CustomerInformation = props =>{
  return (
    <React.Fragment>
      <div className="panel panel-default">
        <div className="panel-heading">Originator</div>
        <div className="panel-body">
          <div className="form form-xs">
            <div className="row row-xs">
              <div className="col-md-6">
                <TextInputDisabled
                  label="Department"
                  value={props.originator.department.name}
                />
                <TextInputDisabled label="Date/Time Created" value={moment(props.created).format('L HH:mm')}/>
              </div>
              <div className="col-md-6">
                <TextInputDisabled label="Name" value={props.originator.username} />
                <TextInputDisabled label="User ID" value={props.originator.id} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CustomerInformation;
