import React, { Component } from "react";

class ModalCustomer extends Component {
  componentDidMount() {
    this.selectRow();
  }
  selectRow = () => {
    let index,
      table = document.getElementById("table");
    for (let i = 1; i < table.rows.length; i++) {
      table.rows[i].onclick = function() {
        // remove the background from the previous selected row
        if (typeof index !== "undefined") {
          table.rows[index].classList.toggle("selected");
        }
        // get the selected row index
        index = this.rowIndex;
        // add class selected to the row
        this.classList.toggle("selected");
      };
    }
  };

  render() {
    const { customer, onClick } = this.props;
    return (
      <tr
        style={{
          cursor: "pointer"
        }}
        onClick={onClick}
      >
        <td>{customer.name}</td>
        <td>{customer.ship_to_address}</td>
      </tr>
    );
  }
}

export default ModalCustomer;
