import React from 'react';

import Modal from '../../../../ui/Modal/Modal';
import './Modal.css';
import DraftServiceLabor from './draftServiceLabor';
import DraftRelocation from './draftRelocation';

const DraftModal = props => {
  let body = '';
  console.log(props.dsubmit.quotation_type.replace(/\s/g, ""));
  switch(props.dsubmit.quotation_type.replace(/\s/g, "")){
  // case 'SERVICELABORCHARGE':
  //   body = <DraftServiceLabor {...props}/>
  //   break;
  case 'MachineRelocation':
    body = <DraftRelocation {...props}/>
    break;
  // case 'TONER':
  //   break;
  // case 'PARTSREPLACEMENT':
  //   break;
  default:
    body = <DraftServiceLabor {...props}/>
  }
  return (
    <Modal title="Quotation Draft" onclose={props.onClose} modalid={props.modalid} modalclass={props.modalclass}>
      {body}
      <div className="modal-footer">
        {/* <button type="button" className="btn btn-primary pull-right" onClick={props.onSave} style={{marginLeft: "5px"}}>Save</button> */}
        <button type="button" className="btn btn-default pull-right" onClick={props.onClose} style={{border: "0"}}>Close</button>
      </div>
    </Modal>
  )
}

export default DraftModal;







