import {
  GET_USERS,
  XXX_DATA,
  TICKET_HISTORY_DATA,
  TICKET_INBOX_DATA,
  // TICKET_INBOX_HELPDESK_DATA,
  LIST_USER,
  // LIST_USER_CE,
  // LIST_FAULT_AREA,
  // LIST_PROBLEM,
  DETAIL_HISTORY,
  DETAIL_INBOX,
  PROSES_TICKET_HELPDESK,
  // PROSES_TICKET_ASSIGN_CE,
  DATA_CREATE_USER,
  SAVE_USER,
  RESET,
  DEL_INBOX,
  DEL_MESSAGE
} from "../actions/types";

const initialState = {
  user_data: [],
  userx: [],

  ticketHis: [],
  ticket_typeHis: [],
  ticket_statusHis: [],
  ticketIn: [],
  ticket_typeIn: [],
  ticket_statusIn: [],
  list_user: [],
  // list_user_ce: [],
  problem_code: [],
  fault_area_code: [],
  history_comments: [],

  originator: [],
  tickets_det: [],
  originator_des: [],
  originator_des_dep: [],
  customer: [],
  ticket_details: [],

  persons: [],
  user: [],
  branch_list: [],
  department: [],

  resp: [],
  call_information: [],
  service_code_det: [],
  machine: [],
  ce_assign: [],

  ticket_origin: [],
  originator: [],
  departments: [],
  machine_customer: [],
  machine: [],
  customers: [],
  blocked_by: [],
  machine_models: [],
  technician: [],
  shipping: [],
  ticket_tracking: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        user_data: action.payload
      };
    case XXX_DATA:
      return {
        ...state,
        userx: action.payload
      };
    case TICKET_HISTORY_DATA:
      return {
        ...state,
        ticketHis: action.ticketx,
        ticket_typeHis: action.ticket_typex,
        ticket_statusHis: action.ticket_statusx
      };
    case TICKET_INBOX_DATA:
      return {
        ...state,
        ticketIn: action.ticket,
        ticket_typeIn: action.ticket_type,
        ticket_statusIn: action.ticket_status
      };
    case DEL_INBOX:
      return {
        ...state,
        ticketIn: action.ticket,
        ticket_typeIn: action.ticket_type,
        ticket_statusIn: action.ticket_status
      };

    // case TICKET_INBOX_HELPDESK_DATA:
    //   return {
    //     ...state,
    //     ticketIn: action.ticket,
    //     ticket_typeIn: action.ticket_type,
    //     ticket_statusIn: action.ticket_status
    //   };

    case LIST_USER:
      return {
        ...state,
        list_user: action.payload
      };
    // case LIST_USER_CE:
    //   return {
    //     ...state,
    //     list_user_ce: action.payload
    //   };
    // case LIST_PROBLEM:
    //   return {
    //     ...state,
    //     problem_code: action.problem_code
    //   };

    // case LIST_FAULT_AREA:
    //   return {
    //     ...state,
    //     fault_area_code: action.fault_area_code
    //   };

    case DETAIL_HISTORY:
      return {
        ...state,
        assign_ce: action.payload,
        ticket_origin: action.ticket_origin,
        originator: action.originator,
        departments: action.departments,
        machine_customer: action.machine_customer,
        machine: action.machine,
        customers: action.customers,
        blocked_by: action.blocked_by,
        machine_models: action.machine_models,
        technician: action.technician,
        shipping: action.shipping,
        ticket_tracking: action.ticket_tracking
      };
    case DETAIL_INBOX:
      return {
        ...state,
        originator: action.originator,
        tickets_det: action.tickets_det,
        originator_des: action.originator_des,
        originator_des_dep: action.originator_des_dep,
        customer: action.customer,
        ticket_details: action.ticket_details
      };
    case PROSES_TICKET_HELPDESK:
      return {
        ...state,
        originator: action.originator,
        tickets_det: action.tickets_det,
        originator_des: action.originator_des,
        originator_des_dep: action.originator_des_dep,
        customer: action.customer,
        ticket_details: action.ticket_details,
        // call_information: action.call_information,
        service_code_det: action.service_code_det,
        machine: action.machine,
        history_comments: action.history_comments
      };

    case DATA_CREATE_USER:
      return {
        ...state,

        persons: action.persons,
        user: action.user,
        branch_list: action.branch_list,
        department: action.department
      };
    case SAVE_USER:
      return {
        ...state,

        resp: action.payload
      };
    case RESET:
      return {
        ...state,

        resp: action.payload
      };
    case DEL_MESSAGE:
      return {
        ...state,
        resp: action.payload
      };

    default:
      return state;
  }
}
