const salesItem = (
  type,
  item_code,
  location_code,
  shipment_date,
  description,
  description2,
  quantity,
  quantity_to_invoice,
  quantity_to_ship,
  unit_price,
  quantity_shipped,
  quantity_invoiced,
  line_amount,
  bin_code,
  unit_of_measure_code,
  planned_delivery_date,
  planned_shipment_date,
  ship_to_code,
  machine_code,
  service_report_number
) => {
  return {
    order_type: type,
    item_code: item_code,
    location_code: location_code,
    shipment_date: shipment_date,
    description: description,
    description2: description2,
    quantity: quantity,
    quantity_to_invoice: quantity_to_invoice,
    quantity_to_ship: quantity_to_ship,
    unit_price: unit_price,
    quantity_shipped: quantity_shipped,
    quantity_invoiced: quantity_invoiced,
    line_amount: line_amount,
    bin_code: bin_code,
    unit_of_measure_code,
    planned_delivery_date,
    planned_shipment_date,
    ship_to_code: ship_to_code,
    machine_code: machine_code,
    service_report_number: service_report_number
  };
};

export default salesItem;
