import { SET_SEARCH_CUSTOMER_BY_SERIAL } from "../actions/searchSerialAction";

export default function searchSerial(state = [], action) {
  switch (action.type) {
    case SET_SEARCH_CUSTOMER_BY_SERIAL:
      return { ...state, serial: action.payload };
    default:
      return state;
  }
}
