import React, { Component } from 'react';
import TextInputGroup from "../../../common/TextInputGroup";
import TextInputDisabled from "../../../common/TextInputDisabled";
import TextAreaInput from "../../../common/TextAreaInput";
import moment from 'moment';

const CustomerInformation = props => {
  return (
    <React.Fragment>
      <div className="panel panel-default">
        <div className="panel-heading">Customer Information</div>
        <div className="panel-body">
          <div className="form form-xs">
            <div className="row row-xs">
              <div className="col-md-6">
                <fieldset>
                  <legend>Search By</legend>
                  {/* <TextInputGroup label="Serial No" name="serial_no" value={props.machine.machine_serial ? props.machine.machine_serial : ''} disabled="disabled"/> */}
                  <TextInputGroup label="Equipment No" name="serial_no" value={props.machine.equipment_number ? props.machine.equipment_number : ''} disabled="disabled"/>
                  <TextInputGroup label="Customer Name" name="serial_no" value={props.customers.CustomerName ? props.customers.CustomerName : ''} disabled="disabled"/>
                </fieldset>
                <TextInputDisabled label="Model" value={props.machine.machine_serial ? props.machine.machine_models.model_name: '-'}/>
                <TextInputDisabled label="Customer Category" value={props.customers.customer_category ? props.customers.customer_category: '-'}/>
                <TextInputDisabled label="Prefered CE" value={props.customers.sap_technician_code ? props.customers.sap_technician_code : '-'}/>
                <fieldset>
                  <legend>Ship To</legend>
                  <TextInputDisabled label="Ship to Name" value={props.ship_to_name != null ? props.ship_to_name : ''}/>
                  <TextInputDisabled label="Ship to Code" value={props.ship_to_code != null ? props.ship_to_code : ''}/>
                  <TextInputDisabled label="Ship to Contact" value={props.ship_to_contact_name ? props.ship_to_contact_name : ''}/>
                </fieldset>
                <fieldset>
                  <legend>CSRC</legend>
                  <TextInputDisabled label="CSRC Status" value={props.csrc_status ? props.csrc_status : ''}/>
                  <TextInputDisabled label="Last Day of Connection" value=""/>
                </fieldset>
              </div>
              <div className="col-md-6">
                <fieldset>
                  <legend>Block By</legend>
                  <TextInputDisabled label="Status" />
                  <TextInputDisabled label="Department" value={ props.customers.blocked_by.blocked_by_department ? props.customers.blocked_by.blocked_by_department : '-'}/>
                  <TextInputDisabled label="Name" value={props.customers.blocked_by.blocked_by_user_name ? props.customers.blocked_by.blocked_by_user_name: '-'}/>
                  <TextInputDisabled label="User ID" value={props.customers.blocked_by.blocked_by_user_id ? props.customers.blocked_by.blocked_by_user_id : '-'}/>
                </fieldset>
                <fieldset>
                  <legend>Contract Detail</legend>
                  <TextInputDisabled label="Contract No" value={props.contracts.contract_number ? props.contracts.contract_number : ''}/>
                  <TextInputDisabled label="End Date" value={props.contracts.contract_end_date ? moment(props.contracts.contract_end_date).format('L') : ''}/>
                </fieldset>
                <fieldset>
                  <legend>Sales Detail</legend>
                  <TextInputDisabled label="Sales Person Code" value={props.customers.sap_sales_person_code ? props.customers.sap_sales_person_code : '-'}/>
                  <TextInputDisabled label="Sales Person Name" value={props.customers.sap_sales_person_code  ? props.customers.sap_sales_person_code : '-'}/>
                  <TextInputDisabled label="DSM Code" value={props.customers.sap_dsm_code ? props.customers.sap_dsm_code : '-'}/>
                  <TextInputDisabled label="DSM Name" value={props.customers.sap_dsm_name ? props.customers.sap_dsm_name : '-'}/>
                </fieldset>
              </div>
            </div>
            <fieldset>
              <legend>Customer Details</legend>
              <TextAreaInput label="Ship to Address" value={props.ship_to_address ? props.ship_to_address : ''} disabled="disabled"/>
              <TextAreaInput label="Location of Equipment No" value={props.location ? props.location : ''} disabled="disabled"/>
              <div className="row">
                <div className="col-md-6">
                  <TextInputDisabled label="Telephone No" value={props.ship_to_contact_phone ? props.ship_to_contact_phone : ''} disabled="disabled"/>
                </div>
                <div className="col-md-6">
                  <TextInputDisabled label="Postal Code" value={props.ship_to_postcode ? props.ship_to_postcode : ''} disabled="disabled"/>
                  <TextInputDisabled label="Delivery Date" value={props.delivery_date ? moment(props.delivery_date).format('L') : ''} disabled="disabled"/>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>SLA Details</legend>
              <TextInputDisabled label="SLA Response Time" value=""/>
              <TextInputDisabled label="Additional SLA Details" value=""/>
            </fieldset>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CustomerInformation;
