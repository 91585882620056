import { QUOTATION_PDF_SAVE, QUOTATION_PDF_PURGE } from "./types";

export const saveQuotation= data => async dispatch => {
  console.log(data);
  dispatch({
    type: QUOTATION_PDF_SAVE,
    payload: data
  })
};

export const removeQuotation= data => async dispatch => {
  dispatch({
    type: QUOTATION_PDF_PURGE,
    payload: data
  })
};





