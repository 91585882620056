import { LIST_UNASSIGN, UNASSIGN_CE } from "../actions/types";

const initialState = {
  // listUnassign: []
  ticketIn: [],
  ticket_typeIn: [],
  ticket_statusIn: [],

  assign_ce: {},
  // ce: [],
  ticket_type: {},
  department: {},
  ticket_origin: {},
  machine_status: {},
  machine_type: {},
  service_code: {},
  machine: {},
  machine_customer: {},
  customers: {},
  machine_models: {},
  technicians: {},
  contracts: {},
  sales: {},
  customer: {},
  // resSaveOtp: [],
  // resSaveAssign: [],
  blocked_by: {},
  contact: {},
  originator: {},
  originator_dept: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_UNASSIGN:
      return {
        ...state,
        ticketIn: action.ticket,
        ticket_typeIn: action.ticket_type,
        ticket_statusIn: action.ticket_status
      };

    case UNASSIGN_CE:
      return {
        ...state,
        assign_ce: action.payload,
        // ce: action.ce,
        ticket_type: action.ticket_type,
        department: action.department,
        ticket_origin: action.ticket_origin,
        machine_status: action.machine_status,
        machine_customer: action.machine_customer,
        machine_type: action.machine_type,
        service_code: action.service_code,
        machine: action.machine,
        customers: action.customers,
        machine_models: action.machine_models,
        technicians: action.technicians,
        contracts: action.contracts,
        sales: action.sales,
        customer: action.customer,
        blocked_by: action.blocked_by,
        contact: action.contact,
        originator: action.originator,
        originator_dept: action.originator_dept
      };

    default:
      return state;
  }
}
