import React from "react";
import PropTypes from "prop-types";

const TextAreaInput = ({ dcname, dstyle, type, label, value, cname, name, onChange, disabled, rules, children}) => {
  return (
    <div className={dcname} style={{dstyle}}>
      <label className="control-label">{label}</label>
      <textarea
        cols="30"
        rows="3"
        className={cname}
        name={name}
        onChange={onChange}
        value={value}
        disabled={disabled}/>
      {children}
    </div>
  );
};

TextAreaInput.propTypes = {
  type: PropTypes.string.isRequired
};

TextAreaInput.defaultProps = {
  type: "text",
  cname: "form-control",
  rules: "",
  dcname: "form-group",
  dstyle: ''
};

export default TextAreaInput;
