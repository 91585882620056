import React from 'react';

import Modal from '../../../ui/Modal/Modal';
import '../../../css/Modal.css';
import moment from 'moment';
const ceJobModal = (props) => {
  //console.log(props.listJob);
  return (
    <Modal title="Last Delivery" onclose={props.onclose} modalid={props.modalid} modalclass={props.modalclass}>
      <div className="modal-body">
       <div className="table-fixed-header" style={{height: "200px"}}>
          <table className="table table-bordered table-hover table-xs table-fixed">
            <thead>
              <tr>
                {/* <th width="10"></th> */}
                <th width="10%">Date</th>
                <th width="5%">Time</th>
                <th width="25%">Customer Name</th>
                <th width="15%">Serial No</th>
                <th width="15%">Model</th>
                <th width="35%">Ship to Address</th>
              </tr>
            </thead>
            <tbody>
              {props.listJob.length > 0 ? props.listJob.map(lj => (
                <tr key={lj.id}>
                  <td width="10%">{moment(lj.date_of_assign).format('L')}</td>
                  <td width="5%">{moment(lj.date_of_assign).format('HH:mm')}</td>
                  <td width="25%">{lj.ticket_origin.customer.CustomerName}</td>
                  <td width="15%">{lj.ticket_origin.machine_customer.machine.machine_serial}</td>
                  <td width="15%">{lj.ticket_origin.machine_customer.machine.machine_models.model_name}</td>
                  <td width="35%">{lj.ticket_origin.machine_customer.ship_to_address}</td>
                </tr>
              ))
               :
               <tr>
                   <td colSpan="6" width="100%" style={{textAlign: "center"}}>
                     No Data Available in This Table
                   </td>
                 </tr>
              }
              
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-footer align-right">
        <button type="button" className="btn btn-default pull-right" onClick={props.onclose}>Close</button>
      </div>
    </Modal>
  )
}

export default ceJobModal;
