import React from 'react'

const ServiceReport = props => {
  return(
    <React.Fragment>
       <div className="panel panel-default">
        <div className="panel-heading">Service Report</div>
        <div className="panel-body">
          <div className="form form-xs">
            <div className="row row-xs">
             <a href="#" className="btn btn-danger">View New Window</a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ServiceReport;
