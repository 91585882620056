import React from 'react';
import TextInputDisabled from "../../../common/TextInputDisabled";
import TextAreaInput from "../../../common/TextAreaInput";
const ContactNote = props => {
  return(
    <div className="panel panel-default">
      <div className="panel-body">
        <div className="form form-xs">
          <TextInputDisabled label="Contact Note" value={props.origin.contact_note}/>
          <TextInputDisabled label="Action Taken" value={props.origin.action_taken}/>
          <TextInputDisabled label="Internal Note" value={props.origin.internal_note}/>
          <div>
            <label className="radio-inline">
              <input type="radio" name="is_service" value="1" checked={props.origin.is_service == 1 ? 'checked' : ''} disabled/>
              Service/Machine Problem
            </label>
            <label className="radio-inline">
              <input type="radio" name="is_service" value="2" checked={props.origin.is_service == 2 ? 'checked' : ''} disabled/>
              Internal Follow Up?
            </label>
            <label className="radio-inline">
              <input type="radio" name="is_service" value="3" checked={props.origin.is_service == 3 ? 'checked' : ''} disabled/>
              Other?
            </label>
          </div>
          <div className="well">
              <br/>
              <TextAreaInput label="Fault Comment" value={props.origin.fault_comment} name="fault_comment" disabled/>
              <div className="form-group">
                <label className="control-label">Machine Status</label>
                <select name="machine_status_id" value={props.origin.machine_status.id} className="form-control" disabled>
                  <option value="1">Machine Running</option>
                  <option value="2">Machine Down</option>
                </select>
              </div>
              <div className='form-group'>
                <label className="control-label">Machine Type</label>
                <select name="machine_type_id" id="" className="form-control" value={props.origin.machine_type.id} disabled>
                  <option value="">{props.origin.machine_type.name}</option>
                  
                </select>
              </div>
              <div className='form-group'>
                <label className="control-label">Service Order Type</label>
                <select name="service_code_id" id="" className="form-control" value={props.origin.service_code.id} disabled>
                  <option value="">{props.origin.service_code.job_code + ' ' + props.origin.service_code.job_detail}</option>
                </select>
              </div>
            </div>
        </div>
      </div>
    </div>
  )

}

export default ContactNote;
