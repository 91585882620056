import axios from "../axios-env.js";
import { TICKET_HISTORY_DATA, DETAIL_HISTORY } from "./types";

export const ticketHistory = jwtToken => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  };

  axios
    .get("/api/v1/ticket-management/ticket-history", head)
    .then(res => {
      // console.log("resp his dlm");
      // console.log(res);
      dispatch({
        type: TICKET_HISTORY_DATA,

        ticketx: res.data.data.ticket_history,
        ticket_typex: res.data.data.ticket_type,
        ticket_statusx: res.data.data.ticket_status
      });
    })
    .catch(err =>
      dispatch({
        type: TICKET_HISTORY_DATA,
        ticketx: null,
        ticket_typex: null,
        ticket_statusx: null
      })
    );
};

export const detailHistory = data => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${data.jwtToken}`
    }
  };

  axios
    .get(`/api/v1/ticket-management/ticket-history-detail/${data.id}`, head)
    .then(res => {
      // console.log("respon history");
      // console.log(res.data.data.ticket_origin.originator);
      dispatch({
        type: DETAIL_HISTORY,

        payload: res.data.data.ticket_origin,
        ticket_origin: res.data.data.ticket_origin,
        originator: res.data.data.ticket_origin.originator,
        departments: res.data.data.ticket_origin.originator.departments,
        machine_customer: res.data.data.ticket_origin.machine_customer,
        machine: res.data.data.ticket_origin.machine_customer.machine,
        customers: res.data.data.ticket_origin.machine_customer.customers,
        blocked_by:
          res.data.data.ticket_origin.machine_customer.customers.blocked_by,
        machine_models:
          res.data.data.ticket_origin.machine_customer.machine.machine_models,
        technician: res.data.data.ticket_origin.machine_customer.technician,
        shipping:
          res.data.data.ticket_origin.machine_customer.customers.shipping,
        ticket_tracking: res.data.data.ticket_origin.ticket_tracking,
        contact_detail: res.data.data.ticket_origin.contact_detail,
        contracts: res.data.data.ticket_origin.machine_customer.contracts,
        sales: res.data.data.ticket_origin.machine_customer.customers.sales
      });
    })
    .catch(err =>
      dispatch({
        type: DETAIL_HISTORY,
        payload: null,
        ticket_origin: null,
        originator: null,
        departments: null,
        machine_customer: null,
        machine: null,
        customers: null,
        blocked_by: null,
        machine_models: null,
        technician: null,
        shipping: null,
        ticket_tracking: null,
        contact_detail: null,
        contracts: null,
        sales: null
      })
    );
};
