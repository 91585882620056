import React from 'react';
import TextInputDisabled from "../../../common/TextInputDisabled";
import TextFormControl from "../../../common/TextFormControl";
import TextInputGroup from "../../../common/TextInputGroup";
import './TicketNumber.css';

const TicketNumber = props =>  {
  return (
     <React.Fragment>
      <div className="panel panel-default">
        <div className="panel-heading">Ticket Number : </div>
        <div className="panel-body">
          <div className="form form-xs">
            <div className="row row-xs">
              <div className="col-md-3">
                <TextInputDisabled clabel="control-label form-label" label="Form" value={props.from ? props.from : ''}/>
              </div>
              <div className="col-md-3">
    <TextInputDisabled clabel="control-label to-label" label="To" value={props.to ? props.to : ''}/>
              </div>
              <div className="col-md-6">
                <TextInputGroup
                  clabel="control-label req-label" label="Requirement Process"
                  value="INVOICE" disabled="disabled" btext="Track Ticket"
                  onClick={props.onClick}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TicketNumber;











