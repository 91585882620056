import { TICKET_HISTORY_DATA, DETAIL_HISTORY } from "../actions/types";

const initialState = {
  ticketHis: [],
  ticket_typeHis: [],
  ticket_statusHis: [],

  assign_ce: [],
  ticket_origin: [],
  originator: [],
  departments: [],
  machine_customer: [],
  machine: [],
  customers: [],
  blocked_by: [],
  machine_models: [],
  technician: [],
  shipping: [],
  ticket_tracking: [],
  contact_detail: [],
  contracts: [],
  sales: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TICKET_HISTORY_DATA:
      return {
        ...state,
        ticketHis: action.ticketx,
        ticket_typeHis: action.ticket_typex,
        ticket_statusHis: action.ticket_statusx
      };

    case DETAIL_HISTORY:
      return {
        ...state,
        assign_ce: action.payload,
        ticket_origin: action.ticket_origin,
        originator: action.originator,
        departments: action.departments,
        machine_customer: action.machine_customer,
        machine: action.machine,
        customers: action.customers,
        blocked_by: action.blocked_by,
        machine_models: action.machine_models,
        technician: action.technician,
        shipping: action.shipping,
        ticket_tracking: action.ticket_tracking,
        contact_detail: action.contact_detail,
        contracts: action.contracts,
        sales: action.sales
      };

    default:
      return state;
  }
}
