import React, { Component } from 'react';
import axios from '../../../../axios-env';
import SimpleReactValidator from 'simple-react-validator';

import SettingContainer from '../Container';
import TextInputGroup from "../../../common/TextInputGroup";
import Pagination from '../../../../components/common/Pagination';
import Flash from '../../../../components/common/FlashMessage';

import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Part extends Component {
  constructor(props){
    super(props)
    this.state = {
      part: [
        
      ],
      currentData: [],
      currentPage: null,
      totalPages: null,
      file: ''
    }
    this.validator = new SimpleReactValidator();
  }

  componentDidMount(){
    this.validator = new SimpleReactValidator();
    const headers = { headers:{ Authorization: `Bearer ${localStorage.getItem("jwtToken")}`}};
    axios.get('/api/v1/setting/spare-part/list', headers).then(res => {
      const meta = res.data.meta; const data = res.data.data;
      if(meta.code === 200){
        if(data.length > 0){
          this.setState({part: data})
        }
      }else{
        this.setState({...this.state, alert_type: 'error', alert_message: 'Failed to Load Data'});
      }
      
    }).catch(error => {
      this.setState({...this.state, alert_type: 'error', alert_message: 'Failed to Load Data'});
    });
  }

  onPageChanged = data => {
    const { part } = this.state;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentData= part.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentData, totalPages });
  };

  addFiles = e => {
    e.preventDefault();
    this.setState({...this.state, file: e.target.files[0]});
  }

  uploadHandler = e => {
    e.preventDefault();
    if (this.state.file !== '') {
      const headers = { headers:{ Authorization: `Bearer ${localStorage.getItem("jwtToken")}`, 'Content-Type': 'multipart/form-data' }};
      let file = this.state.file;
      let formData = new FormData();
      formData.append('file', file);

      axios.post('/api/v1/setting/spare-part/import', formData, headers).then(res => {
        const meta = res.data.meta; const data = res.data.data;
        
        if(meta.code === 200){
          this.setState({...this.state, alert_type: 'success', alert_message: 'Data has been imported'});

          setTimeout(() => {
            this.setState({...this.state, alert_type: 'success', alert_message: 'Data has been imported'});
            window.location.reload();
          }, 2000)
        }else{
          this.setState({...this.state, alert_type: 'error', alert_message: 'Failed to Import Data'});
        }
      }).catch(error => {
        this.setState({...this.state, alert_type: 'error', alert_message: 'Something error'});
      });
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });
  
  render(){
    let recordLength = this.state.part.length;
    let alert = '';
    if(this.state.alert_type){
      alert = <Flash type={this.state.alert_type} message={this.state.alert_message}/>
    }
    return (
      <div>
        <SettingContainer pagename="Setting" pagedetailname="Spare Part Management" location="consumable">
          {alert}
          <div className="panel panel-default">
                <div className="panel-heading">SPARE PARTS MANAGEMENT</div>
                <div className="panel-body form form-xs">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="well form form-xs">
                                <div className="alert alert-info text-center">
                                    Please upload the data using right excel format
                                </div>
                                <div className="form-group">
                                    <label>Download</label>
                                    <div>
                                      { this.state.part != '' ? 
                                        <ExcelFile element={<button className="btn btn-danger"  target="_blank" download>Download Excel Format</button>} filename="Sparepart">
                                          <ExcelSheet data={this.state.part} name="Part">
                                            <ExcelColumn label="id" value="id"/>
                                            <ExcelColumn label="part_number" value="part_number"/>
                                            <ExcelColumn label="description" value="description"/>
                                            <ExcelColumn label="unit_price" value="unit_price"/>
                                            <ExcelColumn label="sap_material_code" value="sap_material_code"/>
                                            <ExcelColumn label="machine_model" value="machine_model"/>
                                          </ExcelSheet>
                                        </ExcelFile>
                                        : <button className="btn btn-danger" disabled>Download Excel Format</button>
                                      }
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Upload</label>
                                  <input type="file" name="file" onChange={this.addFiles}/>
                                </div>
                              <span className="help-block form-error" style={{color: "#8B0000"}}>{this.validator.message('upload', this.state.file, 'required')}</span>
                                <div className="form-group">
                                    <button className="btn btn-primary" onClick={this.uploadHandler}>Upload</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                          <table className="table table-bordered table-xs">
                            <thead>
                                <tr>
                                    <th>Model Name</th>
                                    <th>Parts No</th>
                                    <th>Parts Name</th>
                                    <th>Quantity</th>
                                    <th>Type</th>
                                    <th>Threshold</th>
                                    <th>is Monitoring?</th>
                                    <th>Stockroom</th>
                                    <th>CE SAP ID</th>
                                </tr>
                            </thead>
                            <tbody>
                               {this.state.part.length <= 0
                               ?<tr><td colSpan="9" className="text-center">No Data Available in This Table</td></tr>
                               :
                               this.state.currentData.map(p => (
                                 <tr key={p.id}>
                                   <td>{p.machine_model}</td>
                                   <td>{p.part_number}</td>
                                   <td>{p.description}</td>
                                   <td>100*</td>
                                   <td>OP*</td>
                                   <td>10*</td>
                                   <td>Yes*</td>
                                   <td>CE*</td>
                                   <td>{p.sap_material_code}</td>
                                 </tr>
                               ))}
                             
                            </tbody>
                          </table>
                          <div className="panel-footer form-xs">
                            {recordLength !== 0 ?
                             <Pagination
                               totalRecords={recordLength}
                               pageLimit={10}
                               pageNeighbours={1}
                               onPageChanged={this.onPageChanged}
                             />
                             : ''}
                          </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </SettingContainer>
      </div>
    )
  }
}

export default Part;
