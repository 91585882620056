import React from 'react';

const JobInvoice = props =>  {
  return (
    <React.Fragment>
      <div className="panel panel-default">
        <div className="panel-heading">Job Invoice</div>
        <div className="panel-body">
          <div className="form form-xs">
            <div className="row row-xs">
              <table className="table table-bordered table-xs">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>No</th>
                    <th>Description</th>
                    <th>Line Discount</th>
                    <th>CE Code</th>
                    <th>Location Code</th>
                    <th>Qty</th>
                    <th>Unit Price</th>
                    <th>Line Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {props.ji.length > 0 ?
                   props.ji.map(j => (
                     <tr key={j.id}>
                       <td>&nbsp;</td>
                       <td>{j.item_number}</td>
                       <td>{j.description}</td>
                       <td>&nbsp;</td>
                       <td>&nbsp;</td>
                       <td>&nbsp;</td>
                       <td>{j.quantity}</td>
                       <td>{j.price}</td>
                       <td>&nbsp;</td>
                     </tr>
                   ))
                   : <tr><td colSpan="9" style={{textAlign: "center"}}>No Data Available in This Table</td></tr>}
                  
                </tbody></table>
              <div className="pull-left">
                <button className="btn btn-primary btn-sm">View Draft</button>
                <button className="btn btn-danger btn-sm" style={{marginLeft: "2px"}}>Print &amp; Clear Ticket</button>
              </div>
              <div className="pull-right">
                <button className="btn btn-primary btn-sm">Save</button>
                <button className="btn btn-primary btn-sm" style={{marginLeft: "2px"}}>ESC</button>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default JobInvoice;
