import React, { Component } from "react";
// import Navbar from "../../layout/Navbar";
// import Sidebar from "../../layout/Sidebar";
import Pagination from "../../../common/Pagination";
import PaginationServerSide from "../../../common/PaginationServerSide";
// import PaginationReactTable from "../../../common/PaginationReactTable";
import PaginationReactTable from "../../../common/PaginationReactTable";
import "../../../pages/ticket/pagination.css";
import axios from "axios";
import { Link, Route } from "react-router-dom";
import { connect } from "react-redux";
import {
  holiday_list,
  add_holiday,
  edit_holiday,
  delete_holiday,
  del_edit
} from "../../../../actions/calendar_settingAction";
import SettingContainer from "../Container";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { del_res_wsp } from "../../../../actions/sprAction";
import matchSorter from "match-sorter";
import BigCalendar from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import "../../../../../src/react-table2.css";
import moment from "moment";
let myEventsList = [];

class calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      persons: [],
      resp2: [],
      search: "",
      search_by: "",
      currentTickets: [],
      currentPage: null,
      totalPages: null
    };
    // this.reset = this.reset.bind(this);
    // this.onChanged = this.onChanged.bind(this);
    // this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const jwtToken = localStorage.getItem("jwtToken");

    this.props.holiday_list(jwtToken);

    // const { resp } = this.props.list_user;
    // this.setState({ resp2: resp });
  }
  componentWillUnmount() {
    // del_edit();
    // console.log("ini unmount.......");
  }
  createNotification = type => {
    return () => {
      switch (type) {
        case "info":
          NotificationManager.info("Info message");
          break;
        case "success":
          NotificationManager.success("Success message", "Title here");
          break;
        case "warning":
          NotificationManager.warning(
            "Warning message",
            "Close after 3000ms",
            3000
          );
          break;
        case "error":
          NotificationManager.error("Error message", "Click me!", 5000, () => {
            alert("callback");
          });
          break;
      }
    };
  };

  showModalEdit = (idx, date, description) => {
    this.setState({
      id_holy: idx,
      date_holy: date,
      desc_holy: description
    });
  };
  showModalDelete = idx => {
    this.setState({
      id_holy_del: idx
    });
  };
  // onPageChanged = data => {
  //   // const { tickets } = this.state;
  //   const { list_user } = this.props.list_user;
  //   const { currentPage, totalPages, pageLimit } = data;

  //   const offset = (currentPage - 1) * pageLimit;
  //   const currentTickets = list_user.slice(offset, offset + pageLimit);

  //   this.setState({ currentPage, currentTickets, totalPages });
  // };
  // reset() {
  //   // this.props.reset();
  //   localStorage.removeItem("message");
  //   localStorage.removeItem("error");
  // }
  // onChanged(e) {
  //   this.setState({ search: e.target.value.substr(0, 20) });
  // }
  handleAdd = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleEdit = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  submitAdd = () => {
    const saveUserData = {
      description: this.state.description,
      date: this.state.date
    };
    const datax = {
      dat: saveUserData,
      jwtToken: localStorage.getItem("jwtToken")
    };
    // console.log("ini default kirim", datax);
    this.props.add_holiday(datax);
    setTimeout(
      function() {
        window.location.reload();
      }.bind(this),
      3000
    );
    // this.props.history.push("/setting/pm");
    // window.location.reload();
  };
  submitEdit = () => {
    const saveUserData = {
      holiday_id: this.state.id_holy,
      description: this.state.desc_holy,
      date: this.state.date_holy
    };
    const datax = {
      dat: saveUserData,
      jwtToken: localStorage.getItem("jwtToken")
    };
    // console.log("ini default kirim", datax);
    this.props.edit_holiday(datax);
    // this.props.history.push("/setting/pm");
    // NotificationManager.info("Info message");
    // setTimeout(
    //   function() {
    //     //Start the timer
    //     window.location.reload();
    //     // window.location.reload(); //After 1 second, set render to true
    //     // this.props.del_edit();
    //   }.bind(this),
    //   5000
    // );
    // window.location.reload();
  };
  submitDelete = () => {
    // const saveUserData = {
    //   holiday_id: this.state.id_holy,
    //   description: this.state.desc_holy,
    //   date: this.state.date_holy
    // };
    const datax = {
      dat: this.state.id_holy_del,
      jwtToken: localStorage.getItem("jwtToken")
    };
    // console.log("ini default kirim", datax);
    this.props.delete_holiday(datax);
    // this.props.history.push("/setting/pm");
    // window.location.reload();
    setTimeout(
      function() {
        window.location.reload();
      }.bind(this),
      3000
    );
  };
  del_edit_action = () => {
    this.props.del_edit();
    // console.log("ddd");
  };
  render() {
    const {
      list_holiday,
      res_edit_holiday,
      store_holiday,
      res_delete_holiday
    } = this.props.list_holiday;
    const localizer = BigCalendar.momentLocalizer(moment);
    myEventsList = list_holiday.map(tic =>
      tic.date
        ? {
            end: new Date(moment(tic.date)),
            start: new Date(moment(tic.date)),

            title: tic.description,
            desc: tic.description,
            id: tic.description
          }
        : ""
    );
    const mylist = [
      {
        title: "Conference",
        start: new Date("06/26/2019"),
        end: new Date("06/27/2019"),
        desc: "Big conference"
      },
      {
        title: "Meeting",
        start: new Date(2017, 3, 12, 10, 30, 0, 0),
        end: new Date(2017, 3, 12, 12, 30, 0, 0),
        desc: "Pre-meeting meeting, to prepare for the meeting"
      },
      {
        title: "Lunch",
        start: new Date("08/17/2019, 13:30:00"),
        end: new Date("08/17/2019, 14:30:00"),
        desc: "Power lunch"
      }
    ];
    // console.log("mye", myEventsList);
    // console.log("myl", mylist);
    const mypick = {
      title: "SELECTED",
      start: this.state.ini3,
      end: this.state.ini4,
      desc: "picker"
    };
    const columns = [
      {
        Header: "NO",
        columns: [
          {
            accessor: "id",

            // header: "aa",
            show: false
          },
          {
            // Header: "No",
            filterable: false,
            sortable: false,
            style: { textAlign: "center" },
            width: 40,
            Cell: row => {
              return <div>{row.index + 1}</div>;
            },
            filterable: false
          }
        ]
      },
      {
        Header: "Description",
        columns: [
          {
            accessor: "description",
            // Header: "Sort Description",
            filterable: false,
            sortable: false,
            width: 200
            // filterMethod: (filter, row) =>
            //   row[filter.id].startsWith(filter.value)
            // &&
            // row[filter.id].endsWith(filter.value)
            // filterMethod: (filter, rows) =>
            //   matchSorter(rows, filter.value, { keys: ["description"] }),
            // filterAll: true
          }
        ]
      },
      {
        Header: "Date",
        columns: [
          {
            accessor: "date",
            // Header: "Sort Date",
            style: { textAlign: "center" },
            sortable: false,
            filterable: false
          }
        ]
      },
      {
        Header: "Action",
        columns: [
          {
            filterable: false,
            sortable: false,
            // accessor: "id",
            // accessor: "date",
            // accessor: "description",
            style: { textAlign: "center" },
            width: 200,
            Cell: ({ row }) => (
              <div>
                <div className="col-sm-6">
                  <a
                    data-toggle="modal"
                    data-target="#modal-edit"
                    className="btn btn-primary btn-xs"
                    onClick={() =>
                      this.showModalEdit(row.id, row.date, row.description)
                    }
                  >
                    <i className="fa fa-fw fa-pencil"></i> Edit
                  </a>
                </div>{" "}
                <div className="col-sm-6">
                  <a
                    data-toggle="modal"
                    data-target="#modal-delete"
                    className="btn btn-danger btn-xs"
                    onClick={() => this.showModalDelete(row.id)}
                  >
                    <i className="fa fa-fw fa-trash"></i> Remove
                  </a>
                </div>
              </div>
            )
          }
        ]
      }
    ];
    // this.setState({ resp2: resp });

    // const { list_user } = this.props.list_user;
    // const jwtToken = localStorage.getItem("jwtToken");
    // const header = { header: { Authorization: "Bearer " + jwtToken } };

    // console.log("as");
    // console.log(list_user);
    // // const { resp } = this.props.list_user;
    // // const { resp2 } = this.state;
    // console.log("respon-nya");
    // console.log(resp);
    // if (resp) {
    // }

    // localStorage.setItem("error", resp ? resp.error : "");
    // localStorage.setItem("message", resp ? resp.message : "");
    // const { errorx } = localStorage.getItem("error");
    // const messagex = localStorage.getItem("message");
    // // const { persons } = this.state;
    // const { search, search_by, currentTickets } = this.state;
    // let filtered = holiday_list.slice();

    // if (this.state.search && search_by === "fullname") {
    //   filtered = filtered.filter(
    //     ticket =>
    //       ticket.fullname
    //         .toLowerCase()
    //         .indexOf(this.state.search.toLowerCase()) !== -1
    //   );
    // }
    // if (this.state.search && search_by === "email") {
    //   filtered = filtered.filter(
    //     ticket =>
    //       ticket.email
    //         .toLowerCase()
    //         .indexOf(this.state.search.toLowerCase()) !== -1
    //   );
    // }
    // if (this.state.search && search_by === "mobile_phone") {
    //   filtered = filtered.filter(
    //     ticket =>
    //       [ticket.mobile_phone]
    //         .toString()
    //         .indexOf(this.state.search.toLowerCase()) !== -1
    //   );
    // }
    // const totalTickets = list_user.length;
    // if (totalTickets === 0) return null;
    let modaladd;
    modaladd = (
      <div className="modal fade" id="modal-add">
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
              <h4 className="modal-title">Add Holiday</h4>
            </div>

            <div className="modal-body">
              <div className="form-horizontal">
                <div className="form-group">
                  <label className="col-sm-4 control-label">Description</label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Description"
                      name="description"
                      value={this.state.description}
                      onChange={this.handleAdd}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-4 control-label">Date</label>
                  <div className="col-sm-8">
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Customer Name"
                      name="date"
                      value={this.state.date}
                      onChange={this.handleAdd}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-info btn-xs btn-block"
                onClick={this.submitAdd}
                data-dismiss="modal"
                aria-hidden="true"
                disabled={!this.state.description || !this.state.date}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    let modaledit;
    modaledit = (
      <div className="modal fade" id="modal-edit">
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
              <h4 className="modal-title">Edit Holiday</h4>
            </div>

            <div className="modal-body">
              <div className="form-horizontal">
                <div className="form-group">
                  <label className="col-sm-4 control-label">Description</label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Description"
                      name="desc_holy"
                      value={this.state.desc_holy}
                      onChange={this.handleEdit}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-4 control-label">Date</label>
                  <div className="col-sm-8">
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Customer Name"
                      name="date_holy"
                      value={this.state.date_holy}
                      onChange={this.handleEdit}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-info btn-xs btn-block"
                onClick={this.submitEdit}
                data-dismiss="modal"
                aria-hidden="true"
                disabled={!this.state.desc_holy || !this.state.date_holy}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    let modaldelete;
    modaldelete = (
      <div className="modal fade" id="modal-delete">
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
              <h4 className="modal-title">Delete Holiday</h4>
            </div>

            <div className="modal-body">
              Are You Sure to delete?
              {/* {this.state.id_holy_del} */}
            </div>

            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-danger btn-xs pull-right"
                onClick={() => this.submitDelete()}
                data-dismiss="modal"
                aria-hidden="true"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    if (res_edit_holiday) {
      if (res_edit_holiday.length !== 0) {
        NotificationManager.success(
          res_edit_holiday.message,
          "Edit Data Holiday"
        );
        setTimeout(
          function() {
            //Start the timer
            window.location.reload();
            // window.location.reload(); //After 1 second, set render to true
            // this.props.del_edit();
          }.bind(this),
          4000
        );
      }
    }
    if (store_holiday) {
      if (store_holiday.length !== 0) {
        NotificationManager.success(store_holiday.message, "Add Data Holiday");
      }
    }
    if (res_delete_holiday) {
      if (res_delete_holiday.length !== 0) {
        NotificationManager.success(
          res_delete_holiday.message,
          "Delete Holiday"
        );
      }
    }
    // console.log("res_edit_holiday", res_edit_holiday.length);
    // console.log("store_holiday", store_holiday.length);
    // if (add_holiday) {
    //   if (add_holiday.length !== 0) {
    //     NotificationManager.success("Add Holiday", add_holiday.message);
    //   }
    // }

    return (
      <div>
        <SettingContainer
          pagename="Setting"
          pagedetailname="Calendar"
          location="calendar"
        >
          <div className="panel panel-default">
            <div className="tab-content">
              <div className="panel-heading">Holiday Management</div>
              <div className="tabs-xs  col-sm-6">
                <div className="panel-body form form-xs">
                  {/* <button
                    className="btn btn-info"
                    onClick={() => this.del_edit_action()}
                  >
                    Info
                  </button> */}

                  <NotificationContainer />
                  {modaladd}
                  {modaledit}
                  {modaldelete}
                  <a
                    data-toggle="modal"
                    data-target="#modal-add"
                    className="btn btn-success btn-xs pull-right"
                  >
                    Create New
                  </a>
                </div>

                <ReactTable
                  data={list_holiday}
                  // PaginationComponent={PaginationServerSide}
                  // PaginationComponent={PaginationReactTable}
                  PaginationComponent={PaginationReactTable}
                  columns={columns}
                  defaultPageSize={5}
                  className="-highlight"
                  filterable
                  previousText="<<"
                  nextText=">>"
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value
                  }
                  minRows={1}
                  pageSizeOptions={[40, 200]}
                  // defaultPageSize={40}
                  // pageSize={20}
                />
                {/* <table className="table table-xs table-bordered">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Contact name</th>
                      <th>Detail</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list_holiday.map((person, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{person.date}</td>
                        <td>{person.description}</td>
                        <td>
                          <a
                            data-toggle="modal"
                            data-target="#modal-edit"
                            className="btn btn-primary btn-xs"
                            onClick={() =>
                              this.showModalEdit(
                                person.id,
                                person.date,
                                person.description
                              )
                            }
                          >
                            <i className="fa fa-fw fa-pencil"></i> Edit
                          </a>{" "}
                          <a
                            data-toggle="modal"
                            data-target="#modal-delete"
                            className="btn btn-danger btn-xs"
                            onClick={() => this.showModalDelete(person.id)}
                          >
                            <i className="fa fa-fw fa-trash"></i> Remove
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table> */}
              </div>
              <div className="col-sm-6">
                <br />
                {/* <h3 className="callout">
                  Click the "+x more" link on any calendar day that cannot fit
                  all the days events to see an inline popup of all the events.
                </h3> */}
                {/* <BigCalendar
                  // selectable
                  localizer={localizer}
                  events={myEventsList}
                  // startAccessor="start"
                  // endAccessor="end"
                  style={{ width: "100%", height: "270px" }}
                  views={["month"]}
                  popupOffset={20}
                  popup={true}
                  onSelectEvent={event => (
                    alert(event.title), "modal", "#modal-edit"
                  )}
                
                  defaultDate={new Date()}
                  eventPropGetter={(event, start, end, isSelected) => {
                    let newStyle = {
                      backgroundColor: "#a8085e",
                      color: "white",
                      borderRadius: "3px",
                      border: "none"
                    };
                    return {
                      className: "",
                      style: newStyle
                    };
                  }}
                  
                  step={60}
                 
                /> */}
              </div>
            </div>
          </div>
        </SettingContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  list_holiday: state.holiday_Reducer
});

export default connect(
  mapStateToProps,

  { holiday_list, add_holiday, edit_holiday, delete_holiday, del_edit }
)(calendar);
