// import axios from "axios";
import {
  GET_ERRORS,
  LIST_PM_CUSTOMER,
  EDIT_PM_CUST,
  UPDATE_PM_CUST,
  LIST_CUSTOMER_ADD,
  STORE_PM_CUST,
  DEL_PM_CUST,
  UPDATE_DEFAULT_DAYS,
  DEL_MESSAGE
} from "./types";

import axios from "../axios-env";

export const listPmCustomer = jwtToken => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  };
  // console.log("head pm");
  // console.log(head);

  axios
    .get("/api/v1/setting/pm-customer/list", head)
    .then(res => {
      // console.log("res pm");
      // console.log(res.data.data);

      const { message, code, error } = res.data.meta;
      const { meta } = res.data;
      if (error === true) {
        const mess = message ? message : "";
        dispatch({
          type: GET_ERRORS,
          payload: meta
        });
      }
      if (error === false) {
        dispatch({
          type: LIST_PM_CUSTOMER,
          payload: res.data.data
          // level: res.data.data.user.level
        });
      }
    })
    .catch(err =>
      dispatch({
        type: LIST_PM_CUSTOMER,
        payload: null
      })
    );
};
export const listConstumerAdd = jwtToken => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  };
  // console.log("head pm");
  // console.log(head);

  axios
    .get("/api/v1/setting/pm-customer/create", head)
    .then(res => {
      // console.log("res pm");
      // console.log(res.data);
      // if (res.data.meta.errormessage === "admin token required") {
      //   this.props.history.push("/dashboard");
      // }
      dispatch({
        type: LIST_CUSTOMER_ADD,

        pm_default: res.data.data.pm_default,
        customers: res.data.data.customers

        // department: res.data.data.user.department,
        // level: res.data.data.user.level
      });
    })
    .catch(err => {
      // console.log("res pm err");
      // console.log(res.data.meta);
      // this.props.history.push("/dashboard");
      dispatch({
        type: LIST_CUSTOMER_ADD,

        pm_default: null,
        customers: null
      });
    });
};

export const edit_pm_cust = data => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${data.jwtToken}`
    }
  };
  // console.log("head edit pm");
  // console.log(head);

  axios
    .get(`/api/v1/setting/pm-customer/edit/${data.id}`, head)
    .then(res => {
      // console.log("res edit pm");
      // console.log(res.data.data);
      dispatch({
        type: EDIT_PM_CUST,
        payload: res.data.data,
        customer: res.data.data.customer
        // level: res.data.data.user.level
      });
    })
    .catch(err =>
      dispatch({
        type: EDIT_PM_CUST,
        payload: null,
        customer: null
        // level: null
      })
    );
};

export const update_pm_cust = off => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${off.jwtToken}`
    }
  };
  const datas = off ? off.dt : "";
  // console.log("head update pm");
  // console.log(datas);

  axios
    .put("/api/v1/setting/pm-customer/update", datas, head)
    .then(res => {
      // console.log("res update pm");
      // console.log("aaa", datas);
      dispatch({
        type: UPDATE_PM_CUST,
        payload: res.data.meta,
        data_send: datas
        // level: res.data.data.user.level
      });
    })
    .catch(err =>
      dispatch({
        type: UPDATE_PM_CUST,
        payload: null,
        data_send: null
        // customer: null
        // level: null
      })
    );
};
export const update_default_days = off => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${off.jwtToken}`
    }
  };
  const datas = off ? off.dat : "";
  // console.log("head default days");
  // console.log(datas);

  axios
    .put("/api/v1/setting/pm-default/update", datas, head)
    .then(res => {
      // console.log("res default days");
      // console.log(res.data.meta);
      dispatch({
        type: UPDATE_DEFAULT_DAYS,
        payload: res.data.meta,
        data_send: datas
        // customer: res.data.data.customer
        // level: res.data.data.user.level
      });
    })
    .catch(err =>
      dispatch({
        type: UPDATE_DEFAULT_DAYS,
        payload: null,
        data_send: null
        // customer: null
        // level: null
      })
    );
};

export const store_pm_cust = off => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${off.jwtToken}`
    }
  };
  const datas = off ? off.dt : "";
  // console.log("head STORE pm");
  // console.log(datas);

  axios
    .post("/api/v1/setting/pm-customer/create", datas, head)
    .then(res => {
      // console.log("res store pm");
      // console.log(res.data.meta);
      dispatch({
        type: STORE_PM_CUST,
        payload: res.data.meta
        // customer: res.data.data.customer
        // level: res.data.data.user.level
      });
    })
    .catch(err =>
      dispatch({
        type: STORE_PM_CUST,
        payload: null
        // customer: null
        // level: null
      })
    );
};
export const del_pm_cust = off => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${off.jwtToken}`
    }
  };
  const datas = off ? off.id : "";
  // console.log("head del pm");
  // console.log(datas);

  axios
    .delete(`/api/v1/setting/pm-customer/delete/${off.id}`, head)
    .then(res => {
      // console.log("res del pm");
      // console.log(res.data.meta);
      dispatch({
        type: DEL_PM_CUST,
        payload: res.data.meta,
        data_send: datas
        // customer: res.data.data.customer
        // level: res.data.data.user.level
      });
    })
    .catch(err =>
      dispatch({
        type: DEL_PM_CUST,
        payload: null,
        data_send: null
        // customer: null
        // level: null
      })
    );
};
export const del_message = () => {
  // console.log("del dalam");
  return {
    type: DEL_MESSAGE,
    payload: []
  };
};
