import { GET_ORIGINATION_CUSTOMER, DELETE_ORIGINATION_CUSTOMER } from "../actions/types";
const initialState = {
  customer: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
  case GET_ORIGINATION_CUSTOMER:
    return {
      ...state,
      customer: action.payload
    };
  case DELETE_ORIGINATION_CUSTOMER:
    console.log('delete origin');
    console.log(state);
    return {
      ...state,
      customer: {}
    };
  default:
    return state;
  }
}
