import React, { Component } from "react";
// import Navbar from "../../layout/Navbar";
// import Sidebar from "../../layout/Sidebar";
import Pagination from "../../../common/Pagination";
import ModalHeader from "../../../common/ModalHeader";
import ModalFooter from "../../../common/ModalFooter";
import axios from "axios";
import { Link, Route } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser } from "../../../../actions/authActions";
import {
  listPmCustomer,
  edit_pm_cust,
  update_pm_cust,
  listConstumerAdd,
  store_pm_cust,
  del_pm_cust,
  update_default_days,
  del_message
} from "../../../../actions/pmAction";
// import { listPmMachine } from "../../../../actions/pmMacineAction";
import PmMachine from "./PmMachine";

import SettingContainer from "../Container";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import ReactTable from "react-table";
import "react-table/react-table.css";
import "../../../pages/ticket/pagination.css";
import PaginationReactTable from "../../../common/PaginationReactTable";
import PaginationServerSide from "../../../common/PaginationServerSide";

import matchSorter from "match-sorter";
let upside = "";
let downside = "";
let pagi = "";
class pm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      pm_days: "",
      currentTickets: [],
      currentTickets2: [],

      currentPage: null,
      totalPages: null
    };

    // this.reset = this.reset.bind(this);
    this.onChanged = this.onChanged.bind(this);
    // this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const jwtToken = localStorage.getItem("jwtToken");
    this.tembak();
    // this.props.listPmCustomer(jwtToken);
    this.props.listConstumerAdd(jwtToken);
    // if (this.props.err.error === true) {
    //   console.log("ini if error se");
    //   this.props.history.push("/dashboard");
    // }
    // this.props.listPmMachine(jwtToken);

    // this.setState({ days_input: this.props.list_pm.pm_default.days });
    // console.log("iki lho", this.props.list_pm.pm_default.days);

    // const { resp } = this.props.list_user;
    // this.setState({ resp2: resp });
  }

  componentWillReceiveProps(nextProps) {
    // const jwtToken = localStorage.getItem("jwtToken");

    // this.props.listPmCustomer(jwtToken);
    // const { pm_default } = this.props.list_pm;
    // const { id, pm_days } = nextProps.list_pm_customer;
    // this.setState({
    //   id,
    //   pm_days
    // });
    // const {
    //   list_pm_customer
    // } = this.props.list_pm;
    // this.setState({
    //   fullname: edit_user.fullname,
    //   username: edit_user.username,
    //   level_id: level.id,
    //   email: edit_user.email,
    //   mobile_phone: edit_user.mobile_phone,
    //   enabled: edit_user.enabled,
    //   department_id: departmentx.id,
    //   sap_user_code: edit_user.sap_user_code,
    //   bin_code: edit_user.bin_code,
    //   longitude: edit_user.longitude,
    //   latitude: edit_user.latitude
    // });
    this.props.list_pm.list_pm_customer = nextProps.list_pm.list_pm_customer;
    // this.setState(prevState => ({
    //   ...prevState,
    //   fault_area_codep: [...prevState.fault_area_codep, +checked_day]
    // }));
    // console.log("next", nextProps.list_pm.list_pm_customer);
    // console.log("prev", this.props.list_pm.list_pm_customer);
    // let yy;
    // if (
    //   nextProps.list_pm.list_pm_customer === nextProps.list_pm.list_pm_customer
    // ) {
    //   const yy = "asdas";
    // }
    // console.log("yy", yy);

    // console.log(
    //   "next",
    //   nextProps.list_pm.list_pm_customer.map(contact =>
    //     contact.customer.id === 9 ? contact : ""
    //   )
    // );

    this.setState({
      days_input: this.props.list_pm.pm_default.days
    });

    // console.log("days_input", this.props.list_pm);
  }
  tembak = () => {
    const jwtToken = localStorage.getItem("jwtToken");
    this.props.listPmCustomer(jwtToken);
  };
  onPageChanged = data => {
    // const { tickets } = this.state;
    const { list_pm_customer } = this.props.list_pm;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentTickets = list_pm_customer.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentTickets, totalPages });
  };

  onChanged = e => {
    this.setState({ search: e.target.value.substr(0, 20) });
    // this.setState({ [e.target.name]: e.target.value });
    // setTimeout(
    //   function() {
    //     // this.props.ticketInbox(jwtToken);
    //     this.refReactTable.fireFetchData();
    //   }.bind(this),
    //   1000
    // );
  };
  handlePmCustomer = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleDefaultDays = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  submitDefaultDays = () => {
    const saveUserData = {
      days: this.state.days_input
        ? parseInt(this.state.days_input)
        : parseInt(this.props.list_pm.pm_default.days)
    };
    const datax = {
      dat: saveUserData,
      jwtToken: localStorage.getItem("jwtToken")
    };
    // console.log("ini default kirim", datax);
    this.props.update_default_days(datax);
    // this.props.history.push("/setting/pm");
    window.location.reload();
  };
  // handleChange(event) {
  //   this.setState({ search_by: event.target.value });
  // }

  showModalMachine = (idx, day, code, name) => {
    // const { dataw } = this.props.list_pm;
    this.setState({
      showMachine: true,
      id_cust: idx,
      pm_days: day,
      code: code,
      name: name
    });

    const datacust = {
      id: idx,
      jwtToken: localStorage.getItem("jwtToken")
    };

    this.props.edit_pm_cust(datacust);
    const { dataw } = this.props.list_pm;
    // console.log("datacust");
    // console.log(code);
  };
  hideModal = () => {
    this.setState({ show: false, showMachine: false });
  };
  hideModal2 = () => {
    window.location.reload();
  };

  handleEdit = e => {
    this.setState({ pm_days: e.target.value });
  };
  reset() {
    window.location.reload();
  }

  handleDelPmCust = idx => {
    // event.preventDefault();
    // const saveUserData = {
    //   id: parseInt(idx)
    // };
    // const jwtToken = localStorage.getItem("jwtToken");
    const datax = {
      id: parseInt(idx),
      jwtToken: localStorage.getItem("jwtToken")
    };
    // console.log("ini data kirim", datax);
    this.props.del_pm_cust(datax);
    setTimeout(
      function() {
        if (this.props.list_pm.resDelPmCust) {
          if (this.props.list_pm.resDelPmCust.code === 200) {
            // window.location.reload();
            this.props.del_message();
          }
        }
      }.bind(this),
      1000
    );
    // this.props.history.push("/setting/pm");
    // window.location.reload();
  };
  handleSubmit = event => {
    event.preventDefault();
    const saveUserData = {
      pm_customer_id: this.props.list_pm.dataw.id,
      pm_days: parseInt(this.state.pm_days),
      code: this.state.code,
      name: this.state.name,
      pm_dayss: {
        // ...this.props.list_pm.dataw,
        id: this.props.list_pm.dataw.id,
        pm_days: parseInt(this.state.pm_days)
      }
    };
    // const jwtToken = localStorage.getItem("jwtToken");
    const datax = {
      dt: saveUserData,
      jwtToken: localStorage.getItem("jwtToken")
    };
    // console.log("ini data kirim", datax);
    this.props.update_pm_cust(datax);
    this.setState({
      showMachine: false
    });
    setTimeout(
      function() {
        if (this.props.list_pm.resUpdateDays) {
          if (this.props.list_pm.resUpdateDays.code === 200) {
            // window.location.reload();
            // console.log("resUpdateDays", this.props.list_pm.resUpdateDays);
            this.props.del_message();
          }
        }
        // console.log("code", this.props.list_pm.resUpdateDays);
      }.bind(this),
      1000
    );
  };
  handleSubmitPmCust = event => {
    event.preventDefault();
    const saveUserData = {
      customer_id: parseInt(this.state.customer_id),
      pm_days: parseInt(this.props.list_pm.pm_default.days)
    };
    // const jwtToken = localStorage.getItem("jwtToken");
    const datay = {
      dt: saveUserData,
      jwtToken: localStorage.getItem("jwtToken")
    };
    // console.log("ini data kirim submit cust", datay);

    this.props.store_pm_cust(datay);
    setTimeout(
      function() {
        if (this.props.list_pm.resStorePmCust) {
          if (this.props.list_pm.resStorePmCust.code === 200) {
            // window.location.reload();
            this.props.del_message();
            this.tembak();
          }
        }
      }.bind(this),
      1000
    );
    // this.props.history.push("/setting/pm");
    // window.location.reload();
  };
  backHome = () => {
    // window.location.reload();
    this.props.history.push("/setting/home");
  };
  muncul = () => {
    NotificationManager.error("Error message", "Click me!", 5000, this.mbalik);
  };
  mbalik = () => {
    window.location.reload();
  };
  render() {
    if (this.props.list_pm.list_pm_customer !== null) {
      if (this.props.list_pm.list_pm_customer.length !== 0) {
        const { errors } = this.props.err;
        // // this.setState({ resp2: resp });
        // let errored;
        // console.log("ini if error", this.props.err.error);
        if (this.props.err.error === true) {
          //   console.log("ini if error se");

          this.props.history.push("/dashboard");
        }
        const {
          list_pm_customer,
          customer,
          dataw,
          customers,
          resDelPmCust,
          pm_default,
          resStorePmCust,
          resUpdateDays
        } = this.props.list_pm;
        const { list_pm_machine } = this.props.pm_machineRed;
        if (resStorePmCust) {
          if (resStorePmCust.length !== 0) {
            NotificationManager.success(resStorePmCust.message, "Input", 1000);
          }
        }
        if (resDelPmCust) {
          if (resDelPmCust.length !== 0) {
            NotificationManager.success(resDelPmCust.message, "Delete", 1000);
          }
        }

        if (resUpdateDays) {
          if (resUpdateDays.length !== 0) {
            NotificationManager.success(resUpdateDays.message, "Update", 1000);
            // console.log("resUpdateDays.message", resUpdateDays.message, 1000);
          }
        }

        const columns = [
          {
            Header: "CUSTOMER CODE",
            columns: [
              {
                // id: "lastName",
                sortable: false,
                filterable: false,
                accessor: "customer.CustomerCode"
              }
            ]
          },
          {
            Header: "CUSTOMER NAME",
            columns: [
              {
                accessor: "customer.CustomerName",
                sortable: false,
                filterable: false,
                style: { textAlign: "center" },
                id: "CustomerName"
                // filterMethod: (filter, rows, search) =>
                //   //   // row[filter.id].startsWith(filter.value)
                //   // console.log("asd", search)
                //   // )
                //   matchSorter(rows, filter.value, {
                //     keys: ["CustomerName"]
                //   }),
                // filterAll: true
              }
            ]
          },
          {
            Header: "PM DAY",
            columns: [
              {
                sortable: false,
                filterable: false,
                accessor: "pm_days"
              }
            ]
          },
          {
            Header: "ACTION",
            // style: { textAlign: "center" },
            columns: [
              {
                accessor: "id",
                show: false
              },
              {
                sortable: false,
                filterable: false,
                Cell: ({ row }) => (
                  <div style={{ textAlign: "center" }}>
                    <a
                      data-toggle="modal"
                      data-target="#modal-add"
                      className="btn btn-primary btn-xs"
                      onClick={() =>
                        this.showModalMachine(
                          row.id,
                          row.pm_days
                          // row.customer.CustomerCode,
                          // row.customer.CustomerName
                        )
                      }
                    >
                      <i class="fa fa-fw fa-pencil"></i> Edit
                    </a>{" "}
                    <a
                      className="btn btn-danger  btn-xs"
                      // onClick={() =>
                      //   this.handleDelPmCust(person.id)
                      // }
                      onClick={() => {
                        if (
                          window.confirm("Are you sure to delete this record?")
                        ) {
                          this.handleDelPmCust(row.id);
                        }
                      }}
                    >
                      <i class="fa fa-fw fa-trash"></i> Remove
                    </a>
                    {/* <Link to={`/ticket-management/assignce/${row.id}`}>
                      <button type="button" className="btn btn-primary btn-xs">
                        SELECT
                      </button>
                    </Link> */}
                  </div>
                )
              }
            ]
          }
        ];

        // console.log("machine", list_pm_machine);
        // let notification;
        // if (resStorePmCust) {
        //   // console.log("resStorePmCust", resStorePmCust.message);
        //   if (resStorePmCust.length !== 0) {
        //     notification = (
        //       <div className="modal display-block">
        //         <div className="modal-dialog modal-sm">
        //           <div className="modal-content">
        //             <div
        //               className="modal-body text-center text-success bg-success"
        //               style={{ padding: "30px" }}
        //             >
        //               <button
        //                 type="button"
        //                 className="close"
        //                 data-dismiss="alert"
        //                 aria-hidden="true"
        //                 onClick={this.hideModal2}
        //               >
        //                 &times;
        //               </button>
        //               <i className="fa fa-check-circle fa-5x" />
        //               <br />
        //               <br />
        //               <p>{resStorePmCust.message}</p>
        //             </div>
        //           </div>
        //         </div>
        //       </div>

        //     );
        //   }
        // }
        // if (resDelPmCust) {
        //   if (resDelPmCust.length !== 0) {
        //     notification = (
        //       <div className="modal display-block">
        //         <div className="modal-dialog modal-sm">
        //           <div className="modal-content">
        //             <div
        //               className="modal-body text-center text-success bg-success"
        //               style={{ padding: "30px" }}
        //             >
        //               <button
        //                 type="button"
        //                 className="close"
        //                 data-dismiss="alert"
        //                 aria-hidden="true"
        //                 onClick={this.hideModal2}
        //               >
        //                 &times;
        //               </button>
        //               <i className="fa fa-check-circle fa-5x" />
        //               <br />
        //               <br />
        //               <p>{resDelPmCust.message}</p>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     );
        //   }
        // }

        // if (resUpdateDays) {
        //   if (resUpdateDays.length !== 0) {
        //     notification = (
        //       <div className="modal display-block">
        //         <div className="modal-dialog modal-sm">
        //           <div className="modal-content">
        //             <div
        //               className="modal-body text-center text-success bg-success"
        //               style={{ padding: "30px" }}
        //             >
        //               <button
        //                 type="button"
        //                 className="close"
        //                 data-dismiss="alert"
        //                 aria-hidden="true"
        //                 onClick={this.hideModal2}
        //               >
        //                 &times;
        //               </button>
        //               <i className="fa fa-check-circle fa-5x" />
        //               <br />
        //               <br />
        //               <p>{resUpdateDays.message}</p>
        //             </div>
        //           </div>
        //         </div>
        //       </div>

        //     );
        //   }
        // }

        // const jwtToken = localStorage.getItem("jwtToken");
        // const header = { header: { Authorization: "Bearer " + jwtToken } };

        // console.log("as");
        // console.log(list_user);
        // // const { resp } = this.props.list_user;
        // // const { resp2 } = this.state;
        // console.log("respon-nya");
        // console.log(resp);
        // if (resp) {
        // }

        // localStorage.setItem("error", resp ? resp.error : "");
        // localStorage.setItem("message", resp ? resp.message : "");
        // const { errorx } = localStorage.getItem("error");
        // const messagex = localStorage.getItem("message");
        // // const { persons } = this.state;
        const { currentTickets, currentTickets2 } = this.state;
        // console.log("currentTickets", currentTickets);
        // console.log("currentTickets2", currentTickets2);

        const { search } = this.state;
        let filtered = list_pm_customer.slice();

        if (this.state.search) {
          filtered = filtered.filter(
            ticket =>
              ticket.customer.CustomerName.toLowerCase().indexOf(
                this.state.search.toLowerCase()
              ) !== -1
          );
        }

        const totalTickets = list_pm_customer.length;
        if (totalTickets === 0) return null;
        upside = (
          <div className="panel-body form form-xs">
            <div
              className="modal fade"
              id="modal-add"
              // className={
              //   this.state.showMachine
              //     ? "modal display-block"
              //     : "modal display-none"
              // }
            >
              <div className="modal-dialog modal-sm">
                <div className="modal-content">
                  {/* <div className="modal-header"> */}
                  {/* <ModalHeader
                    onClick={this.hideModal}
                    info={
                      customer.CustomerName + " (" + customer.CustomerCode + ")"
                    }
                  /> */}
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      &times;
                    </button>
                    <h4 className="modal-title">
                      {" "}
                      {customer.CustomerName
                        ? customer.CustomerName
                        : "" + " (" + customer.CustomerCode + ")"}{" "}
                    </h4>
                  </div>

                  <div className="modal-body">
                    {/* {this.state.id_cust} */}
                    <div className="form-horizontal">
                      <div className="form-group">
                        <label className="col-sm-4 control-label">PM Day</label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Customer Name"
                            name="pm_days"
                            value={this.state.pm_days}
                            onChange={this.handleEdit}
                          />
                        </div>
                        {/* <label className="col-sm-4 control-label">vv</label> */}
                        {/* <div className="col-md-12 center-block"> */}
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-info btn-xs center-block"
                      // style={{ "text-align": "center" }}
                      data-dismiss="modal"
                      aria-hidden="true"
                      onClick={this.handleSubmit}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="pull-left">
              <p>
                <a
                  className="btn btn-danger"
                  data-toggle="collapse"
                  href="#multiCollapseExample1"
                  role="button"
                  aria-expanded="false"
                  aria-controls="multiCollapseExample1"
                >
                  Add PM
                </a>
              </p>

              <div
                className="collapse multi-collapse"
                id="multiCollapseExample1"
              >
                <fieldset>
                  <legend>ADD PM CUSTOMER</legend>

                  <div className="input-group col-sm-5">
                    <select
                      name="customer_id"
                      value={this.state.customer_id}
                      className="form-control"
                      onChange={this.handlePmCustomer}
                    >
                      <option value="">-</option>
                      {customers.map(tic => (
                        <option value={tic.id} key={tic.id}>
                          {tic.CustomerName}
                        </option>
                      ))}
                    </select>
                    <span className="input-group-btn">
                      <button
                        className="btn btn-default"
                        onClick={this.handleSubmitPmCust}
                        disabled={!this.state.customer_id}
                      >
                        <i className="fa fa-plus" />
                      </button>
                    </span>
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="pull-right form-inline">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Customer Name"
                  value={search}
                  onChange={this.onChanged}
                />
                <span className="input-group-addon">
                  <span className="fa fa-search" />
                </span>
              </div>
            </div>
            <div className="clearfix" />
            {/* {notification} */}
            <NotificationContainer />
            {/* <button onClick={this.muncul} className="btn btn-default">
              mun
            </button> */}
          </div>
        );
        downside = (
          <ReactTable
            // data={list_pm_customer}
            data={filtered}
            // PaginationComponent={PaginationServerSide}
            PaginationComponent={PaginationReactTable}
            columns={columns}
            defaultPageSize={5}
            className="-highlight"
            filterable
            previousText="<<"
            nextText=">>"
            defaultFilterMethod={(filter, row, search) =>
              String(row[filter.id]) === search
            }
            minRows={1}
            showPageSizeOptions={false}
            pageSizeOptions={[40, 200]}
            noDataText="No Matches Found"
            resizable={true}
            key="id"
          />
          // <table className="table table-xs table-bordered">
          //   <thead>
          //     <tr>
          //       <th>Customer Code</th>
          //       <th>Customer Name</th>
          //       <th>PM Day</th>
          //       <th />
          //       <th />
          //     </tr>
          //   </thead>
          //   <tbody>
          //     {search === ""
          //       ? currentTickets.map(person => (
          //           <tr key={person.id}>
          //             <td>{person.customer.CustomerCode}</td>
          //             <td>{person.customer.CustomerName}</td>
          //             <td>{person.pm_days}</td>
          //             <td>
          //               {/* <button
          //                     className="btn btn-default btn-xs"
          //                     onClick={() =>
          //                       this.showModalMachine(person.id, person.pm_days)
          //                     }
          //                   >
          //                     Edit
          //                   </button> */}
          //               <a
          //                 data-toggle="modal"
          //                 data-target="#modal-add"
          //                 className="btn btn-default btn-xs btn-block"
          //                 onClick={() =>
          //                   this.showModalMachine(
          //                     person.id,
          //                     person.pm_days,
          //                     person.customer.CustomerCode,
          //                     person.customer.CustomerName
          //                   )
          //                 }
          //               >
          //                 Edit
          //               </a>
          //             </td>
          //             <td>
          //               <button
          //                 className="btn btn-default  btn-xs"
          //                 // onClick={() =>
          //                 //   this.handleDelPmCust(person.id)
          //                 // }
          //                 onClick={() => {
          //                   if (
          //                     window.confirm(
          //                       "Are you sure to delete this record?"
          //                     )
          //                   ) {
          //                     this.handleDelPmCust(person.id);
          //                   }
          //                 }}
          //               >
          //                 Remove
          //               </button>
          //             </td>
          //           </tr>
          //         ))
          //       : filtered.map(person => (
          //           <tr>
          //             <td>{person.customer.CustomerCode}</td>
          //             <td>{person.customer.CustomerName}</td>
          //             <td>{person.pm_days}</td>
          //             <td>
          //               {/* <button
          //                     className="btn btn-default btn-xs"
          //                     onClick={() =>
          //                       this.showModalMachine(person.id, person.pm_days)
          //                     }
          //                   >
          //                     Edit
          //                   </button> */}
          //               <a
          //                 data-toggle="modal"
          //                 data-target="#modal-add"
          //                 className="btn btn-default btn-xs btn-block"
          //                 onClick={() =>
          //                   this.showModalMachine(person.id, person.pm_days)
          //                 }
          //               >
          //                 Edit
          //               </a>
          //             </td>
          //             <td>
          //               <button
          //                 className="btn btn-default  btn-xs"
          //                 // onClick={() =>
          //                 //   this.handleDelPmCust(person.id)
          //                 // }
          //                 onClick={() => {
          //                   if (
          //                     window.confirm(
          //                       "Are you sure to delete this record?"
          //                     )
          //                   ) {
          //                     this.handleDelPmCust(person.id);
          //                   }
          //                 }}
          //               >
          //                 Remove
          //               </button>
          //             </td>
          //           </tr>
          //         ))}
          //   </tbody>
          // </table>
        );
        pagi = (
          <div>
            {/* {search === "" ? (
              <div className="panel-footer form-xs">
                <Pagination
                  totalRecords={totalTickets}
                  pageLimit={4}
                  pageNeighbours={1}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            ) : (
              ""
            )} */}
          </div>
        );
      }
    }
    return (
      <div>
        {/* {this.props.err.error ? (
          { errored }
        ) : ( */}
        <SettingContainer
          pagename="Setting"
          pagedetailname="PM"
          location="pm"
          location2="customer"
        >
          <div className="panel panel-default">
            {/* {upside ? (
              ""
            ) : (
              <i
                className="fa fa-spinner fa-spin fa-2x"
                style={{
                  opacity: "0.3",
                  top: "50%",
                  left: "50%",
                  position: "absolute"
                }}
              />
            )} */}

            <div className="panel-heading">PM By Customer</div>
            <div className="col-md-6">
              <div>{upside ? upside : ""}</div>
              <div>
                {downside ? (
                  downside
                ) : (
                  <table className="table table-xs table-bordered">
                    <thead>
                      <tr>
                        <th>Customer Code</th>
                        <th>Customer Name</th>
                        <th>PM Day</th>
                        <th />
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="5" style={{ textAlign: "center" }}>
                          No Data Available in This Table
                          <i
                            className="fa fa-spinner fa-spin fa-2x"
                            style={{
                              opacity: "0.3",
                              top: "50%",
                              left: "50%",
                              position: "absolute"
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
              <div>{pagi ? pagi : ""}</div>
            </div>
          </div>
        </SettingContainer>
        {/* )} */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  list_pm: state.list_pm,
  pm_machineRed: state.pm_machineRed,
  err: state.errors
});

export default connect(
  mapStateToProps,

  {
    listPmCustomer,
    edit_pm_cust,
    update_pm_cust,
    listConstumerAdd,
    store_pm_cust,
    del_pm_cust,
    update_default_days,
    loginUser,
    del_message
    // listPmMachine
  }
)(pm);
