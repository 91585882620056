import {
  LIST_PM_CUSTOMER,
  EDIT_PM_CUST,
  UPDATE_PM_CUST,
  LIST_CUSTOMER_ADD,
  STORE_PM_CUST,
  DEL_PM_CUST,
  UPDATE_DEFAULT_DAYS,
  DEL_MESSAGE
} from "../actions/types";

const initialState = {
  list_pm_customer: [],
  customer: [],
  dataw: [],
  resUpdateDays: [],
  pm_default: [],
  customers: [],
  resDelPmCust: [],
  resUpdateDefaultDays: []
  //   level: [],
  //   save_user: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_PM_CUSTOMER:
      return {
        ...state,
        list_pm_customer: action.payload
        // departmentx: action.department,
        // level: action.level
      };
    case EDIT_PM_CUST:
      return {
        ...state,
        dataw: action.payload,
        customer: action.customer
      };
    case UPDATE_PM_CUST:
      return {
        ...state,
        resUpdateDays: action.payload,
        list_pm_customer: state.list_pm_customer.map(
          contact =>
            contact.id === action.data_send.pm_customer_id
              ? (contact = { ...contact, pm_days: action.data_send.pm_days })
              : contact
          // console.log("DDD", contact.id, action.aaa.pm_customer_id)
        )
        // list_pm_customer: []
        // console.log("DDD", list_pm_customer);
      };

    case LIST_CUSTOMER_ADD:
      return {
        ...state,
        pm_default: action.pm_default,
        customers: action.customers
      };
    case STORE_PM_CUST:
      return {
        ...state,
        resStorePmCust: action.payload
      };

    case DEL_PM_CUST:
      return {
        ...state,
        resDelPmCust: action.payload,
        list_pm_customer: state.list_pm_customer.filter(
          det => det.id !== action.data_send
        )
        // .map(
        //   // det => det
        //   console.log("DDD", action.aaa)
        // )
      };
    case UPDATE_DEFAULT_DAYS:
      return {
        ...state,
        resUpdateDefaultDays: action.payload,
        pm_default: { ...state.pm_default, days: action.data_send.days }
        // contacts: [action.payload, ...state.contacts]
      };
    case DEL_MESSAGE:
      return {
        ...state,
        resUpdateDays: action.payload,
        resDelPmCust: action.payload,
        resUpdateDefaultDays: action.payload,
        resStorePmCust: action.payload
      };

    default:
      return state;
  }
}
