import React, { Component } from "react";
import Sidebar from "../../../layout/Sidebar";
import Navbar from "../../../layout/Navbar"
import { connect } from "react-redux";
import { get_spr_recon_list, post_submit, print_spr_recon } from "../../../../actions/sprReconAction";
import TextInputDisabled from "../../../common/TextInputDisabled";
// import TextInputGroup from "../../common/TextInputGroup";
// import TextInputDisabled from "../../common/TextInputDisabled";
import TrackingTicket  from "../TrackingTicket";

import TextAreaInput from "../../../common/TextAreaInput";
import moment from "moment";
import Footer from "../../../layout/Footer";
import {
    NotificationContainer,
    NotificationManager
  } from "react-notifications";
  import PrintTemplate from "react-print";
  import logo_konica from "../../../../img/logo-konica.png";
let left = "";
let right = "";

const tableStyle = {
    border: "none"
  };
  const sss ={
    
    position: "fixed",
    // left: "0",
    bottom: "0",
    width: "100%",
    
    
  }

class SprReconciliation extends Component {
    constructor(props) {
        super(props);
        this.state = {
          ticket_type: [],
          ticket_status: [],
          search: "",
          ticket_pic: [],
          ticket_stat_pic: [],
          currentTickets: [],
          currentPage: null,
          totalPages: null,
          start_date: [],
          end_date: []

        };

      }
      componentDidMount() {
        const { id } = this.props.match.params;
        const data = {
          id: id,
          jwtToken: localStorage.getItem("jwtToken")
        };
        this.props.get_spr_recon_list(data);
        
      }

      postSubmit = e => {
        const { id } = this.props.match.params; 
        const valueData = {
            ticket_tracking_id: id
        };
        const data = {
          id: valueData,
          jwtToken: localStorage.getItem("jwtToken")
        };
        this.props.post_submit(data);
      }
      printContent = () => {
        //   const datay = this.props.spr_Reducer.order_db.id;
        const { id } = this.props.match.params;
          const datay = {
            id: id,
            jwtToken: localStorage.getItem("jwtToken")
        
         };
            this.props.print_spr_recon(datay);
            setTimeout(
                function() {
                window.print();
                    
                }.bind(this),
                3000
              );
             
          };
          showModalMachine = () => {
            document.body.style.overflow = "hidden";
            this.setState({ showModal: true });
          };
          hideModal = () => {
            document.body.style.overflow = "unset";
            this.setState({ showModal: false });
          };
  render() {
    if (this.props.spr_Reducer.originator !== null) {
        if (this.props.spr_Reducer.originator.length !== 0) {
      const {spr, 
        department, 
        originator, 
        action_by, 
        ticket_origin, 
        machine_customer,
        customers,
        machine, 
        machine_models,
        customer,
  technician,
  blocked_by,
  contracts,
  sales,
  service_code,
  part_replace,
  checkStock, resPostSubmit, data_spr_recon, contact_detail
    } =this.props.spr_Reducer;

    // console.log("pr", data_spr_recon );
    if (resPostSubmit.length !== 0) {
        if (resPostSubmit.code === 200) {
          NotificationManager.success(
            resPostSubmit.message,
            "Submit Reconciliation", 2000
          );
        }else{NotificationManager.error(
            resPostSubmit.message,
            "Submit Reconciliation", 2000
          );}

      };
    left = (
<div className="col-md-6">
                    <form action="create-new-origination-ticket.html">

                    <div className="panel panel-default">
                        <div className="panel-heading">
                            Originator
                        </div>
                        <div className="panel-body">
                            <div className="form form-xs">
                                <div className="row row-xs">
                                    <div className="col-md-6">
                                        <TextInputDisabled label="Departement" value={department.name}/>
                                        <TextInputDisabled label="Date/Time Created" value={moment(originator.created_at).format("L")}/>
                                    </div>
                                    <div className="col-md-6">
                                        <TextInputDisabled label="Name" value={originator.fullname}/>
                                        <TextInputDisabled label="User ID" value={originator.id}/>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            Contact Details
                        </div>
                        <div className="panel-body">
                            <div className="form form-xs">
                                <div className="row row-xs">
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label">Date/Time Contact</label>
                                            <input type="text" className="form-control" value="2019-02-03 09:43" disabled />
                                        </div>  
                                    </div> */}
                                    <div className="col-md-8">
                                    <div className="form-group">
                                            <label className="control-label" style={{ textAlign: "left", width: "70%" }}>Date/Time Contact</label>
                                            <input type="text" className="form-control" value={contact_detail.date_of_contact} disabled />
                                        </div>  
                                        <div className="form-group">
                                            <label className="control-label" style={{ textAlign: "left", width: "70%" }}>Contact Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={contact_detail.gender}
                                                style={{ width: "20%" }}
                                                disabled
                                            />
                                            
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={contact_detail.contact_name}
                                                disabled
                                                style={{ width: "80%" }}
                                            />
                                        </div>  
                                        <div className="form-group">
                                            <label className="control-label" style={{ textAlign: "left", width: "70%" }}>Channel of Contact</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={contact_detail.type_channel_of_contact}
                                                style={{ width: "45%" }}
                                                disabled
                                            />
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={contact_detail.channel_of_contact}
                                                disabled
                                                style={{ width: "55%" }}
                                            />
                                        </div> >  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel panel-default">
                        <div className="panel-heading">
                            Customer Information
                        </div>
                        <div className="panel-body">
                            <div className="form form-xs">
                                <div className="row row-xs">
                                    <div className="col-md-6">
                                        <fieldset>
                                            <legend>Search By</legend>
                                            <div className="form-group">
                                                <label className="control-label">Serial No</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={machine.machine_serial ? machine.machine_serial : ""} disabled/>
                                                    <span className="input-group-btn">
                                                        <button className="btn btn-default" disabled><i className="fa fa-search"></i></button>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label">Equipment No</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={machine_customer.item_no ? machine_customer.item_no : "" } disabled />
                                                    <span className="input-group-btn">
                                                        <button className="btn btn-default" disabled><i className="fa fa-search"></i></button>
                                                    </span>
                                                </div>
                                            </div>  
                                            <div className="form-group">
                                                <label className="control-label">Customer Name</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={customers.CustomerName ? customers.CustomerName : ""} disabled />
                                                    <span className="input-group-btn">
                                                        <button className="btn btn-default" data-toggle="modal" data-target="#modal-search-name" type="button" disabled><i className="fa fa-search"></i></button>
                                                        <button className="btn btn-default" data-toggle="modal" data-target="#modal-customer-multi" type="button" disabled>Multi</button>
                                                    </span>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <TextInputDisabled label="Model" value={machine_models.model_name}/>
                                        <TextInputDisabled label="Customer Category" value={customers.customer_category}/>
                                        <TextInputDisabled label="Preferred CE" value={technician.name}/>
                                        <fieldset>
                                            <legend>SHIP TO</legend>
                                        <TextInputDisabled label="Ship to Name" value={machine_customer.ship_to_name}/>
                                        <TextInputDisabled label="Ship to Code" value={machine_customer.ship_to_code}/>
                                        <TextInputDisabled label="Ship to Contact" value={machine_customer.ship_to_contact_name}/>
                                        </fieldset>
                                        <fieldset>
                                            <legend>CSRC</legend>
                                        <TextInputDisabled label="CSRC Status" value={customers.csrc_status}/>
                                        <TextInputDisabled label="Last Date of Connection" value={""}/>
                                        </fieldset>
                                    </div>

                                    <div className="col-md-6">
                                        <fieldset>
                                            <legend>BLOCK BY</legend>
                                        <TextInputDisabled label="Status" value={customers.blocked_status === 1 ? "BLOCKED" : ""}/>
                                        <TextInputDisabled label="Department" value={blocked_by.blocked_by_department ? blocked_by.blocked_by_department : ""}/>
                                        <TextInputDisabled label="Name" value={blocked_by.blocked_by_user_name ? blocked_by.blocked_by_user_name : ""}/>
                                        <TextInputDisabled label="User ID" value={blocked_by.blocked_by_user_id ? blocked_by.blocked_by_user_id : ""}/>
                                        </fieldset>
                                        <fieldset>
                                            <legend>Contract Detail</legend>
                                        <TextInputDisabled label="Contract No" value={contracts.contract_number ? contracts.contract_number : ""}/>
                                        <TextInputDisabled label="End Date" value={contracts.contract_end_date ? moment(contracts.contract_end_date).format("L") : ""}/>

                                        </fieldset>
                                       
                                        <fieldset>
                                            <legend>Sales Details</legend>
                                        <TextInputDisabled label="Sales Person Code" value={sales.Sales_Person_Code ? sales.Sales_Person_Code : ""}/>
                                        <TextInputDisabled label="Sales Person Name" value={sales.Sales_Person_name ? sales.Sales_Person_name : ""}/>
                                        <TextInputDisabled label="DSM Code" value={sales.dsm_code ? sales.dsm_code : ""}/>
                                        <TextInputDisabled label="DSM Name" value={sales.dsm_name ? sales.dsm_name : ""}/>
                                        </fieldset>
                                    </div>
                                </div>

                                <fieldset>
                                    <legend>Customer Details</legend>
                                    <TextAreaInput label="Ship to Address" value={machine_customer.ship_to_address ? machine_customer.ship_to_address : ""} disabled="disabled"/>
                                    <TextAreaInput label="Location of Equipment No" value={machine_customer.location ? machine_customer.location : ""} disabled="disabled"/>
                                    <div className="row row-xs">
                                        <div className="col-md-6">
                                            {/* <TextInputDisabled label="Telephone No" value={customers.customer_phone1 ? customers.customer_phone1 : ""}/> */}
                                            <div className="form-group" style={{display: "flex"}}>
                                                <label className="control-label ">Telephone No</label>
                                                <div className="col-sm-offset-1 col-sm-1">
                                                <input type="text" className="form-control" value={customers.customer_phone1 ? customers.customer_phone1 : ""} disabled /></div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                        <TextInputDisabled label="Postal Code" value={customers.ship_to_postcode ? customers.ship_to_postcode : ""}/>
                                        <TextInputDisabled label="Delivery Date" value={machine_customer.delivery_date ? machine_customer.delivery_date : ""}/>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend>SLA DETAILS</legend>
                                    <TextInputDisabled label="SLA Response Time" value={customers.sla_details ? customers.sla_details : ""}/>
                                    <TextAreaInput label="Additional SLA Detail" disabled="disabled" value=""/>
                                    
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className="text-right">
                        <button className="btn btn-warning btn-sm" type="submit">TICKET IN PROGRESS</button>
                        <button className="btn btn-default btn-sm" type="submit">CUSTOMER HISTORY</button>
                    </div>
                    </form>
                </div>
    );
    right = (
<div className="col-md-6">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            Ticket Number : {spr.ticket_number ? spr.ticket_number : ""}
                            <NotificationContainer />
                        </div>
                        <div className="panel-body">
                            <div className="form form-xs">
                                <div className="form-inline form-group">
                                    <label style={{width: "50px"}}>From</label>
                                    <input type="text" className="form-control" value={action_by.fullname} disabled style={{width: "100px"}}/>
                                    <label style={{width: "20px"}}>To</label>
                                    <input type="text" className="form-control" value="Spare Parts" disabled style={{width: "100px"}}/>
                               
                                    <label style={{width: "90px"}}>Requirement Process</label>
                                    <input type="text" className="form-control" value="Reconciliation" style={{width: "100px"}} disabled/>{" "}
                                    {/* <button className="btn btn-default">Track Ticket</button> */}

                                    <button
                                    className="btn btn-default"
                                    style={{ width: "100px" }}
                                    onClick={this.showModalMachine}
                                    >
                                    Track Ticket
                                    </button>
                                    <TrackingTicket 
                                   showModal={this.state.showModal}
                                   hideModal={this.hideModal}
                                   datas={ticket_origin.id}
                                   />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="panel panel-default">
                        <div className="panel-heading">Sparepart Requisition</div>
                        <div className="panel-body">
                            <div className="form form-xs">
                                <div className="row row-xs">
                                    <div className="col-md-6">
                                        <TextInputDisabled label="SPR No" value={spr.spr_number ? spr.spr_number : ""}/>
                                        <TextInputDisabled label="Service Order Type" value={service_code.job_code} />
                                        <TextInputDisabled label="Date" value={spr.created_at}/>
                                    </div>
                                    <div className="col-md-6">
                                        <TextInputDisabled label="CE Code" value={technician.code}/>
                                        <TextInputDisabled label="CE Name" value={technician.name}/>
                                        <TextInputDisabled label="Approved By" value={technician.superior_code}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="panel panel-default">
                        <div className="panel-heading">Sparepart Requisition
                        
                        </div>
                        <div className="form form-xs">
                        {/* <nav className="tabs-xs">
                                    <ul className="nav nav-tabs">
                                        <li className="active"><a href="#">Reconciliation</a></li>
                                        <li><a href="#">Internal Note</a></li>
                                    </ul>
                                </nav> */}
                                <fieldset>
                                <div className="row row-xs">
                                    <div className="col-md-6">
                                        {/* <TextInputDisabled label="SPR No" value={spr.spr_number ? spr.spr_number : ""}/> */}
                                        <TextInputDisabled label="Technician Name" value={technician.name}/>
                                        {/* <TextInputDisabled label="Service Order Type" value={service_code.job_code} /> */}
                                        <TextInputDisabled label="Date of Report" value={spr.created_at}/>
                                    </div>
                                    <div className="col-md-6">
                                        <TextInputDisabled label="Technician Code" value={technician.code}/>
                                        {/* <TextInputDisabled label="CE Name" value={technician.name}/> */}
                                        <TextInputDisabled label="Verified By" value={technician.superior_code}/>
                                    </div>
                                </div>
                        <div>

                        <table className="table table-bordered table-xs" >
                            <thead>
                            <tr>
                                <th>No</th>
                                <th>Part No</th>
                                <th>Type</th>
                                <th>Description</th>
                                <th>New</th>
                                <th>Old</th>
                                
                                
                                <th>Usage</th>
                                <th>Change Part?</th>
                            </tr>
                            </thead><tbody>
                            {part_replace.map(person => (
                            <tr>
                                <td>1</td>
                                <td>{person.item_number}</td>
                                <td>{"person.description"}</td>
                                <td>{person.description}</td>
                                <td>0</td>
                                <td>{person.quantity}</td>
                                
                                
                                <td>
                                    {"Returned"}
                                </td>
                                <td className="text-center">
                                <i className="fa fa-fw fa-check"></i>
                                    {/* <input type="checkbox" /> */}
                                </td>
                            </tr>
                            ))}
                            
                            </tbody>
                        </table></div>
                        <div className="form form-xs" style={{textAlign: "right"}}>
                        
                                        <button className="btn btn-default" onClick={this.printContent}>PRINT</button>
                                        <button className="btn btn-default" onClick={this.postSubmit}>POST & SUBMIT</button>
                                        </div>  
                                        </fieldset>    
                    </div>
                   
                    </div>
                    {/* <div className="panel panel-default">
                        <div className="panel-heading">Transfer Order</div>
                        <div className="panel-body">
                            <div className="form form-xs">
                                <div className="row row-xs">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">Transfer Order</label>
                                            <input type="text" className="form-control" disabled />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">Stock (From)</label>
                                            <input type="text" className="form-control" disabled /> 
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">Stock (To)</label>
                                            <input type="text" className="form-control" disabled />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">in Transit Code</label>
                                            <input type="text" className="form-control" disabled />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">Movement Type</label>
                                            <input type="text" className="form-control" disabled />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">Status</label>
                                            <select name="" id="" className="form-control">
                                                <option value="">OPEN</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">Plant</label>
                                            <input type="text" className="form-control" disabled />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">Comment</label>
                                            <textarea name="" id="" cols="30" rows="5" className="form-control" disabled></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <table className="table table-xs">
                        <tbody>
                            <tr>
                                <td><button className="btn btn-default btn-block btn-xs">SUBMIT</button></td>
                                <td><button className="btn btn-default btn-block btn-xs" disabled>PRINT</button></td>
                                <td><button className="btn btn-default btn-block btn-xs">CREATE NOTE</button></td>
                                <td><button className="btn btn-default btn-block btn-xs">SAVE</button></td>
                                <td><button className="btn btn-default btn-block btn-xs">RESET</button></td>
                                <td><button className="btn btn-default btn-block btn-xs">ESC</button></td>
                            </tr>
                        </tbody>
                    </table> */}
                </div>
    );
        }}
    return (
      <div id="app"><div id="react-no-print">
        <Navbar
          information="TICKET MANAGEMENT"
          detail="Process Ticket - SPR Reconciliation"
        />
        <Sidebar information="TicketManagement" /></div>
        <div id="main" className="main-xs">
        {/* <div id="react-no-print"> */}
            {/* <div className="row row-xs">
                <div className="col-md-12 text-right">
                    <a href="#" className="btn btn-warning"><i className="fa fa-plus"></i> Create Note</a>
                </div>
            </div>
            <br/> */}

{/* </div> */}
            <PrintTemplate>
             <div><div id="print-mount" style={{textAlign: "center"}}> 
             <h6>KONICA MINOLTA</h6>
                     <h6>Sparepart Reconciliation Table</h6>
             {/* <table className={tableStyle}>
                 <tbody>
                 <tr>
                     <td style={{width: "20%"}}><img src={logo_konica} style={{height:"10%", width: "90%"}} /></td>
                     <td style={{width: "30%"}}>
                     <h6>KONICA MINOLTA</h6>
                     <h6>BUSINESS SOLUTIONS (M)</h6>
                     <h6>SDN BHD (72640)</h6><br/>
                     <h6>Fax : -</h6>
                     <h6>Tel : -</h6>

                     </td>
                     <td style={{width: "50%", border: "none"}} >
                         
                             <td style={{width: "70%", border: "none", fontSize: "10px"}}>
                             <p>STN No.</p> 
                             <p>Date</p>
                             <p>Reason Code</p>
                             <p>Movement Type</p>
                             <p>Where House Shipment No.</p>
                             
                             <p>Sales Order No.</p>
                             <p>Req. Delivery Note</p>
                             
                             <p>Stock Room</p>
                             </td>
                             <td style={{width: "30%",  border: "none", fontSize: "9px"}}>
                 
                             <p>: </p>
                             <p>: </p>
                             <p>: A7UTE011</p>
                             </td>
                         
                     </td>
                     
                 </tr>
                 <tr>
                         <td style={{width: "50%", fontSize: "10px"}} colSpan="2">Issued To: A7UTE011,Shaharuddin Bin Abdullah<br/>
                         Infinite Centre, Lot 1, Jalan 13/6, 46200 Petaling Jaya, Selangor, Malaysia
                         </td>
                         <td style={{width: "50%", fontSize: "10px"}} colSpan="2">Issued By: PJ SPAREPART STOCK ROOM<br/>
                         Infinite Centre, Lot 1, Jalan 13/6, 46200 Petaling Jaya, Selangor, Malaysia
                         </td>
                     </tr>
                 </tbody>
             </table> */}
             <table className="table table-bordered">
             <thead>
                     <tr>
                         <td style={{width: "10%"}}>No</td>
                         <td style={{width: "20%"}}>Item No. </td>
                         <td style={{width: "20%"}}>Item Description</td>
                         <td style={{width: "10%"}}>Old</td>
                         <td style={{width: "10%"}}>New Qty</td>
                         <td style={{width: "10%"}}>Refurbish Qty</td>
                         <td style={{width: "10%"}}>Qty</td>
                         <td style={{width: "10%"}}>Usage</td>
                     </tr>
                 </thead>
                 <tbody>
                 {this.props.spr_Reducer.data_spr_recon ? this.props.spr_Reducer.data_spr_recon.map((person, index) => (
                     <tr>
                         <td >{(index+1)}</td>
                         <td >{person.item_number ? person.item_number : "-"}</td>
                         <td >{person.description ? person.description : "-"}</td>
                         <td >{person.old ? person.old : "-"}</td>
                         <td >{person.new ? person.new : "-"}</td>
                         <td >{" "}</td>
                         <td >{person.quantity ? person.quantity : "-"}</td>
                         <td >{" "}</td>
                     </tr>
                     )) : ""}
                 </tbody>
             </table>
             {/* <div style={sss}>
                 <table>
                     <tbody>
                         <tr>
                             <td style={{width: "36%", textAlign:"left", fontSize: "8px"}}>
                                <p>TRADEWINDS PLANTATION MANAGEMENT</p>
                                <p>SDN BHD ,THE CAPERS SENTUL, LEVEL 11, UNIT 6, LORONG TIMURSENTUL SELATAN,</p>
                                <p>testing</p>
                                <p>Tehnician : Shaharuddin Bin Abdullah, Model : C258 COPIER/PRINTER , Serial : A7R0041003321</p>
                                <p>Updated By : PJSP001</p>
                            </td>
                            <td style={{width: "32%", textAlign:"left", fontSize: "9px"}}>
                                <p>RECEIVED BY</p><br/><br/>
                                <p>_______________________________</p>
                                <p>Date</p><br/><br/><br/>
                            </td>
                            <td style={{width: "32%", textAlign:"left", fontSize: "9px"}}>
                                <p>APPROVED BY</p><br/><br/>
                                <p>_______________________________</p>
                                <p>Date</p><br/><br/><br/>
                            </td>
                         </tr>
                         
                     </tbody>
                 </table>
                 <div style={{ fontSize: "9px"}}>Printed on Konica Minolta Multi-Functional Printer</div>
      </div> */}
             
                  </div>
             </div>
           </PrintTemplate>
           <div id="react-no-print">

            <div className="row row-xs">
                <div>{left ? "" : (
                    <i
                    className="fa fa-spinner fa-spin fa-3x"
                    style={{
                      opacity: "0.3",
                      top: "50%",
                      left: "50%",
                      position: "absolute"
                    }}
                  />
                )}</div>
                <div>{left ? left : ""}</div>
                <div>{right ? right : ""}</div>
            </div></div>
            
        </div>
        <div id="react-no-print"> <Footer/></div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
    spr_Reducer: state.spr_reconReducer
  });
  export default connect(
    mapStateToProps,
    {
        get_spr_recon_list, post_submit, print_spr_recon
    }
  )(SprReconciliation);


