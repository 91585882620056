import { GET_ERRORS, SET_DELETE_LOGIN } from "../actions/types";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return action.payload;

    case SET_DELETE_LOGIN:
      return action.payload;
    default:
      return state;
  }
}
