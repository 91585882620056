import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../layout/Navbar";
import DatePicker, { subDays } from "react-datepicker";
import Sidebar from "../../layout/Sidebar";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import ModalCustomer from "../../common/ModalCustomer";
import ModalMachine from "../../common/ModalMachine";
import ModalHeader from "../../common/ModalHeader";
import ModalFooter from "../../common/ModalFooter";
import { connect } from "react-redux";
import moment from "moment";
import { listUser } from "../../../actions/getUserActions";
import { detailHistory } from "../../../actions/historyAction";
import NumberFormat from "react-number-format";

class AssignCEHis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickets_det: [],
      originator_des: [],
      originator_des_dep: [],
      originator: [],
      customer: [],
      ticket_details: [],

      call_information: [],
      service_code: [],
      machine: [],
      runing: [],
      yes: [],
      no: [],
      button: [],
      yaho: [],
      action_by: [],
      value: [],
      input_otp: "",
      id_otp: "",
      ott: "",
      long: "",
      lat: "",
      respx: []
    };
    this.handleDisabled = this.handleDisabled.bind(this);
    this.handleEnabled = this.handleEnabled.bind(this);
    this.handleAssignCE = this.handleAssignCE.bind(this);
    this.handleSaveAssignCE = this.handleSaveAssignCE.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    // this.input_otp = this.input_otp.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const jwtToken = localStorage.getItem("jwtToken");

    const data = {
      id: id,
      jwtToken: localStorage.getItem("jwtToken")
    };
    this.props.detailHistory(data);

    this.props.listUser(jwtToken);

    // console.log(this.props.assign_ce.assign_ce);
    // const { assign_ce } = this.props.assign_ce.assign_ce;
  }
  handleAssignCE = e => this.setState({ [e.target.name]: e.target.value });
  input_otp = e => this.setState({ [e.target.name]: e.target.value });
  handleDisabled(event) {
    event.preventDefault();
    // const { value_otp } = this.state.input_otp.replace(/ /g, "");
    const saveUserData = {
      input_otp: parseInt(this.state.input_otp.split(" ").join(""))
    };

    const datax = {
      dt: saveUserData,
      id_otp: this.props.assign_ce.assign_ce.id,
      jwtToken: localStorage.getItem("jwtToken")
    };
    // console.log("otp");
    // console.log(datax);
    this.props.saveOtp(datax);
    // this.props.history.push("/setting");

    this.setState({ button: "disabled", ott: this.state.input_otp });
  }
  handleSaveAssignCE(event) {
    event.preventDefault();

    this.props.history.push("/ticket-management");
  }

  handleEnabled(event) {
    this.setState({ button: "ss" });
  }
  handleSubmit(event) {
    this.setState({ yaho: "ppppp" });
    // console.log(this.state.yaho);

    this.props.history.push("/ticket-management");
  }
  handleChange(value) {
    this.setState({ valueOfInput: value });
  }
  // handleChange2(event) {
  //   this.setState({ id_ce: event.target.value });
  // }
  handleChange2 = e => this.setState({ [e.target.name]: e.target.value });

  save(e) {
    e.preventDefault();
    // const { dt } = this.state;
    // const userData = {
    const we = this.state.id_ce;
    const sa = we.split("#");
    const id_ce = sa[0];
    const name_ce = sa[1];

    // const name_ce = this.state.name_ce;
    // const enable_ce = this.state.enable_ce;
    const enable_ce = sa[2];

    // };
    this.state.value.push({
      id_ce: id_ce,
      name_ce: name_ce,
      enable_ce: enable_ce
    });

    // this.state.value.push(this.state.name_ce);
    // this.state.values.push(this.state.enable_ce);

    this.setState({ value: this.state.value });
    // this.state;
    // console.log("vale");
    // console.log(this.state.value[id_ce]);
  }
  showModalMachine = () => {
    this.setState({ showMachine: true });
  };
  hideModal = () => {
    this.setState({ show: false, showMachine: false });
  };
  showModalMachine2 = () => {
    this.setState({ showMachine2: true });
  };
  hideModal2 = () => {
    this.setState({ show2: false, showMachine2: false });
  };

  render() {
    const { value } = this.state;
    const { action_by } = this.props.action_by;
    const levels = localStorage.getItem("level");
    // console.log("action_by");
    // console.log(action_by);
    const {
      ticket_origin,
      originator,
      departments,
      machine_customer,
      machine,
      customers,
      blocked_by,
      machine_models,
      technician,
      shipping,
      ticket_tracking, contact_detail, contracts, sales
    } = this.props.historyRed;

    const now = moment();
    const { button, id_ce, yaho, name_ce, enable_ce, ott } = this.state;
    // const { list_user } = this.props.yyy;

    //----------------------------cara split-------------------------------
    const str = "How are you doing today?";
    const spl = str.split(" ").join("");
    // console.log("jajal replace");
    // console.log(spl);

    const we2 = this.state.id_ce ? this.state.id_ce : "";
    const sa2 = we2.split("#");
    const av = sa2[2] === "1" ? "Aviliable" : "Not Aviliable";
    const long = sa2[3] === undefined ? "" : sa2[3];
    const lat = sa2[4] === undefined ? "" : sa2[4];

    // let filtered = ce.slice();
    // if (this.state.id_ce) {
    //   filtered = filtered.filter(
    //     ticket => ticket.id.toString().indexOf(sa2[0].toLowerCase()) !== -1
    //   );
    // }
    // const { runing } = this.props.machine.machine_running;
    // const { yes } = { runing } === "1" ? "checked" : "";
    // const { no } = { runing } === "0" ? "checked" : "";

    // const {
    //   tickets_det,
    //   originator_des,
    //   originator_des_dep,
    //   originator,
    //   customer,
    //   ticket_details
    // } = this.state;
    // console.log(tickets_det);
    return (
      <div id="app">
        <Navbar
          icon="fa fa-ticket icon"
          information="TICKET MANAGEMENT"
          detail="Assign CE"
        />
        <Sidebar information="TicketManagement" />

        <div id="main" className="main-xs">
      

          <div className="row row-xs">
            <div className="col-md-6">
              <form action="create-new-origination-ticket.html">
                {/* <div className="panel panel-default">
                  <div className="panel-heading">ORIGINATOR</div>
                  <div className="panel-body">
                    <div className="form form-xs">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="control-label">Department</label>
                            <input
                              type="text"
                              className="form-control"
                              value={departments.name ? departments.name : ""}
                              disabled
                            />
                          </div>
                          <div className="form-group">
                            <label className="control-label">
                              Date Time Created
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              //   value={moment(acting.created_at).format("L")}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="control-label">Name</label>
                            <input
                              type="text"
                              className="form-control"
                              value={originator.fullname ? originator.fullname : ""}
                              disabled
                            />
                          </div>
                          <div className="form-group">
                            <label className="control-label">User ID</label>
                            <input
                              type="text"
                              className="form-control"
                              value={originator.id ? originator.id : ""}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="panel panel-default">
                  <div className="panel-heading">CONTACT DETAIL</div>
                  <div className="panel-body">
                    <div className="form form-xs">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="control-label">
                              Date Time Contact
                            </label>
                            <input
                              type="text"
                              className="form-control"
                                value={moment(contact_detail.date_of_contact).format("L")}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group form-inline">
                        <label
                          className="control-label col-sm-6"
                          style={{ textAlign: "left" }}
                        >
                          Contact Name
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            value={contact_detail.gender}
                            style={{ width: "20%" }}
                            disabled
                          />
                          
                          <input
                            type="text"
                            className="form-control"
                            value={contact_detail.contact_name}
                            disabled
                            style={{ width: "80%" }}
                          />
                        </div>
                      </div>
                      <div className="form-group form-inline">
                        <label
                          className="control-label col-sm-6"
                          style={{ textAlign: "left" }}
                        >
                          Channel of contact
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            value={contact_detail.type_channel_of_contact}
                            style={{ width: "45%" }}
                            disabled
                          />
                          <input
                            type="text"
                            className="form-control"
                            value={contact_detail.channel_of_contact}
                            disabled
                            style={{ width: "55%" }}
                          />
                        </div>
                      </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="panel panel-default">
                  <div className="panel-heading">Customer Information</div>
                  <div className="panel-body">
                    <div className="form form-xs">
                      <div className="row row-xs">
                        <div className="col-md-6">
                          <fieldset>
                            <legend>Search By</legend>
                            <div className="form-group">
                              <label className="control-label">Serial No</label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={machine.machine_serial ? machine.machine_serial : ""}
                                  disabled
                                />
                                <span className="input-group-btn">
                                  <button className="btn btn-default">
                                    <i className="fa fa-search" />
                                  </button>
                                </span>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label">
                                Equipment No
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={machine_customer.item_no ? machine_customer.item_no : ""}
                                  disabled
                                />
                                <span className="input-group-btn">
                                  <button className="btn btn-default">
                                    <i className="fa fa-search" />
                                  </button>
                                </span>
                              </div>
                            </div>
                            {/* <div className="form-group">
                              <label className="control-label">
                                Notification No
                              </label>
                              <div className="input-group">
                                <input type="text" className="form-control" />
                                <span className="input-group-btn">
                                  <button className="btn btn-default">
                                    <i className="fa fa-search" />
                                  </button>
                                </span>
                              </div>
                            </div> */}
                            <div className="form-group">
                              <label className="control-label">
                                Customer Name
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={customers.CustomerName ? customers.CustomerName : ""}
                                  disabled
                                />
                                <span className="input-group-btn">
                                  <button
                                    className="btn btn-default"
                                    data-toggle="modal"
                                    data-target="#modal-search-name"
                                    type="button"
                                  >
                                    <i className="fa fa-search" />
                                  </button>
                                  <button
                                    className="btn btn-default"
                                    data-toggle="modal"
                                    data-target="#modal-customer-multi"
                                    type="button"
                                  >
                                    Multi
                                  </button>
                                </span>
                              </div>
                            </div>
                          </fieldset>
                          <div className="form-group">
                            <label className="control-label">Model</label>
                            <input
                              type="text"
                              className="form-control"
                              value={machine_models.model_name ? machine_models.model_name : ""}
                              disabled
                            />
                          </div>
                          <div className="form-group">
                            <label className="control-label">
                              Customer Category
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={customers.customer_category ? customers.customer_category : ""}
                              disabled
                            />
                          </div>
                          <div className="form-group">
                            <label className="control-label">Prefered CE</label>
                            <input
                              type="text"
                              className="form-control"
                              value={technician.code ? technician.code : ""}
                              disabled
                            />
                          </div>
                          <fieldset>
                            <legend>SHIP TO</legend>
                            <div className="form-group">
                              <label className="control-label">
                                Ship to Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={shipping.ship_to_name ? shipping.ship_to_name : ""}
                                disabled
                              />
                            </div>
                            <div className="form-group">
                              <label className="control-label">
                                Ship to Code
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={shipping.ship_to_code ? shipping.ship_to_code : ""}
                                disabled
                              />
                            </div>
                            <div className="form-group">
                              <label className="control-label">
                                Ship to Contact
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={shipping.ship_to_contact ? shipping.ship_to_contact : ""}
                                disabled
                              />
                            </div>
                          </fieldset>
                          {/* <fieldset>
                            <legend>SOLD TO</legend>
                            <div className="form-group">
                              <label className="control-label">
                                Sold to Name
                              </label>

                              <input
                                type="text"
                                className="form-control"
                                value={customers.sold_to_name ? customers.sold_to_name : ""}
                                disabled
                              />
                            </div>
                            <div className="form-group">
                              <label className="control-label">
                                Sold to Code
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={customers.sold_to_code ? customers.sold_to_code : ""}
                                disabled
                              />
                            </div>
                            <div className="form-group">
                              <label className="control-label">
                                Sold to Contact
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={customers.sold_to_contact ? customers.sold_to_contact : ""}
                                disabled
                              />
                            </div>
                          </fieldset> */}
                          <fieldset>
                            <legend>CSRC</legend>
                            <div className="form-group">
                              <label className="control-label">
                                CSRC Status
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={customers.csrc_status ? customers.csrc_status : ""}
                                disabled
                              />
                            </div>
                            <div className="form-group">
                              <label className="control-label">
                                Last Date of Connection
                              </label>
                              <input type="text" className="form-control" disabled/>
                            </div>
                          </fieldset>
                        </div>

                        <div className="col-md-6">
                          <fieldset>
                            <legend>BLOCK BY</legend>
                            <div className="form-group">
                              <label className="control-label">Status</label>
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  customers.blocked_status === null
                                    ? "-"
                                    : "blocked"
                                }
                                disabled
                              />
                            </div>
                            <div className="form-group">
                              <label className="control-label">
                                Department
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={blocked_by.blocked_by_department ? blocked_by.blocked_by_department : ""}
                                disabled
                              />
                            </div>
                            <div className="form-group">
                              <label className="control-label">Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={blocked_by.blocked_by_user_name ? blocked_by.blocked_by_user_name : ""}
                                disabled
                              />
                            </div>
                            <div className="form-group">
                              <label className="control-label">User ID</label>
                              <input
                                type="text"
                                className="form-control"
                                value={blocked_by.blocked_by_user_id ? blocked_by.blocked_by_user_id : "-"}
                                disabled
                              />
                            </div>
                          </fieldset>
                          <fieldset>
                            <legend>Contract Detail</legend>
                            <div className="form-group">
                              <label className="control-label">
                                Contract No
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={contracts.contract_number ? contracts.contract_number : "-"}
                                disabled
                              />
                            </div>
                            {/* <div className="form-group">
                              <label className="control-label">
                                Start Date
                              </label>
                              <input type="date" className="form-control" />
                            </div> */}
                            <div className="form-group">
                              <label className="control-label">End Date</label>
                              <input
                                type="text"
                                className="form-control"
                                value={moment(
                                  contracts.contract_end_date
                                ).format("L")}
                                disabled
                              />
                            </div>
                            {/* <div className="form-group">
                              <label className="control-label">User ID</label>
                              <input
                                type="text"
                                className="form-control"
                                // value={contracts.id}
                                disabled
                              />
                            </div> */}
                          </fieldset>
                          {/* <fieldset>
                            <legend>Machine Extend</legend>
                            <div className="form-group">
                              <label className="control-label">
                                Start Date
                              </label>
                              <input type="date" className="form-control" />
                            </div>
                            <div className="form-group">
                              <label className="control-label">End Date</label>
                              <input type="date" className="form-control" />
                            </div>
                          </fieldset> */}
                          <fieldset>
                            <legend>Sales Details</legend>
                            <div className="form-group">
                              <label className="control-label">
                                Sales Person Code
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={sales.Sales_Person_Code ? sales.Sales_Person_Code : "-"}
                                disabled
                              />
                            </div>
                            <div className="form-group">
                              <label className="control-label">
                                Sales Person Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={sales.Sales_Person_name ? sales.Sales_Person_name : "-"}
                                disabled
                              />
                            </div>
                            <div className="form-group">
                              <label className="control-label">DSM Code</label>
                              <input
                                type="text"
                                className="form-control"
                                value={sales.dsm_code ? sales.dsm_code : "-"}
                                disabled
                              />
                            </div>
                            <div className="form-group">
                              <label className="control-label">DSM Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={sales.dsm_name ? sales.dsm_name :"-"}
                                disabled
                              />
                            </div>
                          </fieldset>
                        </div>
                      </div>

                      <fieldset>
                        <legend>Customer Details</legend>
                        <div className="form-group">
                          <label className="control-label">
                            Ship to Address
                          </label>
                          <textarea
                            name=""
                            id=""
                            cols="30"
                            rows="3"
                            className="form-control"
                            value={machine_customer.ship_to_address ? machine_customer.ship_to_address : ""}
                            disabled
                          />
                        </div>
                        <div className="form-group">
                          <label className="control-label">
                            Location of Equipment No
                          </label>
                          <textarea
                            name=""
                            id=""
                            cols="30"
                            rows="3"
                            className="form-control"
                            value={machine_customer.location ? machine_customer.location : ""}
                            disabled
                          />
                        </div>
                        <div className="row row-xs">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="control-label">
                                Telephone No
                              </label>
                              <input
                                type="text"
                                className="form-control col-sm-offset-1 "
                                value={customers.customer_phone1 ? customers.customer_phone1 : ""}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="control-label">
                                Postal Code
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={customers.ship_to_postcode ? customers.ship_to_postcode : ""}
                                disabled
                              />
                            </div>
                            <div className="form-group">
                              <label className="control-label">
                                Delivery Date
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={machine_customer.delivery_date ? machine_customer.delivery_date : ""}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset>
                        <legend>SLA DETAILS</legend>
                        <div className="form-group">
                          <label className="control-label">
                            SLA Response Time
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={customers.sla_details ? customers.sla_details : ""}
                            disabled
                          />
                        </div>
                        <div className="form-group">
                          <label className="control-label">
                            Additional SLA Details
                          </label>
                          <input type="text" className="form-control" disabled />
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-6">
              <div className="panel panel-default">
                <div className="panel-heading">
                  Ticket Number : {ticket_origin.ticket_no ? ticket_origin.ticket_no : ""}
                </div>
                <div className="panel-body">
                  <div className="form form-xs">
                    <div className="form-group">
                      <label>Contact Note</label>
                      <textarea
                        type="text"
                        className="form-control"
                        value={ticket_origin.contact_note ? ticket_origin.contact_note : ""}
                        disabled
                      />
                    </div>
                    <div className="form-group">
                      <label>Internal Note</label>
                      <textarea
                        type="text"
                        className="form-control"
                        value={ticket_origin.internal_note ? ticket_origin.internal_note : ""}
                        disabled
                      />
                    </div>
                    {/* <div className="form-group ">
                      <p>
                        <a
                          className="btn btn-default"
                          data-toggle="collapse"
                          href="#multiCollapseExample1"
                          role="button"
                          aria-expanded="false"
                          aria-controls="multiCollapseExample1"
                        >
                          View Internal Note
                        </a>
                      </p>

                      <div
                        className="collapse multi-collapse"
                        id="multiCollapseExample1"
                      >
                        <input
                          type="text"
                          className="form-control"
                          value={ticket_origin.internal_note}
                          disabled
                        />
                      </div>
                    </div> */}
                    {/* <div className="form-group">
                      <label >Action Taken</label>
                      <input
                        type="text"
                        className="form-control"
                        value={ticket_origin.action_taken}
                        disabled
                      />
                    </div> */}
                  </div>
                </div>
              </div>

              {/* <div className="panel panel-default">
                <div className="panel-body">
                  <div className="form form-xs">
                    <div className="form-group">
                      <div className="radio-inline">
                        <label >
                          <input
                            type="radio"
                            name="radio-1"
                            checked={
                              ticket_origin.is_service === 1 ? "checked" : ""
                            }
                          />
                          Service/Machine Problem
                        </label>
                      </div>
                      <div className="radio-inline">
                        <label >
                          <input
                            type="radio"
                            name="radio-1"
                            checked={
                              ticket_origin.is_service === 0 ? "checked" : ""
                            }
                          />
                          Internal Follow Up?
                        </label>
                      </div>
                      <div className="radio-inline">
                        <label >
                          <input
                            type="radio"
                            name="radio-1"
                            checked={
                              ticket_origin.is_service === 2 ? "checked" : ""
                            }
                          />
                          Other?
                        </label>
                      </div>
                    </div>
                    <div className="well">
                      <div className="form-group">
                        <label >Fault Comment</label>
                        <input
                          type="text"
                          className="form-control"
                          value={ticket_origin.fault_comment}
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <label >Machine Status</label>
                        <input
                          type="text"
                          className="form-control"
                          value={machine_status.name}
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <label >Machine Type</label>
                        <input
                          type="text"
                          className="form-control"
                          value={machine_type.name}
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <label >Service Code</label>
                        <input
                          type="text"
                          className="form-control"
                          value={service_code.job_detail}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="panel panel-default"> */}
              {/* <div className="panel-body">
                  <div className="row row-xs">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label >Service Notification Number</label>
                        <input
                          type="text"
                          className="form-control"
                          value={"S" + assign_ce.id}
                        />
                      </div>
                      <div className="form-group">
                        <label >Service Code</label>
                        <input
                          type="text"
                          className="form-control"
                          value={service_code.job_code}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label >Response Time (Hours)</label>
                        <input
                          type="text"
                          className="form-control"
                          value={customers.sla_details}
                        />
                      </div>
                      <div className="form-group">
                        <label >Priority</label>
                        <select
                          name="priority"
                          className="form-control"
                          value={this.state.priority}
                          onChange={this.handleAssignCE}
                        >
                          <option value="">---SELECT PRIORITY---</option>
                          <option value="1">Low</option>
                          <option value="2">Medium</option>
                          <option value="3">High</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <fieldset>
                    <legend>One Time Pin Processing</legend>
                    <div className="form-group">
                      <label >Customer Contact Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="customer_contact_name"
                        onChange={this.handleAssignCE}
                      />
                    </div>
                    <div className="form-group">
                      <label >Input OTP</label>

                      <table className="table table-bordered table-xs">
                        <tr>
                          <NumberFormat
                            className="form-control"
                            format="# # # #"
                            mask="_"
                            name="input_otp"
                            value={this.state.input_otp}
                            onChange={this.input_otp}
                            disabled={button === "disabled" ? "disabled" : ""}
                          />

                          <td>
                            {button === "disabled" ? (
                              <button
                                onClick={this.handleEnabled}
                                className="btn btn-primary btn-xs"
                              >
                                CHANGE
                              </button>
                            ) : (
                              <button
                                onClick={this.handleDisabled}
                                className="btn btn-danger btn-xs"
                              >
                                SAVE
                              </button>
                            )}{" "}
                            <span className="label label-success">
                              Old OTP : {assign_ce.input_otp}
                            </span>{" "}
                            <span className="label label-warning">
                              New OTP : {ott}
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </fieldset>
                  <fieldset>
                    <legend>Assign CE Processing</legend>
                    <div className="row row-xs">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label >Preferred Resource CE</label>
                          <select
                            name="id_ce"
                            className="form-control"
                            onChange={this.handleChange2}
                          >
                            <option value="-">-</option>
                            {ce.map(tic => (
                              <option
                                value={
                                  tic.id +
                                  "#" +
                                  tic.username +
                                  "#" +
                                  tic.enabled +
                                  "#" +
                                  tic.longitude +
                                  "#" +
                                  tic.latitude
                                }
                                disabled={tic.enabled === 1 ? "" : "disabled"}
                              >
                                {tic.username}/{tic.enabled}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label >Status of CE-{name_ce}</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              id_ce ? av + " (" + long + ", " + lat + ")" : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row row-xs">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label >&nbsp;</label>
                              <button
                                className="btn btn-default btn-block"
                                disabled
                              >
                                CALENDAR
                              </button>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label >&nbsp;</label>
                              <button
                                className="btn btn-default btn-block"
                                onClick={this.showModalMachine}
                                disabled={id_ce ? "" : "disabled"}
                              >
                                MAPS
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label >Assigned Time Segment</label>
                          <input
                            type="date"
                            className="form-control"
                            name="date_of_assign"
                            onChange={this.handleAssignCE}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group text-right">
                      <button
                        className="btn btn-default"
                        onClick={this.save.bind(this)}
                        disabled
                      >
                        ADD CE
                      </button>
                    </div>
                    <table className="table table-bordered table-xs">
                      <tr>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Status</th>
                      </tr>
                      {value.map(tic => (
                        <tr>
                          <td>{tic.id_ce}</td>
                          <td>{tic.name_ce}</td>
                          <td>
                            {tic.enable_ce === "1"
                              ? "Aviliable"
                              : "Not Aviliable"}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </fieldset>
                  <br />
                  <br />
                  <input type="checkbox" name="radio-2" disabled /> Approved by
                  Credit Control
                  <div className="form-group form-inline">
                    <label >Date Time</label>{" "}
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        value=""
                        disabled
                      />

                      <span class="input-group-addon">
                        <i class="fa fa-calendar" />
                      </span>
                    </div>
                  </div>
                </div> */}
              {/* </div> */}
              <div className="panel panel-default">
                <div className="panel-heading">Tracking Process of Ticket</div>
                <div className="table-responsive">
                  <table className="table table-xs table-bordered">
                    <thead>
                      <tr>
                        <th>Date Time</th>
                        <th>Description</th>
                        <th>Action By</th>
                        <th>Department</th>
                        <th>Assign to Department</th>
                        <th>Received By</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ticket_tracking.map(tic => (
                        <tr key={tic.id}>
                          <td>{moment(tic.created_at).format("L hh:mm")}</td>
                          <td>{tic.description}</td>
                          <td>
                            {/* {tic.action_by.id} */}
                            {tic.action_by.fullname}
                          </td>
                          <td>{tic.action_department.name}</td>
                          <td>{tic.received_department.name}</td>
                          <td>
                            {tic.received_by.fullname}(
                            {tic.received_by.username})
                          </td>
                          <td>{tic.ticket_status.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="text-left">
                <button
                  className="btn btn-warning btn-sm"
                  onClick={this.handleSaveAssignCE}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // originator: state.originator,
  // tickets_det: state.tickets_det,
  // originator_des: state.originator_des,
  // originator_des_dep: state.originator_des_dep,
  // customer: state.customer,
  // ticket_details: state.ticket_details

  yyy: state.user,
  assign_ce: state.assignCe,
  action_by: state.assignCe.assign_ce,
  historyRed: state.historyRed
});
export default connect(
  mapStateToProps,
  { detailHistory, listUser }
)(AssignCEHis);
