import { DETAIL_CONSUMABLE, CREATE_SALES_ORDER } from "../actions/types";

const initialState = {
  data: null,
  action_by: {},
  received_by: {},
  originator: {},
  ticket_origin: {},
  originator_department: {},
  customer: {},
  customer_sales: {},
  consumable_request: {},
  machine_customer: {},
  machine_customer_detail_customer: {},
  machine: {},
  machine_model: {},
  machine_block_by: {},
  machine_contract: {},
  orders: [],
  ticket_type: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case DETAIL_CONSUMABLE:
      return {
        ...state,
        data: action.data,
        action_by: action.action_by,
        received_by: action.received_by,
        originator: action.originator,
        originator_department: action.originator_department,
        ticket_origin: action.ticket_origin,
        customer: action.customer,
        customer_sales: action.customer_sales,
        consumable_request: action.consumable_request,
        machine_customer: action.machine_customer,
        machine_customer_detail_customer:
          action.machine_customer_detail_customer,
        machine: action.machine,
        machine_model: action.machine_model,
        machine_block_by: action.machine_block_by,
        machine_contract: action.machine_contract,
        ticket_type: action.ticket_type
      };
    case CREATE_SALES_ORDER:
      return {
        ...state,
        orders: [action.payload, ...state]
      };
    default:
      return state;
  }
}
