import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import axios from 'axios'; import moment from 'moment';

import Container from '../../../layout/Container';
import Flash from '../../../common/FlashMessage';
import UserOriginator from './UserOriginator';
import ContactDetail from './ContactDetail';
import CustomerInformation from './CustomerInformation';
import TicketNumber from './TicketNumber';
import TrackTicketModal from './trackTicketModal';
import CallInformation from './CallInformation';
import ProcessInvoice from './ProcessInvoice';
import ServiceReport from './ServiceReport';
import STN from './STN';
import StnModal from './stnModal';
import Quota from './Quota';
import JobInvoice from './JobInvoice';
import SimpleReactValidator from "simple-react-validator";
import uuid from 'uuid';

const headers= { Authorization: 'Bearer ' + localStorage.jwtToken }
const initialState = {
  originator: {
    id: "",
    username: "",
    fullname: "",
    department: {
      name: ""
    }
  },
  created_at: "",
  contact_detail: {
    id: "",
    contact_name: "",
    gender: "",
    date_of_contact: "",
    channel_of_contact: "",
    type_channel_of_contact: ""
  },
  customers: {
    CustomerName: "",
    sap_sales_person_code: "",
    sap_sales_person_name: "",
    sap_dsm_code: "",
    sap_dsm_name: "",
    customer_category: "",
  },
  machine: {
    machine_serial: "",
    equipment_number: "",
    machine_model: {model_name: ""}
  },
  ship_to: {
    ship_to_name: "",
    ship_to_code: "",
    ship_to_contact: "",
    ship_to_address: "",
    ship_to_postcode: "",
    location: ""
  },

  //Right
  action_by_department: "",
  received_by_department: "",
  tracking: [],
  call_info: {
    contact_note: "",
    internal_note: "",
    action_taken: "",
    is_service: "",
    fault_comment: "",
    machine_status: "",
    machine_type: "",
    service_code: "",
  },

  invoice: {
    id: "",
    service_notification_no: "",
    service_notification_date: "",
    service_report_no: "",
    service_report_date: "",
    serial_number: "",
    ce_code: "",
    ce_name: "",
    invoice_no: "",
    invoice_date: "",
    created_at: "",
    updated_at: "",
    ticket_assign_ce_id: "",
    ticket_tracking_id: "",
  },
  stn: [],
  job_invoice: [],
  showTrackingModal: false,
  showStnModal: false,
  selectedStn: "",
}

class Invoice extends Component{
  constructor(props){
    super(props)
    this.state = initialState
  }
  componentDidMount(){
    axios.get('/api/v1/ticket-management/invoice/detail?ticket_tracking_id=' + window.location.href.split("/").pop(), {headers})
      .then(res => {
        const data = res.data.data
        const shipTo = {
          ship_to_name: data.ticket_invoice.ticket_origin.machine_customer.ship_to_name,
          ship_to_code: data.ticket_invoice.ticket_origin.machine_customer.ship_to_code,
          ship_to_contact: data.ticket_invoice.ticket_origin.machine_customer.ship_to_contact_name,
          ship_to_address: data.ticket_invoice.ticket_origin.machine_customer.ship_to_address,
          ship_to_postcode: data.ticket_invoice.ticket_origin.machine_customer.ship_to_postcode,
          delivery_date: data.ticket_invoice.ticket_origin.machine_customer.delivery_date,
          location: data.ticket_invoice.ticket_origin.machine_customer.location
        }
        const callInfo = {
          contact_note: data.ticket_invoice.ticket_origin.contact_note,
          internal_note: data.ticket_invoice.ticket_origin.internal_note,
          action_taken: data.ticket_invoice.ticket_origin.action_taken,
          is_service: data.ticket_invoice.ticket_origin.is_service,
          fault_comment: data.ticket_invoice.ticket_origin.fault_comment,
          machine_status: data.ticket_invoice.ticket_origin.machine_status,
          machine_type: data.ticket_invoice.ticket_origin.machine_type,
          service_code: data.ticket_invoice.ticket_origin.service_code
        }
        this.setState({
          origin_id: data.ticket_invoice.ticket_origin.id,
          originator: data.ticket_invoice.ticket_origin.originator,
          created_at: data.ticket_invoice.created_at,
          contact_detail: data.ticket_invoice.ticket_origin.contact_detail,
          customers: data.ticket_invoice.ticket_origin.machine_customer.customers,
          ship_to: shipTo,
          action_by_dept: data.ticket_invoice.action_by.department.name,
          received_by_dept: data.ticket_invoice.received_by.department.name,
          call_info: callInfo,
          invoice: data.invoice,
          stn: data.stn,
          job_invoice: data.job_invoice
        })
      }).catch(error => {
        //this.flashMessage('error', 'Failed to Load Master Data');
      });
  }

  toggleTrackTicket = e => {
    this.setState({showTrackingModal: !this.state.showTrackingModal});
  }

  trackTicketHandler = e => {
    axios.get('/api/v1/ticket-management/tracking-ticket?ticket_origin_id=' + this.state.origin_id,{headers})
      .then(res => {
      
      }).catch(error => {
        // this.flashMessage('error', 'Failed to Load Master Data');
      });
  }

  toggleStnModal = e => {
    this.setState({showStnModal: !this.state.showStnModal});
  }
  
  viewStnHandler = (e, id) => {
    //let selectedStn = this.state.stn
      let selectedStn = this.state.stn.filter(stn => {return stn.id == id})
    //console.log(selectedStn[0]);
    this.setState({selectedStn: selectedStn[0]});
    this.toggleStnModal()
  }

  flashMessage = (type, message) => {
    this.setState({...this.state, alert_type: type, alert_message: message})
    setTimeout(() => {
      this.setState({...this.state, alert_type: '', alert_message: ''});
    }, 5000)
  };
  
  submitInvoiceHandler = e => {
    axios.post('/api/v1/ticket-management/invoice/detail?ticket_tracking_id=' + this.state.invoice.ticket_tracking_id,{headers})
      .then(res => {
        const meta = res.data.meta; //const data = res.data.data;
        if(meta.code === 200){
          this.flashMessage('success', 'Invoice Ticket Saved');
        }else{
          this.flashMessage('error', 'Failed to save invoice');
        }
        
      }).catch(error => {
        this.flashMessage('error', 'Failed to save invoice');
      });
  }
  viewDraftHandler = e => {}
  
  render(){
    return (
      <Container pagename="Ticket Management" pagedetailname="Invoice" sidebarinfo="TicketManagement">
        <div id="main" className="main-xs">
          <div className="row row-xs">
            <div className="col-md-6">
              <UserOriginator originator={this.state.originator} created={this.state.created_at}/>
              <ContactDetail contact={this.state.contact_detail} created={this.state.created_at}/>
              <CustomerInformation cust={this.state.customers} st={this.state.ship_to}/>
              <button className="btn btn-default btn-sm" type="submit">HISTORY</button>
            </div>
            <div className="col-md-6">
              <TicketNumber from={this.state.action_by_dept} to={this.state.received_by_dept} onClick={this.trackTicketHandler}/>
              <CallInformation origin={this.state.call_info}/>
              <ProcessInvoice invoice={this.state.invoice}/>
              <ServiceReport/>
              <STN stn={this.state.stn} viewStn={this.viewStnHandler} onclose={this.toggleStnModal}/>
              <Quota/>
            </div>
          </div>
          <div className="row row-xs" style={{marginTop: "10px"}}>
            <div className="col-md-12">
              <JobInvoice ji={this.state.job_invoice} submit={this.submitInvoiceHandler} draft={this.viewDraftHandler}/>
            </div>
          </div>
        </div>
        {this.state.showTrackingModal
         ? <TrackTicketModal
             tracking={this.state.tracking}
             modalclass="modal display-block"
             mdialog="modal-dialog modal-lg"
             onclose={this.toggleTrackTicket}/> : ''}
        {this.state.showStnModal
         ? <StnModal
             modalclass="modal display-block modal-large"
             stn={this.state.selectedStn}
             onclose={this.toggleStnModal}/> : ''}
      </Container>
    )
  }
}

export default Invoice;
