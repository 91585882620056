import React from "react";

import Modal from "../../../../ui/Modal/Modal";
import "./Modal.css";

const SearchNameModel = props => {
  return (
    <Modal
      title="Search Machine"
      onclose={props.onclose}
      modalid={props.modalid}
      modalclass={props.modalclass}
    >
      <div className="modal-body">
        <div className="form form-xs">
          <div className="form-group">
            <label>Company Name</label>
            <input
              type="text"
              className="form-control"
              value={props.company.CustomerName}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Ship to Address</label>
              <input
                type="text"
                className="form-control"
                value={props.company.CustomerAddress}
                readOnly
              />
          </div>
        </div>

        <div className="table-fixed-header" style={{ height: "200px" }}>
          <table className="table table-bordered table-xs">
            <thead>
              <tr>
                {/* <th></th> */}
                <th style={{ width: "150px" }}>Serial No</th>
                <th style={{ width: "150px" }} width="20%">
                  Equipment No
                </th>
                <th style={{ width: "200px" }}>Model</th>
                <th style={{ width: "300px" }}>Ship to Address</th>
              </tr>
            </thead>
            <tbody>
              {props.listmachine.map(li => (
                <tr
                  key={li.MachineCode}
                  onClick={e => props.select(e, li.MachineCode)}
                  className={props.trclass === li.MachineCode ? "selected" : ""}
                >
                  {/* <td><input name="serial_no" type="radio" onChange={props.select} value={li.MachineSerialNumber}/></td> */}
                  <td style={{ width: "150px" }}>{li.MachineSerialNumber}</td>
                  <td style={{ width: "150px" }}>{li.MachineCode}</td>
                  <td style={{ width: "200px" }}>{li.MachineModel}</td>
                  <td style={{ width: "300px" }}>
                    {props.company.CustomerAddress}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-footer align-right">
        <button
          type="button"
          className="btn btn-default pull-right"
          onClick={props.onclose}
          style={{marginLeft: "5px"}}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary pull-right"
          onClick={props.onselect}
          disabled={!props.trclass}
        >
          Select
        </button>
      </div>
    </Modal>
  );
};

export default SearchNameModel;
