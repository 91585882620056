import React, { Component } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider, connect } from "react-redux";
import store from "./store";
// import { connect } from "react-redux";
import PrivateRoute from "./components/common/PrivateRoute";

import "./App.css";
import "./Custom.css";

import Login from "./components/auth/Login";
import Dashboard from "./components/pages/Dashboard";
import CustomerInformation from "./components/pages/customer/CustomerInformation";
import TicketManagement from "./components/pages/ticket/TicketManagement";
import TicketManagement2 from "./components/pages/ticket/TicketInboxPagination";
import TicketManagement3 from "./components/pages/ticket/TicketHistoryPagination";

import Unassign from "./components/pages/unassign/UnAssign";
//import QuotationInbox from "./components/pages/ticket/TicketManagement/QuotationInbox";
import QuotationInbox from "./components/pages/ticket/quotation/QuotationInbox";
import Quotation from "./components/pages/ticket/quotation/Quotation";
import QuotationPdf from "./components/pages/ticket/quotation/QuotationPdf";
import Location from "./components/pages/location/Location";
import Invoice from "./components/pages/ticket/invoice/Invoice";

//import CreateOriginationTicket from "./components/pages/ticket/origination/Create";
import CreateOriginationTicket from "./components/pages/ticket/origination/Origination";
//import CreateOriginationTicket from "./components/pages/ticket/origin/Create";
import CreateInstallationTicket from "./components/pages/ticket/installation/Create";
import DetailHistoryTicket from "./components/pages/ticket/AssignCEHis";
import DetailInboxTicket from "./components/pages/ticket/DetailInboxTicket";
import ProcessTicketHelpdesk from "./components/pages/ticket/ProcessTicketHelpdesk";
// import AssignCE from "./components/pages/ticket/AssignCE";

import PmSetting from "./components/pages/setting/pm/pm";
import PmMachine from "./components/pages/setting/pm/PmMachine";
import PmSet from "./components/pages/setting/pm/pmSetting";

import CalendarSetting from "./components/pages/setting/calendar/calendar";
import WorkingSetting from "./components/pages/setting/calendar/working";

import UserSetting from "./components/pages/setting/user/ListUser";
import UserEdit from "./components/pages/setting/user/editUser";

import HomeSetting from "./components/pages/setting/home/Home";
import SalesSetting from "./components/pages/setting/sales/Sales";
import SlaSetting from "./components/pages/setting/sla/Sla";
import SlaUpload from "./components/pages/setting/sla/Upload";
import PartSetting from "./components/pages/setting/part/Part";
import MasterPartSetting from "./components/pages/setting/part/MasterPart";

import Messaging from "./components/pages/Messaging";
import Reports from "./components/pages/Reports";
import Create_user from "./components/pages/setting/user/CreateUser";

import ProcessAssignTechnician from "./components/pages/ProcessAssignTechnician";

import UnAssign from "./components/pages/unassign/UnAssign";
import Consumable from "./components/pages/consumable/Consumable";
import AssignCE from "./components/pages/ticket/AssignCE";
import Csrc from "./components/pages/setting/crsc/Csrc";
import Spr from "./components/pages/ticket/spr/Spr";
import SprReconciliation from "./components/pages/ticket/spr/SprReconciliation";
import LifeToner from "./components/pages/setting/Consumable/LifeToner";
import MachineModel from "./components/pages/setting/Consumable/MachineModel";
import DetailUnassign from "./components/pages/unassign/DetailUnassign";

// Check for token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // TODO: Clear current profile

    // Redirect to login
    window.location.href = "/";
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Route exact path="/" component={Login} />
            <Switch>
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute
                exact
                path="/customer-information"
                component={CustomerInformation}
              />
              <PrivateRoute
                exact
                path="/ticket-management"
                component={TicketManagement}
              />
              <PrivateRoute
                exact
                path="/ticket-management2"
                component={TicketManagement2}
              />
              <PrivateRoute
                exact
                path="/ticket-management3"
                component={TicketManagement3}
              />

              <PrivateRoute
                exact
                path="/ticket-management/create-installation-ticket"
                component={CreateInstallationTicket}
              />
              <PrivateRoute
                exact
                path="/ticket-management/quotation-inbox"
                component={QuotationInbox}
              />
              <PrivateRoute exact path="/unassign" component={Unassign} />

              <PrivateRoute exact path="/messaging" component={Messaging} />
              <PrivateRoute exact path="/location" component={Location} />
              <PrivateRoute
                exact
                path="/ticket-management/create-origin-ticket"
                component={CreateOriginationTicket}
              />
              <PrivateRoute exact path="/reports" component={Reports} />
              <PrivateRoute
                exact
                path="/setting/user"
                component={UserSetting}
              />
              <PrivateRoute exact path="/setting/pm" component={PmSetting} />
              <PrivateRoute
                exact
                path="/setting/pm_setting"
                component={PmSet}
              />

              <PrivateRoute
                exact
                path="/setting/pm_machine"
                component={PmMachine}
              />

              <PrivateRoute
                exact
                path="/setting/calendar"
                component={CalendarSetting}
              />
              <PrivateRoute
                exact
                path="/setting/working"
                component={WorkingSetting}
              />

              <PrivateRoute
                exact
                path="/setting/edit_user/:id"
                component={UserEdit}
              />

              <PrivateRoute
                exact
                path="/setting/create_user"
                component={Create_user}
              />
              <PrivateRoute
                exact
                path="/ticket-management/detailhistoryticket/:id"
                component={DetailHistoryTicket}
              />
              <PrivateRoute
                exact
                path="/ticket-management/detailinboxticket/:id"
                component={DetailInboxTicket}
              />
              <PrivateRoute
                exact
                path="/ticket-management/processtickethelpdesk/:id"
                component={ProcessTicketHelpdesk}
              />
              <PrivateRoute
                exact
                path="/ticket-management/assignce/:id"
                component={AssignCE}
              />
              <PrivateRoute exact path="/unassign" component={UnAssign} />
              <PrivateRoute
                exact
                path="/unassign/detail/:id"
                component={DetailUnassign}
              />
              <PrivateRoute
                exact
                path="/ticket-management/consumable/:id"
                component={Consumable}
              />
              <PrivateRoute
                exact
                path="/ticket-management/spr/:id"
                component={Spr}
              />
              <PrivateRoute
                exact
                path="/ticket-management/spr_reconciliation/:id"
                component={SprReconciliation}
              />
              <PrivateRoute
                exact
                path="/ticket-management/quotation/:id"
                component={Quotation}
              />
              <PrivateRoute
                exact
                path="/ticket-management/invoice/:id"
                component={Invoice}
              />
              <PrivateRoute
                exact
                path="/ticket-management/quotation/:id/savepdf"
                component={QuotationPdf}
              />
              <PrivateRoute
                exact
                path="/setting/home"
                component={HomeSetting}
              />
              <PrivateRoute
                exact
                path="/setting/sales"
                component={SalesSetting}
              />
              <PrivateRoute
                exact
                path="/setting/additional-sla"
                component={SlaSetting}
              />
              <PrivateRoute
                exact
                path="/setting/upload-sla"
                component={SlaUpload}
              />
              <PrivateRoute
                exact
                path="/setting/spare-part"
                component={PartSetting}
              />
              <PrivateRoute
                exact
                path="/setting/spare-part-master"
                component={MasterPartSetting}
              />
              <PrivateRoute
                exact
                path="/setting/csrc-status"
                component={Csrc}
              />
              <PrivateRoute
                exact
                path="/setting/consumable/life-toner"
                component={LifeToner}
              />
              <PrivateRoute
                exact
                path="/setting/consumable/machine-model"
                component={MachineModel}
              />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;

// const mapStateToProps = state => ({
//   auth: state.auth.user
// });
// export default connect(
//   mapStateToProps,
//   { setCurrentUser }
// )(App);
