import React from 'react'

const STN = props => {
  return(
    <React.Fragment>
      <div className="panel panel-default">
        <div className="panel-heading">Process Invoice</div>
        <div className="panel-body">
          <div className="form form-xs">
            <div className="row row-xs">
              <h5>List STN Created</h5>
              <table className="table table-bordered table-xs">
                <thead>
                  <tr>
                    <th>STN No</th>
                    <th>Temp</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Date/ Time</th>
                    <th>Comment</th>
                    <th width="80"></th>
                  </tr>
                </thead>
                <tbody>
                  {props.stn.length > 0 ?
                   props.stn.map(st => (
                     <tr key={st.id}>
                       <td>{st.transfer_order.transfer_order_number}</td>
                       <td>T0D09935</td>
                       <td>Sparepart</td>
                       <td>Teban</td>
                       <td>2019-03-21 09:00</td>
                       <td>Sparepart Division</td>
                       <td><button className="btn btn-xs btn-default btn-block" onClick={e => props.viewStn(e, st.id)}>Preview</button></td>
                     </tr>
                   ))
                   :  <tr><td colSpan="7" style={{textAlign: "center"}}>Data Available in This Table</td></tr>}
                </tbody>
              </table>    
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default STN;
