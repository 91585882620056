import React, { Component } from "react";
import Navbar from "../../../layout/Navbar";
import Sidebar from "../../../layout/Sidebar";
// import axios from "axios";
// import { Link, Route } from "react-router-dom";

import { connect } from "react-redux";
import { getCreateUser, saverUser } from "../../../../actions/getUserActions";
import { editUser, update, del_message } from "../../../../actions/userAction";

import SettingContainer from "../Container";
import SimpleReactValidator from "simple-react-validator";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import iconBlue from "../../../../img/icon-blue.png";
import iconRed from "../../../../img/icon-red.png";
let contain = "";
class EditUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nav_user_id: [],

      branch_id: []
    };
    this.validator = new SimpleReactValidator();
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);

    this.handlefullname = this.handlefullname.bind(this);
    this.handleusername = this.handleusername.bind(this);

    this.handlesap_user_code = this.handlesap_user_code.bind(this);
    this.handlepassword = this.handlepassword.bind(this);
    this.handlecpassword = this.handlecpassword.bind(this);
    this.handleemail = this.handleemail.bind(this);
    this.handlemobile_phone = this.handlemobile_phone.bind(this);
    this.handlelongitude = this.handlelongitude.bind(this);

    this.handlelatitude = this.handlelatitude.bind(this);

    this.handleenabled = this.handleenabled.bind(this);
    this.handlebin_code = this.handlebin_code.bind(this);
    this.handledepartment_id = this.handledepartment_id.bind(this);
  }

  async componentDidMount() {
    const jwtToken = localStorage.getItem("jwtToken");
    // const { id } = this.props.match.params;

    // const data = {
    //   id: id,
    //   jwtToken: localStorage.getItem("jwtToken")
    // };
    // this.props.editUser(data);
    this.tembak();
    this.props.getCreateUser(jwtToken);
    // const edit_userid = this.state.edit_userid;
    // handleCall();
    // const { edit_userid, departmentx, level } = this.props;
    // this.setState({ fullname: "edit_user.fullname" });
    // console.log("edit detail");
    // console.log(this.props);

    // this.props.saverUser();
  }
  // componentWillMount() {
  //   window.location.reload();
  // }
  tembak = () => {
    const { id } = this.props.match.params;
    const data = {
      id: id,
      jwtToken: localStorage.getItem("jwtToken")
    };
    this.props.editUser(data);
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.edit_userid.departmentx !== null) {
      if (this.props.edit_userid.departmentx.length !== 0) {
        const { edit_user, departmentx, level } = nextProps.edit_userid;
        this.setState({
          fullname: edit_user.fullname,
          username: edit_user.username,
          level_id: level.id,
          email: edit_user.email,
          mobile_phone: edit_user.mobile_phone,
          enabled: edit_user.enabled,
          department_id: departmentx.id,
          sap_user_code: edit_user.sap_user_code,
          bin_code: edit_user.bin_code,
          longitude: edit_user.longitude,
          latitude: edit_user.latitude
        });
        // console.log("edit detail");
        // console.log(edit_user.fullname);
      }
    }
  }

  // handleCall() {
  //   // const { edit_user, departmentx, level } = this.props.edit_userid;
  //   // this.setState({ fullname: edit_user.fullname });
  //   console.log("edit detail");
  //   console.log(this.props);
  // }
  handleChange(event) {
    this.setState({ level_id: event.target.value });
  }
  handlefullname(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleCekbox2 = e => {
    let checked_day = e.target.name;
    this.setState(prevState => ({
      ...prevState,
      problem_codep: [...prevState.problem_codep, +checked_day]
    }));
  };
  handleusername(event) {
    this.setState({ username: event.target.value });
  }

  handlesap_user_code(event) {
    this.setState({ sap_user_code: event.target.value });
  }
  handlepassword(event) {
    this.setState({ password: event.target.value });
  }
  handlecpassword(event) {
    this.setState({ cpassword: event.target.value });
  }
  handleemail(event) {
    this.setState({ email: event.target.value });
  }
  handlemobile_phone(event) {
    this.setState({ mobile_phone: event.target.value });
  }

  handleenabled(event) {
    this.setState({ enabled: event.target.value });
  }
  handlebin_code(event) {
    this.setState({ bin_code: event.target.value });
  }
  handlelongitude(event) {
    this.setState({ longitude: event.target.value });
  }
  handlelatitude(event) {
    this.setState({ latitude: event.target.value });
  }
  onMapClicked = (t, map, coord) => {
    const { latLng } = coord;
    // console.log("latt", latLng.lat(), latLng.lng());
    this.setState({
      latPicker: latLng.lat(),
      lngPicker: latLng.lng(),
      latitude2: latLng.lat().toString(),
      longitude2: latLng.lng().toString()
    });
  };
  handleOk = () => {
    this.setState({
      // latPicker: latLng.lat(),
      // lngPicker: latLng.lng(),
      latitude: this.state.latitude2,
      longitude: this.state.longitude2
    });
  };
  handleClose = () => {
    this.setState({
      // latPicker: latLng.lat(),
      // lngPicker: latLng.lng(),
      latitude2: "",
      longitude2: ""
    });
  };
  handledepartment_id(event) {
    this.setState({ department_id: event.target.value });
  }

  handleChange2(event) {
    this.setState({ value2: event.target.value });
  }

  handleChange3(event) {
    this.setState({ value3: event.target.value });
  }

  handleSubmit = event => {
    event.preventDefault();
    if (this.validator.allValid()) {
      // let saveUserData = new FormData();
      // saveUserData.append("fullname", this.state.fullname);
      // saveUserData.append("username", this.state.username);
      // saveUserData.append("level_id", this.state.level_id);
      // saveUserData.append("email", this.state.email);
      // saveUserData.append("mobile_phone", this.state.mobile_phone);
      // saveUserData.append("enabled", this.state.enabled);
      // saveUserData.append("department_id", this.state.department_id);
      // saveUserData.append("sap_user_code", this.state.sap_user_code);
      // saveUserData.append("bin_code", this.state.bin_code);
      // saveUserData.append("longitude", this.state.longitude);
      // saveUserData.append("latitude", this.state.latitude);

      const saveUserData = {
        fullname: this.state.fullname,
        username: this.state.username,
        sap_user_code: this.state.sap_user_code,
        // password: this.state.password,
        level_id: this.state.level_id,
        email: this.state.email,
        mobile_phone: this.state.mobile_phone,
        enabled: this.state.enabled,
        bin_code: this.state.bin_code,
        department_id: this.state.department_id
        // longitude: this.state.longitude,
        // latitude: this.state.latitude
      };
      const datax = {
        id: this.props.edit_userid.edit_user.id,
        dt: saveUserData,
        jwtToken: localStorage.getItem("jwtToken")
      };
      // console.log("data sebelum user");

      // console.log(datax);
      this.props.update(datax);
      NotificationManager.success("Success", "Update User");
      // const { resp } = this.props.www;
      // console.log("ini res to");
      // console.log(resp);
      // localStorage.setItem("error", resp ? resp.error : "");
      // localStorage.setItem("message", resp ? resp.message : "");
      setTimeout(
        function() {
          // window.location.reload();
          this.tembak();
          this.props.del_message();

          // this.props.history.push("/setting/user");

          // this.props.history.push("/setting/user");
        }.bind(this),
        3000
      );
      setTimeout(
        function() {
          this.props.history.push("/setting/user");
        }.bind(this),
        6000
      );
      // this.props.history.push("/setting/user");
    } else {
      this.validator.showMessages();
      //   // rerender to show messages for the first time
      //   // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };
  handleBack = event => {
    event.preventDefault();
    this.props.history.push("/setting/user");
  };
  render() {
    if (this.props.edit_userid.departmentx !== null) {
      if (this.props.edit_userid.departmentx.length !== 0) {
        const { edit_user, departmentx, level } = this.props.edit_userid;
        // this.state.fullname = edit_user.fullname;
        // console.log("edit detail");
        // console.log(this.state.fullname);
        // console.log("levelx");
        // console.log(level.id);
        const { persons } = this.props.www;
        const { user } = this.props.www;
        const { branch_list } = this.props.www;
        const { department } = this.props.www;
        const { resp } = this.props.www;
        const dis = this.state.level_id;
        const im = this.state.value2;
        const alt = this.state.value3;
        const disabled = dis === "8" ? "" : "disabled";
        const cek = alt - im;
        const pw = this.state.password === "" ? "" : this.state.password;
        const cpw = this.state.cpassword === "" ? "" : this.state.cpassword;
        const psw = pw === cpw ? "form-group" : "form-group has-error";
        const psw2 = this.state.cpassword === undefined ? "form-group" : psw;
        const mes =
          cek === 0
            ? "Alternate Immidiate cannot to be same with Immidiate."
            : "";
        const err = cek === 0 ? "form-group has-error" : "form-group";
        const qq =
          this.state.fullname !== "" && this.state.username !== ""
            ? "ak"
            : "disabled";

        const chek = edit_user.enabled === 1 ? "checked" : "";
        const chek2 = edit_user.enabled === 0 ? "checked" : "";
        // console.log(
        //   "as",
        //   parseFloat(this.state.latitude),
        //   parseFloat(this.state.longitude)
        // );
        contain = (
          <div className="col-md-6">
            <div className="panel-body form form-xs">
              {/* <button className="btn btn-default" onClick={this.handleCall}>
                  edit
                </button> */}
              <NotificationContainer />
              <form className="form-horizontal" onSubmit={this.handleSubmit}>
                <div className="box-body">
                  <div
                    className={
                      this.validator.message(
                        "Fullname",
                        this.state.fullname,
                        "required|alpha_num_space"
                      )
                        ? "form-group has-error"
                        : "form-group"
                    }
                  >
                    <label
                      style={{
                        textAlign: "left"
                      }}
                      className="col-sm-2 control-label"
                    >
                      Full Name
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="fullname"
                        name="fullname"
                        placeholder="Full Name"
                        onChange={this.handlefullname}
                        value={this.state.fullname}
                        // onCallback={this.handleCall}
                        // required
                      />
                      <p style={{ color: "#A94442" }}>
                        {this.validator.message(
                          "Fullname",
                          this.state.fullname,
                          "required|alpha_num_dash_space"
                        )}
                      </p>
                    </div>
                  </div>

                  <div
                    className={
                      this.validator.message(
                        "Username",
                        this.state.username,
                        "required|alpha"
                      )
                        ? "form-group has-error"
                        : "form-group"
                    }
                  >
                    <label
                      style={{
                        textAlign: "left"
                      }}
                      className="col-sm-2 control-label"
                    >
                      Username
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                        name="username"
                        placeholder="username"
                        onChange={this.handleusername}
                        value={this.state.username}
                        // required
                      />
                      <p style={{ color: "#A94442" }}>
                        {this.validator.message(
                          "Username",
                          this.state.username,
                          "required|alpha_num_dash_space"
                        )}
                      </p>
                    </div>
                  </div>

                  <div
                    className={
                      this.validator.message(
                        "Sav ID",
                        this.state.sap_user_code,
                        "required|integer"
                      )
                        ? "form-group has-error"
                        : "form-group"
                    }
                  >
                    <label
                      style={{
                        textAlign: "left"
                      }}
                      className="col-sm-2 control-label"
                    >
                      Sav Id
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="sap_user_code"
                        name="sap_user_code"
                        placeholder="Sav Id"
                        onChange={this.handlesap_user_code}
                        value={
                          this.state.sap_user_code === "null"
                            ? "-"
                            : this.state.sap_user_code
                        }
                        // required
                      />
                      <p style={{ color: "#A94442" }}>
                        {this.validator.message(
                          "Sav ID",
                          this.state.sap_user_code,
                          "required|integer"
                        )}
                      </p>
                    </div>
                  </div>

                  {/* <div className="form-group">
                      <label for="Username" className="col-sm-2 control-label">
                        Password
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          name="password"
                          placeholder="Password"
                          onChange={this.handlepassword}
                          value={this.state.password}
                          // required
                        />
                        <p style={{ color: "#A94442" }}>
                          {this.validator.message(
                            "Password",
                            this.state.password,
                            "required|alpha_num_space"
                          )}
                        </p>
                      </div>
                    </div> */}

                  {/* <div className={psw2}>
                      <label for="Username" className="col-sm-2 control-label">
                        Confirm Password
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="password"
                          className="form-control has-error"
                          id="cpassword"
                          name="cpassword"
                          placeholder="Password"
                          onChange={this.handlecpassword}
                          value={this.state.cpassword}
                          // required
                        />
                        <p style={{ color: "#A94442" }}>
                          {this.validator.message(
                            "Confirmation Password",
                            this.state.cpassword,
                            "required|alpha_num_space"
                          )}
                        </p>
                      </div>
                    </div> */}

                  <div
                    className={
                      this.validator.message(
                        "level",
                        this.state.level_id,
                        "required|integer"
                      )
                        ? "form-group has-error"
                        : "form-group"
                    }
                  >
                    <label
                      style={{
                        textAlign: "left"
                      }}
                      className="col-sm-2 control-label"
                    >
                      Level
                    </label>
                    <div className="col-sm-10">
                      <select
                        className="form-control"
                        onChange={this.handleChange}
                        // value={this.state.value}
                        name="level_id"
                        // required
                      >
                        <option value="">-</option>

                        {persons.map(person => (
                          <option
                            value={person.id}
                            selected={level.id === person.id ? "selected" : ""}
                            key={person.id}
                          >
                            {person.name}
                          </option>
                        ))}
                      </select>
                      <p style={{ color: "#A94442" }}>
                        {this.validator.message(
                          "level",
                          this.state.level_id,
                          "required|integer"
                        )}
                      </p>
                    </div>
                  </div>

                  {/* <div className="form-group">
                      <label
                        for="immidiateSuperior"
                        className="col-sm-2 control-label"
                      >
                        Immidiate Superior{" "}
                      </label>
                      <div className="col-sm-10">
                        <select
                          className="form-control"
                          id="spv_id"
                          disabled={disabled}
                          onChange={this.handleChange3}
                          value3={this.state.value3}
                          name="spv_id"
                          // required
                        >
                          <option value="">-</option>

                          {user.map(per => (
                            <option value={per.id}>{per.fullname}</option>
                          ))}
                        </select>
                        <p style={{ color: "#A94442" }}>
                          {this.validator.message(
                            "Immidiate Superior",
                            this.state.spv_id,
                            "required|integer"
                          )}
                        </p>
                      </div>
                    </div> */}

                  {/* <div className={err}>
                      <label
                        for="immidiateSuperior"
                        className="col-sm-2 control-label"
                      >
                        Alternate Immidiate Superior{" "}
                      </label>
                      <div className="col-sm-10">
                        <select
                          className="form-control"
                          id="alt_spv_id"
                          disabled={disabled}
                          onChange={this.handleChange2}
                          value2={this.state.value2}
                          name="alt_spv_id"
                          required
                        >
                          <option value="">-</option>

                          {user.map(per => (
                            <option value={per.id}>{per.fullname}</option>
                          ))}
                        </select>
                        {mes}
                      </div>
                    </div> */}

                  <div
                    className={
                      this.validator.message(
                        "Email",
                        this.state.email,
                        "required|email"
                      )
                        ? "form-group has-error"
                        : "form-group"
                    }
                  >
                    <label
                      style={{
                        textAlign: "left"
                      }}
                      className="col-sm-2 control-label"
                    >
                      Email Address
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Email Address"
                        value={this.state.email}
                        onChange={this.handleemail}
                        // required
                      />
                      <p style={{ color: "#A94442" }}>
                        {this.validator.message(
                          "Email",
                          this.state.email,
                          "required|email"
                        )}
                      </p>
                    </div>
                  </div>

                  <div
                    className={
                      this.validator.message(
                        "Phone",
                        this.state.mobile_phone,
                        "required|phone"
                      )
                        ? "form-group has-error"
                        : "form-group"
                    }
                  >
                    <label
                      style={{
                        textAlign: "left"
                      }}
                      className="col-sm-2 control-label"
                    >
                      Phone
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="mobile_phone"
                        name="mobile_phone"
                        placeholder="Phone"
                        value={
                          this.state.mobile_phone === "null"
                            ? "-"
                            : this.state.mobile_phone
                        }
                        onChange={this.handlemobile_phone}
                        // required
                      />
                      <p style={{ color: "#A94442" }}>
                        {this.validator.message(
                          "Phone",
                          this.state.mobile_phone,
                          "required|phone"
                        )}
                      </p>
                    </div>
                  </div>

                  <div
                    className={
                      this.validator.message(
                        "status",
                        this.state.enabled,
                        "required"
                      )
                        ? "form-group has-error"
                        : "form-group"
                    }
                  >
                    <label
                      style={{
                        textAlign: "left"
                      }}
                      className="col-sm-2 control-label"
                    >
                      status
                    </label>
                    <div className="col-sm-10">
                      <select
                        className="form-control"
                        // id="enabled"
                        name="enabled"
                        onChange={this.handleenabled}
                        // required
                      >
                        <option value="" key={1}>
                          -
                        </option>

                        <option value="1" selected={chek} key={2}>
                          Active
                        </option>
                        <option value="0" selected={chek2} key={3}>
                          Inactive
                        </option>
                      </select>
                      <p style={{ color: "#A94442" }}>
                        {this.validator.message(
                          "Departement",
                          this.state.department_id,
                          "required|integer"
                        )}
                      </p>
                    </div>
                  </div>

                  {/* <div className="form-group">
                      <label for="Username" className="col-sm-2 control-label">
                        status
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="radio"
                          name="enabled"
                          id="enabled"
                          onChange={this.handleenabled}
                          value="1"
                          checked={chek}
                          // required
                        />
                        Active {chek}
                        <input
                          type="radio"
                          name="enabled"
                          id="enabled"
                          onChange={this.handleenabled}
                          value="0"
                          checked={chek2}
                          // required
                        />
                        Inactive
                        <p style={{ color: "#A94442" }}>
                          {this.validator.message(
                            "status",
                            this.state.enabled,
                            "required"
                          )}
                        </p>
                      </div>
                    </div> */}

                  <div
                    className={
                      this.validator.message(
                        "Bincode",
                        this.state.bin_code,
                        "required|integer"
                      )
                        ? "form-group has-error"
                        : "form-group"
                    }
                  >
                    <label
                      style={{
                        textAlign: "left"
                      }}
                      className="col-sm-2 control-label"
                    >
                      Bincode
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="bin_code"
                        name="bin_code"
                        placeholder="Bincode"
                        onChange={this.handlebin_code}
                        value={
                          this.state.bin_code === "null"
                            ? "-"
                            : this.state.bin_code
                        }
                        // required
                      />
                      <p style={{ color: "#A94442" }}>
                        {this.validator.message(
                          "Bincode",
                          this.state.bin_code,
                          "required|integer"
                        )}
                      </p>
                    </div>
                  </div>

                  <div
                    className={
                      this.validator.message(
                        "Departement",
                        this.state.department_id,
                        "required|integer"
                      )
                        ? "form-group has-error"
                        : "form-group"
                    }
                  >
                    <label
                      style={{
                        textAlign: "left"
                      }}
                      className="col-sm-2 control-label"
                    >
                      Departement
                    </label>
                    <div className="col-sm-10">
                      <select
                        className="form-control"
                        id="immidiateSuperior"
                        name="department_id"
                        onChange={this.handledepartment_id}
                        // required
                      >
                        <option value="">-</option>

                        {department.map(per => (
                          <option
                            value={per.id}
                            selected={
                              departmentx.id === per.id ? "selected" : ""
                            }
                          >
                            {per.name}
                          </option>
                        ))}
                      </select>
                      <p style={{ color: "#A94442" }}>
                        {this.validator.message(
                          "Departement",
                          this.state.department_id,
                          "required|integer"
                        )}
                      </p>
                    </div>
                  </div>

                  {/* <div className="form-group">
                    <label
                      style={{
                        textAlign: "left"
                      }}
                      className="col-sm-2 control-label"
                    >
                      Latitude, Longitude
                    </label>
                    <div className="input-group col-sm-12">
                      <div className="col-sm-4">
                        <input
                          type="text"
                          className="form-control"
                          // id="bin_code"
                          name="latitude"
                          placeholder="Latitude"
                          onChange={this.handlelatitude}
                          value={this.state.latitude}
                          disabled

                          // required
                        />
                        <p style={{ color: "#A94442" }}>
                          {this.validator.message(
                            "Latitude",
                            this.state.latitude,
                            "required|string"
                          )}
                        </p>
                      </div>

                      <div className="col-sm-4">
                        <input
                          type="text"
                          className="form-control"
                          // id="bin_code"
                          name="longitude"
                          placeholder="Longitude"
                          onChange={this.handlelongitude}
                          value={this.state.longitude}
                          disabled
                          // required
                        />
                        <p style={{ color: "#A94442" }}>
                          {this.validator.message(
                            "Longitude",
                            this.state.longitude,
                            "required|string"
                          )}
                        </p>
                      </div>
                      <div className="col-sm-4">
                        <span className="input-group-btn">
                          <a
                            data-toggle="modal"
                            data-target="#modal-add"
                            className="btn btn-default btn-xs btn-block"
                            // onClick={() =>
                            //   this.showModalMachine(person.id, person.pm_days)
                            // }
                          >
                            Pick
                          </a>
                        </span>
                      </div>
                      
                    </div>
                  </div> */}
                </div>
                <div className="modal fade" id="modal-add">
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                          onClick={this.handleClose}
                        >
                          &times;
                        </button>
                        <h4 className="modal-title">
                          {this.state.fullname} (
                          {Math.round(this.state.latitude * 10000) / 10000},{" "}
                          {Math.round(this.state.longitude * 10000) / 10000})
                        </h4>
                      </div>

                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-12 modal_body_map">
                            <div
                              className="row"
                              style={{
                                height: "75vh"
                              }}
                            >
                              <Map
                                google={this.props.google}
                                onClick={this.onMapClicked}
                                // onClick={(e) => this.onMapClicked(e)}
                                // onClick={(t, map, c) => this.onMapClicked(c.latLng, map)}
                                // initialCenter={this.state.center}
                                center={{
                                  lat: this.state.latitude2
                                    ? parseFloat(
                                        Math.round(
                                          this.state.latitude2 * 10000
                                        ) / 10000
                                      )
                                    : this.state.latitude
                                    ? this.state.latitude
                                    : 1.351,
                                  lng:
                                    this.state.longitude2 * 10000
                                      ? parseFloat(
                                          Math.round(
                                            this.state.longitude2 * 10000
                                          ) / 10000
                                        )
                                      : this.state.longitude
                                      ? this.state.longitude
                                      : 103.877
                                }}
                                zoom={
                                  this.state.longitude || this.state.longitude2
                                    ? 18
                                    : 14
                                }
                              >
                                <Marker
                                  title="Picker Location"
                                  name="Picker Location"
                                  position={{
                                    lat: this.state.latitude2
                                      ? this.state.latitude2
                                      : this.state.latitude,
                                    lng: this.state.longitude2
                                      ? this.state.longitude2
                                      : this.state.longitude
                                  }}
                                  icon={iconRed}
                                  type="company"
                                />
                              </Map>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                      <div className="modal-footer">
                        <button
                          type="submit"
                          className="btn btn-info btn-xs center-block"
                          // style={{ "text-align": "center" }}
                          data-dismiss="modal"
                          aria-hidden="true"
                          onClick={this.handleOk}
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-footer">
                  <button className="btn btn-default" onClick={this.handleBack}>
                    Back
                  </button>

                  <button type="submit" className="btn btn-info pull-right">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        );
      }
    }
    return (
      <div>
        <SettingContainer
          pagename="Setting"
          pagedetailname="User/Edit User"
          location="user"
        >
          <div className="panel panel-default">
            <div className="panel-heading">Edit User</div>
            <div>
              {contain ? (
                contain
              ) : (
                <i
                  className="fa fa-spinner fa-spin fa-2x"
                  style={{
                    opacity: "0.3",
                    top: "50%",
                    left: "50%",
                    position: "absolute"
                  }}
                />
              )}
            </div>
          </div>
        </SettingContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // persons: state.persons,
  // user: state.user,
  // branch_list: state.branch_list,
  edit_userid: state.edit_userid,
  www: state.user
});
export default connect(mapStateToProps, {
  getCreateUser,
  saverUser,
  editUser,
  update,
  del_message
})(
  GoogleApiWrapper({
    apiKey: "AIzaSyD8kVr5dj4fb_s-s80rqu8mbehkHKRXgFY"
  })(EditUser)
);
// (CreateUser);
