import {
  GET_SPR_RECON,
  POST_SUB,
  PRINT_SPR
  // EDIT_PM_CUST,
  // UPDATE_PM_CUST,
  // LIST_CUSTOMER_ADD,
  // STORE_PM_CUST,
  // DEL_PM_CUST,
  // UPDATE_DEFAULT_DAYS
} from "../actions/types";

const initialState = {
  spr: [],
  action_by: [],
  ticket_origin: [],
  originator: [],
  department: [],
  machine_customer: [],
  customers: [],
  machine: [],
  machine_models: [],
  customer: [],
  technician: [],
  blocked_by: [],
  contracts: [],
  sales: [],
  service_code: [],
  part_replace: [],
  resPostSubmit: [],
  data_spr_recon: []
  // checkStock: []
  // customer: [],
  // dataw: [],
  // resUpdateDays: [],
  // pm_default: [],
  // customers: [],
  // resDelPmCust: [],
  // resUpdateDefaultDays: []
  //   level: [],
  //   save_user: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SPR_RECON:
      return {
        ...state,
        spr: action.payload,
        action_by: action.action_by,
        ticket_origin: action.ticket_origin,
        originator: action.originator,
        department: action.department,
        machine_customer: action.machine_customer,
        customers: action.customers,
        machine: action.machine,
        machine_models: action.machine_models,
        customer: action.customer,
        technician: action.technician,
        blocked_by: action.blocked_by,
        contracts: action.contracts,
        sales: action.sales,
        service_code: action.service_code,
        part_replace: action.part_replace,
        contact_detail: action.contact_detail
        // departmentx: action.department,
        // level: action.level
      };
    case POST_SUB:
      return {
        ...state,
        resPostSubmit: action.payload
      };
    case PRINT_SPR:
      return {
        ...state,
        data_spr_recon: action.payload
        // action_by: action.action_by,
        // ticket_origin: action.ticket_origin,
        // originator: action.originator,
        // department: action.department,
        // machine_customer: action.machine_customer,
        // customers: action.customers,
        // machine: action.machine,
        // machine_models: action.machine_models,
        // customer: action.customer,
        // technician: action.technician,
        // blocked_by: action.blocked_by,
        // contracts: action.contracts,
        // sales: action.sales,
        // service_code: action.service_code,
        // spr_requests: action.spr_requests
      };
    //   case LIST_CUSTOMER_ADD:
    //     return {
    //       ...state,
    //       pm_default: action.pm_default,
    //       customers: action.customers
    //     };
    //   case STORE_PM_CUST:
    //     return {
    //       ...state,
    //       resStorePmCust: action.payload
    //     };

    //   case DEL_PM_CUST:
    //     return {
    //       ...state,
    //       resDelPmCust: action.payload
    //     };
    //     case UPDATE_DEFAULT_DAYS:
    //     return {
    //       ...state,
    //       resUpdateDefaultDays: action.payload
    //     };

    default:
      return state;
  }
}
