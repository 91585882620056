import React, { Component } from 'react';
//import PropTypes from "prop-types";

const Modal = props => {
  return(
    <div className={props.modalclass} id={props.modalid}>
      <div className={props.mdialog}>
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={props.onclose}>&times;</button>
            <h4 className="modal-title">{props.title}</h4>
          </div>
          {props.children}
        </div>
      </div>
    </div>
  )
}

Modal.defaultProps = {
  modalid: "default-modal",
  modalclass: "modal fade",
  mdialog: "modal-dialog"
};

export default Modal;
