import axios from "../axios-env.js";
import {
  GET_USERS,
  // LIST_FAULT_AREA,
  // LIST_PROBLEM,
  // XXX_DATA,
  // TICKET_HISTORY_DATA,
  TICKET_INBOX_DATA,
  // TICKET_INBOX_HELPDESK_DATA,
  LIST_USER,
  // LIST_USER_CE,
  // DETAIL_HISTORY,
  DETAIL_INBOX,
  PROSES_TICKET_HELPDESK,
  // PROSES_TICKET_ASSIGN_CE,
  DATA_CREATE_USER,
  SAVE_USER,
  RESET,
  DEL_INBOX,
  DEL_MESSAGE
} from "./types";

// Get all profiles
export const getUsers = () => dispatch => {
  axios
    .get("https://jsonplaceholder.typicode.com/users")
    .then(res =>
      dispatch({
        type: GET_USERS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_USERS,
        payload: null
      })
    );
};

export const ticketInbox = jwtToken => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  };

  axios
    .get("/api/v1/ticket-management/ticket-inbox", head)
    .then(res => {
      // console.log("resp");
      // console.log(res);
      // const { message, code, error } = res.data.meta;
      // if (message === "Internal Server Error") {
      //   window.location.href = "/";
      // }
      dispatch({
        type: TICKET_INBOX_DATA,

        ticket: res.data.data.ticket_inbox,
        ticket_type: res.data.data.ticket_type,
        ticket_status: res.data.data.ticket_status
      });
    })
    .catch(err =>
      dispatch({
        type: TICKET_INBOX_DATA,
        ticket: null,
        ticket_type: null,
        ticket_status: null
      })
    );
};

export const del_inbox = () => {
  // console.log("del dalam");
  return {
    type: DEL_INBOX,
    // payload: []
    ticket: [],
    ticket_type: [],
    ticket_status: []
  };
};

export const listUser = jwtToken => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  };
  axios
    .get("/api/v1/user", head)

    .then(res => {
      // console.log("ber2");
      // console.log(res);
      dispatch({
        type: LIST_USER,
        payload: res.data.data.user
      });
    })

    .catch(err =>
      dispatch({
        type: LIST_USER,
        payload: null
      })
    );
};

export const getCreateUser = jwtToken => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  };
  axios
    .get("/api/v1/data-master-user", head)
    .then(res =>
      dispatch({
        type: DATA_CREATE_USER,

        persons: res.data.data.level,
        user: res.data.data.user,
        branch_list: res.data.data.level,
        department: res.data.data.deparment
      })
    )
    .catch(err =>
      dispatch({
        type: DATA_CREATE_USER,
        persons: null,
        user: null,
        branch_list: null,
        department: null
      })
    );
};

export const saverUser = datax => dispatch => {
  // console.log("data2");

  const head2 = {
    headers: {
      Authorization: `Bearer ${datax ? datax.jwtToken : ""}`
    }
  };
  const data = datax ? datax.dt : "";
  // console.log("data2");
  // console.log(head2);
  // console.log(data);
  axios
    .post("/api/v1/user", data, head2)
    .then(res => {
      // console.log("res-nya");
      // console.log(res);

      // this.props.history.push({
      //   pathname: "/setting/user",
      //   data: res.data.meta.message
      // });
      dispatch({
        type: SAVE_USER,
        payload: res.data.meta
      });
    })
    // .then(res => {
    //   this.setState({ comments: res.data.meta.message });
    //   console.log("comment nya");
    //   console.log(comments);
    // })
    .catch(err =>
      dispatch({
        type: SAVE_USER,
        payload: null
      })
    );
};

export const reset = () => dispatch => {
  dispatch({
    type: RESET,
    payload: ""
  });
};
export const del_message = () => {
  // console.log("del dalam");
  return {
    type: DEL_MESSAGE,
    payload: []
  };
};
