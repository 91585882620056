import React from "react";
import moment from "moment";

const CustomerInformation = props => {
  return (
    <React.Fragment>
        <div className="panel panel-default">
            <div className="panel-heading">
                Customer Information
            </div>
            <div className="panel-body">
                <div className="form form-xs">
                    <div className="row row-xs">
                        <div className="col-md-6">
                            <fieldset>
                                <legend>Search By</legend> 
                                {/* <div className="form-group">
                                    <label className="control-label">Serial No</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" defaultValue={props.machine.machine_serial} disabled />
                                        <span className="input-group-btn">
                                            <button className="btn btn-default"><i className="fa fa-search"></i></button>
                                        </span>
                                    </div>
                                </div> */}
                                <div className="form-group">
                                    <label className="control-label">Equipment No</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" disabled />
                                        <span className="input-group-btn">
                                            <button className="btn btn-default"><i className="fa fa-search"></i></button>
                                        </span>
                                    </div>
                                </div>  
                                <div className="form-group">
                                    <label className="control-label">Notification No</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" disabled />
                                        <span className="input-group-btn">
                                            <button className="btn btn-default"><i className="fa fa-search"></i></button>
                                        </span>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Customer Name</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" defaultValue={props.machine_customer_detail_customer.CustomerName} disabled />
                                        <span className="input-group-btn">
                                            <button className="btn btn-default" data-toggle="modal" data-target="#modal-search-name" type="button"><i className="fa fa-search"></i></button>
                                            <button className="btn btn-default" data-toggle="modal" data-target="#modal-customer-multi" type="button">Multi</button>
                                        </span>
                                    </div>
                                </div>
                            </fieldset>
                            <div className="form-group">
                                <label className="control-label">Model</label>
                                <input type="text" className="form-control" defaultValue={props.machine_model.model_name} disabled />
                            </div>
                            <div className="form-group">
                                <label className="control-label">Customer Category</label>
                                <input type="text" className="form-control" defaultValue={props.machine_customer_detail_customer.customer_category} disabled />
                            </div>
                            <div className="form-group">
                                <label className="control-label">Preferred CE</label>
                                <input type="text" className="form-control" disabled />
                            </div>
                            <fieldset>
                                <legend>SHIP TO</legend>
                                <div className="form-group">
                                    <label className="control-label">Ship to Name</label>
                                    <input type="text" className="form-control" defaultValue = {props.machine_customer.ship_to_name} disabled />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Ship to Code</label>
                                    <input type="text" className="form-control" defaultValue = {props.machine_customer.ship_to_code} disabled />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Ship to Contact</label>
                                    <input type="text" className="form-control" defaultValue = {props.machine_customer.ship_to_contact_name} disabled />
                                </div>
                            </fieldset>
                            <fieldset>
                                <legend>CSRC</legend>
                                <div className="form-group">
                                    <label className="control-label">CSRC Status</label>
                                    <input type="text" className="form-control" defaultValue={props.machine_customer_detail_customer.csrc_status} disabled />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Last Date of Connection</label>
                                    <input type="text" className="form-control" disabled />
                                </div>
                            </fieldset>
                        </div>

                        <div className="col-md-6">
                            <fieldset>
                                <legend>BLOCK BY</legend>
                                <div className="form-group">
                                    <label className="control-label">Status</label>
                                    <input type="text" className="form-control" defaultValue = {props.machine_customer_detail_customer.blocked_status} disabled />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Department</label>
                                    <input type="text" className="form-control" defaultValue = {props.machine_block_by.blocked_by_department} disabled />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Name</label>
                                    <input type="text" className="form-control" defaultValue = {props.machine_block_by.blocked_by_user_name} disabled />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">User ID</label>
                                    <input type="text" className="form-control" defaultValue = {props.machine_block_by.blocked_by_user_id} disabled />
                                </div>
                            </fieldset>
                            <fieldset>
                                <legend>Contract Detail</legend>
                                <div className="form-group">
                                    <label className="control-label">Contract No</label>
                                    <input type="text" className="form-control" defaultValue={props.machine_contract.contract_number} disabled />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Start Date</label>
                                    <input  className="form-control" disabled />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">End Date</label>
                                    <input className="form-control" defaultValue = { moment(props.machine_contract.contract_end_date).format("L HH:mm")} disabled />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">User ID</label>
                                    <input type="text" className="form-control" defaultValue = {props.machine_contract.id} disabled />
                                </div>
                            </fieldset>
                            <fieldset>
                                <legend>Machine Extend</legend>
                                <div className="form-group">
                                    <label className="control-label">Start Date</label>
                                    <input type="date" className="form-control" disabled />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">End Date</label>
                                    <input type="date" className="form-control" disabled />
                                </div>
                            </fieldset>
                            <fieldset>
                                <legend>Sales Details</legend>
                                <div className="form-group">
                                    <label className="control-label">Sales Person Code</label>
                                    <input type="text" className="form-control" disabled defaultValue={props.customer_sales.Sales_Person_Code} />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Sales Person Name</label>
                                    <input type="text" className="form-control" disabled defaultValue={props.customer_sales.Sales_Person_name} />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">DSM Code</label>
                                    <input type="text" className="form-control" disabled defaultValue={props.customer_sales.dsm_code} />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">DSM Name</label>
                                    <input type="text" className="form-control" disabled defaultValue={props.customer_sales.dsm_name} />
                                </div>
                            </fieldset>
                        </div>
                    </div>

                    <fieldset>
                        <legend>Customer Details</legend>
                        <div className="form-group">
                            <label className="control-label">Ship to Address </label>
                            <textarea type = "text" name="" id="" cols="30" rows="3" className="form-control" value={props.machine_customer.ship_to_address} disabled></textarea>
                        </div>
                        <div className="form-group">
                            <label className="control-label">Location of Equipment No</label>
                            <textarea name="" id="" cols="30" rows="3" className="form-control" value={props.machine_customer.location} disabled></textarea>
                        </div>
                        <div className="row row-xs">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="" className="control-label">Telephone No</label>
                                    <input type="text" className="form-control" defaultValue = {props.machine_customer.ship_to_contact_phone} disabled />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="" className="control-label">Postal Code</label>
                                    <input type="text" className="form-control" defaultValue = {props.machine_customer.ship_to_postcode} disabled />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="" className="control-label">Delivery Date</label>
                                    <input type="text" className="form-control" defaultValue = {moment(props.machine_customer.delivery_date).format("L")} disabled />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>SLA DETAILS</legend>
                        <div className="form-group">
                            <label htmlFor="" className="control-label">SLA Response Time</label>
                            <input type="text" className="form-control" disabled />
                        </div>
                        <div className="form-group">
                            <label htmlFor="" className="control-label">Additional SLA Details</label>
                            <input type="text" className="form-control" disabled />
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </React.Fragment>
  );
};

export default CustomerInformation;
