import axios from "../axios-env";
export const SET_SEARCH_CUSTOMER_BY_SERIAL = "SET_SEARCH_CUSTOMER_BY_SERIAL";

export const setSearhCustomerBySerial = payload => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${payload.headers}`
    }
  };
  axios
    .get(
      `/api/v1/customers/history-ticket?machine_customer_id=${
        payload.id
      }&from&to`,
      head
    )
    .then(res => {
      dispatch({
        type: SET_SEARCH_CUSTOMER_BY_SERIAL,
        payload: res.data.data
      });
    })
    .catch(err => {
      console.log(err);
    });
};
