// import axios from "axios";
import { GET_SPR, CHECK_STOCK, ORDER_DB, ORDER_WSP, PRINT_S } from "./types";

import axios from "../axios-env";

export const get_spr_list = data => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${data.jwtToken}`
    }
  };
  // console.log("head asce");
  // console.log(head);

  axios
    .get(`/api/v1/ticket-process/detail-sparepart/${data.id}`, head)
    .then(res => {
      // console.log("res spr");
      // console.log(res.data.data);

      const { message, code, error } = res.data.meta;
      const { meta } = res.data;

      dispatch({
        type: GET_SPR,
        payload: res.data.data,
        action_by: res.data.data.action_by,
        // department: res.data.data.action_by.department,
        ticket_origin: res.data.data.ticket_origin,
        originator: res.data.data.ticket_origin.originator,
        department: res.data.data.ticket_origin.originator.department,

        machine_customer: res.data.data.ticket_origin.machine_customer,
        customers: res.data.data.ticket_origin.machine_customer.customers,
        machine: res.data.data.ticket_origin.machine_customer.machine,
        machine_models:
          res.data.data.ticket_origin.machine_customer.machine.machine_models,
        customer: res.data.data.ticket_origin.customer,
        technician: res.data.data.technician,
        blocked_by:
          res.data.data.ticket_origin.machine_customer.customers.blocked_by,
        contracts: res.data.data.ticket_origin.machine_customer.contracts,
        sales: res.data.data.ticket_origin.machine_customer.customers.sales,
        service_code: res.data.data.ticket_origin.service_code,
        tracking_spr: res.data.data.tracking_spr,
        contact_detail: res.data.data.ticket_origin.contact_detail
      });
    })
    .catch(err =>
      dispatch({
        type: GET_SPR,
        payload: null,
        action_by: null,

        ticket_origin: null,
        originator: null,
        department: null,
        machine_customer: null,
        customers: null,
        machine: null,
        machine_models: null,
        customer: null,
        technician: null,
        blocked_by: null,
        contracts: null,
        sales: null,
        service_code: null,
        tracking_spr: null,
        contact_detail: null
      })
    );
};

export const check_stock = datax => dispatch => {
  const headx = {
    headers: {
      Authorization: `Bearer ${datax.jwtToken}`
      // ,
      // Accept: "application/json",
      // "Content-Type": "application/json"
    }
    // Body: {
    //   sparepart: [
    //     {
    //       item_no: "4640392001"
    //     }
    //   ]
    // }
  };
  // console.log("head pm");
  // console.log(datax);

  axios
    .post("/api/v1/ticket-management/check-inventory-by-ce", datax.dat, headx)
    .then(res => {
      // console.log("res pm");
      // console.log(res.data);
      dispatch({
        type: CHECK_STOCK,
        payload: res.data.data,
        res_cek: res.data.meta
      });
    })
    .catch(err => {
      // console.log("res pm err");
      dispatch({
        type: CHECK_STOCK,
        payload: null,
        res_cek: null
      });
    });
};

export const order_to_db = data => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${data.jwtToken}`
    }
  };
  // console.log("head edit pm");
  // console.log(data);

  axios
    .post(`/api/v1/ticket-management/post-transfer-order`, data.ser, head)
    .then(res => {
      // console.log("res edit pm");
      // console.log(res.data);
      dispatch({
        type: ORDER_DB,
        payload: res.data.data
        // customer: res.data.data.customer
        // level: res.data.data.user.level
      });
    })
    .catch(err =>
      dispatch({
        type: ORDER_DB,
        payload: null
        // customer: null
        // level: null
      })
    );
};
export const order_to_wsp = data => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${data.jwtToken}`
    }
  };
  // console.log("head wsp");
  // console.log(data);

  axios
    .put(`/api/v1/ticket-management/transfer-order`, data.ser, head)
    .then(res => {
      // console.log("res wsp");
      // console.log(res.data);
      dispatch({
        type: ORDER_WSP,
        payload: res.data.data,
        res_wsp: res.data.meta
        // customer: res.data.data.customer
        // level: res.data.data.user.level
      });
    })
    .catch(err =>
      dispatch({
        type: ORDER_WSP,
        payload: null,
        res_wsp: null
        // customer: null
        // level: null
      })
    );
};
export const del_res_wsp = () => {
  // console.log("del dalam");
  return {
    type: ORDER_WSP,
    payload: [],
    res_wsp: []
  };
};
export const print_stn = data => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${data.jwtToken}`
    }
  };
  // console.log("head wsp");
  // console.log(data);

  axios
    .get(`/api/v1/ticket-management/print-stn/${data.id}`, head)
    .then(res => {
      // console.log("res wsp");
      // console.log(res.data);
      dispatch({
        type: PRINT_S,
        payload: res.data.data,
        transfer_order: res.data.data.transfer_order,
        spr: res.data.data.spr,
        res_stn: res.data.meta
        // customer: res.data.data.customer
        // level: res.data.data.user.level
      });
    })
    .catch(err =>
      dispatch({
        type: PRINT_S,
        payload: null,
        transfer_order: null,
        spr: null,
        res_stn: null
        // customer: null
        // level: null
      })
    );
};

//   export const update_pm_cust = off => dispatch => {
//     const head = {
//       headers: {
//         Authorization: `Bearer ${off.jwtToken}`
//       }
//     };
//     const datas = off ? off.dt : "";
//     console.log("head update pm");
//     console.log(datas);

//     axios
//       .put("/api/v1/setting/pm-customer/update", datas, head)
//       .then(res => {
//         console.log("res update pm");
//         console.log(res.data.meta);
//         dispatch({
//           type: UPDATE_PM_CUST,
//           payload: res.data.meta
//           // customer: res.data.data.customer
//           // level: res.data.data.user.level
//         });
//       })
//       .catch(err =>
//         dispatch({
//           type: UPDATE_PM_CUST,
//           payload: null
//           // customer: null
//           // level: null
//         })
//       );
//   };
//   export const update_default_days = off => dispatch => {
//     const head = {
//       headers: {
//         Authorization: `Bearer ${off.jwtToken}`
//       }
//     };
//     const datas = off ? off.dat : "";
//     console.log("head default days");
//     console.log(datas);

//     axios
//       .put("/api/v1/setting/pm-default/update", datas, head)
//       .then(res => {
//         console.log("res default days");
//         console.log(res.data.meta);
//         dispatch({
//           type: UPDATE_DEFAULT_DAYS,
//           payload: res.data.meta
//           // customer: res.data.data.customer
//           // level: res.data.data.user.level
//         });
//       })
//       .catch(err =>
//         dispatch({
//           type: UPDATE_DEFAULT_DAYS,
//           payload: null
//           // customer: null
//           // level: null
//         })
//       );
//   };

//   export const store_pm_cust = off => dispatch => {
//     const head = {
//       headers: {
//         Authorization: `Bearer ${off.jwtToken}`
//       }
//     };
//     const datas = off ? off.dt : "";
//     console.log("head STORE pm");
//     console.log(datas);

//     axios
//       .post("/api/v1/setting/pm-customer/create", datas, head)
//       .then(res => {
//         console.log("res store pm");
//         console.log(res.data.meta);
//         dispatch({
//           type: STORE_PM_CUST,
//           payload: res.data.meta
//           // customer: res.data.data.customer
//           // level: res.data.data.user.level
//         });
//       })
//       .catch(err =>
//         dispatch({
//           type: STORE_PM_CUST,
//           payload: null
//           // customer: null
//           // level: null
//         })
//       );
//   };
//   export const del_pm_cust = off => dispatch => {
//     const head = {
//       headers: {
//         Authorization: `Bearer ${off.jwtToken}`
//       }
//     };
//     const datas = off ? off.dt : "";
//     console.log("head del pm");
//     console.log(datas);

//     axios
//       .delete(`/api/v1/setting/pm-customer/delete/${off.id}`, head)
//       .then(res => {
//         console.log("res del pm");
//         console.log(res.data.meta);
//         dispatch({
//           type: DEL_PM_CUST,
//           payload: res.data.meta
//           // customer: res.data.data.customer
//           // level: res.data.data.user.level
//         });
//       })
//       .catch(err =>
//         dispatch({
//           type: DEL_PM_CUST,
//           payload: null
//           // customer: null
//           // level: null
//         })
//       );
//   };
