import React, { Component } from "react";
import axios from "axios";
import moment from "moment";

import Pagination from "../../common/Pagination";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ticketHistory } from "../../../actions/historyAction";
import { ClipLoader } from "react-spinners";
import DatePicker, { subDays } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
let head = "";
let table = "";
let pagi = "";

class HistoryTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticket_type: [],
      ticket_status: [],
      search: "",
      ticket_pic: [],
      ticket_stat_pic: [],
      currentTickets2: [],
      currentPage: null,
      totalPages: null,
      start_date: "",
      end_date: ""
    };
    this.onChanged = this.onChanged.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndtDate = this.handleEndtDate.bind(this);

    // console.log(this.props);
    const jwtToken = localStorage.getItem("jwtToken");
    this.props.ticketHistory(jwtToken);
    // console.log("did mount hisr");
  }

  componentDidMount() {
    // const jwtToken = localStorage.getItem("jwtToken");
    // this.props.ticketHistory(jwtToken);
    // console.log("did mount his");
  }

  // Function to handle pagination
  onPageChanged = data => {
    // const { tickets } = this.state;
    const { ticketHis } = this.props.tic_his;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentTickets2 = ticketHis.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentTickets2, totalPages });
  };

  // handleStartDate(event) {
  //   this.setState({ start_date: event.target.value });
  // }
  // handleEndtDate(event) {
  //   this.setState({ end_date: event.target.value });
  // }

  handleChange(event) {
    this.setState({ ticket_pic: event.target.value });
  }

  handleChange2(event) {
    this.setState({ ticket_stat_pic: event.target.value });
  }

  onChanged(e) {
    this.setState({ search: e.target.value.substr(0, 20) });
  }
  handleStartDate = start_date => this.setState({ start_date });
  handleEndtDate = end_date => this.setState({ end_date });

  render() {
    // const { customer } = this.props.tic_in;
    // console.log("resSaveAssign");
    // console.log(customer);
    // const { dd } = customer ? customer.error : "";
    // const { rr } = dd === true ? "errrrrrr" : "";
    if (this.props.tic_his.ticket_typeHis !== null) {
      if (this.props.tic_his.ticket_typeHis.length !== 0) {
        const {
          ticketHis,
          ticket_typeHis,
          ticket_statusHis
        } = this.props.tic_his;
        // console.log("ticketHisrr");
        // console.log(this.props.tic_his);
        ticket_typeHis.length = 9;
        let filtered = ticketHis.slice();
        if (this.state.search) {
          filtered = filtered.filter(ticket =>
            // console.log(
            //   "list cariii his",
            //   ticket.ticket_origin.machine_customer.item_no !== null
            //     ? ticket.ticket_origin.machine_customer.item_no.toLowerCase()
            //     : "",
            //   this.state.search
            // ),
            ticket.ticket_origin.machine_customer.item_no !== null
              ? ticket.ticket_origin.machine_customer.item_no
                  .toLowerCase()
                  .toString()
                  .indexOf(this.state.search.toLowerCase().toString()) !== -1
              : "" ||
                ticket.ticket_origin.machine_customer.machine.machine_serial !==
                  null
              ? ticket.ticket_origin.machine_customer.machine.machine_serial
                  .toLowerCase()
                  .toString()

                  .indexOf(this.state.search.toLowerCase().toString()) !== -1
              : "" ||
                ticket.ticket_origin.machine_customer.ship_to_name !== null
              ? ticket.ticket_origin.machine_customer.ship_to_name
                  .toLowerCase()
                  .toString()

                  .indexOf(this.state.search.toLowerCase().toString()) !== -1
              : ""
          );
        }
        // console.log("filtered............", filtered);
        if (this.state.ticket_pic) {
          filtered = filtered.filter(
            ticket =>
              ticket.ticket_type.name.indexOf(this.state.ticket_pic) !== -1
          );
        }

        if (this.state.ticket_stat_pic) {
          filtered = filtered.filter(
            ticket =>
              ticket.ticket_origin.ticket_status.name.indexOf(
                this.state.ticket_stat_pic
              ) !== -1
          );
        }

        if (
          this.state.start_date.length !== 0 ||
          this.state.end_date.length !== 0
        ) {
          filtered = filtered.filter(
            ticket =>
              moment(this.state.start_date).format("YYYY-MM-DD") <=
                ticket.created_at &&
              ticket.created_at <=
                moment(this.state.end_date)
                  .add(1, "days")
                  .format("YYYY-MM-DD")
            // this.state.start_date <= ticket.created_at &&
            // ticket.created_at <= this.state.end_date
          );
        }

        const {
          currentTickets2,
          ticket_pic,
          ticket_stat_pic,
          search,
          ticket_type,
          ticket_status,
          start_date,
          end_date
        } = this.state;

        const totalTickets = ticketHis.length;

        if (totalTickets === 0) return null;
        // console.log(filtered);
        head = (
          <div className="panel-body form-xs">
            <div className="pull-left form-inline">
              {/* <label htmlFor="">From</label> */}
              {/* <input
                type="date"
                className="form-control"
                style={{ width: "130px" }}
                onChange={this.handleStartDate}
                name="start_date"
                value={start_date}
              /> */}
              <DatePicker
                className="form-control "
                selected={this.state.start_date}
                onChange={this.handleStartDate}
                style={{ width: "130px" }}
                selectsStart
                startDate={this.state.start_date}
                endDate={this.state.end_date}
                isClearable={start_date}
                placeholderText="Start date"
              />
              <label htmlFor="">{/* To */}-</label>
              {/* <input
                type="date"
                className="form-control"
                style={{ width: "130px" }}
                onChange={this.handleEndtDate}
                name="end_date"
                value={end_date}
              /> */}
              <DatePicker
                className="form-control "
                selected={this.state.end_date}
                onChange={this.handleEndtDate}
                style={{ width: "130px" }}
                selectsEnd
                startDate={this.state.start_date}
                endDate={this.state.end_date}
                minDate={this.state.start_date}
                isClearable={end_date}
                placeholderText="End date"
              />
              <label htmlFor="">Ticket Type </label>
              <select
                // name=""
                // id=""
                style={{ width: "100px" }}
                className="form-control-xs"
                onChange={this.handleChange}
                // value={ticket_pic}
                name="ticket_pic"
                // multiple={false}
              >
                <option value="">-</option>

                {ticket_typeHis
                  ? ticket_typeHis.map(tic => (
                      <option value={tic.name} key={tic.id}>
                        {tic.name}
                      </option>
                    ))
                  : ""}
              </select>
              <label htmlFor="">Status Ticket {ticket_stat_pic} </label>
              <select
                // name=""
                // id=""
                style={{ width: "100px" }}
                className="form-control-xs"
                onChange={this.handleChange2}
                // value={ticket_stat_pic}
                name="ticket_stat_pic"
                // multiple={true}
              >
                <option value="">-</option>

                {ticket_statusHis.map(tic => (
                  <option value={tic.name} key={tic.id}>
                    {tic.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="pull-right form-inline">
              <label htmlFor="">Serial, Equipment or Name</label>
              <input
                type="text"
                className="form-control"
                style={{ width: "100px" }}
                value={search}
                onChange={this.onChanged.bind(this)}
                placeholder="search"
              />
              {/* <button className="btn btn-default">Filter</button> */}
            </div>
            <div className="clearfix" />
          </div>
        );
        table = (
          <div className="table-responsive form-xs">
            <table className="table table-xs table-bordered">
              <thead>
                <tr>
                  <th>Date Time</th>
                  <th>From</th>
                  <th>Service Order</th>
                  <th>Equipment No</th>
                  <th>Serial Number</th>
                  <th>Model No</th>
                  <th>Shipping Name</th>
                  <th>Ticket No</th>
                  <th>Ticket Type</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {search === "" &&
                ticket_pic.length === 0 &&
                ticket_stat_pic.length === 0 &&
                start_date.length === 0 &&
                end_date.length === 0
                  ? currentTickets2.map(ticket => (
                      <tr key={ticket.id}>
                        <td>
                          {ticket.created_at === null
                            ? "-"
                            : moment(ticket.created_at).format("L")}
                        </td>
                        <td>
                          {" "}
                          {ticket.action_by.username === null
                            ? "-"
                            : ticket.action_by.username}{" "}
                        </td>
                        <td>
                          {" "}
                          {ticket.ticket_origin.service_code.job_code === null
                            ? "-"
                            : ticket.ticket_origin.service_code.job_code}{" "}
                        </td>
                        <td>
                          {" "}
                          {ticket.ticket_origin.machine_customer.item_no ===
                          null
                            ? "-"
                            : ticket.ticket_origin.machine_customer
                                .item_no}{" "}
                        </td>
                        <td>
                          {" "}
                          {ticket.ticket_origin.machine_customer.machine
                            .machine_serial === null
                            ? "-"
                            : ticket.ticket_origin.machine_customer.machine
                                .machine_serial}{" "}
                        </td>
                        <td>
                          {" "}
                          {ticket.ticket_origin.machine_customer.machine
                            .machine_models.model_name === null
                            ? "-"
                            : ticket.ticket_origin.machine_customer.machine
                                .machine_models.model_name}{" "}
                        </td>
                        <td>
                          {" "}
                          {ticket.ticket_origin.machine_customer
                            .ship_to_name === null
                            ? "-"
                            : ticket.ticket_origin.machine_customer
                                .ship_to_name}{" "}
                        </td>
                        <td>
                          {" "}
                          {ticket.ticket_number === null
                            ? "-"
                            : ticket.ticket_number}{" "}
                        </td>
                        <td>
                          {" "}
                          {ticket.ticket_type.name === null
                            ? "-"
                            : ticket.ticket_type.name}{" "}
                        </td>
                        <td>
                          {" "}
                          {ticket.ticket_status.name === null
                            ? "-"
                            : ticket.ticket_status.name}{" "}
                        </td>
                        <td>
                          <div className="row">
                            <div className="col-md-2">
                              <Link
                                to={`/ticket-management/detailhistoryticket/${ticket.ticket_origin.id}`}
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                >
                                  SELECT
                                  {/* {ticket.ticket_origin.id} */}
                                </button>
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  : filtered.map(ticket => (
                      <tr key={ticket.id}>
                        <td>
                          {ticket.created_at === null
                            ? "-"
                            : moment(ticket.created_at).format("L")}
                        </td>
                        <td>
                          {" "}
                          {ticket.action_by.username === null
                            ? "-"
                            : ticket.action_by.username}{" "}
                        </td>
                        <td>
                          {" "}
                          {ticket.ticket_origin.service_code.job_code === null
                            ? "-"
                            : ticket.ticket_origin.service_code.job_code}{" "}
                        </td>
                        <td>
                          {" "}
                          {ticket.ticket_origin.machine_customer.item_no ===
                          null
                            ? "-"
                            : ticket.ticket_origin.machine_customer
                                .item_no}{" "}
                        </td>
                        <td>
                          {" "}
                          {ticket.ticket_origin.machine_customer.machine
                            .machine_serial === null
                            ? "-"
                            : ticket.ticket_origin.machine_customer.machine
                                .machine_serial}{" "}
                        </td>
                        <td>
                          {" "}
                          {ticket.ticket_origin.machine_customer.machine
                            .machine_models.model_name === null
                            ? "-"
                            : ticket.ticket_origin.machine_customer.machine
                                .machine_models.model_name}{" "}
                        </td>
                        <td>
                          {" "}
                          {ticket.ticket_origin.machine_customer
                            .ship_to_name === null
                            ? "-"
                            : ticket.ticket_origin.machine_customer
                                .ship_to_name}{" "}
                        </td>
                        <td>
                          {" "}
                          {ticket.ticket_number === null
                            ? "-"
                            : ticket.ticket_number}{" "}
                        </td>
                        <td>
                          {" "}
                          {ticket.ticket_type.name === null
                            ? "-"
                            : ticket.ticket_type.name}{" "}
                        </td>
                        <td>
                          {" "}
                          {ticket.ticket_status.name === null
                            ? "-"
                            : ticket.ticket_status.name}{" "}
                        </td>
                        <td>
                          <div className="row">
                            <div className="col-md-2">
                              <Link
                                to={`/ticket-management/detailhistoryticket/${ticket.id}`}
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                >
                                  SELECT
                                  {/* {ticket.id} */}
                                </button>
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        );
        pagi = (
          <div>
            {search === "" &&
            ticket_pic.length === 0 &&
            ticket_stat_pic.length === 0 &&
            start_date.length === 0 &&
            end_date.length === 0 ? (
              <div className="panel-footer form-xs">
                <Pagination
                  totalRecords={totalTickets}
                  pageLimit={4}
                  pageNeighbours={1}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        );
      }
    }
    return (
      <div className="panel panel-default">
        <div className="panel-heading">Ticket History</div>
        <div>
          {head
            ? head
            : // <div style={{ textAlign: "center" }}>

              //   <br />
              //   <i
              //     className="fa fa-spinner fa-spin fa-2x"
              //     style={{ opacity: "0.3" }}
              //   />
              //   <br />
              //   <br />
              // </div>
              ""}
        </div>
        {table ? (
          table
        ) : (
          <div className="table-responsive form-xs">
            <table className="table table-xs table-bordered">
              <thead>
                <tr>
                  <th>Date Time</th>
                  <th>From</th>
                  <th>Service Order</th>
                  <th>Equipment No</th>
                  <th>Serial Number</th>
                  <th>Model No</th>
                  <th>Shipping Name</th>
                  <th>Ticket No</th>
                  <th>Ticket Type</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="11">
                    <div style={{ textAlign: "center" }}>
                      No Data Available in This Table
                      <br />
                      {/* <ClipLoader sizeUnit={"px"} size={50} color={"#123abc"} /> */}
                      <i
                        className="fa fa-spinner fa-spin fa-2x"
                        style={{ opacity: "0.3" }}
                      />
                      <br />
                      <br />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {/* <div>{table ? table : ""}</div> */}
        <div>{pagi ? pagi : ""}</div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  // ticketIn: state.ticketIn,
  // ticket_type: state.ticket_typeInHis,
  // ticket_status: state.ticket_statusIn

  tic_his: state.user
});
export default connect(
  mapStateToProps,
  { ticketHistory }
)(HistoryTicket);
