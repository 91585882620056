import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import userReducer from "./userReducer";
import originationReducer from "./originationReducer";
import installationReducer from "./installationReducer";
import assignCeReducer from "./assignCeReducer";
import helpDeskReducer from "./helpDeskReducer";

import historyReducer from "./historyReducer";
import trackingReducer from "./ticketTrackingReducer";
import consumableReducer from "./consumableReducer";
import listToner from "./listTonerReducer";
import calendarReducer from "./calendarReducer";
import mapReducer from "./mapReducer";
import edituserReducer from "./userRealReducer";
import flashReducer from "./flashReducer";
import pmReducer from "./pmReducer";
import pmMachineReducer from "./pmMachineReducer";
import unassignReducer from "./unassignReducer";
import sprReducer from "./sprReducer";
import sprReconReducer from "./sprReconReducer";
import holidayReducer from "./calendar_settingReducer";
import workingReducer from "./calendarWorkingReducer";
import searchName from "./searchNameReducer";
import searchSerial from "./searchSerialReducer";
import quotationReducer from "./quotationReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  customerOrigin: originationReducer,
  installation: installationReducer,
  user: userReducer,
  assignCe: assignCeReducer,
  helpDesk: helpDeskReducer,
  historyRed: historyReducer,
  trackingReducer: trackingReducer,
  consumable: consumableReducer,
  calendar: calendarReducer,
  map_location: mapReducer,
  edit_userid: edituserReducer,
  list_toner: listToner,
  flash: flashReducer,
  list_pm: pmReducer,
  pm_machineRed: pmMachineReducer,
  unassign_Reducer: unassignReducer,
  spr_Reducer: sprReducer,
  holiday_Reducer: holidayReducer,
  searchName: searchName,
  searchSerial: searchSerial,
  workingReducer: workingReducer,
  spr_reconReducer: sprReconReducer,
  quotation: quotationReducer
});
