import { ADD_FLASH, DELETE_FLASH } from "../actions/types";

const initialState = {
  notification: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
  case ADD_FLASH:
    return {
      ...state,
      notification: action.payload
    }
  case DELETE_FLASH:
   return {
      ...state,
      notification: undefined
    }
  default:
    return state;
  }
}









