import React from "react";
import PropTypes from "prop-types";

const LoadingModal = (
  {
    //   dcname,
    //   type,
    //   label,
    //   value,
    //   cname,
    //   name,
    //   onChange
  }
) => {
  return (
    <div className="modal display-block">
      <div className="modal-dialog modal-sm">
        <i
          className="fa fa-spinner fa-spin fa-3x"
          style={{
            opacity: "0.3",
            top: "50%",
            left: "50%",
            position: "absolute",
            color: "#ffffff"
          }}
        />
      </div>
    </div>
    // <div className={dcname}>
    //   <label className="control-label"> {label} </label>
    //   <input
    //     type={type}
    //     className={cname}
    //     name={name}
    //     value={value}
    //     onChange={onChange}
    //   />
    // </div>
  );
};

// TextFormControl.propTypes = {
//   //   type: PropTypes.string.isRequired
// };

// TextFormControl.defaultProps = {
//   //   type: "text",
//   //   cname: "form-control",
//   //   dcname: "form-group"
// };

export default LoadingModal;
