import React, { Component } from "react";

class ModalMachine extends Component {
  componentDidMount() {
    this.selectRow2();
  }
  selectRow2 = () => {
    let index,
      table = document.getElementById("table2");
    for (let i = 1; i < table.rows.length; i++) {
      table.rows[i].onclick = function() {
        // remove the background from the previous selected row
        if (typeof index !== "undefined") {
          table.rows[index].classList.toggle("selected");
        }
        // get the selected row index
        index = this.rowIndex;
        // add class selected to the row
        this.classList.toggle("selected");
      };
    }
  };
  render() {
    const { machine, onClick } = this.props;
    return (
      <tr style={{ cursor: "pointer" }} onClick={onClick}>
        <td>{machine.serial_no}</td>
        <td>{machine.equipment_no}</td>
        <td> {machine.model} </td>
        <td> {machine.ship_to_address} </td>
      </tr>
    );
  }
}

export default ModalMachine;
