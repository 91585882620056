import axios from "../axios-env";

export const creataAdditionalSla = manualSla => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${manualSla ? manualSla.jwtToken : ""}`
    }
  };

  axios
    .post("/api/v1/setting/additional-sla/manual/add", manualSla, head)
    .then(res => {
      if (res.data.meta.code === 200) {
        console.log("manual sla has been created");
      }
    })
    .catch(err => {
      console.log(err);
    });
};
