import React, { Component } from "react";
import Tabs from "./Tabs";

require("./styles.css");

class Document extends Component {
  render() {
    return (
      <div className="panel panel-default">
        <div className="panel-heading">Document</div>
        <Tabs>
          <div label="Origination">
            {/* <div className="btn btn-warning">Not data from API yet</div> */}
            {/* <table className="table table-bordered table-xs">
              <thead>
                <tr>
                  <th width="50">No</th>
                  <th>Document Order</th>
                  <th width="50">Action</th>
                </tr>
              </thead>
              <tbody> */}
            {/* <tr>
                  <td>1</td>
                  <td>IV827319</td>
                  <td className="text-nowrap">
                    <a href="foo" className="btn btn-default btn-sm">
                      Select
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>IV827319</td>
                  <td className="text-nowrap">
                    <a href="foo" className="btn btn-default btn-sm">
                      Select
                    </a>
                  </td>
                </tr> */}
            {/* <tr>
                  <p style={{ textAlign: "center" }}> No data </p>
                </tr>
              </tbody>
            </table> */}
            <p style={{ textAlign: "center" }}> No data </p>
          </div>
          <div label="Service Order">
            <p style={{ textAlign: "center" }}> No data </p>
          </div>
          <div label="Service Report">
            <p style={{ textAlign: "center" }}> No data </p>
          </div>
          <div label="MFP PM Checklist">
            <p style={{ textAlign: "center" }}> No data </p>
          </div>
          <div label="PP PM Checklist">
            <p style={{ textAlign: "center" }}> No data </p>
          </div>
          <div label="Invoice">
            <p style={{ textAlign: "center" }}> No data </p>
          </div>
          <div label="Quotation">
            <p style={{ textAlign: "center" }}> No data </p>
          </div>
          <div label="Delivery Note">
            <p style={{ textAlign: "center" }}> No data </p>
          </div>
          <div label="STN">
            <p style={{ textAlign: "center" }}> No data </p>
          </div>
          <div label="CSS">
            <p style={{ textAlign: "center" }}> No data </p>
          </div>
        </Tabs>
      </div>
    );
  }
}

export default Document;
