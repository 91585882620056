import axios from "axios";
import { GET_ORIGINATION_CUSTOMER, DELETE_ORIGINATION_CUSTOMER } from "./types";

export const getOriginationCustomer = searchKey => async dispatch => {
  const res = await axios
        .get("");
  dispatch( {
    type: GET_ORIGINATION_CUSTOMER,
    payload: res.data
  });
}

export const deleteOriginationCustomer  = () => async dispatch => {
  dispatch( {
    type: DELETE_ORIGINATION_CUSTOMER
  });
}




















