import { LIST_TONER_CONSUMABLE } from "./types";
import axios from "../axios-env";

export const listToner = jwtToken => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  };
  axios
    .get("/api/v1/ticket-management/toner-stock", head)
    .then(res => {
      dispatch({
        type: LIST_TONER_CONSUMABLE,
        payload: res.data.data
      });
    })
    .catch(err => {
      console.log(err);
    });
};
