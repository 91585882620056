import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

const defaultButton = props => <button {...props}>{props.children}</button>;

export default class PaginationReactTable extends React.Component {
  constructor(props) {
    super();

    this.changePage = this.changePage.bind(this);

    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages)
    };
  }

  static propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    PageButtonComponent: PropTypes.any,
    onPageChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string
  };

  componentWillReceiveProps(nextProps) {
    this.changePage(nextProps.page + 1);

    if (this.props.pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages)
      });
      this.props.onPageChange(1 - 1); //----------------- reset 1 on change filter---------------
    }

    // if (this.props.page + 1 > nextProps.pages) {
    //   const visiblePages = this.getVisiblePages(1, this.props.pages);

    //   this.setState({
    //     visiblePages: this.filterPages(visiblePages, this.props.pages)
    //   });

    //   this.props.onPageChange(1 - 1);
    //   this.setState({ nu_page: "" });
    // }
    if (this.props.page === ".") {
      this.setState({ nu_page: "" });
    }
    // console.log("pge", nextProps.pages);
    // console.log("ph", this.props.pages);
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  // changePage(page) {
  changePage = page => {
    // this.setState({ nu_page: "" });
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = this.getVisiblePages(page, this.props.pages);

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages)
    });

    this.props.onPageChange(page - 1);

    // if (activePage >= this.props.pages) {
    //   const visiblePages = this.getVisiblePages(1, this.props.pages);

    //   this.setState({
    //     visiblePages: this.filterPages(visiblePages, this.props.pages)
    //   });

    //   this.props.onPageChange(1 - 1);
    // }
    // console.log("pge", this.props.pages);
  };
  delNu = e => {
    this.setState({ nu_page: "" });
  };
  inputPage = e => {
    this.setState({ [e.target.name]: e.target.value.split(".").join("") });
    const activePage = this.props.page + 1;

    if (e.target.value.split(".").join("") === activePage) {
      return;
    }

    const visiblePages = this.getVisiblePages(
      e.target.value.split(".").join(""),
      this.props.pages
    );

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages)
    });

    this.props.onPageChange(e.target.value.split(".").join("") - 1);
    if (e.target.value.split(".").join("") === "") {
      const visiblePages = this.getVisiblePages(1, this.props.pages);

      this.setState({
        visiblePages: this.filterPages(visiblePages, this.props.pages)
      });

      this.props.onPageChange(1 - 1);
    }
    // if (e.target.value === ".") {
    //   console.log("hapus");
    // }

    // console.log("sembarang", this.props.page);
  };

  render() {
    const { PageButtonComponent = defaultButton } = this.props;
    const { visiblePages } = this.state;
    const activePage = this.props.page + 1;

    return (
      <div className="p-10">
        <ul className="pagination">
          {/* <div className="Table__pagination"> */}
          {/* <div className="Table__prevPageWrapper"> */}
          {/* <PageButtonComponent
            className="Table__pageButton"
            onClick={() => {
              if (activePage === 1) return;
              this.changePage(activePage - 1);
            }}
            disabled={activePage === 1}
          >
            {this.props.previousText}
          </PageButtonComponent> */}

          <li className="page-item">
            <a
              className="page-link"
              // href="javascript: void(0)"
              // onClick={() => {
              //   if (activePage === 1) return;
              //   this.changePage(activePage - 1);
              // }}
              onClick={() => {
                if (activePage === 1) return;
                this.changePage(activePage - 1);
                this.delNu();
              }}
              disabled={activePage === 1}
              className="Table__pageButton"
            >
              {this.props.previousText}
            </a>
          </li>
          {/* </div> */}
          {/* <div className="Table__visiblePagesWrapper"> */}
          {visiblePages.map((page, index, array) => {
            return (
              // <PageButtonComponent
              //   key={page}
              //   className={
              //     activePage === page
              //       ? "Table__pageButton Table__pageButton--active"
              //       : "Table__pageButton"
              //   }
              //   onClick={this.changePage.bind(null, page)}
              // >
              //   {array[index - 1] + 2 < page ? `...${page}` : page}
              // </PageButtonComponent>

              <li
                key={index}
                className="page-item"
                className={`page-item${activePage === page ? " active" : ""}`}
              >
                <a
                  className="page-link"
                  // href="javascript: void(0)"
                  // onClick={this.changePage.bind(null, page)}
                  onClick={() => {
                    this.changePage(page);
                    this.delNu();
                  }}
                  className={
                    activePage === page
                      ? "Table__pageButton Table__pageButton--active"
                      : "Table__pageButton"
                  }
                >
                  {array[index - 1] + 2 < page ? `...${page}` : page}
                </a>
              </li>
            );
          })}
          {/* </div> */}
          {/* <div className="Table__nextPageWrapper"> */}
          {/* <PageButtonComponent
            className="Table__pageButton"
            onClick={() => {
              if (activePage === this.props.pages) return;
              this.changePage(activePage + 1);
            }}
            disabled={activePage === this.props.pages}
          >
            {this.props.nextText}
          </PageButtonComponent> */}

          <li className="page-item">
            <a
              className="page-link"
              // href="javascript: void(0)"
              // onClick={() => {
              //   if (activePage === this.props.pages) return;
              //   this.changePage(activePage + 1);
              // }}
              onClick={() => {
                if (activePage === this.props.pages) return;
                this.changePage(activePage + 1);
                this.delNu();
              }}
              disabled={activePage === this.props.pages}
              className="Table__pageButton"
            >
              {this.props.nextText}
            </a>
          </li>

          {/* </div> */}
          {/* </div> */}

          <li className="page-item">
            &nbsp; &nbsp; &nbsp;
            {/* <input
              type="number"
              style={{ width: "45px", textAlign: "center" }}
              onChange={this.inputPage}
              value={this.state.nu_page}
              className="Table__pageInput"
              name="nu_page"
              placeholder="..."
              // pattern="[0-9]*"
              min="1"
            ></input> */}
            <NumberFormat
              className="form-control"
              // format="# # # #"
              // mask="_"
              // name="input_otp"
              // value={this.state.input_otp}
              // onChange={this.input_otp}
              // disabled={button === "disabled" ? "disabled" : ""}
              // style={{ textAlign: "center" }}
              style={{ width: "45px", textAlign: "center" }}
              onChange={this.inputPage}
              value={this.state.nu_page}
              className="Table__pageInput"
              name="nu_page"
              placeholder="..."
              // pattern="[0-9]*"
              allowNegative={false}
              // decimalScale="1"
              min="1"
            />
            {/* {this.state.nu_page} */}
          </li>
        </ul>
      </div>
    );
  }
}
