import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const initialState = {};

const middleware = [thunk];

const machine = process.env.REACT_APP_MACHINE;

const store =
  machine === "PRODUCTION"
    ? createStore(rootReducer, initialState, applyMiddleware(...middleware))
    : createStore(
        rootReducer,
        initialState,
        compose(
          applyMiddleware(...middleware),
          window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
        )
      );
export default store;
