import React from "react";
import PropTypes from "prop-types";

const TextInputGroup = ({ type, label, cname, name, value, onChange, onKeyDown, onClick, disabled, dcname, btext, clabel}) => {
  return (
    <div className={dcname}>
      <label className={clabel}>{label}</label>
      <div className="input-group">
        <input
          type={type}
          className={cname}
          value={value}
          name={name}
          onChange={onChange}
          onKeyDown={onKeyDown}
          disabled={disabled}
        />
        <span className="input-group-btn">
          <button className="btn btn-default" onClick={onClick}>
            {btext ? btext : 
             <i className="fa fa-search" />
            }
          </button>
        </span>
      </div>
    </div>
  );
};

TextInputGroup.propTypes = {
  type: PropTypes.string.isRequired
};

TextInputGroup.defaultProps = {
  type: "text",
  cname: "form-control",
  dcname: "form-group",
  clabel: "control-label"
};

export default TextInputGroup;
