import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
import axios from '../../../axios-env';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
//import GoogleMapReact from "google-map-react";
import iconRed from "../../../img/icon-red.png";

import LayoutContainer from "../../layout/Container";
import CeJobModal from './ceJobModal';
import InfoWindowEx from './infoWindowEx';
import moment from 'moment';
const headers = { headers:{ Authorization: `Bearer ${localStorage.getItem("jwtToken")}`}};

class SettingContainer extends Component {
  constructor(props){
    super(props);
    this.state = {
      listCe: [],
      listJob: [],
      selectedCE: "",
      ceDetail: "",
      showCeJobModal: false,
      alert_type: '',
      alert_message: '',
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
    }
  }

  componentDidMount(){
    
    axios.get('/api/v1/ticket-management/location-all-ce', headers).then(res => {
      const meta = res.data.meta; const data = res.data.data;
      if(meta.code === 200){
        if(data.length > 0){
          this.setState({listCe: data})
        }
      }else{
        this.setState({...this.state, alert_type: 'error', alert_message: 'Failed to Load Data'});
      }
      
    }).catch(error => {
      this.setState({...this.state, alert_type: 'error', alert_message: 'Failed to Load Data'});
    });
  }
  
  onChange = e => this.setState({ [e.target.name]: e.target.value });
  
  locateCeHandler = e => {
    axios.get('/api/v1/ticket-management/check-location-ce?id_ce='+this.state.selectedCE, headers).then(res => {
      const meta = res.data.meta; const data = res.data.data;
      if(meta.code === 200){
        if(data.longitude && data.latitude){
          this.setState({ceLat: data.latitude, ceLong: data.longitude, ceDetail: data});
        }
      }else{
        this.setState({...this.state, alert_type: 'error', alert_message: 'Failed to Load Data'});
      }
      
    }).catch(error => {
      this.setState({...this.state, alert_type: 'error', alert_message: 'Failed to Load Data'});
    });
  }

  ceJobHandler = e => {
    axios.get('/api/v1/ticket-process/show-job-paginate-ce/1?id_ce='+this.state.selectedCE, headers).then(res => {
      const meta = res.data.meta; const data = res.data.data;
      if(meta.code === 200){
        this.setState({listJob: data.job});
        this.toggleCeJobModal();
      }else{
        this.setState({...this.state, alert_type: 'error', alert_message: 'Failed to Load Data'});
      }
      
    }).catch(error => {
      this.setState({...this.state, alert_type: 'error', alert_message: 'Failed to Load Data'});
    });
  }

  onMarkerClick = (props , marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  } 
  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };

  toggleInfoWindow = e => this.setState({showingInfoWindow: !this.state.showingInfoWindow});
  toggleCeJobModal = e => this.setState({showCeJobModal: !this.state.showCeJobModal});
  
  render() {
    return (
      <LayoutContainer pagename="Location" pagedetailname="" sidebarinfo="Location">
        <div id="main" className="main-xs">
          <div className="row row-xs">
            <div className="col-md-3">
              <div className="panel panel-default">
                <div className="panel-body form-xs">
                  <div className="form-group">
                    <label>Select CE</label>
                    <select name="selectedCE" id="" className="form-control" onChange={this.onChange} value={this.state.selectedCe}>
                      <option value="">Select</option>
                      {this.state.listCe.length > 0 ? this.state.listCe.map(ce => <option key={ce.id} value={ce.id}>{ce.username + ' (' + ce.fullname + ' ' + ce.id +')'}</option>) : ''}
                    </select>
                  </div>
                  <div className="form-group">
                    <button className="btn btn-default btn-block" onClick={this.locateCeHandler}>Locate</button>
                  </div>
                   <div className="form-group">
                    <button className="btn btn-default btn-block" onClick={this.ceJobHandler}>Job Schedule</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div id="map_canvas" style={{height:"calc(100vh - 150px)"}} >
                <Map
                  google={this.props.google}
                  initialCenter= {{ lat: 1.3585695, lng: 103.8012966 }}
                  center={this.state.ceDetail.latitude != null && this.state.ceDetail.longitude != null ?  {lat: this.state.ceDetail.latitude, lng: this.state.ceDetail.longitude } : { lat: 1.3585695, lng: 103.8012966 }}
                  zoom={this.state.ceDetail.latitude != null && this.state.ceDetail.longitude != null ? 12 : 12}
                  onClick={this.onMapClicked}>
                  {this.state.ceDetail.latitude != null && this.state.ceDetail.longitude != null ?
                   <Marker
                     id={1}
                     title={'Location of CE '+ this.state.ceDetail.username}
                     name={this.state.ceDetail.username}
                     icon={iconRed}
                     position={{lat: this.state.ceDetail.latitude, lng: this.state.ceDetail.longitude}}
                     onClick={this.onMarkerClick}>
                     
                   </Marker>
                   : ''}
                  <InfoWindowEx
                    marker={this.state.activeMarker}
                    visible={this.state.showingInfoWindow}>
                    <div>
                      Technician: {this.state.ceDetail.username + ' | ' +this.state.ceDetail.fullname}<br/>
                      Last Updated Location : { moment(this.state.ceDetail.updated_at).format('L hh:mm') }<br/>
                      {/* Status : ON JOB Job Load =  2<br/> */}
                      {/* Customer : Ye. Wono (Advocate &amp; Solicitor)<br/> */}
                      {/* LOT W17A1, 17 Floor, West Wisma Selancar Dredging, No 142 C, Jalan Ampang<br/> */}
                      <hr/>
                      <button className="btn btn-primary pull-right" type="button" onClick={this.ceJobHandler}>
                        Show CE Jobs
                      </button>
                    </div>
                  </InfoWindowEx>
                </Map>
              </div>
            </div>
          </div>
        </div>
        {this.state.showCeJobModal
         ? <CeJobModal listJob={this.state.listJob}
             modalclass="modal display-block modal-large" onclose={this.toggleCeJobModal}/> : ''}
      </LayoutContainer>

    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyD8kVr5dj4fb_s-s80rqu8mbehkHKRXgFY"
})(SettingContainer);
