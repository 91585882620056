import React from 'react';
import moment from 'moment';
import DatePicker, { subDays } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SpanError from "../../../common/SpanError";
import './ContactDetail.css';

const ContactDetail = props => {

  let cocVal = 'required';
  if(props.tcoc == 'Telephone'){
    cocVal = 'required|phone';
  }else{
    cocVal = 'required|email';
  }
  
  return (
    <div className="panel panel-default contact-detail" style={{overflow: "visible"}}>
      <div className="panel-heading">Contact Details</div>
      <div className="panel-body">
        <div className="form form-xs">
          <div className="row row-xs">
            {/* <div className="col-md-6"> */}
              <div className={props.validator.message('date_of_contact', props.doc, 'required') ? 'form-group has-error' : 'form-group'}>
                <label style={{width:"196px"}}>Date/Time Contact</label>
                <DatePicker
                  selected={props.doc}
                  onChange={props.hChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  dateFormat="MM/dd/yyyy HH:mm"
                  timeCaption="time"
                  className="form-control datetimecontact"
    //value={this.state.date_of_contact}
                  name="date_of_contact"
                />
              </div>
              {props.validator.message('date_of_contact', props.doc, 'required') ? <SpanError>{props.validator.message('date_of_contact', props.doc, 'required')}</SpanError> : ''}
            {/* </div> */}
            {/* <div className="col-md-6"> */}
              <div className={props.validator.message('contact_name', props.cn, 'required') ? 'form-group has-error' : 'form-group'}>
                <label className="control-label" style={{width:"150px"}}>Contact Name</label>
                <select name="gender" className="form-control" style={{width: "auto"}} onChange={props.onChange}>
                  <option value="Mr">Mr</option>
                  <option value="Ms">Ms</option>
                </select>
                <input type="text" className="form-control" name="contact_name" style={{minWidth: "140px", maxWidth:"200px"}} value={props.cn} onChange={props.onChange}/>
              </div>
              {props.validator.message('contact_name', props.cn, 'required') ? <SpanError style={{marginLeft: '80px !important'}}>{props.validator.message('contact_name', props.cn, 'required')}</SpanError> : ''}
              <div className={props.validator.message('channel_of_contact', props.coc, cocVal) ? 'form-group has-error' : 'form-group'}>
                <label className="control-label" style={{width:"150px"}}>Channel of Contact</label>
                <select name="type_channel_of_contact" id="" className="form-control" style={{width: "auto"}} onChange={props.onChange}>
                  <option value="Telephone">Telephone</option>
                  <option value="Email">Email</option>
                </select>
                <input type="text" className="form-control channel" name="channel_of_contact" style={{minWidth: "150px", maxWidth: "161px"}} value={props.coc} onChange={props.onChange}/>
                {/* <input type="text" className="form-control" style={{minWidth: "80px", maxWidth:"200px"}} name="channel_of_contact" value={props.coc} onChange={props.onChange}/> */}
              </div>
              {props.validator.message('channel_of_contact', props.coc, cocVal) ? <SpanError>{props.validator.message('channel_of_contact', props.coc, cocVal)}</SpanError> : ''}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactDetail;
