import React from 'react';

import Modal from '../../../../ui/Modal/Modal';
import logo from '../../../../img/logo-konica.png';
import moment from 'moment';

const DraftModal = props => {
  return (
    <Modal title="Stock Transfer Note" onclose={props.onclose} modalid={props.modalid} modalclass={props.modalclass}>
      <div className="modal-body">
        <div className="report" style={{width: "800px", margin: "0 auto", order: "1px solid #000"}}>
          <table style={{width: "100%"}}>
            <tbody>
              <tr>
                <td width="150" style={{padding: "15px", verticalAlign: "top"}}>
                  <img src={logo} alt="" style={{width: "100%"}}/>
                </td>
                <td style={{verticalAlign: "top", padding:"15px"}}>
                  <h4>KONICA MINOLTA</h4>
                  <h6>
                    BUSINESS SOLUTIONS (M)<br/>
                    SDN BHD (72640)
                  </h6>
                  <p>-</p>
                  <p>Tel : -</p>
                  <p>Fax : -</p>
                </td>
                <td style={{verticalAlign: "top", padding:"15px"}}>
                  <div><strong>Stock Transfer Note</strong></div>
                  <br/>
                  <table style={{width: "100%"}}>
                    <tbody>
                      <tr>
                        <td width="100">STN No.</td>
                        <td width="5">:</td>
                        <td>{props.stn.transfer_order.transfer_order_number ? props.stn.transfer_order.transfer_order_number : ''}</td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td>:</td>
                        <td>{props.stn.date ? props.stn.date : ''}</td>
                      </tr>
                      <tr>
                        <td>Reason Code</td>
                        <td>:</td>
                        <td>{props.stn.reason_code ? props.stn.reason_code : ''}</td>
                      </tr>
                      <tr>
                        <td>Movement Type</td>
                        <td>:</td>
                        <td>{props.stn.transfer_order.movement_type ? props.stn.transfer_order.movement_type : ''}</td>
                      </tr>
                      <tr>
                        <td>Where House</td>
                        <td>:</td>
                        <td>{props.stn.where_house ? props.stn.where_house : ''}</td>
                      </tr>
                      <tr>
                        <td>Shipment No.</td>
                        <td>:</td>
                        <td>{props.stn.shipment_number ? props.stn.shipment_number : ''}</td>
                      </tr>
                      <tr>
                        <td>Sales Order No.</td>
                        <td>:</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Req. Delivery Note</td>
                        <td>:</td>
                        <td>{props.stn.req_delivery_note ? props.stn.req_delivery_note : '' }</td>
                      </tr>
                      <tr>
                        <td>Stock Room</td>
                        <td>:</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="table table-bordered" style={{margin: "0"}}>
            <tbody>
              <tr>
                <td width="50%" style={{padding: "15px", borderRight:"1px solid #000"}}>
                  Issued to : A7UTE011, Shaharuddin Bin Abdullah<br/>
                  Infinite Centre, Lot 1, Jalan 13/6, 46200 Petaling Jaya, Selanggor, Malaysia
                </td>
                <td width="50%" style={{padding:"15px"}}>
                  Issued By : PJ SPAREPARTS STOCK ROOM<br/>
                  Infinite Centre, Lot 1, Jalan 13/6, 46200 Petaling Jaya, Selanggor, Malaysia
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{minHeight:"300px"}}>
            <table className="table table-bordered" style={{margin: "0"}}>
              <thead>
                <tr>
                  <td>Seq</td>
                  <td>Item No</td>
                  <td>Item Description</td>
                  <td>Qty</td>
                  <td>UOM</td>
                  <td>Valuation</td>
                  <td>Document Ref</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>13GA45550</td>
                  <td>L PAPER EXIT FLAPER 40</td>
                  <td>2</td>
                  <td>PC</td>
                  <td>NEW</td>
                  <td>TO008818//S25854</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{borderTop: "1px solid #eee"}}>
            <table style={{width: "100%"}}>
              <tbody>
                <tr>
                  <td width="33%" style={{padding: "15px", verticalAlign: "top"}}>
                    TRADEWINDS PLANTATION MANAGEMENT SDN BHD, THE CAPERS SENTUL, LEVEL 11. UNIT 6, LORONG TIMUR SENTUL SELATAN.<br/>
                    testing<br/>
                    Technician : Shaharuddin Bin Abdullah<br/>
                    Model : C258 COPIER/PRINTER<br/>
                    SERIAL : A7R0041003321<br/>
                    Updated By : PJSP001
                  </td>
                  <td width="33%" style={{padding: "15px", verticalAlign: "top"}}>
                    RECEIVED BY
                    <div style={{ height: "50px", borderBottom: "1px solid #aaa"}}></div>
                    Date
                  </td>
                  <td style={{padding: "15px", verticalAlign: "top"}}>
                    APPROVED BY
                    <div style={{ height: "50px", borderBottom: "1px solid #aaa"}}></div>
                    Date
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
          <div style={{ textAlign: "center", padding: "15px 15px 0"}}>Printed on Konica Minolta Multi-Functional Printer</div>
        <div style={{ textAlign: "left", padding: "0 15px 15px"}}><small>Private & Confidential</small></div>
          
        </div>
      </div>
      <div className="modal-footer align-right">
        <button type="button" className="btn btn-default pull-right" onClick={props.onclose}>Close</button>
      </div>
    </Modal>
  )
}

export default DraftModal;







