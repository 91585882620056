import React, { Component } from "react";
// import Navbar from "../../layout/Navbar";
// import Sidebar from "../../layout/Sidebar";
// import PaginationServerSide from "../../common/PaginationServerSide";
import PaginationReactTable from "../../common/PaginationReactTable";

import Pagination from "../../common/Pagination";

import axios from "../../../axios-env";
import { Link, Route } from "react-router-dom";
import { connect } from "react-redux";
// import {
//   holiday_list,
//   add_holiday,
//   edit_holiday,
//   delete_holiday,
//   del_edit
// } from "../../../../actions/calendar_settingAction";
// import SettingContainer from "../Container";
// import {
//   NotificationContainer,
//   NotificationManager
// } from "react-notifications";
// import "react-notifications/lib/notifications.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "./pagination.css";
import "./reactDatePicker.css";
// import { del_res_wsp } from "../../../../actions/sprAction";
import matchSorter from "match-sorter";
// import BigCalendar from "react-big-calendar";
// import "react-big-calendar/lib/css/react-big-calendar.css";
// import "../../../../src/Custom.css";
import moment from "moment";
import TicketManagementContainer from "./TicketManagement/Container";
import DatePicker, { subDays } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// let myEventsList = [];
let page = 1;
const loadingq = (
  <div>
    <i className="fa fa-spinner fa-spin fa-2x" />
  </div>
);

class TicketInboxPagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ticket_types: [],
      ticket_statuses: [],
      start_date: "",
      end_date: "",
      machine: ""
    };
  }
  componentDidMount() {
    // const jwtToken = localStorage.getItem("jwtToken");
    // this.props.holiday_list(jwtToken);
    // console.log(this.state);
    // const { resp } = this.props.list_user;
    // this.setState({ resp2: resp });
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    setTimeout(
      function() {
        // this.props.ticketInbox(jwtToken);
        this.refReactTable.fireFetchData();
      }.bind(this),
      1000
    );

    // this.refReactTable.fireFetchData();
  };
  handleStartDate = start_date => {
    this.setState({ start_date });
    setTimeout(
      function() {
        // this.props.ticketInbox(jwtToken);
        this.refReactTable.fireFetchData();
      }.bind(this),
      1000
    );
  };
  handleEndDate = end_date => {
    this.setState({ end_date });
    setTimeout(
      function() {
        // this.props.ticketInbox(jwtToken);
        this.refReactTable.fireFetchData();
      }.bind(this),
      1000
    );
  };
  handleEventClick = () => {
    // var startDate = moment(slotInfo.start).format("YYYY-MM-DD h:mm:ss");
    // var endDate = moment(slotInfo.end).format("YYYY-MM-DD h:mm:ss");
    this.setState({
      ticket_type: this.state.ticket_type,
      ticket_status: this.state.ticket_status,
      machine: this.state.machine,
      start_date: this.state.start_date,
      end_date: this.state.end_date
      // page: 1
    });
    // this.fetchData();
    this.refReactTable.fireFetchData();
    this.props.history.push("/ticket-management2");
  };
  // fetchData =

  render() {
    // console.log(this.state);
    const { ticket_types } = this.state;
    ticket_types.length = 9;
    return (
      <div>
        <div className="panel panel-default">
          <div className="panel-heading">Ticket Inbox</div>
          <div className="panel-body form-xs">
            <div className="pull-left form-inline">
              {/* <div className="form-group"></div> */}
              <DatePicker
                className="form-control "
                selected={this.state.start_date}
                style={{ width: "130px" }}
                onChange={this.handleStartDate}
                selectsStart
                startDate={this.state.start_date}
                endDate={this.state.end_date}
                placeholderText="Start date"
                isClearable={this.state.start_date}
                // name="start_date"
              />
              <label>-</label>
              <DatePicker
                className="form-control "
                selected={this.state.end_date}
                onChange={this.handleEndDate}
                style={{ width: "130px" }}
                selectsEnd
                startDate={this.state.start_date}
                endDate={this.state.end_date}
                minDate={this.state.start_date}
                placeholderText="End date"
                isClearable={this.state.end_date}
                // name="end_date"
              />
              <label htmlFor="">Ticket Type </label>
              <select
                style={{ width: "100px" }}
                className="form-control"
                onChange={this.handleChange}
                // value={ticket_pic}
                name="ticket_type"
              >
                <option value="0">All</option>
                {/* <option value="1">x</option> */}
                {this.state.ticket_types.map(tic => (
                  <option value={tic.id} key={tic.id}>
                    {tic.name}
                  </option>
                ))}
              </select>

              <label htmlFor="">Ticket Status</label>
              <select
                style={{ width: "100px" }}
                className="form-control"
                onChange={this.handleChange}
                // value={ticket_pic}
                name="ticket_status"
              >
                <option value="0">All</option>
                {this.state.ticket_statuses.map(tic => (
                  <option value={tic.id} key={tic.id}>
                    {tic.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="pull-right form-inline">
              <label htmlFor="">Serial, Equipment or Name</label>
              <input
                type="text"
                className="form-control"
                style={{ width: "100px" }}
                value={this.state.machine}
                onChange={this.handleChange}
                placeholder="Search"
                name="machine"
              />
            </div>
          </div>

          <ReactTable
            data={this.state.data} // should default to []
            PaginationComponent={PaginationReactTable}
            pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
            loading={this.state.loading}
            manual // informs React Table that you'll be handling sorting and pagination server-side
            showPageSizeOptions={false}
            // className="-highlight"
            previousText="<<"
            nextText=">>"
            resizable={true}
            key="id"
            loadingText={loadingq}
            minRows={1}
            noDataText="No Matches Found"
            columns={[
              // { indexKey: "id" },
              {
                Header: "DATE TIME",

                columns: [
                  {
                    accessor: "created_at",
                    show: false
                  },
                  // {
                  //   accessor: "id",
                  //   show: false
                  // },
                  {
                    // id: "created_at",
                    sortable: false,
                    filterable: false,
                    Cell: ({ row }) => moment(row.created_at).format("L")
                  }
                ]
              },
              {
                Header: "FROM",
                columns: [
                  {
                    // id: "lastName",
                    sortable: false,
                    filterable: false,
                    accessor: "action_by.username"
                  }
                ]
              },
              {
                Header: "SERVICE ORDER",
                columns: [
                  {
                    accessor: "ticket_origin.service_code.job_code",
                    sortable: false,
                    filterable: false,
                    style: { textAlign: "center" }
                  }
                ]
              },
              {
                Header: "EQUIPMENT NO",
                columns: [
                  {
                    sortable: false,
                    filterable: false,
                    accessor:
                      "ticket_origin.machine_customer.machine.equipment_number"
                  }
                ]
              },
              {
                Header: "SERIAL NUMBER",
                columns: [
                  {
                    sortable: false,
                    filterable: false,
                    accessor:
                      "ticket_origin.machine_customer.machine.machine_serial"
                  }
                ]
              },

              {
                Header: "MODEL NO",
                columns: [
                  {
                    sortable: false,
                    filterable: false,
                    accessor:
                      "ticket_origin.machine_customer.machine.machine_models.model_name"
                  }
                ]
              },
              {
                Header: "SHIPPING NAME",
                columns: [
                  {
                    sortable: false,
                    filterable: false,
                    accessor: "ticket_origin.machine_customer.ship_to_name"
                  }
                ]
              },
              {
                Header: "TICKET NO",
                columns: [
                  {
                    sortable: false,
                    filterable: false,
                    accessor: "ticket_number"
                  }
                ]
              },
              {
                Header: "TICKET TYPE",
                columns: [
                  {
                    sortable: false,
                    filterable: false,
                    accessor: "ticket_type.name"
                  }
                ]
              },
              {
                Header: "STATUS",
                // width: 200,
                columns: [
                  {
                    sortable: false,
                    filterable: false,
                    accessor: "ticket_status.name",
                    style: { textAlign: "center" }
                  }
                ]
              },
              {
                Header: "ACTION",
                // style: { textAlign: "center" },
                columns: [
                  {
                    accessor: "id",
                    show: false
                  },
                  {
                    sortable: false,
                    filterable: false,
                    Cell: ({ row }) => {
                      let but = "";
                      if (row["ticket_type.name"] === "Assign CE") {
                        but = (
                          <div style={{ textAlign: "center" }}>
                            <Link to={`/ticket-management/assignce/${row.id}`}>
                              <button
                                type="button"
                                className="btn btn-primary btn-xs"
                              >
                                SELECT
                                {/* {row["ticket_type.name"]} */}
                              </button>
                            </Link>
                          </div>
                        );
                      }
                      if (
                        row["ticket_type.name"] === "Consumable Fulfillment"
                      ) {
                        but = (
                          <div style={{ textAlign: "center" }}>
                            <Link
                              to={`/ticket-management/consumable/${row.id}`}
                            >
                              <button
                                type="button"
                                className="btn btn-primary btn-xs"
                                style={{ textAlign: "center" }}
                              >
                                SELECT
                                {/* {row["ticket_type.name"]} */}
                              </button>
                            </Link>
                          </div>
                        );
                      }
                      if (row["ticket_type.name"] === "Help Desk") {
                        but = (
                          <div style={{ textAlign: "center" }}>
                            <Link
                              to={`/ticket-management/processtickethelpdesk/${row.id}`}
                            >
                              <button
                                type="button"
                                className="btn btn-primary btn-xs"
                                style={{ textAlign: "center" }}
                              >
                                SELECT
                                {/* {row["ticket_type.name"]} */}
                              </button>
                            </Link>
                          </div>
                        );
                      }
                      if (
                        row["ticket_type.name"] === "Spare-Parts Fulfillment"
                      ) {
                        but = (
                          <div style={{ textAlign: "center" }}>
                            <Link to={`/ticket-management/spr/${row.id}`}>
                              <button
                                type="button"
                                className="btn btn-primary btn-xs"
                                style={{ textAlign: "center" }}
                              >
                                SELECT
                                {/* {row["ticket_type.name"]} */}
                              </button>
                            </Link>
                          </div>
                        );
                      }
                      if (
                        row["ticket_type.name"] === "Spare-Parts Reconciliation"
                      ) {
                        but = (
                          <div style={{ textAlign: "center" }}>
                            <Link
                              to={`/ticket-management/spr_reconciliation/${row.id}`}
                            >
                              <button
                                type="button"
                                className="btn btn-primary btn-xs"
                                style={{ textAlign: "center" }}
                              >
                                SELECT
                                {/* {row["ticket_type.name"]} */}
                              </button>
                            </Link>
                          </div>
                        );
                      }
                      if (row["ticket_type.name"] === "Invoice") {
                        but = (
                          <div style={{ textAlign: "center" }}>
                            <Link to={`/ticket-management/invoice/${row.id}`}>
                              <button
                                type="button"
                                className="btn btn-primary btn-xs"
                                style={{ textAlign: "center" }}
                              >
                                SELECT
                                {/* {row["ticket_type.name"]} */}
                              </button>
                            </Link>
                          </div>
                        );
                      }
                      return but;
                    }
                  }
                ]
              }
            ]}
            onFetchData={(state, instance) => {
              this.forceUpdate();
              // show the loading overlay
              this.setState({ loading: true });
              // fetch your data
              const headers = {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwtToken")}`
                }
              };
              // const data = {
              //   ticket_type: this.state.ticket_type
              //     ? parseInt(this.state.ticket_type)
              //     : 0,
              //   ticket_status: this.state.ticket_status
              //     ? parseInt(this.state.ticket_status)
              //     : 0,
              //   machine: this.state.machine ? this.state.machine : "",
              //   start_date: this.state.start_date
              //     ? moment(this.state.start_date).format("YYYY-MM-DD 00:00:00")
              //     : "0001-01-01 00:00:00",
              //   end_date: this.state.end_date
              //     ? moment(this.state.end_date)
              //         .add(1, "days")
              //         .format("YYYY-MM-DD 00:00:00")
              //     : "0001-01-01 00:00:00"
              // };
              const mchn = this.state.machine ? this.state.machine : "";
              const tic_set = this.state.ticket_status
                ? parseInt(this.state.ticket_status)
                : 0;
              const tic_typ = this.state.ticket_type
                ? parseInt(this.state.ticket_type)
                : 0;
              const st_dt = this.state.start_date
                ? moment(this.state.start_date).format("YYYY-MM-DD 00:00:00")
                : "0001-01-01 00:00:00";
              const en_dt = this.state.end_date
                ? moment(this.state.end_date)
                    .add(1, "days")
                    .format("YYYY-MM-DD 00:00:00")
                : "0001-01-01 00:00:00";
              //   console.log("page id", state.page + 1);
              // console.log("head side", data);
              // console.log("headers side", headers);
              axios
                .get(
                  `/api/v1/ticket-management/filter-by-all/${state.page +
                    1}?machine=${mchn}&ticket_status=${tic_set}&ticket_type=${tic_typ}&start_date=${st_dt}&end_date=${en_dt}`,
                  // data,
                  headers
                )

                .then(res => {
                  // Update react-table
                  // console.log(res);
                  const mod = res.data.data.total % 5;
                  const addmod = mod > 0 ? 1 : 0;
                  // console.log(mod);
                  // console.log(addmod);
                  // console.log((res.data.data.total - mod) / 5 + addmod);
                  this.setState({
                    data: res.data.data.ticket_inbox,
                    pages:
                      res.data.data.total === 0
                        ? 1
                        : (res.data.data.total - mod) / 5 + addmod,
                    loading: false,
                    ticket_types: res.data.data.ticket_type,
                    ticket_statuses: res.data.data.ticket_status
                  });
                });
            }}
            ref={refReactTable => {
              this.refReactTable = refReactTable;
            }}
            // onFilteredChange={e => {
            //   this.setState({ [e.target.name]: e.target.value });
            // }}
            //   data={list_holiday}
            //   columns={columns}
            defaultPageSize={5}
            // getTdProps={(state, rowInfo, column, instance) => {
            //   return {
            //     onClick: (e, handleOriginal) => {
            //       console.log("A Td Element was clicked!");
            //       console.log("it produced this event:", e);
            //       console.log("It was in this column:", column);
            //       console.log(
            //         "It was in this row:",
            //         rowInfo.row["ticket_type.name"]
            //       );
            //       console.log("It was in this table instance:", instance);
            //       // IMPORTANT! React-Table uses onClick internally to trigger
            //       // events like expanding SubComponents and pivots.
            //       // By default a custom 'onClick' handler will override this functionality.
            //       // If you want to fire the original onClick handler, call the
            //       // 'handleOriginal' function.
            //       // if (handleOriginal) {
            //       //   handleOriginal()
            //       // }
            //     }
            //   };
            // }}
            //   className="-striped -highlight"
            //   filterable
            //   defaultFilterMethod={(filter, row) =>
            //     String(row[filter.id]) === filter.value
            //   }
          />
        </div>
      </div>
    );
  }
}

// const mapStateToProps = state => ({
//   list_holiday: state.holiday_Reducer
// });

export default TicketInboxPagination;
