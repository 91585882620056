import React from 'react'
import './SpanError.css';

const SpanError = props => {
  return(
    <span className="help-block form-error validation-error" style={{color: "#A94442", marginTop: "-13px" }}>
       {props.children}
     </span>
  )
}

export default SpanError;

