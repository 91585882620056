import { EDIT_USER, UPDATE, DEL_MESSAGE } from "../actions/types";

const initialState = {
  edit_user: [],
  departmentx: [],
  level: [],
  save_user: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case EDIT_USER:
      return {
        ...state,
        edit_user: action.payload,
        departmentx: action.department,
        level: action.level
      };
    case UPDATE:
      return {
        ...state,
        save_user: action.payload
        // edit_user: { ...state.edit_user, days: action.aaa.days }
      };
    case DEL_MESSAGE:
      return {
        ...state,
        save_user: action.payload
        // resDelPmCust: action.payload,
        // resUpdateDefaultDays: action.payload,
        // resStorePmCust: action.payload
      };

    default:
      return state;
  }
}
