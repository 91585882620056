import React, { Component } from 'react';
import axios from '../../../../axios-env';

import SettingContainer from '../Container';

class Home extends Component{
  state = {
    
  }
  
  componentDidMount(){
    console.log(this.props.location);
    const headers = { headers:{ Authorization: `Bearer ${localStorage.getItem("jwtToken")}`}};
    axios.get('/api/v1/setting/sales/list', headers).then(res => {
      console.log(res.data);
    }).catch(error => {
      console.log(error);
    });
  }
  render (){
    return (
      <div>
        <SettingContainer pagename="Setting" pagedetailname="Home" location="home">
          <div className="panel panel-default">
            <div className="panel-heading">Home</div>
            <div className="panel-body form form-xs">
            </div>
          </div>
        </SettingContainer>
      </div>
    )
  }
}

export default Home;
