import React, { Component } from "react";
import { Link } from "react-router-dom";
import LayoutContainer from "../../layout/Container";
import Navbar from "../../layout/Navbar";
import Sidebar from "../../layout/Sidebar";
import Flash from "../../common/FlashMessage";
import "./Container.css";

class SettingContainer extends Component {
  render() {
    const level = localStorage.getItem("level");
    const dept = level === "Control Center" ? "" : "disabled";
    return (
      <LayoutContainer
        icon="fa fa-ticket icon"
        pagename={this.props.pagename}
        pagedetailname={this.props.pagedetailname}
        sidebarinfo="Setting"
      >
        <div id="main" className="main-xs">
          <nav className="tabs-xs tabs-bg-blue">
            <ul className="nav nav-tabs">
              <li className={this.props.location === "home" ? "active" : ""}>
                <Link to="/setting/home">Home</Link>
              </li>
              <li className={this.props.location === "user" ? "active" : ""}>
                {dept === "disabled" ? (
                  ""
                ) : (
                  <Link to="/setting/user">User</Link>
                )}
              </li>
              {dept === "disabled" ? (
                ""
              ) : (
                <li
                  className={
                    this.props.location === "calendar" ||
                    this.props.location === "working"
                      ? "active"
                      : ""
                  }
                >
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Calendar
                    <i className="caret" />
                  </a>
                  <ul className="dropdown-menu nopadding">
                    <li
                      className={
                        this.props.location === "working" ? "active" : ""
                      }
                    >
                      <Link to="/setting/working">State Calendar</Link>
                    </li>
                    <li
                      className={
                        this.props.location === "calendar" ? "active" : ""
                      }
                    >
                      <Link to="/setting/calendar">Holiday Management</Link>
                    </li>
                  </ul>
                </li>
              )}
              <li className={this.props.location === "sales" ? "active" : ""}>
                <Link to="/setting/sales">Sales Person</Link>
              </li>
              <li
                className="dropdown"
                className={
                  this.props.location === "consumable-toner-life" ||
                  this.props.location === "consumable-machine-modal"
                    ? "active"
                    : ""
                }
              >
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                  Consumable
                  <i className="caret" />
                </a>
                <ul className="dropdown-menu nopadding">
                  <li
                    className={
                      this.props.location === "consumable-toner-life"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/setting/consumable/life-toner">Toner Life</Link>
                  </li>
                  <li
                    className={
                      this.props.location === "consumable-machine-modal"
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/setting/consumable/machine-model">
                      Machine Model
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className="dropdown"
                className={this.props.location === "consumable" || this.props.location === "masterpart" ? "active" : ""}
              >
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                  Spare-Part
                  <i className="caret" />
                </a>
                <ul className="dropdown-menu nopadding">
                  <li
                    className={
                      this.props.location === "consumable" ? "active" : ""
                    }
                  >
                    <Link to="/setting/spare-part">Spare-parts</Link>
                  </li>
                  <li className={this.props.location === "masterpart" ? "active" : ""}>
                   <Link to="/setting/spare-part-master">Spare-parts Master Management</Link>
                  </li>
                </ul>
              </li>

              <li className={this.props.location === "utility" ? "active" : ""}>
                <Link to="/setting/">Utility</Link>
              </li>
              <li className={this.props.location === "pm" ? "active" : ""}>
                {dept === "disabled" ? (
                  ""
                ) : (
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    PM
                    <i className="caret" />
                  </a>
                )}
                <ul className="dropdown-menu nopadding">
                  <li
                    className={
                      this.props.location2 === "customer" ? "active" : ""
                    }
                  >
                    {dept === "disabled" ? (
                      ""
                    ) : (
                      <Link to="/setting/pm">
                        PM by Customer
                      </Link>
                    )}
                  </li>
                  <li
                    className={
                      this.props.location2 === "machine" ? "active" : ""
                    }
                  >
                    {dept === "disabled" ? (
                      ""
                    ) : (
                      <Link to="/setting/pm_machine">
                        PM by Machine{" "}
                      </Link>
                    )}
                  </li>
                  <li
                    className={
                      this.props.location2 === "pm_setting" ? "active" : ""
                    }
                  >
                    {dept === "disabled" ? (
                      ""
                    ) : (
                      <Link to="/setting/pm_setting">
                        Setting{" "}
                      </Link>
                    )}
                  </li>
                </ul>
              </li>
              <li
                className="dropdown"
                className={
                  this.props.location === "addsla" ||
                  this.props.location === "uploadsla"
                    ? "active"
                    : ""
                }
              >
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                  Additional SLA
                  <i className="caret" />
                </a>
                <ul className="dropdown-menu nopadding">
                  <li
                    className={this.props.location === "addsla" ? "active" : ""}
                  >
                    <Link to="/setting/additional-sla">Additional SLA</Link>
                  </li>
                  <li
                    className={
                      this.props.location === "uploadsla" ? "active" : ""
                    }
                  >
                    <Link to="/setting/upload-sla">Upload SLA</Link>
                  </li>
                </ul>
              </li>
              <li className={this.props.location === "csrc" ? "active" : ""}>
                <Link to="/setting/csrc-status">CSRC Status</Link>
              </li>
            </ul>
          </nav>
          <br />

          {this.props.children}
        </div>
      </LayoutContainer>
    );
  }
}

export default SettingContainer;
