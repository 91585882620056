import React, { Component } from "react";
import Navbar from "../../../layout/Navbar";
import Sidebar from "../../../layout/Sidebar";
import { connect } from "react-redux";
import {
  getCreateUser,
  saverUser,
  del_message
} from "../../../../actions/getUserActions";
import SettingContainer from "../Container";
import SimpleReactValidator from "simple-react-validator";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import iconBlue from "../../../../img/icon-blue.png";
import iconRed from "../../../../img/icon-red.png";

class CreateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // fullname: [],
      // username: [],
      nav_user_id: [],
      // sap_user_code: [],
      // password: [],
      // level_id: [],

      // email: [],
      // mobile_phone: [],
      branch_id: []
      // enabled: [],
      // bin_code: [],
      // department_id: [],
      // photo: [],
      // longitude: [],
      // latitude: []
    };
    this.validator = new SimpleReactValidator();
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);

    this.handlefullname = this.handlefullname.bind(this);
    this.handleusername = this.handleusername.bind(this);

    this.handlesap_user_code = this.handlesap_user_code.bind(this);
    this.handlepassword = this.handlepassword.bind(this);
    this.handlecpassword = this.handlecpassword.bind(this);
    this.handleemail = this.handleemail.bind(this);
    this.handlemobile_phone = this.handlemobile_phone.bind(this);
    this.handlelongitude = this.handlelongitude.bind(this);

    this.handlelatitude = this.handlelatitude.bind(this);

    this.handleenabled = this.handleenabled.bind(this);
    this.handlebin_code = this.handlebin_code.bind(this);
    this.handledepartment_id = this.handledepartment_id.bind(this);
  }

  componentDidMount() {
    const jwtToken = localStorage.getItem("jwtToken");
    this.props.getCreateUser(jwtToken);
    // this.props.saverUser();
  }
  // componentWillMount() {
  //   window.location.reload();
  // }
  handleChange(event) {
    //this.setState({value: event.target.value});
    // this.setState({fullname: event.target.value});
    // this.setState({username: event.target.value});
    // this.setState({nav_user_id: event.target.value});
    // this.setState({sap_user_code: event.target.value});
    // this.setState({password: event.target.value});
    this.setState({ level_id: event.target.value });
    //this.setState({spv_id: event.target.value});
    //this.setState({alt_spv_id: event.target.value});
    // this.setState({email: event.target.value});
    // this.setState({mobile_phone: event.target.value});
    // this.setState({branch_id: event.target.value});
    // this.setState({enabled: event.target.value});
    // this.setState({bin_code: event.target.value});
    // this.setState({department_id: event.target.value});
    // this.setState({photo: event.target.value});
  }
  handlefullname(event) {
    this.setState({ fullname: event.target.value });
  }
  handleusername(event) {
    this.setState({ username: event.target.value });
  }

  handlesap_user_code(event) {
    this.setState({ sap_user_code: event.target.value });
  }
  handlepassword(event) {
    this.setState({ password: event.target.value });
  }
  handlecpassword(event) {
    this.setState({ cpassword: event.target.value });
  }
  handleemail(event) {
    this.setState({ email: event.target.value });
  }
  handlemobile_phone(event) {
    this.setState({ mobile_phone: event.target.value });
  }

  handleenabled(event) {
    this.setState({ enabled: event.target.value });
  }
  handlebin_code(event) {
    this.setState({ bin_code: event.target.value });
  }
  handlelongitude(event) {
    this.setState({ longitude: event.target.value });
  }
  handlelatitude(event) {
    this.setState({ latitude: event.target.value });
  }
  handledepartment_id(event) {
    this.setState({ department_id: event.target.value });
  }

  handleChange2(event) {
    this.setState({ value2: event.target.value });
  }

  handleChange3(event) {
    this.setState({ value3: event.target.value });
  }
  handleBack = event => {
    event.preventDefault();
    this.props.history.push("/setting/user");
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const saveUserData = {
        fullname: this.state.fullname,
        username: this.state.username,
        // nav_user_id: this.state.nav_user_id,
        sap_user_code: this.state.sap_user_code,
        password: this.state.password,

        level_id: this.state.level_id,
        // spv_id: this.state.value3,
        // alt_spv_id: this.state.value2,

        email: this.state.email,
        mobile_phone: this.state.mobile_phone,
        // branch_id: this.state.branch_id,
        enabled: this.state.enabled,
        bin_code: this.state.bin_code,
        department_id: this.state.department_id
        // longitude: this.state.longitude,
        // latitude: this.state.latitude
        // photo: this.state.photo
      };
      // const jwtToken = localStorage.getItem("jwtToken");
      const datax = {
        dt: saveUserData,
        jwtToken: localStorage.getItem("jwtToken")
      };
      // console.log("data", datax);
      this.props.saverUser(datax);

      const { resp } = this.props.www;
      // console.log("ini res to");
      // console.log(resp);
      // localStorage.setItem("error", resp ? resp.error : "");
      // localStorage.setItem("message", resp ? resp.message : "");

      setTimeout(
        function() {
          window.location.reload();
          this.props.del_message();
          this.setState({
            fullname: "",
            username: "",
            sap_user_code: "",
            password: "",
            level_id: "",
            email: "",
            mobile_phone: "",
            enabled: "",
            bin_code: "",
            department_id: "",
            longitude: "",
            latitude: ""
          });
        }.bind(this),
        3000
      );

      // th
      // this.props.history.push({
      //   pathname: "/setting/user",
      //   data: resp ? resp.message : ""
      // });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };
  onMapClicked = (t, map, coord) => {
    const { latLng } = coord;
    // console.log("latt", latLng.lat(), latLng.lng());
    this.setState({
      latPicker: latLng.lat(),
      lngPicker: latLng.lng(),
      latitude: latLng.lat().toString(),
      longitude: latLng.lng().toString()
    });
  };
  render() {
    // const { persons } = this.state;
    const { persons } = this.props.www;
    const { user } = this.props.www;
    const { branch_list } = this.props.www;
    const { department } = this.props.www;
    const { resp } = this.props.www;
    const dis = this.state.level_id;
    const im = this.state.value2;
    const alt = this.state.value3;
    const disabled = dis === "8" ? "" : "disabled";
    const cek = alt - im;
    const pw = this.state.password === "" ? "" : this.state.password;
    const cpw = this.state.cpassword === "" ? "" : this.state.cpassword;
    const psw = pw === cpw ? "form-group" : "form-group has-error";
    const psw2 = this.state.cpassword === undefined ? "form-group" : psw;
    const mes =
      cek === 0 ? "Alternate Immidiate cannot to be same with Immidiate." : "";
    const err = cek === 0 ? "form-group has-error" : "form-group";
    const qq =
      this.state.fullname !== "" && this.state.username !== ""
        ? "ak"
        : "disabled";
    if (resp.length !== 0) {
      if (resp.code === 200) {
        NotificationManager.success(resp.message, "Add User");
      } else {
        NotificationManager.error(resp.message, "Add User");
      }
    }
    // console.log("s", this.state.latitude, this.state.longitude);
    return (
      <div>
        <SettingContainer
          pagename="Setting"
          pagedetailname="CreateUser"
          location="user"
        >
          <NotificationContainer />
          <div className="panel panel-default">
            <div className="panel-heading">Insert User</div>
            <div className="col-md-6">
              <div className="panel-body">
                <div className="panel-body form form-xs">
                  <form
                    className="form-horizontal"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="box-body">
                      <div
                        className={
                          this.validator.message(
                            "Fullname",
                            this.state.fullname,
                            "required|alpha_num_space"
                          )
                            ? "form-group has-error"
                            : "form-group"
                        }
                      >
                        <label
                          className="col-sm-2 control-label"
                          style={{
                            textAlign: "left"
                          }}
                        >
                          Full Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            id="fullname"
                            name="fullname"
                            placeholder="Full Name"
                            onChange={this.handlefullname}
                            // required
                          />
                          <p style={{ color: "#A94442" }}>
                            {this.validator.message(
                              "Fullname",
                              this.state.fullname,
                              "required|alpha_num_space"
                            )}
                          </p>
                        </div>
                      </div>

                      <div
                        className={
                          this.validator.message(
                            "Username",
                            this.state.username,
                            "required|alpha"
                          )
                            ? "form-group has-error"
                            : "form-group"
                        }
                      >
                        <label
                          className="col-sm-2 control-label"
                          style={{
                            textAlign: "left"
                          }}
                        >
                          Username
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            id="username"
                            name="username"
                            placeholder="Username"
                            onChange={this.handleusername}
                            // required
                          />
                          <p style={{ color: "#A94442" }}>
                            {this.validator.message(
                              "Username",
                              this.state.username,
                              "required|alpha"
                            )}
                          </p>
                        </div>
                      </div>

                      <div
                        className={
                          this.validator.message(
                            "Sav ID",
                            this.state.sap_user_code,
                            "required|integer"
                          )
                            ? "form-group has-error"
                            : "form-group"
                        }
                      >
                        <label
                          className="col-sm-2 control-label"
                          style={{
                            textAlign: "left"
                          }}
                        >
                          Sav Id
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            id="sap_user_code"
                            name="sap_user_code"
                            placeholder="Sav Id"
                            onChange={this.handlesap_user_code}
                            // required
                          />
                          <p style={{ color: "#A94442" }}>
                            {this.validator.message(
                              "Sav ID",
                              this.state.sap_user_code,
                              "required|integer"
                            )}
                          </p>
                        </div>
                      </div>

                      <div
                        className={
                          this.validator.message(
                            "Password",
                            this.state.password,
                            "required|alpha_num_space"
                          )
                            ? "form-group has-error"
                            : "form-group"
                        }
                      >
                        <label
                          className="col-sm-2 control-label"
                          style={{
                            textAlign: "left"
                          }}
                        >
                          Password
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            name="password"
                            placeholder="Password"
                            onChange={this.handlepassword}
                            value={this.state.password}
                            // required
                          />
                          <p style={{ color: "#A94442" }}>
                            {this.validator.message(
                              "Password",
                              this.state.password,
                              "required|alpha_num_space"
                            )}
                          </p>
                        </div>
                      </div>

                      <div className={psw2}>
                        <label
                          className="col-sm-2 control-label"
                          style={{
                            textAlign: "left"
                          }}
                        >
                          Confirm Password
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="password"
                            className="form-control has-error"
                            id="cpassword"
                            name="cpassword"
                            placeholder="Confirm Password"
                            onChange={this.handlecpassword}
                            value={this.state.cpassword}
                            // required
                          />
                          <p style={{ color: "#A94442" }}>
                            {this.validator.message(
                              "Confirmation Password",
                              this.state.cpassword,
                              "required|alpha_num_space"
                            )}
                          </p>
                        </div>
                      </div>

                      <div
                        className={
                          this.validator.message(
                            "level",
                            this.state.level_id,
                            "required|integer"
                          )
                            ? "form-group has-error"
                            : "form-group"
                        }
                      >
                        <label
                          className="col-sm-2 control-label"
                          style={{
                            textAlign: "left"
                          }}
                        >
                          Level
                        </label>
                        <div className="col-sm-10">
                          <select
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.value}
                            name="level_id"
                            // required
                          >
                            <option value="">-</option>

                            {persons.map(person => (
                              <option value={person.id} key={person.id}>
                                {person.name}
                              </option>
                            ))}
                          </select>
                          <p style={{ color: "#A94442" }}>
                            {this.validator.message(
                              "level",
                              this.state.level_id,
                              "required|integer"
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          className="col-sm-2 control-label"
                          style={{
                            textAlign: "left"
                          }}
                        >
                          Immidiate Superior{" "}
                        </label>
                        <div className="col-sm-10">
                          <select
                            className="form-control"
                            id="spv_id"
                            disabled={disabled}
                            onChange={this.handleChange3}
                            value3={this.state.value3}
                            name="spv_id"
                            // required
                          >
                            <option value="">-</option>

                            {user.map(per => (
                              <option value={per.id} key={per.id}>
                                {per.fullname}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className={err}>
                        <label
                          className="col-sm-2 control-label"
                          style={{
                            textAlign: "left"
                          }}
                        >
                          Alternate Immidiate Superior{" "}
                        </label>
                        <div className="col-sm-10">
                          <select
                            className="form-control"
                            id="alt_spv_id"
                            disabled={disabled}
                            onChange={this.handleChange2}
                            value2={this.state.value2}
                            name="alt_spv_id"
                            required
                          >
                            <option value="">-</option>

                            {user.map(per => (
                              <option value={per.id} key={per.id}>
                                {per.fullname}
                              </option>
                            ))}
                          </select>
                          {mes}
                        </div>
                      </div>

                      <div
                        className={
                          this.validator.message(
                            "Email",
                            this.state.email,
                            "required|email"
                          )
                            ? "form-group has-error"
                            : "form-group"
                        }
                      >
                        <label
                          className="col-sm-2 control-label"
                          style={{
                            textAlign: "left"
                          }}
                        >
                          Email Address
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Email Address"
                            onChange={this.handleemail}
                            // required
                          />
                          <p style={{ color: "#A94442" }}>
                            {this.validator.message(
                              "Email",
                              this.state.email,
                              "required|email"
                            )}
                          </p>
                        </div>
                      </div>

                      <div
                        className={
                          this.validator.message(
                            "Phone",
                            this.state.mobile_phone,
                            "required|phone"
                          )
                            ? "form-group has-error"
                            : "form-group"
                        }
                      >
                        <label
                          className="col-sm-2 control-label"
                          style={{
                            textAlign: "left"
                          }}
                        >
                          Phone
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            id="mobile_phone"
                            name="mobile_phone"
                            placeholder="Phone"
                            onChange={this.handlemobile_phone}
                            // required
                          />
                          <p style={{ color: "#A94442" }}>
                            {this.validator.message(
                              "Phone",
                              this.state.mobile_phone,
                              "required|phone"
                            )}
                          </p>
                        </div>
                      </div>

                      <div
                        className={
                          this.validator.message(
                            "status",
                            this.state.enabled,
                            "required"
                          )
                            ? "form-group has-error"
                            : "form-group"
                        }
                      >
                        <label
                          className="col-sm-2 control-label"
                          style={{
                            textAlign: "left"
                          }}
                        >
                          status
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="radio"
                            name="enabled"
                            id="enabled"
                            onChange={this.handleenabled}
                            value="1"
                            // required
                          />
                          {"\xa0"}
                          Active{"\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"}
                          <input
                            type="radio"
                            name="enabled"
                            id="enabled"
                            onChange={this.handleenabled}
                            value="0"
                            // required
                          />
                          {"\xa0"}
                          Inactive
                          <p style={{ color: "#A94442" }}>
                            {this.validator.message(
                              "status",
                              this.state.enabled,
                              "required"
                            )}
                          </p>
                        </div>
                      </div>

                      <div
                        className={
                          this.validator.message(
                            "Bincode",
                            this.state.bin_code,
                            "required|integer"
                          )
                            ? "form-group has-error"
                            : "form-group"
                        }
                      >
                        <label
                          className="col-sm-2 control-label"
                          style={{
                            textAlign: "left"
                          }}
                        >
                          Bincode
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            className="form-control"
                            id="bin_code"
                            name="bin_code"
                            placeholder="Bincode"
                            onChange={this.handlebin_code}
                            // required
                          />
                          <p style={{ color: "#A94442" }}>
                            {this.validator.message(
                              "Bincode",
                              this.state.bin_code,
                              "required|integer"
                            )}
                          </p>
                        </div>
                      </div>

                      <div
                        className={
                          this.validator.message(
                            "Departement",
                            this.state.department_id,
                            "required|integer"
                          )
                            ? "form-group has-error"
                            : "form-group"
                        }
                      >
                        <label
                          className="col-sm-2 control-label"
                          style={{
                            textAlign: "left"
                          }}
                        >
                          Departement
                        </label>
                        <div className="col-sm-10">
                          <select
                            className="form-control"
                            id="immidiateSuperior"
                            name="department_id"
                            onChange={this.handledepartment_id}
                            // required
                          >
                            <option value="">-</option>

                            {department.map(per => (
                              <option value={per.id} key={per.id}>
                                {per.name}
                              </option>
                            ))}
                          </select>
                          <p style={{ color: "#A94442" }}>
                            {this.validator.message(
                              "Departement",
                              this.state.department_id,
                              "required|integer"
                            )}
                          </p>
                        </div>
                      </div>

                      {/* <div
                        className={
                          this.validator.message(
                            "Latitude",
                            this.state.latitude,
                            "required|string"
                          )
                            ? "form-group has-error"
                            : "form-group"
                        }
                      >
                        <label
                          className="col-sm-2 control-label"
                          style={{
                            textAlign: "left"
                          }}
                        >
                          Latitude, Longitude
                        </label>
                        
                        <div className="input-group col-sm-12">
                          <div className="col-sm-4">
                            <input
                              type="text"
                              className="form-control"
                              // id="bin_code"
                              name="latitude"
                              placeholder="Latitude"
                              onChange={this.handlelatitude}
                              value={this.state.latitude}
                              disabled
                              // required
                            />
                          </div>
                          
                          
                          <div className="col-sm-4">
                            <input
                              type="text"
                              className="form-control"
                              // id="bin_code"
                              name="longitude"
                              placeholder="Longitude"
                              onChange={this.handlelongitude}
                              value={this.state.longitude}
                              disabled
                              // required
                            />
                          </div>
                         
                          <div className="col-sm-4">
                            <span className="input-group-btn">
                              <a
                                data-toggle="modal"
                                data-target="#modal-add"
                                className="btn btn-default btn-xs btn-block"
                                // onClick={() =>
                                //   this.showModalMachine(person.id, person.pm_days)
                                // }
                              >
                                Pick
                              </a>
                            </span>
                          </div>
                          
                          <div className="col-sm-12">
                            <p style={{ color: "#A94442" }}>
                              {this.validator.message(
                                "Latitude & Longitude",
                                this.state.longitude,
                                "required|string"
                              )}
                            </p>
                          </div>
                        </div>
                      </div> */}
                      <div className="modal fade" id="modal-add">
                        <div className="modal-dialog modal-lg">
                          <div className="modal-content">
                            <div className="modal-header">
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-hidden="true"
                              >
                                &times;
                              </button>
                              <h4 className="modal-title">Picker</h4>
                            </div>

                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12 modal_body_map">
                                  <div
                                    className="row"
                                    style={{
                                      height: "75vh"
                                    }}
                                  >
                                    <Map
                                      google={this.props.google}
                                      onClick={this.onMapClicked}
                                      // onClick={(e) => this.onMapClicked(e)}
                                      // onClick={(t, map, c) => this.onMapClicked(c.latLng, map)}
                                      // initialCenter={this.state.center}
                                      center={{
                                        lat: this.state.latPicker
                                          ? this.state.latPicker
                                          : 1.351,
                                        lng: this.state.lngPicker
                                          ? this.state.lngPicker
                                          : 103.877
                                      }}
                                      zoom={this.state.latPicker ? 18 : 12}
                                    >
                                      <Marker
                                        title="Picker Location"
                                        name="Picker Location"
                                        position={{
                                          lat: this.state.latPicker,
                                          lng: this.state.lngPicker
                                        }}
                                        icon={iconRed}
                                        type="company"
                                      />
                                    </Map>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* </div> */}
                            <div className="modal-footer">
                              <button
                                type="submit"
                                className="btn btn-info btn-xs center-block"
                                // style={{ "text-align": "center" }}
                                data-dismiss="modal"
                                aria-hidden="true"
                                // onClick={this.handleSubmit}
                              >
                                Ok
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="box-footer">
                      <button
                        className="btn btn-default"
                        onClick={this.handleBack}
                      >
                        Back
                      </button>

                      <button type="submit" className="btn btn-info pull-right">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </SettingContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // persons: state.persons,
  // user: state.user,
  // branch_list: state.branch_list,
  // department: state.department,
  www: state.user
});
export default connect(mapStateToProps, {
  getCreateUser,
  saverUser,
  del_message
})(
  GoogleApiWrapper({
    apiKey: "AIzaSyD8kVr5dj4fb_s-s80rqu8mbehkHKRXgFY"
  })(CreateUser)
);
// (CreateUser);
