// import axios from "axios";
import { CALENDAR_SEARCH } from "./types";
import axios from "../axios-env";

export const calendar = dataCalendar => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${dataCalendar.jwtToken}`
    }
  };
  // console.log("req calendar dlm", head);
  // console.log("req calendar dlm id", dataCalendar.id);

  axios
    .get(`/api/v1/ticket-process/ce-schedule/${dataCalendar.id}`, head)
    .then(res => {
      // console.log("calendar dlm");
      // console.log("calendar dlm", res.data);
      dispatch({
        type: CALENDAR_SEARCH,
        ce: res.data.data.ce,
        working: res.data.data.working
      });
    })

    .catch(err =>
      dispatch({
        type: CALENDAR_SEARCH,
        ce: null,
        working: null
      })
    );
};
