// import axios from "axios";
import axios from "../axios-env";
import {
  PROSES_TICKET_HELPDESK,
  SAVE_HELP_DESK,
  DEL_ASSIGN_HELP
} from "./types";

export const processTicketHelpdesk = data => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${data.jwtToken}`
    }
  };
  // console.log("head help");
  // console.log(head);
  axios
    .get(`/api/v1/ticket-management/helpdesk/${data.id}`, head)
    .then(res => {
      // console.log("help res");
      // console.log(res);

      // ------------------------error handling---------------------

      dispatch({
        type: PROSES_TICKET_HELPDESK,

        payload: res.data.data.ticket_helpdesk,
        ticket_helpdesk: res.data.data.ticket_helpdesk,
        action_by: res.data.data.ticket_helpdesk.action_by,
        department: res.data.data.ticket_helpdesk.action_by.department,
        level: res.data.data.ticket_helpdesk.action_by.level,
        ticket_origin: res.data.data.ticket_helpdesk.ticket_origin,
        machine_customer:
          res.data.data.ticket_helpdesk.ticket_origin.machine_customer,
        machine:
          res.data.data.ticket_helpdesk.ticket_origin.machine_customer.machine,
        customers:
          res.data.data.ticket_helpdesk.ticket_origin.machine_customer
            .customers,
        machine_models:
          res.data.data.ticket_helpdesk.ticket_origin.machine_customer.machine
            .machine_models,
        technicians:
          res.data.data.ticket_helpdesk.ticket_origin.machine_customer.customers
            .technicians,
        shipping:
          res.data.data.ticket_helpdesk.ticket_origin.machine_customer.customers
            .shipping,
        blocked_by:
          res.data.data.ticket_helpdesk.ticket_origin.machine_customer.customers
            .blocked_by,
        contracts:
          res.data.data.ticket_helpdesk.ticket_origin.machine_customer
            .contracts,
        customer: res.data.data.ticket_helpdesk.ticket_origin.customer,
        sales: res.data.data.ticket_helpdesk.ticket_origin.customer.sales,
        ticket_type: res.data.data.ticket_helpdesk.ticket_type,
        machine_status:
          res.data.data.ticket_helpdesk.ticket_origin.machine_status,
        service_code: res.data.data.ticket_helpdesk.ticket_origin.service_code,
        fault_area_codes: res.data.data.fault_area_codes,
        problem_codes: res.data.data.problem_codes,
        ticket_helpdesk2: res.data.data.ticket_helpdesk.ticket_helpdesk,
        helpdesk_comments: res.data.data.helpdesk_comments,
        contact_detail:
          res.data.data.ticket_helpdesk.ticket_origin.contact_detail,
        originator_hlp: res.data.data.ticket_helpdesk.ticket_origin.originator,
        originator_dept:
          res.data.data.ticket_helpdesk.ticket_origin.originator.department
      });
    })
    .catch(err =>
      dispatch({
        type: PROSES_TICKET_HELPDESK,
        payload: null,
        ticket_helpdesk: null,
        action_by: null,
        department: null,
        level: null,
        ticket_origin: null,
        machine_customer: null,
        machine: null,
        customers: null,
        machine_models: null,
        technicians: null,
        shipping: null,
        blocked_by: null,
        contracts: null,
        customer: null,
        sales: null,
        ticket_type: null,
        machine_status: null,
        service_code: null,
        fault_area_codes: null,
        problem_codes: null,
        ticket_helpdesk2: null,
        helpdesk_comments: null,
        contact_detail: null,
        originator_hlp: null,
        originator_dept: null
      })
    );
};

export const saveHelpDesk = datax => dispatch => {
  // console.log("data2");

  const head2 = {
    headers: {
      Authorization: `Bearer ${datax ? datax.jwtToken : ""}`
    }
  };
  const data = datax ? datax.dt : "";
  // const id = datax ? datax.id_otp : "";
  // console.log("hd-dalam");
  // console.log(head2);
  // console.log(data);
  // console.log(id);

  axios
    .post("/api/v1/ticket-management/helpdesk/store", data, head2)
    .then(res => {
      // console.log("res-hd");
      // console.log(res);
      dispatch({
        type: SAVE_HELP_DESK,
        payload: res.data.meta
      });
    })
    .catch(err =>
      dispatch({
        type: SAVE_HELP_DESK,
        payload: null
      })
    );
};
export const del_assign_help = () => {
  // console.log("del dalam");
  return {
    type: DEL_ASSIGN_HELP,
    payload: []
  };
};
