import React, { Component } from "react";
import UnAssignContainer from "./Container";
import "../../../App.css";
import UnAssignInbox from "./UnAssignInbox";
import UnAttendedInbox from "./UnAttendedInbox";

class UnAssign extends Component {
  render() {
    return (
      <UnAssignContainer
        pagename="Unassign Ticket"
        // pagedetailname="Ticket Management"
        location="ticket-management"
      >
        <div className="panel panel-default">
          {/* <TicketInbox /> */}
          <UnAssignInbox />
          <UnAttendedInbox />
          {/* <TicketInbox /> */}
        </div>
      </UnAssignContainer>
    );
  }
}

export default UnAssign;
