import React, { Component } from "react";
// import Navbar from "../../layout/Navbar";
// import Sidebar from "../../layout/Sidebar";
import Pagination from "../../../common/Pagination";

import axios from "axios";
import { Link, Route } from "react-router-dom";
import { connect } from "react-redux";
import { listUser, reset } from "../../../../actions/getUserActions";
import SettingContainer from "../Container";
import { RingLoader } from "react-spinners";
let upper = "";
let table = "";
let pagi = "";
// const override = {
//     display: block,
//     margin: 0 auto, "border-color": red}
// ;
class ListUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      persons: [],
      resp2: [],
      search: "",
      search_by: "",
      currentTickets: [],
      currentPage: null,
      totalPages: null,
      override: {
        display: "block",
        margin: "0 auto",
        "border-color": "red"
      }
    };
    this.reset = this.reset.bind(this);
    this.onChanged = this.onChanged.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const jwtToken = localStorage.getItem("jwtToken");

    this.props.listUser(jwtToken);
    // const { resp } = this.props.list_user;
    // this.setState({ resp2: resp });
  }
  onPageChanged = data => {
    // const { tickets } = this.state;
    const { list_user } = this.props.list_user;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentTickets = list_user.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentTickets, totalPages });
  };
  reset() {
    // this.props.reset();
    // localStorage.removeItem("message");
    // localStorage.removeItem("error");
    window.location.reload();
  }
  onChanged(e) {
    this.setState({ search: e.target.value.substr(0, 20) });
  }
  handleChange(event) {
    this.setState({ search_by: event.target.value });
  }

  render() {
    if (this.props.list_user.list_user !== null) {
      if (this.props.list_user.list_user.length !== 0) {
        const { resp } = this.props.list_user;
        // this.setState({ resp2: resp });

        const { list_user } = this.props.list_user;
        const jwtToken = localStorage.getItem("jwtToken");
        const header = { header: { Authorization: "Bearer " + jwtToken } };

        // console.log("as");
        // console.log(list_user);
        // const { resp } = this.props.list_user;
        // const { resp2 } = this.state;
        // console.log("respon-nya");
        // console.log(resp);
        // console.log("percobaannya");
        // console.log(this.props.location.data);
        // console.log(this.props.location.data);
        let notification;
        if (resp) {
          // console.log("resp.message");
          // console.log(resp.message);
          if (resp.length !== 0) {
            notification = (
              <div className="alert alert-info alert-dismissible">
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                  onClick={this.reset}
                >
                  &times;
                </button>
                <h4>
                  <i className="icon fa fa-info" /> Alert!
                </h4>
                {resp.message}
              </div>
            );
          }
        }
        // if (resp.length === 0) {
        //   console.log("resp.message");
        //   console.log(resp.message);

        //   notification = (
        //     <div className="alert alert-info alert-dismissible">
        //       <button
        //         type="button"
        //         className="close"
        //         data-dismiss="alert"
        //         aria-hidden="true"
        //         onClick={this.reset}
        //       >
        //         &times;
        //       </button>
        //       <h4>
        //         <i class="icon fa fa-info" /> xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        //       </h4>
        //       {/* {resp.message} */}
        //     </div>
        //   );
        // }
        //------------------------------------------------------------------------------------------
        // localStorage.setItem("error", resp ? resp.error : "");
        // localStorage.setItem("message", resp ? resp.message : "");
        // const { errorx } = localStorage.getItem("error");
        // const messagex = localStorage.getItem("message");

        // const { persons } = this.state;
        const { search, search_by, currentTickets } = this.state;
        let filtered = list_user.slice();

        if (this.state.search && search_by === "fullname") {
          filtered = filtered.filter(ticket =>
            // console.log("list cariii", ticket.username, this.state.search),
            ticket.username !== null
              ? ticket.username
                  .toLowerCase()
                  .indexOf(this.state.search.toLowerCase()) !== -1
              : ""
          );
        }
        // if (this.state.search && search_by === "fullname") {
        //   const absurd = list_user.filter(
        //     list_user => list_user.fullname.indexOf(this.state.search) !== -1
        //   );
        //   console.log("absurd", absurd);
        // }
        if (this.state.search && search_by === "email") {
          filtered = filtered.filter(ticket =>
            ticket.email !== null
              ? ticket.email
                  .toLowerCase()
                  .indexOf(this.state.search.toLowerCase()) !== -1
              : ""
          );
        }
        if (this.state.search && search_by === "mobile_phone") {
          filtered = filtered.filter(ticket =>
            [ticket.mobile_phone] !== null
              ? [ticket.mobile_phone]
                  .toString()
                  .indexOf(this.state.search.toString()) !== -1
              : ""
          );
        }
        const totalTickets = list_user.length;
        if (totalTickets === 0) return null;

        // upper = (

        // );
        table = (
          <div className="table-responsive">
            <table className="table table-bordered table-xs">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Username/Fullname</th>
                  <th>Email</th>
                  <th>Level</th>
                  <th>Status</th>
                  <th>Mobile</th>
                  <th>Action</th>
                </tr>
              </thead>
              {notification}
              <tbody>
                {search === ""
                  ? currentTickets.map(person => (
                      <tr key={person.id}>
                        <td>{person.id}</td>
                        <td>
                          {person.username}/{person.fullname}
                        </td>
                        <td>{person.email}</td>
                        <td>{person.level.name}</td>
                        <td>{person.enabled === 1 ? "enable" : "-"}</td>
                        <td>
                          {!person.mobile_phone ||
                          person.mobile_phone === "null"
                            ? "-"
                            : person.mobile_phone}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Link to={`/setting/edit_user/${person.id}`}>
                            <button
                              type="button"
                              className="btn btn-primary btn-xs"
                            >
                              <i className="fa fa-fw fa-pencil"></i> Edit
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))
                  : filtered.map(person => (
                      <tr key={person.id}>
                        <td>{person.id}</td>
                        <td>
                          {person.username}/{person.fullname}
                        </td>
                        <td>{person.email}</td>
                        <td>{person.level.name}</td>
                        <td>{person.enabled === 1 ? "enable" : "-"}</td>
                        <td>
                          {!person.mobile_phone ||
                          person.mobile_phone === "null"
                            ? "-"
                            : person.mobile_phone}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Link to={`/setting/edit_user/${person.id}`}>
                            <button
                              type="button"
                              className="btn btn-primary btn-xs"
                            >
                              <i className="fa fa-fw fa-pencil"></i> Edit
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        );
        pagi = (
          <div>
            {search === "" ? (
              <div className="panel-footer form-xs">
                <Pagination
                  totalRecords={totalTickets}
                  pageLimit={8}
                  pageNeighbours={1}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            ) : (
              <div className="panel-footer form-xs">Filter is on</div>
            )}
          </div>
        );
      }
    }
    return (
      <div>
        <SettingContainer
          pagename="Setting"
          pagedetailname="ListUser"
          location="user"
        >
          <div className="panel panel-default">
            <div
              style={{
                textAlign: "center"
              }}
            >
              <div className="panel-body form form-xs">
                <div className="pull-left">
                  <Link to="/setting/create_user">
                    <button type="button" className="btn btn-success">
                      Add New
                    </button>
                  </Link>
                </div>
                <div className="pull-right form-inline">
                  <select
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.search_by}
                    name="search_by"
                  >
                    <option value="">Filter</option>
                    <option value="fullname">fullname</option>
                    <option value="email">email</option>
                    <option value="mobile_phone">mobile</option>
                  </select>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control-xs"
                      value={this.state.search}
                      onChange={this.onChanged}
                      disabled={this.state.search_by ? "" : "disabled"}
                      placeholder="&nbsp;search"
                    />

                    {/* <div className="form-group">
                    <button type="button" className="btn btn-default btn-xs">
                      Search
                    </button>
                  </div> */}
                  </div>
                </div>
                <div className="clearfix" />
                {/* {!table ? (
                  // <div className="modal display-block">
                  //   <div className="modal-dialog modal-sm">
                  //     <RingLoader
                  //       css={this.state.override}
                  //       sizeUnit={"px"}
                  //       size={60}
                  //       color={"#ffffff"}
                  //       loading={!pagi}
                  //     />
                  //   </div>
                  // </div>
                  <i
                    className="fa fa-spinner fa-spin fa-3x"
                    style={{
                      opacity: "0.3",
                      top: "50%",
                      left: "50%",
                      position: "absolute"
                      // color: "#ffffff"
                    }}
                  />
                ) : (
                  ""
                )} */}
              </div>
              {/* {upper ? (
                upper
              ) : (
                <i
                  className="fa fa-spinner fa-spin fa-3x"
                  style={{
                    opacity: "0.3",
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    color: "#ffffff"
                  }}
                />
              )}{" "} */}
            </div>
            <div className="col-md-12">
              {!table ? (
                // <div className="modal display-block">
                //   <div className="modal-dialog modal-sm">
                //     <RingLoader
                //       css={this.state.override}
                //       sizeUnit={"px"}
                //       size={60}
                //       color={"#ffffff"}
                //       loading={!pagi}
                //     />
                //   </div>
                // </div>
                <div className="table-responsive">
                  <table className="table table-bordered table-xs">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Username/Fullname</th>
                        <th>Email</th>
                        <th>Level</th>
                        <th>Status</th>
                        <th>Mobile</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="7" style={{ textAlign: "center" }}>
                          No Data Available in This Table
                          <i
                            className="fa fa-spinner fa-spin fa-3x"
                            style={{
                              opacity: "0.3",
                              top: "50%",
                              left: "50%",
                              position: "absolute"
                              // color: "#ffffff"
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                ""
              )}

              <div>{table ? table : ""}</div>
              <div>{pagi ? pagi : ""} </div>
            </div>
          </div>
        </SettingContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  list_user: state.user
});

export default connect(
  mapStateToProps,

  { listUser, reset }
)(ListUser);
