import React from 'react';

import Modal from '../../../../ui/Modal/Modal';
import './Modal.css'

const lastDeliveryModal = (props) => {
  return (
    <Modal title="Last Delivery" onclose={props.onclose} modalid={props.modalid} modalclass={props.modalclass}>
      <div className="modal-body">
        <div>
          <table className="table table-bordered table-hover table-xs ">
            <tbody>
              <tr>
                <th>Date</th>
                <td>{props.dd.created_at}</td>
              </tr>
              <tr>
                <th>C</th>
                <td>{props.dd.consumable_request.c}</td>
              </tr>
              <tr>
                <th>M</th>
                <td>{props.dd.consumable_request.m}</td>
              </tr>
               <tr>
                <th>Y</th>
                <td>{props.dd.consumable_request.y}</td>
               </tr>
               <tr>
                <th>K</th>
                <td>{props.dd.consumable_request.k}</td>
              </tr>
             
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-footer align-right">
        <button type="button" className="btn btn-default pull-right" onClick={props.onclose}>Close</button>
      </div>
    </Modal>
  )
}

export default lastDeliveryModal;
