// import axios from "axios";
import { TICKET_TRACKING } from "./types";
import axios from "../axios-env";

export const ticketTracking = dataTrack => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${dataTrack.jwtToken}`
    }
  };

  axios
    .get(
      `/api/v1/ticket-management/tracking-ticket?ticket_origin_id=${
        dataTrack.id
      }`,
      head
    )
    .then(res => {
      // console.log("tracking Action");
      // console.log(res);
      dispatch({
        type: TICKET_TRACKING,
        payload: res.data.data.ticket_tracking
      });
    })
    .catch(err =>
      dispatch({
        type: TICKET_TRACKING,
        payload: null
      })
    );
};
