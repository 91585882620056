import React, { Component } from "react";
// import Navbar from "../../layout/Navbar";
// import Sidebar from "../../layout/Sidebar";
import Pagination from "../../../common/Pagination";
import ModalHeader from "../../../common/ModalHeader";
import ModalFooter from "../../../common/ModalFooter";
import axios from "axios";
import { Link, Route } from "react-router-dom";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import {
  listPmCustomer,
  edit_pm_cust,
  update_pm_cust,
  listConstumerAdd,
  store_pm_cust,
  del_pm_cust,
  update_default_days,
  del_message
} from "../../../../actions/pmAction";
// import { listPmMachine } from "../../../../actions/pmMacineAction";
import PmMachine from "./PmMachine";

import SettingContainer from "../Container";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import "react-notifications/lib/notifications.css";
let contain = "";
class pm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      pm_days: "",
      currentTickets: [],
      currentTickets2: [],

      currentPage: null,
      totalPages: null
    };
    // this.reset = this.reset.bind(this);

    // this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const jwtToken = localStorage.getItem("jwtToken");

    this.props.listPmCustomer(jwtToken);
    this.props.listConstumerAdd(jwtToken);
    // this.props.listPmMachine(jwtToken);

    // this.setState({ days_input: this.props.list_pm.pm_default.days });
    // console.log("iki lho", this.props.list_pm.pm_default.days);

    // const { resp } = this.props.list_user;
    // this.setState({ resp2: resp });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.list_pm.pm_default !== null) {
      if (this.props.list_pm.pm_default.length !== 0) {
        if (nextProps.list_pm.pm_default.days) {
          // const { days } = nextProps.list_pm.pm_default;
          this.setState({
            days_input: nextProps.list_pm.pm_default.days
          });
          // console.log("ss", nextProps.list_pm.pm_default.days);
        }

        // const { pm_default } = this.props.list_pm;
        if (!nextProps) {
          this.setState({
            days_input: this.props.list_pm.pm_default.days
          });
        }

        // console.log("days_input", nextProps.list_pm.pm_default.days);
      }
    }
  }

  handleDefaultDays = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  submitDefaultDays = () => {
    const saveUserData = {
      days: this.state.days_input
        ? parseInt(this.state.days_input)
        : parseInt(this.props.list_pm.pm_default.days)
    };
    const datax = {
      dat: saveUserData,
      jwtToken: localStorage.getItem("jwtToken")
    };
    // console.log("ini default kirim", datax);
    this.props.update_default_days(datax);
    setTimeout(
      function() {
        // window.location.reload();
        this.props.del_message();
      }.bind(this),
      1000
    );
    // // this.props.history.push("/setting/pm");
    // window.location.reload();
  };
  // handleChange(event) {
  //   this.setState({ search_by: event.target.value });
  // }

  hideModal = () => {
    this.setState({ show: false, showMachine: false });
  };
  hideModal2 = () => {
    window.location.reload();
  };

  reset() {
    window.location.reload();
  }

  createNotification = type => {
    return () => {
      switch (type) {
        case "warning":
          NotificationManager.warning(
            "Are you sure to update default day?",
            "Click me!",
            8000,
            () => {
              // alert("callback");
              this.submitDefaultDays();
            }
          );
          break;
      }
    };
  };

  render() {
    // const { resp } = this.props.list_user;
    // // this.setState({ resp2: resp });
    if (this.props.list_pm.pm_default !== null) {
      if (this.props.list_pm.pm_default.length !== 0) {
        const {
          list_pm_customer,
          customer,
          dataw,
          customers,
          resDelPmCust,
          pm_default,
          resStorePmCust,
          resUpdateDefaultDays
        } = this.props.list_pm;
        const { list_pm_machine } = this.props.pm_machineRed;
        // console.log("machine", list_pm_machine);
        // let notification;
        // if (resUpdateDefaultDays) {
        //   if (resUpdateDefaultDays.length !== 0) {
        //     notification = (
        //       <div className="modal display-block">
        //         <div className="modal-dialog modal-sm ">
        //           <div className="alert alert-info alert-dismissible">
        //             <button
        //               type="button"
        //               className="close"
        //               data-dismiss="alert"
        //               aria-hidden="true"
        //               onClick={this.reset}
        //             >
        //               &times;
        //             </button>
        //             <h4>
        //               <i className="icon fa fa-info" /> Alert!
        //             </h4>
        //             {resUpdateDefaultDays.message}
        //           </div>
        //         </div>
        //       </div>
        //     );
        //   }
        // }
        if (resUpdateDefaultDays) {
          if (resUpdateDefaultDays.length !== 0) {
            NotificationManager.success(
              resUpdateDefaultDays.message,
              "Update",
              3000
            );
          }
        }
        contain = (
          <div className="panel-body form form-xs">
            {/* {notification} */}
            <NotificationContainer />
            <div className="row row-xs">
              <div className="col-md-3">
                <div className="form-group">
                  <label>Days</label>
                  <NumberFormat
                    type="text"
                    className="form-control"
                    name="days_input"
                    value={
                      this.state.days_input
                        ? this.state.days_input
                        : pm_default.days
                    }
                    onChange={this.handleDefaultDays}
                  />
                </div>
                <div className="form-group">
                  <button
                    className="btn btn-warning"
                    // onClick={() => {
                    //   if (
                    //     window.confirm("Are you sure to update default day?")
                    //   ) {
                    //     this.submitDefaultDays();
                    //   }
                    // }}
                    onClick={this.createNotification("warning")}
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <div>
        <SettingContainer
          pagename="Setting"
          pagedetailname="PM"
          location="pm"
          location2="pm_setting"
        >
          <div className="panel panel-default">
            <div>
              {contain ? (
                ""
              ) : (
                <i
                  className="fa fa-spinner fa-spin fa-2x"
                  style={{
                    opacity: "0.3",
                    top: "50%",
                    left: "50%",
                    position: "absolute"
                  }}
                />
              )}
            </div>

            <div className="panel-heading">PM Default Value (Days)</div>
            <div>{contain ? contain : ""}</div>
          </div>
        </SettingContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  list_pm: state.list_pm,
  pm_machineRed: state.pm_machineRed
});

export default connect(
  mapStateToProps,

  {
    listPmCustomer,
    edit_pm_cust,
    update_pm_cust,
    listConstumerAdd,
    store_pm_cust,
    del_pm_cust,
    update_default_days,
    del_message
    // listPmMachine
  }
)(pm);
