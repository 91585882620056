import React from 'react';
import './QProcess.css';
import TextInputDisabled from "../../../common/TextInputDisabled";
import moment from 'moment';
import SpanError from "../../../common/SpanError";

const QProcess = props => {
  return (
    <div>
      <div className="panel panel-default q-process">
        <div className="panel-heading">Quotation</div>
        <div className="panel-body">
          <div className="form form-xs">
            <div className="row row-xs">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="control-label">Quotation Date</label>
                  <input type="text" className="form-control" value={moment(props.created_at).format('L')} disabled/>
                </div>
                <div className="form-group">
                  <label className="control-label">Quotation No</label>
                  <input type="text" className="form-control" value={props.id} disabled/>
                </div>
                <div className="form-group">
                  <label className="control-label">Serial No</label>
                  <input type="text" className="form-control" value={props.dataSubmit.serial_number} disabled/>
                </div>
                <div className="form-group">
                  <label className="control-label">Equipment No</label>
                  <input type="text" className="form-control"  value={props.dataSubmit.equipment_number} disabled/>
                </div>
              </div>
              <div className="col-md-6">
                <div className={props.validator.message('quotation_type', props.dataSubmit.quotation_type, 'required') ? 'form-group quotation-format has-error' : "form-group quotation-format"}>
                  <div>
                    <label className="control-label">Select Quotation Format</label>
                    <label className="control-label"><input type="radio" name="quotation_type" onClick={props.onChangeSubmit} value="Service Labor Charge"/> Service Labor Charge</label>
                    <label className="control-label"><input type="radio" name="quotation_type" onClick={props.onChangeSubmit} value="Machine Relocation"/> Machine Relocation</label>
                    <label className="control-label"><input type="radio" name="quotation_type" onClick={props.onChangeSubmit} value="Toner"/> Toner</label>
                    <label className="control-label"><input type="radio" name="quotation_type" onClick={props.onChangeSubmit} value="Parts Replacement"/> Parts Replacement</label>
                  </div>
                  {props.validator.message('quotation_type', props.dataSubmit.quotation_type, 'required') ? <SpanError>{props.validator.message('quotation_type', props.dataSubmit.quotation_type, 'required')}</SpanError> : ''}
                </div>
                <div className="form-group">
                  <label>Model</label>
                  <input type="text" className="form-control" value={props.dataSubmit.model} disabled/>
                </div>
              </div>
            </div>
            <div className="table-fixed-header" style={{height: "100px"}}>
              <table className="table table-bordered table-xs table-fixed">
                <thead>
                  <tr>
                    <th width="5%">No</th>
                    <th width="27%">Item Description</th>
                    <th width="15%">Item Code</th>
                    <th width="15%">Unit Price</th>
                    <th width="10%">QTY</th>
                    <th width="15%">Disc ($)</th>
                    <th width="15%">Amount ($)</th>
                  </tr>
                </thead>
                <tbody>
                  { props.submit.quotation_items.length > 0 ?

                    props.submit.quotation_items.map((qi, i) => (
                      <tr key={qi.id}>
                        <td width="5%">{i+1}</td>
                        <td width="27%">{qi.item_description}</td>
                        <td width="15%">{qi.item_code}</td>
                        <td width="15%">{qi.unit_price}</td>
                        <td width="10%">{qi.qty}</td>
                        <td width="15%">{qi.disc}</td>
                        <td width="15%">{qi.amount}</td>
                      </tr>
                    ))
                    :
                    <tr>
                      <td colSpan="7" width="101%" style={{textAlign: "center"}}>
                        {props.qival 
                         ? <SpanError><br/>Quotation Items is required</SpanError>
                         : 'No Data Available in This Table'}
                      </td>
                    </tr>
                  }
                  
                  <tr style={{borderBottom: "0px"}}>
                    <th width="101%"><a onClick={props.toggleModal} className="add-consumable pull-right">Add New Item</a></th>
                  </tr>
                </tbody>
              </table>
              
            </div>
            <div className="row row-xs" style={{marginTop: '10px'}}>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Payment Method</label>
                  <select name="payment_method" value={props.submit.payment_method} onChange={props.onChangeSubmit} className="form-control">
                    <option value="Due on Delivery">Due on Delivery</option>
                    <option value="30 Days">30 Days</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="checkbox-inline"><input name="is_waive" type="checkbox" checked={props.dataSubmit.is_waive ? 'checked' : ''} onChange={props.onChangeSubmit}/> Waive</label>
                </div>
                <hr style={{marginBottom: "0px"}}/>
                <label className="checkbox-inline"><input name="is_remark" type="checkbox" checked={props.dataSubmit.is_remark ? 'checked' : ''} onChange={props.onChangeSubmit}/> Remark</label>
                <textarea name="remark" id="" cols="30" rows="3" className="form-control" name="remark" value={props.submit.remark} onChange={props.onChangeSubmit}></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  )
}

export default QProcess;
