import React from 'react';
import { connect } from "react-redux";
import { saveQuotation, removeQuotation } from "../../../../actions/quotationActions";
import DraftServiceLabor from './draftServiceLabor';
import './quotation.css';
class QuotationPdf extends React.Component {
  // componentDidMount(){
  //   if(this.props.submit != null){
      
  //   }
  // }
  render(){
    console.log(this.props);
    return (
      <div>
        <DraftServiceLabor dsubmit={this.props.submit} customer={this.props.customer} contact={this.props.contact}/>
      </div>
    )
  }
}
const mapStateToProps = state => (console.log(state),{
  contact: state.quotation.contact,
  customer: state.quotation.customer,
  submit: state.quotation.submit
});
//export default QuotationPdf;
export default connect(mapStateToProps, { saveQuotation, removeQuotation })(QuotationPdf);
