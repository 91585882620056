import React, { Component } from "react";
import ReactDOM from 'react-dom'
import { Link, Route } from "react-router-dom";
import axios from '../../../axios-env';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
//import GoogleMapReact from "google-map-react";
import iconRed from "../../../img/icon-red.png";

import LayoutContainer from "../../layout/Container";
import CeJobModal from './ceJobModal';

const headers = { headers:{ Authorization: `Bearer ${localStorage.getItem("jwtToken")}`}};

export default class InfoWindowEx extends Component {
  constructor(props) {
    super(props);
    this.infoWindowRef = React.createRef();
    this.contentElement = document.createElement(`div`);
  }

  componentDidUpdate(prevProps) {
    if (this.props.children !== prevProps.children) {
      ReactDOM.render(
        React.Children.only(this.props.children),
        this.contentElement
      );
      this.infoWindowRef.current.infowindow.setContent(this.contentElement);
    }
  }

  render() {
    return <InfoWindow ref={this.infoWindowRef} {...this.props} />;
  }
}
