import React from 'react'
import TextInputDisabled from "../../../common/TextInputDisabled";
import TextFormControl from "../../../common/TextFormControl";

const ProcessInvoice = props => {

  return(
    <React.Fragment>
       <div className="panel panel-default">
        <div className="panel-heading">Process Invoice</div>
        <div className="panel-body">
          <div className="form form-xs">
            <div className="row row-xs">
              <div className="col-md-6">
                <TextInputDisabled label="Service Notification No" value={props.invoice.service_notification_no ? props.invoice.service_notification_no: ''}/>
                <TextInputDisabled label="Service Notification Date" value={props.invoice.service_notification_date ? props.invoice.service_notification_date : ''}/>
                <TextInputDisabled label="Ce Code" value={props.invoice.ce_code ? props.invoice.ce_code : ''}/>
                <TextInputDisabled label="Ce Name" value={props.invoice.ce_name ? props.invoice.ce_name : ''}/>
              </div>
              <div className="col-md-6">
                <TextInputDisabled label="Service Report No" value={props.invoice.service_report_no ? props.invoice.service_report_no : ''}/>
                <TextInputDisabled label="Service Report Date" value={props.invoice.service_report_date ? props.invoice.service_report_date : ''}/>
                <TextInputDisabled label="Invoice No" value={props.invoice.invoice_no ? props.invoice.invoice_no : ''}/>
                <TextInputDisabled label="Invoice Date" value={props.invoice.invoice_date ? props.invoice.invoice_date : ''}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
  
}

export default ProcessInvoice;
