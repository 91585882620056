import React, { Component } from "react";
import LayoutContainer from "../../layout/Container";

const UnAssignContainer = props => {
  return (
    <LayoutContainer
      icon="fa fa-ticket icon"
      pagename={props.pagename}
      pagedetailname={props.pagedetailname}
      sidebarinfo="Unassign"
    >
      <div id="main" className="main-xs">
        {props.children}
      </div>


    </LayoutContainer>
  );
};

export default UnAssignContainer;
