import React, { Component } from "react";
import Sidebar from "../../../layout/Sidebar";
import Navbar from "../../../layout/Navbar"
import { connect } from "react-redux";
import { get_spr_list, check_stock, order_to_db, order_to_wsp, print_stn, del_res_wsp } from "../../../../actions/sprAction";
import TextInputDisabled from "../../../common/TextInputDisabled";
// import TextInputGroup from "../../common/TextInputGroup";
// import TextInputDisabled from "../../common/TextInputDisabled";
import { ticketTracking } from "../../../../actions/ticketTrackingAction";
import TrackingTicket  from "../TrackingTicket";

import TextAreaInput from "../../../common/TextAreaInput";
import moment from "moment";
import Footer from "../../../layout/Footer";
import {
    NotificationContainer,
    NotificationManager
  } from "react-notifications";
  import "react-notifications/lib/notifications.css";
  import PrintTemplate from "react-print";

  import logo_konica from "../../../../img/logo-konica.png";
let left = "";
let right = "";
const tableStyle = {
    border: "none"
  };
  const sss ={
    
    position: "fixed",
    // left: "0",
    bottom: "0",
    width: "100%",
    
    
  }

class Spr extends Component {
    constructor(props) {
    super(props);
    this.state = {
      ticket_type: [],
      ticket_status: [],
      search: "",
      ticket_pic: [],
      ticket_stat_pic: [],
      currentTickets: [],
      currentPage: null,
      totalPages: null,
      start_date: [],
      end_date: [],
      cek: false,
      list: [],
      spin: false,
      data_list: []

      // center: {
      //   lat: 1.338,
      //   lng: 103.977
      // },
      // zoom: 11
    };
    // this.onChanged = this.onChanged.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    // this.handleChange2 = this.handleChange2.bind(this);
    // this.handleStartDate = this.handleStartDate.bind(this);
    // this.handleEndtDate = this.handleEndtDate.bind(this);
    // this.reset = this.reset.bind(this);
    // const jwtToken = localStorage.getItem("jwtToken");
    // this.props.ticketInbox(jwtToken);
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    const jwtToken = localStorage.getItem("jwtToken");

    const data = {
      id: id,
      jwtToken: localStorage.getItem("jwtToken")
    };
    this.props.get_spr_list(data);

    setTimeout(
        function() {
          
const dataTrack = {
        id: this.props.spr_Reducer.ticket_origin.id,
        jwtToken: localStorage.getItem("jwtToken")
      };
      this.props.ticketTracking(dataTrack);

        }.bind(this),
        3000
      );
    
  }
  timerCheck = e => {
    // console.log("cektimer");
  }
  onchangeCheck = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ spin: true });

    
    // const qq = arr.toString()
    // console.log("itmnbr", arr);
    setTimeout(
        function() {
            // console.log("server", this.state.server );
            const arr = {"sparepart": this.props.spr_Reducer.tracking_spr.map(person => ( {"stockroom": this.state.server, "item_no":person.item_number}))};
            const datax = {
                dat: arr,
                ser: this.state.server,
                jwtToken: localStorage.getItem("jwtToken")
              };
              
              this.props.check_stock(datax);
              setInterval(function () {
                // console.log("cek2", datax);
                window.location.reload();

                // this.setState({data_list: datax});
              }, 10*60000); 
              
        }.bind(this),
        2000
      );
      
    //   setInterval(function () {
    //     console.log("cek1", this.state.data_list);
    //     }, 10000);

     
      
      
    
      
      setTimeout(
        function() {
            const { id } = this.props.match.params;
              this.setState({ cek: true, 
                transfer_from_code: this.state.server ==="SP" ? "0214 / SP" :"",
                transfer_to_code: "A2002026",
                ticket_tracking_id: id,
                technician_code: this.props.spr_Reducer.technician.code

             });
            //  console.log("indah2", this.state.indah.toString())

             this.props.spr_Reducer.tracking_spr.map(person => 
                 this.props.spr_Reducer.checkStock
                    .filter(person2=>person2.SapMaterialCode === person.item_number
                        && (person2.Quantity === undefined ? person2.quantity : person2.Quantity) >= person.quantity
                        )
                    .map(person2 => 
                        this.state.list.push({Quantity: person.quantity.toString(),
                            ItemNumber: person2.SapMaterialCode,
                            Description: person.description,
                            id: person.id
                        }
                ))
                );
            //  console.log("indah list", this.state.list);
             
             const dataq = {
                transfer_from_code: this.state.transfer_from_code,
                transfer_to_code: this.state.transfer_to_code,
                ticket_tracking_id: this.state.ticket_tracking_id,
                technician_code: this.state.technician_code,
                transfer_lines: this.state.list

             };
            //  console.log("indah data", dataq);
             this.setState({ spin: false });

        }.bind(this),
        5000
      );
      
    
  };
  submitCheck = e => {
    // console.log("serverxx", this.state.server );
    // const arr = this.props.spr_Reducer.tracking_spr.map(person => (person.item_number));
    // const qq = arr.toString()
    const dataq = {
        transfer_from_code: this.state.transfer_from_code,
        transfer_to_code: this.state.transfer_to_code,
        ticket_tracking_id: this.state.ticket_tracking_id,
        technician_code: this.state.technician_code,
        transfer_lines: this.state.list,
        in_transit_code: ""

     };
    //  console.log("data", dataq);
    //  let saveUserData = new FormData();
    //  saveUserData.append("transfer_from_code", this.state.transfer_from_code);
    //  saveUserData.append("transfer_to_code", this.state.transfer_to_code);
    //  saveUserData.append("ticket_tracking_id", this.state.ticket_tracking_id);
    //  saveUserData.append("technician_code", this.state.technician_code);
    //  saveUserData.append("transfer_lines", this.state.list.json());
    //  saveUserData.append("in_transit_code", "");

    //  console.log("saveUserData", saveUserData);

    const datax = {
        // dat: qq,
        ser: dataq,
        jwtToken: localStorage.getItem("jwtToken")
      };

    this.props.order_to_db(datax)
  };
  submitWsp = e => {
    // console.log("serverxx", this.state.server );
    // const arr = this.props.spr_Reducer.tracking_spr.map(person => (person.item_number));
    // const qq = arr.toString()
    const dataq = {
        id: this.props.spr_Reducer.order_db.id,
        status: "Released"

     };
     let saveUserData2 = new FormData();
     saveUserData2.append("id", this.props.spr_Reducer.order_db.id);
     saveUserData2.append("status", "Released");
    
//  console.log("asda", this.props.spr_Reducer.order_db.id);
    const datax = {
        // dat: qq,
        ser: saveUserData2,
        jwtToken: localStorage.getItem("jwtToken")
      };

    this.props.order_to_wsp(datax)
    setTimeout(
        function() {
        this.props.del_res_wsp();
        }.bind(this),
        3000
      );
    
  };
  printContent = () => {
//   const datay = this.props.spr_Reducer.order_db.id;
  const datay = {
    id: this.props.spr_Reducer.order_db.id,
    jwtToken: localStorage.getItem("jwtToken")

 };
    this.props.print_stn(datay);
    setTimeout(
        function() {
        window.print();
            
        }.bind(this),
        3000
      );
     
  };
  handleBack = event => {
    event.preventDefault();
    this.props.history.push("/ticket-management");
  };
  showModalMachine = () => {
    document.body.style.overflow = "hidden";
    this.setState({ showModal: true });
  };
  hideModal = () => {
    document.body.style.overflow = "unset";
    this.setState({ showModal: false });
  };
  render() {
    if (this.props.spr_Reducer.originator !== null) {
        if (this.props.spr_Reducer.originator.length !== 0) {
      const {spr, 
        department, 
        originator, 
        action_by, 
        ticket_origin, 
        machine_customer,
        customers,
        machine, 
        machine_models,
        customer,
  technician,
  blocked_by,
  contracts,
  sales,
  service_code,
  tracking_spr,
  checkStock, 
  contact_detail,
  order_db,
  res_cek,
  res_wsp,
  resStn
    } =this.props.spr_Reducer;
    // this.props.spr_Reducer.ticket_origin.id
    // const { res_cek} =this.props.spr_Reducer;
    const { ticket_tracking } = this.props.ticket_tracking;
    if (res_wsp.length !== 0) {
        if (res_wsp.code === 200) {
          NotificationManager.success(
            res_wsp.message,
            "Submit WSP"
          );
        }else{NotificationManager.error(
            res_wsp.message,
            "Submit WSP"
          );}

      };
    left = (
<div className="col-md-6">
                    <form action="create-new-origination-ticket.html">

                    <div className="panel panel-default">
                        <div className="panel-heading">
                            Originator
                        </div>
                        <div className="panel-body">
                            <div className="form form-xs">
                                <div className="row row-xs">
                                    <div className="col-md-6">
                                        <TextInputDisabled label="Departement" value={department.name}/>
                                        <TextInputDisabled label="Date/Time Created" value={moment(originator.created_at).format("L")}/>
                                    </div>
                                    <div className="col-md-6">
                                        <TextInputDisabled label="Name" value={originator.fullname}/>
                                        <TextInputDisabled label="User ID" value={originator.id}/>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            Contact Details
                        </div>
                        <div className="panel-body">
                            <div className="form form-xs">
                                <div className="row row-xs">
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label">Date/Time Contact</label>
                                            <input type="text" className="form-control" value={contact_detail.date_of_contact} disabled />
                                        </div>  
                                    </div> */}
                                    <div className="col-md-8">
                                        <div className="form-group">
                                            <label className="control-label" style={{ textAlign: "left", width: "70%" }}>Date/Time Contact</label>
                                            <input type="text" className="form-control" value={contact_detail.date_of_contact} disabled />
                                        </div>  
                                        <div className="form-group">
                                            <label className="control-label" style={{ textAlign: "left", width: "70%" }}>Contact Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={contact_detail.gender}
                                                style={{ width: "20%" }}
                                                disabled
                                            />
                                            
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={contact_detail.contact_name}
                                                disabled
                                                style={{ width: "80%" }}
                                            />
                                        </div>  
                                        <div className="form-group">
                                            <label className="control-label" style={{ textAlign: "left", width: "70%" }}>Channel of Contact</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={contact_detail.type_channel_of_contact}
                                                style={{ width: "45%" }}
                                                disabled
                                            />
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={contact_detail.channel_of_contact}
                                                disabled
                                                style={{ width: "55%" }}
                                            />
                                        </div>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel panel-default">
                        <div className="panel-heading">
                            Customer Information
                        </div>
                        <div className="panel-body">
                            <div className="form form-xs">
                                <div className="row row-xs">
                                    <div className="col-md-6">
                                        <fieldset>
                                            <legend>Search By</legend>
                                            <div className="form-group">
                                                <label className="control-label">Serial No</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={machine.machine_serial ? machine.machine_serial : ""} disabled/>
                                                    <span className="input-group-btn">
                                                        <button className="btn btn-default" disabled><i className="fa fa-search"></i></button>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label">Equipment No</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={machine_customer.item_no ? machine_customer.item_no : "" } disabled />
                                                    <span className="input-group-btn">
                                                        <button className="btn btn-default" disabled><i className="fa fa-search"></i></button>
                                                    </span>
                                                </div>
                                            </div>  
                                            <div className="form-group">
                                                <label className="control-label">Customer Name</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={customers.CustomerName ? customers.CustomerName : ""} disabled />
                                                    <span className="input-group-btn">
                                                        <button className="btn btn-default" data-toggle="modal" data-target="#modal-search-name" type="button" disabled><i className="fa fa-search"></i></button>
                                                        <button className="btn btn-default" data-toggle="modal" data-target="#modal-customer-multi" type="button" disabled>Multi</button>
                                                    </span>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <TextInputDisabled label="Model" value={machine_models.model_name}/>
                                        <TextInputDisabled label="Customer Category" value={customers.customer_category}/>
                                        <TextInputDisabled label="Preferred CE" value={technician.name}/>
                                        <fieldset>
                                            <legend>SHIP TO</legend>
                                        <TextInputDisabled label="Ship to Name" value={machine_customer.ship_to_name}/>
                                        <TextInputDisabled label="Ship to Code" value={machine_customer.ship_to_code}/>
                                        <TextInputDisabled label="Ship to Contact" value={machine_customer.ship_to_contact_name}/>
                                        </fieldset>
                                        <fieldset>
                                            <legend>CSRC</legend>
                                        <TextInputDisabled label="CSRC Status" value={customers.csrc_status}/>
                                        <TextInputDisabled label="Last Date of Connection" value={""}/>
                                        </fieldset>
                                    </div>

                                    <div className="col-md-6">
                                        <fieldset>
                                            <legend>BLOCK BY</legend>
                                        <TextInputDisabled label="Status" value={customers.blocked_status === 1 ? "BLOCKED" : ""}/>
                                        <TextInputDisabled label="Department" value={blocked_by.blocked_by_department ? blocked_by.blocked_by_department : ""}/>
                                        <TextInputDisabled label="Name" value={blocked_by.blocked_by_user_name ? blocked_by.blocked_by_user_name : ""}/>
                                        <TextInputDisabled label="User ID" value={blocked_by.blocked_by_user_id ? blocked_by.blocked_by_user_id : ""}/>
                                        </fieldset>
                                        <fieldset>
                                            <legend>Contract Detail</legend>
                                        <TextInputDisabled label="Contract No" value={contracts.contract_number ? contracts.contract_number : ""}/>
                                        <TextInputDisabled label="End Date" value={contracts.contract_end_date ? moment(contracts.contract_end_date).format("L") : ""}/>

                                        </fieldset>
                                       
                                        <fieldset>
                                            <legend>Sales Details</legend>
                                        <TextInputDisabled label="Sales Person Code" value={sales.Sales_Person_Code ? sales.Sales_Person_Code : ""}/>
                                        <TextInputDisabled label="Sales Person Name" value={sales.Sales_Person_name ? sales.Sales_Person_name : ""}/>
                                        <TextInputDisabled label="DSM Code" value={sales.dsm_code ? sales.dsm_code : ""}/>
                                        <TextInputDisabled label="DSM Name" value={sales.dsm_name ? sales.dsm_name : ""}/>
                                        </fieldset>
                                    </div>
                                </div>

                                <fieldset>
                                    <legend>Customer Details</legend>
                                    <TextAreaInput label="Ship to Address" value={machine_customer.ship_to_address ? machine_customer.ship_to_address : ""} disabled="disabled"/>
                                    <TextAreaInput label="Location of Equipment No" value={machine_customer.location ? machine_customer.location : ""} disabled="disabled"/>
                                    <div className="row row-xs">
                                        <div className="col-md-6">
                                            {/* <TextInputDisabled label="Telephone No" value={customers.customer_phone1 ? customers.customer_phone1 : ""}/> */}
                                            <div className="form-group" style={{display: "flex"}}>
                                                <label className="control-label ">Telephone No</label>
                                                <div className="col-sm-offset-1 col-sm-1">
                                                <input type="text" className="form-control" value={customers.customer_phone1 ? customers.customer_phone1 : ""} disabled /></div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                        <TextInputDisabled label="Postal Code" value={customers.ship_to_postcode ? customers.ship_to_postcode : ""}/>
                                        <TextInputDisabled label="Delivery Date" value={machine_customer.delivery_date ? machine_customer.delivery_date : ""}/>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend>SLA DETAILS</legend>
                                    <TextInputDisabled label="SLA Response Time" value={customers.sla_details ? customers.sla_details : ""}/>
                                    <TextAreaInput label="Additional SLA Detail" disabled="disabled" value=""/>
                                    
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className="text-right">
                        <button className="btn btn-warning btn-sm" type="submit">TICKET IN PROGRESS</button>
                        <button className="btn btn-default btn-sm" type="submit">CUSTOMER HISTORY</button>
                    </div>
                    </form>
                </div>
    );
    right = (
<div className="col-md-6">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            Ticket Number : {spr.ticket_number ? spr.ticket_number : ""}
                            <NotificationContainer />
                        </div>
                        <div className="panel-body">
                            <div className="form form-xs">
                                <div className="form-inline form-group">
                                    <label style={{width: "50px"}}>From</label>
                                    <input type="text" className="form-control" value={action_by.fullname} disabled style={{width: "100px"}}/>
                                    <label style={{width: "20px"}}>To</label>
                                    <input type="text" className="form-control" value="Spare Parts" disabled style={{width: "100px"}}/>
                               
                                    <label style={{width: "90px"}}>Requirement Process</label>
                                    <input type="text" className="form-control" value="Fulfilment" style={{width: "100px"}} disabled/>{" "}
                                    {/* <button className="btn btn-default">Track Ticket</button> */}
                                    <button
                                    className="btn btn-default"
                                    style={{ width: "100px" }}
                                    onClick={this.showModalMachine}
                                    >
                                    Track Ticket
                                    </button>
                                    <TrackingTicket 
                                   showModal={this.state.showModal}
                                   hideModal={this.hideModal}
                                   datas={ticket_origin.id}
                                //    aq={ticket_tracking}
                                   />
                                    

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading">Sparepart Requisition</div>
                        <div className="panel-body">
                            <div className="form form-xs">
                                <div className="row row-xs">
                                    <div className="col-md-6">
                                        <TextInputDisabled label="SPR No" value={spr.spr_number ? spr.spr_number : ""}/>
                                        <TextInputDisabled label="Service Order Type" value={service_code.job_code} />
                                        <TextInputDisabled label="Date" value={spr.created_at}/>
                                    </div>
                                    <div className="col-md-6">
                                        <TextInputDisabled label="CE Code" value={technician.code}/>
                                        <TextInputDisabled label="CE Name" value={technician.name}/>
                                        <TextInputDisabled label="Approved By" value={technician.superior_code}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading">Sparepart Requested {this.state.server}
                        
                        </div>
                        <div className="form form-xs">
                        <div className="col-sm-4 pull-right">
                            
                            <select name="server" id="" className="form-control" onChange={this.onchangeCheck}>
                                        <option value="">---select---</option>
                                        <option value="SP">Teban</option>
                                </select>
                                
                                {/* <button onClick={this.submitCheck}>car</button> */}
                                {/* <input type="hidden" name="item_number" value={spr_requests.map(person => (person.item_number))}/> */}
                                </div></div>
                        <table className="table table-bordered table-xs" style={{width: "auto"}}>
                            <thead>
                            <tr>
                                <th>No</th>
                                <th>Item No</th>
                                <th>Description</th>
                                <th>Quantity</th>
                                <th>Inventory</th>
                                <th>Fulfill</th>
                                <th>Out of Stock</th>
                                <th>Self coolect by CE</th>
                                <th>Change Part?</th>
                            </tr>
                            </thead><tbody>
                            {tracking_spr.map((person, index) => (
                            <tr>
                                <td>{(index+1)}</td>
                                <td>{person.item_number}</td>
                                <td>{person.description}</td>
                                <td>{person.quantity}</td>
                                
                                <td>{checkStock.length !== 0 ? checkStock.map(person2 => 
                                    {if (person2.SapMaterialCode === person.item_number){
                                        return(person2.Quantity === undefined ? person2.quantity : person2.Quantity)
                                    }}) : "-"}</td>
                                <td className="text-center">
                                    {/* <input type="radio" name={person.id} 
                                    checked={
                                        (checkStock.map(person2 => 
                                            {if (person2.SapMaterialCode === person.item_number){
                                                
                                                    return(person2.Quantity - person.quantity  >= 0 ? true : false)
                                            }}))
                                    }
                                    /> */}
                                    {(checkStock.map(person2 => 
                                        {if (person2.SapMaterialCode === person.item_number){
                                            
                                                return((person2.Quantity === undefined ? person2.quantity : person2.Quantity) - person.quantity >= 0 ? <i className="fa fa-fw fa-check"></i> : "")
                                            
                                            
                                        }})) }
                                </td>
                                <td className="text-center">
                                    {/* <input type="radio" name={person.id}
                                    checked={
                                        (checkStock.map(person2 => 
                                            {if (person2.SapMaterialCode === person.item_number){
                                                
                                                    return(person2.Quantity - person.quantity  <= 0 ? true : false)
                                            }}))
                                    }
                                    /> */}
                                    {
                                        (checkStock.map(person2 => 
                                            {if (person2.SapMaterialCode === person.item_number){
                                                
                                                    return((person2.Quantity === undefined ? person2.quantity : person2.Quantity) - person.quantity  <= 0 ? <i className="fa fa-fw fa-check"></i> : "")
                                            }}))
                                    }
                                </td>
                                <td>
                                    Yes
                                </td>
                                <td className="text-center">
                                    <button className="btn btn-default btn-xs">Fulfillment History</button>
                                </td>
                            </tr>
                            ))}
                            
                            </tbody>
                        </table>
                        <div className="panel-heading" style={{textAlign: "right"}}>
                        {this.state.spin ? (<i className="fa fa-spinner fa-spin fa-1x" style={{opacity: "0.8"}} />) : ""}
                        <button className="btn btn-success btn-xs" onClick={this.submitCheck} disabled={this.state.list.length === 0}>SAVE</button></div>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading">Transfer Order</div>
                        <div className="panel-body">
                            <div className="form form-xs">
                                <div className="row row-xs">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">Transfer Order</label>
                                            <input type="text" className="form-control" value={order_db.transfer_order_number ? order_db.transfer_order_number : ""} disabled />
                                        </div>
                                        {/* transfer_from_code: this.state.server ==="SP" ? "0214 / SP" :"",
                transfer_to_code: "A2002026",
                ticket_tracking_id: id,
                technician_code: this.props.spr_Reducer.technician.code,
                transfer_lines: this.state.list */}
                                        <div className="form-group">
                                            <label htmlFor="">Stock (From){this.state.transfer_from_code}</label>
                                            <input type="text" className="form-control" value={this.state.transfer_from_code} disabled /> 
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">Stock (To)</label>
                                            <input type="text" className="form-control" value={this.state.transfer_to_code} disabled />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">in Transit Code</label>
                                            <input type="text" className="form-control" value={order_db.in_transit_code ? order_db.in_transit_code : ""} disabled />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">Movement Type</label>
                                            <input type="text" className="form-control" value={order_db.movement_type ? order_db.movement_type : ""} disabled />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">Status</label>
                                            <select name="" id="" className="form-control">
                                                <option value="">-</option>
                                                <option value="OPEN" selected={order_db.status === "OPEN"}>OPEN</option>
                                                <option value="Released" selected={order_db.status === "Released"}>Released</option>

                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">Plant</label>
                                            <input type="text" className="form-control" disabled />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">Comment</label>
                                            <textarea name="" id="" cols="30" rows="5" className="form-control" disabled></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className="table table-xs">
                        <tbody>
                            <tr>
                                <td><button className="btn btn-default btn-block btn-xs"  onClick={this.submitWsp} disabled={order_db.length === 0}>SUBMIT</button></td>
                                <td><button className="btn btn-default btn-block btn-xs" onClick={this.printContent} disabled={!this.props.spr_Reducer.order_db.id}>PRINT</button></td>
                                <td><button className="btn btn-default btn-block btn-xs" disabled>CREATE NOTE</button></td>
                                <td><button className="btn btn-default btn-block btn-xs" disabled>SAVE</button></td>
                                <td><button className="btn btn-default btn-block btn-xs" disabled>RESET</button></td>
                                <td><button className="btn btn-default btn-block btn-xs"  onClick={this.handleBack}>ESC</button></td>
                                
                            </tr>
                        </tbody>
                    </table>
                </div>
    );
        }}
    return (
        
        <div id="app"><div id="react-no-print">
            <Navbar
            information="TICKET MANAGEMENT"
            detail="Process Ticket - Spr"
            />

            <Sidebar information="TicketManagement" />
            </div>
        <div id="main" className="main-xs"><div id="react-no-print">
            <div className="row row-xs">
                <div className="col-md-12 text-right">
                    <a href="#" className="btn btn-warning"><i className="fa fa-plus"></i> Create Note</a>
                </div>
            </div></div>
            <br/>
            
            <PrintTemplate>
             <div><div id="print-mount"> 
             <table className={tableStyle}>
                 <tbody>
                 <tr>
                     <td style={{width: "20%"}}><img src={logo_konica} style={{height:"10%", width: "90%"}} /></td>
                     <td style={{width: "30%"}}>
                     <h6>KONICA MINOLTA</h6>
                     <h6>BUSINESS SOLUTIONS (M)</h6>
                     <h6>SDN BHD (72640)</h6><br/>
                     <h6>Fax : -</h6>
                     <h6>Tel : -</h6>

                     </td>
                     <td style={{width: "50%", border: "none"}} >
                         
                             <td style={{width: "70%", border: "none", fontSize: "10px"}}>
                             <p>STN No.</p> 
                             <p>Date</p>
                             <p>Reason Code</p>
                             <p>Movement Type</p>
                             <p>Where House Shipment No.</p>
                             
                             <p>Sales Order No.</p>
                             <p>Req. Delivery Note</p>
                             
                             <p>Stock Room</p>
                             </td>
                             <td style={{width: "30%",  border: "none", fontSize: "9px"}}>
                             <p>: {this.props.spr_Reducer.transfer_order.transfer_order_number}</p>
                             <p>: {this.props.spr_Reducer.resStn.date}</p>
                             <p>: {this.props.spr_Reducer.resStn.reason_code}</p>
                             <p>: {this.props.spr_Reducer.transfer_order.movement_type}</p>
                             <p>: {this.props.spr_Reducer.resStn.reason_code}</p>
                             <p>: </p>
                             <p>: </p>
                             <p>: A7UTE011</p>
                             </td>
                         
                     </td>
                     
                 </tr>
                 <tr>
                         <td style={{width: "50%", fontSize: "10px"}} colSpan="2">Issued To: A7UTE011,Shaharuddin Bin Abdullah<br/>
                         Infinite Centre, Lot 1, Jalan 13/6, 46200 Petaling Jaya, Selangor, Malaysia
                         </td>
                         <td style={{width: "50%", fontSize: "10px"}} colSpan="2">Issued By: PJ SPAREPART STOCK ROOM<br/>
                         Infinite Centre, Lot 1, Jalan 13/6, 46200 Petaling Jaya, Selangor, Malaysia
                         </td>
                     </tr>
                 </tbody>
             </table>
             <table className="table table-bordered">
             <thead>
                     <tr>
                         <td style={{width: "10%"}}>Seq</td>
                         <td style={{width: "20%"}}>Item No. </td>
                         <td style={{width: "20%"}}>Item Description</td>
                         <td style={{width: "10%"}}>Qty</td>
                         <td style={{width: "10%"}}>UOM</td>
                         <td style={{width: "10%"}}>Valuation</td>
                         <td style={{width: "20%"}}>Document Ref</td>
                     </tr>
                 </thead>
                 <tbody>
                 {this.props.spr_Reducer.resStn.spr ? this.props.spr_Reducer.resStn.spr.map((person, index) => (
                     <tr>
                         <td >{(index+1)}</td>
                         <td >{person.ItemNumber}</td>
                         <td >{person.Description}</td>
                         <td >{person.Quantity}</td>
                         <td >{this.props.spr_Reducer.resStn.uom}</td>
                         <td >{this.props.spr_Reducer.resStn.valuation}</td>
                         <td >{this.props.spr_Reducer.transfer_order.transfer_order_number+"/"}</td>
                     </tr>
                     )) : ""}
                 </tbody>
             </table>
             <div style={sss}>
                 <table>
                     <tbody>
                         <tr>
                             <td style={{width: "36%", textAlign:"left", fontSize: "8px"}}>
                                <p>TRADEWINDS PLANTATION MANAGEMENT</p>
                                <p>SDN BHD ,THE CAPERS SENTUL, LEVEL 11, UNIT 6, LORONG TIMURSENTUL SELATAN,</p>
                                <p>testing</p>
                                <p>Tehnician : Shaharuddin Bin Abdullah, Model : C258 COPIER/PRINTER , Serial : A7R0041003321</p>
                                <p>Updated By : PJSP001</p>
                            </td>
                            <td style={{width: "32%", textAlign:"left", fontSize: "9px"}}>
                                <p>RECEIVED BY</p><br/><br/>
                                <p>_______________________________</p>
                                <p>Date</p><br/><br/><br/>
                            </td>
                            <td style={{width: "32%", textAlign:"left", fontSize: "9px"}}>
                                <p>APPROVED BY</p><br/><br/>
                                <p>_______________________________</p>
                                <p>Date</p><br/><br/><br/>
                            </td>
                         </tr>
                         
                     </tbody>
                 </table>
                 <div style={{ fontSize: "9px"}}>Printed on Konica Minolta Multi-Functional Printer</div>
      </div>
             
                  </div>
             </div>
           </PrintTemplate><div id="react-no-print">
            <div className="row row-xs">
                <div>{left ? "" : (
                    <i
                    className="fa fa-spinner fa-spin fa-3x"
                    style={{
                      opacity: "0.3",
                      top: "50%",
                      left: "50%",
                      position: "absolute"
                    }}
                  />
                )}</div>
                <div>{left ? left : ""}</div>
                <div>{right ? right : ""}</div>
            </div>
            </div>
        </div>
        {/* <div style={sss}>sadfs</div> */}
        <div id="react-no-print">

            <Footer/>
            </div>
    </div>
    );
  }
}
const mapStateToProps = state => ({
 
    spr_Reducer: state.spr_Reducer,
    ticket_tracking: state.trackingReducer
  });
  export default connect(
    mapStateToProps,
    {
        get_spr_list,
        check_stock, order_to_db, order_to_wsp, print_stn, del_res_wsp, ticketTracking
  
    }
  )(Spr);

