import React from "react";
import PropTypes from "prop-types";

const TextFormControl = ({
  dcname,
  type,
  label,
  value,
  cname,
  name,
  onChange
}) => {
  return (
    <div className={dcname}>
      <label className="control-label"> {label} </label>
      <input
        type={type}
        className={cname}
        name={name}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

TextFormControl.propTypes = {
  type: PropTypes.string.isRequired,
  cname: PropTypes.string.isRequired,
  dcname: PropTypes.string.isRequired
};

TextFormControl.defaultProps = {
  type: "text",
  cname: "form-control",
  dcname: "form-group"
};

export default TextFormControl;
