import { DETAIL_CONSUMABLE, CREATE_SALES_ORDER } from "./types";
import axios from "../axios-env";

export const proccessConsumable = data => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${data.jwtToken}`
    }
  };
  axios
    .get(`/api/v1/ticket-process/detail-consumable/${data.id}`, head)
    .then(res => {
      dispatch({
        type: DETAIL_CONSUMABLE,
        data: res.data.data,
        ticket_origin: res.data.data.ticket_origin,
        machine_customer: res.data.data.ticket_origin.machine_customer,
        machine_customer_detail_customer:
          res.data.data.ticket_origin.machine_customer.customers,
        machine: res.data.data.ticket_origin.machine_customer.machine,
        machine_model:
          res.data.data.ticket_origin.machine_customer.machine.machine_models,
        machine_block_by:
          res.data.data.ticket_origin.machine_customer.customers.blocked_by,
        machine_contract:
          res.data.data.ticket_origin.machine_customer.contracts,
        action_by: res.data.data.action_by,
        received_by: res.data.data.received_by,
        originator: res.data.data.ticket_origin.originator,
        originator_department:
          res.data.data.ticket_origin.originator.department,
        customer: res.data.data.ticket_origin.customer,
        customer_sales: res.data.data.ticket_origin.customer.sales,
        consumable_request: res.data.data.consumable_request,
        ticket_type: res.data.data.ticket_type
      });
    })
    .catch(err => {
      dispatch({
        type: DETAIL_CONSUMABLE,
        data: null,
        ticket_origin: null,
        machine_customer: null,
        machine_customer_detail_customer: null,
        machine: null,
        machine_model: null,
        machine_block_by: null,
        machine_contract: null,
        action_by: null,
        received_by: null,
        originator: null,
        originator_department: null,
        customer: null,
        customer_sales: null,
        consumable_request: null,
        ticket_type: null
      });
    });
};

export const createSalesOrder = (salesOrder, history) => dispatch => {
  const head = {
    headers: {
      Authorization: `Bearer ${salesOrder ? salesOrder.jwtToken : ""}`
    }
  };
  const data = salesOrder ? salesOrder.dt : "";

  axios
    .post(
      "/api/v1/ticket-management/consumable-request/store",
      salesOrder,
      head
    )
    .then(res => {
      // console.log("respon dari create salse order ", res.data.meta.code);

      if (res.data.meta.code === 200) {
        history.push("/ticket-management");
      }
    })
    .catch(err => {
      // console.log(err);
    });
};
