import React, { Component } from 'react'
import TicketManagementContainer from '../TicketManagement/Container'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import PaginationReactTable from "../../../common/PaginationReactTable";
import Pagination from "../../../common/Pagination";
import DatePicker, { subDays } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from '../../../../axios-env'
import moment from 'moment'
import { Link } from "react-router-dom";

let page = 1;
const loadingq = (
  <div>
    <i className="fa fa-spinner fa-spin fa-2x" />
  </div>
);

class QuotationInbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      start_date: "",
      end_date: "",
      quotation_type: "all",
      quotation_no: "all",
      pages: 1,
      allPages: "",
      currentPage: 1,
    };
  }

  handleStartDate = start_date => {
    this.setState({ start_date });
    setTimeout(
      function() {
        this.refReactTable.fireFetchData();
      }.bind(this),
      1000
    );
  };
  handleEndDate = end_date => {
    this.setState({ end_date });
    setTimeout(
      function() {
        this.refReactTable.fireFetchData();
      }.bind(this),
      1000
    );
  };
  handleChange = e => {
    if(!isNaN(e.target.value)){
      if(e.target.name === 'quotation_no' && e.target.value === ''){e.target.value= 'all'}
      if(e.target.name === 'currentPage' && e.target.value > this.state.allPages){e.target.value= this.state.allPages}
      if(e.target.name === 'currentPage' && e.target.value < 1){e.target.value= 1}
      this.setState({ [e.target.name]: e.target.value });
      setTimeout(
        function() {
          this.refReactTable.fireFetchData();
        }.bind(this),
        1000
      );
    }
  };

  changePage = (e, p) => {
    this.setState({
      currentPage: p
    });
    setTimeout(
      function() {
        this.refReactTable.fireFetchData();
      }.bind(this),
      1000
    );
  }

  nextPrevious = (e, value) => {
    let cp = +this.state.currentPage + 1;
    if(value == 'back'){ cp = +cp-2}
    this.setState({currentPage: cp});

     setTimeout(
      function() {
        this.refReactTable.fireFetchData();
      }.bind(this),
      1000
    );
  }
  
  render(){
    const columns = [
      {
        Header: 'Date',
        headerClassName: 'my-favorites-column-header-group',
        columns: [
          {
            accessor: 'created_at',
            sortable: false,
            filterable: false,
            Cell: ({ row }) => moment(row.created_at).format("L")
          }
        ]
      },
      {
        Header: 'Time',
        headerClassName: 'my-favorites-column-header-group',
        columns: [{
          accessor: 'created_at',
          sortable: false,
          filterable: false,
          Cell: ({ row }) => moment(row.created_at).format("HH:mm")
        }]
      },{
        Header: 'Service Order',
        columns: [{
          accessor: 'ticket_origin.service_code.job_code',
          sortable: false,
          filterable: false,
        }]
      },{
        Header: 'Serial No',
        headerClassName: 'my-favorites-column-header-group',
        columns: [{
          accessor: 'ticket_origin.machine_customer.machine.machine_serial'
        }]
      },{
        Header: 'Model No',
        headerClassName: 'my-favorites-column-header-group',
        columns: [{
          accessor: 'ticket_origin.machine_customer.machine.machine_models.model_name'
        }]
      },{
        Header: 'Ship to Name',
        headerClassName: 'my-favorites-column-header-group',
        columns: [{
          accessor: 'ticket_origin.machine_customer.customers.CustomerName'
        }]
      },{
        Header: 'Ticket No',
        headerClassName: 'my-favorites-column-header-group',
        columns: [{
          accessor: 'id'
        }]
      },{
        Header: 'Quotation Type',
        headerClassName: 'my-favorites-column-header-group',
        columns: [{
          accessor: 'quotation.quotation_type'
        }]
      },{
        Header: 'Status',
        headerClassName: 'my-favorites-column-header-group',
        columns: [{
          accessor: 'ticket_status.name'
        }]
      },{
        Header: 'Action',
        headerClassName: 'my-favorites-column-header-group',
        columns: [{
          Cell: ({ row }) => {
            return (
            <div style={{ textAlign: "center" }}>
              <Link to={`/ticket-management/quotation/${row.id}`}>
                <button
                  type="button"
                  className="btn btn-primary btn-xs"
                  disabled={row._original.ticket_status.id === 3 ? 'disabled': ''}
                >
                  SELECT
                </button>
              </Link>
            </div>
            )
          }
        }]
      }
    ]

    var pagi = []
    for(var i=0;i<this.state.allPages;i++){
      pagi.push(i+1);
    }
    
    return (
      <TicketManagementContainer pagename="Ticket Management" pagedetailname="Quotation Inbox" location="quotation-inbox">
        <div className="panel panel-default">
          <div className="panel-heading">Quotation Inbox</div>
          <div className="panel-body form-xs">
            <div className="pull-left form-inline">
              <DatePicker
                className="form-control "
                selected={this.state.start_date}
                style={{ width: "130px" }}
                onChange={this.handleStartDate}
                selectsStart
                startDate={this.state.start_date}
                endDate={this.state.end_date}
                placeholderText="Start date"
                isClearable={this.state.start_date}
              />
              <label>-</label>
              <DatePicker
                className="form-control "
                selected={this.state.end_date}
                onChange={this.handleEndDate}
                style={{ width: "130px" }}
                selectsEnd
                startDate={this.state.start_date}
                endDate={this.state.end_date}
                minDate={this.state.start_date}
                placeholderText="End date"
                isClearable={this.state.end_date}
              />
              <label htmlFor="">Quotation Type </label>
              <select name="quotation_type" className="form-control" onChange={this.handleChange}>
                <option value="all">All</option>
                <option value="Service labor Charge">Service labor Charge</option>
                <option value="Machine Relocation">Machine Relocation</option>
                <option value="Toner">Toner</option>
                <option value="Parts Replacement">Parts Replacement</option>
              </select>
            </div>
            <div className="pull-right form-inline">
              <label htmlFor="">Quotation No </label>
              <input name="quotation_no" className="form-control" type="text" onChange={this.handleChange} value={this.state.quotation_no == 'all' ? '' : this.state.quotation_no}/>
            </div>
          </div>
          <ReactTable data={this.state.data} columns={columns} defaultPageSize={5} className="table table-bordered" manual showPageSizeOptions={false} minRows={1}
                      //PaginationComponent={PaginationReactTable}
                      pages={this.state.pages}
                      showPagination={false}
                      loading={this.state.loading}
                      loadingText={loadingq}
                      ref={refReactTable => {
                        this.refReactTable = refReactTable;
                      }}
                      onFetchData={(state, instance) => {
                        this.setState({loading: true})
                        const headers = { headers: { Authorization: `Bearer ${localStorage.getItem("jwtToken")}`}};

                        const st_dt = this.state.start_date ? moment(this.state.start_date).format("YYYY-MM-DD 00:00:00") : "0001-01-01 00:00:00";
                        const en_dt = this.state.end_date ? moment(this.state.end_date).add(1, "days").format("YYYY-MM-DD 00:00:00") : "0001-01-01 00:00:00";
                        axios.get(`/api/v1/ticket-management/quotation/list-filter/${this.state.currentPage}?quotation_no=${this.state.quotation_no}&quotation_type=${this.state.quotation_type}&start_date=${st_dt}&end_date=${en_dt}`, headers)
                          .then((res) => {
                            
                            const mod = res.data.data.total % 5;
                            const addmod = mod > 0 ? 1 : 0;
                            const pagesData = res.data.data.total === 0 ? 1 : (res.data.data.total - mod) / 5 + addmod;
                            this.setState({
                              data: res.data.data.ticket_inbox,
                              pages: pagesData,
                              allPages: pagesData,
                              loading: false
                            })
                          })
                      }}/>

          <div className="text-right">
            <div className="p-10">
              <ul className="pagination">
                <li className="page-item">
                  {this.state.currentPage == 1 ? <a className="page-link">{'<<'}</a>
                   : <a className="page-link" onClick={e => this.nextPrevious(e, 'back')} >{'<<'}</a>}
                </li>
                {
                  pagi.map(p => (
                    <li className={this.state.currentPage == p ? "page-item active" : "page-item"} key={p}><a className="page-link" onClick={e => this.changePage(e, p)}>{p}</a></li>
                  ))          
                }
                <li className="page-item">
                  {this.state.currentPage == this.state.allPages
                   ? <a className="page-link">{'>>'}</a>
                   : <a className="page-link" onClick={e => this.nextPrevious(e, 'next')} >{'>>'}</a>}
                </li>
                <li className="page-item">&nbsp; &nbsp; &nbsp;
                  <input className="Table__pageInput" name="currentPage" placeholder="..." min="1" type="text" value={this.state.currentPage}
                         style={{width: "45px", height: "26px", textAlign: "center", border: "1px solid #DDDDDD"}} onChange={this.handleChange}/>
                </li>
              </ul>
            </div>
          </div>
          
        </div>
      </TicketManagementContainer>
    )
  }
}

export default QuotationInbox;
