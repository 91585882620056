import React, { Component } from "react";
// import Navbar from "../../layout/Navbar";
// import Sidebar from "../../layout/Sidebar";
import Pagination from "../../../common/Pagination";
import ModalHeader from "../../../common/ModalHeader";
// import ModalFooter from "../../../common/ModalFooter";
// import axios from "axios";
import { Link, Route, withRouter } from "react-router-dom";
import { browserHistory } from "react-router";
import { createHashHistory } from "history";
import SettingContainer from "../Container";
import NumberFormat from "react-number-format";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
// import {
//   listPmCustomer,
//   edit_pm_cust,
//   update_pm_cust,
//   listConstumerAdd,
//   store_pm_cust,
//   del_pm_cust,
//   update_default_days
// } from "../../../../actions/pmAction";
import {
  listPmMachine,
  listMachineAdd,
  store_pm_machine,
  edit_pm_machine,
  update_pm_mach,
  del_pm_mach,
  del_message
} from "../../../../actions/pmMacineAction";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "../../../pages/ticket/pagination.css";
import PaginationReactTable from "../../../common/PaginationReactTable";
import PaginationServerSide from "../../../common/PaginationServerSide";
let upside = "";
let downside = "";
let pagi = "";

class PmMachine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      machi_seri: "",
      cust_name: "",
      pm_days: "",
      currentTickets: [],
      value: [],
      radioo: "",
      machine_id: "",
      // currentTickets2: [],

      currentPage: null,
      totalPages: null,
      radiop: ""
    };
    this.validator = new SimpleReactValidator();
    // this.reset = this.reset.bind(this);
    this.onChanged = this.onChanged.bind(this);
    // this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const jwtToken = localStorage.getItem("jwtToken");

    // this.props.listPmCustomer(jwtToken);
    this.props.listMachineAdd(jwtToken);
    // this.props.listPmMachine(jwtToken);
    this.tembak();

    // this.setState({ days_input: this.props.list_pm.pm_default.days });
    // console.log("iki lho", this.props.list_pm.pm_default.days);

    // const { resp } = this.props.list_user;
    // this.setState({ resp2: resp });
  }
  //   componentWillReceiveProps() {
  //     // const { pm_default } = this.props.list_pm;
  //     this.setState({
  //       days_input: this.props.list_pm.pm_default.days
  //     });

  //     console.log("days_input", this.props.list_pm);
  //   }
  onPageChanged = data => {
    // const { tickets } = this.state;
    const { list_pm_machine } = this.props.pm_machineRed;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentTickets = list_pm_machine.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentTickets, totalPages });
  };
  //   onPageChanged2 = data => {
  //     // const { tickets } = this.state;
  //     // const { list_pm_customer } = this.props.list_pm;
  //     const { list_pm_machine } = this.props.pm_machineRed;
  //     const { currentPage, totalPages, pageLimit } = data;

  //     const offset = (currentPage - 1) * pageLimit;
  //     const currentTickets2 = list_pm_machine.slice(offset, offset + pageLimit);

  //     this.setState({ currentPage, currentTickets2, totalPages });
  //   };
  // const { list_pm_machine } = this.props.pm_machineRed;
  // reset() {
  //   // this.props.reset();
  //   localStorage.removeItem("message");
  //   localStorage.removeItem("error");
  // }
  tembak = () => {
    const jwtToken = localStorage.getItem("jwtToken");
    this.props.listPmMachine(jwtToken);
  };
  onChanged(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handlePmCustomer = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handlePmMod = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleDate = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleDate2 = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleDefaultDays = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  submitDefaultDays = () => {
    const saveUserData = {
      days: this.state.days_input
        ? parseInt(this.state.days_input)
        : parseInt(this.props.list_pm.pm_default.days)
    };
    const datax = {
      dat: saveUserData,
      jwtToken: localStorage.getItem("jwtToken")
    };
    // console.log("ini default kirim", datax);
    this.props.update_default_days(datax);
    // this.props.history.push("/setting/pm");
    // window.location.reload();
  };
  save(e) {
    e.preventDefault();
    const qq = this.state.date_pm;

    this.state.value.push({ date: qq });
    this.setState({ value: this.state.value });

    // console.log("data tanggal", this.state.value);
  }
  save2(e) {
    e.preventDefault();
    const qq = this.state.date_pm_atas;

    this.state.value2.push({ date: qq });
    this.setState({ value2: this.state.value2 });

    // console.log("data tanggal", this.state.value2);
  }
  delarr2 = data_date => {
    // this.state.value2.findIndex(data_date);
    // console.log(
    //   "index nya",
    //   this.state.value.findIndex(date => date.date === data_date)
    // );
    this.setState(
      this.state.value.splice(
        this.state.value.findIndex(date => date.date === data_date),
        1
      )
    );
    // console.log("index", data_date);
    // console.log("nya", this.state.value2);

    // this.setState({ value2: [] });
  };

  delarr = data_date => {
    // this.state.value2.findIndex(data_date);
    // console.log(
    //   "index nya",
    //   this.state.value2.findIndex(date => date.date === data_date)
    // );
    this.setState(
      this.state.value2.splice(
        this.state.value2.findIndex(date => date.date === data_date),
        1
      )
    );
    // console.log("index", data_date);
    // console.log("nya", this.state.value2);

    // this.setState({ value2: [] });
  };
  handleDeleteDate = () => {
    this.setState({ value: [] });
  };
  handleSubmitx = event => {
    event.preventDefault();
    const we = this.state.machine_id;
    const sa = we.split("#");
    if (this.validator.allValid()) {
      if (this.state.radioo === "date") {
        const saveUserData = {
          machine_id: parseInt(sa[0]),
          customer_code: sa[1],
          dates: this.state.value
        };
        const datax = {
          dt: saveUserData,
          jwtToken: localStorage.getItem("jwtToken")
        };
        // console.log("ini data kirim machine save", datax);
        this.props.store_pm_machine(datax);
        setTimeout(
          function() {
            // window.location.reload();
            this.tembak();
            this.props.del_message();
          }.bind(this),
          1000
        );
        // this.props.history.push("/setting/pm");
      }
      if (this.state.radioo === "days") {
        const saveUserData = {
          machine_id: parseInt(sa[0]),
          customer_code: sa[1],
          days: parseInt(this.props.pm_machineRed.pm_default.days)
        };
        const datax = {
          dt: saveUserData,
          jwtToken: localStorage.getItem("jwtToken")
        };
        // console.log("ini data kirim machine save", datax);
        this.props.store_pm_machine(datax);
        setTimeout(
          function() {
            // window.location.reload();
            this.tembak();
            this.props.del_message();
          }.bind(this),
          1000
        );
      }
    } else {
      this.validator.showMessages();

      this.forceUpdate();
    }
  };

  showModalMachine = (idx, day, datex) => {
    // const { dataw } = this.props.list_pm;
    this.setState({
      showMachine: true,
      value2: datex
    });

    const datacust = {
      id: idx,
      jwtToken: localStorage.getItem("jwtToken")
    };

    this.props.edit_pm_machine(datacust);
    const { data_mc, days } = this.props.pm_machineRed;
    // console.log("data ed cust", data_mc ? data_mc : "");
    const ff = data_mc ? data_mc.days.length : "";
    this.setState({
      radiop: day === 0 ? "date" : "days",
      pm_days: day ? day : ""
    });
    // console.log("asssuuuu", this.state.radiop);
  };
  hideModal = () => {
    this.setState({ show: false, showMachine: false });
    window.location.reload();
  };
  hideModal2 = () => {
    window.location.reload();
  };

  handleEdit = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  //   reset() {
  //     window.location.reload();
  //   }

  handleDelPmMachine = idx => {
    const datax = {
      id: parseInt(idx),
      jwtToken: localStorage.getItem("jwtToken")
    };
    // console.log("ini data kirim", datax);
    this.props.del_pm_mach(datax);
    // this.props.history.push("/setting/pm");
    // window.location.reload();
    setTimeout(
      function() {
        // window.location.reload();
        this.props.del_message();
      }.bind(this),
      1000
    );
  };
  handleSubmit = event => {
    event.preventDefault();

    // const jwtToken = localStorage.getItem("jwtToken");
    if (this.state.radiop === "days") {
      const saveUserData = {
        pm_machine_id: this.props.pm_machineRed.data_mc.id,
        days: parseInt(this.state.pm_days),
        dates: []
      };
      const datax = {
        dt: saveUserData,
        jwtToken: localStorage.getItem("jwtToken")
      };
      // console.log("ini data kirim update pm mach days", datax);
      this.props.update_pm_mach(datax);
      setTimeout(
        function() {
          // window.location.reload();
          this.props.del_message();
        }.bind(this),
        1000
      );
    }
    if (this.state.radiop === "date") {
      const saveUserData = {
        pm_machine_id: this.props.pm_machineRed.data_mc.id,
        dates: this.state.value2,
        days: 0
      };
      const datax = {
        dt: saveUserData,
        jwtToken: localStorage.getItem("jwtToken")
      };
      // console.log("ini data kirim update pm mach date", datax);
      this.props.update_pm_mach(datax);
      setTimeout(
        function() {
          // window.location.reload();
          this.props.del_message();
        }.bind(this),
        1000
      );
    }
    this.setState({
      showMachine: false
    });
    // window.location.reload();
  };
  handleSubmitPmCust = event => {
    this.props.history.push("/dashboard");
  };
  render() {
    if (this.props.pm_machineRed.list_pm_machine !== null) {
      if (this.props.pm_machineRed.list_pm_machine.length !== 0) {
        const {
          list_pm_machine,
          pm_default,
          machines,
          resStorePmMachine,
          machine,
          customer,
          data_mc,
          resUpdatePmMachine,
          resDelPmMachine
        } = this.props.pm_machineRed;
        // console.log("machine x---", data_mc ? data_mc.id : "");
        // let but = "disabled";
        // if (this.state.radioo === "date") {
        //   if (this.state.machine_id) {
        //     if (this.state.value !== []) {
        //     }
        //     const but = "";
        //   }
        // }
        // if (this.state.radioo === "days") {
        //   if (this.state.machine_id) {
        //     const but = "";
        //   }
        // }
        const { machi_seri, cust_name } = this.state;
        let filtered = list_pm_machine.slice();

        if (this.state.machi_seri) {
          filtered = filtered.filter(ticket =>
            // console.log(
            //   "akuuu",
            //   ticket.machine.machine_serial,
            //   this.state.machi_seri
            // ),
            ticket.machine.machine_serial !== null
              ? ticket.machine.machine_serial
                  .toString()
                  .indexOf(this.state.machi_seri.toString()) !== -1
              : ""
          );
        }
        if (this.state.cust_name) {
          filtered = filtered.filter(ticket =>
            ticket.customer.CustomerName !== null
              ? ticket.customer.CustomerName.toLowerCase().indexOf(
                  this.state.cust_name.toLowerCase()
                ) !== -1
              : ""
          );
        }

        // <td>{person.machine.machine_serial}</td>
        //               <td>{person.machine.item_no}</td>
        //               <td>{person.customer.CustomerCode}</td>
        //               <td>{person.customer.CustomerName}</td>
        //               <td>
        //                 {person.dates.map(person2 => (
        //                   <div key={person2.date}>
        //                     <span className="label label-default">
        //                       {person2.date}
        //                     </span>
        //                     <br />
        //                   </div>
        //                 ))}
        //               </td>
        //               <td>{person.days === 0 ? "" : person.days}</td>
        const columns = [
          {
            Header: "MACHINE SERIAL",
            columns: [
              {
                // id: "lastName",
                sortable: false,
                filterable: false,
                accessor: "machine.machine_serial"
              }
            ]
          },
          {
            Header: "ITEM NO",
            columns: [
              {
                accessor: "machine.item_no",
                sortable: false,
                filterable: false,
                style: { textAlign: "center" },
                id: "CustomerName"
                // filterMethod: (filter, rows, search) =>
                //   //   // row[filter.id].startsWith(filter.value)
                //   // console.log("asd", search)
                //   // )
                //   matchSorter(rows, filter.value, {
                //     keys: ["CustomerName"]
                //   }),
                // filterAll: true
              }
            ]
          },
          {
            Header: "CUSTOMER CODE",
            columns: [
              {
                sortable: false,
                filterable: false,
                accessor: "customer.CustomerCode"
              }
            ]
          },
          {
            Header: "CUSTOMER NAME",
            columns: [
              {
                sortable: false,
                filterable: false,
                accessor: "customer.CustomerName"
              }
            ]
          },
          {
            Header: "DATE",
            columns: [
              {
                accessor: "dates",
                show: false
              },
              {
                sortable: false,
                filterable: false,
                Cell: ({ row }) =>
                  row.dates.map(person2 => (
                    <div key={person2.date}>
                      <span className="label label-default">
                        {person2.date}
                      </span>
                      <br />
                    </div>
                  ))
              }
            ]
          },
          {
            Header: "DAYS",
            columns: [
              {
                accessor: "days",
                show: false
              },
              {
                sortable: false,
                filterable: false,
                // accessor: "days",
                Cell: ({ row }) => <div>{row.days === 0 ? "" : row.days}</div>
              }
            ]
          },
          {
            Header: "ACTION",
            // style: { textAlign: "center" },
            columns: [
              {
                accessor: "id",
                show: false
              },
              {
                sortable: false,
                filterable: false,
                Cell: ({ row }) => (
                  <div>
                    <a
                      data-toggle="modal"
                      data-target="#modal-add"
                      className="btn btn-primary btn-xs"
                      onClick={() =>
                        this.showModalMachine(row.id, row.days, row.dates)
                      }
                    >
                      <i class="fa fa-fw fa-pencil"></i> Edit
                    </a>{" "}
                    <a
                      className="btn btn-danger  btn-xs"
                      onClick={() => {
                        if (
                          window.confirm("Are you sure to delete this record?")
                        ) {
                          this.handleDelPmMachine(row.id);
                        }
                      }}
                    >
                      <i class="fa fa-fw fa-trash"></i> Remove
                    </a>
                    {/* <Link to={`/ticket-management/assignce/${row.id}`}>
                      <button type="button" className="btn btn-primary btn-xs">
                        SELECT
                      </button>
                    </Link> */}
                  </div>
                )
              }
            ]
          }
        ];

        // let notification;
        // if (resStorePmMachine) {
        //   if (resStorePmMachine.length !== 0) {
        //     notification = (
        //       <div className="modal display-block">
        //         <div className="modal-dialog modal-sm">
        //           <div className="modal-content">
        //             <div
        //               className="modal-body text-center text-success bg-success"
        //               style={{ padding: "30px" }}
        //             >
        //               <button
        //                 type="button"
        //                 className="close"
        //                 data-dismiss="alert"
        //                 aria-hidden="true"
        //                 onClick={this.hideModal2}
        //               >
        //                 &times;
        //               </button>
        //               <i className="fa fa-check-circle fa-5x" />
        //               <br />
        //               <br />
        //               <p>{resStorePmMachine.message}</p>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     );
        //   }
        // }
        // if (resUpdatePmMachine) {
        //   if (resUpdatePmMachine.length !== 0) {
        //     notification = (
        //       // <div className="modal fade" id="modal-add">
        //       //     <div className="modal-dialog">
        //       //       <div className="modal-content">
        //       //         <div className="modal-header">

        //       <div className="modal display-block">
        //         <div className="modal-dialog modal-sm">
        //           <div className="modal-content">
        //             <div
        //               className="modal-body text-center text-success bg-success"
        //               style={{ padding: "30px" }}
        //             >
        //               <button
        //                 type="button"
        //                 className="close"
        //                 data-dismiss="alert"
        //                 aria-hidden="true"
        //                 onClick={this.hideModal2}
        //               >
        //                 &times;
        //               </button>
        //               <i className="fa fa-check-circle fa-5x" />
        //               <br />
        //               <br />
        //               <p>{resUpdatePmMachine.message}</p>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     );
        //   }
        // }
        // if (resDelPmMachine) {
        //   if (resDelPmMachine.length !== 0) {
        //     notification = (
        //       <div className="modal display-block">
        //         <div className="modal-dialog modal-sm">
        //           <div className="modal-content">
        //             <div
        //               className="modal-body text-center text-success bg-success"
        //               style={{ padding: "30px" }}
        //             >
        //               <button
        //                 type="button"
        //                 className="close"
        //                 data-dismiss="alert"
        //                 aria-hidden="true"
        //                 onClick={this.hideModal2}
        //               >
        //                 &times;
        //               </button>
        //               <i className="fa fa-check-circle fa-5x" />
        //               <br />
        //               <br />
        //               <p>{resDelPmMachine.message}</p>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     );
        //   }
        // }
        if (resStorePmMachine) {
          if (resStorePmMachine.length !== 0) {
            if (resStorePmMachine.error === true) {
              NotificationManager.error(
                resStorePmMachine.message,
                "Input Date/Days",
                2000
              );
            }
            if (resStorePmMachine.error === false) {
              NotificationManager.success(
                resStorePmMachine.message,
                "Input Date/Days",
                1000
              );
            }
          }
        }
        if (resDelPmMachine) {
          if (resDelPmMachine.length !== 0) {
            NotificationManager.success(
              resDelPmMachine.message,
              "Delete",
              1000
            );
          }
        }

        if (resUpdatePmMachine) {
          if (resUpdatePmMachine.length !== 0) {
            NotificationManager.success(
              resUpdatePmMachine.message,
              "Update",
              1000
            );
            // console.log("resUpdateDays.message", resUpdateDays.message, 1000);
          }
        }

        const { currentTickets } = this.state;
        // console.log("currentTickets", currentTickets);
        // console.log("currentTickets2", currentTickets2);

        const totalTickets = list_pm_machine.length;
        if (totalTickets === 0) return null;
        upside = (
          <div className="panel-body form form-xs">
            <div
              className="modal fade"
              id="modal-add"
              // className={
              //   this.state.showMachine
              //     ? "modal display-block"
              //     : "modal display-none"
              // }
            >
              <div className="modal-dialog modal-md">
                <div className="modal-content">
                  <div className="modal-header">
                    {/* <ModalHeader
                      // onClick={this.hideModal}
                      info={
                        machine.machine_serial +
                        " (" +
                        customer.CustomerName +
                        ")"
                      }
                    /> */}
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      &times;
                    </button>
                    <div className="panel-body form form-xs">
                      <div className="modal-body">
                        <div className="form-horizontal">
                          <div className="form-group">
                            <label className="col-sm-4 control-label">
                              Customeer Code
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Customer Name"
                                // name="pm_days"
                                value={customer ? customer.CustomerCode : ""}
                                // onChange={this.handleEdit}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              className="control-label col-sm-2"
                              style={{
                                textAlign: "left",
                                fontSize: "12px"
                              }}
                            >
                              PM Type
                            </label>
                            <div className="col-sm-10">
                              <div className="col-sm-2">
                                <label className="control-label">
                                  <input
                                    type="radio"
                                    name="radiop"
                                    value="days"
                                    className="form-control"
                                    checked={
                                      this.state.radiop === "days"
                                        ? "checked"
                                        : ""
                                    }
                                    onChange={this.handlePmMod}
                                  />{" "}
                                  Days
                                </label>
                              </div>
                              <div className="col-sm-2">
                                <label className="control-label">
                                  <input
                                    type="radio"
                                    name="radiop"
                                    value="date"
                                    className="form-control"
                                    checked={
                                      this.state.radiop === "date"
                                        ? "checked"
                                        : ""
                                    }
                                    onChange={this.handlePmMod}
                                  />
                                  Date
                                </label>
                              </div>
                              <div className="col-md-7">
                                <div className="col-md-5">
                                  {this.state.radiop === "days" ? (
                                    <div>
                                      <label className="control-label">
                                        PM Day
                                      </label>
                                      <div className="input-group">
                                        <NumberFormat
                                          type="text"
                                          className="form-control"
                                          placeholder="Customer Name"
                                          name="pm_days"
                                          value={this.state.pm_days}
                                          // value={data_mc ? data_mc.days : ""}
                                          onChange={this.handleEdit}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.radiop === "date" ? (
                                    <div>
                                      <label className="control-label">
                                        PM Date
                                      </label>
                                      <div className="input-group ">
                                        <input
                                          type="date"
                                          className="form-control"
                                          name="date_pm_atas"
                                          value={this.state.date_pm_atas}
                                          onChange={this.handleDate2}
                                          // disabled
                                        />
                                        <span className="input-group-btn">
                                          <button
                                            className="btn btn-default"
                                            onClick={this.save2.bind(this)}
                                            // disabled={!this.state.customer_id}
                                          >
                                            <i className="fa fa-plus" />
                                          </button>
                                        </span>
                                      </div>
                                      {this.state.value2
                                        ? this.state.value2.map(tic3 => (
                                            <div>
                                              <span className="label label-default">
                                                {tic3.date}{" "}
                                                {/* <button
                                            className="btn btn-default"
                                            // onClick={this.save2.bind(this)}
                                            // disabled={!this.state.customer_id}
                                          > */}
                                                <i
                                                  className="fa fa-close"
                                                  style={{ color: "red" }}
                                                  // onClick={this.delarr.bind(this)}
                                                  onClick={() =>
                                                    this.delarr(tic3.date)
                                                  }
                                                />
                                                {/* </button> */}{" "}
                                              </span>

                                              <br />
                                            </div>
                                          ))
                                        : ""}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-info btn-xs center-block"
                      onClick={this.handleSubmit}
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <p>
              <a
                className="btn btn-danger"
                data-toggle="collapse"
                href="#multiCollapseExample2"
                role="button"
                aria-expanded="false"
                aria-controls="multiCollapseExample2"
              >
                Add PM
              </a>
            </p>
            <div className="collapse multi-collapse" id="multiCollapseExample2">
              <fieldset>
                <legend>ADD PM MACHINE</legend>

                <div className="form form-xs">
                  <div className="form-horizontal ">
                    <div
                      className={
                        this.validator.message(
                          "Priority",
                          this.state.machine_id,
                          "required"
                        )
                          ? "form-group has-error"
                          : "form-group"
                      }
                    >
                      <label
                        className="control-label col-sm-2"
                        style={{
                          textAlign: "left",
                          fontSize: "12px"
                        }}
                      >
                        Machine Serial
                      </label>
                      <div className="col-sm-5">
                        <select
                          name="machine_id"
                          value={this.state.machine_id}
                          className="form-control"
                          onChange={this.handlePmCustomer}
                          style={{ width: "70%" }}
                        >
                          <option value="">-</option>
                          {machines.map(tic => (
                            <option
                              value={tic.id + "#" + tic.customer_code}
                              key={tic.id}
                            >
                              {tic.machine_serial + " / " + tic.item_no}
                            </option>
                          ))}
                        </select>
                        <h6 style={{ color: "#A94442" }}>
                          {this.validator.message(
                            " Machine Serial",
                            this.state.machine_id,
                            "required"
                          )}
                        </h6>
                      </div>
                    </div>

                    <div
                      className={
                        this.validator.message(
                          "Priority",
                          this.state.radioo,
                          "required"
                        )
                          ? "form-group has-error"
                          : "form-group"
                      }
                    >
                      <label
                        className="control-label col-sm-2"
                        style={{
                          textAlign: "left",
                          fontSize: "12px"
                        }}
                      >
                        PM Type{" "}
                      </label>
                      <div className="col-sm-10">
                        <div className="col-sm-1">
                          <div className="form-group">
                            <div className="radio">
                              <label>
                                <input
                                  type="radio"
                                  name="radioo"
                                  value="days"
                                  // className="form-control"
                                  // checked={
                                  //   ticket_origin.is_service === 1 ? "checked" : ""
                                  // }
                                  onChange={this.handlePmCustomer}
                                />{" "}
                                Days
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-1">
                          <div className="form-group">
                            <div className="radio">
                              <label>
                                <input
                                  type="radio"
                                  name="radioo"
                                  value="date"
                                  // className="form-control"
                                  // className="optionsRadios"
                                  // checked={
                                  //   ticket_origin.is_service === 1 ? "checked" : ""
                                  // }
                                  onChange={this.handlePmCustomer}
                                />
                                Date
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* <br /> */}

                        <div className="col-md-7">
                          <div className="col-md-9">
                            {this.state.radioo === "days"
                              ? "Default Days."
                              : ""}
                            {this.state.radioo === "date" ? (
                              <div className="input-group ">
                                <input
                                  type="date"
                                  className="form-control"
                                  name="date_pm"
                                  value={this.state.date_pm}
                                  onChange={this.handleDate}
                                  // disabled
                                />
                                <span className="input-group-btn">
                                  <button
                                    className="btn btn-default"
                                    onClick={this.save.bind(this)}
                                    // disabled={!this.state.customer_id}
                                  >
                                    <i className="fa fa-plus" />
                                  </button>
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div>
                          <h6 style={{ color: "#A94442" }}>
                            {this.validator.message(
                              "pm Type",
                              this.state.radioo,
                              "required"
                            )}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        className="control-label col-sm-2"
                        style={{
                          textAlign: "left",
                          fontSize: "12px"
                        }}
                      >
                        {" "}
                      </label>
                      <div className="col-sm-2 col-md-offset-5">
                        {this.state.radioo === "date"
                          ? this.state.value.map(tic => (
                              <div>
                                <span className="label label-default">
                                  {tic.date}
                                  <i
                                    className="fa fa-close"
                                    style={{ color: "red" }}
                                    onClick={() => this.delarr2(tic.date)}
                                  />
                                </span>
                                <br />
                              </div>
                            ))
                          : ""}
                        {/* {this.state.value.length !== 0 ? (
                      <div className="pull-left">
                        <button
                          className="btn btn-danger form-control"
                          onClick={this.handleDeleteDate}
                        >
                          <i class="fa fa-fw fa-trash" />
                        </button>
                      </div>
                    ) : (
                      ""
                    )} */}
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        className="control-label col-sm-1"
                        style={{
                          textAlign: "left",
                          fontSize: "12px"
                        }}
                      >
                        {" "}
                      </label>
                      <div className="col-sm-10">
                        <div className="col-md-3">
                          <button
                            className="btn btn-default form-control"
                            onClick={this.handleSubmitx}
                            // disabled={
                            //   this.state.radioo.length === 0 ||
                            //   this.state.machine_id === ""
                            //     ? "disabled"
                            //     : ""
                            // }
                          >
                            Add
                          </button>
                          {/* <h6 style={{ color: "red" }}>
                            {this.validator.message(
                              "date",
                              this.state.value,
                              "required|array"
                            )}
                          </h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>

            <div className="pull-right form-inline">
              <input
                type="text"
                className="form-control"
                placeholder="Machine Serial"
                name="machi_seri"
                value={this.state.machi_seri}
                onChange={this.onChanged}
              />
              <div className="input-group ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Customer Name"
                  name="cust_name"
                  value={this.state.cust_name}
                  onChange={this.onChanged}
                />
                <span className="input-group-addon">
                  <span className="fa fa-search" />
                </span>
              </div>
            </div>
            {/* {notification} */}
            <NotificationContainer />
            <div className="clearfix" />
          </div>
        );
        downside = (
          // <table className="table table-xs table-bordered">
          //   <thead>
          //     <tr>
          //       <th>Machine Serial</th>
          //       <th>Item No</th>
          //       <th>Customer Code</th>
          //       <th>Customer Name</th>
          //       <th>Date</th>
          //       <th>Days</th>
          //       <th />
          //       <th />
          //     </tr>
          //   </thead>
          //   <tbody>
          //     {machi_seri === "" && cust_name === ""
          //       ? currentTickets.map(person => (
          //           <tr key={person.id}>
          //             <td>{person.machine.machine_serial}</td>
          //             <td>{person.machine.item_no}</td>
          //             <td>{person.customer.CustomerCode}</td>
          //             <td>{person.customer.CustomerName}</td>
          //             <td>
          //               {person.dates.map(person2 => (
          //                 <div key={person2.date}>
          //                   <span className="label label-default">
          //                     {person2.date}
          //                   </span>
          //                   <br />
          //                 </div>
          //               ))}
          //             </td>
          //             <td>{person.days === 0 ? "" : person.days}</td>
          //             <td>
          //               {/* <button
          //                     className="btn btn-default btn-xs"
          //                     onClick={() =>
          //                       this.showModalMachine(
          //                         person.id,
          //                         person.days,
          //                         person.dates
          //                       )
          //                     }
          //                   >
          //                     Edit
          //                   </button> */}

          //               <a
          //                 data-toggle="modal"
          //                 data-target="#modal-add"
          //                 className="btn btn-default btn-xs btn-block"
          //                 onClick={() =>
          //                   this.showModalMachine(
          //                     person.id,
          //                     person.days,
          //                     person.dates
          //                   )
          //                 }
          //               >
          //                 Edit
          //               </a>
          //             </td>
          //             <td>
          //               <button
          //                 className="btn btn-default  btn-xs"
          //                 onClick={() => {
          //                   if (
          //                     window.confirm(
          //                       "Are you sure to delete this record?"
          //                     )
          //                   ) {
          //                     this.handleDelPmMachine(person.id);
          //                   }
          //                 }}
          //               >
          //                 Remove
          //               </button>
          //             </td>
          //           </tr>
          //         ))
          //       : filtered.map(person => (
          //           <tr>
          //             <td>{person.machine.machine_serial}</td>
          //             <td>{person.machine.item_no}</td>
          //             <td>{person.customer.CustomerCode}</td>
          //             <td>{person.customer.CustomerName}</td>
          //             <td>
          //               {person.dates.map(person2 => (
          //                 <div>
          //                   <span className="label label-default">
          //                     {person2.date}
          //                   </span>
          //                   <br />
          //                 </div>
          //               ))}
          //             </td>
          //             <td>{person.days === 0 ? "" : person.days}</td>
          //             <td>
          //               {/* <button
          //                     className="btn btn-default btn-xs"
          //                     onClick={() =>
          //                       this.showModalMachine(
          //                         person.id,
          //                         person.days,
          //                         person.dates
          //                       )
          //                     }
          //                   >
          //                     Edit
          //                   </button> */}
          //               <a
          //                 data-toggle="modal"
          //                 data-target="#modal-add"
          //                 className="btn btn-default btn-xs btn-block"
          //                 onClick={() =>
          //                   this.showModalMachine(
          //                     person.id,
          //                     person.days,
          //                     person.dates
          //                   )
          //                 }
          //               >
          //                 Edit
          //               </a>
          //             </td>
          //             <td>
          //               <button
          //                 className="btn btn-default  btn-xs"
          //                 onClick={() => {
          //                   if (
          //                     window.confirm(
          //                       "Are you sure to delete this record?"
          //                     )
          //                   ) {
          //                     this.handleDelPmMachine(person.id);
          //                   }
          //                 }}
          //               >
          //                 Remove
          //               </button>
          //             </td>
          //           </tr>
          //         ))}
          //   </tbody>
          // </table>
          <ReactTable
            // data={list_pm_customer}
            data={filtered ? filtered : ""}
            // PaginationComponent={PaginationServerSide}
            PaginationComponent={PaginationReactTable}
            columns={columns}
            defaultPageSize={5}
            className="-highlight"
            filterable
            previousText="<<"
            nextText=">>"
            defaultFilterMethod={(filter, row, search) =>
              String(row[filter.id]) === search
            }
            minRows={1}
            showPageSizeOptions={false}
            pageSizeOptions={[40, 200]}
            noDataText="No Matches Found"
            resizable={true}
            key="id"
          />
        );
        pagi = (
          <div>
            {/* {machi_seri === "" && cust_name === "" ? (
              <div className="panel-footer form-xs">
                <Pagination
                  totalRecords={totalTickets}
                  pageLimit={4}
                  pageNeighbours={1}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            ) : (
              ""
            )} */}
          </div>
        );
      }
    }
    return (
      <div>
        <SettingContainer
          pagename="Setting"
          pagedetailname="PM"
          location="pm"
          location2="machine"
        >
          <div className="panel panel-default">
            {/* {upside ? (
              ""
            ) : (
              <i
                className="fa fa-spinner fa-spin fa-2x"
                style={{
                  opacity: "0.3",
                  top: "50%",
                  left: "50%",
                  position: "absolute"
                }}
              />
            )} */}

            <div className="panel-heading">
              PM By Machine{" "}
              {/* {resStorePmMachine ? resStorePmMachine.message : ""} */}
            </div>
            <div className="col-md-10">
              <div>{upside ? upside : ""}</div>
              <div>
                {downside ? (
                  downside
                ) : (
                  <table className="table table-xs table-bordered">
                    <thead>
                      <tr>
                        <th>Machine Serial</th>
                        <th>Item No</th>
                        <th>Customer Code</th>
                        <th>Customer Name</th>
                        <th>Date</th>
                        <th>Days</th>
                        <th />
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="8" style={{ textAlign: "center" }}>
                          No Data Available in This Table
                          <i
                            className="fa fa-spinner fa-spin fa-2x"
                            style={{
                              opacity: "0.3",
                              top: "50%",
                              left: "50%",
                              position: "absolute"
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
              <div>{pagi ? pagi : ""}</div>
            </div>
            <div className="col-md-6">
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </SettingContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  list_pm: state.list_pm,
  pm_machineRed: state.pm_machineRed
});

export default connect(
  mapStateToProps,

  {
    listPmMachine,
    listMachineAdd,
    store_pm_machine,
    edit_pm_machine,
    update_pm_mach,
    del_pm_mach,
    del_message
  }
)(PmMachine);
