import React from "react";
import moment from "moment";

const ContactDetail = props => {
  return (
    <React.Fragment>
      <div className="panel panel-default">
        <div className="panel-heading">CONTACT DETAIL</div>
        <div className="panel-body">
          <div className="form form-xs">
            <div className="row">
              <div className="form-horizontal col-md-8">
                <div className="form-group">
                  <label
                    className="control-label col-sm-6"
                    style={{ textAlign: "left", width: "70%" }}
                  >
                    Date Time Contact
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      value={moment(props.contact.date_of_contact).format(
                        "L HH:mm"
                      )}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group form-inline">
                  <label
                    className="control-label col-sm-6"
                    style={{ textAlign: "left", width: "70%" }}
                  >
                    Contact Name
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      value={props.contact.gender}
                      style={{ width: "20%" }}
                      disabled
                    />
                    <input
                      type="text"
                      className="form-control"
                      value={props.contact.contact_name}
                      disabled
                      style={{ width: "80%" }}
                    />
                  </div>
                </div>
                <div className="form-group form-inline">
                  <label
                    className="control-label col-sm-6"
                    style={{ textAlign: "left", width: "70%" }}
                  >
                    Channel of contact
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      value={props.contact.type_channel_of_contact}
                      style={{ width: "45%" }}
                      disabled
                    />
                    <input
                      type="text"
                      className="form-control"
                      value={props.contact.channel_of_contact}
                      disabled
                      style={{ width: "55%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactDetail;
